import { TransferOrderStatus } from './TransferOrderStatus';

export class TransferOrderStatusCancelledStatus extends TransferOrderStatus {
  public displayName = 'Cancelado';

  public internalName = 'cancelled';

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
