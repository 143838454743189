import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusNotPreparedStatus extends OrderStatus {
  public displayName = 'Sin preparar';

  public internalName = OrderStatuses.NotPrepared;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
