import { Container, InventoryItem, Location } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import {
  PlaceItemInContainerMachine,
  placeItemInContainerInitialContext
} from '../../capa-4/place-item-in-container/PlaceItemInContainerMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface AddItemsToContainerTaskContext {
  originContainer: Container | null;
  currentItem: InventoryItem | null;
  itemsToAdd: InventoryItem[];
  addedItems: InventoryItem[];
  targetLocation: Location | null;
  hint: string | null;
  error: string;
}

export const addItemsToContainerTaskInitialContext: AddItemsToContainerTaskContext =
  {
    originContainer: null,
    currentItem:     null,
    itemsToAdd:      [],
    addedItems:      [],
    targetLocation:  null,
    hint:            null,
    error:           ''
  };

const HELP = `Agregar item a contenedor
Escanee un producto y luego un contenedor para agregarlo al mismo.`;

// Se parece demasiado a AddItemsToContainer (naturalmente)
export const AddItemsToContainerTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<AddItemsToContainerTaskContext>(
    {
      id:      'AddItemsToContainerTaskMachine',
      initial: 'AddItemToContainer',
      states:  {
        Initialize: {
          entry: 'AddItemToContainer'
        },
        AddItemToContainer: {
          invoke: {
            src:  PlaceItemInContainerMachine,
            id:   PlaceItemInContainerMachine.id,
            data: ctx => ({
              ...placeItemInContainerInitialContext,
              requestedContainer: ctx.originContainer
            }),
            onDone: [
              {
                actions: ['updateContainer', 'updateItems'],
                cond:    'itemsRemaining',
                target:  'AddItemToContainer'
              },
              {
                actions: ['updateContainer', 'updateItems'],
                target:  'ConfirmingTask'
              }
            ]
          }
        },
        ConfirmingTask: {
          on: {
            finishTask:                     'AddedItems',
            continue:                       'AddItemToContainer',
            continueWithDifferentContainer: {
              target:  'AddItemToContainer',
              actions: 'resetContainer'
            }
          }
        },
        AddedItems: {
          type: 'final',
          data: ctx => ctx
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        itemsRemaining: ctx => ctx.itemsToAdd.length > 0
      },
      actions: {
        updateItems: assign({
          itemsToAdd: (ctx, event) =>
            ctx.itemsToAdd.filter(item => item.id !== event.data.item.id),
          addedItems: (ctx, event) => [...ctx.addedItems, event.data.item]
        }),
        resetContainer: assign({
          originContainer: () =>
            addItemsToContainerTaskInitialContext.originContainer as any
        }),
        updateContainer: assign({
          originContainer: (ctx, event) =>
            ctx.originContainer || event.data.container
        }),
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API
      }
    }
  );
