import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { PickItem } from './PickItem';
import { PickingProcess } from './PickingProcess';
import {
  PickingToStagingItemStatus,
  PickingToStagingItemStatusCancelledStatus,
  PickingToStagingItemStatusMovedStatus,
  PickingToStagingItemStatusMovingStatus
} from './picking-to-staging-item-status';

export const PickingToStagingItemStatuses = {
  Cancelled: 'cancelled',
  Moving:    'moving',
  Moved:     'moved'
};

@Entity()
export class PickingToStagingItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => PickItem, { name: 'pick_item_id' })
  pickItemId: number;

  @ForeignKey(() => PickingProcess, { name: 'picking_process_id' })
  pickingProcessId: number;

  @Status({
    cancelled: PickingToStagingItemStatusCancelledStatus,
    moving:    PickingToStagingItemStatusMovingStatus,
    moved:     PickingToStagingItemStatusMovedStatus
  })
  status: PickingToStagingItemStatus;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => PickItem, 'pickItemId')
  pickItem: PickItem;

  @Knows(() => PickingProcess, 'pickingProcessId')
  pickingProcess: PickingProcess;
}
