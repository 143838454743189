import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { UploadContainerTaskMachine } from './UploadContainerTaskMachine';

export const UploadContainerTask: FC = withActorRef(UploadContainerTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const menuItems = [
      {
        key:         'confirmar',
        displayName: 'Confirmar',
        event:       'confirm'
      },
      {
        key:         'back',
        displayName: 'Volver a escanear contenedor',
        event:       'back'
      }
    ];
    const handleClick = useCallback((id: string) => {
      const item = menuItems.find(menuItem => menuItem.key === id);
      if (item) send(item.event);
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.hint ? (
                'SUBIR CONTENEDOR'
              ) : (
                <>
                  SUBIR CONTENEDOR:{' '}
                  <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>

        {current.matches('ScanContainer') ? (
          <>
            <Ink.Text>
              Escanee contenedor{' '}
              <Ink.Chalk greenBright>
                {current.context.containerLpn || ''}
              </Ink.Chalk>{' '}
              y subirlo del pasillo a la ubicación{' '}
              <Ink.Chalk greenBright>
                {current.context.locationName || ''}
              </Ink.Chalk>{' '}
            </Ink.Text>
            <ScanContainer />
          </>
        ) : null}
        {current.matches('ScanLocation') ? <ScanLocation /> : null}
        {current.matches('Confirm') ? (
          <>
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                Confirmar subida de contenedor{' '}
                <Ink.Chalk greenBright>
                  {current.context.containerLpn || ''}
                </Ink.Chalk>{' '}
                a la ubicación
                <Ink.Chalk greenBright>
                  {current.context.locationName || ''}
                </Ink.Chalk>{' '}
              </Ink.Text>
              <Ink.Box flexDirection='column' paddingLeft={1}>
                {menuItems.map(item => (
                  <Ink.Button
                    key={item.key}
                    id={item.key}
                    label={item.displayName}
                    onClick={handleClick}
                  />
                ))}
              </Ink.Box>
            </Ink.Box>
          </>
        ) : null}

        {current.matches('Initializing') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}
      </Ink.Box>
    );
  }
);
