import { InventoryItemQualityStatuses } from '../InventoryItem';
import { InventoryItemQualityStatus } from './InventoryItemQualityStatus';

export class InventoryItemQualityStatusNormalStatus extends InventoryItemQualityStatus {
  public displayName = 'Normal';

  public internalName = InventoryItemQualityStatuses.Normal;

  public statusColor = '#d7d7d7';
}
