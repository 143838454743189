import {
  Container,
  Location,
  PutAwayItem,
  Receipt,
  ReceiptItem,
  SlottingAlgorithm,
  Task,
  TaskTypes
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { api } from '../../../../api/utils/axios-instance';
import { Page } from '../../../../types/page';

import {
  CreateContainerMachine,
  createContainerMachineInitialContext
} from '../../capa-4/create-container/CreateContainerMachine';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import { ScanControlDigitMachine } from '../../capa-4/scan-control-digit/scan-control-digit.machine';
import { DefaultScanLocationContext } from '../../capa-4/scan-location/ScanLocationMachine';
import { GoToHelpEvent, GoToOptionsEvent } from '../../core/GenericOptions';
import {
  DefaultReceiptLineMachineContext,
  ReceiptLineMachine
} from './ReceiptLineMachine';
import {
  ContainersListMachine,
  DefaultContainersListMachineContext
} from './options-machines/containers-list/ContainersListMachine';
import {
  DefaultMarkItemAsAnomalyMachineContext,
  MarkItemAsAnomalyMachine
} from './options-machines/mark-item-as-anomaly/MarkItemAsAnomalyMachine';
import {
  RejectItemMachine,
  RejectItemMachineInitialContext
} from './options-machines/reject-item/RejectItemMachine';

export interface VerifyItemsTaskMachineContext {
  task: Task | null;
  payload: any;
  receipt: Receipt | null;
  containersToCreate: any[];
  receiptContainers: Container[];
  receiptItems: ReceiptItem[];
  totalReceiptItems: ReceiptItem[];
  putAwayItems: PutAwayItem[];
  putAwayContainers: Container[];
  currentContainer: Container | null;
  unloadingLocation: Location | null;
  moveContainer: null;
  slottingAlgorithm: SlottingAlgorithm | string | null;
  isAnomaly: boolean;

  nonEmptyContainers: Container[];
  error: string;

  emptyContainers: Container[];
}

export const DefaultVerifyItemsTaskMachine = {
  task:               null,
  payload:            null,
  receipt:            null,
  containersToCreate: [],
  receiptContainers:  [],
  receiptItems:       [],
  totalReceiptItems:  [],
  putAwayItems:       [],
  putAwayContainers:  [],
  currentContainer:   null,
  unloadingLocation:  null,
  moveContainer:      null,
  slottingAlgorithm:  'Default',
  isAnomaly:          false,

  // Existe solo para la lista de containers
  nonEmptyContainers: [],
  error:              '',
  emptyContainers:    []
};

const HELP = `Recepción - Verificación de Productos
 - Ingresar ubicación de recepcionado
 - Crear contenedores
 - Escanear productos
 - Confirmar recepción`;

export const VerifyItemsTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCuAXAggdwIYE8BhAewDt1cBLUsAJ1gBVdYBrAOmsvUtwBtKAXmADEiUAAdisLpTJiQAD0QAGADQh8KgL5b1aLHiJkK1Oo2bsAkujq5upKAHkAbnQBKYAMZhK49CXIqGnphTF5eazAAW1gAZXRiWlwYCHlJaW45JEVEACYATny2AEYANnyADgBWAHYK-NzlABYK9U0EAFoKtgBmJrKy6pqmnuVcptKdPQwcAgCTYPNWNgCAM0paKP1Z-GFPMnXN5EpPFmooNKkZLNAlBFLS3LZc4prS4oKWl9K2xFfu8pNfJVXI1Ro9YpVUoVHS6ECkYgQODybaGeZBMxMZacbh8QRgS4ZWSkeR3NQaRD5SZw1FzYwY+hYqw2JL2JyuWgeby+fz00z0QnXEnZO7FJrKNjNKmVCrNPrjX4IJpVNgjCaNXJVfLKnr1JpTEC0oyBflLdhrDZbGaGQWZYW3RA6ti1fJvOo1KrFfJin4UhCy57FHo9fLKMNVCqlLW5A1G9GmplsI1MkhRcS8MA2VLZdJC0mIUo1Iqh4E1ZSF3XlnqKyPOrUNIug0YtWPWukmxZM23E-OdBV+rrFXofV3KHplz2PfWwoA */
  createMachine<VerifyItemsTaskMachineContext>(
    {
      id:      TaskTypes.VerifyAndPalletizeItemsV0,
      context: DefaultVerifyItemsTaskMachine,
      initial: 'Initializing',
      states:  {
        Initializing: {
          entry: assign({
            totalReceiptItems: context => [...context?.receiptItems]
          }),
          always: [
            {
              cond:   'unloadingLocationIsNotSet',
              target: 'DeclaringUnloadingLocation'
            },
            {
              target: 'DeclaringPutAwayContainers'
            }
          ]
        },

        Finish: {
          type: 'final',
          data: ctx => ({ container: ctx.currentContainer })
        },
        DeclaringUnloadingLocation: {
          invoke: {
            src: ScanControlDigitMachine,
            id:  ScanControlDigitMachine.id,

            data: context => ({
              ...DefaultScanLocationContext,
              requiredLocation: {
                checkDigit: context.payload.appointment.staging.checkDigit,
                name:       context.payload.appointment.staging.name
              }
            }),
            onDone: {
              actions: assign({
                unloadingLocation: (ctx, event) => event.data.location
              }),
              target: 'PatchingUnloadingLocation'
            }
          }
        },
        PatchingUnloadingLocation: {
          invoke: {
            src:    'patchReceipt',
            onDone: {
              target: 'DeclaringPutAwayContainers'
            },
            onError: {
              target:  'DeclaringUnloadingLocation',
              actions: ['assignError']
            }
          }
        },
        ClosingContainers: {
          invoke: {
            id:   ScanContainerMachine.id,
            src:  ScanContainerMachine,
            data: () => ({
              ...DefaultScanContainerContext,
              hint: 'Escanee contenedor a cerrar'
            }),
            onDone: {
              actions: [
                assign({
                  currentContainer: (_ctx, ev) => {
                    return ev.data.container;
                  }
                })
              ],
              target: 'CloseContainer'
            }
          }
        },
        CloseContainer: {
          invoke: {
            src:    'closeContainer',
            onDone: [
              {
                target: 'FetchContainers'
              }
            ],
            onError: [
              {
                target: 'FetchContainers'
              }
            ]
          }
        },
        FetchContainers: {
          initial: 'Fetching',
          states:  {
            Fetching: {
              invoke: {
                src:    'fetchReceiptContainers',
                onDone: [
                  {
                    actions: 'assignReceiptContainers',
                    target:  'Final'
                  }
                ]
              }
            },
            Final: {
              type: 'final'
            }
          },
          onDone: [
            {
              cond:   'remainingOpenContainers',
              target: 'IteratingOverReceiptLines'
            },
            {
              target: 'DeclaringPutAwayContainers'
            }
          ]
        },
        DeclaringPutAwayContainers: {
          invoke: {
            id:   CreateContainerMachine.id,
            src:  CreateContainerMachine,
            data: ctx => ({
              ...createContainerMachineInitialContext,
              receiptId:          ctx.receipt?.id,
              location:           ctx.unloadingLocation,
              pickingWaveId:      ctx.payload?.pickingWaveId,
              requiredContainers: ctx.containersToCreate
            }),
            onDone: {
              actions: [
                assign({
                  receiptContainers: (ctx, ev) => {
                    if (
                      ctx.receiptContainers.some(
                        container => container.lpn === ev.data.container.lpn
                      )
                    ) {
                      return ctx.receiptContainers;
                    }

                    return [...ctx.receiptContainers, ev.data.container];
                  },
                  currentContainer: (_ctx, ev) => {
                    return ev.data.container;
                  }
                })
              ],
              target: 'ConfirmContainerCreation'
            }
          },
          on: {
            StarVerifying: {
              target: 'IteratingOverReceiptLines'
            },
            Container_Created: {}
          }
        },
        ConfirmContainerCreation: {
          on: {
            createContainer: {
              target: 'DeclaringPutAwayContainers'
            },
            receiptLine: {
              target: 'IteratingOverReceiptLines'
            }
          }
        },

        RestartReceiptLines: {
          always: {
            target: 'IteratingOverReceiptLines'
          }
        },
        IteratingOverReceiptLines: {
          invoke: {
            src:  ReceiptLineMachine,
            id:   ReceiptLineMachine.id,
            data: ctx => ({
              ...DefaultReceiptLineMachineContext,
              location:          ctx.unloadingLocation,
              container:         ctx.currentContainer,
              validReceiptItems: ctx.totalReceiptItems,
              receipt:           ctx.receipt || ctx.payload,
              receiptItems:      ctx.receiptItems
            }),
            onDone: [
              {
                actions: 'updateContext',
                cond:    'linesRemaining',
                target:  'IteratingOverReceiptLines'
              },
              {
                actions: 'updateContext',
                target:  'ContainersList'
              }
            ]
          }
        },

        // Start Option states
        RejectItem: {
          invoke: {
            src:  RejectItemMachine,
            id:   RejectItemMachine.id,
            data: context => ({
              ...RejectItemMachineInitialContext,
              receipt:    context.receipt,
              validItems: context.totalReceiptItems
            }),
            onDone: {
              target: 'IteratingOverReceiptLines'
            }
          }
        },
        CreateContainer: {
          always: ['DeclaringPutAwayContainers']
        },
        MarkItemAsAnomaly: {
          invoke: {
            src:  MarkItemAsAnomalyMachine,
            id:   MarkItemAsAnomalyMachine.id,
            data: context => ({
              ...DefaultMarkItemAsAnomalyMachineContext,
              location:   context.unloadingLocation,
              container:  context.currentContainer,
              receipt:    context.receipt,
              validItems: context.totalReceiptItems
            }),
            onDone: {
              target: 'IteratingOverReceiptLines'
            }
          }
        },

        ContainersList: {
          invoke: {
            src:  ContainersListMachine,
            id:   ContainersListMachine.id,
            data: context => ({
              ...DefaultContainersListMachineContext,
              containers: context.nonEmptyContainers
            }),
            onDone: [
              {
                actions: 'removeFromReceiptContainers',
                target:  'ConfirmReceiptItemsVerification'
              }
            ]
          }
        },

        // End Option states

        ConfirmReceiptItemsVerification: {
          on: {
            confirmReceiptVerification: {
              target: 'ConfirmingTask'
            },
            skipTaskConfirmation: {
              target: 'ItemsVerificationComplete'
            }
          }
        },
        ConfirmingTask: {
          invoke: {
            src:    'completeTask',
            data:   ctx => ctx,
            onDone: {
              target: 'ItemsVerificationComplete'
            }
          }
        },
        DeleteEmptyContainers: {
          invoke: {
            src:    'deleteUnusedContainers',
            onDone: {
              actions: assign({
                receiptContainers: (_ctx, _evt) => []
              }),
              target: 'ConfirmReceiptItemsVerification'
            }
          }
        },
        ItemsVerificationComplete: {
          type: 'final'
        }
      },
      on: {
        containerChange: {
          actions: assign({
            currentContainer: (_ctx, event) => event.data.container
          }),
          target: 'RestartReceiptLines'
        },
        goToRejectItem: {
          target: 'RejectItem'
        },
        goToMarkItemAsAnomaly: {
          target: 'MarkItemAsAnomaly'
        },
        goToHelp: {
          actions: 'triggerHelpScreen'
        },
        goToOptions: {
          actions: 'triggerMenuScreen'
        },
        finishTask: {
          target: 'ConfirmingTask'
        },
        goToContainersList: {
          target: 'ContainersList'
        },
        goTocreateContainer: {
          target: 'CreateContainer'
        },
        closeContainer: {
          target: 'CloseContainer'
        },
        goToCloseContainer: {
          target: 'ClosingContainers'
        },
        deleteUnusedContainers: {
          actions: 'assignEmptyContainers',
          target:  'DeleteEmptyContainers'
        }
      }
    },
    {
      guards: {
        ...UtilityGuards,
        unloadingLocationIsNotSet: ctx => !ctx.unloadingLocation,
        linesRemaining:            (ctx, event) => {
          if (ctx.receiptItems.length === 1) {
            return ![...ctx.putAwayItems, event.data.item].find(
              putAwayItem =>
                putAwayItem?.receiptItemId === ctx.receiptItems[0].id
            );
          }
          return ctx.receiptItems.length > 1;
        },
        remainingOpenContainers: (ctx, _event) => {
          return !!ctx.receiptContainers.length;
        }
      },
      actions: {
        ...UtilityActions,

        removeFromReceiptContainers: assign({
          receiptContainers:  (_context, _event) => [],
          nonEmptyContainers: (_context, _event) => [],
          currentContainer:   (_context, _event) => null
        }),
        assignReceiptContainers: assign({
          currentContainer: (ctx, event) =>
            event.data.receiptContainers.find(
              container => container.id === ctx.currentContainer?.id
            ) || event.data.receiptContainers[0],
          receiptContainers: (_ctx, event) => event.data.receiptContainers
        }),
        updateContext: assign({
          // TODO: Revisar Logica que saca la linea pickeada.
          receiptItems: (context, event) =>
            context.receiptItems.filter(
              item => item.id !== event.data.item.receiptItemId
            ),
          putAwayItems: (context, event) => {
            return [...context.putAwayItems, event.data.item];
          },
          nonEmptyContainers: (context, event): Container[] =>
            [
              ...context.nonEmptyContainers,
              context.receiptContainers.find(
                container => container.id === event.data.item.containerId
              )
            ].filter(container => !!container) as Container[]
        }),
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP),
        triggerMenuScreen: (
          ctx,
          { send, state, triggerMenuScreen }: GoToOptionsEvent
        ) => {
          triggerMenuScreen([
            {
              label:   'Terminar tarea',
              onClick: () => send('finishTask')
            },
            ...(state.matches('IteratingOverReceiptLines')
              ? [
                  {
                    label:   'Rechazar producto',
                    onClick: () => send('goToRejectItem')
                  },
                  {
                    label:   'Crear un nuevo contenedor',
                    onClick: () => send('goTocreateContainer')
                  },
                  {
                    label:   'Cerrar un contenedor',
                    onClick: () => send('goToCloseContainer')
                  },
                  ...(ctx.nonEmptyContainers.length !== 0
                    ? [
                        {
                          label:   'Info contenedores',
                          onClick: () => send('goToContainersList')
                        }
                      ]
                    : [])
                ]
              : [])
          ]);
        }
      },
      services: {
        ...API,
        closeContainer: (ctx: VerifyItemsTaskMachineContext) =>
          api.patch('/container/close', {
            containerId: ctx.currentContainer?.id
          }),
        fetchReceiptContainers: async ctx => {
          const { data: receiptContainers } = await api.get<Page<Container>>(
            `/container?qs=receiptId=${
              ctx.receipt?.id
            }%26isClosed=${false}%26&limit=20`
          );
          return { receiptContainers: receiptContainers.rows };
        }
      }
    }
  );
