import { ReservationOrderStatuses } from '../ReservationOrder';
import { ReservationOrderStatus } from './ReservationOrderStatus';

export class ReservationOrderStatusCancelledStatus extends ReservationOrderStatus {
  public displayName = 'Cancelada';

  public internalName = ReservationOrderStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
