import Ink from '@ink';
import React, { FC, useEffect } from 'react';

export interface CountContext {
  processed: number;
  total: number;
}
export interface CounterProps {
  onComplete?: (count: CountContext) => void;
  count: CountContext | null;
  canActivate?: boolean;
}

export const Counter: FC<CounterProps> = ({
  onComplete,
  count,
  canActivate = false
}) => {
  useEffect(() => {
    if (count && canActivate) {
      if (
        count.processed === count.total &&
        typeof count.processed === 'number' &&
        typeof count.total === 'number' &&
        typeof onComplete === 'function' &&
        count.total > 0
      ) {
        onComplete(count);
      }
    }
  }, [count, canActivate]);

  return count ? (
    <Ink.Text>
      <Ink.Chalk greenBright bold>
        {count.processed}
      </Ink.Chalk>
      /
      <Ink.Chalk greenBright bold>
        {count.total}
      </Ink.Chalk>
    </Ink.Text>
  ) : null;
};

Counter.displayName = 'Counter';
