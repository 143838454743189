import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { Order } from './Order';

export enum OrderTypes {
  Sellers = 'hd-sellers',
  WMS = 'wms',
  Transfer = 'transferencia',
  StoreSupplySellers = 'ss-sellers',
  WMSRetailHD = 'wms-retail-hd',
  WMSRetailSS = 'wms-retail-ss'
}

export const HDtypes = [OrderTypes.WMSRetailHD, OrderTypes.Sellers];

@Entity()
export class OrderType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => Order, 'orderTypeId')
  orders: Order[];
}
