import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { CustomerOrder } from './CustomerOrder';
import { Order } from './Order';

@Entity()
export class OrderCustomerOrder {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @ForeignKey(() => CustomerOrder, { name: 'customer_order_id' })
  customerOrderId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Order, 'orderId')
  order: Order;

  @Needs(() => CustomerOrder, 'customerOrderId')
  customerOrder: CustomerOrder;
}
