import { Entity, Field, Needs, PrimaryKey, Status } from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { User } from './User';
import {
  InventoryCountStatus,
  InventoryCountStatusCancelledStatus,
  InventoryCountStatusCompletedStatus,
  InventoryCountStatusConfirmationStatus,
  InventoryCountStatusPendingStatus,
  InventoryCountStatusProcessingStatus
} from './inventory-count-status';

export const InventoryCountStatuses = {
  Pending:      'pending',
  Processing:   'processing',
  Confirmation: 'confirmation',
  Completed:    'completed',
  Cancelled:    'cancelled'
};

// @TODO: Remove deprecated
@Entity()
export class InventoryCount {
  @PrimaryKey()
  id: number;

  @Field({ name: 'inventory_item_id' })
  inventoryItemId: number;

  @Field({ name: 'expected_qty' })
  expectedQty: number;

  @Field({ name: 'found_qty' })
  foundQty: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Field({ name: 'author_id' })
  authorId: number;

  @Field({ name: 'counter_id' })
  counterId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Status({
    pending:      InventoryCountStatusPendingStatus,
    processing:   InventoryCountStatusProcessingStatus,
    confirmation: InventoryCountStatusConfirmationStatus,
    completed:    InventoryCountStatusCompletedStatus,
    cancelled:    InventoryCountStatusCancelledStatus
  })
  status: InventoryCountStatus;

  @Needs(() => User, 'authorId')
  author: User;

  @Needs(() => User, 'counterId')
  counter: User;

  @Needs(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;
}
