import { assign, createMachine } from 'xstate';

export interface EnterQuantityContext {
  checkDigit: number | null;
}

export const DefaultEnterCheckDigitContext = {
  checkDigit: null
};

export const EnterCheckDigitMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFEB2AXMAnAwgCzAGMBrAEQEspz0BZAQ0L3NTADowNtmp8izLqAYgCuABwh1MvEhSroA2gAYAuolCiA9rGrkNqNSAAeiACwBOVmYBMZgGwAOe7atWAzGYCMAVg8AaEACeiPYerOZmZvZmimYA7Ipu9rEAvsn+aJi4BDICtAxMLOycWNzS-HKCsMIARgC21GWy1EqqSCCa2ui6+m3GCFYmVqweJoq2rl5xJg4u9v5BCB4hll6uHlax9jYuJl4pqf6oGhBwBhnYjbn0jMxgBh06egZ9ALR+gYgvXpYRv39-rgOIHOWT4TTyN0KHEypWy5Wo9y0jx6oD6Jli80QSyGuzW6yszl20VsQJBlzk1wKbEYYNyAGUavV0JgIIjOt1nqYJqw3CZ3GYvOMYiY5h9FssrIo1q4rOsTCNBalUkA */
  createMachine<EnterQuantityContext>(
    {
      id:      'EnterCheckDigitMachine',
      initial: 'enteringCheckDigit',
      states:  {
        enteringCheckDigit: {
          on: {
            updateCheckDigit: {
              actions: assign({
                checkDigit: (_ctx, event) => event.data.checkDigit
              })
            },
            submitCheckDigit: {
              target: 'checkDigitSubmitted'
            }
          }
        },
        checkDigitSubmitted: {
          type: 'final',
          data: (ctx, _event) => ({ checkDigit: ctx.checkDigit })
        }
      }
    },
    {}
  );
