import {
  Container,
  Location,
  Lot,
  Product,
  ProductItem,
  ProductTypesNames,
  RestockingPayload,
  Task
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';

import {
  DefaultRestockLineContext,
  RestockLineMachine
} from '../../capa-4/restocking-line-machine/RestockLineMachine';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface RestockingTaskContext {
  container: Container | null;
  task: Task | null;
  payload: RestockingPayload;
  // itemsToRestock: ProductItem[];
  // restockedItems: ProductItem[];
  product: Product;
  lot: Lot;
  location: Location;
  currentItem: ProductItem | null | undefined;
  targetLocation: Location | null;
  originLocation: Location | null;
  title: string | null;
  hint: string | null;
  error: string;
  isRestocking: boolean | null;
  isBulkItem: boolean | null;
}

export const DefaultRestockingTaskContext = {
  container:      null,
  task:           null,
  // itemsToRestock: [],
  // restockedItems: [],
  currentItem:    null,
  targetLocation: null,
  originLocation: null,
  hint:           null,
  error:          '',
  isRestocking:   true,
  isBulkItem:     null
};

const HELP = `Restock de productos
Permite transferir productos de un contenedor a una ubicación de picking. 
Escanee contenedor, luego un producto y luego una ubicación de picking.`;

export const RestockingTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<RestockingTaskContext>(
    {
      id:      'RestockingTaskMachine',
      initial: 'Initialize',
      states:  {
        Initialize: {
          entry: assign(ctx => {
            const payload = ctx.task?.payload as RestockingPayload;
            return {
              ...ctx,
              ...ctx.task?.payload,
              currentItem: payload.inventoryItem
            };
          }),
          always: [
            { cond: 'containerIsSet', target: 'ReplenishingProducts' },
            {
              cond:   'isBulkItem',
              target: 'ReplenishingProducts'
            },
            {
              target: 'ReachingStockContainer'
            }
          ]
        },
        ReachingStockContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              container: ctx.container
            }),
            onDone: {
              actions: 'assignContainer',
              target:  'ReplenishingProducts'
            }
          }
        },
        ReplenishingProducts: {
          invoke: {
            src:  RestockLineMachine,
            id:   RestockLineMachine.id,
            data: ctx => ({
              ...DefaultRestockLineContext,
              product:          ctx.product,
              lot:              ctx.lot,
              location:         ctx.location,
              container:        ctx.container,
              item:             ctx.currentItem,
              pickingProcessId: ctx.payload.pickingProcessId,
              pickingWaveId:    ctx.payload.pickingWaveId,
              orderId:          ctx.task?.orderId,
              isRestocking:     ctx.isRestocking,
              isBulkItem:
                ctx.product?.productType?.name == ProductTypesNames.Granel ||
                ctx.currentItem?.product?.productType?.name ==
                  ProductTypesNames.Granel
            }),
            onDone: 'CompleteTask'
          }
        },
        CompleteTask: {
          invoke: {
            src:    'completeTask',
            data:   ctx => ctx,
            onDone: {
              target: 'ProductsReplenished'
            }
          }
        },
        ProductsReplenished: {
          type: 'final',
          data: ctx => ctx
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        containerIsSet: ctx => !!ctx.container,
        isBulkItem:     ctx =>
          ctx.product?.productType?.name == ProductTypesNames.Granel ||
          ctx.currentItem?.product?.productType?.name ==
            ProductTypesNames.Granel
      },
      actions: {
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API
      }
    }
  );
