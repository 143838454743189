import { StockTransferStatus } from './StockTransferStatus';

export class StockTransferRecivedStatus extends StockTransferStatus {
  public displayName = 'Recibido';

  public internalName = 'recived';

  public statusColor = '#f3ecfe';

  public textColor = '#8240f2';
}
