import Ink from '@ink';
import { Container } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { Counter } from '../../../shared/Counter';
import { withActorRef } from '../../../shared/Machine';
import { SelectList } from '../../../shared/SelectList';
import { CreateContainer } from '../../capa-4/create-container/CreateContainer';
import { MarkAsIncident } from '../../capa-4/mark-as-incident/MarkAsIncident';
import { MoveContainer } from '../../capa-4/move-container/MoveContainer';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { PickItemsTaskMachine } from './PickItemsTaskMachine';
import { PickLine } from './PickLine';

export const PickItemsTask = withActorRef(PickItemsTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const menuItems = [
      {
        key:         'confirm-picking',
        displayName: '1. Confirmar Picking.',
        event:       'continue'
      },
      { key: 'back', displayName: '2. Volver', event: 'goBack' }
    ];

    const handleClick = useCallback((id: string) => {
      const item = menuItems.find(menuItem => menuItem.key === id);
      if (item) send(item.event);
    }, []);

    const onSelectContainer = (container: Container) => {
      send({ type: 'containerChange', data: { container } });
    };

    return (
      <Ink.Box flexDirection='column' paddingX={2}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.order?.id ? (
                'PREPARANDO ORDEN'
              ) : (
                <>
                  PREPARANDO ORDEN:{' '}
                  <Ink.Chalk greenBright>{current.context.order.id}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>

        {current.matches('ScanningPickingCart') ? (
          <>
            Iniciando tarea...
            <ScanLocation />
          </>
        ) : null}

        {current.matches('DeclaringPickingContainer') ? (
          <>
            Declare contenedores de Picking...
            <Ink.Text>
              <Counter
                count={{
                  total:
                    current.context.itemsToPick?.length +
                      current.context.itemsPicked?.length || 0,
                  processed: current.context.itemsPicked?.length
                }}
              />{' '}
              Contenedores
            </Ink.Text>
            <CreateContainer />
          </>
        ) : null}

        {current.matches('iteratingOverPickItems') ? (
          <>
            <Ink.Text>
              <Counter
                count={
                  current.context.pickingWave?.pickItems !== null
                    ? {
                        total:
                          current.context.itemsToPick?.length +
                            current.context.itemsPicked?.length || 0,
                        processed: current.context.itemsPicked?.length
                      }
                    : null
                }
              />{' '}
              Lineas Pickeadas
            </Ink.Text>

            <Ink.Box>
              <Ink.Text>Pickeando en contenedor: </Ink.Text>
              <Ink.Chalk greenBright bold>
                {current.context.currentContainer?.lpn || ''}
              </Ink.Chalk>
            </Ink.Box>

            <SelectList<Container>
              items={current.context.containers}
              trackBy={item => item.id}
              getDisplayText={item => item.lpn}
              onSelect={onSelectContainer}
            />

            <PickLine />
          </>
        ) : null}

        {current.matches('DropPickingContainersInDestination') ? (
          // @TODO: Change to layer 4
          <>
            <Ink.Text>Traslade a un canal de despacho</Ink.Text>
            <MoveContainer />
          </>
        ) : null}

        {current.matches('ConfirmPicking') ? (
          <Ink.Box flexDirection='column'>
            {menuItems.map(item => (
              <Ink.Button
                key={item.key}
                id={item.key}
                label={item.displayName}
                onClick={handleClick}
              />
            ))}
          </Ink.Box>
        ) : null}

        {current.matches('confirmPicking') ? (
          <Ink.Box flexDirection='column' paddingTop={1} paddingX={1}>
            <>
              {current.context.itemsToPick.length === 0 ? (
                <Ink.Text inline>
                  Se han preparado todos los productos de la orden{' '}
                  <Ink.Text bold color='greenBright' inline>
                    #{' '}
                    {current.context?.pickingWaveId ||
                      current.context?.order?.id ||
                      ''}
                  </Ink.Text>
                  .
                </Ink.Text>
              ) : (
                <Ink.Text>
                  Se han preparado{' '}
                  <Ink.Chalk greenBright>
                    {current.context?.itemsPicked?.length}
                  </Ink.Chalk>
                  /
                  <Ink.Chalk greenBright>
                    {current.context?.totalItemsToPick?.length}
                  </Ink.Chalk>{' '}
                  productos de{' '}
                  <Ink.Chalk greenBright>
                    {current.context?.pickingWaveId ||
                      current.context?.order?.id ||
                      ''}
                  </Ink.Chalk>
                  .
                </Ink.Text>
              )}

              <Ink.Box paddingY={1}>
                <Ink.Text>¿Confirmar Picking?</Ink.Text>
                {/* {current.context.error && ( */}
                {/* '  <Ink.Text color='redBright'>{current.context.error}</Ink.Text> */}
                {/* )} */}
              </Ink.Box>
              <Ink.Box flexDirection='column'>
                {menuItems.map(item => (
                  <Ink.Button
                    key={item.key}
                    id={item.key}
                    label={item.displayName}
                    onClick={handleClick}
                  />
                ))}
              </Ink.Box>
            </>
          </Ink.Box>
        ) : null}

        {current.matches('MarkAsIncident') ? <MarkAsIncident /> : null}

        {current.matches('PickingWavePaused') ? (
          <>
            <Ink.Text>
              La ola de pickeo se encuentra pausada por una incidencia. Resuelva
              la incidencia o vuelva más tarde.
            </Ink.Text>
            <Ink.Button
              label='Volver a verificar'
              onClick={() => send('check')}
            />
          </>
        ) : null}

        {current.matches('initializing') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}
      </Ink.Box>
    );
  }
);
