import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { CustomerOrder } from './CustomerOrder';
import { FulfillmentOrder } from './FulfillmentOrder';
import { ReservationItem } from './ReservationItem';
import { ReservationOrder } from './ReservationOrder';
import { ReservationProduct } from './ReservationProduct';
import { ReservationType } from './ReservationType';
import { Warehouse } from './Warehouse';
import { ReservationStatus } from './reservation-status';
import { ReservationStatusCancelledStatus } from './reservation-status/ReservationStatusCancelledStatus';
import { ReservationStatusDeliveredStatus } from './reservation-status/ReservationStatusDeliveredStatus';
import { ReservationStatusDispatchedStatus } from './reservation-status/ReservationStatusDispatchedStatus';
import { ReservationStatusProcessingStatus } from './reservation-status/ReservationStatusProcessingStatus';
import { ReservationStatusRejectedStatus } from './reservation-status/ReservationStatusRejectedStatus';
import { ReservationStatusRoutedStatus } from './reservation-status/ReservationStatusRoutedStatus';
import { ReservationStatusUnRoutedStatus } from './reservation-status/ReservationStatusUnRoutedStatus';

export const SelectedModality = {
  HD:     'F',
  SPU:    'V',
  MANUAL: 'M',
  DIST:   'D'
} as const;

export const RejectionReasonReservation = {
  Entregado:               'entregado',
  ClienteAusente:          'cliente ausente',
  DireccionIncorrecta:     'direccion incorrecta',
  EntregaParcial:          'entrega parcial',
  ZonaPeligrosa:           'zona peligrosa',
  ZonaIntransitable:       'zona instransitable',
  TransbordoDeMercaderia:  'trasbordo de mercaderia',
  TransporteNoVisita:      'transporte no realiza visita',
  Siniestro:               'siniestro de mercaderia',
  ProductoNoDespachado:    'producto no despachado',
  RechazoPorAnulacion:     'rechazo por anulacion',
  ClienteAnulara:          'cliente anulara',
  ClienteRepactaFecha:     'cliente repacta fecha',
  RechazoPorDetalle:       'rechazo por detalle',
  ProductoYaEntregado:     'producto ya entregado',
  ProductoNoCorresponde:   'producto no corresponde',
  ArtNoIngresaAlDomicilio: 'art. no ingresa al domicilio'
} as const;

export const RejectTypes = {
  Success:  '01',
  Rejected: '00'
};

export type SelectedReservationRejectionReasonTypes =
  (typeof RejectionReasonReservation)[keyof typeof RejectionReasonReservation];

export type SelectedModalityTypes =
  (typeof SelectedModality)[keyof typeof SelectedModality];

export const ReservationStatuses = {
  Unrouted:   'un-routed',
  Routed:     'routed',
  Processing: 'processing',
  Dispatched: 'dispatched',
  Delivered:  'delivered',
  Rejected:   'rejected',
  Cancelled:  'cancelled'
};

export interface ReservationCustomer {
  fullName: string;
  document: number;
  documentType: string;
  contactNumber: string;
  contactNumber2: string;
  socialReason: string;
  contactName: string;
  address: {
    zipCode: string;
    street: string;
    streetNumber: number;
    town: string;
    floor: number;
    apartmentNumber: string;
    references: string;
    province: string;
  };
}
export interface Bill {
  number: number;
  import: number;
  sellingPoint: string;
}

@Entity()
export class Reservation {
  @PrimaryKey()
  id: number;

  @Status({
    [ReservationStatuses.Unrouted]:   ReservationStatusUnRoutedStatus,
    [ReservationStatuses.Routed]:     ReservationStatusRoutedStatus,
    [ReservationStatuses.Rejected]:   ReservationStatusRejectedStatus,
    [ReservationStatuses.Dispatched]: ReservationStatusDispatchedStatus,
    [ReservationStatuses.Delivered]:  ReservationStatusDeliveredStatus,
    [ReservationStatuses.Processing]: ReservationStatusProcessingStatus,
    [ReservationStatuses.Cancelled]:  ReservationStatusCancelledStatus
  })
  status: ReservationStatus;

  @Field({ name: 'reserved_at' })
  reservedAt: Date;

  @Field({ name: 'deliver_before' })
  deliverBefore: Date;

  @Field({ name: 'external_reference_id' })
  externalReferenceId: number;

  bill: Bill;

  customer: ReservationCustomer;

  rawCustomerData: ReservationCustomer;

  @Field()
  modality: SelectedModalityTypes;

  @Field({ name: 'delivery_code' })
  deliveryCode: string;

  @NeededBy(() => ReservationProduct, 'reservationId')
  reservationProducts: ReservationProduct[];

  @NeededBy(() => ReservationItem, 'reservationId')
  reservationItems: ReservationItem[];

  @NeededBy(() => ReservationOrder, 'reservationId')
  reservationOrders: ReservationOrder[];

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Knows(() => Warehouse, 'warehouseToId')
  warehouseTo: Warehouse;

  @ForeignKey(() => Warehouse, { name: 'target_warehouse_id' })
  targetWarehouseId: number;

  @Knows(() => Warehouse, 'targetWarehouseId')
  targetWarehouse: Warehouse;

  @ForeignKey(() => Warehouse, { name: 'warehouse_to_id' })
  warehouseToId: number;

  @Knows(() => FulfillmentOrder, 'reservationId')
  fulfillmentOrder: FulfillmentOrder;

  @NeededBy(() => CustomerOrder, 'reservationId')
  customerOrder: CustomerOrder;

  @Field({ name: 'rejection_details' })
  rejectionDetails: SelectedReservationRejectionReasonTypes;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => ReservationType, { name: 'reservation_type_id' })
  reservationTypeId: number;

  @Needs(() => ReservationType, 'reservationTypeId')
  reservationType: ReservationType;
}
