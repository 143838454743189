import { InventoryItemPhysicalStatuses } from '../InventoryItem';
import { InventoryItemPhysicalStatus } from './InventoryItemPhysicalStatus';

export class InventoryItemPhysicalStatusNotInWareHouseStatus extends InventoryItemPhysicalStatus {
  public displayName = 'No disponible';

  public internalName = InventoryItemPhysicalStatuses.NotInWareHouse;

  public statusColor = '#d7d7d7';
}
