import { MachineOptions } from 'xstate';
import { ScanLabelContext } from './machine';

type ScanLabelGuards = MachineOptions<ScanLabelContext, any>['guards'];

export const scanLabelGuards: ScanLabelGuards = {
  hasRequiredLabelAndIsIncorrect: (ctx, _event) => {
    return ctx.requiredLabel !== null && ctx.requiredLabel !== ctx.label;
  }
};
