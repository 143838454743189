import Ink from '@ink';
import { Container } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../../../shared/Machine';
import { MoveContainer } from '../../../../capa-4/move-container/MoveContainer';
import { ContainersListMachine } from './ContainersListMachine';

export const ContainersList: FC = withActorRef(ContainersListMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const uniqueContainers: Container[] = [];

    current.context.containers.map(container => {
      if (
        !uniqueContainers.find(
          checkContainer => container.lpn == checkContainer.lpn
        )
      ) {
        uniqueContainers.push(container);
      }
    });

    current.context.containers = uniqueContainers;

    const handleClick = useCallback(() => {
      if (uniqueContainers) {
        send({
          type: 'closeAll',
          data: { containers: uniqueContainers }
        });
      }
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingTop={1}>
        <>
          {current.matches('ListContainers') ? (
            <>
              <Ink.Button
                label='Cerrar contenedores de la recepción'
                onClick={handleClick}
              />
            </>
          ) : null}

          {current.matches('MoveContainer') ? <MoveContainer /> : null}
        </>
      </Ink.Box>
    );
  }
);
