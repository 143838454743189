import { InventoryCountingStatus } from './InventoryCountingStatus';

export class InventoryCountingStatusCompletedStatus extends InventoryCountingStatus {
  public displayName = 'Completado';

  public internalName = 'completed';

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
