import { PackingWaveStatuses } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { MiniPackingWaveContext } from './machine';

type MiniPackingWaveGuards = MachineOptions<
  MiniPackingWaveContext,
  any
>['guards'];

export const miniPackingWaveGuards: MiniPackingWaveGuards = {
  isPackingWavePending: (_ctx, evt) => {
    return evt.data.status === PackingWaveStatuses.Pending;
  },
  hasPendingItems: (_ctx, evt) => {
    return evt.data.count > 0;
  }
};
