import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { SelectRejectionReasonMachine } from './SelectRejectionReasonMachine';

export const SelectRejectionReason: FC = withActorRef(
  SelectRejectionReasonMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const handleClick = useCallback((id: string) => {
    send({ type: 'select', data: { id } });
  }, []);

  return (
    <>
      {current.matches('listRejectionReasons') ? (
        <>
          <Ink.Text>Seleccione razón de incidencia: </Ink.Text>
          <Ink.Box flexDirection='column'>
            {current.context.rejectionReasons.map(item => (
              <Ink.Button
                key={item.id}
                id={item.id.toString()}
                label={item.name}
                onClick={handleClick}
              />
            ))}
          </Ink.Box>
        </>
      ) : null}
    </>
  );
});
