import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Task } from './Task';
import { TaskRoleTaskType } from './TaskRoleTaskType';
import { User } from './User';

@Entity()
export class TaskType {
  @PrimaryKey()
  id: number;

  @Field()
  displayName: string;

  @Field()
  description: string;

  @Field()
  internalName: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => User, { name: 'author_id' })
  authorId: number;

  @Needs(() => User, 'authorId')
  author: User;

  @Knows(() => TaskRoleTaskType, 'taskTypeId')
  taskRoleTypes: TaskRoleTaskType[];

  @Knows(() => Task, 'taskTypeId')
  tasks: Task[];
}
