import { TransportOrderStatuses } from '../TransportOrder';
import { TransportOrderStatus } from './TransportOrderStatus';

export class TransportOrderStatusPendingStatus extends TransportOrderStatus {
  public displayName = 'Pendiente';

  public internalName = TransportOrderStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}
