import { Container, Location, Task } from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import {
  DefaultScanControlDigitContext,
  ScanControlDigitMachine
} from '../../capa-4/scan-control-digit/scan-control-digit.machine';

import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import { GetContainerByLpnMachine } from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { MaxiConsolidateContainersActions } from './actions';
import { maxiConsolidateContainersGuards } from './guards';
import { maxiConsolidateContainersServices } from './services';

export interface MaxiConsolidateContainersContext {
  task: Task | null;
  orderId: number | null;
  consolidateContainersProcessId: number | null;
  consolidateContainersItemId: number | null;
  consolidateContainersItemsLocation: Location | null;
  palletTruckLocation: Location | null;
  palletTruckType: string | null;
  container1: Container | null;
  container2: Container | null;
  containerUnloadLpn: string | null;
  lastContainerScannedLpn: string | null;
  containersScanned: number;
  stageLocation: Location | null;
  error: string | null;

  consolidateContainersWaveId: number | null;
  originLocation: Location | null;
}

export const DefaultMaxiConsolidateContainersContext = {
  task:                           null,
  orderId:                        null,
  consolidateContainersWaveId:    null,
  consolidateContainersProcessId: null,

  palletTruckLocation: null,
  palletTruckType:     null,

  container1:              null,
  container2:              null,
  containerUnloadLpn:      null,
  lastContainerScannedLpn: null,
  containersScanned:       0,

  error: null,

  stageLocation:  null,
  originLocation: null
};
export const MaxiConsolidateContainersMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QFkCGAPAlgYQPYDtZcAbTCVAFzD3wtU3zACdYA6AZQGNV8GoAFVMWJgKAFSYBXTgGsAxBAJhWDAG64ZytFhpFS5KjToNmbLjz6DhoidJkI1ubhUwEA2gAYAup6+JQAA64sJguBP4g6IgATADMAKysAJzxsR6xSQBsSQAcACx5KQDsADQgAJ6IObGs0UWxefEe2bHR0enxAL6dZdo4BHpklNQExowsrABmopwAFny6JEOGo-TjsACSVAC2sAAyTpSu+ApKKvjqmqx9i-rDRmumUzPz+FC3yyO0jyxbYLsHZzHBwXQ5hfC+XwRIIhcERKIIHLxIqsWIARmieQxaQyaNiOTKlURNTqDSaLTaHW6PRA+FwEDgERuAyWBi+Y1M0OCoWO8MQAFpMoSBYkkmKkkUkviPEk8pkMilur0MP1CKz7qsTBNzLw3lYROIpLIubDeUhIog8tFhYi0awmh4PGi8h4sfFGjkikqQMy1XcVt8tWxphQ5gsWf72T9Njt9mCzYFuXDzQi5SjkTlHfFomiimjMtEhRUqnaHU6XW7Gklvb7BmyHkGONxdVAAPKSCgBDuAo7hc0wnl90AIjHRRLpNEYooF12y+I2nKlx3l11o93xavUoA */
    id:                         'MaxiConsolidateContainers',
    predictableActionArguments: true,
    schema:                     {
      context: {} as MaxiConsolidateContainersContext
    },
    initial: 'ScanningPalletTruck',
    states:  {
      ScanningPalletTruck: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Escanee etiqueta de Transpaleta',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            target:  'FetchingPalletTruckType',
            actions: 'assignPalletTruckLocation'
          }
        }
      },
      FetchingPalletTruckType: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchPalletTruckType',
          onDone: [
            {
              target:  'FetchingWaveContainers',
              actions: ['assignPalletTruckType', 'clearError']
            }
          ],
          onError: {
            target:  'ScanningPalletTruck',
            actions: 'errorInvalidTruckType'
          }
        }
      },
      FetchingWaveContainers: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchWaveContainers',
          onDone: {
            target:  'ScanningContainer1',
            actions: ['assignContainers', 'assignOriginAndStageLocation']
          }
        }
      },
      ScanningContainer1: {
        invoke: {
          id:  'ScanningContainer1',
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando contenedor',
              getRequestedContainerByLpn(
                'Ocurrió un error, por favor reintente más tarde.',
                'Por favor, Escanee el contenedor sugerido',
                ctx.container1!.lpn
              ),
              {
                lpn:  ctx.container1!.lpn,
                hint: 'CONT. REQUERIDO: '
              }
            ),
          onDone: [
            {
              target: 'ScanningContainer2',
              cond:   'doublePalletTruckAndTwoContainers'
            },
            {
              target: 'ScanningControlDigit'
            }
          ]
        },
        exit: 'clearErrorCtx'
      },
      ScanningContainer2: {
        invoke: {
          id:  'ScanningContainer2',
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando contenedor',
              getRequestedContainerByLpn(
                'Ocurrió un error, por favor reintente más tarde.',
                'Por favor, Escanee el contenedor sugerido',
                ctx.container2!.lpn
              ),
              {
                lpn:  ctx.container2!.lpn,
                hint: 'CONT. REQUERIDO: '
              }
            ),
          onDone: [
            {
              target: 'ScanningControlDigit'
            }
          ]
        },
        exit: 'clearErrorCtx'
      },
      ScanningControlDigit: {
        invoke: {
          id:   ScanControlDigitMachine.id,
          src:  ScanControlDigitMachine,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            hint:             'Confirme Ingresando numero verificador',
            requiredLocation: ctx.stageLocation,
            type:             ctx.stageLocation?.locationType
          }),
          onDone: [
            {
              cond:   'singlePalletTruckContainer1WasUnloadAndTwoContainers',
              target: 'UnloadingContainer2'
            },
            {
              target: 'UnloadingContainer1'
            }
          ]
        }
      },
      UnloadingContainer1: {
        invoke: {
          id:   ScanContainerMachine.id,
          src:  ScanContainerMachine,
          data: ctx => ({
            ...DefaultScanContainerContext,
            hint:            'Confirme escaneando etiqueta del contenedor:',
            validContainers: [ctx.container1]
          }),
          onDone: [
            {
              target:  'ScanningContainer2',
              cond:    'simplePalletTruckAndTwoContainers',
              actions: 'assignContainerUnload'
            },
            {
              target:  'UnloadingContainer2',
              cond:    'doublePalletTruckAndTwoContainers',
              actions: 'assignContainerUnload'
            },
            {
              target: 'ConfirmingFinishTransfer'
            }
          ]
        }
      },
      UnloadingContainer2: {
        invoke: {
          id:   ScanContainerMachine.id,
          src:  ScanContainerMachine,
          data: ctx => ({
            ...DefaultScanContainerContext,
            hint:            'Confirme escaneando etiqueta del contenedor',
            validContainers: [ctx.container2]
          }),
          onDone: [
            {
              target: 'ConfirmingFinishTransfer'
            }
          ]
        }
      },
      ConfirmingFinishTransfer: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MaxiConsolidateContainersTaskCompleted',
              payload: {
                consolidateContainersWaveId: ctx.consolidateContainersWaveId,
                locationId:                  ctx.stageLocation?.id
              }
            };
          },
          onDone: 'Finish'
        }
      },
      Finish: {
        type: 'final'
      }
    }
  },
  {
    actions:  MaxiConsolidateContainersActions,
    services: maxiConsolidateContainersServices,
    guards:   maxiConsolidateContainersGuards
  }
);
