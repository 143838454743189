import { StagingAuditingItemStatuses } from '../StagingAuditingItem';
import { StagingAuditingItemStatus } from './StagingAuditingItemStatus';

export class StagingAuditingItemPendingStatus extends StagingAuditingItemStatus {
  public displayName = 'Pendiente';

  public internalName = StagingAuditingItemStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
