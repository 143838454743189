import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { Print } from '../../layer-4/printer/component';
import { TestPrinterMachine } from './machine';

export const TestPrinterTask = withActorRef(TestPrinterMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column'>
        <Ink.Header>Prueba de impresora</Ink.Header>
        <Ink.Text bold>Seleccione una etiqueta</Ink.Text>
        <Ink.Text> </Ink.Text>
        <Ink.Button
          label='Imprimir etiqueta de prueba'
          onClick={() => {
            send({
              type: 'PRINT_TEST'
            });
          }}
          disabled={current.hasTag('loading')}
          id='print-TEST'
          key='print-TEST'
        />
        {current.context.error && (
          <Ink.Text bold color='redBright'>
            {current.context.error}
          </Ink.Text>
        )}{' '}
        <Ink.Text> </Ink.Text>
        <Ink.Text bold>Listo?</Ink.Text>
        <Ink.Button
          label='SALIR'
          onClick={() => {
            send('DONE');
          }}
          disabled={current.hasTag('loading')}
          id='EXIT'
          key='EXIT'
        />
        <Print />
      </Ink.Box>
    );
  }
);
