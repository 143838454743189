import {
  Container,
  ContainerTypes,
  InventoryItem,
  Location,
  OrderItem,
  ProductItem,
  ProductTypesNames,
  SlottingAlgorithm,
  Task,
  TaskTypes
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { api } from '../../../../api/utils/axios-instance';
import {
  CreateContainerMachine,
  createContainerMachineInitialContext
} from '../../capa-4/create-container/CreateContainerMachine';
import { MarkAsIncidentMachine } from '../../capa-4/mark-as-incident/mark-as-incident.machine';
import {
  PlaceItemInContainerMachine,
  placeItemInContainerInitialContext
} from '../../capa-4/place-item-in-container/PlaceItemInContainerMachine';
import {
  RemoveItemFromContainerMachine,
  removeItemFromContainerInitialContext
} from '../../capa-4/remove-item-from-container/RemoveItemFromContainerMachine';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';

import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../../capa-4/scan-item/ScanItemMachine';
import {
  GoToHelpEvent,
  GoToOptionsEvent,
  MenuItemProps
} from '../../core/GenericOptions';

export interface ConsolidateContainersTaskMachineContext {
  task: Task | null;
  payload: any;
  consolidationItems: ProductItem[];
  // consolidatedItems: ProductItem[];
  consolidationLocation: Location | null;
  slottingAlgorithm: SlottingAlgorithm | string | null;

  originContainers: Container[];
  consolidationContainers: Container[];
  container: Container | null | undefined;
  destinationContainer: Container | null | undefined;
  consolidatedItems: OrderItem[];
  orderItem: OrderItem | null;
  item: InventoryItem | null | undefined;

  originLocation: Location;

  granelProduct: boolean;

  parentContainersToSelect: Container[];
  palletParentId: number | null;

  parentContainer: Container | null;

  pendingTasks: Task[];
}

export const DefaultConsolidateContainersTaskTaskMachine = {
  task:               null,
  payload:            null,
  consolidationItems: [],
  moveContainer:      null,
  slottingAlgorithm:  null,

  // Ian created context nombres a definir
  originLocation:        { name: 'GRID 1' } as Location,
  consolidationLocation: { name: 'GRID 2' } as Location,

  originContainers:        [],
  consolidationContainers: [],
  container:               null,
  destinationContainer:    null,
  consolidatedItems:       [],
  orderItem:               null,
  item:                    null,
  granelProduct:           false,

  parentContainersToSelect: [],
  palletParentId:           null,

  parentContainer: null,

  pendingTasks: []
};

const HELP = `Consolidar contenedor
Escanee una ubicación de consolidado y luego el contenedor de origen del cual quiere mover los ítems. Esta tarea permite mover todos los ítems de un contenedor a otro.`;

export const ConsolidateContainersTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCuAXAggdwIYE8BhAewDt1cBLUsAJ1gBVdYBrAOmsvUtwBtKAXmADEiUAAdisLpTJiQAD0QAGADQh8KgL5b1aLHiJkK1Oo2bsAkujq5upKAHkAbnQBKYAMZhK49CXIqGnphTF5eazAAW1gAZXRiWlwYCHlJaW45JEVEACYATny2AEYANnyADgBWAHYK-NzlABYK9U0EAFoKtgBmJrKy6pqmnuVcptKdPQwcAgCTYPNWNgCAM0paKP1Z-GFPMnXN5EpPFmooNKkZLNAlBFLS3LZc4prS4oKWl9K2xFfu8pNfJVXI1Ro9YpVUoVHS6ECkYgQODybaGeZBMxMZacbh8QRgS4ZWSkeR3NQaRD5SZw1FzYwY+hYqw2JL2JyuWgeby+fz00z0QnXEnZO7FJrKNjNKmVCrNPrjX4IJpVNgjCaNXJVfLKnr1JpTEC0oyBflLdhrDZbGaGQWZYW3RA6ti1fJvOo1KrFfJin4UhCy57FHo9fLKMNVCqlLW5A1G9GmplsI1MkhRcS8MA2VLZdJC0mIUo1Iqh4E1ZSF3XlnqKyPOrUNIug0YtWPWukmxZM23E-OdBV+rrFXofV3KHplz2PfWwoA */
  createMachine<ConsolidateContainersTaskMachineContext>(
    {
      id:      TaskTypes.ConsolidateContainersV0,
      context: DefaultConsolidateContainersTaskTaskMachine,
      initial: 'Verify',
      states:  {
        Verify: {
          invoke: {
            src:    'checkAvailableOrderItem',
            onDone: [
              {
                cond:   'finished',
                target: 'Finished'
              },
              {
                actions: ['assignOrderItem', 'assignContainer'],
                target:  'ScanOriginContainer'
              }
            ],
            onError: {
              target: 'GetPendingTasks'
            }
          }
        },
        Finished: {},

        GetPendingTasks: {
          invoke: {
            src:    'findPendingTasks',
            onDone: {
              actions: assign({
                pendingTasks: (ctx, evt) => evt.data.tasks
              }),
              target: 'NoItems'
            }
          }
        },

        NoItems: {
          on: {
            verify: 'Verify'
          }
        },

        ScanOriginContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              validContainers:    [ctx.container],
              suggestedContainer: ctx.container,
              hint:               `Escanee contenedor de picking ${ctx.container?.lpn} en ubicación
            de Consolidación (GRID 1)`
            }),
            onDone: {
              actions: 'assignContainer',
              target:  'ScanItem'
            }
          }
        },
        ScanItem: {
          invoke: {
            src:  ScanItemMachine,
            id:   ScanItemMachine.id,
            data: ctx => ({
              ...DefaultScanItemContext,
              requestedItem: ctx.orderItem?.pickItems[0]?.inventoryItem,
              container:     ctx.container,
              location:      ctx.container?.location,
              validItems:    ctx.orderItem?.pickItems?.map(
                pickItem => pickItem?.inventoryItem
              )
            }),
            onDone: {
              target:  'RemoveItemFromContainer',
              actions: 'assignItem'
            }
          }
        },

        RemoveItemFromContainer: {
          invoke: {
            src:  RemoveItemFromContainerMachine,
            id:   RemoveItemFromContainerMachine.id,
            data: ctx => ({
              ...removeItemFromContainerInitialContext,
              item:      ctx.item,
              container: ctx.container,
              hint:      ''
            }),
            onDone: [
              {
                cond:    'isGranel',
                actions: [
                  'assignItem',
                  assign({
                    granelProduct: _ctx => true
                  })
                ],
                target: 'GetBaldasContainers'
              },
              {
                actions: 'assignItem',
                target:  'GetDestinationContainers'
              }
            ]
          }
        },
        GetDestinationContainers: {
          invoke: {
            src:    'getDestinationContainers',
            onDone: {
              actions: assign({
                consolidationContainers: (_ctx, event) => event.data.containers
              }),
              target: 'SelectDestinationContainer'
            },
            onError: {
              target: 'GetDestinationContainers'
            }
          }
        },

        GetBaldasContainers: {
          invoke: {
            src:    'getDestinationContainersBaldas',
            onDone: {
              actions: assign({
                consolidationContainers: (_ctx, event) => event.data.containers
              }),
              target: 'SelectDestinationContainer'
            }
          }
        },

        SelectDestinationContainer: {
          on: {
            create: [
              {
                cond:   'isGranel',
                target: 'FetchPossibleParentContainers'
              },
              {
                target: 'CreateContainer'
              }
            ],
            select: {
              actions: 'assignDestinationContainer',
              target:  'ScanDestinationContainer'
            }
          }
        },

        FetchPossibleParentContainers: {
          invoke: {
            src:    'getDestinationContainers',
            onDone: {
              actions: assign({
                parentContainersToSelect: (ctx, evt) => evt.data.containers
              }),
              target: 'SelectParentContainer'
            }
          }
        },

        SelectParentContainer: {
          on: {
            select: {
              actions: assign({
                palletParentId: (_ctx, event) => event.data.container.id
              }),
              target: 'CreateContainer'
            },
            create: {
              target: 'CreateParentContainer'
            }
          }
        },

        CreateParentContainer: {
          invoke: {
            src:  CreateContainerMachine,
            id:   CreateContainerMachine.id,
            data: ctx => ({
              ...createContainerMachineInitialContext,
              gridNumber:         2,
              orderId:            ctx.payload?.orderId,
              parentId:           ctx.palletParentId,
              hint:               'Cree contenedor en ubicación GRID 2',
              forceContainerType: true
            }),
            onDone: {
              actions: assign({
                parentContainer: (_ctx, event) => event.data.container
              }),
              target: 'FetchPossibleParentContainers'
            }
          }
        },

        CreateContainer: {
          invoke: {
            src:  CreateContainerMachine,
            id:   CreateContainerMachine.id,
            data: ctx => ({
              ...createContainerMachineInitialContext,
              gridNumber:    2,
              orderId:       ctx.payload?.orderId,
              parentId:      ctx.palletParentId,
              containerType: ctx.granelProduct
                ? { name: ContainerTypes.BALDA }
                : { name: ContainerTypes.PALLET },
              hint:               'Cree contenedor de despacho en ubicación de Sorting (GRID 2)',
              forceContainerType: true
            }),
            onDone: {
              actions: [
                'assignDestinationContainer',
                'addConsolidationContainer'
              ],
              target: 'SelectDestinationContainer'
            }
          }
        },
        ScanDestinationContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              validContainers: [ctx.destinationContainer]
            }),
            onDone: {
              actions: 'assignDestinationContainer',
              target:  'PlaceItemIntoContainer'
            }
          }
        },
        PlaceItemIntoContainer: {
          invoke: {
            src:  PlaceItemInContainerMachine,
            id:   PlaceItemInContainerMachine.id,
            data: ctx => ({
              ...placeItemInContainerInitialContext,
              orderItem:          ctx.orderItem,
              requestedContainer: ctx.destinationContainer,
              container:          ctx.destinationContainer,
              item:               ctx.item
            }),
            onDone: {
              target: 'Verify'
            }
          }
        },

        ConfirmConsolidation: {
          on: {
            confirmReceiptVerification: {
              target: 'ConfirmingTask'
            },
            skipTaskConfirmation: {
              target: 'ConsolidationComplete'
            }
          }
        },
        ConfirmingTask: {
          invoke: {
            src:    'completeTask',
            data:   ctx => ctx,
            onDone: {
              target: 'ConsolidationComplete'
            }
          }
        },

        MarkAsIncident: {
          invoke: {
            src:  MarkAsIncidentMachine,
            id:   MarkAsIncidentMachine.id,
            data: ctx => ({
              orderId:       ctx.orderItem?.orderId,
              location:      ctx.container?.location,
              suggestedItem: ctx.orderItem?.pickItems[0].inventoryItem
            }),
            onDone: 'Verify'
          }
        },

        ConsolidationComplete: {
          type: 'final'
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        },

        goToOptions: {
          actions: 'triggerMenuScreen'
        },

        markIncident: 'MarkAsIncident'
      }
    },
    {
      guards: {
        ...UtilityGuards,
        consolidationLocationIsNotSet: ctx => !ctx.consolidationLocation,
        linesRemaining:                ctx => ctx.consolidationItems?.length > 1,
        finished:                      (ctx, event) => event.data.item.status === 'completed',
        isGranel:                      ctx =>
          ctx.orderItem?.product?.productType?.name === ProductTypesNames.Granel
      },
      actions: {
        ...UtilityActions,
        assignContainer: assign({
          container: (_ctx, event) =>
            (event.data?.item?.pickItems &&
              event.data.item?.pickItems[0]?.inventoryItem?.container) ||
            event.data.container
        }),
        addConsolidationContainer: assign({
          consolidationContainers: (ctx, event): Container[] => [
            ...ctx.consolidationContainers,
            event.data.container
          ]
        }),
        assignDestinationContainer: assign({
          destinationContainer: (_ctx, event) => event.data.container
        }),
        assignOrderItem: assign({
          orderItem: (_ctx, event) => event.data.item
        }),
        updateContext: assign({
          // TODO: Revisar Logica que saca la linea pickeada.
          consolidationItems: (context, event) => {
            return [
              ...context.consolidationItems?.filter(
                item => item.id !== event.data.item.id
              )
            ];
          },
          consolidatedItems: (context, event) => [
            ...context.consolidatedItems,
            event.data.item
          ]
          // item: ctx => ctx.consolidationItems.pop()
        }),
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP),
        triggerMenuScreen: (
          ctx,
          { send, triggerMenuScreen }: GoToOptionsEvent
        ) => {
          const options: MenuItemProps[] = [];

          if (ctx.container && ctx.container.location) {
            options.push({
              label:   'Marcar Incidencia',
              onClick: () => send('markIncident')
            });
          }

          triggerMenuScreen(options);
        }
      },
      services: {
        ...API,
        checkAvailableOrderItem: async ctx => {
          const { data: item } = await api.get(
            `/order/${ctx.payload.orderId}/check-cas?processInstanceId=${
              ctx.task!.processInstanceId
            }`
          );

          return { item };
        }
      }
    }
  );
