import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { Provider } from './Provider';
import { Warehouse } from './Warehouse';

@Entity()
export class StockTransferLogic {
  @PrimaryKey()
  id: number;

  @Field({ name: 'sap_doc_entry' })
  sapDocEntry: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => InventoryItem, 'stockTransferLogicId')
  stockTransferLogicItems: InventoryItem[];

  @ForeignKey(() => Provider, { name: 'provider_id' })
  providerId: number;

  @Knows(() => Provider, 'providerId')
  provider: Provider;

  @ForeignKey(() => Warehouse, { name: 'from_warehouse_id' })
  fromWarehouseId: number;

  @Knows(() => Warehouse, 'fromWarehouseId')
  fromWarehouse: Warehouse;

  @ForeignKey(() => Warehouse, { name: 'to_warehouse_id' })
  toWarehouseId: number;

  @Knows(() => Warehouse, 'toWarehouseId')
  toWarehouse: Warehouse;

  @Field({ name: 'comment_1' })
  comment1: string;

  @Field({ name: 'comment_2' })
  comment2: string;
}
