import { locationAttributeName } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MaxiPickingWaveContext } from './machine';

type MaxiPickingWaveServices = MachineOptions<
  MaxiPickingWaveContext,
  any
>['services'];

export const maxiPickingWaveServices: MaxiPickingWaveServices = {
  fetchSuggestedContainerType: async (ctx, _evt) => {
    const { data } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/suggested-container-type`
    );
    return {
      containerType: data
    };
  },
  fetchNextPickItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/next-pick-item`
    );
    return {
      pickItem: data
    };
  },
  fetchPickItemsQuantity: async (ctx, _evt) => {
    const { data } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/pending-pick-items-splitted`
    );
    return {
      totalItems:  data.totalItems,
      pickedItems: data.pickedItems
    };
  },
  fetchOutputLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/output-location`
    );
    return {
      outputLocation: data
    };
  },
  getHasAlreadyBeenPicked: async (_ctx, evt) => {
    // Funcion pasamanos, para que la cond tenga populado el evt
    await Promise.resolve(true);
    return {
      pickItem: evt.data.pickItem
    };
  },
  checkIfHasMoreContainers: async (ctx, _evt) => {
    await Promise.resolve();

    if (ctx.containersLeftToUnload.length > 0) {
      return true;
    }
    return false;
  },
  fetchPalletTruckType: async (ctx, _evt) => {
    const { data } = await api.get(
      `/location/${ctx.palletTruck?.id}/location-attribute?name=${locationAttributeName.PALLETTRUCKTYPE}`
    );

    return {
      palletTruckType: data
    };
  },
  validPalletTruckType: async (_ctx, _evt) => {
    await Promise.resolve(false);
  },
  validateContainer: async (ctx, _evt) => {
    const { data } = await api.get(`container/verify-lpn/${ctx.containerlpn}`);
    return data;
  }
};
