import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusDispatchedStatus extends OrderStatus {
  public displayName = 'Despachado';

  public internalName = OrderStatuses.Dispatched;

  public statusColor = '#f3ecfe';

  public textColor = '#8b4df3';
}
