import { AuditOrderStatuses } from '../AuditOrder';
import { AuditOrderStatus } from './AuditOrderStatus';

export class AuditOrderStatusCompletedStatus extends AuditOrderStatus {
  public displayName = 'Completada';

  public internalName = AuditOrderStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
