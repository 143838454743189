import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { MopSorterExitItem } from './MopSorterExitItem';
import { OrderBatch } from './OrderBatch';
import {
  MopSorterExitProcessCancelledStatus,
  MopSorterExitProcessCompletedStatus,
  MopSorterExitProcessPendingStatus,
  MopSorterExitProcessProcessingStatus,
  MopSorterExitProcessStatus
} from './mop-sorter-exit-process-status';

export const MopSorterExitProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class MopSorterExitProcess {
  @PrimaryKey()
  id: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Status({
    [MopSorterExitProcessStatuses.Pending]:    MopSorterExitProcessPendingStatus,
    [MopSorterExitProcessStatuses.Processing]:
      MopSorterExitProcessProcessingStatus,
    [MopSorterExitProcessStatuses.Completed]:
      MopSorterExitProcessCompletedStatus,
    [MopSorterExitProcessStatuses.Cancelled]:
      MopSorterExitProcessCancelledStatus
  })
  status: MopSorterExitProcessStatus;

  @Knows(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(() => MopSorterExitItem, 'mopSorterExitProcessId')
  mopSorterExitItems: MopSorterExitItem[];
}
