import { AuditingWaveStatuses } from '../AuditingWave';
import { AuditingWaveStatus } from './AuditingWaveStatus';

export class AuditingWaveCompletedStatus extends AuditingWaveStatus {
  public displayName = 'Completado';

  public internalName = AuditingWaveStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
