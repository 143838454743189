import { Container } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import {
  CustomFetchResultError,
  fetchResult
} from '../../../../../../utils/fetch-result';
import { GetContainerByLpnFn } from '../machine';

export function getRequestedContainerByLpn(
  defaultErrorMessage: string,
  lpnMismatchErrorMessage: string,
  requestedContainerLpn: string
): GetContainerByLpnFn {
  return fetchResult(defaultErrorMessage, lpn => {
    if (lpn === requestedContainerLpn) {
      return api.get<Container>(`container/lpn/${lpn}`);
    } else {
      throw new CustomFetchResultError(lpnMismatchErrorMessage);
    }
  });
}
