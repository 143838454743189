import { MachineOptions, assign } from 'xstate';
import { AuditingTaskAssignerContext } from './machine';

type AuditingTaskAssignerActions = MachineOptions<
  AuditingTaskAssignerContext,
  any
>['actions'];

export const auditingTaskAssignerActions: AuditingTaskAssignerActions = {
  assignAuditingContainer: assign({
    container: (_ctx, event) => event.data
  }),
  assignAuditingLocation: assign({
    location: (_ctx, event) => event.data
  }),
  assignErrorMessage: assign({
    errorMessage: (_ctx, event) => event.data.response.data.errorView
  })
};
