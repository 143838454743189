import React, { FC, createContext, useMemo } from 'react';
import { AnyStateMachine, InterpreterFrom, Prop, StateFrom } from 'xstate';
import { RootMachine } from '../../../tasks/core/RootMachine';

export interface RootMachineApi<
  T extends AnyStateMachine = typeof RootMachine
> {
  state: StateFrom<T>;
  send: Prop<InterpreterFrom<T>, 'send'>;
  interpreter: InterpreterFrom<T>;
}

export const rootMachineContext = createContext({} as RootMachineApi);
rootMachineContext.displayName = 'rootMachineContext';

export const RootMachineProvider: FC<RootMachineApi> = ({
  state,
  send,
  interpreter,
  children
}) => {
  const value = useMemo(
    () => ({ state, send, interpreter }),
    [state, send, interpreter]
  );

  return (
    <rootMachineContext.Provider value={value}>
      {children}
    </rootMachineContext.Provider>
  );
};

RootMachineProvider.displayName = 'RootMachineProvider';
