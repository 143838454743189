import { ConsolidateContainersProcessStatuses } from '../ConsolidateContainersProcess';
import { ConsolidateContainersProcessStatus } from './ConsolidateContainersProcessStatus';

export class ConsolidateContainersProcessStatusProcessing extends ConsolidateContainersProcessStatus {
  public displayName = 'Procesando';

  public internalName = ConsolidateContainersProcessStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
