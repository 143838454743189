import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanQTY } from '../../capa-4/scan-item/EnterQuantity';
import { FractionateContainerTaskMachine } from './FractionateContainerTaskMachine';
import { ScanPieces } from './ScanPieces';

export const FractionateContainerTask: FC = withActorRef(
  FractionateContainerTaskMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const handleClick = useCallback((id: string) => {
    send({ type: 'select', data: { id } });
  }, []);

  return (
    <Ink.Box flexDirection='column' paddingLeft={1}>
      <Ink.Box>
        <Ink.Header>
          <Ink.Text bold>
            {!current.context.hint ? (
              'FRACCIONANDO CONTENEDOR'
            ) : (
              <>
                FRACCIONANDO CONTENEDOR:{' '}
                <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
              </>
            )}
          </Ink.Text>
        </Ink.Header>
      </Ink.Box>

      {current.matches('Initializing') ? (
        <Ink.Text>Cargando...</Ink.Text>
      ) : null}

      {current.matches('SelectItem') ? (
        <>
          <Ink.Text>Seleccione un ítem para fraccionar</Ink.Text>
          <Ink.Text>
            Contenedor:
            {current.context.containerLpn ? (
              <Ink.Chalk greenBright>
                {current.context.containerLpn as any}
              </Ink.Chalk>
            ) : (
              <Ink.Chalk greenBright>
                {current.context.containerId as any}
              </Ink.Chalk>
            )}
          </Ink.Text>
          <Ink.Box flexDirection='column'>
            {current.context.items.map((item, i) => (
              <Ink.Button
                key={item.id}
                id={item.id.toString()}
                label={
                  item.lot
                    ? `${i + 1}. ${item.quantity} x ${
                        item.product.name
                      } - Lote ${item.lot?.lotNumber}`
                    : `${i + 1}. ${item.quantity} x ${item.product.name}`
                }
                onClick={handleClick}
              />
            ))}
          </Ink.Box>
        </>
      ) : null}

      {current.matches('EnterPieces') ? (
        <>
          <Ink.Text>
            Ítem seleccionado:
            {current.context.inventoryItem ? (
              <Ink.Chalk greenBright>
                {current.context.inventoryItem.sku +
                  ' - ' +
                  current.context.inventoryItem.product.name}
              </Ink.Chalk>
            ) : null}
          </Ink.Text>
          {current.context.inventoryItem &&
          current.context.inventoryItem.lot ? (
            <Ink.Text>
              Lote:
              <Ink.Chalk greenBright>
                {current.context.inventoryItem.lot.lotNumber}
              </Ink.Chalk>
            </Ink.Text>
          ) : null}
          <Ink.Text>
            Cantidad:
            {current.context.inventoryItem ? (
              <Ink.Chalk greenBright>
                {current.context.inventoryItem.quantity}
              </Ink.Chalk>
            ) : null}
          </Ink.Text>
          <ScanPieces
            piecesEntered={pieces =>
              send({ type: 'piecesEntered', data: { pieces } })
            }
            fracQty={
              current.context.inventoryItem
                ? current.context.inventoryItem.product.fracWeight
                : null
            }
            suggestedPieces={current.context.suggestedPieces}
          />
        </>
      ) : null}

      {current.matches('EnterRemainingQty') ? (
        <>
          <Ink.Text>
            Si sobró cantidad con la que no se puede armar otra bolsa de la
            cantidad pedida, pongala toda en una bolsa y indique su cantidad{' '}
            <Ink.Text bold contents>
              en GRAMOS
            </Ink.Text>
            .
          </Ink.Text>
          <Ink.Text>De lo contrario, ingrese 0.</Ink.Text>
          <ScanQTY
            suggestedItem={null}
            quantityEntered={remQty =>
              send({ type: 'remQtyEntered', data: { remQty } })
            }
          />
        </>
      ) : null}

      {current.matches('FatalError') ? (
        <>
          <Ink.Text>
            <Ink.Chalk redBright>{current.context.error as any}</Ink.Chalk>
          </Ink.Text>
          <Ink.Button
            key={1}
            id='1'
            label='Reintentar'
            onClick={() => send('retry')}
          />
        </>
      ) : null}
    </Ink.Box>
  );
});
