import { ReceiptStatus } from './ReceiptStatus';

export class ReceiptStatusStoringStatus extends ReceiptStatus {
  public displayName = 'Almacenando';

  public internalName = 'storing';

  public statusColor = '#fbe7d9';

  public textColor = '#ea881b';
}
