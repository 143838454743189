import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { BigPickingWaveContext } from './machine';

type BigPickingWaveServices = MachineOptions<
  BigPickingWaveContext,
  any
>['services'];

export const bigPickingWaveServices: BigPickingWaveServices = {
  fetchPickItemAssociatedToPickingStagingItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/picking-to-staging-item`
    );

    return data;
  },
  fetchNextPickItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/next-pick-item`
    );
    if (data) {
      console.log(
        `%c Check Digits de: ${data.product.name}`,
        'color: green; font-size: 24px; font-weight: bold;'
      );
      console.log(
        `%c InventoryItem: ${data?.inventoryItem.location.name} - "${data?.inventoryItem.location.checkDigit}" `,
        'color: red; font-size: 20px'
      );
      console.log(
        `%c Staging: ${data?.orderItem.order.appointment.staging.name} - "${data?.orderItem.order.appointment?.staging.checkDigit}" `,
        'color: blue; font-size: 20px'
      );
    }
    return data;
  },
  fetchPendingPickItems: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/pending-pick-items-splitted`
    );

    return {
      totalItems:  data.totalItems,
      pickedItems: data.pickedItems
    };
  },
  fetchClampCapacity: async (ctx, _evt) => {
    const { data } = await api.get(
      `pick-item/${ctx.currentPickItem?.id}/max-clamp-capacity`
    );

    return {
      maxClampCapacity: data
    };
  },
  fetchSuggestedPickQuantity: async (ctx, _evt) => {
    await Promise.resolve();
    const pickItemQuantity = ctx.currentPickItem?.quantity as number;
    const clampCapacity = ctx.maxClampCapacity as number;
    if (pickItemQuantity > clampCapacity) {
      return clampCapacity;
    } else {
      return pickItemQuantity;
    }
  }
};
