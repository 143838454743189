import Ink from '@ink';
import { useActor } from '@xstate/react';
import { isAlphanumeric } from 'class-validator';
import React, { FC, Reducer, useReducer } from 'react';
import { withActorRef } from '../../../../../shared/Machine';
import { ScanItem } from '../../../../capa-4/scan-item/ScanItem';
import { RejectItemMachine } from './RejectItemMachine';

export const RejectItem: FC = withActorRef(RejectItemMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const onSubmit = (reason: string) => {
      send({ type: 'setRejectionReason', reason });
    };

    const [inputReason, setReason] = useReducer<Reducer<string, string>>(
      (prev, next) => {
        if (next !== '') {
          // setError('');
        }
        return next === '' || isAlphanumeric(next) ? next : prev;
      },
      ''
    );

    return (
      <Ink.Box flexDirection='column' paddingTop={1}>
        <>
          {current.matches('ScanItem') ? <ScanItem /> : null}

          {current.matches('AddRejectionReason') ? (
            <>
              <Ink.Text>Ingrese motivo de rechazo</Ink.Text>

              <Ink.TextInput
                value={inputReason}
                onChange={setReason}
                onSubmit={onSubmit}
              />
            </>
          ) : null}
        </>
      </Ink.Box>
    );
  }
);
