import { PickingProcessStatus } from './PickingProcessStatus';

export class PickingProcessStatusPendingStatus extends PickingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = 'pending';

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
