import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusRepairedStatus extends ReceiptItemStatus {
  public displayName = 'Subsanado';

  public internalName = ReceiptItemStatuses.Repaired;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
