import Ink from '@ink';
import { OrderType, OrderTypes } from '@wms/domain';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { ScanProductIdentifier } from '../../capa-4/scan-product/ScanProductIdentifier';
import { Print } from '../../layer-4/printer/component';
import { MidiPickingWavePrinting } from './components/printing';
import { MidiPickingWaveMachine } from './machine';

export const MidiPickingWaveTask = withActorRef(MidiPickingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const getDisplayName = (orderType: OrderType): string => {
      switch (orderType.name) {
        case OrderTypes.WMSRetailHD:
          return 'HD';

        case OrderTypes.WMSRetailSS:
        case OrderTypes.StoreSupplySellers:
          return 'AB TIE';

        default:
          return 'N/A';
      }
    };

    return (
      <Ink.Box paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            Picking Ola N° {current.context.pickingWaveId || 'N/A'} - PickTower
          </Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column'>
          {/* Marking all the 'Loading' states here */}
          {![
            'Initializing',
            'FetchingSuggestedItem',
            'MarkingAsPicked',
            'MarkingAsInSorter',
            'PrePrinting',
            'CompletingPickingWave',
            'ConfirmingScreen',
            'Done'
          ].includes(current.value.toString()) ? (
            <>
              <Ink.Text>
                SKU {current.context.currentPickItem.pickOrder} de{' '}
                {current.context.totalItems} -{' '}
                {getDisplayName(
                  current.context.currentPickItem.orderItem.order.orderType
                )}
              </Ink.Text>

              {current.matches('ScanLocation') && (
                <>
                  <Ink.Text>
                    {current.context.currentPickItem.sku} -{' '}
                    {current.context.currentPickItem.product.name}
                  </Ink.Text>
                  <Ink.Text>Trasládese a la siguiente ubicación:</Ink.Text>
                  <Ink.Chalk greenBright bold>
                    {current.context.currentPickItem.inventoryItem.location
                      .name || 'N/A'}
                  </Ink.Chalk>
                </>
              )}
              {current.matches('ScanItem') && (
                <>
                  <Ink.Text> </Ink.Text>
                  <Ink.Text>Escanee CB del producto:</Ink.Text>
                  <Ink.Chalk greenBright bold>
                    {`${current.context.currentPickItem.sku} - ${current.context.currentPickItem.product.name}`}
                  </Ink.Chalk>
                </>
              )}

              {(current.matches('SelectingQuantity') ||
                current.matches('PrintingInternalSupply')) && (
                <>
                  <Ink.Text> </Ink.Text>
                  <Ink.Text>
                    Tome
                    <Ink.Box paddingLeft={1} paddingRight={1}>
                      <Ink.Chalk greenBright bold>
                        {current.context.currentPickItem.quantity}
                      </Ink.Chalk>
                    </Ink.Box>
                    unidades de
                  </Ink.Text>
                  <Ink.Chalk greenBright bold>
                    {`${current.context.currentPickItem.sku} - ${current.context.currentPickItem.product.name}`}
                  </Ink.Chalk>
                </>
              )}

              {current.hasTag('printing') && (
                <Ink.Box>
                  <Print />
                </Ink.Box>
              )}

              {current.matches('DepositingInSorter') && (
                <>
                  <Ink.Text>
                    Etiqueta{' '}
                    {current.context.currentPickItem.quantity -
                      current.context.pendingItemsToSort +
                      1}{' '}
                    de {current.context.currentPickItem.quantity}
                  </Ink.Text>
                  <Ink.Text>Escanee etiqueta del producto:</Ink.Text>
                  <Ink.Chalk greenBright bold>
                    {`${current.context.currentPickItem.sku} - ${current.context.currentPickItem.product.name}`}
                  </Ink.Chalk>
                </>
              )}

              {current.matches('ProductInfoMismatch') && (
                <>
                  <Ink.Text> </Ink.Text>
                  <Ink.Text>
                    La cantidad tomada y la requerida no coinciden
                  </Ink.Text>
                </>
              )}

              <Ink.Text> </Ink.Text>
            </>
          ) : (
            !current.matches('ConfirmingScreen') && (
              <Ink.Text>Cargando...</Ink.Text>
            )
          )}

          {/* Below separator */}
          {current.matches('ScanLocation') && <ScanLocation />}

          {current.matches('ScanItem') && <ScanProductIdentifier />}

          {(current.matches('PrintingHomeDelivery') ||
            current.matches('PrintingInternalSupply')) && (
            <MidiPickingWavePrinting actorRef={actorRef} />
          )}

          {current.matches('SelectingQuantity') && <EnterQuantity />}

          {current.matches('DepositingInSorter') && <ScanLabel />}

          {current.matches('ProductInfoMismatch') && (
            <>
              <Ink.Text bold>¿Continuar o reportar incidencia?</Ink.Text>
              <Ink.Button
                onClick={() => {
                  send('continue');
                }}
                label='Continuar'
              />
              <Ink.Button
                onClick={() => {
                  send('report');
                }}
                label='Reportar'
              />
            </>
          )}

          {current.context.error && (
            <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
          )}

          {current.matches('ConfirmingScreen') && (
            <>
              <Ink.Text bold>
                ¿Estas seguro que quieres terminar la Ola de Pickeo #
                {current.context.pickingWaveId}?
              </Ink.Text>
              <Ink.Box flexDirection='row' justifyContent='space-between'>
                <Ink.Button
                  style={{ width: '40%' }}
                  onClick={() => {
                    send('confirm');
                  }}
                  label='Si'
                />
                <Ink.Button
                  style={{ width: '40%' }}
                  onClick={() => {
                    send('cancel');
                  }}
                  label='No'
                />
              </Ink.Box>
            </>
          )}
        </Ink.Box>
      </Ink.Box>
    );
  }
);
