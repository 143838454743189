import {
  Container,
  InventoryItem,
  Location,
  OrderItem,
  ProductItemName,
  PutAwayItem
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { GoToOptionsEvent } from '../../core/GenericOptions';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../scan-container/ScanContainerMachine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../scan-item/ScanItemMachine';

export interface PlaceItemInContainerTaskContext {
  fromLocation: Location | null;
  requestedContainer: Container | null;
  requiredLocation: Location | null;
  location: Location | null;

  container: Container | null;
  item: PutAwayItem | InventoryItem | null;

  type: string;

  orderItem: OrderItem | null;

  hint: string | null;
  error: string | null;
}

export const placeItemInContainerInitialContext: PlaceItemInContainerTaskContext =
  {
    fromLocation: null,

    requestedContainer: null,
    requiredLocation:   null,
    location:           null,

    container: null,
    item:      null,

    type: ProductItemName.InventoryItem,

    orderItem: null,

    hint:  'Añade un item al contenedor',
    error: ''
  };

export const PlaceItemInContainerMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFkD2A3MBhVA7ALgIYCWuYATgHSnH7GEA2xAXmAMSKgAOqstxeTiAAeiALQBGAEyUAHBIAsAThUB2WVIBsspQFYFAGhABPcQtkBfC0bSYcBEmSqEA7iTq4o9oqQoBlAGNCXDYsAHkAOQAVAEEASQiAUQAlAH0-LBiIpIARIR4+OkEkE3EAZiUyuVVdXWklAAYdXRUjUQQKmWlNKVUFfoUy1U0JKxsMbDwfJ0pkuFQGdFIoAHVUcgAzBlQXDhKC-mLQdtldVUpdKTKGmqVVGqlZI1MEMV6GynMpc0qFKQaGo8xiBbJMHL4qAAxMD4AIAC2WfgArlAYLB8JAADKoIJFEIQPBgai4dCoADWRNB3kcFEo0NhCM8yNRcAxEGxuIEuAQpFJnLwAG0GgBdfK8Q64ITtCTSWSUPRaDSaG5-czPRCKKoAvSqCSVMqaTTDYFUqY0qEw+GIlFotkcwh4tgUcjrShcBgOjbrAC2lFN4Jm9KtTJtrKxOIdXJ5JIjeKFov24rxUo1ZTOn3MDRlZSktU0l3VHR0cgkZQNAKzOm+Jom1IhlFc7mW9rxgWCbBbXLbuDIEDFhS5KY6Mso+dUlU0-VUj11qkLUiuF36+aUpaUUlluhrdjN9fQjGIEEjnjYBLIxNJFL9td3M33TCPHig0b5kcFIv7EqEL16mkoVzqWp1AaPRZAaXQ2kQU4qm0BRLl0AEpEaHptzBaZaXvQ9jygJ1yBdKh3U9H1rx3AMMIPR9lhfWMuXjT9kxKE4RzuMowM0dcGgNJRDFKBBNUobV9CGBClBGJQrGsEBcFQCA4CEf10KoGg6APVh6MHRjxD1BRKEUDdalkZRdEMm5CzEXQ-zKBQJBuMsAR6WR5FQusZkbfhPBc-wgklRMByOEQNRaShWPMMCFCzcLVCGMyKiqWQajqJCmj0FRnNvWk5lgBYlk8NZNm2Fx1P89oRnOfMJH0BRNDLX4nl4yRGkoe4004hdWMaaQ0rIi0GWtFl0XDfkfO4JMNOORBRPOECoruJo4Nasy4IuZVwokdRlF1VLJIU80GzcdyoE7PBuyK4aAoQJDdF0hRVCzYY9PXKyzMamzLlueDhh0LrFMoTDKM8U6h0nK6DWuJRZBGUsGiq+dhn-NMKrOMDQPA77ds88hQT7Xyv00viJHzUd12UR4kPzXQylhv8AJlCzV0neRNDRiFAbxt4JDlPTEcM0DIsWw05CqxzTkaS7VAkiwgA */
  createMachine<PlaceItemInContainerTaskContext>(
    {
      id:      'PlaceItemInContainer',
      initial: 'Initialize',
      states:  {
        Initialize: {
          always: {
            actions: assign({
              container: (context: PlaceItemInContainerTaskContext) =>
                context.requestedContainer
            }),
            target: 'AwaitingContainerScan'
          }
        },
        AwaitingContainerScan: {
          always: [
            {
              cond:   'containerIsSet',
              target: 'AwaitingItemScan'
            }
          ],
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              suggestedContainer: ctx.requestedContainer
            }),
            onDone: [
              {
                actions: ['assignContainer'],
                cond:    'itemIsSet',
                target:  'Validating'
              },
              {
                actions: ['assignContainer'],
                target:  'AwaitingItemScan'
              }
            ],
            onError: {
              actions: 'assignError',
              target:  'AwaitingContainerScan'
            }
          }
        },
        AwaitingItemScan: {
          always: {
            cond:   'itemIsSet',
            target: 'Validating'
          },
          invoke: {
            src:  ScanItemMachine,
            id:   ScanItemMachine.id,
            data: ctx => ({
              ...DefaultScanItemContext,
              location:      ctx.location || ctx.container?.location,
              // container:     ctx.container,
              suggestedItem: ctx.item,
              container:     ctx.item?.container
            }),
            onDone: {
              actions: 'assignItem',
              target:  'Validating'
            }
          }
        },
        Validating: {
          always: [
            {
              cond:   'validate',
              target: 'AddItemToContainer'
            },
            {
              target: 'AwaitingItemScan'
            }
          ]
        },

        AddItemToContainer: {
          invoke: {
            src:    'addItemToContainer',
            onDone: [
              {
                actions: 'assignItem',
                target:  'ItemAddedToContainer'
              }
            ],
            onError: [
              {
                actions: ['assignError', 'resetItem'],
                target:  'FatalError'
              }
            ]
          }
        },
        FatalError: {
          type: 'final'
        },
        ItemAddedToContainer: {
          type: 'final',
          data: ctx => ({ container: ctx.container, item: ctx.item })
        }
      },
      on: {
        goToOptions: {
          actions: 'triggerMenuScreen'
        },
        markProductIncident: {}
      }
    },
    {
      guards: {
        ...UtilityGuards,
        validate: ctx => {
          const { item, container } = ctx;

          return !item?.containerId || item?.containerId !== container?.id;
        },
        containerIsSet:        ctx => !!ctx.requestedContainer,
        itemIsSet:             ctx => !!ctx.item,
        containerAndItemIsSet: ctx => !!ctx.requestedContainer && !!ctx.item
      },
      actions: {
        ...UtilityActions,
        assignContainer: assign({
          container: (_ctx, event) => event.data.container
        }),
        triggerMenuScreen: (
          ctx,
          { send, triggerMenuScreen }: GoToOptionsEvent
        ) => {
          triggerMenuScreen([
            {
              label:   'INCIDENTES',
              onClick: () => send('markProductIncident')
            }
          ]);
        }
      },
      services: {
        ...API
      }
    }
  );
