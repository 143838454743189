import {
  Entity,
  Field,
  ForeignKey,
  IsNotEmpty,
  Knows,
  MaxLength,
  MinLength,
  NeededBy,
  PrimaryKey
} from '@skyframe/core';
import { ApiField } from '../decorators';
import { Address } from './Address';
import { Appointment } from './Appointment';
import { Configuration } from './Configuration';
import { Incident } from './Incident';
import { InventoryCounting } from './InventoryCounting';
import { Location } from './Location';
import { Order } from './Order';
import { OrderBatch } from './OrderBatch';
import { PickingAlgorithm } from './PickingAlgorithm';
import { Printer } from './Printer';
import { PurchaseOrder } from './PurchaseOrder';
import { PurchaseOrderItem } from './PurchaseOrderItem';
import { Receipt } from './Receipt';
import { Reservation } from './Reservation';
import { StockTable } from './StockTable';
import { StockTransferLogic } from './StockTransferLogic';
import { TaskBucket } from './TaskBucket';
import { TaskRole } from './TaskRole';
import { TransferOrder } from './TransferOrder';
import { User } from './User';

// @TODO: This should be a related entity.
export enum WarehouseType {
  DistributionCenter = 'distribution_center',
  BranchOffice = 'branch_office'
}

export const WarehouseTypeView = {
  DistributionCenter: {
    displayName:  'Centro de Distribución',
    internalName: WarehouseType.DistributionCenter
  },
  BranchOffice: {
    displayName:  'Sucursal',
    internalName: WarehouseType.BranchOffice
  }
};

const WMS_WH_CODE_MIN_LENGTH = 1;
const WMS_WH_CODE_MAX_LENGTH = 16;

@Entity()
export class Warehouse {
  @ApiField()
  @PrimaryKey()
  id: number;

  @Field({ name: 'cot_sequence' })
  cotSequence: number;

  @ApiField()
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field()
  name: string;

  @ApiField()
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @MinLength(WMS_WH_CODE_MIN_LENGTH, {
    message: ({ value, constraints }) =>
      value ? `La longitud mínima es ${constraints[0]}` : ''
  })
  @MaxLength(WMS_WH_CODE_MAX_LENGTH, {
    message: ({ value, constraints }) =>
      value ? `La longitud máxima es ${constraints[0]}` : ''
  })
  @Field({ name: 'wms_wh_code' })
  wmsWhCode: string;

  @ApiField()
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'warehouse_type' })
  warehouseType: WarehouseType;

  @ApiField()
  @ForeignKey(() => Location, { name: 'cross_dock_id' })
  crossDockId: number;

  @ApiField()
  @ForeignKey(() => Location, { name: 'anomaly_section_id' })
  anomalySectionId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => Location, 'warehouseId')
  locations: Location[];

  @NeededBy(() => Reservation, 'warehouseId')
  reservations: Reservation[];

  @Knows(() => Location, 'crossDockId')
  crossDock: Location;

  @Knows(() => Location, 'anomalySectionId')
  anomalySection: Location;

  @NeededBy(() => Configuration, 'warehouseId')
  configuration: Configuration;

  @NeededBy(() => Appointment, 'warehouseId')
  appointments: Appointment[];

  @Knows(() => User, 'warehouseId')
  users: User[];

  @Knows(() => StockTransferLogic, 'fromWarehouseId')
  stockTransferLogicOrigins: StockTransferLogic[];

  @Knows(() => StockTransferLogic, 'toWarehouseId')
  stockTransferLogicDestinies: StockTransferLogic[];

  @NeededBy(() => Incident, 'warehouseId')
  incident: Incident[];

  @NeededBy(() => PurchaseOrderItem, 'warehouseId')
  purchaseOrderItems: PurchaseOrderItem[];

  @NeededBy(() => Printer, 'warehouseId')
  printers: Printer[];

  @NeededBy(() => StockTable, 'warehouseId')
  stockTable: StockTable;

  @ApiField()
  @ForeignKey(() => Address, { name: 'address_id' })
  addressId: number;

  @Knows(() => Address, 'addressId')
  address: Address;

  @Knows(() => Receipt, 'warehouseId')
  receipts: Receipt[];

  @Knows(() => Order, 'warehouseId')
  orders: Order[];

  @NeededBy(() => TransferOrder, 'fromWarehouseId')
  outboundTransferOrders: TransferOrder[];

  @NeededBy(() => TransferOrder, 'toWarehouseId')
  inboundTransferOrders: TransferOrder[];

  @Knows(() => PurchaseOrder, 'warehouseId')
  purchaseOrders: PurchaseOrder[];

  @NeededBy(() => TaskBucket, 'warehouseId')
  taskBucket: TaskBucket;

  @NeededBy(() => OrderBatch, 'warehouseId')
  orderBatches: OrderBatch[];

  @NeededBy(() => TaskRole, 'warehouseId')
  taskRoles: TaskRole[];

  @Knows(() => PickingAlgorithm, 'warehouseId')
  pickingAlgorithms: PickingAlgorithm[];

  @Knows(() => Warehouse, 'parentId')
  parent: Warehouse;

  @Knows(() => Warehouse, 'parentId')
  children: Warehouse[];

  @ForeignKey(() => Warehouse, { name: 'parent_id' })
  parentId: number;

  @NeededBy(() => InventoryCounting, 'warehouseId')
  inventoryCounting: InventoryCounting;

  @Knows(() => Reservation, 'warehouseToId')
  reservationsTo: Reservation[];

  @Knows(() => Reservation, 'targetWarehouseId')
  reservationsTarget: Reservation[];
}
