import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusAuditingStatus extends OrderStatus {
  public displayName = 'Auditando';

  public internalName = OrderStatuses.Auditing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
