import {
  Entity,
  Field,
  IsEmail,
  IsNotEmpty,
  IsNumberString,
  Knows,
  NeededBy,
  PrimaryKey
} from '@skyframe/core';
import { ClassificationContainer } from './ClassificationContainer';
import { CustomerOrder } from './CustomerOrder';
import { MopPickingContainer } from './MopPickingContainer';

@Entity()
export class Customer {
  @PrimaryKey()
  id: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'first_name' })
  firstName: string;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'last_name' })
  lastName: string;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @IsEmail(
    {},
    {
      message: 'El campo debe ser un email válido'
    }
  )
  @Field()
  email: string;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @IsNumberString({
    message: 'El campo debe ser numérico'
  })
  @Field({ name: 'phone_number' })
  phoneNumber: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field()
  address: string;

  @Field()
  city: boolean;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => CustomerOrder, 'customerId')
  customerOrders: CustomerOrder[];

  @NeededBy(() => MopPickingContainer, 'customerId')
  mopPickingContainers: MopPickingContainer[];

  @NeededBy(() => ClassificationContainer, 'customerId')
  classificationContainer: ClassificationContainer;
}
