import { ConsolidateContainersWaveStatuses } from '../ConsolidateContainersWave';
import { ConsolidateContainersWaveStatus } from './ConsolidateContainersWaveStatus';

export class ConsolidateContainersWaveStatusProcessing extends ConsolidateContainersWaveStatus {
  public displayName = 'Procesando';

  public internalName = ConsolidateContainersWaveStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
