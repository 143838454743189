import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { InventoryItem } from './InventoryItem';
import { StagingAuditingContainer } from './StagingAuditingContainer';
import { StagingAuditingItemDraftStatus } from './staging-auditing-item-status';
import { StagingAuditingItemAuditedStatus } from './staging-auditing-item-status/StagingAuditingItemAuditedStatus';
import { StagingAuditingItemAuditingStatus } from './staging-auditing-item-status/StagingAuditingItemAuditingStatus';
import { StagingAuditingItemCancelledStatus } from './staging-auditing-item-status/StagingAuditingItemCancelledStatus';
import { StagingAuditingItemPendingStatus } from './staging-auditing-item-status/StagingAuditingItemPendingStatus';
import { StagingAuditingItemStatus } from './staging-auditing-item-status/StagingAuditingItemStatus';

export const StagingAuditingItemStatuses = {
  Pending:   'pending',
  Auditing:  'auditing',
  Audited:   'audited',
  Cancelled: 'cancelled',
  Draft:     'draft'
};

@Entity()
export class StagingAuditingItem {
  @PrimaryKey()
  id: number;

  @Status({
    [StagingAuditingItemStatuses.Pending]:   StagingAuditingItemPendingStatus,
    [StagingAuditingItemStatuses.Auditing]:  StagingAuditingItemAuditingStatus,
    [StagingAuditingItemStatuses.Audited]:   StagingAuditingItemAuditedStatus,
    [StagingAuditingItemStatuses.Cancelled]: StagingAuditingItemCancelledStatus,
    [StagingAuditingItemStatuses.Draft]:     StagingAuditingItemDraftStatus
  })
  status: StagingAuditingItemStatus;

  @ForeignKey(() => StagingAuditingContainer, {
    name: 'staging_auditing_container_id'
  })
  stagingAuditingContainerId: number;

  @Field({ name: 'label' })
  label: string;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @Field({ name: 'expected_quantity' })
  expectedQuantity: number;

  @Field({ name: 'audited_quantity' })
  auditedQuantity: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => StagingAuditingContainer, 'stagingAuditingContainerId')
  stagingAuditingContainer: StagingAuditingContainer;

  @Knows(() => Container, 'containerId')
  container: Container;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;
}
