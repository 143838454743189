import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, Reducer, useReducer } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainerMachine } from './ScanContainerMachine';

export const ScanContainer: FC = withActorRef(ScanContainerMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const onSubmit = lpn => {
      send({ type: 'ContainerScanned', data: { lpn } });
      setLpn('');
    };

    const [inputLpn, setLpn] = useReducer<Reducer<string, string>>(
      (prev, next) => {
        if (next !== '' && current.context) {
          // setError('');
        }
        return next;
      },
      ''
    );

    return (
      <>
        <Ink.Text>
          {current.context.hint ? current.context.hint : 'Escanee contenedor:'}
        </Ink.Text>

        {current.context.suggestedContainer && (
          <>
            <Ink.Text>
              UB. DE CONT.:&nbsp;
              <Ink.Chalk greenBright bold>
                {current.context.suggestedContainer!.location?.name}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              CONT.SUGERIDO:&nbsp;
              <Ink.Chalk greenBright bold>
                {current.context.suggestedContainer!.lpn}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>Escanear Contenedor:</Ink.Text>
          </>
        )}

        {current.context.requestedContainer && (
          <>
            <Ink.Text>
              UB. DE CONT.:&nbsp;
              <Ink.Chalk greenBright bold>
                {current.context.requestedContainer!.location?.name}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              CONT.REQUERIDO:&nbsp;
              <Ink.Chalk greenBright bold>
                {current.context.requestedContainer!.lpn}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>Escanear Contenedor:</Ink.Text>
          </>
        )}

        <Ink.TextInput value={inputLpn} onChange={setLpn} onSubmit={onSubmit} />

        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}
      </>
    );
  }
);
