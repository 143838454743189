import {
  Customer,
  Location,
  MopPickingContainer,
  PickItem,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultEnterLpnContext,
  EnterLpnMachine
} from '../../capa-4/enter-lpn/EnterLpnMachine';
import {
  DefaultEnterQuantityContext,
  EnterQuantityMachine
} from '../../capa-4/enter-quantity/EnterQuantityMachine';

import {
  DefaultScanSKUContext,
  ScanSKUMachine
} from '../../capa-4/scan-sku/ScanSKUMachine';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import { getlocationByNameAndValidLocationAttribute } from '../../layer-4/location/get-location-by-name/fetchers/get-location-by-valid-location-attribute';
import { GetLocationByNameMachine } from '../../layer-4/location/get-location-by-name/machine';
import { miniMopPickingWaveActions } from './actions';
import { miniMopPickingWaveGuards } from './guards';
import { miniMopPickingWaveServices } from './services';

export interface MiniMopPickingWaveContext {
  task: Task | null;
  pickingWaveId: number | null;

  pickingCartTypes: string[] | null;
  pickingCartType: string | null;
  pickingCart: Location | null;
  pickingCartPosition: number | null;

  branchOfficeTotal: number | null;
  branchOfficeCreated: number;
  branchOfficeToPrepare: Customer | null;

  containerLpn: string | null;

  currentPickItem: PickItem | null;
  pickItemLocation: Location | null;
  pickItemDestinyContainerLocation: MopPickingContainer | null;
  pickedQuantity: number | null;

  currentMopPickingContainerToClose: MopPickingContainer | null;

  positionError: string | null;
}

export const DefaultMiniMopPickingWaveContext = {
  task:                              null,
  pickingWaveId:                     null,
  pickingCartTypes:                  null,
  pickingCartType:                   null,
  pickingCart:                       null,
  pickingCartPosition:               null,
  branchOfficeTotal:                 null,
  branchOfficeCreated:               0,
  branchOfficeToPrepare:             null,
  containerLpn:                      null,
  currentPickItem:                   null,
  pickItemLocation:                  null,
  pickItemDestinyContainerLocation:  null,
  pickedQuantity:                    null,
  currentMopPickingContainerToClose: null,
  positionError:                     null
};
export const MiniMopPickingWaveMachine = createMachine(
  {
    id:                         'MiniMopPickingWave',
    predictableActionArguments: true,
    schema:                     {
      context: {} as MiniMopPickingWaveContext
    },
    initial: 'FetchingPickingCartTypes',
    states:  {
      FetchingPickingCartTypes: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchPickingCartTypes',
          onDone: [
            {
              target:  'SelectingPickingCartType',
              actions: 'assignPickingCartTypes'
            }
          ]
        }
      },
      SelectingPickingCartType: {
        on: {
          pickingCartTypeSelected: {
            target:  'ScanningPickingCart',
            actions: 'assignSelectedPickingCartType'
          }
        }
      },
      ScanningPickingCart: {
        tags:   ['location'],
        invoke: {
          id:  'ScanningPickingCart',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee etiqueta del carro',
              getlocationByNameAndValidLocationAttribute(
                'Ocurrió un error, por favor reintente más tarde.',
                ctx.pickingCartType!
              )
            ),
          onDone: [
            {
              target:  'CountingBranchOffices',
              actions: 'assignPickingCart'
            }
          ]
        }
      },
      CountingBranchOffices: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchBranchOfficesQuantity',
          onDone: [
            {
              target:  'GettingNextBranchOffice',
              actions: 'assignBranchOfficesQuantity'
            }
          ]
        }
      },
      GettingNextBranchOffice: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchNextBranchOfficeToPrepare',
          onDone: [
            {
              cond:    'allBranchOfficesHaveBeenPrepared',
              target:  'GettingNextPickItemToScan',
              actions: 'assignNextBranchOfficeToPrepare'
            },
            {
              target:  'ScanningContainer',
              actions: 'assignNextBranchOfficeToPrepare'
            }
          ]
        }
      },
      ScanningContainer: {
        tags:   ['containerCreate'],
        invoke: {
          id:   EnterLpnMachine.id,
          src:  EnterLpnMachine,
          data: ctx => ({
            ...DefaultEnterLpnContext,
            location: ctx.pickingCart
          }),
          onDone: [
            {
              target:  'SelectingPickingCartPosition',
              actions: 'assignContainerLpn'
            }
          ]
        }
      },
      SelectingPickingCartPosition: {
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: _ctx => ({
            ...DefaultEnterQuantityContext,
            hint: 'Ingrese la posición del carro',
            min:  1,
            max:  9
          }),
          onDone: [
            {
              target:  'VerifyingPickingCartPosition',
              actions: ['assignPickingCartPosition', 'clearPositionError']
            }
          ]
        }
      },
      VerifyingPickingCartPosition: {
        invoke: {
          src:    'fetchVerifyPickingCartPositionAvailable',
          onDone: [
            {
              cond:   'pickingCartPositionIsAvailable',
              target: 'ConfirmingContainerCreation'
            },
            {
              actions: 'assignPositionError',
              target:  'SelectingPickingCartPosition'
            }
          ]
        }
      },
      ConfirmingContainerCreation: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniMopPickingContainerCreated',
              payload: {
                lpn:                 ctx?.containerLpn,
                locationId:          ctx.pickingCart?.id,
                containerTypeId:     5,
                customerId:          ctx.branchOfficeToPrepare?.id, // FIJARSE
                pickingCartPosition: ctx.pickingCartPosition,
                pickingWaveId:       ctx.pickingWaveId
              }
            };
          },
          onDone: [
            {
              target:  'GettingNextBranchOffice',
              actions: 'assignBranchOfficeCreated'
            }
          ]
        }
      },
      GettingNextPickItemToScan: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchNextPickItem',
          onDone: [
            {
              cond:    'hasPendingPickItems',
              target:  'ScanningItemLocation',
              actions: 'assignNextPickItem'
            },
            {
              target: 'FetchingNextMopPickingContainerToClose'
            }
          ]
        }
      },
      ScanningItemLocation: {
        tags:   ['location'],
        invoke: {
          id:  'ScanningItemLocation',
          src: GetLocationByNameMachine(
            'Confirme escaneando ubicacion del item',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: [
            {
              target:  'ScanningPickItem',
              actions: 'assignPickItemLocation'
            }
          ]
        }
      },
      ScanningPickItem: {
        tags:   ['sku'],
        invoke: {
          id:   ScanSKUMachine.id,
          src:  ScanSKUMachine,
          data: ctx => ({
            ...DefaultScanSKUContext,
            hint:             'Confirme escaneando SKU del producto:',
            validIdentifiers: [ctx.currentPickItem?.sku]
          }),
          onDone: [
            {
              target: 'FetchingPickItemDestinyContainer'
            }
          ]
        }
      },
      FetchingPickItemDestinyContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchPickItemDestinyContainer',
          onDone: [
            {
              target:  'ScanningPickItemDestinyLocation',
              actions: 'assignPickItemDestinyLocation'
            }
          ]
        }
      },
      ScanningPickItemDestinyLocation: {
        tags:   ['containerGet'],
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee cubeta de destino',
              getRequestedContainerByLpn(
                'Ocurrió un error, por favor reintente más tarde.',
                'La cubeta escaneada es incorrecta',
                ctx.pickItemDestinyContainerLocation!.container.lpn
              )
            ),
          onDone: [
            {
              target: 'ScanningItemQuantity'
            }
          ]
        }
      },
      ScanningItemQuantity: {
        tags:   ['quantity'],
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: ctx => ({
            ...DefaultEnterQuantityContext,
            hint: 'Ingrese cantidad pickeada:',
            min:  1,
            max:  ctx.currentPickItem?.quantity
          }),
          onDone: [
            {
              target:  'MarkingPickItemAsPicked',
              actions: 'assignPickedQuantity'
            }
          ]
        }
      },
      MarkingPickItemAsPicked: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniMopPickingItemPicked',
              payload: {
                pickItemId:     ctx.currentPickItem?.id,
                containerId:    ctx.pickItemDestinyContainerLocation?.container.id,
                pickedQuantity: ctx.pickedQuantity
              }
            };
          },
          onDone: [
            {
              target: 'GettingNextPickItemToScan'
            }
          ]
        }
      },
      FetchingNextMopPickingContainerToClose: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchNextMopPickingContainerToClose',
          onDone: [
            {
              cond:    'hasPendingMopPickingContainersToClose',
              target:  'ScanningMiniMopContainer',
              actions: 'assignCurrentMopPickingContainerToClose'
            },
            {
              target: 'FinishingTask'
            }
          ]
        }
      },
      ScanningMiniMopContainer: {
        tags:   ['containerGet'],
        invoke: {
          id:  'ScanningMiniMopContainer',
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee contenedor a depositar en el sorter',
              getRequestedContainerByLpn(
                'Ocurrió un error, por favor reintente más tarde.',
                'El contenedor escaneado es incorrecto',
                ctx.currentMopPickingContainerToClose!.container.lpn
              )
            ),
          onDone: [
            {
              target: 'ClosingMiniMopContainer'
            }
          ]
        }
      },
      ClosingMiniMopContainer: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniMopContainerClosed',
              payload: {
                containerId:   ctx.currentMopPickingContainerToClose?.containerId,
                locationId:    1,
                pickingWaveId: ctx.pickingWaveId
              }
            };
          },
          onDone: [
            {
              target: 'FetchingNextMopPickingContainerToClose'
            }
          ]
        }
      },
      FinishingTask: {
        on: {
          confirmTaskFinished: {
            target: 'CompletingPickingWave'
          },
          cancel: {
            target: 'FetchingNextMopPickingContainerToClose'
          }
        }
      },
      CompletingPickingWave: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniMopPickingWaveCompleted',
              payload: {
                pickingWaveId: ctx.pickingWaveId
              }
            };
          },
          onDone: [
            {
              target: 'Done'
            }
          ]
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   miniMopPickingWaveGuards,
    actions:  miniMopPickingWaveActions,
    services: miniMopPickingWaveServices
  }
);
