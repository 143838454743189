import {
  Container,
  Location,
  ProductItemName,
  PutAwayItem,
  Receipt,
  ReceiptItem,
  cantPutManyInSameContainer
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { CreateItemMachine } from '../../capa-4/create-item/CreateItemMachine';
import { PlaceItemInContainerMachine } from '../../capa-4/place-item-in-container/PlaceItemInContainerMachine';

export interface ReceiptLineMachineContext {
  receipt: Receipt | null;
  receiptItems: ReceiptItem[];
  validReceiptItems: ReceiptItem[];
  item: PutAwayItem | null;
  location: Location | null;
  container: Container | null;
  suggestedItem: ReceiptItem | null;
}

export const DefaultReceiptLineMachineContext = {
  receipt:           null,
  receiptItems:      [],
  item:              null,
  validReceiptItems: [],
  location:          null,
  container:         null,
  suggestedItem:     null
};

export const ReceiptLineMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlk3XwEkAXMVAYggHtCSCA3ZgazBIGUK1OqkSgADs1i4auVmJAAPRACYAjAA4SKgMwAGACwA2AJxqA7AfMaVugDQgAnogvmSxgKwmdRvScsmBt4AvsEOaFh4hKTiADZYYLT0VPgACriY3ARQAMKsNOgEYABOTKx8nDx8qfGYiSIpNMx5+AVFxQCykUUKktKy8khKiDo2JBoaHgZ6ah7mRjazHg7OCCZ6JCbrGmZ6RgY65rYaoeEYOEUxtfXJaRlZ+Ln5hYSlVAAqAKIdAPqpADIAQRynwAIj8qAA5H45ADykPegKhnwASr0pDI5PgFMoEOZDCR9jp1FYjCo9DpDisXB4dO5zEcVKZzKNNP5QmEQPhmBA4AoIhdomQhElREM+pjBqBcQYVNSEGojB53GSFmqNEY1OpzKcQAKosQSHEEqKUulMtkWm1Xuj+licSMtuN1mS9HoPBoDmp5ZoVJsPZTrIYNOY1AZdfrLiRzQ8nqg4mA6LbJdihriTBo6RZM0c9rKPHpzPLyVoDEEQ6TTIETCoI+cDURkwNU9KXBofVo3W61Do1N4PKYThygA */
  createMachine<ReceiptLineMachineContext>(
    {
      id:      'ReceiptLine',
      initial: 'FetchContainer',
      context: DefaultReceiptLineMachineContext,
      states:  {
        FetchContainer: {
          invoke: {
            src:    'findContainer',
            onDone: {
              actions: ['assignContainer'],
              target:  'CreateItem'
            }
          }
        },
        CreateItem: {
          entry:  'selectSuggetedItem',
          invoke: {
            src:  CreateItemMachine,
            id:   CreateItemMachine.id,
            data: context => ({
              validItems: [
                ...context.receiptItems,
                ...context.validReceiptItems
              ],
              beforeCreationValidation: contextCreateContainer => {
                return cantPutManyInSameContainer(
                  context.receipt!,
                  context.container!,
                  contextCreateContainer.scannedProduct
                );
              },
              suggestedItem:     context.suggestedItem,
              type:              ProductItemName.PutAwayItem,
              location:          context.location,
              expectedContainer: context.container,
              receiptId:         context.receipt?.id
            }),
            onDone: {
              actions: ['assignItem', 'updateReceiptItems'],
              target:  'PlaceItemInContainer'
            }
          }
        },
        PlaceItemInContainer: {
          invoke: {
            id:   PlaceItemInContainerMachine.id,
            src:  PlaceItemInContainerMachine,
            data: ctx => {
              return {
                requestedContainer: ctx.container,
                type:               ProductItemName.InventoryItem,
                item:               ctx.item,
                location:           ctx.location
              };
            },
            onDone: [
              {
                actions: 'assignItem',
                target:  'VerificationComplete'
              }
            ]
          }
        },
        VerificationComplete: {
          type: 'final',
          data: ctx => ({
            item: ctx.item
          })
        }
      }
    },
    {
      guards: {
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions,
        selectSuggetedItem: assign({
          suggestedItem: context => context.receiptItems[0]
        }),
        updateReceiptItems: assign({
          receiptItems: context => {
            const receiptItem = context.receiptItems.find(
              item => item.sku === context.item!.sku
            );
            if (
              context.item?.sku === receiptItem?.sku &&
              context.item?.quantity === receiptItem?.quantity
            ) {
              return context.receiptItems.filter(
                item => item.sku !== context.item!.sku
              );
            }
            return context.receiptItems;
          }
        })
      },
      services: { ...API }
    }
  );
