import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { Counter } from '../../../shared/Counter';
import { withActorRef } from '../../../shared/Machine';
import { CreateContainer } from '../../capa-4/create-container/CreateContainer';
import { MarkAsIncident } from '../../capa-4/mark-as-incident/MarkAsIncident';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanItem } from '../../capa-4/scan-item/ScanItem';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { TransferItem } from '../../capa-4/transfer-item/TransferItem';
import { ConsolidateContainersTaskMachine } from './ConsolidateContainersTaskMachine';

export const ConsolidateContainersTask: FC = withActorRef(
  ConsolidateContainersTaskMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const onCompleted = () => {
    console.log('AllItemsConsolidated');
  };

  const menuItems = [
    {
      key:         'assign-task',
      displayName: '1. Confirmar tarea.',
      event:       'confirmReceiptVerification'
    }
  ];

  const handleClick = useCallback((id: string) => {
    const clickedItem = [...menuItems].find(item => item.key === id);
    if (clickedItem) send(clickedItem.event);
  }, []);

  return (
    <Ink.Box flexDirection='column' paddingLeft={1}>
      <Ink.Box>
        <Ink.Header>
          <Ink.Text bold>
            {!current.context.task?.id ? (
              'PROCESANDO CONSOLIDACIÓN'
            ) : (
              <>
                PROCESANDO CONSOLIDACIÓN:{' '}
                <Ink.Chalk>{current.context.task?.id}</Ink.Chalk>
              </>
            )}
          </Ink.Text>
        </Ink.Header>
      </Ink.Box>
      {current.matches('DeclaringConsolidationLocation') ? (
        <>
          Especifique ubicación de consolidado.
          <ScanLocation />
        </>
      ) : null}

      {current.matches('NoItems') ? (
        <>
          <Ink.Text>La tarea CAS esta momentaneamente detenida</Ink.Text>
          <Ink.Text>
            Tareas pendientes:{' '}
            <Ink.Chalk greenBright>
              {current.context.pendingTasks.length}
            </Ink.Chalk>
          </Ink.Text>
          {current.context.pendingTasks.map((t, i) => (
            <Ink.Box key={i}>
              <Ink.Text>
                Tarea ID:{' '}
                <Ink.Chalk greenBright>
                  {t.id.toString() + ' - ' + t.type}
                </Ink.Chalk>
              </Ink.Text>
            </Ink.Box>
          ))}

          <Ink.Box flexDirection='column' paddingLeft={1}>
            <Ink.Button
              key='goBack'
              id='goBack'
              label='Volver a verificar'
              onClick={() => send('verify')}
            />
          </Ink.Box>
        </>
      ) : null}

      {current.matches('Finished') ? (
        <>
          <Ink.Text>La tarea CAS está finalizada</Ink.Text>
          <Ink.Text>Diríjase a opciones para volver al menú</Ink.Text>
        </>
      ) : null}

      {current.matches('ScanDestinationContainer') ? (
        <>
          <Ink.Text>
            Deposite los productos y escanee el contenedor en GRID 2 de
            <Ink.Text bold color='greenBright'>
              LPN {current.context.destinationContainer?.lpn}
            </Ink.Text>
            de la ubicación
            <Ink.Text bold color='greenBright'>
              {current.context.consolidationLocation?.name}
            </Ink.Text>
          </Ink.Text>
          <ScanContainer />{' '}
        </>
      ) : null}

      {current.matches('ScanOriginContainer') ? (
        <>
          <ScanContainer />
        </>
      ) : null}

      {current.matches('ScanItem') ? (
        <>
          <Ink.Text>
            Escanee SKU {current.context.orderItem?.sku} del contenedor{' '}
            {current.context.container?.lpn}
          </Ink.Text>
          <Ink.Text>
            Cantidad {current.context.orderItem?.quantity || 0}/
            {current.context.orderItem?.expectedQuantity}
          </Ink.Text>

          <ScanItem />
        </>
      ) : null}

      {current.matches('SelectDestinationContainer') ? (
        <>
          <Ink.Text>
            Escoger {current.context.granelProduct ? 'balda' : 'contenedor'} de
            despacho del GRID 2
          </Ink.Text>

          {current.context.consolidationContainers.map(container => (
            <Ink.Button
              key={container.id.toString()}
              id={container.id.toString()}
              label={container.lpn}
              onClick={() => send({ type: 'select', data: { container } })}
            />
          ))}

          <Ink.Button
            key='create'
            id='create'
            label='Crear nuevo contenedor'
            onClick={() => send('create')}
          />
        </>
      ) : null}

      {current.matches('CreateContainer') ? <CreateContainer /> : null}

      {current.matches('CreateParentContainer') ? <CreateContainer /> : null}

      {current.matches('IteratingOverTransferLines') ? (
        <>
          <Ink.Text>
            <Counter
              count={
                current.context.consolidationItems !== null
                  ? {
                      total:     current.context.consolidationItems?.length | 0,
                      processed: current.context.consolidatedItems?.length
                    }
                  : null
              }
              onComplete={onCompleted}
            />
            {'  '} Lineas traspasadas
          </Ink.Text>

          <TransferItem />
        </>
      ) : null}

      {current.matches('ConfirmReceiptItemsVerification') ? (
        <Ink.Box flexDirection='column' paddingTop={1} paddingX={1}>
          <>
            {current.context.consolidationItems?.length === 0 ? (
              <Ink.Text>
                Se han consolidado todos los ítems de la tarea de consolidación{' '}
                <Ink.Chalk>{current.context?.task?.id || ''}</Ink.Chalk>.
              </Ink.Text>
            ) : (
              <Ink.Text>
                Se han consolidado{' '}
                <Ink.Chalk greenBright>
                  {current.context?.consolidatedItems?.length}
                </Ink.Chalk>
                /
                <Ink.Chalk greenBright>
                  {current.context?.payload?.consolidationItems?.length || 0}
                </Ink.Chalk>{' '}
                lineas de la tarea{' '}
                <Ink.Chalk greenBright>
                  {current.context?.task?.id || ''}
                </Ink.Chalk>
                .
              </Ink.Text>
            )}

            <Ink.Box paddingY={1}>
              <Ink.Text>¿Confirmar Consolidación?</Ink.Text>
              {/* {state.context.error && ( */}
              {/*   <Ink.Text color='redBright'>{state.context.error}</Ink.Text> */}
              {/* )} */}
            </Ink.Box>

            <Ink.Box flexDirection='column'>
              {menuItems.map(item => (
                <Ink.Button
                  key={item.key}
                  id={item.key}
                  label={item.displayName}
                  onClick={handleClick}
                />
              ))}
            </Ink.Box>
          </>
        </Ink.Box>
      ) : null}

      {current.matches('SelectParentContainer') ? (
        <>
          <Ink.Text>Escoger contenedor padre para la balda:</Ink.Text>

          {current.context.parentContainersToSelect.map(container => (
            <Ink.Button
              key={container.id.toString()}
              id={container.id.toString()}
              label={container.lpn}
              onClick={() => send({ type: 'select', data: { container } })}
            />
          ))}

          <Ink.Button
            key='create'
            id='create'
            label='Crear nuevo contenedor'
            onClick={() => send('create')}
          />
        </>
      ) : null}

      {current.matches('MarkAsIncident') ? <MarkAsIncident /> : null}

      {current.matches('initializing') ? (
        <Ink.Text>Cargando...</Ink.Text>
      ) : null}
    </Ink.Box>
  );
});
