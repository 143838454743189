import { ReceiptStatus } from './ReceiptStatus';

export class ReceiptStatusSortingStatus extends ReceiptStatus {
  public displayName = 'Clasificando';

  public internalName = 'sorting';

  public statusColor = '#fbe7d9';

  public textColor = '#ea881b';
}
