import { InventoryItemStatuses } from '../InventoryItem';
import { InventoryItemStatus } from './InventoryItemStatus';

export class InventoryItemStatusNotInWareHouseStatus extends InventoryItemStatus {
  public displayName = 'No disponible';

  public internalName = InventoryItemStatuses.NotInWareHouse;

  public statusColor = '#d7d7d7';
}
