import { Entity, Field, Knows, NeededBy, PrimaryKey } from '@skyframe/core';
import { Container } from './Container';
import { Location } from './Location';
import { ReceiptItem } from './ReceiptItem';
import { ZipCode } from './ZipCode';

@Entity()
export class ZoneClassifier {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'is_return' })
  isReturn: boolean;

  @Field({ name: 'is_wms_product' })
  isWMSProduct: boolean;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Location, 'zoneClassifierId')
  locations: Location[];

  @NeededBy(() => ZipCode, 'zoneClassifierId')
  zipCodes: ZipCode[];

  @Knows(() => Container, 'zoneClassifierId')
  container: Container[];

  @Knows(() => ReceiptItem, 'zoneClassifierId')
  receiptItem: ReceiptItem[];
}
