import Ink from '@ink';
import { isDecimal } from 'class-validator';
import React, { FC, Reducer, useReducer } from 'react';
import { ProductItem } from '../../../shared/utils/ProductItem';

export const ScanQTY: FC<{
  packagingLevelUnit?: string;
  suggestedItem: ProductItem | null;
  quantityEntered: (qty: string) => void;
}> = ({ packagingLevelUnit, suggestedItem, quantityEntered }) => {
  const [inputQty, setQty] = useReducer<Reducer<string, string>>(
    (prev, next) => {
      return next === '' || isDecimal(next) ? next : prev;
    },
    ''
  );

  return (
    <>
      <Ink.Text bold>
        Ingrese cantidad
        {packagingLevelUnit ? ' (' + packagingLevelUnit + ')' : ''}:
      </Ink.Text>

      {suggestedItem?.quantity ? (
        <>
          <Ink.Text>
            Cantidad:&nbsp;
            <Ink.Chalk greenBright>{suggestedItem.quantity}</Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      <Ink.TextInput
        value={inputQty}
        onChange={setQty}
        onSubmit={quantityEntered}
      />
    </>
  );
};

ScanQTY.displayName = 'ScanQTY';
