import { locationAttributeName } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MaxiConsolidateContainersContext } from './machine';

type MaxiConsolidateContainersServices = MachineOptions<
  MaxiConsolidateContainersContext,
  any
>['services'];

export const maxiConsolidateContainersServices: MaxiConsolidateContainersServices =
  {
    fetchPalletTruckType: async (ctx, _evt) => {
      const { data } = await api.get(
        `location/${ctx.palletTruckLocation?.id}/location-attribute?name=${locationAttributeName.PALLETTRUCKTYPE}`
      );

      return {
        type: data
      };
    },
    checkIfContainersHaveSameGroup: async (ctx, _evt) => {
      const { data } = await api.get('container/checkGroupContainers', {
        params: {
          containerId1: ctx.container1?.id,
          containerId2: ctx.container2?.id
        }
      });

      return data;
    },
    fetchStageLocation: async (ctx, _evt) => {
      const { data } = await api.get(`location/${ctx.orderId}/stageLocation`);
      return data;
    },
    fetchWaveContainers: async (ctx, _evt) => {
      const { data } = await api.get(
        `consolidate-containers-wave/${ctx.consolidateContainersWaveId}/container`
      );
      return data;
    }
  };
