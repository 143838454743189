import { MovingToAuditingLocationProcessStatuses } from '../MovingToAuditingLocationProcess';
import { MovingToAuditingLocationProcessStatus } from './MovingToAuditingLocationProcessStatus';

export class MovingToAuditingLocationProcessCancelledStatus extends MovingToAuditingLocationProcessStatus {
  public displayName = 'Cancelado';

  public internalName = MovingToAuditingLocationProcessStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
