import { Task } from '@wms/domain';
import { createMachine } from 'xstate';
import { printTestLabel } from '../../layer-4/printer/labels/print-test-label';
import { PrintMachine, PrintMachineId } from '../../layer-4/printer/machine';

import { testPrinterActions } from './actions';
import { testPrinterGuards } from './guards';
import { testPrinterServices } from './services';

export interface TestPrinterContext {
  task: Task;
  error: string | null;
}

export const DefaultTestPrinterContext: TestPrinterContext = {
  task:  {} as Task,
  error: null
};

export const TestPrinterMachine = createMachine(
  {
    id:                         'TestPrinter',
    predictableActionArguments: true,
    schema:                     {
      context: {} as TestPrinterContext
    },
    initial: 'SelectingLabel',
    states:  {
      SelectingLabel: {
        on: {
          PRINT_TEST: {
            target: 'Printing'
          },
          DONE: 'Done'
        }
      },
      Printing: {
        tags:   ['loading'],
        invoke: {
          id:  PrintMachineId,
          src: _ctx =>
            PrintMachine(
              printTestLabel('Ocurrió un error, por favor reintente más tarde.')
            ),
          onDone:  'SelectingLabel',
          onError: {
            target:  'SelectingLabel',
            actions: 'assignError'
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   testPrinterGuards,
    actions:  testPrinterActions,
    services: testPrinterServices
  }
);
