import { PurchaseOrderStatus } from './PurchaseOrderStatus';

export class PurchaseOrderStatusOpenStatus extends PurchaseOrderStatus {
  public displayName = 'Abierto';

  public internalName = 'open';

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
