import { Main, initializeInk } from '@wms/client-core';
import { inspect } from '@xstate/inspect';
import React from 'react';
import ReactDOM from 'react-dom';
import { inkModule } from './ink/module';
import reportWebVitals from './reportWebVitals';

import '@exampledev/new.css/new.css';
import './index.css';

initializeInk(inkModule);

const useDevTools = process.env.DEV_TOOLS !== 'false';
if (useDevTools) {
  inspect({ iframe: false });
}

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
