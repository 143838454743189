import { MachineOptions, assign } from 'xstate';
import { BigPickingWaveContext } from './machine';

type BigPickingWaveActions = MachineOptions<
  BigPickingWaveContext,
  any
>['actions'];

export const bigPickingWaveActions: BigPickingWaveActions = {
  assignCurrentPickItem: assign({
    currentPickItem: (_ctx, event) => event.data
  }),
  assignClampLocation: assign({
    clampLocation: (_ctx, event) => event.data
  }),
  assignMaxClampCapacity: assign({
    maxClampCapacity: (_ctx, event) => event.data.maxClampCapacity
  }),
  assignSuggestedPickQuantity: assign({
    suggestedPickQuantity: (_ctx, event) => event.data
  }),
  assignPickedQuantity: assign({
    pickedQuantity: (_ctx, event) => event.data.quantity
  }),
  assignPickItemQuantities: assign({
    pickItemsQuantity: (_ctx, event) => event.data.totalItems,
    pickItemsIndex:    (_ctx, event) => event.data.pickedItems + 1
  })
};
