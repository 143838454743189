import { MovingToAuditingLocationProcessStatuses } from '../MovingToAuditingLocationProcess';
import { MovingToAuditingLocationProcessStatus } from './MovingToAuditingLocationProcessStatus';

export class MovingToAuditingLocationProcessPendingStatus extends MovingToAuditingLocationProcessStatus {
  public displayName = 'Pendiente';

  public internalName = MovingToAuditingLocationProcessStatuses.Pending;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
