import { LocationStatuses } from '../BaseLocation';
import { LocationStatus } from './LocationStatus';

export class LocationStatusAvailableStatus extends LocationStatus {
  public displayName = 'Disponible';

  public internalName = LocationStatuses.Available;

  public statusColor = '#66bb6a';
}
