import { MopSorterExitProcessStatuses } from '../MopSorterExitProcess';
import { MopSorterExitProcessStatus } from './MopSorterExitProcessStatus';

export class MopSorterExitProcessCancelledStatus extends MopSorterExitProcessStatus {
  public displayName = 'Cancelado';

  public internalName = MopSorterExitProcessStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
