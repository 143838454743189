import Ink from '@ink';
import React, { FC } from 'react';

export const LoadingScreen: FC = () => {
  return (
    <Ink.Box justifyContent='center'>
      <Ink.Chalk greenBright bold>
        Cargando...
      </Ink.Chalk>
    </Ink.Box>
  );
};
LoadingScreen.displayName = 'LoadingScreen';
