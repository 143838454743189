import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusReturnedStatus extends OrderItemStatus {
  public displayName = 'Devuelto';

  public internalName = OrderItemStatuses.Returned;

  public statusColor = '#e3c5d1';

  public textColor = '#64053f';
}
