import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { Location } from './Location';

@Entity()
export class PreStorageLocationMapping {
  @PrimaryKey()
  id: number;

  @Field({ name: 'pre_storage_location_name' })
  preStorageLocationName: string;

  @ForeignKey(() => Location, { name: 'pre_storage_location_id' })
  preStorageLocationId: number;

  @Knows(() => Location, 'preStorageLocationId')
  preStorageLocation: Location;

  @Field({ name: 'storage_location_name' })
  storageLocationName: string;

  @ForeignKey(() => Location, { name: 'storage_location_id' })
  storageLocationId: number;

  @Knows(() => Location, 'storageLocationId')
  storageLocation: Location;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}
