import { MopSorterExitItemStatuses } from '../MopSorterExitItem';
import { MopSorterExitItemStatus } from './MopSorterExitItemStatus';

export class MopSorterExitItemDeliveredStatus extends MopSorterExitItemStatus {
  public displayName = 'Entregado';

  public internalName = MopSorterExitItemStatuses.Delivered;

  public statusColor = '#b90c0c';
}
