import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusPreparedStatus extends OrderStatus {
  public displayName = 'Preparado';

  public internalName = OrderStatuses.Prepared;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
