import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchAuditingStatus extends OrderBatchStatus {
  public displayName = 'Auditando';

  public internalName = OrderBatchStatuses.Auditing;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}
