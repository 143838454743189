import Ink from '@ink';
import { InventoryItem } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterLot } from './EnterLotInformation';
import { ScanQTY } from './EnterQuantity';
import { ScanItemMachine } from './ScanItemMachine';
import { ScanSKU } from './ScanSKU';

export const ScanItem: FC = withActorRef(ScanItemMachine)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const handleClick = useCallback(
    (id: string) => {
      const itemId = Number(id);

      const item = current.context.possibleItems.find(i => i.id === itemId);

      send({
        type: 'select',
        data: { item, id, itemId, items: current.context }
      });
    },
    [current.context, current.context.possibleItems]
  );

  return (
    <>
      {current.matches('awaitingBarcodeScan') ? (
        <ScanSKU
          barcodeScanned={barcode =>
            send({ type: 'productScanned', data: { sku: barcode } })
          }
          suggestedItem={current.context.suggestedItem}
          requestedItem={current.context.requestedItem}
        />
      ) : null}

      {current.matches('scanQTY') ? (
        <>
          <ScanQTY
            quantityEntered={quantity => {
              send({ type: 'QtyEntered', data: { quantity } });
            }}
            suggestedItem={
              current.context.requestedItem
                ? current.context.requestedItem
                : current.context.suggestedItem
            }
            packagingLevelUnit={
              current.context.product?.packagingLevels[0]?.name || ''
            }
          />
        </>
      ) : null}

      {current.matches('EnterLot') ? (
        <EnterLot
          lotEntered={lot => send({ type: 'lotEntered', data: { lot } })}
          suggestedLot={
            (current.context.requestedItem as any)?.lot?.lotNumber ||
            (current.context.requestedItem as any)?.lotNumber ||
            (current.context.suggestedItem as any)?.lot?.lotNumber ||
            (current.context.suggestedItem as any)?.lotNumber
          }
        />
      ) : null}

      {current.matches('SelectItem') ? (
        <>
          <Ink.Text>
            Existen varios ítems que cumplen las caracteristicas pedidas.
          </Ink.Text>
          <Ink.Text>Seleccione el requerido: </Ink.Text>
          <Ink.Text>
            Producto:{' '}
            <Ink.Chalk greenBright>
              {current.context.possibleItems[0].product.sku +
                ' - ' +
                current.context.possibleItems[0].product.name}
            </Ink.Chalk>
          </Ink.Text>
          <Ink.Text>
            {current.context.container ? (
              <>
                <Ink.Text>Contenedor: </Ink.Text>
                <Ink.Chalk greenBright>
                  {current.context.container?.lpn as any}
                </Ink.Chalk>
              </>
            ) : (
              <>
                <Ink.Text>Ubicación: </Ink.Text>
                <Ink.Chalk greenBright>
                  {current.context.location?.name as any}
                </Ink.Chalk>
              </>
            )}
          </Ink.Text>
          {(current.context.possibleItems[0] as any).lot ? (
            <>
              <Ink.Text>
                Lote:{' '}
                <Ink.Chalk greenBright>
                  {(current.context.possibleItems[0] as any).lot.lotNumber}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Text>
                Fecha de expiración:{' '}
                <Ink.Chalk greenBright>
                  {new Date(
                    (current.context.possibleItems[0] as any).lot
                      .expirationDate as string
                  ).toLocaleDateString()}
                </Ink.Chalk>
              </Ink.Text>
            </>
          ) : null}
          <Ink.Box flexDirection='column'>
            {current.context.possibleItems.map((item, i) => (
              <Ink.Button
                key={item.id}
                id={item.id.toString()}
                label={`${i + 1}. Cantidad disponible ${item.quantity}${
                  (item as any).container
                    ? ` - Cont. ${(item as any).container?.lpn}`
                    : ''
                } - Estado ${InventoryItem.mapStatusName(
                  (item as any).status
                )} (calidad: ${InventoryItem.mapQualityStatusName(
                  (item as any).qualityStatus
                )})`}
                onClick={handleClick}
              />
            ))}
          </Ink.Box>
        </>
      ) : null}

      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}
    </>
  );
});
