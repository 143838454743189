import { PackingItemStatuses } from '../PackingItem';
import { PackingItemStatus } from './PackingItemStatus';

export class PackingItemStatusPendingStatus extends PackingItemStatus {
  public displayName = 'Pendiente';

  public internalName = PackingItemStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
