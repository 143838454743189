import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Order } from './Order';
import { Reservation } from './Reservation';
import { ReservationOrderStatus } from './reservation-order-status';
import { ReservationOrderStatusCancelledStatus } from './reservation-order-status/ReservationOrderStatusCancelledStatus';
import { ReservationOrderStatusDeliveredStatus } from './reservation-order-status/ReservationOrderStatusDeliveredStatus';
import { ReservationOrderStatusDispatchedStatus } from './reservation-order-status/ReservationOrderStatusDispatchedStatus';
import { ReservationOrderStatusProcessingStatus } from './reservation-order-status/ReservationOrderStatusProcessingStatus';
import { ReservationOrderStatusRejectedStatus } from './reservation-order-status/ReservationOrderStatusRejectedStatus';
import { ReservationOrderStatusRoutedStatus } from './reservation-order-status/ReservationOrderStatusRoutedStatus';

export const ReservationOrderStatuses = {
  Routed:     'routed',
  Processing: 'processing',
  Dispatched: 'dispatched',
  Delivered:  'delivered',
  Rejected:   'rejected',
  Cancelled:  'cancelled'
};

@Entity()
export class ReservationOrder {
  @PrimaryKey()
  id: number;

  @Status({
    [ReservationOrderStatuses.Routed]:     ReservationOrderStatusRoutedStatus,
    [ReservationOrderStatuses.Processing]:
      ReservationOrderStatusProcessingStatus,
    [ReservationOrderStatuses.Dispatched]:
      ReservationOrderStatusDispatchedStatus,
    [ReservationOrderStatuses.Delivered]: ReservationOrderStatusDeliveredStatus,
    [ReservationOrderStatuses.Rejected]:  ReservationOrderStatusRejectedStatus,
    [ReservationOrderStatuses.Cancelled]: ReservationOrderStatusCancelledStatus
  })
  status: ReservationOrderStatus;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => Reservation, { name: 'reservation_id' })
  reservationId: number;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @Needs(() => Reservation, 'reservationId')
  reservation: Reservation;

  @Needs(() => Order, 'orderId')
  order: Order;
}
