import { Entity } from '@skyframe/core';
import { BaseLocation } from './BaseLocation';
import { CategoryLocation } from './CategoryLocation';
import { Container } from './Container';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { LocationType } from './LocationType';
import { Warehouse } from './Warehouse';
import { ZoneClassifier } from './ZoneClassifier';

@Entity()
export class StagingGrid extends BaseLocation {
  rubric: never;

  families: CategoryLocation[];

  checkDigit: never;

  status: never;

  side: never;

  column: never;

  level: never;

  placementAllowed: never;

  routeId: never;

  /* Section */
  parent: Location;

  /* Stock locations */
  children: Location[];

  appointment: never;

  containers: Container[];

  inventoryItems: InventoryItem[];

  locationType: LocationType;

  warehouse: Warehouse;

  zoneClassifier: ZoneClassifier;

  crossDockWarehouse: never;

  route: never;
}
