import { Container, Location } from '@wms/domain';
import { createMachine } from 'xstate';
import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getPackingLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-packing-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { packingTaskAssignerActions } from './actions';
import { packingTaskAssignerGuards } from './guards';
import { packingTaskAssignerServices } from './services';

export interface PackingTaskAssignerContext {
  error: string | null;

  packingLocation: Location | null;
  container: Container | null;
}

export const DefaultPackingTaskAssignerContext: PackingTaskAssignerContext = {
  error:           null,
  packingLocation: null,
  container:       null
};

export const PackingTaskAssignerMachine = createMachine(
  {
    id:                         'PackingTaskAssignerMachine',
    predictableActionArguments: true,
    schema:                     {
      context: {} as PackingTaskAssignerContext
    },
    context: DefaultPackingTaskAssignerContext,
    initial: 'EnteringPackingLocation',
    states:  {
      EnteringPackingLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Ingrese UB. de Empaque',
            getPackingLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            actions: 'assignPackingLocation',
            target:  'EnteringContainer'
          }
        }
      },
      EnteringContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: GetContainerByLpnMachine(
            'Escanee LPN de Contenedor',
            getAnyContainerByLpn(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            actions: 'assignContainer',
            target:  'FetchingTask'
          }
        },
        on: {
          RESET: 'EnteringPackingLocation'
        }
      },
      FetchingTask: {
        invoke: {
          src:     'FetchPackingWaveTask',
          onDone:  'TaskAssigned',
          onError: {
            actions: 'assignError',
            target:  'ShowingFetchTaskError'
          }
        }
      },
      ShowingFetchTaskError: {
        on: {
          back: {
            actions: 'clearError',
            target:  'EnteringPackingLocation'
          }
        }
      },
      TaskAssigned: {
        type: 'final',
        data: (context, event) => event.data
      }
    }
  },
  {
    guards:   packingTaskAssignerGuards,
    actions:  packingTaskAssignerActions,
    services: packingTaskAssignerServices
  }
);
