import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchDispatchingStatus extends OrderBatchStatus {
  public displayName = 'Despachando';

  public internalName = OrderBatchStatuses.Dispatching;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}
