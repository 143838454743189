import { AuditingProcessStatuses } from '../AuditingProcess';
import { AuditingProcessStatus } from './AuditingProcessStatus';

export class AuditingProcessCompletedStatus extends AuditingProcessStatus {
  public displayName = 'Completado';

  public internalName = AuditingProcessStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
