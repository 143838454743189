import { StagingAuditingContainerStatuses } from '../StagingAuditingContainer';
import { StagingAuditingContainerStatus } from './StagingAuditingContainerStatus';

export class StagingAuditingContainerAuditedStatus extends StagingAuditingContainerStatus {
  public displayName = 'Auditado';

  public internalName = StagingAuditingContainerStatuses.Audited;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
