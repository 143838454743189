import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditOrderItem } from './AuditOrderItem';
import { Category } from './Category';
import { Container } from './Container';
import { InventoryCount } from './InventoryCount';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { Lot } from './Lot';
import { Order } from './Order';
import { OrderBatch } from './OrderBatch';
import { OrderItem } from './OrderItem';
import { PickItem } from './PickItem';
import { PickingProcess } from './PickingProcess';
import { Process } from './Process';
import { Product } from './Product';
import { ProductRestockingLevel } from './ProductRestockingLevel';
import { PutAwayItem } from './PutAwayItem';
import { Receipt } from './Receipt';
import { ReceiptItem } from './ReceiptItem';
import { SlottingAlgorithm } from './SlottingAlgorithm';
import { TaskBucket } from './TaskBucket';
import { TaskType } from './TaskType';
import { User } from './User';
import { ZoneClassifier } from './ZoneClassifier';
import {
  TaskStatus,
  TaskStatusCancelledStatus,
  TaskStatusCompletedStatus,
  TaskStatusPausedStatus,
  TaskStatusPendingStatus
} from './task-status';
import { TaskStatusDraftStatus } from './task-status/TaskStatusDraftStatus';

export const TaskStatuses = {
  Draft:     'draft',
  Pending:   'pending',
  Completed: 'completed',
  Cancelled: 'cancelled',
  Paused:    'paused'
};

export const NewTaskTypes = {
  VerifyAndPalletizeItemsV0:       'verify-and-palletize-items-v0',
  PutAwayContainersToPrestorageV0: 'put-away-containers-to-prestorage-v0',
  PutAwayContainersToStorageV0:    'put-away-containers-to-storage-v0',
  PutAwayContainersV0:             'put-away-containers-v0',
  RestockInventoryV0:              'restock-inventory-v0',

  // Inbound Process
  InboundDirectReceptionV0: 'inbound-direct-reception-v0',

  PickOrderV0:                'pick-order-v0',
  PickWaveV0:                 'pick-wave-v0',
  PickAndSortV0:              'pick-and-sort-v0',
  SinglePickerBatchPickingV0: 'single-picker-batch-picking-v0',

  ConsolidateContainersV0: 'consolidate-containers-v0',
  WeightContainersV0:      'weight-and-tag-containers-to-storage-v0',
  StageContainersV0:       'stage-containers-for-outbound-v0',
  AuditContainersV0:       'audit-containers-v0',
  AuditItemsQuantityV0:    'audit-items-quantity-v0',
  ShipContainersV0:        'ship-containers-v0',
  DispatchContainersV0:    'dispatch-containers-v0',

  MoveContainerV0:          'move-containers-v0',
  MoveItemsV0:              'move-items-v0',
  CreateContainersV0:       'create-containers-v0',
  CreateContainerV0:        'create-container-v0',
  TrasladoDeMercaderiaTest: 'TrasladoDeMercaderiaTest',
  CreateItem:               'create-item',
  AddItemsToContainer:      'add-items-to-container',
  RemoveItemsFromContainer: 'remove-items-from-container',

  MoveContainerToStorage:                   'container-move-to-storage',
  InboundMoveContainerFromStagingToStorage:
    'inbound-move-container-from-staging-to-storage',
  DispatchItem:          'order-dispatch-item',
  MoveContainersToGrid3: 'containers-move-to-grid-3',

  MarkItemAsAnomaly: 'mark-as-anomaly',

  SelectRejectionReason: 'select-rejection-reason',

  InventoryCounting: 'inventory-counting',

  MarkAsAnomalyTask: 'mark-as-anomaly-task',

  DownloadContainer: 'download-container',
  UploadContainer:   'upload-container',

  FractionateContainer: 'frac-container',

  ItemDetails: 'item-details',
  FracStorage: 'frac-storage',

  RestockingUtility: 'restocking-utility',

  MidiPickingWave: 'midi-picking-wave',
  MiniPickingWave: 'mini-picking-wave',
  MaxiPickingWave: 'maxi-picking-wave',
  BigPickingWave:  'big-picking-wave',

  MiniMopAuditing:    'mini-mop-auditing',
  MiniMopPickingWave: 'mini-mop-picking-wave'
} as const;

export const ReceiptTaskTypes = {
  CreateContainer:      'receipt-createContainer',
  CountContainers:      'receipt-confirm-pallet-quantity',
  ReceiptTaking:        'receipt-taking',
  PlaceItemInContainer: 'receipt-placeItem-in-container',
  Confirm:              'receipt-confirm',
  ConfirmMove:          'receipt-confirm-move',
  MoveContainer:        'receipt-moveContainer'
} as const;

export const OutboundTaskTypes = {
  MiniMoveToPackingLocationTask:        'mini-move-to-packing-location',
  MiniPackingTask:                      'mini-packing-task',
  MiniMoveToSorterExitTask:             'mini-move-to-sorter-exit',
  MidiMoveToSorterExitTask:             'midi-move-to-sorter-exit',
  MiniMopMoveToSorterExitTask:          'mini-mop-move-to-sorter-exit',
  MaxiConsolidateContainersTask:        'maxi-consolidate-containers',
  MiniMopMovingToAuditingLocationTask:  'mini-mop-moving-to-auditing-location',
  ConsolidatingContainersToStagingType: 'consolidate-containers-to-staging',
  StagingAuditingTask:                  'staging-auditing'
} as const;

export const StrictOrderTaskTypes = {
  CreatePickingContainer:      'order-create-picking-container',
  BatchPicking:                'order-batch-picking',
  MoveContainerToLabelingDesk: 'order-move-containers-to-labeling-desk',
  MoveContainer:               'order-moveContainer',
  MoveToStaging:               'order-move-to-staging',
  ConfirmPreparation:          'order-confirm-preparation',
  SearchItem:                  'order-search-item',
  ConfirmAudit:                'order-confirm-audit',
  ConfirmDispatch:             'order-confirm-dispatch'
} as const;

export const OrderTaskTypes = {
  ...StrictOrderTaskTypes,
  AuditItem:            'order-audit-item',
  PlaceItemInContainer: 'order-placeItem-in-container',
  DispatchItem:         'order-dispatch-item'
} as const;

export const LogisticTaskTypes = {
  SortItem:          'logistic-sort-item',
  SortContainer:     'logistic-sort-container',
  SortCrossDockItem: 'logistic-sort-cross-dock-item',
  Replenishment:     'logistic-replenishment',
  DownloadContainer: 'download-container',
  UploadContainer:   'upload-container'
} as const;

export const RestockingTaskTypes = {
  restockInventory:  'restocking-inventory',
  ReadjustInventory: 'readjust-inventory'
} as const;

export const InventoryCountTaskTypes = {
  countInventory: 'count-inventory'
} as const;

export const UtilityTaskTypes = {
  viewContainerDetail: 'view-container-detail',
  viewLocationDetail:  'view-location-detail',
  ViewProductDetail:   'view-product-detail',
  ChangeItemStatus:    'change-item-status',
  ManagePrinter:       'manage-printer',
  TestPrinter:         'test-printer'
} as const;

export const MissingItemTaskTypes = {
  MarkItemAsMissing: 'mark-item-as-missing'
} as const;

export const TaskTypes = {
  ...NewTaskTypes,
  ...OutboundTaskTypes,
  ...ReceiptTaskTypes,
  ...OrderTaskTypes,
  ...LogisticTaskTypes,
  ...RestockingTaskTypes,
  ...InventoryCountTaskTypes,
  ...UtilityTaskTypes,
  ...MissingItemTaskTypes
} as const;

export const PullTaskTypes = [
  TaskTypes.MoveContainerToStorage,
  TaskTypes.ConsolidateContainersV0,
  TaskTypes.WeightContainersV0,
  TaskTypes.MoveContainersToGrid3,
  TaskTypes.FractionateContainer,
  TaskTypes.DownloadContainer,
  TaskTypes.UploadContainer,
  TaskTypes.RestockInventoryV0
];

export type ProductItem =
  | ReceiptItem
  | PutAwayItem
  | InventoryItem
  | PickItem
  | OrderItem;

export const ProductItemName = {
  ReceiptItem:   'ReceiptItem',
  PutAwayItem:   'PutAwayItem',
  InventoryItem: 'InventoryItem',
  PickItem:      'PickItem',
  OrderItem:     'OrderItem'
} as const;

type EmptyPayload = Record<string, never>;

export type NewTaskType = (typeof NewTaskTypes)[keyof typeof NewTaskTypes];

export type ReceiptTaskType =
  (typeof ReceiptTaskTypes)[keyof typeof ReceiptTaskTypes];

export type OutboundTaskType =
  (typeof OutboundTaskTypes)[keyof typeof OutboundTaskTypes];

export type OrderTaskType =
  (typeof OrderTaskTypes)[keyof typeof OrderTaskTypes];

export type LogisticTaskType =
  (typeof LogisticTaskTypes)[keyof typeof LogisticTaskTypes];

export type RestockingTaskType =
  (typeof RestockingTaskTypes)[keyof typeof RestockingTaskTypes];

export type InventoryCountTaskType =
  (typeof InventoryCountTaskTypes)[keyof typeof InventoryCountTaskTypes];

export type UtilityTaskType =
  (typeof UtilityTaskTypes)[keyof typeof UtilityTaskTypes];

export type MarkItemAsMissingType =
  (typeof MissingItemTaskTypes)[keyof typeof MissingItemTaskTypes];

export type StringTaskType =
  | NewTaskType
  | ReceiptTaskType
  | OutboundTaskType
  | OrderTaskType
  | LogisticTaskType
  | RestockingTaskType
  | InventoryCountTaskType
  | UtilityTaskType
  | MarkItemAsMissingType;

export interface CreateContainerReceiptPayload {
  providerName?: string;
  checkDigit: string;
  stagingId?: number;
  stagingName?: string;
  dockId?: number;
  dockName?: string;
  receiptType?: string;
  pendingZone?: ZoneClassifier;
  pendingCategory?: Category;
}

export interface ReceiptTakingPayload {
  receipt: Receipt;
  providerName?: string;
  dockId?: number;
  dockName?: string;
  stagingId?: number;
  stagingName?: string;
  skuCount?: number;
}

export interface PlaceItemsInContainerReceiptPayload {
  receiptItem: ReceiptItem;
  checkDigit: string;
  stagingId: number;
  stagingName: string;
  receiptType?: string;
}

export interface MoveContainerReceiptPayload {
  containerToMove: Container;
  locationId: number;
  locationName: string;
  receiptType?: string;
  dockName?: string;
}

export interface ConfirmReceiptPayload {
  checkDigit: string;
  stagingId: number;
  itemCount: { processedItems: number; totalItems: number };
  receiptType?: string;
}
export interface ConfirmMoveContainerReceiptPayload {
  containerCount: { processedContainers: number; totalContainers: number };
  receiptType?: string;
}

export interface BatchPickingPayload {
  routeId: number;
  routeName: string;
}

export interface CreatePickingContainerPayload {
  pickingProcessId: number;
  pickingProcess: PickingProcess;
}

export interface PickingWavePayload {
  orderId: number;
  predispatchContainerLpn: string;
  pickingWaveId: number;
  orderBatchId?: number;
  deliveryType?: string;
}

export interface AuditingWavePayload {
  auditingWaveId: number;
}

export interface PackingPayload {
  packingWaveId: number;
  packingLocationId: number;
}

export interface MoveToPackingLocationPayload {
  packingLocationProcessId: number;
}

export interface MovingToAuditingLocationPayload {
  movingToAuditingLocationProcessId: number;
}

export interface ContainersReadyToConsolidatePayload {
  consolidateContainersProcessId?: number;
  consolidateContainersItemId?: number;
  consolidateContainersWaveId?: number;
}

export interface MoveToSorterExitPayload {
  sorterExitProcessId: number;
}

export interface PlaceItemsInContainerOrderPayload {
  pickItem: PickItem;
  pickingWaveId: number;
  dispatchContainerId: number;
  dispatchContainer: Container;
  orderId: number;
  pickItemId: number;
  orderItem?: OrderItem;
  routeId?: number;
  routeName?: string;
}

export interface MoveContainerToStoragePayload {
  container: Container;
  suggestLocation: boolean;
}

export interface MoveContainersToGrid3Payload {
  suggestLocation: boolean;
  orderId: number;
}

export interface MoveContainerFromCrossDockOrderPayload {
  container: Container;
  orderId: number;
}

export interface MissingItemsCheckPayload {
  sku: string;
  warehouseId: number;
}

export interface ConfirmPreparationOrderPayload {
  orderId: number;
}

export interface AuditOrderItemPayload {
  auditOrderItem: AuditOrderItem;
  pickItems: PickItem[];
  orderItem: OrderItem;
  orderItemId: number;
  routeId: number;
  routeName: string;
  destination?: string;
  auditId: number;
  orderId: number;
  anomaliesQuantity?: number;
}

export interface SearchInventoryItemPayload {
  inventoryItemId: number;
  wmsOrderItemId: string;
  lastKnownLocationName: string;
  auditOrderItemId: number;
  orderItemId?: number;
  pickItemId: number;
  destinationName: string;
  orderId: number;
}

export interface ConfirmAuditOrderPayload {
  routeId: number;
  routeName: string;
  auditId: number;
  orderId: number;
}

export interface ReceiptContainerQuantityPayload {
  checkDigit: number;
  receiptId: number;
  locationId: number;
  locationName: string;
}
export interface DispatchItemOrderPayload {
  pickItem: PickItem;
  orderItem: OrderItem;
  container: Container;
  orderId: number;
  destination?: string;
  routeName?: string;
}

export interface ConfirmDispatchOrderPayload {
  totalItems: number;
  processedItems: number;
  orderId: number;
  order: Order;
}

export interface MoveItemLogisticPayload {
  inventoryItem: InventoryItem;
  destination: Location;
}

export interface SortCrossDockItemLogisticPayload {
  location: Location;
  pickItem: PickItem;
  orderId: number;
  routeId: number;
  container: Container;
}

export interface MoveContainerLogisticPayload {
  container: Container;
  containerId?: number;
  destination: Location;
}

export interface MoveContainerToStagingPayload {
  orderId: number;
  container: Container;
  destination: Location;
}

export interface RestockInventoryPayload {
  productRestockingLevelId: number;
  productRestockingLevel: ProductRestockingLevel;
  sourceInventoryItems: InventoryItem[];
}

export interface RestockingPayload {
  inventoryItem: InventoryItem;
  container: Container;
  lot: Lot;
  product: Product;

  // 3 fields opcionales, solo puede existir 1 (UNO) solo dentro del payload

  // Si es restocking
  location?: Location;

  // Si es reajuste orden grande
  pickingProcessId?: number;

  // Si es reajuste PW
  pickingWaveId?: number;
}

export interface UploadDownloadContainerPayload {
  containerId: number;
  containerLpn: string;
  locationName: string;
}

export interface InventoryCountPayload {
  inventoryItem: InventoryItem;
  inventoryCount: InventoryCount;
  inventoryCountId: number;
}

export interface ReplenishmentLogisticPayload {
  container: Container;
  containerId: number;
}

export interface FractioningContainerPayload {
  containerId: number;
  receiptId: number;
  containerLpn: string;
}

export interface ConsolidationPayload {
  consolidatingToStagingContainerId: number;
  slotPosition: number;
}

export interface StagingAuditingPayload {
  stagingAuditingWaveId: number;
}

export type ReceiptTaskPayload =
  | CreateContainerReceiptPayload
  | ReceiptContainerQuantityPayload
  | ReceiptTakingPayload
  | PlaceItemsInContainerReceiptPayload
  | MoveContainerReceiptPayload
  | ConfirmReceiptPayload
  | ConfirmMoveContainerReceiptPayload
  | RestockingPayload;

export type OrderTaskPayload =
  | CreatePickingContainerPayload
  | PickingWavePayload
  | AuditingWavePayload
  | PackingPayload
  | MoveToPackingLocationPayload
  | MoveToSorterExitPayload
  | ContainersReadyToConsolidatePayload
  | MoveContainerFromCrossDockOrderPayload
  | ConfirmPreparationOrderPayload
  | AuditOrderItemPayload
  | SearchInventoryItemPayload
  | ConfirmAuditOrderPayload
  | DispatchItemOrderPayload
  | ConfirmDispatchOrderPayload
  | MoveContainerToStagingPayload
  | MovingToAuditingLocationPayload;

export type LogisticTaskPayload =
  | MoveItemLogisticPayload
  | MoveContainerLogisticPayload
  | SortCrossDockItemLogisticPayload
  | ReplenishmentLogisticPayload
  | UploadDownloadContainerPayload;

export type TaskPayload =
  | ReceiptTaskPayload
  | OrderTaskPayload
  | LogisticTaskPayload
  | RestockInventoryPayload
  | InventoryCountPayload
  | MissingItemsCheckPayload
  | DispatchContainersV0Payload
  | ConsolidationPayload
  | StagingAuditingPayload;

export type DispatchContainersV0Payload = {
  order: Order;
};

export interface AuditContainersV0Payload {
  orderId: number;
  routeId: number;
  auditId: number;
  routeName: string;
  containersToAudit: Container[];
  auditLocationId: number;
  auditLocation: Location;
}

export type MoveContainerV0Payload = {
  currentContainer: Container;
  containersToMove: Container[];
  movedContainers: Container[];
  slottingAlgorithm: SlottingAlgorithm;
  targetLocation: Location;
  hint: string;
  error: string;
};

export type PutAwayContainersV0Payload = {
  receipt: Receipt;
  receiptContainers: Container[];
  putAwayContainers: Container[];
  currentContainer: Container;
  fromLocation: Location;
  dropoutLocation: Location;
  slottingAlgorithm: SlottingAlgorithm;
};

export type MoveItemsV0Payload = {
  fromLocation: Location | null;

  // Any of these 4
  slottingAlgorithm: SlottingAlgorithm | null;
  suggestedLocation: Location | null;
  requiredLocation: Location | null;
  location: Location | null;

  // Any of these 3.
  requestedItem: Container | null;
  suggestedItem: Container | null;
  validItems: Container[];
  item: Container | null;
  validator: null;

  hint: string | null;
  error: string | null;
  quantity: number;
};

export type MoveContainersV0Payload = {
  currentItem: ProductItem | null;
  itemsToMove: ProductItem[];
  movedItems: ProductItem[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
};

export type CreateContainersV0Payload = {
  currentContainer: Container | null;
  containersToCreate: Container[];
  createdContainers: Container[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
};

export type CreateContainerV0Payload = {
  currentContainer: Container | null;
  containersToCreate: Container[];
  createdContainers: Container[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
};

export type VerifyAndPalletizeItemsV0Payload = {
  receipt: Receipt | null;
  containersToCreate: any[];
  receiptContainers: Container[];
  receiptItems: ReceiptItem[];
  putAwayItems: PutAwayItem[];
  putAwayContainers: Container[];
  currentItem: null;
  currentContainer: Container | null;
  dropoutLocation: Location | null;
  moveContainer: null;
  slottingAlgorithm: SlottingAlgorithm | string | null;
};

export type CreateItemPayload = {
  currentItem: ProductItem | null;
  itemsToCreate: ProductItem[];
  createdItems: ProductItem[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
};

export type InventoryCountingPayload = {
  locationName: string;
  containerLpn: string;
  sku: string;
  lotNumber: string;
  inventoryCountingItemId: number;
  productName: string;
  itemStatus: string;
  // Only used for showing tasks
  inventoryCountingId: number;
};

// @TODO
export type ConsolidateContainersV0Payload = EmptyPayload;

// @TODO
export type PickOrderV0Payload = EmptyPayload;

// @TODO
export type PickAndSortV0Payload = EmptyPayload;

export type NewTaskPayloads =
  | PutAwayContainersV0Payload
  | VerifyAndPalletizeItemsV0Payload
  | PickOrderV0Payload
  | PickAndSortV0Payload
  | MoveContainerV0Payload
  | MoveItemsV0Payload
  | CreateContainersV0Payload
  | CreateContainerV0Payload
  | ConsolidateContainersV0Payload
  | CreateItemPayload
  | MoveContainerToStoragePayload
  | MoveContainersToGrid3Payload;

export type RestockingTaskTypePayload = RestockInventoryPayload;

@Entity()
export class Task {
  @PrimaryKey()
  id: number;

  @Status({
    [TaskStatuses.Draft]:     TaskStatusDraftStatus,
    [TaskStatuses.Pending]:   TaskStatusPendingStatus,
    [TaskStatuses.Cancelled]: TaskStatusCancelledStatus,
    [TaskStatuses.Completed]: TaskStatusCompletedStatus,
    [TaskStatuses.Paused]:    TaskStatusPausedStatus
  })
  status: TaskStatus;

  @Field({ name: 'assigned_at' })
  assignedAt: Date;

  payload: TaskPayload;

  @Field()
  type: StringTaskType;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => User, { name: 'user_id' })
  userId: number;

  @ForeignKey(() => User, { name: 'receipt_id' })
  receiptId: number;

  @ForeignKey(() => User, { name: 'order_id' })
  orderId: number;

  @ForeignKey(() => User, { name: 'order_batch_id' })
  orderBatchId: number;

  @ForeignKey(() => TaskBucket, { name: 'task_bucket_id' })
  taskBucketId: number;

  @ForeignKey(() => Process, { name: 'process_instance_id' })
  processInstanceId: number;

  @ForeignKey(() => TaskType, { name: 'task_type_id' })
  taskTypeId: number;

  @Knows(() => User, 'userId')
  user: User;

  @Knows(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Knows(() => Order, 'orderId')
  order: Order;

  @Knows(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @Needs(() => TaskBucket, 'taskBucketId')
  taskBucket: TaskBucket;

  @Knows(() => Process, 'processInstanceId')
  processInstance: Process;

  // Knows relation in order to be compatible with existing string types.
  @Knows(() => TaskType, 'taskTypeId')
  taskType: TaskType;

  getProcessType() {
    const process = this.type.split('-')[0];
    return process as 'receipt' | 'order' | 'logistic' | 'restocking' | 'count';
  }
}
