import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Location } from './Location';
import { Order } from './Order';
import { OrderBatch } from './OrderBatch';
import { PickItem } from './PickItem';
import { PickingAlgorithm } from './PickingAlgorithm';
import { PickingToStagingItem } from './PickingToStagingItem';
import { PickingWave } from './PickingWave';
import { User } from './User';
import {
  PickingProcessStatus,
  PickingProcessStatusCancelledStatus,
  PickingProcessStatusCompletedStatus,
  PickingProcessStatusPendingStatus,
  PickingProcessStatusProcessingStatus
} from './picking-process-status';

export const PickingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class PickingProcess {
  @PrimaryKey()
  id: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Field({ name: 'author_id' })
  authorId: number;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @ForeignKey(() => PickingAlgorithm, { name: 'picking_algorithm_id' })
  pickingAlgorithmId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Status({
    pending:    PickingProcessStatusPendingStatus,
    processing: PickingProcessStatusProcessingStatus,
    completed:  PickingProcessStatusCompletedStatus,
    cancelled:  PickingProcessStatusCancelledStatus
  })
  status: PickingProcessStatus;

  @NeededBy(() => PickingWave, 'pickingProcessId')
  pickingWaves: PickingWave[];

  @Knows(() => PickingToStagingItem, 'pickingProcessId')
  pickingToStagingItem: PickingToStagingItem;

  @Knows(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @Knows(() => Order, 'orderId')
  order: Order;

  @Needs(() => PickingAlgorithm, 'pickingAlgorithmId')
  pickingAlgorithm: PickingAlgorithm;

  @Needs(() => User, 'authorId')
  author: User;

  @Knows(() => Location, 'consolidationLocationId')
  consolidationLocation: Location;

  @ForeignKey(() => Location, { name: 'consolidation_location_id' })
  consolidationLocationId: number;

  @Knows(() => PickItem, 'pickingProcessId')
  pickItems: PickItem[];
}
