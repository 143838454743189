import { MachineOptions, assign } from 'xstate';
import { MaxiConsolidateContainersContext } from './machine';

type MaxiConsolidateContainersActionsType = MachineOptions<
  MaxiConsolidateContainersContext,
  any
>['actions'];

export const MaxiConsolidateContainersActions: MaxiConsolidateContainersActionsType =
  {
    assignPalletTruckLocation: assign({
      palletTruckLocation: (_ctx, event) => event.data
    }),
    assignPalletTruckType: assign({
      palletTruckType: (_ctx, event) => event.data.type
    }),
    assignContainer1: assign({
      container1: (_ctx, event) => event.data
    }),
    assignContainer2: assign({
      container2: (_ctx, event) => event.data
    }),
    incrementContainersScanned: assign({
      containersScanned: (ctx, _event) => ctx.containersScanned + 1
    }),
    clearContainerCtx: assign({
      container1:        (_, __) => null,
      container2:        (_, __) => null,
      containersScanned: (_, __) => 0
    }),
    assignContainerUnload: assign({
      containerUnloadLpn: (_ctx, event) => event.data.container.lpn
    }),
    clearErrorCtx: assign({
      error: (_, __) => null
    }),
    clearContainersScanned: assign({
      containersScanned: (_, __) => 0
    }),
    assignStageLocation: assign({
      stageLocation: (_ctx, event) => event.data
    }),
    assignLastContainerScannedLpn: assign({
      lastContainerScannedLpn: (_ctx, event) => event.data.container.lpn
    }),
    assignContainers: assign({
      container1: (_ctx, event) => event.data.containers[0],
      container2: (_ctx, event) => event.data.containers[1] || null
    }),
    assignOriginAndStageLocation: assign({
      originLocation: (_ctx, event) => event.data.originLocation,
      stageLocation:  (_ctx, event) => event.data.targetLocation
    }),
    clearError: assign({
      error: (_ctx, _event) => null
    }),
    errorInvalidTruckType: assign({
      error: (_ctx, _event) => 'La ubicación escaneada no es válida'
    })
  };
