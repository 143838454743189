import { TaskStatuses } from '../Task';
import { TaskStatus } from './TaskStatus';

export class TaskStatusPendingStatus extends TaskStatus {
  public displayName = 'Pendiente';

  public internalName = TaskStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}
