import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditOrderItem } from './AuditOrderItem';
import { Order } from './Order';
import {
  AuditOrderStatus,
  AuditOrderStatusCompletedStatus,
  AuditOrderStatusPendingStatus
} from './audit-order-status';

export const AuditOrderStatuses = {
  Pending:   'pending',
  Completed: 'completed'
};

export const AuditOrderContinueCases = {
  DispatchOrder:                    'dispatch-order',
  ReAuditAnyUser:                   're-audit-any-user',
  ReAuditNewUser:                   're-audit-new-user',
  SearchItems:                      'search-items',
  ContinueDispatchWithMissingItems: 'continue-dispatch-with-missing-items'
};

@Entity()
export class AuditOrder {
  @PrimaryKey()
  id: number;

  @Field({ name: 'unique_auditors' })
  uniqueAuditors: boolean;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @Needs(() => Order, 'orderId')
  order: Order;

  @NeededBy(() => AuditOrderItem, 'auditOrderId')
  auditItems: AuditOrderItem[];

  @Status({
    [AuditOrderStatuses.Pending]:   AuditOrderStatusPendingStatus,
    [AuditOrderStatuses.Completed]: AuditOrderStatusCompletedStatus
  })
  status: AuditOrderStatus;
}
