import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusOnHoldStatus extends OrderItemStatus {
  public displayName = 'Retenido';

  public internalName = OrderItemStatuses.OnHold;

  public statusColor = '#e3c5d1';

  public textColor = '#64053f';
}
