import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Owns,
  PrimaryKey,
  Unique
} from '@skyframe/core';
import { AuditingItem } from './AuditingItem';
import { Category } from './Category';
import { Category2 } from './Category2';
import { ContainerCapacityRule } from './ContainerCapacityRule';
import { CustomerOrderItem } from './CustomerOrderItem';
import { InventoryItem } from './InventoryItem';
import { OrderItem } from './OrderItem';
import { PackagingLevel } from './PackagingLevel';
import { ProductAttribute, productAttributeVolume } from './ProductAttribute';
import { ProductRestockingLevel } from './ProductRestockingLevel';
import { ProductRestockingLevelAlert } from './ProductRestockingLevelAlert';
import { ProductType } from './ProductType';
import { PurchaseOrderItem } from './PurchaseOrderItem';
import { ReceiptItem } from './ReceiptItem';
import { ReservationItem } from './ReservationItem';
import { ReservationProduct } from './ReservationProduct';
import { StockTable } from './StockTable';
import { TransferOrderItem } from './TransferOrderItem';

export const ProductTypes = {
  WMS:          'wms-product',
  EnvioPack:    'envio-pack-product',
  Sellers:      'sellers-product',
  Unidentified: 'unidentified',
  StorePickup:  'store-pickup',
  Returned:     'returned',
  StoreSupply:  'store-supply'
} as const;

export const ProductTypesNames = {
  Bebidas:                 'Bebidas',
  Bienestar:               'Bienestar',
  CarnesYPescados:         'Carnes y Pescados',
  CervezasVinosYLicores:   'Cervezas, Vinos y Licores',
  Congelados:              'Congelados',
  Despensa:                'Despensa',
  Farmacia:                'Farmacia',
  FrutasYVerduras:         'Frutas y Verduras',
  GiftCards:               'Gift Cards',
  Granel:                  'Granel',
  HigienePersonalYBelleza: 'Higiene Personal y Belleza',
  HogarYEntretenimiento:   'Hogar y Entretenimiento',
  LacteosYHuevos:          'Lácteos y Huevos',
  LimpiezaDelHogar:        'Limpieza del Hogar',
  Mascotas:                'Mascotas',
  PanYTortillas:           'Pan y Tortillas',
  Refrigerados:            'Refrigerados',
  Salchichoneria:          'Salchichonería'
};

type productType = (typeof ProductTypes)[keyof typeof ProductTypes];

export const Classification = {
  A: 'A',
  B: 'B',
  C: 'C'
};

export type ClassificationTypes =
  (typeof Classification)[keyof typeof Classification];

@Entity()
export class Product {
  @PrimaryKey()
  id: number;

  @Unique()
  @Field()
  sku: string;

  @Field()
  name: string;

  @Field()
  images: string;

  @Field({ name: 'thumbnail_url' })
  thumbnailUrl: string;

  @Field()
  length: number;

  @Field()
  weight: number;

  @Field()
  width: number;

  @Field()
  height: number;

  @Field()
  ean: string;

  @Field({ name: 'frac_weight' })
  fracWeight: number;

  @Field({ name: 'lot_control' })
  lotControl: boolean;

  @Field({ name: 'serialized_control' })
  serializedControl: boolean;

  @Field({ name: 'classification_type' })
  classificationType: ClassificationTypes;

  @Field({ name: 'brand_name' })
  brandName: string;

  @Field()
  type: productType;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'sap_sww' })
  sapSWW: string;

  @Field({ name: 'size_type' })
  sizeType: productAttributeVolume;

  @ForeignKey(() => ProductType, { name: 'product_type_id' })
  productTypeId: number;

  @Knows(() => ProductType, 'productTypeId')
  productType: ProductType;

  @Owns(() => PackagingLevel, 'productId')
  packagingLevels: PackagingLevel[];

  @NeededBy(() => InventoryItem, 'productId')
  inventoryItems: InventoryItem[];

  @NeededBy(() => ReservationProduct, 'productId')
  reservationProducts: ReservationProduct[];

  @ForeignKey(() => Category, { name: 'category_id' })
  categoryId: number;

  @ForeignKey(() => Category2, { name: 'category2_id' })
  category2Id: number;

  @Knows(() => Category, 'categoryId')
  category: Category;

  @Knows(() => Category2, 'category2Id')
  category2: Category2;

  @NeededBy(() => StockTable, 'productId')
  stockTable: StockTable;

  @NeededBy(() => ProductRestockingLevel, 'productId')
  productRestockingLevels: ProductRestockingLevel[];

  @NeededBy(() => ProductRestockingLevelAlert, 'productId')
  productRestockingLevelAlerts: ProductRestockingLevelAlert[];

  @Knows(() => OrderItem, 'productId')
  orderItems: OrderItem[];

  @Knows(() => ProductAttribute, 'productId')
  productAttributes: ProductAttribute[];

  @Knows(() => ReceiptItem, 'productId')
  receiptItems: ReceiptItem[];

  @Knows(() => PurchaseOrderItem, 'productId')
  purchaseOrderItems: PurchaseOrderItem[];

  @Knows(() => CustomerOrderItem, 'productId')
  customerOrderItems: CustomerOrderItem[];

  @Knows(() => TransferOrderItem, 'productId')
  transferOrderItems: TransferOrderItem[];

  @NeededBy(() => ContainerCapacityRule, 'productId')
  containerRules: ContainerCapacityRule[];

  @NeededBy(() => ReservationItem, 'productId')
  reservationItems: ReservationItem[];

  @NeededBy(() => AuditingItem, 'productId')
  auditingItems: AuditingItem[];

  /* @Knows(() => TransferOrderItem, 'productId')
  transferOrderItems: TransferOrderItem[];*/
}
