import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { LocationLocationAttribute } from './LocationLocationAttribute';

export const locationAttributePalletTruckType = {
  SIMPLE: 'simplepalletjack',
  DOUBLE: 'doublepalletjack'
} as const;

export const locationAttributeName = {
  PALLETTRUCKTYPE: 'pallet-truck-type'
} as const;

export type LocationAttributeName =
  (typeof locationAttributeName)[keyof typeof locationAttributeName];

export type LocationAttributePalletTruckType =
  (typeof locationAttributePalletTruckType)[keyof typeof locationAttributePalletTruckType];

export type LocationAttributeValues = LocationAttributePalletTruckType;
@Entity()
export class LocationAttribute {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  value: any;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => LocationLocationAttribute, 'locationAttributeId')
  LocationLocationAttributes: LocationLocationAttribute[];
}
