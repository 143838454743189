import { PickingContainerGroupStatuses } from '../PickingContainerGroup';
import { PickingContainerGroupStatus } from './PickingContainerGroupStatus';

export class PickingContainerGroupStatusOpen extends PickingContainerGroupStatus {
  public displayName = 'Abierto';

  public internalName = PickingContainerGroupStatuses.Open;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
