import { ConsolidateContainersProcessStatuses } from '../ConsolidateContainersProcess';
import { ConsolidateContainersProcessStatus } from './ConsolidateContainersProcessStatus';

export class ConsolidateContainersProcessStatusCancelled extends ConsolidateContainersProcessStatus {
  public displayName = 'Cancelado';

  public internalName = ConsolidateContainersProcessStatuses.Cancelled;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
