import { TaskBucketStatuses } from '../TaskBucket';
import { TaskBucketStatus } from './TaskBucketBucketStatus';

export class TaskBucketStatusPendingStatus extends TaskBucketStatus {
  public displayName = 'Pendiente';

  public internalName = TaskBucketStatuses.Pending;

  public statusColor = '#eaea47';

  public textColor = '#475569';
}
