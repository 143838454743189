import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../../shared/Machine';
import { GetProductBySKUMachine, GetProductBySKUMachineId } from './machine';

export const GetProductBySKU: FC = withActorRef(
  GetProductBySKUMachine,
  GetProductBySKUMachineId
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const updateProductSKU = useCallback(
    (productSKU: string) => send({ type: 'UpdateProductSKU', productSKU }),
    [send]
  );

  const submitProductSKU = useCallback(
    () => send({ type: 'SubmitProductSKU' }),
    [send]
  );

  return (
    <Ink.Box flexDirection='column' paddingY={1}>
      <Ink.Text bold>{current.context.hint}:</Ink.Text>

      {current.hasTag('loading') ? (
        <Ink.Text>Cargando</Ink.Text>
      ) : (
        <>
          <Ink.TextInput
            value={current.context.productSKU}
            onChange={updateProductSKU}
            onSubmit={submitProductSKU}
          />

          {current.context.error ? (
            <Ink.Text bold color='redBright'>
              {current.context.error}
            </Ink.Text>
          ) : null}
        </>
      )}
    </Ink.Box>
  );
});
