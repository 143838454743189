import { MachineOptions } from 'xstate';
import { MaxiConsolidateContainersContext } from './machine';

type MaxiConsolidateContainersGuards = MachineOptions<
  MaxiConsolidateContainersContext,
  any
>['guards'];

export const maxiConsolidateContainersGuards: MaxiConsolidateContainersGuards =
  {
    containersLeftToScan: (ctx, _evt) => {
      return ctx.containersScanned === 0;
    },
    containersHaveNotTheSameGroup: (_ctx, evt) => {
      return evt.data;
    },
    sameContainerWasScanned: (ctx, evt) => {
      return evt.data.lpn === ctx.container1?.lpn;
    },
    sameContainerWasUnloading: (ctx, evt) => {
      return evt.data.container.lpn === ctx.lastContainerScannedLpn;
    },
    doublePalletTruckAndTwoContainers: (ctx, _evt) => {
      return (
        ctx.palletTruckType === 'doublepalletjack' && ctx.container2 != null
      );
    },
    simplePalletTruckAndTwoContainers: (ctx, _evt) => {
      return (
        ctx.palletTruckType == 'simplepalletjack' && ctx.container2 != null
      );
    },
    singlePalletTruckContainer1WasUnloadAndTwoContainers: (ctx, _evt) => {
      return (
        ctx.palletTruckType == 'simplepalletjack' &&
        ctx.containerUnloadLpn === ctx.container1?.lpn &&
        ctx.container2 != null
      );
    }
  };
