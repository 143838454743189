import {
  Entity,
  Field,
  IsAlphanumeric,
  IsNotEmpty,
  IsNumberString,
  Knows,
  PrimaryKey
} from '@skyframe/core';
import { Vehicle } from './Vehicle';

@Entity()
export class Courier {
  @PrimaryKey()
  id: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field()
  name: string;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @IsAlphanumeric({
    message: 'No se permiten caracteres especiales'
  })
  @IsNumberString({
    message: 'Ingrese solo números'
  })
  @Field({ name: 'dni_cuit_cuil' })
  dniCuitCuil: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Vehicle, 'courierId')
  vehicles: Vehicle[];
}
