import { Container, InventoryItem, Location } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { CreateAnomalyMachine } from '../create-anomaly/CreateAnomalyTaskMachine';
import {
  CreateContainerMachine,
  createContainerMachineInitialContext
} from '../create-container/CreateContainerMachine';
import { MoveContainerMachine } from '../move-container/MoveContainerMachine';
import { DefaultScanContainerContext } from '../scan-container/ScanContainerMachine';
import { TransferItemMachine } from '../transfer-item/TransferItemMachine';

export interface MarkAsAnomalyContext {
  anomalyContainers: Container[];
  pickingWaveId: number | null;
  hint: any;
  location: Location | null;
  selectedContainer: Container | null;
  suggestedItem: InventoryItem | null;
  anomalyInventoryItem: InventoryItem | null;
  anomalyLocation: Location | null;
  receiptId: number | null;
  orderId: number | null;
  inventoryItemStatusFilter: string | null;
}

export const markAsAnomalyInitialContext = {
  anomalyContainers:         [],
  pickingWaveId:             null, // for testing, otherwise is null
  hint:                      null,
  location:                  null,
  selectedContainer:         null,
  suggestedItem:             null,
  anomalyInventoryItem:      null,
  anomalyLocation:           null,
  receiptId:                 null,
  orderId:                   null,
  inventoryItemStatusFilter: null
};

/* const HELP = `Marcar incidencia en producto
Escanee una etiqueta de producto y el lote.`;*/

export const MarkAsAnomalyMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<MarkAsAnomalyContext>(
    {
      id:      'MarkAsAnomalyPickingMachine',
      initial: 'Initial',
      context: markAsAnomalyInitialContext,
      states:  {
        Initial: {
          always: [
            {
              cond:   'isPickWave',
              target: 'PickWaveStart'
            },
            {
              cond:   'isReceipt',
              target: 'ReceiptStart'
            },
            {
              cond:   'isOrder',
              target: 'OrderStart'
            }
          ]
        },
        ReceiptStart: {
          invoke: {
            src:    'getReceiptAnomalyContainers',
            onDone: {
              actions: assign({
                anomalyContainers: (ctx, evt) => evt.data.containers
              }),
              target: 'SelectAnomalyContainer'
            },
            onError: {
              target: 'CreateContainer'
            }
          }
        },
        PickWaveStart: {
          invoke: {
            src:    'getPickingWaveAnomalyContainers',
            onDone: {
              actions: assign({
                anomalyContainers: (ctx, evt) => evt.data.containers
              }),
              target: 'SelectAnomalyContainer'
            },
            onError: {
              target: 'CreateContainer'
            }
          }
        },
        OrderStart: {
          invoke: {
            src:    'getOrderAnomalyContainers',
            onDone: {
              actions: assign({
                anomalyContainers: (ctx, evt) => evt.data.containers
              }),
              target: 'SelectAnomalyContainer'
            },
            onError: {
              target: 'CreateContainer'
            }
          }
        },
        CreateContainer: {
          invoke: {
            src:  CreateContainerMachine,
            id:   CreateContainerMachine.id,
            data: ctx => ({
              ...createContainerMachineInitialContext,
              ...ctx,
              isAnomaly:     true,
              location:      ctx.location,
              pickingWaveId: ctx.pickingWaveId,
              receiptId:     ctx.receiptId,
              orderId:       ctx.orderId,
              hint:          'Crear contenedor para anomalías'
            }),
            onDone: {
              actions: assign({
                anomalyContainers: (ctx, evt) => [evt.data.container]
              }),
              target: 'SelectAnomalyContainer'
            }
          }
        },
        SelectAnomalyContainer: {
          on: {
            select: {
              actions: assign({
                selectedContainer: (ctx, evt) => evt.data.container
              }),
              target: 'CreateAnomaly'
            },
            create: {
              target: 'CreateContainer'
            }
          }
        },
        CreateAnomaly: {
          invoke: {
            src:  CreateAnomalyMachine,
            id:   CreateAnomalyMachine.id,
            data: ctx => ({
              inventoryItemStatusFilter: ctx.inventoryItemStatusFilter
            }),
            onDone: {
              target:  'ShowTransferItemDetails',
              actions: assign({
                anomalyInventoryItem: (ctx, evt) => evt.data.item
              })
            }
          }
        },
        ShowTransferItemDetails: {
          on: {
            confirm: {
              target: 'MoveToAnomalyContainer'
            }
          }
        },
        MoveToAnomalyContainer: {
          invoke: {
            src:  TransferItemMachine,
            id:   TransferItemMachine.id,
            data: ctx => ({
              fromLocation:       ctx.location,
              requestedItem:      ctx.anomalyInventoryItem,
              suggestedContainer: ctx.selectedContainer
            }),
            onDone: {
              target: 'GetAnomalyLocation'
              /* actions: assign({
                anomalyInventoryItem: (ctx, evt) => evt.data.item
              })*/
            }
          }
        },
        GetAnomalyLocation: {
          invoke: {
            src:    'getAnomalyLocation',
            onDone: {
              target:  'MoveContainerAnomalyContainer',
              actions: assign({
                anomalyLocation: (ctx, evt) => evt.data.location
              })
            }
          }
        },
        MoveContainerAnomalyContainer: {
          on: {
            skip: {
              target: 'AnomalyCreated'
            }
          },
          invoke: {
            src:  MoveContainerMachine,
            id:   MoveContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              suggestedContainer: ctx.selectedContainer,
              suggestedLocation:  ctx.anomalyLocation
            }),
            onDone: {
              target: 'CloseContainer'
            }
          }
        },
        CloseContainer: {
          invoke: {
            src:    'closeContainer',
            onDone: {
              target: 'AnomalyCreated'
            }
          }
        },
        AnomalyCreated: {
          type: 'final'
        }
      }
    },
    {
      guards: {
        anomalyContainerExists: ctx => ctx.anomalyContainers.length > 0,
        isPickWave:             ctx => !!ctx.pickingWaveId,
        isReceipt:              ctx => !!ctx.receiptId,
        isOrder:                ctx => !!ctx.orderId,
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions
      },
      services: {
        ...API
      }
    }
  );
