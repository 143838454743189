import { api } from '../../../../api/utils/axios-instance';

export const moveToAuditingLocationServices = {
  fetchingMopPickingContainer: async (ctx, _evt) => {
    const { data } = await api.get(
      `mop-picking-container/by-container/${ctx.container.id}`
    );

    return {
      mopPickingContainer: data.mopPickingContainer
    };
  }
};
