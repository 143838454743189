import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetLocationByNameFn } from '../machine';

export function getPackingLocationByName(
  defaultErrorMessage: string
): GetLocationByNameFn {
  // TODO: Filter by locationType.
  return fetchResult(defaultErrorMessage, name =>
    api.get<Location>('location/name', {
      params: { name }
    })
  );
}
