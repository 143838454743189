import { PurchaseOrderStatus } from './PurchaseOrderStatus';

export class PurchaseOrderStatusClosedStatus extends PurchaseOrderStatus {
  public displayName = 'Cerrado';

  public internalName = 'closed';

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
