import { ConsolidatingContainersToStagingProcessStatuses } from '../ConsolidatingContainersToStagingProcess';
import { ConsolidatingContainersToStagingProcessStatus } from './ConsolidatingContainersToStagingProcessStatus';

export class ConsolidatingContainersToStagingProcessCancelledStatus extends ConsolidatingContainersToStagingProcessStatus {
  public displayName = 'Cancelado';

  public internalName =
    ConsolidatingContainersToStagingProcessStatuses.Cancelled;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
