import React, { FC } from 'react';
import { ActorRefFrom, AnyStateMachine } from 'xstate';
import { ROOT_TASK_ID } from '../../../tasks/core/constants';
import { CurrentActorRefProvider } from './currentActorRefContext';
import { useCurrentActorRef } from './useCurrentActorRef';
import { useRootMachine } from './useRootMachine';

export interface WithActorComponentProps<T extends AnyStateMachine> {
  actorRef: ActorRefFrom<T>;
}

export interface ComponentWithActorProps {
  id?: string;
}

export const withActorRef = <T extends AnyStateMachine>(
  machine: T | ((...args: any[]) => T),
  id: string | null = null
) => {
  return (
    Component: FC<WithActorComponentProps<T>>
  ): FC<ComponentWithActorProps> => {
    const WithActorWrap: FC<ComponentWithActorProps> = props => {
      const root = useRootMachine();
      const parent = useCurrentActorRef();

      const parentOrRootState = parent ? parent.state : root?.state;
      const childId = props.id
        ? props.id
        : parent
        ? id || (machine as T).id
        : id
        ? id
        : ROOT_TASK_ID;

      const childActor = parentOrRootState?.children?.[
        childId
      ] as ActorRefFrom<T>;

      return childActor ? (
        <CurrentActorRefProvider<T> actorRef={childActor}>
          <Component {...props} actorRef={childActor} />
        </CurrentActorRefProvider>
      ) : null;
    };

    WithActorWrap.displayName = `WithActorWrap:${
      'id' in machine ? machine.id : id
    }`;

    return WithActorWrap;
  };
};
