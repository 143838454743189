import { StagingAuditingItemStatuses } from '../StagingAuditingItem';
import { StagingAuditingItemStatus } from './StagingAuditingItemStatus';

export class StagingAuditingItemDraftStatus extends StagingAuditingItemStatus {
  public displayName = 'Borrador';

  public internalName = StagingAuditingItemStatuses.Draft;

  public statusColor = '#d7d7d7';

  public textColor = '#ffa300';
}
