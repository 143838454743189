import { Container, Location, ProductItem, Task, TaskTypes } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API } from '../../../../api/api';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import { ScanControlDigitMachine } from '../../capa-4/scan-control-digit/scan-control-digit.machine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../../capa-4/scan-item/ScanItemMachine';
import { DefaultScanLocationContext } from '../../capa-4/scan-location/ScanLocationMachine';
import {
  GoToHelpEvent,
  GoToOptionsEvent,
  MenuItemProps
} from '../../core/GenericOptions';

export interface AuditContainerTaskContext {
  task: Task | null;
  orderId: number | null;

  currentContainer: Container | null;
  containersToAudit: Container[];
  auditedContainers: Container[] | null;

  lastScannedItem: ProductItem | null;

  auditLocation: Location | null;

  payload: any;
}

export const DefaultAuditContainersTaskContext = {
  task:    null,
  orderId: null,

  currentContainer:  null,
  containersToAudit: [],
  auditedContainers: [],

  lastScannedItem: null,

  auditLocation: null,

  payload: null
};

const HELP = `Auditar Contenedores
  Escanee el contenedor a auditar y, a continuación, los bultos que contiene.`;

export const AuditContainersTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCWBjA1gSQC5gFtYAVAQ1kwDpUA7VXVUgG1QC8wBiABw01qjIUAMgHtSESIlBcRseqhE0pIAB6IAtAGYALJsoAOXQFYATAAZtARgDsANiMBOBwBoQAT0Qnbe7dc36nW2t9HVtfAF9w1zQsPEIScip6MAAnUgYaKAB5ADdUmJx8Ig4AOTAAdwBhRVxSWlTlGTkGRWU1BEt9a0pvEysHf2sTIxCTVw8EE0tbSm0AhwCjWydNe2tI6N44okEk-DSM7LyUgu3YDgAxWlRYAAsC-kbZeVakVUQjazMDB1tzbTM1msDkBLncnkslkonUsRksmiBJhCyxMGxApyKCQo1H26X4uXyW0xHAAgkwmGcCpI3k0Xko3u0lkZKA5rLpLFZLFNVvpxogEdoepYzLCjNomSZgZo0Rj4rtKOhFAAzVApAgPTIcRU0FVqjVQJ7NBT00DtDm-SjBTQjBzTbR-BF8jpwyiaCy2QxmEzDfRGfwyolyxKUAAiYHQTFIKX4+uqNFq9RSHAAyrUUrh9Ya6W1EMDoZD7Vz9JZfr7rE7NA5BQDpnDfiYq9ZLAHYpj5WGI1GY7x+HGEzRUhw+3UBykAPqVFJgdLU6TPFom94IdRIkyUEw6e0ikuAhuaJ32vRmT5mYu-Xe2luFIPYkMpERcWM1EepWDYGhh2AZPGKDj6kkAEYiOms4gLSC45ggDjDK6tjCposJVgsTolg4szeMKVhNk4hhXmc7b3o+PaZMOiZvh+cDfgupLkqRo6wHeD5cKB4HGpBXJeJaCILBufgcl0TqSmhXKmLa8x1vYkRRCANAiBI8BvLKOzBtcDDMGwYBZhBDIaLo3RLJWXQOKYNa8uCCCaJW65OJCwSSv4ILrNJSlYnsqR4pkBInIGRBaWxOmTLY3yaOY3jWGKnxcvu5khd8ZiVoCXQRdoAzaHhbbBtqurqsRBo0vO-mmogUVQvofznvY+gBMWKHaIK+jusCbJ-CWqLOT5rmUDwWD8Ls1QEFwTBgPgEB+a8RUdHVaGOEFQTmJKHGCay0LDA2HpTHMITpTeVAdpG0aZE+8YvikY2Lu0K72pQ8V9PaRifECcFGBWXQ-IiCK+o2x7bcp2LvkqwE5T1mSAcBuAAErTrA41gQVMPtHYaG+BYXRcsKDbaE6cGUJ8QRmE9dX+JKP2dYxRHA1AdGvu+n5UcalAALIiHkVOnflRrw4gDVrqeTheCe4pehWXzXV4kJGBYuj4w4JMEQ+R39tTFFfrQP6LqxnMIEC+jXfM-NfILYzmQ23THr41rHqYdh9LLiRnZB6iFjj3gLMCJkimZEzqJCrpVvYFinlMIw21JQA */
  createMachine<AuditContainerTaskContext>(
    {
      id:      TaskTypes.AuditContainersV0,
      context: DefaultAuditContainersTaskContext,
      initial: 'ScanningLocation',
      states:  {
        ScanningLocation: {
          invoke: {
            src:  ScanControlDigitMachine,
            id:   ScanControlDigitMachine.id,
            data: ctx => ({
              ...DefaultScanLocationContext,
              suggestLocation:  false,
              requiredLocation: {
                checkDigit: ctx.payload.auditLocation.checkDigit,
                name:       ctx.payload.auditLocation.name
              }
            }),
            onDone: {
              target: 'LocationScanned'
            }
          }
        },
        LocationScanned: {
          always: {
            target: 'SelectContainer'
          }
        },
        OpeningNewContainer: {
          entry:  assign({ currentContainer: null }),
          always: { target: 'SelectContainer' }
        },
        SelectContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              validContainers: ctx.containersToAudit
            }),
            onDone: [
              {
                actions: assign({
                  currentContainer: (context, event) =>
                    (event as any).container || (event as any).data.container
                }),
                target: 'IteratingOverItemsInContainer'
              }
            ]
          }
        },
        IteratingOverItemsInContainer: {
          invoke: {
            src:  ScanItemMachine,
            id:   ScanItemMachine.id,
            data: ctx => ({
              ...DefaultScanItemContext,
              validItems:        ctx.currentContainer?.inventoryItems,
              skipQuantityCheck: true
            }),
            onDone: {
              actions: assign({
                lastScannedItem: (context, event) =>
                  (event as any).item || (event as any).data.item
              }),
              target: 'MarkingItemAsAudited'
            }
          }
        },
        MarkingItemAsAudited: {
          invoke: {
            src:    'markItemAsAudited',
            onDone: {
              target: 'IteratingOverItemsInContainer'
            },
            onError: {
              target: 'IteratingOverItemsInContainer'
            }
          }
        },
        ConfirmAudit: {
          invoke: {
            src:    'completeTask',
            onDone: {
              target: 'AuditTaskCompleted'
            },
            onError: {
              // @TODO rollback?
              target: 'AuditTaskCompleted'
            }
          }
        },
        AuditTaskCompleted: {
          type: 'final'
        }
      },
      on: {
        goToOptions: {
          actions: 'triggerMenuScreen'
        },
        goToHelp: {
          actions: 'triggerHelpScreen'
        },
        finishAudit: {
          target: 'ConfirmAudit'
        },
        closeContainer: {
          target: 'OpeningNewContainer'
        }
      }
    },
    {
      actions: {
        triggerMenuScreen: (
          ctx,
          { send, triggerMenuScreen }: GoToOptionsEvent
        ) => {
          const options: MenuItemProps[] = [];
          options.push({
            label:   'Marcar Incidencia',
            onClick: () => send('markIncident')
          });

          options.push({
            label:   'Finalizar Auditoría',
            onClick: () => send('finishAudit')
          });

          if (ctx.currentContainer) {
            options.push({
              label:   'Cerrar Contenedor',
              onClick: () => send('closeContainer')
            });
          }

          triggerMenuScreen(options);
        },
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) => {
          triggerHelpScreen(HELP);
        }
      },
      services: {
        ...API
      }
    }
  );
