import {
  Container,
  InventoryCountingPayload,
  InventoryItem,
  Location,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { api } from '../../../../api/utils/axios-instance';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../../capa-4/scan-item/ScanItemMachine';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../../capa-4/scan-location/ScanLocationMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface InventoryCountingTaskContext {
  task: Task | null;
  payload: InventoryCountingPayload;

  title: string;
  hint: string;
  error: string;

  location: Location | null;
  item: InventoryItem | null;
  container: Container | null;
}

export const InventoryCountingTaskInitialContext = {
  task:    null,
  payload: null,

  title: '',
  hint:  '',
  error: '',

  location:  null,
  item:      null,
  container: null
};

const HELP = `Control de inventario
Escanee una ubicación, un sku, selecione un lote e ingrese la cantidad de unidades que se encuentran en dicho lote. `;

export const InventoryCountingTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<InventoryCountingTaskContext>(
    {
      id:      'InventoryCountingTaskMachine',
      initial: 'Initialize',
      states:  {
        Initialize: {
          always: [
            {
              cond:   'pickingLocation',
              target: 'ScanLocation'
            },
            { target: 'ScanContainer' }
          ]
        },
        ScanLocation: {
          invoke: {
            id:   ScanLocationMachine.id,
            src:  ScanLocationMachine,
            data: ctx => ({
              ...DefaultScanLocationContext,
              requiredLocation: {
                name: ctx.payload.locationName
              },
              validLocations: [{ name: ctx.payload.locationName }]
            }),
            onDone: {
              actions: 'assignLocation',
              target:  'ScanItem'
            }
          }
        },
        ScanContainer: {
          invoke: {
            id:   ScanContainerMachine.id,
            src:  ScanContainerMachine,
            data: ctx => ({
              ...DefaultScanContainerContext,
              requestedContainer: {
                lpn:      ctx.payload.containerLpn,
                location: {
                  name: ctx.payload.locationName
                }
              },
              validContainers: [{ lpn: ctx.payload.containerLpn }]
            }),
            onDone: {
              actions: 'assignContainer',
              target:  'ScanItem'
            }
          }
        },
        ScanItem: {
          invoke: {
            id:   ScanItemMachine.id,
            src:  ScanItemMachine,
            data: ctx => ({
              ...DefaultScanItemContext,
              container:     ctx.container,
              location:      ctx.location,
              requestedItem: {
                sku: ctx.payload.sku,
                lot: {
                  lotNumber: ctx.payload.lotNumber
                },
                product: {
                  name: ctx.payload.productName
                }
              },
              skipQuantityCheck:       true,
              status:                  ctx.payload.itemStatus,
              inventoryCountingItemId: ctx.payload.inventoryCountingItemId
            }),
            onDone: {
              actions: 'assignItem',
              target:  'UpdatingSAPStock'
            }
          }
        },
        UpdatingSAPStock: {
          invoke: {
            src:     'updateSAPStock',
            onDone:  'ConfirmingTask',
            onError: {
              actions: 'assignError',
              target:  'ScanItem'
            }
          }
        },
        ConfirmingTask: {
          on: {
            finishTask: 'CompleteTask',
            continue:   'ScanLocation'
          }
        },
        CompleteTask: {
          invoke: {
            src:    'completeTask',
            onDone: 'InventoryControlled'
          }
        },
        InventoryControlled: {
          type: 'final',
          data: ctx => ctx
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        ...UtilityGuards,
        pickingLocation: ctx => !ctx.payload.containerLpn
      },
      actions: {
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API,
        updateSAPStock: ctx => {
          return api.patch(
            `/inventory-counting-item/${ctx.payload.inventoryCountingItemId}/quantity`,
            { quantity: ctx.item?.quantity, inventoryItemId: ctx.item?.id }
          );
        }
      }
    }
  );
