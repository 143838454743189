import { ConsolidateContainersProcessStatuses } from '../ConsolidateContainersProcess';
import { ConsolidateContainersProcessStatus } from './ConsolidateContainersProcessStatus';

export class ConsolidateContainersProcessStatusCompleted extends ConsolidateContainersProcessStatus {
  public displayName = 'Completado';

  public internalName = ConsolidateContainersProcessStatuses.Completed;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
