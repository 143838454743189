import { StockTableStatuses } from '../StockTable';
import { StockItemStatus } from './StockItemStatus';

export class StockItemStatusReservedStatus extends StockItemStatus {
  public displayName = 'Reservado';

  public internalName = StockTableStatuses.Reserved;

  public statusColor = '#d7d7d7';

  public textColor = '#393838';
}
