import {
  Container,
  InventoryItem,
  InventoryItemStatuses,
  Location,
  ProductItemName
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import { ProductItem } from '../../../shared/utils/ProductItem';
import { PlaceItemInContainerMachine } from '../../capa-4/place-item-in-container/PlaceItemInContainerMachine';
import { ScanItemMachine } from '../../capa-4/scan-item/ScanItemMachine';
import { ScanLocationMachine } from '../../capa-4/scan-location/ScanLocationMachine';

export interface PickLineContext {
  scannedLocation: Location | null;
  location: Location | null;
  container: Container | null;
  currentItem: ProductItem | null;
  validLocations: Location[] | null;
  reservedItemLocation: Location | null;
  originalItem: ProductItem | null;
}

export const DefaultPickLineContext = {
  scannedLocation:      null,
  location:             null,
  container:            null,
  validLocations:       [],
  currentItem:          null,
  reservedItemLocation: null,
  originalItem:         null
};

export const PickLineMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlk3XwEkAXMVAYggHtCSCA3ZgazBIGUK1OqkSgADs1i4auVmJAAPRACYAjAA4SKgMwAGACwA2AJxqA7AfMaVugDQgAnogvmSxgKwmdRvScsmBt4AvsEOaFh4hKTiADZYYLT0VPgACriY3ARQAMKsNOgEYABOTKx8nDx8qfGYiSIpNMx5+AVFxQCykUUKktKy8khKiDo2JBoaHgZ6ah7mRjazHg7OCCZ6JCbrGmZ6RgY65rYaoeEYOEUxtfXJaRlZ+Ln5hYSlVAAqAKIdAPqpADIAQRynwAIj8qAA5H45ADykPegKhnwASr0pDI5PgFMoEOZDCR9jp1FYjCo9DpDisXB4dO5zEcVKZzKNNP5QmEQPhmBA4AoIhdomQhElREM+pjBqBcQYVNSEGojB53GSFmqNEY1OpzKcQAKosQSHEEqKUulMtkWm1Xuj+licSMtuN1mS9HoPBoDmp5ZoVJsPZTrIYNOY1AZdfrLiRzQ8nqg4mA6LbJdihriTBo6RZM0c9rKPHpzPLyVoDEEQ6TTIETCoI+cDURkwNU9KXBofVo3W61Do1N4PKYThygA */
  createMachine<PickLineContext>(
    {
      id:      'PickLine',
      initial: 'fetchingValidLocations',
      context: DefaultPickLineContext,
      states:  {
        fetchingValidLocations: {
          invoke: {
            src:    'getValidLocationsForPicking',
            id:     'getValidLocationsForPicking',
            onDone: {
              actions: 'assignValidLocations',
              target:  'scanLocation'
            }
          }
        },
        scanLocation: {
          id:      'ScanLocationState',
          initial: 'scanLocationMachine',
          states:  {
            scanLocationMachine: {
              invoke: {
                id:   ScanLocationMachine.id,
                src:  ScanLocationMachine,
                data: (context: PickLineContext) => ({
                  suggestedLocation: (context.currentItem as InventoryItem)
                    .location,
                  validLocations: [
                    ...context.validLocations!,
                    (context.currentItem as InventoryItem).location
                  ],
                  hint: 'Escanee ubicación de picking'
                }),
                onDone: [
                  {
                    actions: assign({
                      scannedLocation: (ctx, event) => event.data.location
                    }),
                    target: 'scanned'
                  }
                ]
              }
            },
            scanned: {
              type: 'final'
            }
          },
          onDone: [
            {
              cond:   'scanSuggedtedLocation',
              target: 'scanItem'
            },
            {
              actions: assign({
                reservedItemLocation: ctx =>
                  (ctx.currentItem as InventoryItem).location,
                originalItem: ctx => ctx.currentItem
              }),
              target: 'scanItem'
            }
          ]
        },
        scanItem: {
          invoke: {
            id:   ScanItemMachine.id,
            src:  ScanItemMachine,
            data: (context: PickLineContext) => ({
              requestedItem: context.currentItem,
              location:      context.scannedLocation,
              locationName:  context.scannedLocation?.name,
              type:          ProductItemName.InventoryItem,
              status:        context.reservedItemLocation
                ? InventoryItemStatuses.Available
                : InventoryItemStatuses.Reserved
            }),
            onDone: [
              {
                actions: assign({
                  currentItem: (ctx, event) => event.data.item
                }),
                target: 'placeItemInPickingContainer'
              }
            ]
          }
        },
        placeItemInPickingContainer: {
          invoke: {
            id:   PlaceItemInContainerMachine.id,
            src:  PlaceItemInContainerMachine,
            data: ctx => {
              return {
                requestedContainer:   ctx.container,
                type:                 ProductItemName.PickItem,
                item:                 ctx.currentItem,
                originalItem:         ctx.originalItem,
                reservedItemLocation: ctx.reservedItemLocation
              };
            },
            onDone: [
              {
                target: 'PickingComplete'
              }
            ]
          }
        },
        PickingComplete: {
          type: 'final',
          data: ctx => ({
            item: ctx.currentItem
          })
        }
      }
    },
    {
      guards: {
        scanSuggedtedLocation: ctx => {
          return (
            ctx.scannedLocation?.name ===
            (ctx.currentItem as InventoryItem).location.name
          );
        }
      },
      actions: {
        ...UtilityActions
      },
      services: {
        ...API
      }
    }
  );
