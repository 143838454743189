import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditingWave } from './AuditingWave';
import { Container } from './Container';
import { MopSorterExitProcess } from './MopSorterExitProcess';
import { WMSTransportOrder } from './WMSTransportOrder';
import {
  MopSorterExitItemCancelledStatus,
  MopSorterExitItemDeliveredStatus,
  MopSorterExitItemMovingStatus,
  MopSorterExitItemStatus
} from './mop-sorter-exit-item-status';

export const MopSorterExitItemStatuses = {
  Moving:    'moving',
  Delivered: 'delivered',
  Cancelled: 'cancelled'
};

@Entity()
export class MopSorterExitItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => AuditingWave, { name: 'auditing_wave_id' })
  auditingWaveId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => MopSorterExitProcess, {
    name: 'mop_sorter_exit_process_id'
  })
  mopSorterExitProcessId: number;

  @Field({ name: 'label' })
  label: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Status({
    [MopSorterExitItemStatuses.Moving]:    MopSorterExitItemMovingStatus,
    [MopSorterExitItemStatuses.Delivered]: MopSorterExitItemDeliveredStatus,
    [MopSorterExitItemStatuses.Cancelled]: MopSorterExitItemCancelledStatus
  })
  status: MopSorterExitItemStatus;

  @Needs(() => AuditingWave, 'auditingWaveId')
  auditingWave: AuditingWave;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Needs(() => MopSorterExitProcess, 'mopSorterExitProcessId')
  mopSorterExitProcess: MopSorterExitProcess;

  @Knows(() => WMSTransportOrder, 'mopSorterExitItemId')
  wmsTransportOrder: WMSTransportOrder;
}
