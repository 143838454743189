import { Task } from '@wms/domain';
import qs from 'qs';
import { UseListApi, useListApi, useManualApi } from './utils';

export function useAutoAssignTask(
  offset: number = 0,
  id?: number
): [Task, ReactUseApi.State, ReactUseApi.RequestFn] {
  return useManualApi<Task>({
    method: 'GET',
    url:    'task/pick',
    params: { offset, ...(id ? { id } : {}) }
  });
}

export function useFindTasks(
  types: string[],
  perPage: number
): UseListApi<Task> {
  return useListApi<Task>(
    {
      method: 'GET',
      url:    'task/assignable-tasks',
      params: {
        qs: qs.stringify({
          type:   types,
          status: 'pending'
        })
      }
    },
    perPage
  );
}
