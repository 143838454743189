import { InventoryCountStatus } from './InventoryCountStatus';

export class InventoryCountStatusProcessingStatus extends InventoryCountStatus {
  public displayName = 'Procesando';

  public internalName = 'processing';

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
