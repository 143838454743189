import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanItem } from '../scan-item/ScanItem';
import { ScanLocation } from '../scan-location/ScanLocation';
import { MoveItemMachine } from './MoveItemMachine';

export const MoveItem: FC = withActorRef(MoveItemMachine)(({ actorRef }) => {
  const [current] = useActor(actorRef);

  return (
    <>
      <Ink.Text>Trasladando Productos</Ink.Text>
      {current.matches('AwaitingItemScan') ? <ScanItem /> : null}

      {current.matches('AwaitingLocationScan') ||
      current.matches('AwaitingFromLocationScan') ? (
        <ScanLocation />
      ) : null}

      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}
    </>
  );
});
