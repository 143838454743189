import { BoxProps } from '@wms/client-core';
import React, { CSSProperties, FC, useMemo } from 'react';

type StyleAppliers = {
  [K in keyof BoxProps]: (value: NonNullable<BoxProps[K]>) => CSSProperties;
};

const styleAppliers: StyleAppliers = {
  flexDirection:  value => ({ flexDirection: value }),
  flexShrink:     value => ({ flexShrink: value }),
  justifyContent: value => ({ justifyContent: value }),
  width:          value => ({
    width: typeof value === 'string' ? value : `${value * 0.25}rem`
  }),
  height: value => ({
    height: typeof value === 'string' ? value : `${value * 0.25}rem`
  }),
  minHeight: value => ({
    minHeight: typeof value === 'string' ? value : `${value * 0.25}rem`
  }),
  margin:  value => ({ margin: `${value * 0.25}rem` }),
  marginX: value => ({
    marginLeft:  `${value * 0.25}rem`,
    marginRight: `${value * 0.25}rem`
  }),
  marginY: value => ({
    marginTop:    `${value * 0.25}rem`,
    marginBottom: `${value * 0.25}rem`
  }),
  marginTop:    value => ({ marginTop: `${value * 0.25}rem` }),
  marginBottom: value => ({ marginBottom: `${value * 0.25}rem` }),
  marginLeft:   value => ({ marginLeft: `${value * 0.25}rem` }),
  marginRight:  value => ({ marginRight: `${value * 0.25}rem` }),
  padding:      value => ({ padding: `${value * 0.25}rem` }),
  paddingX:     value => ({
    paddingLeft:  `${value * 0.25}rem`,
    paddingRight: `${value * 0.25}rem`
  }),
  paddingY: value => ({
    paddingTop:    `${value * 0.25}rem`,
    paddingBottom: `${value * 0.25}rem`
  }),
  paddingTop:    value => ({ paddingTop: `${value * 0.25}rem` }),
  paddingBottom: value => ({ paddingBottom: `${value * 0.25}rem` }),
  paddingLeft:   value => ({ paddingLeft: `${value * 0.25}rem` }),
  paddingRight:  value => ({ paddingRight: `${value * 0.25}rem` })
};

export const Box: FC<BoxProps> = props => {
  const style = useMemo<CSSProperties>(
    () =>
      Object.entries(props).reduce<CSSProperties>(
        (res, [key, value]) => {
          const applier = styleAppliers[key];
          return applier ? { ...res, ...applier(value) } : res;
        },
        { display: 'flex' }
      ),
    [props]
  );

  return <div style={style}>{props.children}</div>;
};
