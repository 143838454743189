import Ink from '@ink';
import React, { FC, useCallback, useMemo } from 'react';
import { useRootMachine } from '../../shared/Machine';

export interface TaskHelpProps {
  helpText: string;
}

export const TaskHelp: FC<TaskHelpProps> = ({ helpText }) => {
  const rootRef = useRootMachine();

  const goBack = useCallback(
    () => rootRef.send('quitHelpScreen'),
    [rootRef.send]
  );

  const help = useMemo(
    () => (
      <>
        {helpText.split('\n').map((line, i) => (
          <Ink.Text key={i} bold>
            {line}
          </Ink.Text>
        ))}
      </>
    ),
    [helpText]
  );

  return (
    <>
      <Ink.Header>
        <Ink.Text>AYUDA</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingTop={4} paddingX={2}>
        {help}

        <Ink.Box paddingTop={2}>
          <Ink.Button label='ATRÁS' onClick={goBack} />
        </Ink.Box>
      </Ink.Box>
    </>
  );
};

TaskHelp.displayName = 'TaskHelp';
