export interface ApiFieldOptions {
  type: any;
}

export interface ApiFieldObject {
  name: string | symbol;
  options?: ApiFieldOptions;
}

export interface ApiEntity {
  entity: any;
  fields: ApiFieldObject[];
}

const apiFieldsByEntity: Map<
  any,
  Map<string | symbol, ApiFieldOptions>
> = new Map();

export const ApiField = (options?: ApiFieldOptions): PropertyDecorator => {
  return (target, propertyKey) => {
    const fields = apiFieldsByEntity.get(target) || new Map();
    fields.set(propertyKey, options);
    apiFieldsByEntity.set(target, fields);
  };
};

export function getApiEntities(): ApiEntity[] {
  return Array.from(apiFieldsByEntity.entries(), ([entity, fields]) => ({
    entity,
    fields: Array.from(fields.entries(), ([name, options]) => ({
      name,
      options
    }))
  }));
}
