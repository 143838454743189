import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusPreparingStatus extends OrderItemStatus {
  public displayName = 'Preparando';

  public internalName = OrderItemStatuses.Preparing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
