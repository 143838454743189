import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { RestockLine } from '../../capa-4/restocking-line-machine/RestockLine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { RestockingTaskMachine } from './RestockingTaskMachine';

export const RestockingTask = withActorRef(RestockingTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const menuItems = [
      {
        key:         'assign-task',
        displayName: 'Continuar con más productos del contenedor.',
        event:       'continue'
      },
      { key: 'back', displayName: 'Volver al menu.', event: 'finishTask' }
    ];
    const handleClick = useCallback((id: string) => {
      const item = menuItems.find(i => i.key === id);
      if (item) send(item.event);
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.hint ? (
                'RESTOCKEANDO PRODUCTOS'
              ) : (
                <>
                  RESTOCKEANDO PRODUCTOS:{' '}
                  <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>

        {current.matches('ReachingStockContainer') ? (
          <>
            Alcance contenedor de origen para Restocking:
            <ScanContainer />
          </>
        ) : null}

        {current.matches('ReplenishingProducts') ? (
          <>
            <RestockLine />
          </>
        ) : null}

        {current.matches('ConfirmingTask') ? (
          <>
            ¿Desea continuar con otro contenedor?
            <Ink.Box flexDirection='column'>
              {menuItems.map(item => (
                <Ink.Button
                  key={item.key}
                  id={item.key}
                  label={item.displayName}
                  onClick={handleClick}
                />
              ))}
            </Ink.Box>
          </>
        ) : null}

        {current.matches('Initializing') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}
      </Ink.Box>
    );
  }
);
