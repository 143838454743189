import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Unique
} from '@skyframe/core';
import { LabelingDesk } from './LabelingDesk';
import { Order } from './Order';
import { Receipt } from './Receipt';
import { User } from './User';

@Entity()
export class Document {
  @PrimaryKey()
  id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'expired_at' })
  expiredAt: Date;

  @Field()
  extension: string;

  @Field()
  documentType: string;

  @Field()
  @Unique()
  fileName: string;

  @Knows(() => LabelingDesk, 'labelingDeskId')
  labelingDesk: LabelingDesk;

  @Knows(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Knows(() => Order, 'orderId')
  order: Order;

  @Needs(() => User, 'userId')
  user: User;

  @ForeignKey(() => User, { name: 'user_id' })
  userId: number;

  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @ForeignKey(() => LabelingDesk, { name: 'labeling_desk_id' })
  labelingDeskId: number;
}
