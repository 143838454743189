import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { PickingAlgorithm } from './PickingAlgorithm';
import { PickingZoneLocation } from './PickingZoneLocation';

@Entity()
export class PickingZone {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => PickingAlgorithm, { name: 'picking_algorithm_id' })
  pickingAlgorithmId: number;

  @Needs(() => PickingAlgorithm, 'pickingAlgorithmId')
  pickingAlgorithm: PickingAlgorithm;

  @Knows(() => PickingZoneLocation, 'pickingZoneId')
  pickingZoneLocations: PickingZoneLocation[];
}
