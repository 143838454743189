import { Container, Location, LocationTypeNames } from '@wms/domain';
import { Task } from '@wms/domain/src/models';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import {
  MoveContainerMachine,
  moveContainerInitialContext
} from '../move-container/MoveContainerMachine';
import { ScanContainerMachine } from '../scan-container/ScanContainerMachine';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../scan-location/ScanLocationMachine';

export interface WeightContainerContext {
  hint: string;

  error: string;

  weight: number;
  container: Container | null;
  lpn: string | undefined;

  location: Location;
  suggestedLocation: Location | null;
  type: string;
  task: Task | null;
}

export const DefaultWeightContainerContext = {
  hint: 'Escanee contenedor',

  error: '',

  weight:    0,
  container: null,
  lpn:       '',

  location:          { name: 'AB-LY-G4-C1' } as Location,
  suggestedLocation: null,
  type:              LocationTypeNames.Grid,
  task:              null
};

export const WeightContainerMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGED2A7ALgQwJbrACcB1MXKAC0wDpsB3PTfKNLPAwgZQGNt0BiCBjDV8AN1QBrEaxz4ipclVoNcTdCwxyOPPgnGpeTDAG0ADAF1EoAA6pYa3BmsgAHogC0ARgDMAVmo-MzMAFi8ANh8AJnDwgA4vAE4vABoQAE9EKIB2ROozPyjE6KKfOKDskIBfKrTZdgUyShp6RmZ6+S5eASJCVEJqGwAbbEwAM36AW2oOjkVmlTaNWaJddH10CSMndHMrJBA7B2N0F3cEDx8zcMCrry8ooKir4rTMhBy8gtKo0vKzSo1OpaBokJrKWDdeZUfh0cGYACiWCIkBcR0czgO50uvmoD2yBJ8iWuPnucXCb0QIXCeTiISi+L8sRC1y8QJAKzBShoYmwQ1wEFGYGhmH4aPsGNOWM8PligS8dLMUTMiUS8S8IT8lIQ8TyXgJRT82TMGriv3ZnJFg1G3Ao7RBnRFgmEok2UhkDrm8OtmFt9rYjvhGy2ox2e3Fxx2Zxl4Wy1Eenz8fjNJoV2vumrxNLiLxzD0iFs9jW5Pr9yyLXOa-F6-UGI3GUxmFatNhtdvLAa93ODhlDpksEcl0Yufh81AS2SKIX81L8xKT2tieVj2TucWysdjPkLneLCzGYF97agABleydnQRXRJpE3d5XlAej8wz9sMD237sBwd0Sdhx5IkCKIwnCFlEgZOc5xCdMrjHYJYiZM0QmyU0d20PdlF5flBXUKBLxEAxb0tb0sIFUMNA-Psv32WwJT-aULl+ah-GeLwCnJOIEiiTj0yNMxqFVJV-HCI0CXCNDQStUicOYatCD6AZhlGCZCGmYiS2k8ioEok5wx-OiowY5U8myOJEiZVc4lCMJFxpagVzXDcnO3dl0FQCA4BcdSFlaRwO3Qro+EHejQGxfU8jKScEiZIlVS1DJEC8IJqAJOllXzXximqWoOWbb1IT4EVgsM0LPAeLx8lJWMYnpPMTW1Ik4ns35QLM5VOLpCTAw0vkyMwYV4WKzFSouUkAk4iJBNCJLZW1OcbnXbI-AeYpyiTcScu85RW2ffzJMG-TI2GtwYzyNV6RAhIzHJadtTVPIoJzbjCmuHMuq7fdDzLU9zxKw4DOO7EczxISigKJMfGNVIEoQe5CjxWLollKyl3ejCeV6mSNCGqURo8Ip8lM8kZ3gpIfHTekohSkIrJVAEJsnNGHxobgKwAWVQMRUUOocGI8el8mnY1EissoROW9MaQq6zwiKM1fjNJmip5kKTtG4oBNA4CIium7yZh0yUtMlkzCJXxk2KGoaiAA */
  createMachine<WeightContainerContext>(
    {
      context: DefaultWeightContainerContext,
      id:      'WeightContainer',
      initial: 'initialize',
      states:  {
        initialize: {
          always: [
            {
              cond:    'containerIsSet',
              actions: 'assignLpnFromContainer',
              target:  'fetchingContainer'
            },
            {
              target: 'awaitingContainerScan'
            }
          ]
        },
        awaitingContainerScan: {
          invoke: {
            src:    ScanContainerMachine,
            onDone: [
              {
                actions: 'assignContainer',
                target:  'confirmSelectedContainer'
              }
            ],
            onError: [
              {
                actions:  'assignError',
                target:   'awaitingContainerScan',
                internal: false
              }
            ]
          }
        },
        fetchingContainer: {
          invoke: {
            src:    'findContainerByLpn',
            data:   ctx => ({ container: ctx.container?.lpn }),
            onDone: {
              actions: 'assignContainer',
              target:  'confirmSelectedContainer'
            },
            onError: {
              actions: 'assignError',
              target:  'awaitingContainerScan'
            }
          }
        },
        confirmSelectedContainer: {
          on: {
            confirmSelectedContainer: {
              target: 'scanWeight'
            }
          }
        },
        scanWeight: {
          on: {
            weightEntered: {
              actions: 'assignWeight',
              target:  'validateWeight'
            }
          }
        },
        validateWeight: {
          always: [
            {
              actions: 'assignWeightError',
              cond:    'weightInvalid',
              target:  'scanWeight'
            },
            {
              actions: 'assignWeightToContainer',
              target:  'patchingContainerWeight'
            }
          ]
        },
        patchingContainerWeight: {
          invoke: {
            src:    'patchContainerWeight',
            onDone: [
              {
                target: 'palletizeContainer'
              }
            ],
            onError: [
              {
                actions: 'assignWeightError',
                target:  'scanWeight'
              }
            ]
          }
        },
        palletizeContainer: {
          on: {
            palletizeContainer: {
              target: 'fetchingGridFour'
            }
          }
        },
        fetchingGridFour: {
          invoke: {
            src:    'findLocationByName',
            onDone: [
              {
                actions: ['assignLocation', 'assignSuggestedLocation'],
                target:  'awaitingLocationScan'
              }
            ]
          }
        },
        awaitingLocationScan: {
          invoke: {
            src:  ScanLocationMachine,
            id:   ScanLocationMachine.id,
            data: ctx => ({
              ...DefaultScanLocationContext,
              validLocations:    ctx.location?.children,
              suggestedLocation: ctx.suggestedLocation,
              hint:              'Escanee ubicación destino en grid cuatro'
            }),
            onDone: [
              {
                actions: 'assignLocation',
                target:  'validating'
              }
            ],
            onError: [
              {
                actions:  'assignError',
                target:   'awaitingLocationScan',
                internal: false
              }
            ]
          }
        },
        validating: {
          invoke: {
            src:  MoveContainerMachine,
            id:   MoveContainerMachine.id,
            data: ctx => ({
              ...moveContainerInitialContext,
              container: ctx.container,
              location:  ctx.location,
              task:      ctx.task
            }),
            onDone: [
              {
                actions: 'assignContainer',
                target:  'containerMoved'
              }
            ],
            onError: [
              {
                actions: 'assignError',
                target:  'awaitingContainerScan'
              }
            ]
          }
        },
        containerMoved: {
          type: 'final',
          data: context => ({ container: context.container })
        }
      }
    },
    {
      guards: {
        weightInvalid: ctx => {
          return ctx.weight < 0;
        },
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions,
        assignWeightError: assign({
          error: 'Error al asignar peso.'
        }),
        assignContainer: assign({
          container: (_ctx, event) => event.data.container
        }),
        assignLpnFromContainer: assign({
          lpn: ctx => ctx.container?.lpn
        }),
        assignWeightToContainer: assign({
          container: context => {
            if (context.container) {
              context.container.weight = context.weight;
            }
            return context.container;
          }
        }),
        assignSuggestedLocation: assign({
          suggestedLocation: ctx =>
            ctx.location.children.find(
              childrenLocation => childrenLocation.containers.length == 0
            ) || null
        })
      },
      services: { ...API }
    }
  );
