import { Entity, Field, Knows, PrimaryKey } from '@skyframe/core';
import { User } from './User';

@Entity()
export class UserRole {
  @PrimaryKey()
  id: number;

  @Field({ name: 'name' })
  name: string;

  @Field({ name: 'heirarchy_level' })
  heirarchyLevel: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => User, 'userRoleId')
  users: User[];
}
