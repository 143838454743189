import { Entity, Field, Knows, PrimaryKey, Unique } from '@skyframe/core';
import { Route } from './Route';

@Entity()
export class Driver {
  @PrimaryKey()
  id: number;

  @Field({ name: 'last_name' })
  lastName: string;

  @Field({ name: 'name' })
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'dni' })
  @Unique()
  dni: string;

  @Knows(() => Route, 'driverId')
  driverRoutes: Route[];

  @Knows(() => Route, 'coDriverId')
  coDriverRoutes: Route[];
}
