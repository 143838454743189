import Ink, { TextProps } from '@ink';
import React, {
  ComponentProps,
  FC,
  ReactElement,
  useCallback,
  useMemo
} from 'react';

interface ItemProps {
  isSelected?: boolean;
  label: string;
}

interface IndicatorProps {
  isSelected?: boolean;
}

export interface SelectListProps<T> {
  items: T[];
  trackBy: (item: T, index: number) => any;
  getDisplayText: (item: T, index: number) => string;
  onSelect: (item: T) => void;
}

const Item: FC<ItemProps> = ({ isSelected = false, label }) => {
  const textProps: TextProps = isSelected
    ? { color: 'greenBright', bold: true }
    : {};
  return <Ink.Text {...textProps}>{label}</Ink.Text>;
};

Item.displayName = 'Item';

const Indicator: FC<IndicatorProps> = ({ isSelected = false }) => (
  <Ink.Box marginRight={1}>
    {isSelected ? (
      <Ink.Text color='greenBright'>{'>'}</Ink.Text>
    ) : (
      <Ink.Text> </Ink.Text>
    )}
  </Ink.Box>
);

Indicator.displayName = 'Indicator';

const EXTRA_PROPS = {
  itemComponent:      Item,
  indicatorComponent: Indicator
};

export function SelectList<T>({
  items,
  trackBy,
  getDisplayText,
  onSelect
}: SelectListProps<T>): ReactElement {
  Ink.useInput(input => {
    const num = parseInt(input, 10);

    if (!isNaN(num)) {
      const item = items[num - 1];

      if (item) {
        onSelect(item);
      }
    }
  });

  const selectItems = useMemo(
    () =>
      items.map((item, i) => ({
        key:   trackBy(item, i),
        label: getDisplayText(item, i),
        value: item
      })),
    [items]
  );

  const onSelectItem = useCallback<
    NonNullable<ComponentProps<typeof Ink.SelectInput>['onSelect']>
  >(
    item => {
      onSelect(item.value as T);
    },
    [onSelect]
  );

  return (
    <Ink.SelectInput<T>
      items={selectItems}
      onSelect={onSelectItem}
      {...EXTRA_PROPS}
    />
  );
}

SelectList.displayName = 'SelectList';
