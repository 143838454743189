import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { AnyActorRef, AnyInterpreter } from 'xstate';
import { RootMachineProvider } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';

export interface PackingTaskAssignerProps {
  actorRef: AnyActorRef;
}

export const PackingTaskAssigner: FC<PackingTaskAssignerProps> = ({
  actorRef
}) => {
  const [current, send] = useActor(actorRef);
  const back = useCallback(() => send('back'), [send]);

  return (
    <RootMachineProvider
      state={current}
      send={send as any}
      interpreter={actorRef as AnyInterpreter}
    >
      <Ink.Box paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>TAREA DE ETIQUETADO</Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column'>
          {current.matches('EnteringPackingLocation') ? (
            <GetLocationByName />
          ) : null}

          {current.matches('EnteringContainer') ? (
            <Ink.Box flexDirection='column'>
              <GetContainerByLpn />
              <Ink.Button
                label='Volver a empezar'
                onClick={() => send({ type: 'RESET' })}
                id='reset'
                key='reset'
              />
            </Ink.Box>
          ) : null}

          {current.matches('ShowingFetchTaskError') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>Error al procesar la tarea:</Ink.Text>
              <Ink.Text color='redBright' bold>
                {current.context.error}
              </Ink.Text>
              <Ink.Button label='Regresar' onClick={back} />
            </Ink.Box>
          ) : null}
        </Ink.Box>
      </Ink.Box>
    </RootMachineProvider>
  );
};
