import Ink from '@ink';
import { Task } from '@wms/domain';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useAutoAssignTask } from '../../api/Task';
import { getErrorView } from '../shared/utils/getErrorView';
import {
  isCantAuditError,
  isSerializationError
} from '../shared/utils/isTaskError';

export interface AutoAssignTaskProps {
  setTask: React.Dispatch<React.SetStateAction<Task | undefined>>;
  taskId?: number;
  backToMenu: any;
  machine?: any;
}

const delay = Number.parseInt(process.env.DELAY_REFRESH as string) || 5;

export const AutoAssignTask: FC<AutoAssignTaskProps> = ({
  setTask,
  taskId,
  backToMenu
}) => {
  const [offset, setOffset] = useState<number>(0);
  const [selectedTask, selectedTaskStatus, selectTask] = useAutoAssignTask(
    offset,
    taskId
  );

  const [error, setError] = useState<string | null>();

  const incrementOffset = () => {
    setOffset(offsetMod => offsetMod + 1);
  };

  const resetOffset = () => {
    setOffset(0);
  };

  useEffect(() => {
    selectTask();
  }, []);

  useEffect(() => {
    const notFound = !selectedTaskStatus.loading && selectedTask === null;
    const errorView = notFound
      ? 'No se encontró tarea asignable'
      : getErrorView(selectedTaskStatus.error);

    let autoRefresh;
    const isSerializationErrorValue = isSerializationError(
      selectedTaskStatus.error
    );
    if (isSerializationErrorValue) {
      selectTask();
    }

    const isCantAuditErrorValue = isCantAuditError(selectedTaskStatus.error);
    if (isCantAuditErrorValue) {
      incrementOffset();
      selectTask();
    }

    if (errorView && !(isSerializationErrorValue || isCantAuditErrorValue)) {
      setError(errorView);
      resetOffset();
      autoRefresh = setTimeout(selectTask, Number(delay) * 1000);
    } else if (!selectedTaskStatus.loading && selectedTask) {
      resetOffset();
      setTask(selectedTask);
    }

    return () => {
      clearTimeout(autoRefresh);
    };
  }, [selectedTaskStatus, selectedTask]);

  const retryAssign = useCallback(() => selectTask(), [selectTask]);

  return (
    <>
      <Ink.Box paddingBottom={1}>
        <Ink.Header>
          <Ink.Text bold>Seleccionando Tarea</Ink.Text>
        </Ink.Header>
      </Ink.Box>

      {!!error && (
        <>
          <Ink.Box paddingX={1}>
            <Ink.Text color='redBright'>{error}</Ink.Text>
          </Ink.Box>
          <Ink.Box flexDirection='column' paddingX={1} paddingTop={1}>
            <Ink.Button
              label='VOLVER A BUSCAR'
              onClick={retryAssign}
              disabled={!error || !!selectedTask}
            />

            <Ink.Button label='ATRÁS' onClick={backToMenu} />
          </Ink.Box>
        </>
      )}
    </>
  );
};

AutoAssignTask.displayName = 'AutoAssignTask';
