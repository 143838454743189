import { ReservationStatuses } from '../Reservation';
import { ReservationStatus } from './ReservationStatus';

export class ReservationStatusProcessingStatus extends ReservationStatus {
  public displayName = 'Procesando';

  public internalName = ReservationStatuses.Processing;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
