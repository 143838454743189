import { InventoryItemStatuses } from '../InventoryItem';
import { InventoryItemStatus } from './InventoryItemStatus';

export class InventoryItemStatusAnomalyStatus extends InventoryItemStatus {
  public displayName = 'Con Incidencias';

  public internalName = InventoryItemStatuses.Anomaly;

  public statusColor = '#ffeff3';

  public textColor = '#b90c0c';
}
