import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { Order } from './Order';
import { Receipt } from './Receipt';
import { User } from './User';

@Entity()
export class Comment {
  @PrimaryKey()
  id: number;

  @Field({ name: 'content' })
  content: string;

  @ForeignKey(() => User, { name: 'author_id' })
  authorId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => User, 'authorId')
  author: User;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Knows(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Knows(() => Order, 'orderId')
  order: Order;
}
