import { ReceiptStatus } from './ReceiptStatus';

export class ReceiptStatusProcessingStatus extends ReceiptStatus {
  public displayName = 'Procesando';

  public internalName = 'processing';

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}
