import { MachineOptions } from 'xstate';
import { GetLocationByNameContext, GetLocationByNameFn } from './machine';

type GetLocationByNameServices = MachineOptions<
  GetLocationByNameContext,
  any
>['services'];

export const getLocationByNameServices: (
  fetchFn: GetLocationByNameFn
) => GetLocationByNameServices = fetchFn => ({
  fetchLocation: (context, _event) => fetchFn(context.locationName.trim())
});
