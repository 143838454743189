import { MachineOptions } from 'xstate';
import { MidiPickingWaveContext } from './machine';

type MidiPickingWaveGuards = MachineOptions<
  MidiPickingWaveContext,
  any
>['guards'];

export const midiPickingWaveGuards: MidiPickingWaveGuards = {
  hasPendingItems: (_ctx, evt) => {
    return evt.data.count > 0;
  },
  hasPendingItemsToSort: (_ctx, evt) => {
    return evt.data.count > 0;
  },
  isQuantityExact: (ctx, evt) => {
    return evt.data.quantity === ctx.currentPickItem?.quantity!;
  },
  isQuantityLess: (ctx, evt) => {
    return (
      evt.data.quantity < ctx.currentPickItem?.quantity! &&
      !(evt.data.quantity < 0)
    );
  },
  isInternalSupplyDelivery: (_ctx, evt) => {
    return evt.data.type === 'AB';
  },
  isSuggestedProduct: (ctx, evt) => {
    return evt.data.product.sku === ctx.currentPickItem.sku;
  }
};
