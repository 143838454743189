import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusDispatchedStatus extends OrderItemStatus {
  public displayName = 'Despachado';

  public internalName = OrderItemStatuses.Dispatched;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
