import { Entity, Field, Knows, PrimaryKey } from '@skyframe/core';
import { OrderItem } from './OrderItem';

export enum OrderItemTypes {
  Unidentified = 'unidentified',
  Sellers = 'hd-sellers',
  StorePickup = 'spu-sellers',
  Returned = 'returned',
  WMSHD = 'wms-hd',
  WMSSPU = 'wms-spu',
  WMS = 'wms',
  WMS_SS = 'wms-ss'
}

@Entity()
export class OrderItemType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => OrderItem, 'orderItemTypeId')
  orderItems: OrderItem[];
}
