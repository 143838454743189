import { StagingAuditingWaveStatuses } from '../StagingAuditingWave';
import { StagingAuditingWaveStatus } from './StagingAuditingWaveStatus';

export class StagingAuditingWaveProcessingStatus extends StagingAuditingWaveStatus {
  public displayName = 'Procesando';

  public internalName = StagingAuditingWaveStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
