import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { ApiField } from '../decorators';
import { AuditOrderItem } from './AuditOrderItem';
import { AuditingWave } from './AuditingWave';
import { Category2 } from './Category2';
import { ClassificationContainer } from './ClassificationContainer';
import { ClassificationItem } from './ClassificationItem';
import { ConsolidateContainersItem } from './ConsolidateContainersItem';
import { ConsolidatingContainersToStagingContainer } from './ConsolidatingContainersToStagingContainer';
import { ContainerType } from './ContainerType';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { MopPickingContainer } from './MopPickingContainer';
import { MopSorterExitItem } from './MopSorterExitItem';
import { Order } from './Order';
import { PackingLocationItem } from './PackingLocationItem';
import { PickingContainerGroupContainer } from './PickingContainerGroupContainer';
import { PickingWave } from './PickingWave';
import { Product } from './Product';
import { PutAwayItem } from './PutAwayItem';
import { Receipt } from './Receipt';
import { StagingAuditingContainer } from './StagingAuditingContainer';
import { StagingAuditingItem } from './StagingAuditingItem';
import { StockTransfer } from './StockTransfer';
import { ZoneClassifier } from './ZoneClassifier';
import { ReceiptStatusConfirmedStatus } from './receipt-status';

@Entity()
export class Container {
  @ApiField()
  @PrimaryKey()
  id: number;

  @ApiField()
  @Field()
  lpn: string;

  @ApiField()
  @Field({ name: 'location_date' })
  locationDate: Date;

  @ApiField()
  @Field({ name: 'is_anomaly' })
  isAnomaly: boolean;

  @ApiField()
  @Field({ name: 'is_closed' })
  isClosed: boolean;

  @ApiField()
  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ApiField()
  @ForeignKey(() => ContainerType, { name: 'container_type_id' })
  containerTypeId: number;

  @ApiField()
  @ForeignKey(() => ZoneClassifier, { name: 'zone_classifier_id' })
  zoneClassifierId: number;

  @ApiField()
  @ForeignKey(() => Category2, { name: 'category_id' })
  categoryId: number;

  @ApiField()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @ApiField()
  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @ApiField({ type: () => Category2 })
  @Knows(() => Category2, 'categoryId')
  category: Category2;

  @ApiField({ type: () => Receipt })
  @Knows(() => Receipt, 'receiptId')
  receipt: Receipt;

  @ApiField({ type: () => Order })
  @Knows(() => Order, 'orderId')
  order: Order;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @ForeignKey(() => PickingWave, { name: 'picking_wave_id' })
  pickingWaveId: number;

  @ApiField()
  @Field()
  labeled: boolean;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Location, 'locationId')
  location: Location;

  @Knows(() => InventoryItem, 'containerId')
  inventoryItems: InventoryItem[];

  @NeededBy(() => ClassificationContainer, 'containerId')
  classificationContainer: ClassificationContainer;

  @Needs(() => ContainerType, 'containerTypeId')
  containerType: ContainerType;

  @Knows(() => ZoneClassifier, 'zoneClassifierId')
  zoneClassifier: ZoneClassifier;

  @Knows(() => PutAwayItem, 'containerId')
  putAwayItems: PutAwayItem[];

  @Knows(() => PickingWave, 'pickingWaveId')
  pickingWave: PickingWave;

  @Field()
  weight: number;

  @Field({ name: 'weight_in_kg' })
  weightInKg: number;

  @ForeignKey(() => StockTransfer, { name: 'stock_transfer_id' })
  stockTransferId: number;

  @Knows(() => StockTransfer, 'stockTransferId')
  stockTransfer: StockTransfer;

  @ForeignKey(() => Container, { name: 'parent_id' })
  parentId: number;

  parent: Location;

  children: Location[];

  @NeededBy(() => AuditOrderItem, 'containerId')
  auditItems: AuditOrderItem[];

  @NeededBy(() => PackingLocationItem, 'containerId')
  packingLocationItem: PackingLocationItem;

  @NeededBy(() => PickingContainerGroupContainer, 'containerId')
  pickingContainerGroupContainer: PickingContainerGroupContainer;

  @NeededBy(() => ConsolidateContainersItem, 'containerId')
  consolidateContainersItems: ConsolidateContainersItem[];

  @NeededBy(() => MopPickingContainer, 'containerId')
  mopPickingContainers: MopPickingContainer[];

  @Knows(() => AuditingWave, 'targetContainerId')
  auditingWaves: AuditingWave[];

  @NeededBy(() => MopSorterExitItem, 'containerId')
  mopSorterExitItems: MopSorterExitItem[];

  @NeededBy(() => ConsolidatingContainersToStagingContainer, 'containerId')
  consolidatingContainersToStagingContainer: ConsolidatingContainersToStagingContainer;

  @Knows(() => ClassificationItem, 'containerId')
  classificationItem: ClassificationItem;

  @Knows(() => StagingAuditingContainer, 'containerId')
  stagingAuditingContainer: StagingAuditingContainer;

  @Knows(() => StagingAuditingItem, 'containerId')
  stagingAuditingItems: StagingAuditingItem[];
}

export function cantPutManyInSameContainer(
  receipt: Receipt,
  container: Container,
  product: Product
): boolean {
  const sameItemAndDisabledMultiItemsXContainer =
    !receipt.manySkuAllowedInContainer &&
    container.inventoryItems.some(ii => ii.productId !== product.id);
  return (
    receipt &&
    !(receipt.status instanceof ReceiptStatusConfirmedStatus) &&
    sameItemAndDisabledMultiItemsXContainer
  );
}
