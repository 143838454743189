import {
  Entity,
  Field,
  ForeignKey,
  IsNotEmpty,
  Knows,
  PrimaryKey
} from '@skyframe/core';
import { ApiField } from '../decorators';
import { Category2 } from './Category2';
import { CategoryLocation } from './CategoryLocation';
import { Product } from './Product';
import { ProductType } from './ProductType';

export const CategoryTypes = {
  Heading: 0,
  Family:  1
};

export type CategoryTypeTypes =
  typeof CategoryTypes[keyof typeof CategoryTypes];

@Entity()
export class Category {
  @ApiField()
  @PrimaryKey()
  id: number;

  @ApiField()
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'name' })
  name: string;

  @Field({ name: 'allow_all' })
  allowAll: boolean;

  @Field({ name: 'type' })
  type: CategoryTypeTypes;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'product_match' })
  productMatch: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'sap_category_code' })
  sapCategoryCode: number;

  @ForeignKey(() => ProductType, { name: 'parent_pt_id' })
  parentPTId: number;

  @Knows(() => ProductType, 'parentPTId')
  parent: ProductType;

  @Knows(() => Category2, 'parentCatId')
  children: Category2[];

  @Knows(() => CategoryLocation, 'categoryId')
  categoryLocation: CategoryLocation;

  @Knows(() => Product, 'categoryId')
  products: Product[];
}
