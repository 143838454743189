import { PickingWaveStatuses } from '../PickingWave';
import { PickingWaveStatus } from './PickingWaveStatus';

export class PickingWaveStatusCancelledStatus extends PickingWaveStatus {
  public displayName = 'Cancelado';

  public internalName = PickingWaveStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
