import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { MoveToPackingLocationMachine } from './machine';

export const MiniMoveToPackingLocationTask = withActorRef(
  MoveToPackingLocationMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);
  return (
    <Ink.Box paddingX={1}>
      <Ink.Header>
        <Ink.Text bold>Trasladar manualmente el paquete</Ink.Text>
      </Ink.Header>
      {current.matches('ScanningContainer') ? (
        <>
          <ScanContainer />
        </>
      ) : null}
      {current.matches('ScanningLocation') ? (
        <>
          <Ink.Box flexDirection='column'>
            <ScanLocation />
          </Ink.Box>
        </>
      ) : null}
      {current.matches('MoveConfirmed') ? (
        <>
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>El paquete se trasladó correctamente.</Ink.Text>
            <Ink.Button
              label='Continuar'
              onClick={() => send('confirm')}
              id='continue'
              key='continue'
            />
          </Ink.Box>
        </>
      ) : null}
      {current.matches('Error') ? (
        <>
          <Ink.Box flexDirection='column'>
            Hubo un error en la operación.
          </Ink.Box>
        </>
      ) : null}
    </Ink.Box>
  );
});
