import { MovingToAuditingLocationProcessStatuses } from '../MovingToAuditingLocationProcess';
import { MovingToAuditingLocationProcessStatus } from './MovingToAuditingLocationProcessStatus';

export class MovingToAuditingLocationProcessProcessingStatus extends MovingToAuditingLocationProcessStatus {
  public displayName = 'Procesando';

  public internalName = MovingToAuditingLocationProcessStatuses.Processing;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
