import { ConsolidateContainersItemStatuses } from '../ConsolidateContainersItem';
import { ConsolidateContainersItemStatus } from './ConsolidateContainersItemStatus';

export class ConsolidateContainersItemStatusCancelled extends ConsolidateContainersItemStatus {
  public displayName = 'Cancelado';

  public internalName = ConsolidateContainersItemStatuses.Cancelled;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
