import { AuditOrderStatuses } from '../AuditOrder';
import { AuditOrderStatus } from './AuditOrderStatus';

export class AuditOrderStatusPendingStatus extends AuditOrderStatus {
  public displayName = 'Pendiente';

  public internalName = AuditOrderStatuses.Pending;

  public statusColor = '#d7d7d7';
}
