import Ink from '@ink';
import {
  LogisticTaskTypes,
  RestockingTaskTypes,
  Task,
  TaskTypes
} from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC, useCallback, useEffect } from 'react';
import { useFindTasks } from '../../../../api/Task';
import { withActorRef } from '../../../shared/Machine';
import { Paginator } from '../../../shared/Paginator';
import { SelectList } from '../../../shared/SelectList';
import { TaskProcessType } from '../../taskIndex';
import { TaskSearcherMachine } from './machine';

function getEntityId(task: Task): number {
  const payload = task as any;
  return (
    payload?.payload?.pickingWaveId ||
    payload?.payload?.packingWaveId ||
    payload?.payload?.consolidateContainersProcessId ||
    payload?.payload?.context?.pickingWaveId ||
    payload?.payload?.container?.lpn ||
    payload?.receiptId ||
    payload?.payload?.context?.stockTransfer?.id ||
    payload?.payload?.stockTransfer?.id ||
    payload?.orderId ||
    payload?.pickingWaveId ||
    payload?.packingWaveId ||
    payload?.consolidateContainersProcessId ||
    payload?.consolidateContainersWaveId ||
    payload?.payload?.id ||
    payload?.id ||
    payload?.consolidatingToStagingContainerId ||
    payload?.payload?.consolidatingToStagingContainerId
  );
}

function getSkuReadjustment(payload): string {
  if (payload.context) {
    if (payload.context.inventoryItem) {
      return payload.context.inventoryItem.sku;
    } else if (payload.context.currentItem) {
      return payload.context.currentItem.sku;
    } else {
      return `(Tarea #${payload.id}`;
    }
  } else if (payload.product) {
    return payload.product.sku;
  } else if (payload.inventoryItem) {
    return payload.inventoryItem.sku;
  } else if (payload.currentItem) {
    return payload.currentItem.sku;
  } else {
    return `(Tarea #${payload.id}`;
  }
}

function getSkuRestocking(payload): string {
  if (payload.context) {
    if (payload.context.product) {
      return payload.context.product.sku;
    } else if (payload.context.sku) {
      return payload.context.sku;
    } else {
      return `(Tarea #${payload.id}`;
    }
  } else if (payload.product) {
    return payload.product.sku;
  } else if (payload.sku) {
    return payload.sku;
  } else {
    return `(Tarea #${payload.id}`;
  }
}

function getLogisticLpn(payload) {
  if (payload.context) {
    if (payload.context.container) {
      return payload.context.container.lpn;
    } else if (payload.context.containerLpn) {
      return payload.context.containerLpn;
    } else {
      `(Tarea #${payload.id}`;
    }
  } else if (payload.containerLpn) {
    return payload.containerLpn;
  } else {
    `(Tarea #${payload.id}`;
  }
}

function getContainerLpn(payload) {
  if ((payload.context && payload.context.container) || payload.container) {
    const lpn =
      (payload.context &&
        payload.context.container &&
        payload.context.container.lpn) ||
      payload.container.lpn;
    return lpn.substr(Math.max(0, lpn.length - 4));
  } else {
    return `(Tarea #${payload.id}`;
  }
}

function getOperationName(task: Task): string | null {
  const payload = task ? (task.payload as any) : null;
  if (task.type == TaskTypes.RestockInventoryV0 && task && task.orderId) {
    return `Completar SKU #${getSkuReadjustment(payload)} de OS #${
      task.orderId
    }`;
  } else if (task.type == TaskTypes.RestockInventoryV0) {
    return `Reposición de SKU #${getSkuRestocking(payload)} - ${getAisle(
      task
    )}`;
  } else if (task.type == LogisticTaskTypes.DownloadContainer) {
    return `Bajar contenedor #${getLogisticLpn(payload)} - ${getAisle(task)}`;
  } else if (task.type == LogisticTaskTypes.UploadContainer) {
    return `Subir contenedor #${getLogisticLpn(payload)} - ${getAisle(task)}`;
  } else if (task.type == TaskTypes.PickWaveV0) {
    return `Orden #${payload.orderId || task.orderId} - Ola de Picking #${
      payload.pickingWaveId || payload.context.pickingWaveId
    }`;
  } else if (task.type == TaskTypes.AuditContainersV0) {
    return `Auditar orden #${task.orderId}`;
  } else if (task.type == TaskTypes.MoveContainerToStorage) {
    return `Recep: ${task.receiptId} - 
    Cont: #${getContainerLpn(task.payload)} - 
    Ubi: ${
      (payload.container ? payload : payload.context).container.location.name
    }`;
  } else if (task.orderBatchId) {
    return `Grupo de Órdenes #${task.orderBatchId}`;
  } else if (task.type === TaskTypes.MaxiPickingWave) {
    return `Ola N°${
      payload.pickingWaveId || payload.context.pickingWaveId
    } - Selectivos Maxi`;
  } else if (task.type === TaskTypes.MidiPickingWave) {
    return `Ola N°${
      payload.pickingWaveId || payload.context.pickingWaveId
    } - PickTower [Midi]`;
  } else if (task.type === TaskTypes.MiniPickingWave) {
    return `Ola N°${
      payload.pickingWaveId || payload.context.pickingWaveId
    } - Mezzanine Nivel [N] [Mini]`;
  } else if (task.type == TaskTypes.MaxiConsolidateContainersTask) {
    return `Traslado #${
      payload.consolidateContainersWaveId ||
      payload.context.consolidateContainersWaveId
    } - Selectivos Maxi`;
  } else if (task.type === TaskTypes.BigPickingWave) {
    return `Ola N°${payload.pickingWaveId || payload.context.pickingWaveId} - ${
      payload.deliveryType || payload.context.deliveryType
    } - Autoestibas Big`;
  } else if (task.type === TaskTypes.MiniMopPickingWave) {
    return `Ola N°${
      payload.pickingWaveId || payload.context.pickingWaveId
    }  - AB TIE - Mezzanine Nivel [N]`;
  } else if (task.type === TaskTypes.ConsolidatingContainersToStagingType) {
    return `Traslado desde ubicacion ${
      payload.locationName || payload.context.locationName
    } en posicion ${payload.slotPosition || payload.context.slotPosition}`;
  }
  return null;
}

function getAisle(task: Task) {
  const payload = task.payload as any;

  if (
    (task.type == RestockingTaskTypes.restockInventory ||
      task.type == RestockingTaskTypes.ReadjustInventory) &&
    task &&
    task.orderId
  ) {
    return getHallwayName(getRestockingLocation(payload));
  } else if (
    task.type == LogisticTaskTypes.DownloadContainer ||
    task.type == LogisticTaskTypes.UploadContainer
  ) {
    return getHallwayName(getLogisticLocation(payload));
  } else {
    return 'Sin pasillo asignado';
  }
}

function getHallwayName(locationName): string {
  if (locationName) {
    return locationName.split('-').slice(0, 3).join('-');
  } else {
    return '';
  }
}

function getLogisticLocation(payload) {
  if (payload.context) {
    return payload.context.locationName;
  } else if (payload.locationName) {
    return payload.locationName;
  }
}

function getRestockingLocation(payload) {
  if (payload.context) {
    if (payload.context.container) {
      if (payload.context.container.location) {
        return payload.context.container.location.name;
      }
    } else if (payload.context.inventoryItem) {
      if (payload.context.inventoryItem.location) {
        return payload.context.inventoryItem.location.name;
      }
    } else if (payload.context.currentItem) {
      if (payload.context.currentItem.location) {
        return payload.context.currentItem.location.name;
      }
    }
  } else if (payload.container) {
    if (payload.container.location) {
      return payload.container.location.name;
    }
  } else if (payload.inventoryItem) {
    if (payload.inventoryItem.location) {
      return payload.inventoryItem.location.name;
    }
  }
}

export const TaskSearcher: FC = withActorRef(
  TaskSearcherMachine,
  TaskSearcherMachine.id
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);
  const taskProcess = TaskProcessType[current.context.menuItemKey!];

  const { page, request, hasPrev, hasNext, requestPrev, requestNext } =
    useFindTasks(taskProcess.validBucketTypes, 5);

  useEffect(() => {
    request();
  }, []);

  const onSelect = useCallback(
    (task: Task) => send({ type: 'taskSelected', data: { task } }),
    [send]
  );

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione Tarea de {taskProcess.label}</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingTop={2} padding={2}>
        <Ink.Text inline bold>
          Seleccione <Ink.Chalk greenBright>{taskProcess.entity}</Ink.Chalk>:
        </Ink.Text>

        {page ? (
          page.rows.length > 0 ? (
            <>
              <SelectList<Task>
                items={page.rows}
                trackBy={item => item.id}
                getDisplayText={item =>
                  getOperationName(item)
                    ? (getOperationName(item) as string)
                    : `${taskProcess?.entity} #${getEntityId(item)}`
                }
                onSelect={onSelect}
              />

              <Paginator
                hasPrev={hasPrev}
                hasNext={hasNext}
                onPrev={requestPrev}
                onNext={requestNext}
              />
            </>
          ) : (
            <Ink.Text color='redBright'>
              No hay tareas disponibles para {taskProcess.entity}
            </Ink.Text>
          )
        ) : (
          <Ink.Text>Cargando...</Ink.Text>
        )}
      </Ink.Box>
    </Ink.Box>
  );
});

TaskSearcher.displayName = 'TaskSearcher';
