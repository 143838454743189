import { InventoryCountingStatus } from './InventoryCountingStatus';

export class InventoryCountingStatusCancelledStatus extends InventoryCountingStatus {
  public displayName = 'Cancelado';

  public internalName = 'cancelled';

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
