import { ConsolidatingContainersToStagingProcessStatuses } from '../ConsolidatingContainersToStagingProcess';
import { ConsolidatingContainersToStagingProcessStatus } from './ConsolidatingContainersToStagingProcessStatus';

export class ConsolidatingContainersToStagingProcessCompletedStatus extends ConsolidatingContainersToStagingProcessStatus {
  public displayName = 'Completado';

  public internalName =
    ConsolidatingContainersToStagingProcessStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
