import { PickingToStagingItemStatuses } from '../PickingToStagingItem';
import { PickingToStagingItemStatus } from './PickingToStagingItemStatus';

export class PickingToStagingItemStatusCancelledStatus extends PickingToStagingItemStatus {
  public displayName = 'Cancelado';

  public internalName = PickingToStagingItemStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
