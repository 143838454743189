import { assign, MachineOptions } from "xstate";
import { MoveToPackingLocationContext } from "./machine";


type TransferToPackingLocationActionsType = MachineOptions<
MoveToPackingLocationContext,
  any
>['actions'];

export const TransferToPackingLocationActions: TransferToPackingLocationActionsType = {

  assignContainer: assign({
    container: (_ctx, event) => event.data.container
  }),
  assignLocationId: assign({
    locationId: (_ctx, event) => event.data.location.id
  }),

}