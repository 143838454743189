import { MachineOptions, assign } from 'xstate';
import { GetLocationByNameContext } from './machine';

type GetLocationByNameActions = MachineOptions<
  GetLocationByNameContext,
  any
>['actions'];

export const getLocationByNameActions: GetLocationByNameActions = {
  updateLocationName: assign({
    locationName: (_context, event) => event.locationName
  }),
  assignLocation: assign({
    location: (context, event) => event.data.data
  }),
  assignError: assign({
    error: (context, event) => event.data.error
  }),
  clearError: assign({
    error: (_context, _event) => null
  })
};
