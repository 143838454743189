import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { MopPickingContainer } from './MopPickingContainer';
import { MopSorterExitItem } from './MopSorterExitItem';
import { PackingItem } from './PackingItem';
import { PackingLocationItem } from './PackingLocationItem';
import { SortingItem } from './SortingItem';
import { TransportOrder } from './TransportOrder';

export const WMSTransportItems = {
  PackingLocationItem: 'packingLocationItemId',
  PackingItem:         'packingItemId',
  SortingItem:         'sortingItemId',
  MopPickingContainer: 'mopPickingContainerId',
  MopSorterExitItem:   'mopSorterExitItemId'
};
@Entity()
export class WMSTransportOrder {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => TransportOrder, { name: 'transport_order_id' })
  transportOrderId: number;

  @ForeignKey(() => PackingLocationItem, { name: 'packing_location_item_id' })
  packingLocationItemId: number;

  @ForeignKey(() => PackingItem, { name: 'packing_item_id' })
  packingItemId: number;

  @ForeignKey(() => SortingItem, { name: 'sorting_item_id' })
  sortingItemId: number;

  @ForeignKey(() => MopPickingContainer, { name: 'mop_picking_container_id' })
  mopPickingContainerId: number;

  @ForeignKey(() => MopSorterExitItem, { name: 'mop_sorter_exit_item_id' })
  mopSorterExitItemId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => TransportOrder, 'transportOrderId')
  transportOrder: TransportOrder;

  @Knows(() => PackingLocationItem, 'packingLocationItemId')
  packingLocationItem: PackingLocationItem;

  @Knows(() => PackingItem, 'packingItemId')
  packingItem: PackingItem;

  @Knows(() => SortingItem, 'sortingItemId')
  sortingItem: SortingItem;

  @Knows(() => MopPickingContainer, 'mopPickingContainerId')
  mopPickingContainer: MopPickingContainer;

  @Knows(() => MopSorterExitItem, 'mopSorterExitItemId')
  mopSorterExitItem: MopSorterExitItem;
}
