import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { Location } from './Location';
import { TaskRole } from './TaskRole';

@Entity()
export class LocationTaskRole {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ForeignKey(() => TaskRole, { name: 'task_role_id' })
  taskRoleId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Location, 'locationId')
  location: Location;

  @Needs(() => TaskRole, 'taskRoleId')
  taskRole: TaskRole;
}
