import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ProductItem } from '../../../shared/utils/ProductItem';
import { CreateLot } from '../create-lot/CreateLot';
import { ScanQTY } from '../scan-item/EnterQuantity';
import { ScanSKU } from '../scan-item/ScanSKU';
import { ScanLocation } from '../scan-location/ScanLocation';
import { CreateItemMachine } from './CreateItemMachine';

export const CreateItem: FC = withActorRef(CreateItemMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <>
        {current.matches('ScanSKU') ? (
          <ScanSKU
            barcodeScanned={barcode =>
              send({ type: 'productScanned', data: { identifier: barcode } })
            }
            suggestedItem={current.context.suggestedItem}
            requestedItem={current.context.requestedItem}
          />
        ) : null}

        {current.matches('ScanQTY') ? (
          <>
            <Ink.Text color='greenBright' bold inline>
              {current.context.scannedProduct?.sku} -{' '}
              {current.context.scannedProduct?.name}
            </Ink.Text>

            <ScanQTY
              packagingLevelUnit={
                (current.context.suggestedItem as any)?.packagingLevel?.name ||
                ''
              }
              quantityEntered={quantity =>
                send({
                  type: 'quantityEntered',
                  data: {
                    quantity: quantity
                  }
                })
              }
              suggestedItem={
                (current.context.suggestedItem as any).packagingLevel
                  ? ({
                      ...current.context.suggestedItem,
                      quantity:
                        (current.context.suggestedItem?.quantity as any) /
                        (current.context.suggestedItem as any).packagingLevel
                          ?.quantity
                    } as ProductItem)
                  : current.context.suggestedItem
              }
            />
          </>
        ) : null}

        {current.matches('ScanLocation') ? <ScanLocation /> : null}

        {current.matches('EnterLot') ? <CreateLot /> : null}

        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}
      </>
    );
  }
);
