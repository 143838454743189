import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusStockedStatus extends OrderItemStatus {
  public displayName = 'Almacenado';

  public internalName = OrderItemStatuses.Stocked;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
