import { Entity, Field, Knows, PrimaryKey } from '@skyframe/core';
import { Category2RejectionReason } from './Category2RejectionReason';
import { Incident } from './Incident';
import { PutAwayItem } from './PutAwayItem';

export enum RejectionReasonCodes {
  FALTANTE_DE_PIEZAS_EN_RECIBO_POR_CAJA = 'FALTANTE DE PIEZAS EN RECIBO POR CAJA',
  MISSING_PRODUCT_RESTOCKING_LEVEL = 'PRODUCTO SIN NIVEL DE RESTOCKING'
}

@Entity()
export class RejectionReason {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'is_reception_reason' })
  isReceptionReason: boolean;

  @Knows(() => Category2RejectionReason, 'rejectionReasonId')
  category2RejectionReasons: Category2RejectionReason[];

  @Knows(() => PutAwayItem, 'rejectionReasonId')
  putAwayItems: PutAwayItem[];

  @Knows(() => Incident, 'rejectionReasonId')
  Incidents: Incident[];
}
