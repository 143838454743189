import { LocationStatuses } from '../BaseLocation';
import { LocationStatus } from './LocationStatus';

export class LocationStatusUnavailableStatus extends LocationStatus {
  public displayName = 'Ocupado';

  public internalName = LocationStatuses.Unavailable;

  public statusColor = '#ba000d';
}
