import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusPendingToHoldStatus extends OrderItemStatus {
  public displayName = 'Por Retener';

  public internalName = OrderItemStatuses.PendingToHold;

  public statusColor = '#eddae2';

  public textColor = '#a32671';
}
