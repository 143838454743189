import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { OrderItem } from './OrderItem';
import { PickingProcess } from './PickingProcess';
import { PickingToStagingItem } from './PickingToStagingItem';
import { PickingWave } from './PickingWave';
import { Product } from './Product';
import { SortingItem } from './SortingItem';
import {
  PickItemStatus,
  PickItemStatusIdleStatus,
  PickItemStatusMissingStatus,
  PickItemStatusPickedStatus,
  PickItemStatusUnfulfilledStatus
} from './pick-item-status';

export const PickItemStatuses = {
  Idle:        'idle',
  Picked:      'picked',
  Missing:     'missing',
  Unfulfilled: 'unfulfilled'
};

@Entity()
export class PickItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => OrderItem, { name: 'order_item_id' })
  orderItemId: number;

  @Field({ name: 'pick_order' })
  pickOrder: number;

  @ForeignKey(() => PickingProcess, { name: 'picking_process_id' })
  pickingProcessId: number;

  sku: string;

  product: Product;

  @Field({ name: 'quantity' })
  quantity: number;

  @Field({ name: 'picked_quantity' })
  pickedQuantity: number;

  @Field({ name: 'picking_wave_id' })
  pickingWaveId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Status({
    [PickItemStatuses.Idle]:        PickItemStatusIdleStatus,
    [PickItemStatuses.Picked]:      PickItemStatusPickedStatus,
    [PickItemStatuses.Missing]:     PickItemStatusMissingStatus,
    [PickItemStatuses.Unfulfilled]: PickItemStatusUnfulfilledStatus
  })
  status: PickItemStatus;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Needs(() => OrderItem, 'orderItemId')
  orderItem: OrderItem;

  @Knows(() => PickingWave, 'pickingWaveId')
  pickingWave: PickingWave;

  @Knows(() => PickingProcess, 'pickingProcessId')
  pickingProcess: PickingProcess;

  @Knows(() => PickingToStagingItem, 'pickItemId')
  pickingToStagingItem: PickingToStagingItem;

  @NeededBy(() => SortingItem, 'pickItemId')
  sortingItem: SortingItem[];
}
