import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { CreateItem } from '../../capa-4/create-item/CreateItem';
import { PlaceItemInContainer } from '../../capa-4/place-item-in-container/PlaceItemInContainer';
import { ScanItem } from '../../capa-4/scan-item/ScanItem';
import { ReceiptLineMachine } from './ReceiptLineMachine';

export const ReceiptLine: FC = withActorRef(ReceiptLineMachine)(
  ({ actorRef }) => {
    const [current] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingTop={1}>
        {current.matches('initializing') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}
        <>
          {current.matches('ScanItem') ? <ScanItem /> : null}

          {current.matches('CreateItem') ? <CreateItem /> : null}

          {current.matches('PlaceItemInContainer') ? (
            <PlaceItemInContainer />
          ) : null}
        </>
      </Ink.Box>
    );
  }
);
