import React, { PropsWithChildren, ReactElement, createContext } from 'react';
import { ActorRefFrom, AnyActorRef, AnyStateMachine } from 'xstate';

export const currentActorRefContext = createContext<AnyActorRef>(null as any);
currentActorRefContext.displayName = 'currentActorRefContext';

export interface CurrentActorRefProviderProps<T extends AnyStateMachine> {
  actorRef: ActorRefFrom<T>;
}

export function CurrentActorRefProvider<T extends AnyStateMachine>({
  actorRef,
  children
}: PropsWithChildren<CurrentActorRefProviderProps<T>>): ReactElement {
  return (
    <currentActorRefContext.Provider value={actorRef}>
      {children}
    </currentActorRefContext.Provider>
  );
}

CurrentActorRefProvider.displayName = 'CurrentActorRefProvider';
