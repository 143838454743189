import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { PrintMachine, PrintMachineId } from './machine';

export const Print = withActorRef(
  PrintMachine,
  PrintMachineId
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  return (
    <Ink.Box flexDirection='column' paddingY={1}>
      {current.hasTag('loading') ? (
        <Ink.Text bold color='greenBright'>
          IMPRIMIENDO...
        </Ink.Text>
      ) : (
        <>
          {current.matches('ConfirmingPrinting') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>
                ¡Impresión terminada! Confirme que la impresión fue exitosa.
              </Ink.Text>
              <Ink.Button
                label='Confirmar'
                onClick={() => send('CONFIRM_PRINTING')}
                key='confirm'
                id='confirm'
              />
              <Ink.Button
                label='Reimprimir'
                onClick={() => send('REPRINT')}
                key='cancel'
                id='cancel'
              />
            </Ink.Box>
          )}

          {current.matches('Error') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>Error al imprimir las etiquetas</Ink.Text>
              <Ink.Text color='redBright' bold>
                {current.context.error}
              </Ink.Text>
              <Ink.Button
                label='Reintentar'
                onClick={() => send('retry')}
                key='cancel'
                id='cancel'
              />
              <Ink.Button
                label='Ignorar'
                onClick={() => send('ignore')}
                key='confirm'
                id='confirm'
              />
            </Ink.Box>
          )}
        </>
      )}
    </Ink.Box>
  );
});
