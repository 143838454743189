import { StagingAuditingWaveStatuses } from '../StagingAuditingWave';
import { StagingAuditingWaveStatus } from './StagingAuditingWaveStatus';

export class StagingAuditingWavePendingStatus extends StagingAuditingWaveStatus {
  public displayName = 'Pendiente';

  public internalName = StagingAuditingWaveStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
