import { 
    Entity, 
    Field, 
    PrimaryKey, 
    ForeignKey,
    Status,
    NeededBy
} from '@skyframe/core';

import { Needs } from '@skyframe/core';

import{
    PackingLocationProcessStatus,
    PackingLocationProcessStatusCompletedStatus,
    PackingLocationProcessStatusCancelledStatus,
    PackingLocationProcessStatusPendingStatus,
    PackingLocationProcessStatusProcessingStatus
} from './packing-location-process-status'

import { OrderBatch } from './OrderBatch';
import { PackingLocationItem } from './PackingLocationItem';

export const PackingLocationProcessStatuses = {
    Pending:    'pending',
    Processing: 'processing',
    Completed:  'completed',
    Cancelled:  'cancelled'
};

@Entity()
export class PackingLocationProcess {

  @PrimaryKey()
  id: number;

  @Status({
    [PackingLocationProcessStatuses.Pending]:       PackingLocationProcessStatusPendingStatus,
    [PackingLocationProcessStatuses.Processing]:    PackingLocationProcessStatusProcessingStatus,
    [PackingLocationProcessStatuses.Completed]:     PackingLocationProcessStatusCompletedStatus,
    [PackingLocationProcessStatuses.Cancelled]:     PackingLocationProcessStatusCancelledStatus
  })
  status: PackingLocationProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
  
  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(() => PackingLocationItem, 'packingLocationProcessId')
  packingLocationItems: PackingLocationItem[];

}