import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { LoadingScreen } from '../../../shared/LoadingScreen';
import { withActorRef } from '../../../shared/Machine';
import { MarkAsIncident } from '../../capa-4/mark-as-incident/MarkAsIncident';
import { MoveContainer } from '../../capa-4/move-container/MoveContainer';
import { MoveContainerTaskMachine } from './MoveContainerTaskMachine';

export const MoveContainerTask: FC = withActorRef(MoveContainerTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const menuItems = [
      {
        key:         'assign-task',
        displayName: 'Confirmar Almacenamiento',
        event:       'confirm'
      }
    ];
    const handleClick = useCallback((id: string) => {
      const item = menuItems.find(menuItem => menuItem.key === id);
      if (item) send(item.event);
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.hint ? (
                'TRASLADANDO CONTENEDOR'
              ) : (
                <>
                  TRASLADANDO CONTENEDOR:{' '}
                  <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>

        {current.matches('MovingContainer') ? (
          <>
            <MoveContainer />
          </>
        ) : null}
        {current.matches('ConfirmStorage') ? (
          <Ink.Box flexDirection='column' paddingTop={1}>
            <>
              <Ink.Box paddingY={1}>
                <Ink.Text>¿Confirmar Almacenamiento?</Ink.Text>
              </Ink.Box>

              <Ink.Box flexDirection='column'>
                {menuItems.map(item => (
                  <Ink.Button
                    key={item.key}
                    id={item.key}
                    label={item.displayName}
                    onClick={handleClick}
                  />
                ))}
              </Ink.Box>
            </>
          </Ink.Box>
        ) : null}

        {current.matches('Initializing') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}

        {current.matches('ConfirmingTask') ? <LoadingScreen /> : null}

        {current.matches('MarkAsIncident') ? <MarkAsIncident /> : null}
      </Ink.Box>
    );
  }
);
