import { Product } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';

export interface ScanSKUContext {
  product: Product | null;
  identifier: string | null;
  hint: string;
  error: string | null;
  validIdentifiers: string[] | null;
}

export const DefaultScanSKUContext: ScanSKUContext = {
  product:          null,
  identifier:       null,
  hint:             'Escanee SKU/EAN:',
  error:            null,
  validIdentifiers: null
};

export const ScanSKUMachine = createMachine<ScanSKUContext>(
  {
    id:      'ScanProductIdentifierMachine',
    initial: 'AwaitingIdentifierScan',
    states:  {
      AwaitingIdentifierScan: {
        on: {
          ChangedIdentifier: {
            actions: assign({
              identifier: (_, event) => event.data
            })
          },
          SubmittedIdentifier: [
            {
              cond:   'isValidIdentifier',
              target: 'FetchingProduct'
            },
            {
              target:  'AwaitingIdentifierScan',
              actions: 'errorInvalidProduct'
            }
          ]
        }
      },
      FetchingProduct: {
        invoke: {
          src:    'findProductByIdentifier',
          onDone: {
            actions: assign({ product: (_ctx, event) => event.data.product }),
            target:  'ProductFound'
          },
          onError: {
            actions: 'assignError',
            target:  'AwaitingIdentifierScan'
          }
        }
      },
      ProductFound: {
        type: 'final',
        data: ctx => ({ product: ctx.product })
      }
    }
  },
  {
    guards: {
      ...UtilityGuards,
      isValidIdentifier: (ctx, _event) => {
        if (!ctx.validIdentifiers) {
          return true;
        }
        if (!ctx.identifier) {
          return false;
        }
        return ctx.validIdentifiers.includes(ctx.identifier);
      }
    },
    actions: {
      ...UtilityActions
    },
    services: { ...API }
  }
);
