// Ver si pasar para capa4

import {
  ProductItemName,
  PutAwayItem,
  Receipt,
  ReceiptItem
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../../../api/api';

import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../../../../capa-4/scan-item/ScanItemMachine';

export interface RejectItemMachineContext {
  receipt: Receipt | null;
  item: ReceiptItem | null;
  putAwayItem: PutAwayItem | null;
  validItems: ReceiptItem[];
}

export const RejectItemMachineInitialContext = {
  receipt:     null,
  item:        null,
  putAwayItem: null,
  validItems:  []
};

export const RejectItemMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCuAXAggdwIYE8BhAewDt1cBLUsAJ1gBVdYBrAOmsvUtwBtKAXmADEiUAAdisLpTJiQAD0QAGADQh8KgL5b1aLHiJkK1Oo2bsAkujq5upKAHkAbnQBKYAMZhK49CXIqGnphTF5eazAAW1gAZXRiWlwYCHlJaW45JEVEACYATny2AEYANnyADgBWAHYK-NzlABYK9U0EAFoKtgBmJrKy6pqmnuVcptKdPQwcAgCTYPNWNgCAM0paKP1Z-GFPMnXN5EpPFmooNKkZLNAlBFLS3LZc4prS4oKWl9K2xFfu8pNfJVXI1Ro9YpVUoVHS6ECkYgQODybaGeZBMxMZacbh8QRgS4ZWSkeR3NQaRD5SZw1FzYwY+hYqw2JL2JyuWgeby+fz00z0QnXEnZO7FJrKNjNKmVCrNPrjX4IJpVNgjCaNXJVfLKnr1JpTEC0oyBflLdhrDZbGaGQWZYW3RA6ti1fJvOo1KrFfJin4UhCy57FHo9fLKMNVCqlLW5A1G9GmplsI1MkhRcS8MA2VLZdJC0mIUo1Iqh4E1ZSF3XlnqKyPOrUNIug0YtWPWukmxZM23E-OdBV+rrFXofV3KHplz2PfWwoA */
  createMachine<RejectItemMachineContext>(
    {
      id:      'Reject Item',
      context: RejectItemMachineInitialContext,
      initial: 'ScanItem',
      states:  {
        ScanItem: {
          invoke: {
            src:  ScanItemMachine,
            id:   ScanItemMachine.id,
            data: (context: RejectItemMachineContext) => ({
              ...DefaultScanItemContext,
              type:       ProductItemName.ReceiptItem,
              receiptId:  context.receipt?.id,
              validItems: context.validItems
            }),
            onDone: {
              actions: 'assignItem',
              target:  'RejectItem'
            }
          }
        },
        AddRejectionReason: {
          on: {
            setRejectionReason: {
              actions: 'assignRejectionReason',
              target:  'RejectItem'
            }
          }
        },
        RejectItem: {
          invoke: {
            src:    'rejectItem',
            onDone: {
              actions: [
                assign({
                  putAwayItem: (_context, event) => event.data.item
                }),
                'updateValidItemsQuantity'
              ],
              target: 'ItemRejected'
            },
            onError: {
              target: 'ScanItem'
            }
          }
        },

        ItemRejected: {
          type: 'final',
          data: context => ({ item: context.putAwayItem })
        }
      }
    },
    {
      guards: {
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions,
        updateValidItemsQuantity: assign({
          validItems: context => {
            context.validItems.forEach(item => {
              if (item.sku === context.item!.sku) {
                item.quantity -= context.item!.quantity;
              }
            });
            return context.validItems;
          }
        })
      },
      services: { ...API }
    }
  );
