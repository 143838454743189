import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  OwnedBy,
  PrimaryKey
} from '@skyframe/core';
import { ContainerCapacityRule } from './ContainerCapacityRule';
import { CustomerOrderItem } from './CustomerOrderItem';
import { Label } from './Label';
import { Lot } from './Lot';
import { OrderItem } from './OrderItem';
import { Product } from './Product';
import { ProductRestockingLevel } from './ProductRestockingLevel';
import { PurchaseOrderItem } from './PurchaseOrderItem';
import { ReceiptItem } from './ReceiptItem';
import { TransferOrderItem } from './TransferOrderItem';
import { UOM } from './UOM';

@Entity()
export class PackagingLevel {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  ean: number;

  @Field()
  quantity: number;

  @Field()
  weight: number;

  @Field()
  width: number;

  @Field()
  height: number;

  @Field()
  depth: number;

  @Field({ name: 'is_default' })
  isDefault: boolean;

  @Field({ name: 'is_main' })
  isMain: boolean;

  @Field({ name: 'is_sales' })
  isSales: boolean;

  @Field({ name: 'is_purchase' })
  isPurchase: boolean;

  @ForeignKey(() => UOM, { name: 'uom_id' })
  uomId: number;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => UOM, 'uomId')
  uom: UOM;

  @Knows(() => Label, 'packagingLevelId')
  labels: Label[];

  @OwnedBy(() => Product, 'productId')
  product: Product;

  @Knows(() => ReceiptItem, 'packagingLevelId')
  receiptItems: ReceiptItem[];

  @Knows(() => OrderItem, 'packagingLevelId')
  orderItems: OrderItem[];

  @NeededBy(() => PurchaseOrderItem, 'packagingLevelId')
  purchaseOrderItems: PurchaseOrderItem[];

  @Knows(() => CustomerOrderItem, 'packagingLevelId')
  customerOrderItems: CustomerOrderItem[];

  @NeededBy(() => ProductRestockingLevel, 'packagingLevelId')
  productRestockingLevels: ProductRestockingLevel[];

  @Knows(() => TransferOrderItem, 'packagingLevelId')
  transferOrderItems: TransferOrderItem[];

  @Knows(() => Lot, 'packagingLevelId')
  lot: Lot;

  @NeededBy(() => ContainerCapacityRule, 'packagingLevelId')
  containerRules: ContainerCapacityRule[];
}
