import { MachineOptions } from 'xstate';
import { GetProductBySKUContext, GetProductBySKUFn } from './machine';

type GetProductBySKUServices = MachineOptions<
  GetProductBySKUContext,
  any
>['services'];

export const getProductBySKUServices: (
  fetchFn: GetProductBySKUFn
) => GetProductBySKUServices = fetchFn => ({
  fetchProduct: (context, _event) => fetchFn(context.productSKU.trim())
});
