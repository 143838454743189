import { MachineOptions } from 'xstate';
import { MaxiPickingWaveContext } from './machine';

type MaxiPickingWaveGuards = MachineOptions<
  MaxiPickingWaveContext,
  any
>['guards'];

export const maxiPickingWaveGuards: MaxiPickingWaveGuards = {
  hasNextPickItem: (_ctx, evt) => {
    return !!evt.data.pickItem;
  },
  hasAlreadyBeenPicked: (ctx, evt) => {
    return evt.data.pickItem.id === ctx.currentPickItem?.id;
  },
  palletTruckTypeIsDoubleAndContainerLeftToCreate: (ctx, _evt) => {
    return (
      ctx.palletTruckType === 'doublepalletjack' &&
      ctx.createdContainersLpn.length < 2
    );
  },
  hasMoreContainers: (ctx, evt) => {
    return evt.data;
  },
  ContainerWasForcedToClose: (ctx, _evt) => {
    return ctx.containerWasForcedToClose;
  },
  containerIsValid: (_ctx, evt) => {
    return evt.data;
  }
};
