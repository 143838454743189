// Ver si pasar para capa4

import { Container } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../../../api/api';
import { api } from '../../../../../../api/utils/axios-instance';

export interface ContainersListMachineContext {
  containers: Container[];
  currentContainer: Container | null;
  onlyCloses: boolean;
  containerIds: number[];
}

export const DefaultContainersListMachineContext = {
  containers:       [],
  currentContainer: null,
  onlyCloses:       false,
  containerIds:     []
};

export const ContainersListMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCuAXAggdwIYE8BhAewDt1cBLUsAJ1gBVdYBrAOmsvUtwBtKAXmADEiUAAdisLpTJiQAD0QAGADQh8KgL5b1aLHiJkK1Oo2bsAkujq5upKAHkAbnQBKYAMZhK49CXIqGnphTF5eazAAW1gAZXRiWlwYCHlJaW45JEVEACYATny2AEYANnyADgBWAHYK-NzlABYK9U0EAFoKtgBmJrKy6pqmnuVcptKdPQwcAgCTYPNWNgCAM0paKP1Z-GFPMnXN5EpPFmooNKkZLNAlBFLS3LZc4prS4oKWl9K2xFfu8pNfJVXI1Ro9YpVUoVHS6ECkYgQODybaGeZBMxMZacbh8QRgS4ZWSkeR3NQaRD5SZw1FzYwY+hYqw2JL2JyuWgeby+fz00z0QnXEnZO7FJrKNjNKmVCrNPrjX4IJpVNgjCaNXJVfLKnr1JpTEC0oyBflLdhrDZbGaGQWZYW3RA6ti1fJvOo1KrFfJin4UhCy57FHo9fLKMNVCqlLW5A1G9GmplsI1MkhRcS8MA2VLZdJC0mIUo1Iqh4E1ZSF3XlnqKyPOrUNIug0YtWPWukmxZM23E-OdBV+rrFXofV3KHplz2PfWwoA */
  createMachine<ContainersListMachineContext>(
    {
      id:      'Containers List',
      context: DefaultContainersListMachineContext,
      initial: 'ListContainers',
      states:  {
        ListContainers: {
          on: {
            closeAll: {
              actions: assign({
                currentContainer: (_ctx, event) => event.data.container,

                containerIds: (_ctx, event) =>
                  event.data.containers.map(container => container.id)
              }),
              target: 'CloseAllContainers'
            }
          }
        },
        CloseAllContainers: {
          invoke: {
            src:    'closeAllContainers',
            onDone: [
              {
                target: 'Finish'
              }
            ]
          }
        },
        Finish: {
          type: 'final',
          data: ctx => ({ container: ctx.currentContainer })
        }
      }
    },
    {
      guards: {
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions
      },
      services: {
        ...API,
        closeAllContainers: (ctx: ContainersListMachineContext) =>
          api.patch('/container/close-all', {
            containerIds: ctx.containerIds
          })
      }
    }
  );
