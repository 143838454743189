import { StagingAuditingContainerStatuses } from '../StagingAuditingContainer';
import { StagingAuditingContainerStatus } from './StagingAuditingContainerStatus';

export class StagingAuditingContainerDraftStatus extends StagingAuditingContainerStatus {
  public displayName = 'Borrador';

  public internalName = StagingAuditingContainerStatuses.Draft;

  public statusColor = '#d7d7d7';

  public textColor = '#ffa300';
}
