import {
  Container,
  InventoryItem,
  InventoryItemStatuses,
  Location,
  Lot
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { UtilityActions } from '../../../../api/api';
import { api } from '../../../../api/utils/axios-instance';
import { ProductItem } from '../../../shared/utils/ProductItem';
import {
  CreateContainerMachine,
  createContainerMachineInitialContext
} from '../create-container/CreateContainerMachine';
import {
  MoveItemMachine,
  moveItemInitialContext
} from '../move-item/MoveItemMachine';
import {
  PlaceItemInContainerMachine,
  placeItemInContainerInitialContext
} from '../place-item-in-container/PlaceItemInContainerMachine';
import { RemoveItemFromContainerMachine } from '../remove-item-from-container/RemoveItemFromContainerMachine';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../scan-container/ScanContainerMachine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../scan-item/ScanItemMachine';

export interface RestockLineContext {
  container: Container | null;
  item: ProductItem | null;
  lot: Lot | null;
  restockedItems: ProductItem[];
  itemToRestock: ProductItem | null;
  containers: Container[];
  destinationContainer?: Container | null;
  location?: Location | null;
  pickingWaveId?: number;
  pickingProcessId?: number;
  orderId?: number;
  isRestocking: boolean | null;
  isBulkItem: boolean | null;
}

export const DefaultRestockLineContext: RestockLineContext = {
  location:       null,
  container:      null,
  item:           null,
  lot:            null,
  restockedItems: [],
  itemToRestock:  null,
  containers:     [],
  isRestocking:   null,
  isBulkItem:     null
};

export const RestockLineMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlk3XwEkAXMVAYggHtCSCA3ZgazBIGUK1OqkSgADs1i4auVmJAAPRACYAjAA4SKgMwAGACwA2AJxqA7AfMaVugDQgAnogvmSxgKwmdRvScsmBt4AvsEOaFh4hKTiADZYYLT0VPgACriY3ARQAMKsNOgEYABOTKx8nDx8qfGYiSIpNMx5+AVFxQCykUUKktKy8khKiDo2JBoaHgZ6ah7mRjazHg7OCCZ6JCbrGmZ6RgY65rYaoeEYOEUxtfXJaRlZ+Ln5hYSlVAAqAKIdAPqpADIAQRynwAIj8qAA5H45ADykPegKhnwASr0pDI5PgFMoEOZDCR9jp1FYjCo9DpDisXB4dO5zEcVKZzKNNP5QmEQPhmBA4AoIhdomQhElREM+pjBqBcQYVNSEGojB53GSFmqNEY1OpzKcQAKosQSHEEqKUulMtkWm1Xuj+licSMtuN1mS9HoPBoDmp5ZoVJsPZTrIYNOY1AZdfrLiRzQ8nqg4mA6LbJdihriTBo6RZM0c9rKPHpzPLyVoDEEQ6TTIETCoI+cDURkwNU9KXBofVo3W61Do1N4PKYThygA */
  createMachine<RestockLineContext>(
    {
      id:      'RestockLine',
      initial: 'Initialize',
      context: DefaultRestockLineContext,
      states:  {
        Initialize: {
          entry: assign({
            itemToRestock: ctx => ctx.item
          }),
          always: [
            {
              cond:   'checkIfBulkItem',
              target: 'ScanItem'
            },
            { target: 'ScanOriginContainer' }
          ]
        },

        ScanOriginContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              requestedContainer: ctx.container
            }),
            onDone:  'ScanItem',
            onError: 'ScanOriginContainer'
          }
        },

        ScanItem: {
          invoke: {
            id:   ScanItemMachine.id,
            src:  ScanItemMachine,
            data: (context: RestockLineContext) => ({
              ...DefaultScanItemContext,
              requestedItem: context.item,
              location:      (context.item as InventoryItem).location,
              container:
                context.container || (context.item as InventoryItem).container,
              lotNumber:
                context.lot?.lotNumber ||
                (context.item as InventoryItem)?.lotNumber,
              status: !context.location
                ? InventoryItemStatuses.Reserved
                : undefined,
              isBulkItem: !!context.isBulkItem
            }),
            onDone: [
              {
                actions: 'assignItem',
                cond:    'isReadjusting',
                target:  'GetContainers'
              },
              { actions: 'assignItem', target: 'RemoveItemFromContainer' }
            ]
          }
        },

        // PW or CAS Readjusting
        GetContainers: {
          invoke: {
            src:    'getProcessContainers',
            onDone: {
              actions: 'assignContainers',
              target:  'SelectDestinationContainer'
            },
            onError: 'ScanItem'
          }
        },

        SelectDestinationContainer: {
          on: {
            create: 'CreateContainer',
            select: {
              actions: 'assignDestinationContainer',
              target:  'ScanDestinationContainer'
            }
          }
        },
        CreateContainer: {
          invoke: {
            src:  CreateContainerMachine,
            id:   CreateContainerMachine.id,
            data: ctx => ({
              ...createContainerMachineInitialContext,
              pickingWaveId:    ctx.pickingWaveId,
              pickingProcessId: ctx.pickingProcessId,
              hint:             'Cree contenedor en ubicación de consolidación'
            }),
            onDone: {
              actions: ['addToContainers'],
              target:  'SelectDestinationContainer'
            }
          }
        },

        ScanDestinationContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              requestedContainer: ctx.destinationContainer,
              validContainers:    [ctx.destinationContainer]
            }),
            onDone: {
              actions: 'assignDestinationContainer',
              target:  'PlaceItemIntoContainer'
            }
          }
        },

        PlaceItemIntoContainer: {
          invoke: {
            src:  PlaceItemInContainerMachine,
            id:   PlaceItemInContainerMachine.id,
            data: ctx => ({
              ...placeItemInContainerInitialContext,
              container:          ctx.destinationContainer,
              requestedContainer: ctx.destinationContainer,
              item:               ctx.item
            }),
            onDone: [
              {
                cond:    'quantityRestocked',
                target:  'RestockComplete',
                actions: 'addToRestockedItems'
              },
              { target: 'ScanItem', actions: 'addToRestockedItems' }
            ]
          }
        },
        // End PW or CAS Readjusting

        RemoveItemFromContainer: {
          invoke: {
            id:   RemoveItemFromContainerMachine.id,
            src:  RemoveItemFromContainerMachine,
            data: ctx => {
              return {
                requestedContainer: ctx.container,
                container:          ctx.container,
                item:               ctx.item,
                hint:               '',
                isBulkItem:         ctx.isBulkItem
              };
            },
            onDone: {
              actions: assign({
                item: (ctx, event) => event.data.item
              }),
              target: 'MoveItem'
            }
          }
        },
        MoveItem: {
          invoke: {
            id:   MoveItemMachine.id,
            src:  MoveItemMachine,
            data: ctx => ({
              ...moveItemInitialContext,
              item:             ctx.item,
              requiredLocation: ctx.location,
              hint:             'Restockeando ubicación de Picking'
            }),
            onDone: [
              {
                cond:    'quantityRestocked',
                target:  'RestockComplete',
                actions: 'addToRestockedItems'
              },
              {
                target: 'ScanItem'
              }
            ]
          }
        },
        RestockComplete: {
          type: 'final',
          data: ctx => ({
            item: ctx.item
          })
        }
      }
    },
    {
      guards: {
        quantityRestocked: (ctx, event) => {
          const restockedQuantity: number =
            ctx.restockedItems.reduce(
              (quantity, accItem) => quantity + accItem.quantity,
              0
            ) + (event.data.item?.quantity || 0);
          return restockedQuantity >= (ctx.itemToRestock?.quantity || 0);
        },
        isReadjusting:   ctx => !ctx.location,
        checkIfBulkItem: ctx => !!ctx.isBulkItem
      },
      actions: {
        ...UtilityActions,
        assignContainers: assign({
          containers: (_ctx, event) => event.data.containers
        }),
        addToRestockedItems: assign({
          restockedItems: (ctx, event) => [
            ...ctx.restockedItems,
            event.data.item
          ]
        }),
        addToContainers: assign({
          containers: (ctx, event) => [...ctx.containers, event.data.container]
        }),
        assignDestinationContainer: assign({
          destinationContainer: (_ctx, event) => event.data.container
        })
      },
      services: {
        getProcessContainers: async ctx => {
          const { path, id } = ctx.pickingWaveId
            ? { path: 'picking-wave', id: ctx.pickingWaveId }
            : { path: 'picking-process', id: ctx.pickingProcessId };

          const { data: containers } = await api.get(
            `${path}/${id}/containers`
          );

          return { containers };
        }
      }
    }
  );
