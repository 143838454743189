import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { OrderBatch } from './OrderBatch';
import { PackingItem } from './PackingItem';
import {
  SorterExitProcessStatus,
  SorterExitProcessStatusCancelledStatus,
  SorterExitProcessStatusCompletedStatus,
  SorterExitProcessStatusPendingStatus,
  SorterExitProcessStatusProcessingStatus
} from './sorter-exit-process-status';

export const SorterExitProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class SorterExitProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [SorterExitProcessStatuses.Cancelled]:
      SorterExitProcessStatusCancelledStatus,
    [SorterExitProcessStatuses.Completed]:
      SorterExitProcessStatusCompletedStatus,
    [SorterExitProcessStatuses.Pending]:    SorterExitProcessStatusPendingStatus,
    [SorterExitProcessStatuses.Processing]:
      SorterExitProcessStatusProcessingStatus
  })
  status: SorterExitProcessStatus;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @Knows(() => PackingItem, 'sorterExitProcessId')
  packingItems: PackingItem[];
}
