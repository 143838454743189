import { AuditingWaveStatuses } from '../AuditingWave';
import { AuditingWaveStatus } from './AuditingWaveStatus';

export class AuditingWavePendingStatus extends AuditingWaveStatus {
  public displayName = 'Pendiente';

  public internalName = AuditingWaveStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
