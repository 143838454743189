import { MopPickingContainerStatuses } from '../MopPickingContainer';
import { MopPickingContainerStatus } from './MopPickingContainerStatus';

export class MopPickingContainerPickingStatus extends MopPickingContainerStatus {
  public displayName = 'Pickeando';

  public internalName = MopPickingContainerStatuses.Picking;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
