import { MopSorterExitProcessStatuses } from '../MopSorterExitProcess';
import { MopSorterExitProcessStatus } from './MopSorterExitProcessStatus';

export class MopSorterExitProcessCompletedStatus extends MopSorterExitProcessStatus {
  public displayName = 'Completado';

  public internalName = MopSorterExitProcessStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
