import { InventoryItemStatuses } from '../InventoryItem';
import { InventoryItemStatus } from './InventoryItemStatus';

export class InventoryItemStatusReservedStatus extends InventoryItemStatus {
  public displayName = 'Reservado';

  public internalName = InventoryItemStatuses.Reserved;

  public statusColor = '#ffeff3';

  public textColor = '#b90c0c';
}
