import {
  ClassificationContainer,
  ClassificationItem,
  ContainerType,
  Customer,
  Location
} from '@wms/domain';
import { createMachine } from 'xstate';

import {
  DefaultScanLabelContext,
  ScanLabelMachine
} from '../../capa-4/scan-label/machine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import { GetContainerByLpnMachine } from '../../layer-4/container/get-container-by-lpn/machine';
import { consolidationClasificationActions } from './actions';
import { ConsolidationClassificationGuards } from './guards';
import { consolidationClassificationServices } from './services';

export interface ConsolidationClasificationContext {
  label: string | null;

  newContainerLabel: string | null;
  selectedContainerTypeName: string | null;
  selectedContainerType: ContainerType | null;
  classificationContainer: ClassificationContainer | null;
  suggestedSlotPosition: number | null;

  classificationItem: ClassificationItem | null;
  classificationItemLocation: Location | null;
  classificationItemCostumer: Customer | null;

  error: string | null;
}

export const DefaultConsolidationClasificationContext: ConsolidationClasificationContext =
  {
    label:                      null,
    newContainerLabel:          null,
    selectedContainerTypeName:  null,
    selectedContainerType:      null,
    classificationContainer:    null,
    classificationItem:         null,
    classificationItemLocation: null,
    classificationItemCostumer: null,
    suggestedSlotPosition:      null,
    error:                      null
  };

export const ConsolidationClasificationMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGED2A7WqA2BLCAhgC64bLYGy4BmuAxsaegHQDKD66u6UAMgQCMw2AMQQMYZtwBuqANaS0mHPkZkKVWgxIY2HLj35DsCGam1MA2gAYAujduJQAB1RUd6JyAAeiAIwALABMzAHWAGxBAQAcAJzRfgDMQbHWAQA0IACeiImRzACs1sXhftYA7BWx4dUAvrWZSlh4hB7klDT0aizIABZgdHLcUACS1AASlO2wml1tGEQE3GAATmISUuiyCsxNKq1M050Wun0DQzxjk7DTsyfoSovLK6Zb5t0ODl6u7kxevghEtEAqEgn5ouUguVyqlrFCMtlEFEQQUgokYrFMeVIrFwfVGhhmqo2hpjt1dv1BsMrlMNHduo8luhVutmZttopCfsGaStOSzlTLhNaZR6fN0E9mS8zPdPn5HEgQD9cB5-rlgaDwZDobD4ZkcoCArFmEEihVkjUoVFEviQHsWjyOnyPHoCJxhgA5MAAdwASjhsIznqzJGYdvbiYdeXMmK73TwvX6A0Gpa9ZLK7F9FcrVYqAX4-OFoswIUVwfECgFweF9bkguFCmiirigtFookCoFbRGDuonTHdLx3iQeCmWWOVkPtJAsy43Cq-nnELFyiDrMlym3MXkUgVawhMSby1DUpCiq36g0QOhUBA4F4e46xYu578MGqEABaGuIr8FZiYoBxTFNCcKJLE3Zcg6JL9vccYGHwgjCN8865qA+aJH4zB+DCRZJAklYFHuv5Vv+wHrri5REei4QFJByjQVGsH8pSFyjMKNx0mS4qSqsKFvp4S4IME+6muUzDkeippwoRAT0USvYPNGcHsG6CGJv62CBgsTJ8dmqEvj4y6Fsw4ERLuBThNisT7skIJUcUGIFoklR+PJ3Iwc+pwrGAjAJj6tzcYcOnPPxC7vkJgQBA2Dlwqu7ZouUiS2TEJpmpZ4QgRElnuYxfZeSwU5+VAE5hWhRkHiZZk4kRVk1KJtHMLFLnRbEZrhLlkb5UFugACJgDm1IPCFUplYZ+ZpKEsRgtEBQwnkRZgvuCRTYBmJROEAQFG1nWKUczqxsgPnFYmgXUFkIxEGAAC2Y0Rehy6JIUfiWaaRqbpi4L7oE1gScBznhLRL1yVej6eT1PTYPOo4jXpr7hYJD0IH4QQhNU1jTeUOGWQU0TrvuuNYWaKOJMkYnWHRoNQV1SnMS6ABi3C4LAvTDHdiMVS9IRghjeSoiupTEQafjxIUwGo3j7bAsCu1PhDzC9RI7MfkkII89NQEU5uQQE0Ev1mtlmKZdCQSXrUQA */
    id:                         'ConsolidationClasification',
    predictableActionArguments: true,
    schema:                     {
      context: {} as ConsolidationClasificationContext
    },
    context: DefaultConsolidationClasificationContext,
    initial: 'ScanningLabel',
    states:  {
      ScanningLabel: {
        invoke: {
          id:   ScanLabelMachine.id,
          src:  ScanLabelMachine,
          data: ctx => ({
            ...DefaultScanLabelContext,
            hint:          'Confirme escaneando etiqueta de bulto',
            requiredLabel: ctx.classificationItem?.label || null
          }),
          onDone: {
            target:  'GettingClassificationItem',
            actions: ['assignLabel', 'clearError']
          }
        }
      },
      GettingClassificationItem: {
        tags:   ['loading'],
        invoke: {
          src:    'getClassificationItemByLabel',
          onDone: {
            target:  'CheckingIfHasClassificationContainer',
            actions: 'assignClassificationItem'
          },
          onError: {
            target:  'ScanningLabel',
            actions: 'assignError'
          }
        }
      },
      CheckingIfHasClassificationContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfHasClassificationContainer',
          onDone: [
            {
              cond:    'hasClassificationContainer',
              actions: 'assignClassificationContainer',
              target:  'DepositingInContainer'
            },
            {
              target: 'ChoosingContainerType'
            }
          ]
        }
      },
      ChoosingContainerType: {
        on: {
          CONTAINER_TYPE_CHOSEN: {
            target:  'FetchingContainerType',
            actions: 'assignSelectedContainerTypeName'
          }
        }
      },
      FetchingContainerType: {
        invoke: {
          src:    'getContainerTypeByName',
          onDone: {
            target:  'ScanningRollContainer',
            actions: 'assignSelectedContainerType'
          }
        }
      },
      ScanningRollContainer: {
        invoke: {
          id:   ScanLabelMachine.id,
          src:  ScanLabelMachine,
          data: _ctx => ({
            ...DefaultScanLabelContext,
            hint: 'Confirme escanenado etiqueta de contenedor'
          }),
          onDone: {
            target:  'FetchingSuggestedSlotPosition',
            actions: 'assignNewContainerLabel'
          }
        }
      },
      FetchingSuggestedSlotPosition: {
        tags:   ['loading'],
        invoke: {
          src:    'getSuggestedSlotPosition',
          onDone: {
            target:  'CreatingNewClassificationContainer',
            actions: 'assignSlotPosition'
          }
        }
      },
      CreatingNewClassificationContainer: {
        invoke: {
          src:    'createNewClassificationContainer',
          onDone: {
            target: 'LocatingContainer'
          }
        }
      },
      LocatingContainer: {
        on: {
          CONTAINER_LOCATED: 'ScanningLabel'
        }
      },
      DepositingInContainer: {
        invoke: {
          id:  'DepositingInContainer',
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando etiqueta de contenedor',
              getRequestedContainerByLpn(
                'Ocurrio un error al obtener el contenedor, intente de nuevo',
                'El contenedor escaneado no corresponde al bulto correspondiente',
                ctx.classificationContainer!.container.lpn
              )
            ),
          onDone: 'ConsolidatingClassificationClassifyItem'
        }
      },
      ConsolidatingClassificationClassifyItem: {
        invoke: {
          src:    'ConsolidationClassificationClassifyItem',
          onDone: 'Finishing'
        }
      },
      ClosingContainer: {
        invoke: {
          src:    'ConsolidationClassificationContainerClosed',
          onDone: {
            target:  'ScanningLabel',
            actions: 'clearCtx'
          }
        }
      },
      Finishing: {
        on: {
          CLOSING_CONTAINER:   'ClosingContainer',
          CONTINUING_SCANNING: {
            target:  'ScanningLabel',
            actions: 'clearCtx'
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   ConsolidationClassificationGuards,
    actions:  consolidationClasificationActions,
    services: consolidationClassificationServices
  }
);
