import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../scan-container/ScanContainer';
import { ScanItem } from '../scan-item/ScanItem';
import { PlaceItemInContainerMachine } from './PlaceItemInContainerMachine';

export const PlaceItemInContainer: FC = withActorRef(
  PlaceItemInContainerMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  return (
    <>
      <Ink.Text>Agregar Item a Contenedor</Ink.Text>
      {current.matches('AwaitingContainerScan') ? <ScanContainer /> : null}

      {current.matches('AwaitingItemScan') ? <ScanItem /> : null}

      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}
    </>
  );
});
