import {
  Container,
  InventoryItem,
  Location,
  LocationTypeNames,
  Order,
  PickItem,
  PickingWave,
  Task,
  TaskTypes
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API } from '../../../../api/api';
import { api } from '../../../../api/utils/axios-instance';
import {
  CreateContainerMachine,
  createContainerMachineInitialContext
} from '../../capa-4/create-container/CreateContainerMachine';
import { MarkAsIncidentMachine } from '../../capa-4/mark-as-incident/mark-as-incident.machine';
import {
  MoveContainerMachine,
  moveContainerInitialContext
} from '../../capa-4/move-container/MoveContainerMachine';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../../capa-4/scan-location/ScanLocationMachine';
import {
  GoToHelpEvent,
  GoToOptionsEvent,
  MenuItemProps
} from '../../core/GenericOptions';

import { DefaultPickLineContext, PickLineMachine } from './PickLineMachine';

export interface PickItemsTaskContext {
  task: Task | null;
  pickingWave: PickingWave | null;
  pickingWaveId: number | null;

  itemsToPick: PickItem[];
  itemsPicked: PickItem[];
  totalItemsToPick: PickItem[];

  currentPickItem: PickItem | null;
  currentItem: InventoryItem | null | undefined;

  currentContainer: Container | null;
  containers: Container[];

  pickingCartLocation: Location | null;
  dropoutLocation: Location | null;
  moveContainer;

  // opcional
  order: Order | null;
  orderId: number | null;

  payload: any;
}

export const DefaultPickItemsTaskMachineContext = {
  task: null,

  pickingWaveId: null,
  pickingWave:   null,
  order:         null,
  orderId:       null,

  itemsToPick:      [],
  itemsPicked:      [],
  totalItemsToPick: [],

  currentPickItem:  null,
  currentItem:      null,
  currentContainer: null,
  containers:       [],

  pickingCartLocation: null,
  dropoutLocation:     null,
  moveContainer:       null,

  payload: null
};

const HELP = `Pick Items
Escanee un carro de picking y luego los ítems a medida que los carga.`;

export const PickItemsTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCWBjA1gSQC5gFtYAVAQ1kwDpUA7VXVUgG1QC8wBiABw01qjIUAMgHtSESIlBcRseqhE0pIAB6IAtAGYALJsoAOXQFYATAAZtARgDsANiMBOBwBoQAT0Qnbe7dc36nW2t9HVtfAF9w1zQsPEIScip6MAAnUgYaKAB5ADdUmJx8Ig4AOTAAdwBhRVxSWlTlGTkGRWU1BEt9a0pvEysHf2sTIxCTVw8EE0tbSm0AhwCjWydNe2tI6N44okEk-DSM7LyUgu3YDgAxWlRYAAsC-kbZeVakVUQjazMDB1tzbTM1msDkBLncnkslkonUsRksmiBJhCyxMGxApyKCQo1H26X4uXyW0xHAAgkwmGcCpI3k0Xko3u0lkZKA5rLpLFZLFNVvpxogEdoepYzLCjNomSZgZo0Rj4rtKOhFAAzVApAgPTIcRU0FVqjVQJ7NBT00DtDm-SjBTQjBzTbR-BF8jpwyiaCy2QxmEzDfRGfwyolyxKUAAiYHQTFIKX4+uqNFq9RSHAAyrUUrh9Ya6W1EMDoZD7Vz9JZfr7rE7NA5BQDpnDfiYq9ZLAHYpj5WGI1GY7x+HGEzRUhw+3UBykAPqVFJgdLU6TPFom94IdRIkyUEw6e0ikuAhuaJ32vRmT5mYu-Xe2luFIPYkMpERcWM1EepWDYGhh2AZPGKDj6kkAEYiOms4gLSC45ggDjDK6tjCposJVgsTolg4szeMKVhNk4hhXmc7b3o+PaZMOiZvh+cDfgupLkqRo6wHeD5cKB4HGpBXJeJaCILBufgcl0TqSmhXKmLa8x1vYkRRCANAiBI8BvLKOzBtcDDMGwYBZhBDIaLo3RLJWXQOKYNa8uCCCaJW65OJCwSSv4ILrNJSlYnsqR4pkBInIGRBaWxOmTLY3yaOY3jWGKnxcvu5khd8ZiVoCXQRdoAzaHhbbBtqurqsRBo0vO-mmogUVQvofznvY+gBMWKHaIK+jusCbJ-CWqLOT5rmUDwWD8Ls1QEFwTBgPgEB+a8RUdHVaGOEFQTmJKHGCay0LDA2HpTHMITpTeVAdpG0aZE+8YvikY2Lu0K72pQ8V9PaRifECcFGBWXQ-IiCK+o2x7bcp2LvkqwE5T1mSAcBuAAErTrA41gQVMPtHYaG+BYXRcsKDbaE6cGUJ8QRmE9dX+JKP2dYxRHA1AdGvu+n5UcalAALIiHkVOnflRrw4gDVrqeTheCe4pehWXzXV4kJGBYuj4w4JMEQ+R39tTFFfrQP6LqxnMIEC+jXfM-NfILYzmQ23THr41rHqYdh9LLiRnZB6iFjj3gLMCJkimZEzqJCrpVvYFinlMIw21JQA */
  createMachine<PickItemsTaskContext>(
    {
      id:      TaskTypes.PickWaveV0,
      context: DefaultPickItemsTaskMachineContext,
      initial: 'GetPickItems',
      states:  {
        GetPickItems: {
          invoke: {
            src:    'getPWPickItems',
            id:     'getPWPickItems',
            onDone: {
              actions: ['assignItemsToPick', 'updateContext'],
              target:  'initialize'
            }
          }
        },
        initialize: {
          entry: assign({
            orderId:          context => context.payload?.order?.id,
            pickingWaveId:    context => context.payload?.pickingWaveId,
            totalItemsToPick: context => context.itemsToPick,
            currentPickItem:  context => context.itemsToPick[0],
            currentItem:      context => context.itemsToPick[0]?.inventoryItem
          }),
          always: {
            target: 'CheckPickingWaveStatus'
          }
        },
        CheckPickingWaveStatus: {
          invoke: {
            src:    'checkPickWave',
            onDone: [
              {
                cond:   'pickingFinished',
                target: 'DropPickingContainersInDestination'
              },
              {
                cond:   'cartHaveContainer',
                target: 'iteratingOverPickItems'
              },

              {
                target: 'ScanningPickingCart'
              }
            ],
            onError: 'PickingWavePaused'
          }
        },
        ScanningPickingCart: {
          invoke: {
            id:   ScanLocationMachine.id,
            src:  ScanLocationMachine,
            data: {
              ...DefaultScanLocationContext,
              type: LocationTypeNames.PickingCart,
              hint: 'Escanee carrito de picking que utilizará.'
            },
            onDone: {
              actions: assign({
                pickingCartLocation: (context, event) =>
                  (event as any).location || (event as any).data.location
              }),
              target: 'DeclaringPickingContainer'
            }
          }
        },
        PickingWavePaused: {
          on: {
            check: {
              target: 'CheckPickingWaveStatus'
            }
          }
        },
        DeclaringPickingContainer: {
          invoke: {
            id:   CreateContainerMachine.id,
            src:  CreateContainerMachine,
            data: ctx => ({
              ...createContainerMachineInitialContext,
              location:             ctx.pickingCartLocation,
              orderId:              ctx.order?.id,
              partialContainerBody: {
                pickingWaveId: ctx.pickingWaveId
              }
            }),
            onDone: {
              actions: assign({
                containers:       (ctx, ev) => [...ctx.containers, ev.data.container],
                currentContainer: (_ctx, ev) => ev.data.container
              }),
              target: 'iteratingOverPickItems'
            }
          },
          on: {
            StartPicking: {
              target: 'iteratingOverPickItems'
            }
          }
        },
        iteratingOverPickItems: {
          invoke: {
            src:  PickLineMachine,
            id:   PickLineMachine.id,
            data: ctx => ({
              ...DefaultPickLineContext,
              location:    ctx.currentContainer?.location,
              container:   ctx.currentContainer,
              currentItem: ctx.currentItem
            }),
            onDone: [
              { actions: 'pickedItem', target: 'GetPickItems' },
              {
                cond:    'lastItem',
                actions: 'updateContext',
                target:  'ConfirmPicking'
              },
              {
                actions: 'updateContext',
                target:  'iteratingOverPickItems'
              }
            ]
          },
          on: {
            NewContainer: {
              target: 'DeclaringPickingContainer'
            },
            FinishPicking: {
              target: 'InformPickingAbortReason'
            },
            AllItemsPicked: {
              target: 'DropPickingContainersInDestination'
            }
          }
        },
        completeTask: {
          invoke: {
            src:    'completeTask',
            onDone: {
              target: 'pickingTaskCompleted'
            },
            onError: {
              // @TODO rollback?
              target: 'pickingTaskCompleted'
            }
          }
        },

        pickingTaskCompleted: {
          type: 'final'
        },
        InformPickingAbortReason: {},
        ConfirmPicking:           {
          on: {
            continue: {
              target: 'DropPickingContainersInDestination'
            },
            goBack: {
              target: 'iteratingOverPickItems'
            }
          }
        },
        DropPickingContainersInDestination: {
          invoke: {
            src:  MoveContainerMachine,
            id:   MoveContainerMachine.id,
            data: ctx => ({
              ...moveContainerInitialContext,
              validContainers:    ctx.containers,
              suggestedContainer: ctx.containers[0],
              suggestLocation:    true,
              requiredLocation:   ctx.payload.destination,
              hint:               'Trasladar contenedores a un canal',
              suggestedScanDigit: true
            }),
            onDone: [
              {
                cond:    'remainingContainers',
                actions: 'updateContainers',
                target:  'DropPickingContainersInDestination'
              },
              {
                target: 'completeTask'
              }
            ]
          }
        },
        skippedLine: {
          always: { target: 'iteratingOverPickItems' }
        },
        MarkAsIncident: {
          invoke: {
            src:  MarkAsIncidentMachine,
            id:   MarkAsIncidentMachine.id,
            data: ctx => ({
              suggestedItem: ctx.currentItem,
              pickingWaveId: ctx.pickingWaveId,
              location:      ctx.pickingCartLocation
            }),
            onDone: 'GetPickItems'
          }
        },
        RestartPickLines: {
          always: {
            target: 'iteratingOverPickItems'
          }
        }
      },
      on: {
        goToOptions: {
          actions: 'triggerMenuScreen'
        },
        goToHelp: {
          actions: 'triggerHelpScreen'
        },
        skipLine: [
          {
            cond:    'lastItem',
            actions: 'skipLine',
            target:  'ConfirmPicking'
          },
          {
            actions: 'skipLine',
            target:  'skippedLine'
          }
        ],
        skipToConfirm: {
          target: 'ConfirmPicking'
        },
        markIncident:    'MarkAsIncident',
        containerChange: {
          actions: assign({
            currentContainer: (_ctx, event) => event.data.container
          }),
          target: 'RestartPickLines'
        },
        createContainer: {
          target: 'DeclaringPickingContainer'
        }
      }
    },
    {
      guards: {
        pickingFinished: ctx =>
          ctx.containers.length > 0 && ctx.itemsToPick.length === 0,
        cartHaveContainer:   ctx => ctx.containers.length > 0,
        lastItem:            ctx => ctx.itemsToPick.length === 0,
        remainingContainers: (ctx, event) =>
          ctx.containers.length > 1 ||
          !ctx.containers.find(
            container => container.id === event.data.container.id
          )
      },
      actions: {
        pickedItem: assign({
          itemsPicked: ctx => [...ctx.itemsPicked, ctx.itemsToPick[0]]
        }),
        assignItemsToPick: assign({
          itemsToPick: (_ctx, event) => event.data.pickItems
        }),
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP),
        updateContext: assign(ctx => {
          const itemsToPick = [...ctx.itemsToPick];
          const currentItem = itemsToPick[0]?.inventoryItem || null;

          return {
            ...ctx,
            itemsToPick,
            currentItem
          };
        }),
        updateContainers: assign({
          containers: (ctx, event) => [
            ...ctx.containers.filter(
              container => container.id !== event.data.container.id
            )
          ]
        }),
        skipLine: assign(ctx => {
          const [skippedItem, ...nextItems] = ctx.itemsToPick;
          const currentItem = nextItems[0]?.inventoryItem || null;
          const itemsToPick = [...nextItems, skippedItem];
          return { ...ctx, itemsToPick, currentItem };
        }),
        triggerMenuScreen: (
          ctx,
          { send, triggerMenuScreen }: GoToOptionsEvent
        ) => {
          const options: MenuItemProps[] = [];
          if (ctx.itemsToPick.length > 1) {
            options.push({
              label:   'Siguente Linea',
              onClick: () =>
                send('skipLine', { data: { item: ctx.currentItem } })
            });
          }

          if (ctx.itemsToPick.length > 0) {
            options.push({
              label:   'Confirmar con Faltantes',
              onClick: () => send('skipToConfirm')
            });
          }
          if (
            (ctx.itemsToPick.length > 0 && ctx.pickingCartLocation) ||
            ctx.itemsPicked.length > 0
          ) {
            options.push({
              label:   'Marcar Incidencia',
              onClick: () => send('markIncident')
            });
          }

          if (ctx.itemsToPick.length > 0) {
            options.push({
              label:   'Crear Contenedor',
              onClick: () => send('createContainer')
            });
          }

          triggerMenuScreen(options);
        }
      },
      services: {
        ...API,
        getPWPickItems: async ctx => {
          const { data: pickItems } = await api.get(
            `/picking-wave/${ctx.payload.pickingWaveId}/pick-items`
          );

          return { pickItems };
        }
      }
    }
  );

// export const PickingOptions = {
//   states: {
//     scanItem: {},
//     anomalia: {
//       on: {
//         CONTAINER_SCANNED: {
//           target:   'placeItemInContainer',
//           internal: false
//         }
//       }
//     }
//   },
//   on: {
//     allItemsPicked: {
//       target: 'confirmPicking'
//     }
//   }
// };
