import { MachineOptions, assign } from 'xstate';
import { PrintMachineContext } from './machine';

type PrintActions = MachineOptions<PrintMachineContext, any>['actions'];

export const printActions: PrintActions = {
  assignError: assign({
    error: (_context, event) => event.data.error
  }),
  clearError: assign({
    error: (_context, _event) => null
  })
};
