import { AuditOrderItemStatuses } from '../AuditOrderItem';
import { AuditOrderItemStatus } from './AuditOrderItemStatus';

export class AuditOrderItemPausedStatus extends AuditOrderItemStatus {
  public displayName = 'Pausada';

  public internalName = AuditOrderItemStatuses.Paused;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
