import { InkModule } from '@wms/client-core';
import { Box } from './components/Box';
import { Button } from './components/Button';
import { Chalk } from './components/Chalk';
import { Header } from './components/Header';
import { Newline } from './components/Newline';
import { SelectInput } from './components/SelectInput';
import { Text } from './components/Text';
import { TextInput } from './components/TextInput';
import { useInput } from './hooks/useInput';

export const inkModule: InkModule = {
  Box,
  Button,
  Chalk,
  Header,
  Newline,
  SelectInput,
  Text,
  TextInput,
  useInput
};
