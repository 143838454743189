import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../../shared/Machine';
import { EnterCheckDigit } from '../../../capa-4/enter-check-digit/EnterCheckDigit';
import { EnterQuantity } from '../../../capa-4/enter-quantity/EnterQuantity';
import { ScanProductIdentifier } from '../../../capa-4/scan-product/ScanProductIdentifier';
import { InboundDirectReceptionMachine } from './InboundDirectReceptionMachine';

export const InboundDirectReceptionTask: FC = withActorRef(
  InboundDirectReceptionMachine
)(({ actorRef }) => {
  const [current] = useActor(actorRef);

  return (
    <Ink.Box flexDirection='column' paddingLeft={1}>
      <Ink.Header>
        <Ink.Text bold>
          {!current.context.receiptId ? (
            'REALIZAR'
          ) : (
            <>
              REALIZANDO RECEPCIÓN EN LÍNEA CON GUARDADO:{' '}
              <Ink.Chalk greenBright>{current.context.receiptId}</Ink.Chalk>
            </>
          )}
        </Ink.Text>
      </Ink.Header>

      {current.matches('ScanProductToReceive') ? (
        <>
          <Ink.Text>Escanear producto a recibir:</Ink.Text>
          <ScanProductIdentifier />
        </>
      ) : null}

      {current.matches('EnteringQuantity') ? <EnterQuantity /> : null}

      {current.matches('FetchingSuggestedStorageLocation') ? (
        <Ink.Text>Buscando Ubicación Sugerida...</Ink.Text>
      ) : null}

      {current.matches('EnteringStorageLocationCheckDigit') ? (
        <>
          <Ink.Text>
            UBI. SUGERIDA:{' '}
            <Ink.Chalk greenBright bold>
              {`${current.context.suggestedStorageLocation?.name}<${current.context.suggestedStorageLocation?.checkDigit}>`}
            </Ink.Chalk>
          </Ink.Text>
          <EnterCheckDigit />
        </>
      ) : null}

      {current.matches('Initializing') ? (
        <Ink.Text>Cargando...</Ink.Text>
      ) : null}

      {current.context.error ? (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      ) : null}
    </Ink.Box>
  );
});
