import Ink from '@ink';
import React, { FC } from 'react';

export interface PaginatorProps {
  hasPrev: boolean;
  hasNext: boolean;
  onPrev: () => void;
  onNext: () => void;
}

export const Paginator: FC<PaginatorProps> = ({
  hasPrev,
  hasNext,
  onPrev,
  onNext
}) => {
  return (
    <Ink.Box flexDirection='row' justifyContent='space-between' width='100%'>
      {hasPrev ? <Ink.Button label='<- ANT.' onClick={onPrev} /> : null}

      {hasNext ? <Ink.Button label='SIG. ->' onClick={onNext} /> : null}
    </Ink.Box>
  );
};

Paginator.displayName = 'Paginator';
