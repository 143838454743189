import { Location } from '@wms/domain';
import { createMachine } from 'xstate';
import { Maybe } from '../../../../../utils/maybe';
import { getLocationByNameActions } from './actions';
import { getLocationByNameGuards } from './guards';
import { getLocationByNameServices } from './services';

export interface GetLocationByNameContext {
  readonly hint: string;
  locationName: string;
  location: Location | null;
  error: string | null;
}

export type GetLocationByNameFn = (name: string) => Promise<Maybe<Location>>;

export const GetLocationByNameMachineId = 'GetLocationByName';

export const GetLocationByNameMachine = (
  hint: string,
  fetchFn: GetLocationByNameFn
) =>
  createMachine(
    {
      id:                         GetLocationByNameMachineId,
      predictableActionArguments: true,
      schema:                     {
        context: {} as GetLocationByNameContext
      },
      context: {
        hint,
        locationName: '',
        location:     null,
        error:        null
      },
      initial: 'EnteringLocationName',
      states:  {
        EnteringLocationName: {
          on: {
            UpdateLocationName: {
              actions: ['updateLocationName', 'clearError']
            },
            SubmitLocationName: {
              cond:   'isValidLocationName',
              target: 'FetchingLocation'
            }
          }
        },
        FetchingLocation: {
          tags:   ['loading'],
          invoke: {
            src:    'fetchLocation',
            onDone: [
              {
                cond:    'isSuccess',
                actions: 'assignLocation',
                target:  'Finished'
              },
              {
                actions: 'assignError',
                target:  'EnteringLocationName'
              }
            ]
          }
        },
        Finished: {
          type: 'final',
          data: (context, _event) => context.location
        }
      }
    },
    {
      guards:   getLocationByNameGuards,
      actions:  getLocationByNameActions,
      services: getLocationByNameServices(fetchFn)
    }
  );
