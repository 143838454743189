import { SorterExitProcessStatuses } from '../SorterExitProcess';
import { SorterExitProcessStatus } from './SorterExitProcessStatus';

export class SorterExitProcessStatusCancelledStatus extends SorterExitProcessStatus {
  public displayName = 'Cancelado';

  public internalName = SorterExitProcessStatuses.Cancelled;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
