import { TaskStatuses } from '../Task';
import { TaskStatus } from './TaskStatus';

export class TaskStatusPausedStatus extends TaskStatus {
  public displayName = 'Pausada';

  public internalName = TaskStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
