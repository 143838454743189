import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { Location } from './Location';
import { PickingZone } from './PickingZone';

@Entity()
export class PickingZoneLocation {
  @PrimaryKey()
  id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => PickingZone, { name: 'picking_zone_id' })
  pickingZoneId: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @Knows(() => PickingZone, 'pickingZoneId')
  pickingZone: PickingZone;

  @Knows(() => Location, 'locationId')
  location: Location;
}
