import { ReservationStatuses } from '../Reservation';
import { ReservationStatus } from './ReservationStatus';

export class ReservationStatusUnRoutedStatus extends ReservationStatus {
  public displayName = 'Sin rutear';

  public internalName = ReservationStatuses.Unrouted;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
