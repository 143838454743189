import { AuditingWaveStatuses } from '../AuditingWave';
import { AuditingWaveStatus } from './AuditingWaveStatus';

export class AuditingWaveCancelledStatus extends AuditingWaveStatus {
  public displayName = 'Cancelado';

  public internalName = AuditingWaveStatuses.Cancelled;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
