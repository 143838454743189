import { MachineOptions } from "xstate";
import { MoveToPackingLocationContext } from "./machine";


type TransferToPackingLocationGuards = MachineOptions<
MoveToPackingLocationContext,
  any
>['guards'];

export const transferToPackingLocationGuards: TransferToPackingLocationGuards = {}

