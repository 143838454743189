import { TaskBucketStatuses } from '../TaskBucket';
import { TaskBucketStatus } from './TaskBucketBucketStatus';

export class TaskBucketStatusDraftStatus extends TaskBucketStatus {
  public displayName = 'Draft';

  public internalName = TaskBucketStatuses.Draft;

  public statusColor = '#e2e8f0';

  public textColor = '#84898f';
}
