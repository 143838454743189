import { Container, Receipt, SlottingAlgorithm, Task } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import {
  MoveContainerMachine,
  moveContainerInitialContext
} from '../../capa-4/move-container/MoveContainerMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface PutAwayContainersTaskContext {
  task: Task | null;
  payload: any;
  receipt: Receipt | null;
  receiptContainers: Container[];
  putAwayContainers: Container[];
  currentContainer: Container | null;
  fromLocation: Location | null;
  dropoutLocation: Location | null;
  slottingAlgorithm: SlottingAlgorithm | string | null;
}

export const DefaultPutAwayContainersTaskContext = {
  task:              null,
  payload:           null,
  receipt:           null,
  receiptContainers: [],
  putAwayContainers: [],
  currentContainer:  null,
  fromLocation:      null,
  dropoutLocation:   null,
  slottingAlgorithm: 'Default'
};

const HELP = `Put Away Container
Escanee un container y luego una ubicación para moverlo a la misma.`;

export const PutAwayContainersTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCuAXAggdwIYE8BhAewDt1cBLUsAJ1gBVdYBrAOmsvUtwBtKAXmADEiUAAdisLpTJiQAD0QAGADQh8KgL5b1aLHiJkK1Oo2bsAkujq5upKAHkAbnQBKYAMZhK49CXIqGnphTF5eazAAW1gAZXRiWlwYCHlJaW45JEVEACYATny2AEYANnyADgBWAHYK-NzlABYK9U0EAFoKtgBmJrKy6pqmnuVcptKdPQwcAgCTYPNWNgCAM0paKP1Z-GFPMnXN5EpPFmooNKkZLNAlBFLS3LZc4prS4oKWl9K2xFfu8pNfJVXI1Ro9YpVUoVHS6ECkYgQODybaGeZBMxMZacbh8QRgS4ZWSkeR3NQaRD5SZw1FzYwY+hYqw2JL2JyuWgeby+fz00z0QnXEnZO7FJrKNjNKmVCrNPrjX4IJpVNgjCaNXJVfLKnr1JpTEC0oyBflLdhrDZbGaGQWZYW3RA6ti1fJvOo1KrFfJin4UhCy57FHo9fLKMNVCqlLW5A1G9GmplsI1MkhRcS8MA2VLZdJC0mIUo1Iqh4E1ZSF3XlnqKyPOrUNIug0YtWPWukmxZM23E-OdBV+rrFXofV3KHplz2PfWwoA */
  createMachine<PutAwayContainersTaskContext>(
    {
      id:      'PutAwayContainersTask',
      context: DefaultPutAwayContainersTaskContext,
      initial: 'Initializing',
      states:  {
        Initializing: {
          entry: assign({
            receipt:           context => context.payload,
            receiptContainers: context => context.payload?.containers,
            currentContainer:  context => context.payload.containers[0],
            fromLocation:      context => context.payload?.unloadingLocation || null,
            slottingAlgorithm: context =>
              context.payload?.slottingAlgorithm || 'Default'
          }),
          always: [
            {
              target: 'IteratingOverReceiptContainers'
            }
          ]
        },
        IteratingOverReceiptContainers: {
          invoke: {
            id:   MoveContainerMachine.id,
            src:  MoveContainerMachine,
            data: ctx => ({
              ...moveContainerInitialContext,
              fromLocation:       ctx.receipt?.unloadingLocation,
              // requiredLocation: ctx.dropoutLocation,
              validContainers:    ctx.payload?.containers,
              suggestedContainer: ctx.currentContainer,
              slottingAlgorithm:  ctx.slottingAlgorithm
            }),
            onDone: [
              {
                actions: 'updateContext',
                cond:    'linesRemaining',
                target:  'IteratingOverReceiptContainers'
              },
              {
                actions: 'updateContext',
                target:  'ConfirmPutAway'
              }
            ]
          }
        },
        ConfirmPutAway: {
          on: {
            confirmPutAway: {
              target: 'ConfirmingTask'
            },
            skipTaskConfirmation: {
              target: 'PutAwayTaskCompleted'
            }
          }
        },
        ConfirmingTask: {
          invoke: {
            src:    'completeTask',
            data:   ctx => ctx,
            onDone: {
              target: 'PutAwayTaskCompleted'
            }
          }
        },
        PutAwayTaskCompleted: {
          type: 'final'
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        ...UtilityGuards,
        linesRemaining: ctx => ctx.receiptContainers.length > 1
      },
      actions: {
        ...UtilityActions,
        updateContext: assign(ctx => {
          const [putAwayContainer, ...receiptContainers] =
            ctx.receiptContainers;
          const currentContainer = receiptContainers[0];
          const putAwayContainers = [
            ...ctx.putAwayContainers,
            putAwayContainer
          ];

          return {
            ...ctx,
            receiptContainers,
            putAwayContainers,
            currentContainer
          };
        }),
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: { ...API }
    }
  );
