import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  PrimaryKey
} from '@skyframe/core';
import { PickingProcess } from './PickingProcess';
import { PickingZone } from './PickingZone';
import { Warehouse } from './Warehouse';

@Entity()
export class PickingAlgorithm {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  key: string;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @NeededBy(() => PickingProcess, 'pickingAlgorithmId')
  pickingProcesses: PickingProcess[];

  @NeededBy(() => PickingZone, 'pickingAlgorithmId')
  pickingZones: PickingZone[];
}
