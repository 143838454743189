import {
  Container,
  InventoryItemStatuses,
  Location,
  MoveContainerToStoragePayload,
  MoveContainersToGrid3Payload,
  SlottingAlgorithm,
  Task,
  TaskTypes
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import { MarkAsIncidentMachine } from '../../capa-4/mark-as-incident/mark-as-incident.machine';
import {
  MoveContainerMachine,
  moveContainerInitialContext
} from '../../capa-4/move-container/MoveContainerMachine';
import {
  GoToHelpEvent,
  GoToOptionsEvent,
  MenuItemProps
} from '../../core/GenericOptions';

export interface MoveContainersTaskContext {
  gridNumber?: number;
  task: Task | null;
  currentContainer: Container | null;
  containersToMove: Container[];
  movedContainers: Container[];
  targetLocation: Location | null;
  originLocation: Location | null;
  containersMoved: Container[];
  title: string | null;
  hint: string | null;
  error: string | null;
  suggestLocation: boolean;
  slottingAlgorithm: SlottingAlgorithm | null;
  orderId: number;
  payload: MoveContainerToStoragePayload | MoveContainersToGrid3Payload;
}

export const moveContainersTaskInitialContext = {
  task:              null,
  currentContainer:  null,
  containersToMove:  [],
  movedContainers:   [],
  slottingAlgorithm: null,
  targetLocation:    null,
  hint:              null,
  error:             '',
  suggestLocation:   false,
  payload:           {}
};

export const moveContainersToGrid3TaskInitialContext = {
  currentContainer:  null,
  containersToMove:  [],
  movedContainers:   [],
  slottingAlgorithm: 'grid-3-cedis',
  targetLocation:    null,
  hint:              null,
  error:             '',
  suggestLocation:   true,
  payload:           {}
};

const HELP = `Traslado de contenedor
Escanee un contenedor y su nueva ubicación para trasladarlo. `;

export const MoveContainersTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<MoveContainersTaskContext>(
    {
      id:      'MoveContainersTaskMachine',
      initial: 'Initialize',
      states:  {
        Initialize: {
          entry: assign({
            suggestLocation: ctx =>
              ctx.suggestLocation || ctx.payload?.suggestLocation,
            slottingAlgorithm: ctx => ctx.slottingAlgorithm
          }),
          always: [
            {
              target:  'GettingContainers',
              actions: assign({
                orderId: ctx =>
                  (ctx.payload as MoveContainersToGrid3Payload).orderId
              }),
              cond: 'isMovingToGrid3'
            },
            {
              target:  'MovingContainers',
              actions: assign({
                containersToMove: ctx => {
                  return (
                    ctx.containersToMove ||
                    [
                      (ctx.payload as MoveContainerToStoragePayload)?.container
                    ].filter(container => container)
                  );
                }
              })
            }
          ]
        },

        GettingContainers: {
          invoke: {
            src:    'getDestinationContainers',
            onDone: {
              actions: assign({
                containersToMove: (_ctx, event) => event.data.containers
              }),
              target: 'MovingContainers'
            },
            onError: {
              target: 'ContainersMoved'
            }
          }
        },

        MovingContainers: {
          invoke: {
            src:  MoveContainerMachine,
            id:   MoveContainerMachine.id,
            data: ctx => ({
              ...moveContainerInitialContext,
              gridNumber:
                ctx.task?.type === TaskTypes.MoveContainersToGrid3
                  ? 3
                  : ctx.gridNumber,
              suggestLocation:    ctx.suggestLocation,
              validContainers:    ctx.containersToMove,
              suggestedContainer:
                ctx.containersToMove[0] ||
                (ctx.task?.payload as any)?.container,
              slottingAlgorithmKey: ctx.slottingAlgorithm,
              task:                 ctx.task
            }),
            onDone: [
              {
                actions: 'updateContext',
                cond:    'containersRemaining',
                target:  'MovingContainers'
              },
              // remove until we migrate logic to xstate
              /* {
                actions: 'updateContext',
                cond:    'isMovingToStorage',
                target:  'ConfirmingTask'
              },*/
              {
                actions: 'updateContext',
                cond:    'inTask',
                target:  'ConfirmingTask'
              },
              {
                actions: 'updateContext',
                target:  'ContainersMoved'
              }
            ]
          }
        },

        ConfirmingTask: {
          invoke: {
            src:    'completeTask',
            onDone: {
              target: 'ContainersMoved'
            }
          }
        },

        ContainersMoved: {
          type: 'final',
          data: ctx => ctx
        },

        MarkAsIncident: {
          invoke: {
            src:  MarkAsIncidentMachine,
            id:   MarkAsIncidentMachine.id,
            data: ctx => ({
              receiptId:     (ctx as any).container.receiptId,
              location:      (ctx as any).container.location,
              suggestedItem: {
                ...(ctx as any).container.inventoryItems[0],
                container: (ctx as any).container
              },
              inventoryItemStatusFilter: InventoryItemStatuses.Available
            }),
            onDone: 'MovingContainers'
          }
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        },

        goToOptions: {
          actions: 'triggerMenuScreen'
        },

        markIncident: 'MarkAsIncident'
      }
    },
    {
      guards: {
        containersRemaining: (ctx, event) =>
          (ctx.containersToMove.length === 1 &&
            ctx.containersToMove[0].lpn !== event.data.container.lpn) ||
          ctx.containersToMove.length > 1,
        // @TODO wtf is this
        inTask:          ctx => !!ctx.task?.id,
        isMovingToGrid3: ctx =>
          ctx.task?.type === TaskTypes.MoveContainersToGrid3,
        isMovingToStorage: ctx =>
          ctx.task?.type === TaskTypes.MoveContainerToStorage
      },
      actions: {
        updateContext: assign({
          containersToMove: (context, event) => [
            ...context.containersToMove.filter(
              container => container.lpn !== event.data.container.lpn
            )
          ],
          movedContainers: (context, event) => [
            ...context.movedContainers,
            event.data.container
          ],
          currentContainer: _ctx => null
        }),
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP),
        triggerMenuScreen: (
          ctx,
          { send, triggerMenuScreen }: GoToOptionsEvent
        ) => {
          const options: MenuItemProps[] = [];

          options.push({
            label:   'Marcar Incidencia',
            onClick: () => send('markIncident')
          });
          triggerMenuScreen(options);
        }
      },
      services: {
        ...API
      }
    }
  );
