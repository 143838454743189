import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ConsolidateContainersProcess } from './ConsolidateContainersProcess';
import { ConsolidateContainersWave } from './ConsolidateContainersWave';
import { Container } from './Container';
import { PickingContainerGroup } from './PickingContainerGroup';
import { PickingWave } from './PickingWave';
import {
  ConsolidateContainersItemStatus,
  ConsolidateContainersItemStatusCancelled,
  ConsolidateContainersItemStatusDraft,
  ConsolidateContainersItemStatusMoved,
  ConsolidateContainersItemStatusMoving,
  ConsolidateContainersItemStatusPending
} from './consolidate-containers-item-status';

export const ConsolidateContainersItemStatuses = {
  Pending:   'pending',
  Moved:     'moved',
  Cancelled: 'cancelled',
  Draft:     'draft',
  Moving:    'moving'
};

@Entity()
export class ConsolidateContainersItem {
  @PrimaryKey()
  id: number;

  @Status({
    [ConsolidateContainersItemStatuses.Pending]:
      ConsolidateContainersItemStatusPending,
    [ConsolidateContainersItemStatuses.Moved]:
      ConsolidateContainersItemStatusMoved,
    [ConsolidateContainersItemStatuses.Cancelled]:
      ConsolidateContainersItemStatusCancelled,
    [ConsolidateContainersItemStatuses.Moving]:
      ConsolidateContainersItemStatusMoving,
    [ConsolidateContainersItemStatuses.Draft]:
      ConsolidateContainersItemStatusDraft
  })
  status: ConsolidateContainersItemStatus;

  @ForeignKey(() => ConsolidateContainersProcess, {
    name: 'consolidate_containers_process_id'
  })
  consolidateContainersProcessId: number;

  @ForeignKey(() => PickingContainerGroup, {
    name: 'picking_container_group_id'
  })
  pickingContainerGroupId: number;

  @ForeignKey(() => Container, {
    name: 'container_id'
  })
  containerId: number;

  @ForeignKey(() => ConsolidateContainersWave, {
    name: 'consolidate_containers_wave_id'
  })
  consolidateContainersWaveId: number;

  @ForeignKey(() => PickingWave, {
    name: 'picking_wave_id'
  })
  pickingWaveId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => ConsolidateContainersProcess, 'consolidateContainersProcessId')
  consolidateContainersProcess: ConsolidateContainersProcess;

  @Knows(() => ConsolidateContainersWave, 'consolidateContainersWaveId')
  consolidateContainersWave: ConsolidateContainersWave;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Needs(() => PickingWave, 'pickingWaveId')
  pickingWave: PickingWave;

  @Knows(() => PickingContainerGroup, 'pickingContainerGroupId')
  pickingContainerGroup: PickingContainerGroup;
}
