import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { AuditingTaskAssignerContext } from './machine';

type AuditingTaskAssignerServices = MachineOptions<
  AuditingTaskAssignerContext,
  any
>['services'];

export const auditingTaskAssignerServices: AuditingTaskAssignerServices = {
  markItemAsMoved: async (ctx, _event) => {
    const { data } = await api.post('auditing-wave/launch-task', {
      locationId:  ctx.location?.id,
      containerId: ctx.container?.id
    });
    return data;
  }
};
