import { SorterExitProcessStatuses } from '../SorterExitProcess';
import { SorterExitProcessStatus } from './SorterExitProcessStatus';

export class SorterExitProcessStatusPendingStatus extends SorterExitProcessStatus {
  public displayName = 'Pendiente';

  public internalName = SorterExitProcessStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
