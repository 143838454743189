import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { ChangeEvent, FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { CreateLotMachine } from './CreateLotMachine';

export const CreateLot: FC = withActorRef(CreateLotMachine)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const onSubmit = () => {
    send('submit');
  };

  const onChangeLotNumber = (lotNumber: string) => {
    send({ type: 'change', data: { lotNumber } });
  };

  const setDate = (event: ChangeEvent<HTMLInputElement>) => {
    send({ type: 'change', data: { expirationDate: event.target.value } });
  };

  return (
    <>
      {current.matches('AwaitingLotNumber') ? (
        <>
          <Ink.Text>Ingrese Lote:</Ink.Text>
          <Ink.TextInput
            value={current.context.lotNumber}
            onChange={onChangeLotNumber}
            onSubmit={onSubmit}
          />{' '}
        </>
      ) : null}

      {current.matches('AwaitingDate') ? (
        <>
          <Ink.Text>Ingrese fecha de vencimiento del Lote</Ink.Text>
          <input
            type='date'
            autoFocus
            onChange={setDate}
            style={{ colorScheme: 'dark', width: '25%' }}
          />
          <Ink.Button onClick={onSubmit} label='Confirmar' />
        </>
      ) : null}
    </>
  );
});
