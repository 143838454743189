import { ReservationOrderStatuses } from '../ReservationOrder';
import { ReservationOrderStatus } from './ReservationOrderStatus';

export class ReservationOrderStatusRoutedStatus extends ReservationOrderStatus {
  public displayName = 'Ruteada';

  public internalName = ReservationOrderStatuses.Routed;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
