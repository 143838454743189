import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../../../shared/Machine';
import { CreateContainer } from '../../../../capa-4/create-container/CreateContainer';
import { CreateItem } from '../../../../capa-4/create-item/CreateItem';
import { PlaceItemInContainer } from '../../../../capa-4/place-item-in-container/PlaceItemInContainer';
import { ScanItem } from '../../../../capa-4/scan-item/ScanItem';
import { MarkItemAsAnomalyMachine } from './MarkItemAsAnomalyMachine';

export const MarkItemAsAnomaly: FC = withActorRef(MarkItemAsAnomalyMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const menuItems = [
      {
        key:         'crear-contenedor',
        displayName: 'Crear otro contenedor',
        event:       'create'
      },
      {
        key:         'continuar-contenedor',
        displayName: 'Continuar con el mismo',
        event:       'continue'
      }
    ];

    const handleClick = useCallback((id: string) => {
      const clickedItem = menuItems.find(item => item.key === id);
      if (clickedItem) send(clickedItem.event);
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingTop={1}>
        <>{current.matches('ScanItem') ? <ScanItem /> : null}</>

        {current.matches('CreateContainer') ? <CreateContainer /> : null}
        {current.matches('CreateItem') ? <CreateItem /> : null}
        {current.matches('placeItemInContainer') ? (
          <PlaceItemInContainer />
        ) : null}

        {current.matches('ChooseContainer') ? (
          <Ink.Box flexDirection='column'>
            <Ink.Header>
              <Ink.Text>Seleccione una opción:</Ink.Text>
            </Ink.Header>

            <Ink.Box flexDirection='column' paddingLeft={1}>
              {menuItems.map(item => (
                <Ink.Button
                  key={item.key}
                  id={item.key}
                  label={item.displayName}
                  onClick={handleClick}
                />
              ))}
            </Ink.Box>
          </Ink.Box>
        ) : null}
      </Ink.Box>
    );
  }
);
