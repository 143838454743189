import { AuditOrderItemStatuses } from '../AuditOrderItem';
import { AuditOrderItemStatus } from './AuditOrderItemStatus';

export class AuditOrderItemCompletedStatus extends AuditOrderItemStatus {
  public displayName = 'Completada';

  public internalName = AuditOrderItemStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
