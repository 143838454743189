import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryCountingItem } from './InventoryCountingItem';
import { User } from './User';
import { Warehouse } from './Warehouse';
import {
  InventoryCountingStatus,
  InventoryCountingStatusCancelledStatus,
  InventoryCountingStatusCompletedStatus,
  InventoryCountingStatusPendingStatus,
  InventoryCountingStatusProcessingStatus
} from './inventory-counting-status';

export const InventoryCountingStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class InventoryCounting {
  @PrimaryKey()
  id: number;

  @Status({
    [InventoryCountingStatuses.Pending]:    InventoryCountingStatusPendingStatus,
    [InventoryCountingStatuses.Processing]:
      InventoryCountingStatusProcessingStatus,
    [InventoryCountingStatuses.Completed]:
      InventoryCountingStatusCompletedStatus,
    [InventoryCountingStatuses.Cancelled]:
      InventoryCountingStatusCancelledStatus
  })
  status: InventoryCountingStatus;

  @ForeignKey(() => User, { name: 'author_id' })
  authorId: number;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Field()
  remarks: string;

  @Field()
  reference2: string;

  @Field({ name: 'journal_remark' })
  journalRemark: string;

  @Field()
  causal: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => User, 'authorId')
  author: User;

  @NeededBy(() => InventoryCountingItem, 'inventoryCountingId')
  inventoryCountingItems: InventoryCountingItem[];

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;
}
