import { StagingAuditingProcessStatuses } from '../StagingAuditingProcess';
import { StagingAuditingProcessStatus } from './StagingAuditingProcessStatus';

export class StagingAuditingProcessProcessingStatus extends StagingAuditingProcessStatus {
  public displayName = 'Procesando';

  public internalName = StagingAuditingProcessStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
