import {
  Entity,
  Field,
  IsNotEmpty,
  IsNumberString,
  Knows,
  PrimaryKey
} from '@skyframe/core';
import { PurchaseOrder } from './PurchaseOrder';
import { StockTransferLogic } from './StockTransferLogic';

@Entity()
export class Provider {
  @PrimaryKey()
  id: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'business_name' })
  businessName: string;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field()
  description: string;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field()
  address: string;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @IsNumberString({
    message: 'El campo debe ser numerico'
  })
  @Field()
  cuit: string;

  @Field({ name: 'email' })
  email: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => PurchaseOrder, 'providerId')
  purchaseOrders: PurchaseOrder[];

  @Knows(() => StockTransferLogic, 'providerId')
  stockTransferLogic: StockTransferLogic[];

  // @Knows(() => Address, 'addressId')
  // address: Address;

  @Field({ name: 'sap_code' })
  sapCode: string;
}
