import { PurchaseOrderStatus } from './PurchaseOrderStatus';

export class PurchaseOrderStatusCancelledStatus extends PurchaseOrderStatus {
  public displayName = 'Cancelado';

  public internalName = 'cancelled';

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
