import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { TaskRole } from './TaskRole';
import { TaskType } from './TaskType';

@Entity()
export class TaskRoleTaskType {
  @PrimaryKey()
  id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => TaskRole, { name: 'task_role_id' })
  taskRoleId: number;

  @ForeignKey(() => TaskType, { name: 'task_type_id' })
  taskTypeId: number;

  @Knows(() => TaskRole, 'taskRoleId')
  taskRole: TaskRole;

  @Knows(() => TaskType, 'taskTypeId')
  taskType: TaskType;
}
