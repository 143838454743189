import { HeaderProps } from '@wms/client-core';
import React, { CSSProperties, FC } from 'react';

const headerContainerStyle: CSSProperties = {
  position:        'absolute',
  display:         'flex',
  flexDirection:   'row',
  top:             0,
  left:            0,
  right:           0,
  height:          '2rem',
  backgroundColor: '#9061F9',
  color:           '#FFFFFF'
};

const logoStyle: CSSProperties = {
  margin:    'auto 0.5rem',
  alignSelf: 'center',
  width:     '1rem',
  height:    '1rem'
};

const headerContentContainerStyle: CSSProperties = {
  width:      '100%',
  height:     '100%',
  alignItems: 'center',
  fontWeight: 'bold',
  display:    'inline-flex'
};

export const Header: FC<HeaderProps> = ({ children }) => {
  return (
    <div style={headerContainerStyle}>
      <img style={logoStyle} src='favicon.ico' />
      <div style={headerContentContainerStyle}>{children}</div>
    </div>
  );
};
