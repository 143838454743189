import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusPreparingStatus extends OrderStatus {
  public displayName = 'Preparando';

  public internalName = OrderStatuses.Preparing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
