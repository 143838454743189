import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { OrderItem } from './OrderItem';
import { Package } from './Package';
import { PackingProcess } from './PackingProcess';
import { PackingWave } from './PackingWave';
import { SorterExitProcess } from './SorterExitProcess';
import { WMSTransportOrder } from './WMSTransportOrder';
import {
  PackingItemStatus,
  PackingItemStatusDeliveredStatus,
  PackingItemStatusDraftStatus,
  PackingItemStatusPendingStatus
} from './packing-item-status';
import { PackingItemStatusMovingStatus } from './packing-item-status/PackingItemStatusMovingStatus';

export const PackingItemStatuses = {
  Pending:   'pending',
  Draft:     'draft',
  Delivered: 'delivered',
  Moving:    'moving'
};

@Entity()
export class PackingItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Package, { name: 'package_id' })
  packageId: number;

  @ForeignKey(() => PackingWave, { name: 'packing_wave_id' })
  packingWaveId: number;

  @ForeignKey(() => SorterExitProcess, { name: 'sorter_exit_process_id' })
  sorterExitProcessId: number;

  @Status({
    [PackingItemStatuses.Draft]:     PackingItemStatusDraftStatus,
    [PackingItemStatuses.Pending]:   PackingItemStatusPendingStatus,
    [PackingItemStatuses.Delivered]: PackingItemStatusDeliveredStatus,
    [PackingItemStatuses.Moving]:    PackingItemStatusMovingStatus
  })
  status: PackingItemStatus;

  @Field()
  quantity: number;

  @ForeignKey(() => OrderItem, { name: 'order_item_id' })
  orderItemId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => PackingProcess, { name: 'packing_process_id' })
  packingProcessId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => OrderItem, 'orderItemId')
  orderItem: OrderItem;

  @Needs(() => Package, 'packageId')
  package: Package;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Knows(() => PackingProcess, 'packingProcessId')
  packingProcess: PackingProcess;

  @Knows(() => PackingWave, 'packingWaveId')
  packingWave: PackingWave;

  @Knows(() => SorterExitProcess, 'sorterExitProcessId')
  sorterExitProcess: SorterExitProcess;

  @Knows(() => WMSTransportOrder, 'packingItemId')
  wmsTransportOrder: WMSTransportOrder;
}
