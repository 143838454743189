import { InventoryCountStatus } from './InventoryCountStatus';

export class InventoryCountStatusCompletedStatus extends InventoryCountStatus {
  public displayName = 'Completado';

  public internalName = 'completed';

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
