import { AxiosInstance } from 'axios';
import { RootContext } from './RootMachine';

export class RootService {
  constructor(private readonly axios: AxiosInstance) {}

  public getServices() {
    return {
      sendTaskEvent: this.sendTaskEvent.bind(this),
      pauseTask:     this.pauseTask.bind(this)
    };
  }

  private async sendTaskEvent(context: RootContext, event: any): Promise<void> {
    await this.axios.post<void>(
      `/task/${context.currentTask?.id}/event`,
      event
    );
  }

  private async pauseTask(context: RootContext, _event: any): Promise<void> {
    const { currentTask, currentTaskRef } = context;

    if (currentTask?.id) {
      const payload = currentTaskRef?.getSnapshot();
      await this.axios.post<void>(`/task/${currentTask.id}/pause`, {
        value:   payload.value,
        context: payload.context
      });
    } else {
      /* Ignore utility task */
    }
  }
}
