import { ClassificationProcessStatuses } from '../ClassificationProcess';
import { ClassificationProcessStatus } from './ClassificationProcessStatus';

export class ClassificationProcessPausedStatus extends ClassificationProcessStatus {
  public displayName = 'Pausado';

  public internalName = ClassificationProcessStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
