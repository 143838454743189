import { assign, createMachine } from 'xstate';

export interface EnterQuantityContext {
  quantity: number | null;
  hint: string | null;
  min: number;
  max: number | null;
  matches: number | null;
  error: string | null;
}

export const DefaultEnterQuantityContext: EnterQuantityContext = {
  quantity: null,
  hint:     'Ingrese cantidad',
  min:      0,
  max:      null,
  matches:  null,
  error:    null
};

export const EnterQuantityMachine = createMachine<EnterQuantityContext>(
  {
    id:      'EnterQuantityMachine',
    initial: 'enteringQuantity',
    states:  {
      enteringQuantity: {
        on: {
          updateQuantity: {
            actions: assign({
              quantity: (_ctx, event) => event.data.quantity,
              error:    (_ctx, _event) => null
            })
          },
          submitQuantity: {
            target: 'validateQuantity'
          }
        }
      },
      validateQuantity: {
        invoke: {
          src:    'validateMinAndMaxQuantity',
          onDone: [
            {
              target:  'enteringQuantity',
              cond:    'quantityIsNotValid',
              actions: assign({
                error:    (_ctx, event) => event.data,
                quantity: (_ctx, _event) => null
              })
            },
            {
              target: 'quantitySubmitted'
            }
          ]
        }
      },
      quantitySubmitted: {
        type: 'final',
        data: (ctx, _event) => ({ quantity: ctx.quantity })
      }
    }
  },
  {
    services: {
      // eslint-disable-next-line require-await
      validateMinAndMaxQuantity: async ctx => {
        if (ctx.matches && (ctx.quantity as number) !== ctx.matches) {
          return `La cantidad debe ser ${ctx.matches}`;
        }
        if (ctx.min && (ctx.quantity as number) < ctx.min) {
          return `La cantidad no puede ser menor a ${ctx.min}`;
        }

        if (ctx.max && (ctx.quantity as number) > ctx.max) {
          return `La cantidad no puede ser mayor a ${ctx.max}`;
        }

        return false;
      }
    },
    guards: {
      quantityIsNotValid: (_ctx, event) => event.data
    }
  }
);
