import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditingWave } from './AuditingWave';
import { Container } from './Container';
import { Customer } from './Customer';
import { MovingToAuditingLocationProcess } from './MovingToAuditingLocationProcess';
import { PickingWave } from './PickingWave';
import { WMSTransportOrder } from './WMSTransportOrder';
import {
  MopPickingContainerCancelledStatus,
  MopPickingContainerMovedStatus,
  MopPickingContainerMovingStatus,
  MopPickingContainerPickingStatus,
  MopPickingContainerStatus
} from './mop-picking-container-status';

export const MopPickingContainerStatuses = {
  Picking:   'picking',
  Moving:    'moving',
  Moved:     'moved',
  Cancelled: 'cancelled'
};

@Entity()
export class MopPickingContainer {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => PickingWave, { name: 'picking_wave_id' })
  pickingWaveId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => Customer, { name: 'customer_id' })
  customerId: number;

  @ForeignKey(() => MovingToAuditingLocationProcess, {
    name: 'moving_to_auditing_location_process_id'
  })
  movingToAuditingLocationProcessId: number;

  @Status({
    [MopPickingContainerStatuses.Picking]:   MopPickingContainerPickingStatus,
    [MopPickingContainerStatuses.Moving]:    MopPickingContainerMovingStatus,
    [MopPickingContainerStatuses.Moved]:     MopPickingContainerMovedStatus,
    [MopPickingContainerStatuses.Cancelled]: MopPickingContainerCancelledStatus
  })
  status: MopPickingContainerStatus;

  @Field({ name: 'picking_cart_position' })
  pickingCartPosition: number;

  @Field({ name: 'label' })
  label: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => PickingWave, 'pickingWaveId')
  pickingWave: PickingWave;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Needs(() => Customer, 'customerId')
  customer: Customer;

  @Needs(
    () => MovingToAuditingLocationProcess,
    'movingToAuditingLocationProcessId'
  )
  movingToAuditingLocationProcess: MovingToAuditingLocationProcess;

  @NeededBy(() => AuditingWave, 'mopPickingContainerId')
  auditingWaves: AuditingWave[];

  @Knows(() => WMSTransportOrder, 'mopPickingContainerId')
  wmsTransportOrder: WMSTransportOrder;
}
