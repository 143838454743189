import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusPreparedStatus extends OrderItemStatus {
  public displayName = 'Preparado';

  public internalName = OrderItemStatuses.Prepared;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
