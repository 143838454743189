import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { Location } from './Location';
import { LocationAttribute } from './LocationAttribute';

@Entity()
export class LocationLocationAttribute {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ForeignKey(() => LocationAttribute, { name: 'location_attribute_id' })
  locationAttributeId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Location, 'locationId')
  location: Location;

  @Needs(() => LocationAttribute, 'locationAttributeId')
  locationAttribute: LocationAttribute;
}
