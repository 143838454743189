import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { PackagingLevel } from './PackagingLevel';

@Entity()
export class Label {
  @PrimaryKey()
  id: number;

  @Field()
  code: number;

  @ForeignKey(() => PackagingLevel, { name: 'packaging_level_id' })
  packagingLevelId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => PackagingLevel, 'packagingLevelId')
  labels: PackagingLevel;
}
