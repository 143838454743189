import { Lot } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { api } from '../../../../api/utils/axios-instance';

export interface CreateLotContext {
  lotNumber: string;
  expirationDate: Date | null;
  createdLot: Lot | null;
  hint: string | null;
  error: string | null;
}

export const defaultCreateLotContext = {
  lotNumber:      '',
  expirationDate: null,
  createdLot:     null,
  error:          '',
  hint:           null
};

export const CreateLotMachine = createMachine<CreateLotContext>(
  {
    id:      'CreateLotMachine',
    initial: 'AwaitingLotNumber',
    states:  {
      AwaitingLotNumber: {
        on: {
          submit: [
            {
              cond:   'invalidLotNumber',
              target: 'AwaitingLotNumber'
            },
            {
              target: 'AwaitingDate'
            }
          ],
          change: {
            actions: 'assignLotNumber',
            target:  'AwaitingLotNumber'
          }
        }
      },
      AwaitingDate: {
        on: {
          change: {
            actions: assign({
              expirationDate: (_ctx, event) => event.data.expirationDate
            }),
            target: 'AwaitingDate'
          },
          submit: [
            {
              cond:    'invalidDate',
              target:  'AwaitingDate',
              actions: 'errorInvalidDate'
            },
            {
              target: 'CreateLot'
            }
          ]
        }
      },
      CreateLot: {
        invoke: {
          src:    'createLot',
          onDone: {
            actions: 'assignLot',
            target:  'LotCreated'
          },
          onError: {
            actions: 'lotCreationError',
            target:  'AwaitingLotNumber'
          }
        }
      },
      LotCreated: {
        type: 'final',
        data: ctx => ({ lot: ctx.createdLot })
      }
    }
  },
  {
    guards: {
      ...UtilityGuards,
      invalidLotNumber: ctx => !ctx.lotNumber || ctx.lotNumber === '',
      invalidDate:      ctx => !ctx.expirationDate || ctx.expirationDate < new Date()
    },
    actions: {
      ...UtilityActions,
      assignLotNumber: assign({
        lotNumber: (ctx, event) => event.data.lotNumber
      }),
      errorInvalidDate: assign({
        error: _ctx => 'Ingrese una fecha de expiración válida'
      }),
      assignLot: assign({
        createdLot: (_ctx, event) => event.data.lot
      })
    },
    services: {
      ...API,
      createLot: async ctx => {
        const { data: lot } = await api.post('lot', {
          lotNumber:      ctx.lotNumber,
          expirationDate: ctx.expirationDate
        });
        return { lot };
      }
    }
  }
);
