import Ink from '@ink';
import React, { FC, useCallback } from 'react';

interface MenuProps {
  disconnect: () => void;
  send: (any: string, any1: any) => void;
}

const menuItems = [
  {
    key:         'inbound',
    displayName: 'Recepciones',
    event:       'NavigateToInboundMenu',
    payload:     {}
  },
  {
    key:         'outbound',
    displayName: 'Preparaciones',
    event:       'NavigateToOutboundMenu',
    payload:     {}
  },
  {
    key:         'logistics',
    displayName: 'Logisticas',
    event:       'NavigateToLogisticsMenu',
    payload:     {}
  },
  {
    key:         'inventory-management',
    displayName: 'Utilidades',
    event:       'NavigateToInventoryManagementMenu',
    payload:     {}
  },
  {
    key:         'disconnect',
    displayName: 'Desconectarse',
    event:       'NavigateToInboundMenu',
    payload:     {}
  }
] as const;

export const Menu: FC<MenuProps> = ({ disconnect, send }) => {
  const handleClick = useCallback((id: string) => {
    const idToTransitionMap = {
      'assign-task':          'findTask',
      inbound:                'NavigateToInboundMenu',
      outbound:               'NavigateToOutboundMenu',
      'inventory-management': 'NavigateToInventoryManagementMenu',
      logistics:              'NavigateToLogisticsMenu'
    };
    if (id in idToTransitionMap) {
      send(idToTransitionMap[id], {});
    } else {
      disconnect();
    }
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item => (
          <Ink.Button
            key={item.key}
            id={item.key}
            label={item.displayName}
            onClick={handleClick}
          />
        ))}
      </Ink.Box>
    </Ink.Box>
  );
};

Menu.displayName = 'Menu';
