import { ReceiptStatus } from './ReceiptStatus';

export class ReceiptStatusOpenStatus extends ReceiptStatus {
  public displayName = 'Esperado';

  public internalName = 'open';

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
