import { StockTableStatuses } from '../StockTable';
import { StockItemStatus } from './StockItemStatus';

export class StockItemStatusScrapStatus extends StockItemStatus {
  public displayName = 'Roto';

  public internalName = StockTableStatuses.Scrap;

  public statusColor = '#ffd3d3';
}
