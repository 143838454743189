import { PackingProcessStatuses } from '../PackingProcess';
import { PackingProcessStatus } from './PackingProcessStatus';

export class PackingProcessStatusPendingStatus extends PackingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = PackingProcessStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
