import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { LabelingDesk } from './LabelingDesk';
import { User } from './User';
import { Warehouse } from './Warehouse';

export enum PrinterTypes {
  Portable = 'portable',
  Fixed = 'fixed'
}

export enum PrinterLabels {
  Test = 'test',
  Container = 'container'
}

@Entity()
export class Printer {
  @PrimaryKey()
  id: number;

  @Field({ name: 'printer_code' })
  printerCode: string;

  @Field({ name: 'model' })
  model: string;

  @Field({ name: 'available' })
  available: boolean;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Field({ name: 'type' })
  type: string;

  @ForeignKey(() => User, { name: 'user_id' })
  userId: number;

  @Field({ name: 'ip' })
  ip: string;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => LabelingDesk, { name: 'labeling_desk_id' })
  labelingDeskId: number;

  @Knows(() => LabelingDesk, 'labelingDeskId')
  labelingDesk: LabelingDesk;

  @Knows(() => User, 'userId')
  user: User;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}
