import { InventoryItem } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import Ink from '../../../../ink';
import { withActorRef } from '../../../shared/Machine';
import { RestockLine } from '../../capa-4/restocking-line-machine/RestockLine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanItem } from '../../capa-4/scan-item/ScanItem';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { RestockingUtilityMachine } from './RestockingUtilityMachine';

export const RestockingUtility: FC = withActorRef(RestockingUtilityMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const handleClick = useCallback(
      (id: string) => {
        const itemId = Number(id);

        const item = current.context.restockItems.find(i => i.id === itemId);

        send({
          type: 'select',
          data: { item, id, itemId, items: current.context }
        });
      },
      [current.context, current.context.restockItems]
    );

    return (
      <>
        <Ink.Header>Utilidad de Restocking</Ink.Header>

        {current.matches('ChooseContOrLocation') ? (
          <>
            <Ink.Text>Buscar item suelto o en contenedor?</Ink.Text>

            <Ink.Button
              key='location'
              id='location'
              label='Ubicación'
              onClick={() => send({ type: 'location' })}
            />

            <Ink.Button
              key='create'
              id='create'
              label='Contenedor'
              onClick={() => send('container')}
            />
          </>
        ) : null}

        {current.matches('ScanLocation') ? <ScanLocation /> : null}

        {current.matches('ScanContainer') ? <ScanContainer /> : null}

        {current.matches('ScanItem') ? <ScanItem /> : null}

        {current.matches('RestockItems') ? <RestockLine /> : null}

        {current.matches('ChooseRestockItem') ? (
          <>
            <Ink.Text>Elija el item que se utilizará para restockear.</Ink.Text>
            <Ink.Box flexDirection='column'>
              {current.context.restockItems.map((item, i) => (
                <Ink.Button
                  key={item.id}
                  id={item.id.toString()}
                  label={
                    `${i + 1}. Cantidad ${
                      item.quantity
                    } - Estado ${InventoryItem.mapStatusName(
                      (item as any).status
                    )} (calidad: ${InventoryItem.mapQualityStatusName(
                      (item as any).qualityStatus
                    )}) ` + (item.lot ? `- Lote: ${item.lot.lotNumber}` : '')
                  }
                  onClick={handleClick}
                />
              ))}
            </Ink.Box>
          </>
        ) : null}

        {current.matches('Error') ? (
          <Ink.Text>
            <Ink.Chalk redBright>{current.context.error as any}</Ink.Chalk>
          </Ink.Text>
        ) : null}
      </>
    );
  }
);

RestockingUtility.displayName = 'RestockingUtility';
