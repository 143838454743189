import { StagingAuditingContainerStatuses } from '../StagingAuditingContainer';
import { StagingAuditingContainerStatus } from './StagingAuditingContainerStatus';

export class StagingAuditingContainerPendingStatus extends StagingAuditingContainerStatus {
  public displayName = 'Pendiente';

  public internalName = StagingAuditingContainerStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
