import { MachineOptions, assign } from 'xstate';
import { MiniMoveToSorterExitContext } from './machine';

type MiniMoveToSorterExitActions = MachineOptions<
  MiniMoveToSorterExitContext,
  any
>['actions'];

export const miniMoveToSorterExitActions: MiniMoveToSorterExitActions = {
  assignLabel: assign({
    label: (_ctx, event) => event.data.label
  }),
  assignLocation: assign({
    location: (_ctx, event) => event.data.location
  }),
  assignConfirmationError: assign({
    error: (_ctx, event) =>
      event?.data?.data?.errorView ||
      'Ha ocurrido un error, por favor intente nuevamente.'
  })
};
