import { MachineOptions, assign } from 'xstate';
import { TestPrinterContext } from './machine';

type TestPrinterActions = MachineOptions<TestPrinterContext, any>['actions'];

export const testPrinterActions: TestPrinterActions = {
  assignError: assign({
    error: (_ctx, event) => {
      return event.data.data.response.data.errorView;
    }
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  })
};
