import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { Location } from './Location';

export const LocationTypeExternalNames = {
  STK: 'stk'
};

export const LocationTypeNames = {
  Dock:           'Muelle',
  Staging:        'Canal',
  PreStoraging:   'Ubicación de Prealmacenamiento',
  PreDispatch:    'Ubicación de Consolidación y Empaque',
  Grid:           'Grid',
  Lane:           'Canal de Grid',
  Section:        'Sector',
  Aisle:          'Pasillo',
  Level:          'Nivel',
  Rack:           'Rack',
  Picking:        'Ubicación de Picking',
  Herramienta:    'MHE',
  Storage:        'Ubicación de Almacenamiento',
  Location:       'Ubicación',
  Header:         'Ubicación de Cabecera',
  Ship:           'Nave',
  SorterLocation: 'Ubicación de Sorter',

  // Special Storage Equipment
  Refrigerator:       'Refrigerado',
  FrozenRefrigerator: 'Congelador',
  Camera:             'Cámara',

  // Process-Related Locations
  Consolidation:   'Consolidación',
  ValueAddedTable: 'Mesa',
  PackingStation:  'Estación de Empaque',
  ProcessLocation: 'Ubicación de Proceso',
  // Quality-Related Locations
  Anomaly:         'Anomalías',

  // Equipment
  PickingCart: 'PickingCart',
  PalletClamp: 'PalletClamp',
  Forklift:    'Forklift',
  Sorter:      'Sorter',
  Conveyor:    'Cinta',
  MHE:         'MHE'
};

@Entity()
export class LocationType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'external_name' })
  externalName: string;

  @Field({ name: 'masked' })
  masked: boolean;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => Location, 'locationTypeId')
  locations: Location[];
}
