import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { Location } from './Location';
import { Product } from './Product';
import { ProductRestockingLevel } from './ProductRestockingLevel';

@Entity()
export class ProductRestockingLevelAlert {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ForeignKey(() => ProductRestockingLevel, {
    name: 'product_restocking_level_id'
  })
  productRestockingLevelId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Product, 'productId')
  product: Product;

  @Needs(() => Location, 'locationId')
  location: Location;

  @Needs(() => ProductRestockingLevel, 'productRestockingLevelId')
  productRestockingLevel: ProductRestockingLevel;
}
