import { PickingWaveStatus } from './PickingWaveStatus';

export class PickingWaveStatusPausedStatus extends PickingWaveStatus {
  public displayName = 'Pausado';

  public internalName = 'paused';

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
