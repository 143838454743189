import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MoveToAuditingLocationContext } from './machine';

type MoveToAuditingLocationActionsType = MachineOptions<
  MoveToAuditingLocationContext,
  any
>['actions'];

export const moveToAuditingLocationActions: MoveToAuditingLocationActionsType =
  {
    assignContainer: assign({
      container: (_ctx, event) => event.data
    }),
    assignLocationId: assign({
      locationId: (_ctx, event) => event.data.id
    }),
    assignMopPickingContainer: assign({
      mopPickingContainer: (_ctx, event) => event.data.mopPickingContainer
    }),
    assignError: assign({
      error: (_ctx, event) =>
        getErrorView(
          event.data?.data ?? event.data,
          'Ocurrió un error, por favor reintente más tarde.'
        )
    }),
    clearError: assign({
      error: (_ctx, _event) => null
    })
  };
