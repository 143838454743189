import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { Container } from './Container';
import { PickingContainerGroup } from './PickingContainerGroup';

@Entity()
export class PickingContainerGroupContainer {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => PickingContainerGroup, {
    name: 'picking_container_group_id'
  })
  pickingContainerGroupId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Needs(() => PickingContainerGroup, 'pickingContainerGroupId')
  pickingContainerGroup: PickingContainerGroup;
}
