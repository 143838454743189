import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetLocationByNameFn } from '../machine';

export function getlocationByNameAndValidLocationAttribute(
  defaultErrorMessage: string,
  requestedLocationAttribute: string
): GetLocationByNameFn {
  return fetchResult(defaultErrorMessage, name =>
    api.get<Location>('location/attribute/', {
      params: { name, attribute: requestedLocationAttribute }
    })
  );
}
