import { ClassificationItemStatuses } from '../ClassificationItem';
import { ClassificationItemStatus } from './ClassificationItemStatus';

export class ClassificationItemClassifiedStatus extends ClassificationItemStatus {
  public displayName = 'Clasificado';

  public internalName = ClassificationItemStatuses.Classified;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
