import { TransportOrderStatuses } from '../TransportOrder';
import { TransportOrderStatus } from './TransportOrderStatus';

export class TransportOrderStatusCancelledStatus extends TransportOrderStatus {
  public displayName = 'Cancelado';

  public internalName = TransportOrderStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
