import { ConsolidateContainersProcessStatuses } from '../ConsolidateContainersProcess';
import { ConsolidateContainersProcessStatus } from './ConsolidateContainersProcessStatus';

export class ConsolidateContainersProcessStatusPending extends ConsolidateContainersProcessStatus {
  public displayName = 'Pendiente';

  public internalName = ConsolidateContainersProcessStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
