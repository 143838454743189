import axios from 'axios';
import { SESSION_TOKEN } from '../../session/useSession';

export const api = axios.create({
  baseURL: process.env.BACKEND_HOST || 'http://localhost:8080/api/v1'
});

api.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    SESSION_TOKEN
  )}`;
  return config;
});
