import {
  AnomalyTypes,
  Container,
  Lot,
  ProductItem,
  ReceiptItemStatuses
} from '@wms/domain';
import { assign } from 'xstate';
import { Page } from '../types/page';
import { api } from './utils/axios-instance';

export const API = {
  createContainer: async ctx => {
    const { data: container } = await api.post('/container', {
      lpn:              ctx.lpn,
      containerTypeId:  ctx.containerType?.id,
      containerType:    ctx.containerType,
      locationId:       ctx.location?.id,
      location:         { name: ctx.location?.name },
      isAnomaly:        ctx.isAnomaly,
      receiptId:        ctx.receiptId,
      orderId:          ctx.orderId,
      pickingWaveId:    ctx.pickingWaveId,
      pickingProcessId: ctx.pickingProcessId,
      parentId:         ctx.parentId,
      ...ctx.partialContainerBody
    } as Container);

    return { container };
  },
  patchContainerWeight: ctx => {
    return api.patch(`/container/${ctx.container.id}`, {
      weight: ctx.container.weight,
      lpn:    `DISPATCH-CONTAINER-${ctx.container.lpn}`
    });
  },
  getSuggestedLocation: ctx => {
    return api.post('/location/suggest-location', ctx);
  },
  finishIfAllWeightCompleted: ctx => {
    return api.post(`/order/${ctx.container.orderId}/check-if-all-weighted`);
  },
  startDispatchIfWeightCompleted: ctx => {
    return api.post(`/order/${ctx.container.orderId}/dispatch`, {});
  },
  confirmDispatch: ctx => {
    return api.post(`/order/${ctx.orderId}/confirm-dispatch`, ctx);
  },
  getDestinationContainers: async ctx => {
    const { data: containers } = await api.get(
      `/order/${ctx.payload.orderId}/containers`,
      { params: { onlyAvailable: true } }
    );

    return { containers };
  },

  getTasksForContainers: async ctx => {
    const { data: associatedTasks } = await api.get(
      `/container/${ctx?.container.id}/tasks`
    );
    return { associatedTasks };
  },

  getDestinationContainersBaldas: async ctx => {
    const { data: containers } = await api.get(
      `/order/${ctx.payload.orderId}/baldas-containers`
    );

    return { containers };
  },

  getLotsForLocationBySku: async ctx => {
    const lots: Lot[] = [];
    const containers = ctx.location.containers;
    let correctContainer;
    for (const container of containers) {
      await api
        .get(`/container/${container.id}/get-lots-by-sku/${ctx.sku}`)
        .then(response => {
          const { data: containerLots } = response;
          lots.push(...containerLots);
          correctContainer = container;
        })
        .catch(err => {
          if (
            err?.response?.data?.errorView !=
            `No existe item de SKU ${ctx.sku} en el contenedor ${container.id}.`
          ) {
            throw new Error(err?.response?.data?.errorView);
          }
        });
    }

    return { lots, correctContainer };
  },
  validateItem: async ctx => {
    const { data: canCreate } = await api.post('/product-item/validate', {
      ...ctx,
      sku:              ctx.sku,
      quantity:         ctx.quantity,
      lotNumber:        ctx.lot?.lotNumber,
      lotId:            ctx.lot?.id,
      containerId:      ctx.container?.id,
      packagingLevelId: ctx.suggestedItem?.packagingLevel?.id,
      location:         ctx.location,
      type:             ctx.type
    });

    return { canCreate };
  },
  createItem: async ctx => {
    const { data: item } = await api.post('/product-item', {
      ...ctx,
      sku:              ctx.sku,
      quantity:         ctx.quantity,
      lotNumber:        ctx.lot?.lotNumber,
      lotId:            ctx.lot?.id,
      containerId:      ctx.container?.id,
      packagingLevelId: ctx.suggestedItem?.packagingLevel?.id,
      location:         ctx.location,
      type:             ctx.type
    });

    return { item };
  },
  createLot: async ctx => {
    const { data: createdLot } = await api.post('/lot', {
      ...ctx,
      lotNumber:      ctx.lot,
      expirationDate: new Date()
    });

    return { createdLot };
  },
  moveItemToContainer: ctx => {
    return api.patch(`/product-item/${ctx.item?.id}/container`, {
      containerId: ctx.container?.id
    });
  },
  findItem: async ctx => {
    const {
      type,
      product,
      sku,
      location,
      container,
      lotNumber,
      receiptId,
      inventoryCountingItemId,
      status,
      quantity,
      skipQuantityCheck,
      locationName,
      isBulkItem
    } = ctx;

    const params = {
      type,
      sku,
      productId:   product?.id,
      locationId:  location?.id,
      containerId: container?.id,
      lotNumber,
      receiptId,
      inventoryCountingItemId,
      status,
      ...(!skipQuantityCheck ? { quantity } : {}),
      locationName,
      isBulkItem
    };

    const { data: items } = await api.get<ProductItem[]>('/product-item', {
      params
    });

    return { items };
  },
  changeItemStatus: ctx => {
    return api.patch(`/product-item/${ctx.item.id}/status`, ctx);
  },
  findItemBySku: async ctx => {
    const { data: item } = await api.get(`/inventory-item/sku/${ctx.sku}`);
    return { item };
  },
  findProduct: async context => {
    const { data: product } = await api.get(
      `/product/get-by-identifier?identifier=${
        context.identifier ||
        context?.item?.sku ||
        context?.sku ||
        context?.ean ||
        context?.upc
      }`
    );
    return { product };
  },
  findProductByIdentifier: async context => {
    const { data: product } = await api.get(
      `/product/by-identifier?identifier=${
        context.identifier ||
        context?.item?.sku ||
        context?.sku ||
        context?.ean ||
        context?.upc
      }`
    );
    return { product };
  },
  findContainerByLpn: async ctx => {
    const { data: container } = await api.get(`/container/lpn/${ctx.lpn}`);
    return { container };
  },

  /**
   * @param ctx {id: number, lpn: string}
   */
  findContainer: async ctx => {
    const { data: container } = await api.get<Container>(
      '/container/container-details',
      {
        params: { lpn: ctx.lpn, containerId: ctx.id || ctx.container.id }
      }
    );
    return { container };
  },
  findLocation: () => {
    return Promise.resolve({ id: 1, name: 'AB-123-421-52' });
  },
  fetchReceiptContainers: async ctx => {
    const receiptContainers = await api.get<Page<Container>>(
      `/container?qs=receiptId=${ctx.receiptId}%26isClosed=${false}%26&limit=20`
    );
    return receiptContainers.data.rows;
  },
  findLocationByName: async ctx => {
    const params = {
      name: ctx.location.name,
      type: ctx.type
    };
    const { data: location } = await api.get('/location/name', { params });
    return { location };
  },
  findLocationByDigitControl: async ctx => {
    const params = {
      checkDigit: ctx.location.checkDigit.toString(),
      name:       ctx.requiredLocation.name,
      type:       ctx.type
    };

    const { data: location } = await api.get('/location/check-digit-and-name', {
      params
    });
    return { location };
  },
  moveContainer: async ctx => {
    const { data: container } = await api.post(
      '/container/place-in-new-location',
      {
        containerId:  ctx.container.id,
        locationName: ctx.location.name,
        taskId:       ctx.task?.id
      }
    );

    return { container };
  },
  moveItem: async context => {
    const { data: item } = await api.patch(
      `/inventory-item/${context.item.id}/move-to-location`,
      {
        quantity:                context.item.quantity,
        destinationLocationName: context.location.name,
        isRestocking:            context.isRestocking
      }
    );
    return { item };
  },
  addItemToContainer: async context => {
    const { data: item } = await api.post(
      `/container/${context.container.id}/add-item`,
      {
        item: context.item,
        context
      }
    );

    return { item };
  },
  changeItemContainerAndLocation: async context => {
    const { data: item } = await api.post(
      '/container/change-item-container-and-location',
      {
        item:            context.item,
        newContainerLpn: context.logicStorageContainerLpn,
        location:        context.location
      }
    );

    return { item };
  },
  removeItemFromContainer: async context => {
    const { data: item } = await api.post('/container/remove-item', {
      item:     context.item,
      quantity: context.quantity
    });

    return { item };
  },
  fetchSuggestedLocation: (ctx: any) => {
    return api.get(ctx.fetchSuggestedLocationFn, {
      params: {
        lpn: ctx.container?.lpn
      }
    });
  },

  rejectItem: async context => {
    const { data: item } = await api.post(
      `/receipt-item/${context.item.id}/reject`,
      {
        status: ReceiptItemStatuses.Rejected,
        item:   context.item
      }
    );
    return { item };
  },
  fetchSuggestedLocationForItem: () => {
    return Promise.resolve({ location: 2010 });
  },
  fetchSuggestedContainer: () => {
    return Promise.resolve({ lpn: 10101 });
  },
  fetchTasks: ctx => {
    return api.get('/task-bucket' + ctx.container.id + '');
  },
  selfAssignTask: () => {
    return Promise.resolve([]);
  },
  patchReceipt: ctx => {
    return api.patch(`/receipt/${ctx.receipt.id}`, {
      unloadingLocationId: ctx.unloadingLocation.id
    });
  },
  initializeTask: ctx => {
    return api.post('/task-bucket/' + ctx.id + '/start', ctx);
  },
  completeTask: ctx => {
    return api.post(`/task/${ctx.task?.id}/complete`, ctx);
  },
  completeSimpleTask: ctx => {
    return api.post(`/task/${ctx.task?.id}/complete-simple`, {
      ...ctx,
      orderId:         ctx.order?.id,
      stockTransferId: ctx.stockTransfer?.id
    });
  },
  markItemAsAudited: context => {
    return api.post('/audit-order-item/audit', {
      ...context
    });
  },
  createWeightingTasks: ctx => {
    return api.post('/task/create-weighting-tasks', {
      containerLpns: ctx.movedContainers.map(container => container.lpn),
      context:       ctx
    });
  },
  findInventoryItem: async ctx => {
    const { data: items } = await api.get(
      `/container/${ctx.container.id}/find-items-in-container`,
      {
        params: {
          sku:       ctx.sku,
          lotNumber: ctx.lotNumber
        }
      }
    );

    return { items };
  },
  fetchRejectionReasons: async () => {
    const { data: rejectionReasons } = await api.get('/rejection-reasons');

    return { rejectionReasons };
  },
  markAsAnomaly: ctx => {
    return api.post('/inventory-item/mark-as-anomaly', {
      anomalyType:       ctx.anomalyType,
      sku:               ctx.item.sku,
      lotNumber:         ctx.item.lot?.lotNumber,
      quantity:          ctx.anomalyQuantity,
      containerId:       ctx.item.containerId,
      rejectionReasonId: ctx.selectedRejectionReasonId
    });
  },
  getPickingWaveAnomalyContainers: async ctx => {
    const { data: containers } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/anomaly-containers`,
      {}
    );

    return { containers };
  },
  getAnomalyLocation: async ctx => {
    const { data: location } = await api.get(
      `location/warehouse/${ctx.location.warehouseId}/anomaly-location`,
      {}
    );

    return { location };
  },
  checkPickWave: async ctx => {
    const { data: pickWave } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/is-paused`,
      {}
    );

    return { pickWave };
  },
  getReceiptAnomalyContainers: async ctx => {
    const { data: containers } = await api.get(
      `receipt/${ctx.receiptId}/anomaly-containers`,
      {}
    );

    return { containers };
  },
  closeContainer: ctx => {
    return api.patch('container/close', {
      containerId: ctx.selectedContainer.id
    });
  },
  closeAllContainers: ctx => {
    return api.patch('container/close-all', {
      containerIds: ctx.containerIds
    });
  },
  getOrderAnomalyContainers: async ctx => {
    const { data: containers } = await api.get(
      `order/${ctx.orderId}/anomaly-containers`
    );

    return { containers };
  },
  fetchFractionableItems: async ctx => {
    const { data: items } = await api.get(
      `container/${ctx.containerId}/get-fractionable-items`
    );

    return { items };
  },
  inventoryItemDetails: async ctx => {
    const { data: item } = await api.get(
      `inventory-item/${ctx.selectedItemId}`
    );

    return { item };
  },
  fetchSuggestedFracPieces: async ctx => {
    const { data: pieces } = await api.get(
      `inventory-item/${ctx.selectedItemId}/get-fractionable-pieces`
    );

    return { pieces };
  },
  getValidLocationsForPicking: async ctx => {
    const { data: locations } = await api.get(
      'location/get-locations-for-picking',
      {
        params: {
          sku: ctx.currentItem.sku
        }
      }
    );

    return { locations };
  },
  fracItem: ctx => {
    return api.post('/inventory-item/fractionate-item', {
      inventoryItemId:   ctx.selectedItemId,
      pieces:            ctx.fracPieces,
      remainingQuantity: ctx.remainingQty
    });
  },
  fetchRestockingItems: async ctx => {
    const { data: items } = await api.get(
      `product/${ctx.item.productId}/needs-restocking/${ctx.item.locationId}`
    );

    return { items };
  },
  deleteUnusedContainers: async ctx => {
    const { data: containers } = await api.post(
      `/receipt/${ctx.receipt.id}/remove-unused-containers`,
      {}
    );

    return { containers };
  },
  findPendingTasks: async ctx => {
    const { data: tasks } = await api.get(
      `/order/${ctx.order.id}/pending-tasks`,
      {}
    );

    return { tasks };
  },
  fetchContainerTypes: async ctx => {
    if (ctx.receiptId) {
      const { data } = await api.get(
        `/container-type/receipt/${ctx.receiptId.toString()}`
      );
      return { containerTypes: data };
    } else {
      const { data } = await api.get('/container-type');
      return { containerTypes: data.rows };
    }
  }
};

export const UtilityGuards = {
  containerIsSet:         context => !!context.container,
  locationIsSet:          context => !!context.location,
  itemIsSet:              context => context.item !== null,
  requestedItemValidated: (context, event) =>
    context?.requestedItem?.sku === event?.data?.sku,
  requestedItemInvalid: (context, event) => {
    return (
      !!context?.requestedItem &&
      context?.requestedItem?.sku !== event?.data?.sku
    );
  },
  // TODO
  requestedContainerValidated: (context, event) =>
    !!context.requestedContainer &&
    context.requestedContainer.lpn == event.data.lpn,
  requestedContainerInvalid: (context, event) =>
    !!context.requestedContainer &&
    context.requestedContainer.lpn !== event.data.lpn,
  slottingAlgorithmIsSet:           context => !!context.slottingAlgorithm,
  productScannedItsNotTheSuggested: context =>
    context.suggestedItem?.sku !== context.scannedProduct?.sku,
  // TODO
  productIsBultoCiego:       context => context.scannedProduct?.serializedControl,
  // TODO
  requestedItemIsBultoCiego: context =>
    context.requestedItem.product?.serializedControl,
  matchesRequestedItemSerial: (context, event) => {
    return (
      !!context.requestedItem &&
      context.requestedItem.wmsReceiptItemId === event.barcode
    );
  },
  // TODO
  matchesValidItems:         () => true,
  productIsLotControlled:    context => context.scannedProduct?.lotControl,
  'receiptItemsRemaining<1': context =>
    context.receiptItemsRemaining.length < 1,
  'containersRemaining<1': context => context.containersToMove.length < 1,
  isMissingAnomaly:        ctx => ctx.anomalyType === AnomalyTypes.MISSING
};

export const UtilityActions = {
  // Errors
  errorInvalidContainer: assign<any>({
    error: () => 'El contenedor escaneado es inválido'
  }),
  errorInvalidNumberOfLocation: assign<any>({
    error: () => 'La ubicación solo debe tener números'
  }),
  errorInvalidMaxCharacters: assign<any>({
    error: () => 'La cantidad de caracteres fue superada'
  }),
  errorInvalidRange: assign<any>({
    error: () => 'Solo se permite ingresar posiciones del 1 al 5'
  }),
  errorInvalidLocation: assign<any>({
    error: () => 'La locación escaneada es inválida'
  }),
  errorInvalidProduct: assign<any>({
    error: () => 'El producto escaneado es inválido'
  }),
  errorInvalidControlDigit: assign<any>({
    error: () => 'El dígito de control es inválido'
  }),
  assignError: assign<any>({
    error: (context, event: any) =>
      event.data?.response?.data?.errorView ||
      event.data?.response?.data?.message ||
      (event as any).data.toString() ||
      'No se pudo crear contenedor'
  }),
  clearError: assign<any>({
    error: (_ctx, _event) => ''
  }),
  assignLocation: assign({
    location: (context, event) =>
      (event as any).location || (event as any).data.location
  }),
  assignValidLocations: assign({
    validLocations: (_ctx, event) => (event as any).data.locations
  }),
  assignContainer: assign({
    container: (
      _context,
      event: {
        data: {
          container: Container;
        };
      }
    ): Container => event.data.container
  }),
  assignItem: assign({
    item: (_context, event) => {
      return (event as any).item || (event as any).data.item;
    }
  }),
  assignCurrentItem: assign({
    currentItem: (context, event) =>
      (event as any).item || (event as any).data.item
  }),
  assignPossibleItems: assign({
    possibleItems: (context, event) =>
      (event as any).items || (event as any).data.items
  }),
  // Barcodes / Reads
  assignLPN: assign({
    lpn: (context, event) => (event as any).data.lpn
  }),
  assignProductToItem: assign({
    item: (context, event) => ({
      ...(context as any).item,
      product: (event as any).data.product
    })
  }),
  assignScannedValue: assign({
    inputValue: (ctx, e: any) => e.data.value
  }),
  assignList: assign({
    list: (context, event) => (event as any).data.rows
  }),
  assignSku: assign({
    sku: (_context, event) => (event as any).data.sku
  }),
  assignQuantity: assign({
    quantity: (_context, event) =>
      typeof (event as any).data.quantity === 'string'
        ? parseInt((event as any).data.quantity)
        : (event as any).data.quantity
  }),
  assignWeight: assign({
    weight: (_context, event) =>
      typeof (event as any).data.weight === 'string'
        ? parseInt((event as any).data.weight)
        : (event as any).data.weight
  }),
  assignIdentifier: assign({
    identifier: (_context, event) => (event as any).data.identifier
  })
};
