import { ProductItem } from '@wms/domain/src/models/Task';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import {
  MoveItemMachine,
  moveItemInitialContext
} from '../../capa-4/move-item/MoveItemMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface MoveItemsTaskContext {
  currentItem: ProductItem | null;
  itemsToMove: ProductItem[];
  movedItems: ProductItem[];
  targetLocation: Location | null;
  originLocation: Location | null;
  hint: string | null;
  error: string | null;
}

export const moveItemsTaskInitialContext = {
  currentItem:       null,
  itemsToMove:       [],
  movedItems:        [],
  slottingAlgorithm: null,
  targetLocation:    null,
  hint:              null,
  error:             ''
};

const HELP = `Traslado de productos
Escanee un producto y su nueva ubicación para trasladarlo.`;

export const MoveItemsTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<MoveItemsTaskContext>(
    {
      id:      'MoveItemsTaskMachine',
      initial: 'MovingItems',
      states:  {
        Initialize: {
          entry: 'MovingItems'
        },
        MovingItems: {
          invoke: {
            src:    MoveItemMachine,
            id:     MoveItemMachine.id,
            data:   moveItemInitialContext,
            onDone: [
              {
                actions: 'updateContext',
                cond:    'itemsRemaining',
                target:  'MovingItems'
              },
              {
                actions: 'updateContext',
                target:  'ConfirmingTask'
              }
            ]
          }
        },
        ConfirmingTask: {
          on: {
            finishTask: {
              actions: 'completeTask',
              target:  'ItemsMoved'
            },
            continue: 'MovingItems'
          }
        },
        ItemsMoved: {
          type: 'final',
          data: ctx => ctx
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        itemsRemaining: ctx => ctx.itemsToMove.length > 0
      },
      actions: {
        updateContext: assign({
          // TODO: Review removal from itemsToMove.
          itemsToMove: (context, event) => [
            ...context.itemsToMove.filter(
              item => item.sku !== event.data.item.sku
            )
          ],
          movedItems: (context, event) => [
            ...context.movedItems,
            event.data.item
          ],
          currentItem: (ctx, evt) => null
        }),
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API
      }
    }
  );
