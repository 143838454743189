import { ConsolidatingContainersToStagingProcessStatuses } from '../ConsolidatingContainersToStagingProcess';
import { ConsolidatingContainersToStagingProcessStatus } from './ConsolidatingContainersToStagingProcessStatus';

export class ConsolidatingContainersToStagingProcessProcessingStatus extends ConsolidatingContainersToStagingProcessStatus {
  public displayName = 'Procesando';

  public internalName =
    ConsolidatingContainersToStagingProcessStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
