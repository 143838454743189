import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { ProductRestockingLevel } from './ProductRestockingLevel';

export enum AlertTypes {
  LowStockLevel = 'low-stock-level'
}

@Entity()
export class ProductRestockingLevelAlertType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => ProductRestockingLevel, 'productRestockingLevelAlertTypeId')
  productRestockingLevels: ProductRestockingLevel[];
}
