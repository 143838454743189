import { AuditingWaveStatuses } from '../AuditingWave';
import { AuditingWaveStatus } from './AuditingWaveStatus';

export class AuditingWavePausedStatus extends AuditingWaveStatus {
  public displayName = 'Pausado';

  public internalName = AuditingWaveStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
