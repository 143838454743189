import { ClassificationProcessStatuses } from '../ClassificationProcess';
import { ClassificationProcessStatus } from './ClassificationProcessStatus';

export class ClassificationProcessCancelledStatus extends ClassificationProcessStatus {
  public displayName = 'Cancelado';

  public internalName = ClassificationProcessStatuses.Cancelled;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
