import { Entity, Field, Knows, PrimaryKey } from '@skyframe/core';
import { Package } from './Package';

@Entity()
export class PackType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  description: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Package, 'packTypeId')
  packages: Package[];
}
