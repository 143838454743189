import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusVerifiedStatus extends ReceiptItemStatus {
  public displayName = 'Verificado';

  public internalName = ReceiptItemStatuses.Verified;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
