import {
  ConsolidatingContainersToStagingContainer,
  Container,
  Location,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultScanControlDigitContext,
  ScanControlDigitMachine
} from '../../capa-4/scan-control-digit/scan-control-digit.machine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { consolidatingContainersToStagingActions } from './actions';
import { consolidatingContainersToStagingServices } from './services';

export interface ConsolidatingContainersToStagingContext {
  task: Task;
  consolidatingToStagingContainerId: number;
  slotPosition: number;

  consolidatingContainersToStagingContainer: ConsolidatingContainersToStagingContainer | null;
  containerToScan: Container | null;
  destinyLocation: Location | null;

  error: string | null;
}

export const DefaultConsolidatingContainersToStagingContext: ConsolidatingContainersToStagingContext =
  {
    task:                                      {} as Task,
    consolidatingToStagingContainerId:         -1,
    slotPosition:                              -1,
    consolidatingContainersToStagingContainer: null,
    containerToScan:                           null,
    destinyLocation:                           null,
    error:                                     null
  };

export const ConsolidatingContainersToStagingMachine = createMachine(
  {
    id:                         'consolidatingContainerstoStaging',
    predictableActionArguments: true,
    schema:                     {
      context: {} as ConsolidatingContainersToStagingContext
    },
    initial: 'FetchingConsolidatingContainersToStaging',
    states:  {
      FetchingConsolidatingContainersToStaging: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchConsolidatingContainersToStaging',
          onDone: {
            target:  'ScanningContainer',
            actions: ['assignConsolidatingContainer']
          },
          onError: {
            target:  'ScanningContainer',
            actions: ['assignError']
          }
        }
      },
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanear contenedor',
              getRequestedContainerByLpn(
                'Ocurrion un error, por favor intente de nuevo',
                'El contenedor escaneado es invalido',
                ctx.containerToScan!.lpn
              )
            ),
          onDone: {
            target:  'FetchingDestinyLocation',
            actions: ['clearError']
          }
        }
      },
      FetchingDestinyLocation: {
        invoke: {
          src:    'fetchDestinyLocation',
          onDone: {
            target:  'ScanningDestinyLocation',
            actions: ['assignDestinyLocation']
          },
          onError: {
            target:  'ScanningContainer',
            actions: ['assignError']
          }
        }
      },
      ScanningDestinyLocation: {
        invoke: {
          id:   ScanControlDigitMachine.id,
          src:  ScanControlDigitMachine,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            hint:             'Escanear digito de control',
            requiredLocation: ctx.destinyLocation!
          }),
          onDone: {
            target:  'ConsolidationContainerConsolidated',
            actions: ['clearError']
          }
        }
      },
      ConsolidationContainerConsolidated: {
        invoke: {
          src:     'ConsolidationContainerConsolidated',
          onDone:  'Done',
          onError: {
            target:  'ScanningDestinyLocation',
            actions: ['assignError']
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    actions:  consolidatingContainersToStagingActions,
    services: consolidatingContainersToStagingServices
  }
);
