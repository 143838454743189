import { InventoryCountStatus } from './InventoryCountStatus';

export class InventoryCountStatusPendingStatus extends InventoryCountStatus {
  public displayName = 'Pendiente';

  public internalName = 'pending';

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
