import { PutAwayItemQualityStatuses } from '../PutAwayItem';
import { PutAwayItemQualityStatus } from './PutAwayItemQualityStatus';

export class PutAwayItemQualityStatusNormalStatus extends PutAwayItemQualityStatus {
  public displayName = 'Normal';

  public internalName = PutAwayItemQualityStatuses.Normal;

  public statusColor = '#d7d7d7';
}
