import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { SorterWMSLocation } from './SorterWMSLocation';

@Entity()
export class SorterLocation {
  @PrimaryKey()
  id: number;

  @Field({ name: 'location_code' })
  locationCode: string;

  @Field({ name: 'location_id' })
  locationId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => SorterWMSLocation, 'sorterLocationId')
  sorterWMSLocations: SorterWMSLocation[];
}
