import { Task } from '@wms/domain';
import { assign, createMachine } from 'xstate';

export interface TaskSearcherContext {
  task: Task | null;
  menuItemKey: string | null;
  list: [];
}

type TaskSelectedEvent = { type: 'taskSelected'; data: { task: Task } };
export type TaskSearcherEvent = TaskSelectedEvent;

export const TaskSearcherMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QBUCGsDWBlMqBOAxgBZh4CyqxAlgHZgB0AZmAC7U1RqawDEEA9nXq0AbvwwMu2XIRLlKRWg2ZtFHKbASj+BVCyqCA2gAYAuidOJQAB36wq+wVZAAPRACYAzAE563--7GAKzuAIze7gAswQA0IACeiAAcnvTuAOyekQBs3rlBxpHu2UEAvqVxUjj4xKQU7AywYAA2YAT66ugYPCxdOK3tkBbOtvaONM5uCEHpxvShUd5JkenpoZ7G2e5xiQhJofSrWUnGeZHeQUn75RUgNPwQcM5VMrXyDSN2DgYTSK6IAFpsjtAdlypU+q85PU1MpWOxOF14H9Rt8nH8pkUQXsDucAul3DN3El0t50uCQC8atCFEp6E0Bh1EZhPmMfpNEJFQkE-MtPNkNmTPLN+djQsF6MYpcZPFcVkkgpEgp4KVTZHVaUJepgABLoABCYDANH6bRYkFZaN+oCmBKSaRKQW85yuBOdYplhwKxiiIQJ6xKN1KQA */
  id:      'TaskSearcherMachine',
  initial: 'selectingTask',
  schema:  {
    context: {} as TaskSearcherContext,
    events:  {} as TaskSearcherEvent
  },
  context: {
    task:        null,
    menuItemKey: null,
    list:        []
  },
  states: {
    fetchingTasks: {
      invoke: {
        src:    'fetchTasks',
        onDone: {
          actions: 'assignList',
          target:  'selectingTask'
        }
      }
    },
    selectingTask: {
      on: {
        taskSelected: {
          actions: assign({
            task: (ctx, ev: TaskSelectedEvent) => ev.data.task
          }),
          target: 'taskHasBeenSelected'
        }
      }
    },
    taskHasBeenSelected: {
      type: 'final',
      data: ctx => ctx.task
    }
  }
});
