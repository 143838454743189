import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  OwnedBy,
  PrimaryKey
} from '@skyframe/core';
import { CustomerOrder } from './CustomerOrder';
import { PackagingLevel } from './PackagingLevel';
import { Product } from './Product';

@Entity()
export class CustomerOrderItem {
  @PrimaryKey()
  id: number;

  @Field({ name: 'requested_quantity' })
  requestedQuantity: number;

  @Field()
  sku: string;

  @ForeignKey(() => CustomerOrder, { name: 'customer_order_id' })
  customerOrderId: number;

  @OwnedBy(() => CustomerOrder, 'customerOrderId')
  customerOrder: CustomerOrder;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => PackagingLevel, { name: 'packaging_level_id' })
  packagingLevelId: number;

  // @TODO: Change to Needs
  @Knows(() => PackagingLevel, 'packagingLevelId')
  packagingLevel: PackagingLevel;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Needs(() => Product, 'productId')
  product: Product;
}
