import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { AnyActorRef, AnyInterpreter } from 'xstate';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { RootMachineProvider } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';

export interface AuditingTaskAssignerProps {
  actorRef: AnyActorRef;
}

export const AuditingTaskAssigner: FC<AuditingTaskAssignerProps> = ({
  actorRef
}) => {
  const [current, send] = useActor(actorRef);

  return (
    <RootMachineProvider
      state={current}
      send={send as any}
      interpreter={actorRef as AnyInterpreter}
    >
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>TAREA DE AUDITORÍA</Ink.Text>
        </Ink.Header>
        {/* Header */}
        <Ink.Box>
          {current.matches('ScanningLocation') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>Escanear ubicación de auditoría</Ink.Text>
              <Ink.Text> </Ink.Text>
            </Ink.Box>
          )}
          {current.matches('ScanningContainer') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>Escanear contenedor que desea trasladar</Ink.Text>
              <Ink.Text> </Ink.Text>
            </Ink.Box>
          )}
        </Ink.Box>
        {/* Body */}
        <Ink.Box flexDirection='column'>
          {current.matches('ScanningLocation') ? (
            <>
              <Ink.Box flexDirection='column'>
                <HighlightedInfo
                  attribute='Brazo de sorter 1'
                  value='M2-S-02'
                />{' '}
                <HighlightedInfo
                  attribute='Brazo de sorter 2'
                  value='M2-S-03'
                />
                <Ink.Text />
                <GetLocationByName />
              </Ink.Box>
            </>
          ) : null}
          {current.matches('ScanningContainer') && (
            <Ink.Box flexDirection='column'>
              <GetContainerByLpn />
              <Ink.Button
                label='Volver a empezar'
                onClick={() => send({ type: 'RESET' })}
                id='reset'
                key='reset'
              />
            </Ink.Box>
          )}
          {current.context.errorMessage ? (
            <Ink.Text color='red'> {current.context.errorMessage} </Ink.Text>
          ) : null}
        </Ink.Box>
      </Ink.Box>
    </RootMachineProvider>
  );
};
