import { PickingProcessStatus } from './PickingProcessStatus';

export class PickingProcessStatusProcessingStatus extends PickingProcessStatus {
  public displayName = 'Procesando';

  public internalName = 'processing';

  /* public statusColor = '#64b5f6';

  public textColor = '#ffa300';*/

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
