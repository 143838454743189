import { InventoryItemStatuses } from '../InventoryItem';
import { InventoryItemStatus } from './InventoryItemStatus';

export class InventoryItemStatusDetainedStatus extends InventoryItemStatus {
  public displayName = 'Retenido';

  public internalName = InventoryItemStatuses.Detained;

  public statusColor = '#e3c5d1';

  public textColor = '#64053f';
}
