import { Location } from '@wms/domain';
import { createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';

export interface LocationDetailsContext {
  location: Location | null;
}

export const LocationDetailsMachine = createMachine<LocationDetailsContext>(
  {
    id:      'LocationDetails',
    initial: 'FetchingDetail',
    states:  {
      FetchingDetail: {
        invoke: {
          src:    'findLocationByName',
          onDone: {
            actions: 'assignLocation',
            target:  'ShowingDetails'
          }
        }
      },
      ShowingDetails: {}
    }
  },
  {
    actions: {
      ...UtilityActions
    },
    services: {
      ...API
    }
  }
);
