import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { MiniMoveToSorterExitMachine } from './machine';

export const MiniMoveToSorterExitTask = withActorRef(
  MiniMoveToSorterExitMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  return (
    <Ink.Box paddingX={1}>
      <Ink.Header>
        <Ink.Text bold>Trasladando contenedor a salida del sorter</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column'>
        {current.matches('ScanningLocation') && (
          <>
            <Ink.Text>Trasládese a alguna salida del sorter</Ink.Text>
          </>
        )}

        {current.matches('ConfirmingMovement') && (
          <>
            <Ink.Text>
              El paquete {current.context.label} será trasladado a la ubicación{' '}
              {current.context.location?.name}
            </Ink.Text>
            <Ink.Text bold>
              Revise que la información sea correcta y confirme el traslado
            </Ink.Text>
            <Ink.Button
              onClick={() => {
                send('MOVEMENT_CONFIRMED');
              }}
              label='Confirmar'
            />
            <Ink.Button
              onClick={() => {
                send('CANCEL');
              }}
              label='Cancelar'
            />
            {current.context.error && (
              <Ink.Text bold color='redBright'>
                {current.context.error}
              </Ink.Text>
            )}
          </>
        )}

        {current.matches('ScanningPackage') && <ScanLabel />}
        {current.matches('ScanningLocation') && <ScanLocation />}
      </Ink.Box>
    </Ink.Box>
  );
});
