import { Location } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import { ProductItem } from '../../../shared/utils/ProductItem';
import {
  CreateItemMachine,
  DefaultCreateItemMachineContext
} from '../../capa-4/create-item/CreateItemMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface CreateItemsTaskContext {
  currentItem: ProductItem | null;
  itemsToCreate: ProductItem[];
  createdItems: ProductItem[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
}

export const createItemsTaskInitialContext = {
  currentItem:    null,
  itemsToCreate:  [],
  createdItems:   [],
  targetLocation: null,
  hint:           null,
  error:          ''
};

const HELP = `Creación de Ítem 
Escanee una etiqueta de producto para asignarle una ubicación y crearlo en el sistema.`;

export const CreateItemsTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<CreateItemsTaskContext>(
    {
      id:      'CreateItemsTaskMachine',
      initial: 'CreatingItems',
      states:  {
        Initialize: {
          entry: 'CreatingItems'
        },
        CreatingItems: {
          invoke: {
            src:    CreateItemMachine,
            id:     CreateItemMachine.id,
            data:   DefaultCreateItemMachineContext,
            onDone: [
              {
                actions: 'updateContext',
                cond:    'itemsRemaining',
                target:  'CreatingItems'
              },
              {
                actions: 'updateContext',
                target:  'ConfirmingTask'
              }
            ]
          }
        },
        ConfirmingTask: {
          on: {
            finishTask: {
              target: 'ItemsCreated'
            },
            continue: 'CreatingItems'
          }
        },
        ItemsCreated: {
          type: 'final',
          data: ctx => ctx
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        itemsRemaining: ctx => ctx.itemsToCreate.length > 0
      },
      actions: {
        updateContext: assign({
          itemsToCreate: (context, event) => [
            ...context.itemsToCreate.filter(
              item => item.sku !== event.data.item.sku
            )
          ],
          createdItems: (context, event) => [
            ...context.createdItems,
            event.data.item
          ],
          currentItem: _ctx => null
        }),
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API
      }
    }
  );
