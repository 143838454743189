import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusMissingStatus extends OrderItemStatus {
  public displayName = 'Faltante';

  public internalName = OrderItemStatuses.Missing;

  public statusColor = '#ffd3d3';

  public textColor = '#b90c0c';
}
