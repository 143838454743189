import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  PrimaryKey
} from '@skyframe/core';
import { Location } from './Location';
import { PackType } from './PackType';
import { PackingItem } from './PackingItem';

@Entity()
export class Package {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ForeignKey(() => PackType, { name: 'pack_type_id' })
  packTypeId: number;

  @Field()
  label: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Location, 'locationId')
  location: Location;

  @Knows(() => PackType, 'packTypeId')
  packType: PackType;

  @NeededBy(() => PackingItem, 'packageId')
  packingItems: PackingItem[];
}
