import { MachineOptions, assign } from 'xstate';
import { ConsolidatingContainersToStagingContext } from './machine';

type ConsolidatingContainersToStagingActionsType = MachineOptions<
  ConsolidatingContainersToStagingContext,
  any
>['actions'];

export const consolidatingContainersToStagingActions: ConsolidatingContainersToStagingActionsType =
  {
    assignConsolidatingContainer: assign({
      containerToScan: (_ctx, event) =>
        event.data.consolidatingContainersToStagingcontainer
          .classificationContainer.container,
      consolidatingContainersToStagingContainer: (_ctx, event) =>
        event.data.consolidatingContainersToStagingcontainer
    }),
    assignDestinyLocation: assign({
      destinyLocation: (_ctx, event) => event.data.destinyLocation
    }),
    assignError: assign({
      error: (_ctx, event) => event.data.errorView
    }),
    clearError: assign({
      error: (___, __) => null
    })
  };
