import { StringTaskType, TaskTypes } from '@wms/domain';
import { FC } from 'react';
import { AnyStateMachine, ContextFrom } from 'xstate';
import { AddItemsToContainerTask } from './capa-3/add-items-to-container/AddItemsToContainerTask';
import {
  AddItemsToContainerTaskMachine,
  addItemsToContainerTaskInitialContext
} from './capa-3/add-items-to-container/AddItemsToContainerTaskMachine';

import { AuditContainersTask } from './capa-3/audit-containers/AuditContainersTask';
import { AuditContainersTaskMachine } from './capa-3/audit-containers/AuditContainersTaskMachine';
import { BigPickingWaveTask } from './capa-3/big-picking-wave/component';
import {
  BigPickingWaveMachine,
  DefaultBigPickingWaveContext
} from './capa-3/big-picking-wave/machine';
import { ConsolidateContainersTask } from './capa-3/consolidate-container-contents/ConsolidateContainersTask';
import {
  ConsolidateContainersTaskMachine,
  DefaultConsolidateContainersTaskTaskMachine
} from './capa-3/consolidate-container-contents/ConsolidateContainersTaskMachine';
import { ConsolidatingContainersToStagingTask } from './capa-3/consolidating-containers-to-staging/component';
import {
  ConsolidatingContainersToStagingMachine,
  DefaultConsolidatingContainersToStagingContext
} from './capa-3/consolidating-containers-to-staging/machine';
import { CreateItemsTask } from './capa-3/crear-item/CreateItemsTask';
import {
  CreateItemsTaskMachine,
  createItemsTaskInitialContext
} from './capa-3/crear-item/CreateItemsTaskMachine';
import { CreateContainersTask } from './capa-3/create-container/CreateContainersTask';
import {
  CreateContainersTaskMachine,
  createContainersTaskInitialContext
} from './capa-3/create-container/CreateContainersTaskMachine';
import { DispatchContainers } from './capa-3/dispatch-containers/DispatchContainers';
import { DispatchContainersMachine } from './capa-3/dispatch-containers/dispatch-containers.machine';
import { DispatchItemTask } from './capa-3/dispatch-item/DispatchItemTask';
import {
  DefaultDispatchItemsTaskMachine,
  DispatchItemTaskMachine
} from './capa-3/dispatch-item/DispatchItemTaskMachine';
import { DownloadContainerTask } from './capa-3/download-container/DownloadContainerTask';
import {
  DefaultDownloadContainerTaskContext,
  DownloadContainerTaskMachine
} from './capa-3/download-container/DownloadContainerTaskMachine';
import { FractionateContainerTask } from './capa-3/frac-container/FractionateContainerTask';
import {
  DefaultFractionateContainerTaskContext,
  FractionateContainerTaskMachine
} from './capa-3/frac-container/FractionateContainerTaskMachine';
import {
  DefaultInboundDirectReceptionContext,
  InboundDirectReceptionMachine
} from './capa-3/inbound/inbound-direct-reception/InboundDirectReceptionMachine';
import { InboundDirectReceptionTask } from './capa-3/inbound/inbound-direct-reception/InboundDirectReceptionTask';
import { InventoryCountingTask } from './capa-3/inventory-counting/InventoryCountingTask';
import {
  InventoryCountingTaskInitialContext,
  InventoryCountingTaskMachine
} from './capa-3/inventory-counting/InventoryCountingTaskMachine';
import { ManagePrinterTask } from './capa-3/manage-printer/component';
import {
  DefaultManagePrinterContext,
  ManagePrinterMachine
} from './capa-3/manage-printer/machine';
import { MaxiConsolidateContainersTask } from './capa-3/maxi-consolidate-containers/component';
import {
  DefaultMaxiConsolidateContainersContext,
  MaxiConsolidateContainersMachine
} from './capa-3/maxi-consolidate-containers/machine';
import { MaxiPickingWaveTask } from './capa-3/maxi-picking-wave/component';
import {
  DefaultMaxiPickingWaveContext,
  MaxiPickingWaveMachine
} from './capa-3/maxi-picking-wave/machine';
import { MidiPickingWaveTask } from './capa-3/midi-picking-wave/component';
import {
  DefaultMidiPickingWaveContext,
  MidiPickingWaveMachine
} from './capa-3/midi-picking-wave/machine';
import { MiniMopAuditingWaveTask } from './capa-3/mini-mop-auditing-wave/component';
import {
  DefaultMiniMopAuditingWaveContext,
  MiniMopAuditingWaveMachine
} from './capa-3/mini-mop-auditing-wave/machine';
import { MiniMopMoveToSorterExitTask } from './capa-3/mini-mop-move-to-sorter-exit/component';
import {
  DefaultMiniMopMoveToSorterExitContext,
  MiniMopMoveToSorterExitMachine
} from './capa-3/mini-mop-move-to-sorter-exit/machine';
import { MiniMopPickingWaveTask } from './capa-3/mini-mop-picking-wave/component';
import {
  DefaultMiniMopPickingWaveContext,
  MiniMopPickingWaveMachine
} from './capa-3/mini-mop-picking-wave/machine';
import { MiniPackingWaveTask } from './capa-3/mini-packing-wave/component';
import { MiniPackingWaveMachine } from './capa-3/mini-packing-wave/machine';
import { MiniPickingWaveTask } from './capa-3/mini-picking-wave/component';
import {
  DefaultMiniPickingWaveContext,
  MiniPickingWaveMachine
} from './capa-3/mini-picking-wave/machine';
import { MoveContainerTask } from './capa-3/move-container/MoveContainerTask';
import {
  MoveContainerTaskMachine,
  moveContainerTaskInitialContext,
  moveContainersToGrid3TaskInitialContext
} from './capa-3/move-container/MoveContainerTaskMachine';
import { MoveContainerFromStagingToStorageTask } from './capa-3/move-container-from-staging-to-storage/MoveContainerFromStagingToStorageTask';
import {
  MoveContainerFromStagingToStorageMachine,
  moveContainerFromStagingToStorageTaskInitialContext
} from './capa-3/move-container-from-staging-to-storage/MoveContainerFromStagingToStorageTaskMachine';
import { MoveContainersTask } from './capa-3/move-containers/MoveContainersTask';
import { MoveContainersTaskMachine } from './capa-3/move-containers/MoveContainersTaskMachine';
import { MoveItemsTask } from './capa-3/move-items/MoveItemsTask';
import {
  MoveItemsTaskMachine,
  moveItemsTaskInitialContext
} from './capa-3/move-items/MoveItemsTaskMachine';
import { MiniMopMoveToAuditingLocationTask } from './capa-3/move-to-auditing-location/component';
import {
  DefaultMoveToAuditingLocationContext,
  MoveToAuditingLocationMachine
} from './capa-3/move-to-auditing-location/machine';
import { MiniMoveToSorterExitTask } from './capa-3/move-to-sorter-exit/component';
import {
  DeafultMiniMoveToSorterExitContext,
  MiniMoveToSorterExitMachine
} from './capa-3/move-to-sorter-exit/machine';
import { PickItemsTask } from './capa-3/pick';
import {
  DefaultPickItemsTaskMachineContext,
  PickItemsTaskMachine
} from './capa-3/pick/PickItemsTaskMachine';
import { PutAwayContainersTask } from './capa-3/put-away-containers/PutAwayContainersTask';
import {
  DefaultPutAwayContainersTaskContext,
  PutAwayContainersTaskMachine
} from './capa-3/put-away-containers/PutAwayContainersTaskMachine';
import { RemoveItemsFromContainerTask } from './capa-3/remove-items-from-container/RemoveItemsFromContainerTask';
import {
  RemoveItemsFromContainerTaskMachine,
  removeItemsFromContainerTaskInitialContext
} from './capa-3/remove-items-from-container/RemoveItemsFromContainerTaskMachine';
import { RestockingUtility } from './capa-3/restock-utility/RestockingUtility';
import {
  DefaultRestockingUtilityContext,
  RestockingUtilityMachine
} from './capa-3/restock-utility/RestockingUtilityMachine';
import { RestockingTask } from './capa-3/restocking-task/RestockingTask';
import {
  DefaultRestockingTaskContext,
  RestockingTaskMachine
} from './capa-3/restocking-task/RestockingTaskMachine';

import { TestPrinterTask } from './capa-3/test-printer/component';
import {
  DefaultTestPrinterContext,
  TestPrinterMachine
} from './capa-3/test-printer/machine';
import { MiniMoveToPackingLocationTask } from './capa-3/transfer-to-packing-location/component';
import {
  DefaultMoveToPackingLocationContext,
  MoveToPackingLocationMachine
} from './capa-3/transfer-to-packing-location/machine';
import { UploadContainerTask } from './capa-3/upload-container/UploadContainerTask';
import {
  DefaultUploadContainerTaskContext,
  UploadContainerTaskMachine
} from './capa-3/upload-container/UploadContainerTaskMachine';
import { VerifyItemsTask } from './capa-3/verify-items/VerifyItemsTask';
import {
  DefaultVerifyItemsTaskMachine,
  VerifyItemsTaskMachine
} from './capa-3/verify-items/VerifyItemsTaskMachine';
import { ViewContainerDetailsTask } from './capa-3/view-container-detail/ViewContainerDetails';
import {
  ViewContainerDetailsMachine,
  initialContainerDetailsContext
} from './capa-3/view-container-detail/ViewContainerDetailsMachine';
import { ViewItemDetailsTask } from './capa-3/view-item-details/ViewItemDetails';
import {
  ViewItemDetailsMachine,
  initialItemDetailsContext
} from './capa-3/view-item-details/ViewItemDetailsMachine';
import { ViewLocationDetailsTask } from './capa-3/view-location-detail/ViewLocationDetails';
import {
  ViewLocationDetailsMachine,
  initialLocationDetailsContext
} from './capa-3/view-location-detail/ViewLocationDetailsMachine';
import { ViewProductDetailsTask } from './capa-3/view-product-detail/ViewProductDetails';
import {
  ViewProductDetailsMachine,
  initialProductDetailContext
} from './capa-3/view-product-detail/ViewProductDetailsMachine';
import {
  WeightContainerTaskInitialContext,
  WeightContainerTaskMachine
} from './capa-3/weight-container/WeightContainerTaskMachine';
import { WeightContainerTask } from './capa-3/weight-container/WeightContainersTask';
import { BulkStorageItem } from './capa-4/bulk-storage/BulkStorageItem';
import {
  BulkStorageItemMachine,
  BulkStorageItemTaskInitialContext
} from './capa-4/bulk-storage/BulkStorageItemMachine';
import { ChangeItemStatus } from './capa-4/change-item-status/ChangeItemStatus';
import {
  ChangeItemStatusMachine,
  defaultChangeItemStatusMachineContext
} from './capa-4/change-item-status/ChangeItemStatusMachine';
import { CreateAnomaly } from './capa-4/create-anomaly/CreateAnomalyTask';
import {
  CreateAnomalyMachine,
  createAnomalyInitialContext
} from './capa-4/create-anomaly/CreateAnomalyTaskMachine';
import { MarkAsAnomaly } from './capa-4/mark-as-anomaly/MarkAsAnomaly';
import {
  MarkAsAnomalyMachine,
  markAsAnomalyInitialContext
} from './capa-4/mark-as-anomaly/MarkAsAnomalyMachine';
import { SelectRejectionReason } from './capa-4/select-rejection-reason/SelectRejectionReason';
import {
  DefaultSelectRejectionReasonContext,
  SelectRejectionReasonMachine
} from './capa-4/select-rejection-reason/SelectRejectionReasonMachine';
import { JobTypes } from './jobs';
import { ConsolidationClasificationComponent } from './jobs/consolidation-clasification/component';
import {
  ConsolidationClasificationMachine,
  DefaultConsolidationClasificationContext
} from './jobs/consolidation-clasification/machine';

export interface TaskItem {
  machine: AnyStateMachine;
  initialContext?: ContextFrom<this['machine']>;
  component: FC;
}

export const TaskIndex = {
  /** **** INBOUND *********/

  // Ingreso de Mercaderia
  [TaskTypes.ReceiptTaking]: {
    machine:        VerifyItemsTaskMachine,
    initialContext: DefaultVerifyItemsTaskMachine,
    component:      VerifyItemsTask
  },
  // Ingreso de Mercaderia
  [TaskTypes.VerifyAndPalletizeItemsV0]: {
    machine:        VerifyItemsTaskMachine,
    component:      VerifyItemsTask,
    initialContext: DefaultVerifyItemsTaskMachine
  },

  // Almacenaje
  [TaskTypes.PutAwayContainersV0]: {
    machine:        PutAwayContainersTaskMachine,
    initialContext: DefaultPutAwayContainersTaskContext,
    component:      PutAwayContainersTask
  },

  // Recepcion Directa
  [TaskTypes.InboundDirectReceptionV0]: {
    machine:        InboundDirectReceptionMachine,
    initialContext: DefaultInboundDirectReceptionContext,
    component:      InboundDirectReceptionTask
  },

  // Pre-Almacenaje
  [TaskTypes.PutAwayContainersToPrestorageV0]: {
    machine:        PutAwayContainersTaskMachine,
    initialContext: DefaultPutAwayContainersTaskContext,
    component:      PutAwayContainersTask
  },

  // Almacenaje desde Pre-Storage
  [TaskTypes.PutAwayContainersToStorageV0]: {
    machine:        PutAwayContainersTaskMachine,
    initialContext: DefaultPutAwayContainersTaskContext,
    component:      PutAwayContainersTask
  },

  [TaskTypes.InboundMoveContainerFromStagingToStorage]: {
    machine:        MoveContainerFromStagingToStorageMachine,
    initialContext: moveContainerFromStagingToStorageTaskInitialContext,
    component:      MoveContainerFromStagingToStorageTask
  },

  // Restockear ubicación de picking
  [TaskTypes.RestockInventoryV0]: {
    machine:        RestockingTaskMachine,
    initialContext: DefaultRestockingTaskContext,
    component:      RestockingTask
  },

  // Bajar contenedor a pasillo
  [TaskTypes.DownloadContainer]: {
    machine:        DownloadContainerTaskMachine,
    initialContext: DefaultDownloadContainerTaskContext,
    component:      DownloadContainerTask
  },

  // Subir contenedor desde el pasillo
  [TaskTypes.UploadContainer]: {
    machine:        UploadContainerTaskMachine,
    initialContext: DefaultUploadContainerTaskContext,
    component:      UploadContainerTask
  },

  [TaskTypes.MoveContainerToStorage]: {
    machine:        MoveContainerTaskMachine,
    initialContext: moveContainerTaskInitialContext,
    component:      MoveContainerTask
  },

  [TaskTypes.FracStorage]: {
    machine:        BulkStorageItemMachine,
    initialContext: BulkStorageItemTaskInitialContext,
    component:      BulkStorageItem
  },

  /** **** OUTBOUND *********/

  // Sorter

  [TaskTypes.MiniMoveToPackingLocationTask]: {
    machine:        MoveToPackingLocationMachine,
    initialContext: DefaultMoveToPackingLocationContext,
    component:      MiniMoveToPackingLocationTask
  },

  // Pick

  [TaskTypes.PickOrderV0]: {
    machine:        PickItemsTaskMachine,
    initialContext: DefaultPickItemsTaskMachineContext,
    component:      PickItemsTask
  },

  [TaskTypes.PickWaveV0]: {
    machine:        PickItemsTaskMachine,
    component:      PickItemsTask,
    initialContext: DefaultPickItemsTaskMachineContext
  },

  [TaskTypes.PickAndSortV0]: {
    machine:   PickItemsTaskMachine,
    component: PickItemsTask
  },

  [TaskTypes.MidiPickingWave]: {
    machine:        MidiPickingWaveMachine,
    component:      MidiPickingWaveTask,
    initialContext: DefaultMidiPickingWaveContext
  },
  [TaskTypes.MiniPickingWave]: {
    machine:        MiniPickingWaveMachine,
    component:      MiniPickingWaveTask,
    initialContext: DefaultMiniPickingWaveContext
  },
  [TaskTypes.MiniMopMoveToSorterExitTask]: {
    component:      MiniMopMoveToSorterExitTask,
    machine:        MiniMopMoveToSorterExitMachine,
    initialContext: DefaultMiniMopMoveToSorterExitContext
  },
  [TaskTypes.MiniMopAuditing]: {
    component:      MiniMopAuditingWaveTask,
    machine:        MiniMopAuditingWaveMachine,
    initialContext: DefaultMiniMopAuditingWaveContext
  },
  [TaskTypes.MaxiPickingWave]: {
    machine:        MaxiPickingWaveMachine,
    component:      MaxiPickingWaveTask,
    initialContext: DefaultMaxiPickingWaveContext
  },
  [TaskTypes.BigPickingWave]: {
    machine:        BigPickingWaveMachine,
    component:      BigPickingWaveTask,
    initialContext: DefaultBigPickingWaveContext
  },
  [TaskTypes.MiniMopPickingWave]: {
    machine:        MiniMopPickingWaveMachine,
    component:      MiniMopPickingWaveTask,
    initialContext: DefaultMiniMopPickingWaveContext
  },

  // Pack
  [TaskTypes.MiniPackingTask]: {
    machine:   MiniPackingWaveMachine,
    component: MiniPackingWaveTask
  },

  // Consolidate
  [TaskTypes.ConsolidateContainersV0]: {
    machine:   PickItemsTaskMachine,
    component: PickItemsTask
  },

  [TaskTypes.ConsolidatingContainersToStagingType]: {
    machine:        ConsolidatingContainersToStagingMachine,
    initialContext: DefaultConsolidatingContainersToStagingContext,
    component:      ConsolidatingContainersToStagingTask
  },

  [TaskTypes.DispatchContainersV0]: {
    machine:   DispatchContainersMachine,
    component: DispatchContainers
  },

  // Sorter
  [TaskTypes.MiniMoveToSorterExitTask]: {
    machine:        MiniMoveToSorterExitMachine,
    initialContext: DeafultMiniMoveToSorterExitContext,
    component:      MiniMoveToSorterExitTask
  },
  // Weight Containers
  [TaskTypes.WeightContainersV0]: {
    machine:        WeightContainerTaskMachine,
    initialContext: WeightContainerTaskInitialContext,
    component:      WeightContainerTask
  },
  // Consolidate Containers
  [TaskTypes.MaxiConsolidateContainersTask]: {
    machine:        MaxiConsolidateContainersMachine,
    initialContext: DefaultMaxiConsolidateContainersContext,
    component:      MaxiConsolidateContainersTask
  },
  // Stage
  [TaskTypes.StageContainersV0]: {
    machine:   PickItemsTaskMachine,
    component: PickItemsTask
  },

  // Audit
  [TaskTypes.AuditContainersV0]: {
    machine:   AuditContainersTaskMachine,
    component: AuditContainersTask
  },

  [TaskTypes.MiniMopMovingToAuditingLocationTask]: {
    machine:        MoveToAuditingLocationMachine,
    initialContext: DefaultMoveToAuditingLocationContext,
    component:      MiniMopMoveToAuditingLocationTask
  },

  // Ship
  [TaskTypes.ShipContainersV0]: {
    machine:   PickItemsTaskMachine,
    component: PickItemsTask
  },

  // Ship but cooler
  [TaskTypes.DispatchItem]: {
    machine:        DispatchItemTaskMachine,
    initialContext: DefaultDispatchItemsTaskMachine,
    component:      DispatchItemTask
  },

  // Move to grid 3
  [TaskTypes.MoveContainersToGrid3]: {
    machine:        MoveContainersTaskMachine,
    initialContext: moveContainersToGrid3TaskInitialContext,
    component:      MoveContainersTask
  },

  /** * Utilities de CAPA 3 */
  [TaskTypes.CreateContainersV0]: {
    machine:        CreateContainersTaskMachine,
    initialContext: createContainersTaskInitialContext,
    component:      CreateContainersTask
  },

  [TaskTypes.CreateContainer]: {
    machine:        CreateContainersTaskMachine,
    initialContext: CreateContainersTaskMachine,
    component:      CreateContainersTask
  },

  [TaskTypes.CreateItem]: {
    machine:        CreateItemsTaskMachine,
    initialContext: createItemsTaskInitialContext,
    component:      CreateItemsTask
  },

  [TaskTypes.RemoveItemsFromContainer]: {
    machine:        RemoveItemsFromContainerTaskMachine,
    initialContext: removeItemsFromContainerTaskInitialContext,
    component:      RemoveItemsFromContainerTask
  },

  [TaskTypes.MarkItemAsAnomaly]: {
    machine:        CreateAnomalyMachine,
    initialContext: createAnomalyInitialContext,
    component:      CreateAnomaly
  },

  [TaskTypes.SelectRejectionReason]: {
    machine:        SelectRejectionReasonMachine,
    initialContext: DefaultSelectRejectionReasonContext,
    component:      SelectRejectionReason
  },

  [TaskTypes.InventoryCounting]: {
    machine:        InventoryCountingTaskMachine,
    initialContext: InventoryCountingTaskInitialContext,
    component:      InventoryCountingTask
  },

  /** * Utilities de CAPA 4 ****/
  // Gestion de Inventario

  [TaskTypes.viewContainerDetail]: {
    machine:        ViewContainerDetailsMachine,
    initialContext: initialContainerDetailsContext,
    component:      ViewContainerDetailsTask
  },

  [TaskTypes.viewLocationDetail]: {
    machine:        ViewLocationDetailsMachine,
    initialContext: initialLocationDetailsContext,
    component:      ViewLocationDetailsTask
  },

  [TaskTypes.ManagePrinter]: {
    machine:        ManagePrinterMachine,
    initialContext: DefaultManagePrinterContext,
    component:      ManagePrinterTask
  },

  [TaskTypes.TestPrinter]: {
    machine:        TestPrinterMachine,
    initialContext: DefaultTestPrinterContext,
    component:      TestPrinterTask
  },

  [TaskTypes.ChangeItemStatus]: {
    machine:        ChangeItemStatusMachine,
    initialContext: defaultChangeItemStatusMachineContext,
    component:      ChangeItemStatus
  },

  [TaskTypes.MoveItemsV0]: {
    machine:        MoveItemsTaskMachine,
    initialContext: moveItemsTaskInitialContext,
    component:      MoveItemsTask
  },

  [TaskTypes.ConsolidateContainersV0]: {
    machine:        ConsolidateContainersTaskMachine,
    initialContext: DefaultConsolidateContainersTaskTaskMachine,
    component:      ConsolidateContainersTask
  },

  // Por ahora
  [TaskTypes.AddItemsToContainer]: {
    machine:        AddItemsToContainerTaskMachine,
    component:      AddItemsToContainerTask,
    initialContext: addItemsToContainerTaskInitialContext
  },

  [TaskTypes.MarkAsAnomalyTask]: {
    machine:        MarkAsAnomalyMachine,
    component:      MarkAsAnomaly,
    initialContext: markAsAnomalyInitialContext
  },

  [TaskTypes.FractionateContainer]: {
    machine:        FractionateContainerTaskMachine,
    component:      FractionateContainerTask,
    initialContext: DefaultFractionateContainerTaskContext
  },

  [TaskTypes.ItemDetails]: {
    machine:        ViewItemDetailsMachine,
    component:      ViewItemDetailsTask,
    initialContext: initialItemDetailsContext
  },

  [TaskTypes.ViewProductDetail]: {
    machine:        ViewProductDetailsMachine,
    component:      ViewProductDetailsTask,
    initialContext: initialProductDetailContext
  },

  [TaskTypes.InventoryCounting]: {
    machine:        InventoryCountingTaskMachine,
    component:      InventoryCountingTask,
    initialContext: InventoryCountingTaskInitialContext
  },

  [TaskTypes.RestockingUtility]: {
    machine:        RestockingUtilityMachine,
    component:      RestockingUtility,
    initialContext: DefaultRestockingUtilityContext
  },
  [JobTypes.Outbound.Consolidation.Classification]: {
    machine:        ConsolidationClasificationMachine,
    component:      ConsolidationClasificationComponent,
    initialContext: DefaultConsolidationClasificationContext
  }
} as Record<StringTaskType, TaskItem>;

export const TaskProcessType = {
  'process-receipt': {
    entity:           'Orden de Entrada',
    label:            'ingreso',
    validBucketTypes: [TaskTypes.VerifyAndPalletizeItemsV0]
  },
  'direct-reception': {
    entity:           'Línea Directa',
    label:            'linea-directa',
    validBucketTypes: [TaskTypes.InboundDirectReceptionV0]
  },
  'put-away': {
    entity:           'Orden de Entrada',
    label:            'almacenaje',
    validBucketTypes: [
      TaskTypes.PutAwayContainersV0,
      TaskTypes.PutAwayContainersToPrestorageV0,
      TaskTypes.PutAwayContainersToStorageV0
    ]
  },
  'move-container-to-prestorage': {
    entity:           'Mover contenedor',
    label:            'almacenaje',
    validBucketTypes: [
      TaskTypes.MoveContainerToStorage,
      TaskTypes.InboundMoveContainerFromStagingToStorage
    ]
  },
  'pick-order': {
    entity:           'Orden de Salida',
    label:            'picking',
    validBucketTypes: [TaskTypes.PickOrderV0]
  },
  'pick-wave': {
    entity:           'Ola de Picking',
    label:            'picking',
    validBucketTypes: [
      TaskTypes.PickWaveV0,
      TaskTypes.MidiPickingWave,
      TaskTypes.MiniPickingWave,
      TaskTypes.MaxiPickingWave,
      TaskTypes.BigPickingWave,
      TaskTypes.MiniMopPickingWave
    ]
  },
  'consolidate-containers': {
    entity:           'Traslado',
    label:            'Traslado',
    validBucketTypes: [TaskTypes.MaxiConsolidateContainersTask]
  },
  'pack-wave': {
    entity:           'Ola de Packing',
    label:            'packing',
    validBucketTypes: [TaskTypes.MiniPackingTask]
  },
  'transfer-to-packing-location': {
    entity:           'Preparación',
    label:            'transfer',
    validBucketTypes: [TaskTypes.MiniMoveToPackingLocationTask]
  },
  'transfer-to-auditing-location': {
    entity:           'Tarea de traslado a auditoría',
    label:            'transfer',
    validBucketTypes: [TaskTypes.MiniMopMovingToAuditingLocationTask]
  },
  'mini-move-to-sorter-exit': {
    entity:           'Traslado Manual a Salida de Sorter',
    label:            'sorter',
    validBucketTypes: [
      TaskTypes.MiniMoveToSorterExitTask,
      TaskTypes.MiniMopMoveToSorterExitTask
    ]
  },
  'move-containers-of-sorter-track-to-staging': {
    entity:           'Tarea de traslado de salida de rampa a zona de staging',
    label:            'Traslado',
    validBucketTypes: [TaskTypes.ConsolidatingContainersToStagingType]
  },
  'audit-order': {
    entity:           'Orden de Salida',
    label:            'auditoría',
    validBucketTypes: [TaskTypes.AuditContainersV0]
  },
  'consolidate-order': {
    entity:           'Orden de Salida',
    label:            'consolidación',
    validBucketTypes: [TaskTypes.ConsolidateContainersV0]
  },
  'move-containers-to-grid-3': {
    entity:           'Orden de Salida',
    label:            'mover a grid 3',
    validBucketTypes: [TaskTypes.MoveContainersToGrid3]
  },
  'dispatch-order': {
    entity:           'Orden de Despacho',
    label:            'despacho',
    validBucketTypes: [TaskTypes.DispatchItem, TaskTypes.DispatchContainersV0]
  },
  'weight-container': {
    entity:           'Pesar Contenedor',
    label:            'almacenaje',
    validBucketTypes: [TaskTypes.WeightContainersV0]
  },
  'download-container': {
    entity:           'Bajar contenedor a Pasillo',
    label:            'bajar contenedor a pasillo',
    validBucketTypes: [TaskTypes.DownloadContainer]
  },
  'upload-container': {
    entity:           'Subir contenedor',
    label:            'subir contenedor a pasillo',
    validBucketTypes: [TaskTypes.UploadContainer]
  },
  'restock-inventory': {
    entity:           'Restockear inventario',
    label:            'Restockear inventario',
    validBucketTypes: [TaskTypes.RestockInventoryV0]
  },
  'readjust-products': {
    entity:           'Productos y Orden de Salida',
    label:            'Completar productos',
    validBucketTypes: [TaskTypes.RestockInventoryV0]
  },
  'frac-container': {
    entity:           'Fraccionar Contenedor',
    label:            'Fraccionar Contenedor',
    validBucketTypes: [TaskTypes.FractionateContainer]
  },
  'inventory-counting': {
    entity:           'Conteo de inventario',
    label:            'Conteo de Inventario',
    validBucketTypes: [TaskTypes.InventoryCounting]
  }
} as const;
