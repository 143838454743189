import { Entity, ForeignKey, Knows, NeededBy, Needs } from '@skyframe/core';
import { Appointment } from './Appointment';
import { BaseLocation } from './BaseLocation';
import { CategoryLocation } from './CategoryLocation';
import { ClassificationContainer } from './ClassificationContainer';
import { ConsolidateContainersWave } from './ConsolidateContainersWave';
import { Container } from './Container';
import { InventoryItem } from './InventoryItem';
import { LabelingDesk } from './LabelingDesk';
import { LocationAssignment } from './LocationAssignment';
import { LocationLocationAttribute } from './LocationLocationAttribute';
import { LocationProductAttribute } from './LocationProductAttribute';
import { LocationTaskRole } from './LocationTaskRole';
import { LocationType } from './LocationType';
import { LocationZone } from './LocationZone';
import { Order } from './Order';
import { Package } from './Package';
import { PackingWave } from './PackingWave';
import { PickingProcess } from './PickingProcess';
import { PickingWave } from './PickingWave';
import { PickingZoneLocation } from './PickingZoneLocation';
import { PreStorageLocationMapping } from './PreStorageLocationMapping';
import { ProductRestockingLevel } from './ProductRestockingLevel';
import { ProductRestockingLevelAlert } from './ProductRestockingLevelAlert';
import { Receipt } from './Receipt';
import { Route } from './Route';
import { SorterWMSLocation } from './SorterWMSLocation';
import { StagingAuditingWave } from './StagingAuditingWave';
import { Warehouse } from './Warehouse';
import { ZoneClassifier } from './ZoneClassifier';

export const LocationTypeZoneName = {
  ABA: 'ABA',
  ALM: 'ALM',
  AV:  'AV',
  GRA: 'GRA',
  STK: 'STK',
  STG: 'STG',
  PA:  'PA',
  RE:  'RE',
  LY:  'LY',
  INS: 'INS',
  STI: 'STI'
};

export const LocationMatchStorageWithPicking = {
  ABA: 'ALM',
  ALM: 'ABA',
  AV:  'STK',
  STK: 'AV',
  GRA: 'STG',
  STG: 'GRA',
  INS: 'STI',
  STI: 'INS'
};

@Entity()
export class Location extends BaseLocation {
  checkDigit: never;

  status: never;

  @Knows(() => Location, 'parentId')
  parent: Location;

  @Knows(() => Location, 'parentId')
  children: Location[];

  @Knows(() => Container, 'locationId')
  containers: Container[];

  @Knows(() => ZoneClassifier, 'zoneClassifierId')
  zoneClassifier: ZoneClassifier;

  @Needs(() => LocationType, 'locationTypeId')
  locationType: LocationType;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @Knows(() => InventoryItem, 'locationId')
  inventoryItems: InventoryItem[];

  /* Dock location only */
  @Knows(() => Appointment, 'stagingId')
  appointment: Appointment[];

  /* Section only */
  @Knows(() => Warehouse, 'crossDockId')
  crossDockWarehouse: Warehouse;

  /* Section only */
  @Knows(() => Warehouse, 'anomalySectionId')
  anomalySectionWarehouse: Warehouse;

  /* Section only */
  @Knows(() => CategoryLocation, 'sectionId')
  rubric: CategoryLocation;

  /* Aisle only */
  @Knows(() => CategoryLocation, 'aisleId')
  families: CategoryLocation[];

  @Knows(() => Route, 'routeId')
  route: Route;

  @NeededBy(() => LabelingDesk, 'locationId')
  labelingDesk: LabelingDesk;

  @NeededBy(() => ProductRestockingLevel, 'locationId')
  productRestockingLevels: ProductRestockingLevel[];

  @NeededBy(() => ProductRestockingLevelAlert, 'locationId')
  productRestockingLevelAlerts: ProductRestockingLevelAlert[];

  @NeededBy(() => LocationProductAttribute, 'locationId')
  locationProductAttributes: LocationProductAttribute[];

  @NeededBy(() => LocationLocationAttribute, 'locationId')
  LocationLocationAttributes: LocationLocationAttribute[];

  @NeededBy(() => SorterWMSLocation, 'locationId')
  sorterWMSLocation: SorterWMSLocation;

  @NeededBy(() => ClassificationContainer, 'sorterExitLocationId')
  classificationContainer: ClassificationContainer;

  @NeededBy(() => PackingWave, 'locationId')
  packingWaves: PackingWave[];

  @NeededBy(() => ConsolidateContainersWave, 'originLocationId')
  originConsolidateContainersWave: ConsolidateContainersWave[];

  @NeededBy(() => ConsolidateContainersWave, 'targetLocationId')
  targetConsolidateContainersWave: ConsolidateContainersWave[];

  @ForeignKey(() => LocationZone, { name: 'location_zone_id' })
  locationZoneId: number;

  @Knows(() => LocationZone, 'locationZoneId')
  locationZone: LocationZone;

  @NeededBy(() => LocationAssignment, 'locationId')
  locationAssignments: LocationAssignment[];

  @NeededBy(() => LocationTaskRole, 'locationId')
  locationTaskRoles: LocationTaskRole[];

  @Knows(() => Receipt, 'unloadingLocationId')
  unloadingLocationReceipts: Receipt[];

  @Knows(() => Order, 'dispatchLocationId')
  dispatchLocationOrders: Order[];

  @Knows(() => PickingProcess, 'consolidationLocationId')
  pickingProcessesToConsolidate: PickingProcess[];

  @Knows(() => PickingWave, 'consolidationLocationId')
  pickingWavesToConsolidate: PickingWave[];

  @Knows(() => PickingZoneLocation, 'locationId')
  pickingZones: PickingZoneLocation[];

  @Knows(() => PreStorageLocationMapping, 'preStorageLocationId')
  storageLocationMappings: PreStorageLocationMapping[];

  @Knows(() => PreStorageLocationMapping, 'storageLocationId')
  preStorageLocationMappings: PreStorageLocationMapping[];

  @Knows(() => Package, 'locationId')
  packages: Package[];

  @NeededBy(() => StagingAuditingWave, 'stagingId')
  stagingAuditingWaves: StagingAuditingWave[];
}
