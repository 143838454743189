import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { Reservation } from './Reservation';

export enum ReservationTypes {
  InternalSupply = 'internal-supply',
  StorePickup = 'store-pickup',
  HomeDelivery = 'home-delivery',
  CrossDock = 'cross-dock'
}

export type TypeReservation =
  (typeof ReservationTypes)[keyof typeof ReservationTypes];

@Entity()
export class ReservationType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => Reservation, 'reservationTypeId')
  reservations: Reservation[];
}
