import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { LocationZone } from './LocationZone';
import { ProductType } from './ProductType';

@Entity()
export class ProductTypeLocationZone {
  @PrimaryKey()
  id: number;

  @Field()
  priority: number;

  @ForeignKey(() => LocationZone, { name: 'location_zone_id' })
  locationZoneId: number;

  @ForeignKey(() => ProductType, { name: 'product_type_id' })
  productTypeId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => LocationZone, 'locationZoneId')
  locationZone: LocationZone;

  @Needs(() => ProductType, 'productTypeId')
  productType: ProductType;
}
