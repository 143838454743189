import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { PlaceItemInContainer } from '../place-item-in-container/PlaceItemInContainer';
import { RemoveItemFromContainer } from '../remove-item-from-container/RemoveItemFromContainer';
import { TransferItemMachine } from './TransferItemMachine';

export const TransferItem: FC = withActorRef(TransferItemMachine)(
  ({ actorRef }) => {
    const [current] = useActor(actorRef);

    return (
      <>
        <Ink.Header>Traspasando Productos</Ink.Header>
        {current.matches('RemovingItemFromContainer') ? (
          <RemoveItemFromContainer />
        ) : null}

        {current.matches('PlacingItemInContainer') ? (
          <PlaceItemInContainer />
        ) : null}

        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}
      </>
    );
  }
);
