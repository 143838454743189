import { MachineOptions, assign } from 'xstate';
import { ConsolidationClasificationContext } from './machine';

type ConsolidationClassificationActionsType = MachineOptions<
  ConsolidationClasificationContext,
  any
>['actions'];

export const consolidationClasificationActions: ConsolidationClassificationActionsType =
  {
    assignLabel: assign({
      label: (_ctx, event) => event.data.label
    }),
    assignClassificationItem: assign({
      classificationItem:         (_ctx, event) => event.data.classificationItem,
      classificationItemLocation: (_ctx, event) => event.data.location,
      classificationItemCostumer: (_ctx, event) => event.data.customer
    }),
    assignClassificationContainer: assign({
      classificationContainer: (_ctx, event) =>
        event.data.classificationContainer
    }),
    clearCtx: assign({
      label:                      null,
      classificationItem:         null,
      classificationContainer:    null,
      classificationItemLocation: null,
      classificationItemCostumer: null,
      newContainerLabel:          null,
      suggestedSlotPosition:      null,
      selectedContainerTypeName:  null,
      selectedContainerType:      null,
      error:                      null
    }),
    assignError: assign({
      error: (_ctx, event) => event.data.response.data.errorView
    }),
    assignSlotPosition: assign({
      suggestedSlotPosition: (_ctx, event) => event.data.slotPosition
    }),
    assignSelectedContainerTypeName: assign({
      selectedContainerTypeName: (_ctx, event) => event.data.containerTypeName
    }),
    assignSelectedContainerType: assign({
      selectedContainerType: (_ctx, event) => event.data.containerType
    }),
    assignNewContainerLabel: assign({
      newContainerLabel: (_ctx, event) => event.data.label
    }),
    clearError: assign({
      error: null
    })
  };
