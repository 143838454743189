import { ChalkProps, ForegroundColor, pallet } from '@wms/client-core';
import React, { CSSProperties, FC, useMemo } from 'react';

type ColorStyleAppliers = {
  [K in ForegroundColor]: (value: boolean) => CSSProperties;
};

type StyleAppliers = {
  [K in Exclude<keyof ChalkProps, 'children'>]: (
    value: NonNullable<ChalkProps[K]>
  ) => CSSProperties;
} & ColorStyleAppliers;

const colorStyleAppliers = Object.entries(pallet).reduce(
  (res, [k, v]) => ({ ...res, [k]: value => (value ? { color: v } : {}) }),
  {}
) as ColorStyleAppliers;

const styleAppliers: StyleAppliers = {
  ...colorStyleAppliers,
  bold:  value => (value ? { fontWeight: 'bold' } : {}),
  color: value => (value ? { color: pallet[value] } : {})
};

export const Chalk: FC<ChalkProps> = props => {
  const style = useMemo<CSSProperties>(
    () =>
      Object.entries(props).reduce<CSSProperties>((res, [key, value]) => {
        const applier = styleAppliers[key];
        return applier ? { ...res, ...applier(value) } : res;
      }, {}),
    [props]
  );

  return <span style={style}>{props.children}</span>;
};
