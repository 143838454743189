import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ConsolidateContainersItem } from './ConsolidateContainersItem';
import { ConsolidateContainersWave } from './ConsolidateContainersWave';
import { OrderBatch } from './OrderBatch';
import {
  ConsolidateContainersProcessStatus,
  ConsolidateContainersProcessStatusCancelled,
  ConsolidateContainersProcessStatusCompleted,
  ConsolidateContainersProcessStatusPending,
  ConsolidateContainersProcessStatusProcessing
} from './consolidate-containers-process-status';

export const ConsolidateContainersProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class ConsolidateContainersProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [ConsolidateContainersProcessStatuses.Pending]:
      ConsolidateContainersProcessStatusPending,
    [ConsolidateContainersProcessStatuses.Processing]:
      ConsolidateContainersProcessStatusProcessing,
    [ConsolidateContainersProcessStatuses.Cancelled]:
      ConsolidateContainersProcessStatusCancelled,
    [ConsolidateContainersProcessStatuses.Completed]:
      ConsolidateContainersProcessStatusCompleted
  })
  status: ConsolidateContainersProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => ConsolidateContainersItem, 'consolidateContainersProcessId')
  consolidateContainersItems: ConsolidateContainersItem[];

  @NeededBy(() => ConsolidateContainersWave, 'consolidateContainersProcessId')
  consolidateContainersWave: ConsolidateContainersWave[];
}
