import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Appointment } from './Appointment';
import { Container } from './Container';
import { Process } from './Process';
import { Receipt } from './Receipt';
import { TransferOrder } from './TransferOrder';
import {
  StockTransferDispatchedStatus,
  StockTransferPendingStatus,
  StockTransferPreparingStatus,
  StockTransferRecivedStatus,
  StockTransferStatus
} from './stock-transfer-status';

export const StockTransferStatuses = {
  Pending:    'pending',
  Preparing:  'preparing',
  Dispatched: 'dispatched',
  Recived:    'recived'
};

@Entity()
export class StockTransfer {
  @PrimaryKey()
  id: number;

  @Field({ name: 'sap_doc_entry' })
  sapDocEntry: string;

  @Field({ name: 'sap_base_entry' })
  sapBaseEntry: string;

  @ForeignKey(() => TransferOrder, { name: 'transfer_order_id' })
  transferOrderId: number;

  @Needs(() => TransferOrder, 'transferOrderId')
  transferOrder: TransferOrder;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Container, 'stockTransferId')
  containers: Container[];

  @ForeignKey(() => Appointment, { name: 'appointment_id' })
  appointmentId: number;

  @Needs(() => Appointment, 'appointmentId')
  appointment: Appointment;

  @Knows(() => Receipt, 'stockTransferId')
  receipt: Receipt;

  @ForeignKey(() => Process, { name: 'process_instance_id' })
  processInstanceId: number;

  @Knows(() => Process, 'processInstanceId')
  processInstance: Process;

  @Status({
    [StockTransferStatuses.Pending]:    StockTransferPendingStatus,
    [StockTransferStatuses.Preparing]:  StockTransferPreparingStatus,
    [StockTransferStatuses.Dispatched]: StockTransferDispatchedStatus,
    [StockTransferStatuses.Recived]:    StockTransferRecivedStatus
  })
  status: StockTransferStatus;
}
