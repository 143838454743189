import { PackingProcessStatuses } from '../PackingProcess';
import { PackingProcessStatus } from './PackingProcessStatus';

export class PackingProcessStatusCancelledStatus extends PackingProcessStatus {
  public displayName = 'Cancelado';

  public internalName = PackingProcessStatuses.Cancelled;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
