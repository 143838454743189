import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ConsolidateContainersItem } from './ConsolidateContainersItem';
import { PickingContainerGroupContainer } from './PickingContainerGroupContainer';
import { PickingWave } from './PickingWave';
import {
  PickingContainerGroupStatus,
  PickingContainerGroupStatusClosed,
  PickingContainerGroupStatusOpen
} from './picking-container-group-status';

export const PickingContainerGroupStatuses = {
  Open:   'open',
  Closed: 'closed'
};

@Entity()
export class PickingContainerGroup {
  @PrimaryKey()
  id: number;

  @Status({
    [PickingContainerGroupStatuses.Open]:   PickingContainerGroupStatusOpen,
    [PickingContainerGroupStatuses.Closed]: PickingContainerGroupStatusClosed
  })
  status: PickingContainerGroupStatus;

  @ForeignKey(() => PickingWave, { name: 'picking_wave_id' })
  pickingWaveId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => PickingWave, 'pickingWaveId')
  pickingWave: PickingWave;

  @Knows(() => ConsolidateContainersItem, 'pickingContainerGroupId')
  consolidateContainersItems: ConsolidateContainersItem[];

  @NeededBy(() => PickingContainerGroupContainer, 'pickingContainerGroupId')
  pickingContainerGroupContainers: PickingContainerGroupContainer[];
}
