import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { LocationTaskRole } from './LocationTaskRole';
import { TaskRoleTaskType } from './TaskRoleTaskType';
import { User } from './User';
import { UserTaskRole } from './UserTaskRole';
import { Warehouse } from './Warehouse';

@Entity()
export class TaskRole {
  @PrimaryKey()
  id: number;

  @Field({ name: 'name' })
  name: string;

  @ForeignKey(() => User, { name: 'author_id' })
  authorId: number;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  // JSONB field defined in the backend.
  roles: any;

  @Field({ name: 'identifier_name' })
  identifierName: string;

  @Field({ name: 'icon_url' })
  iconUrl: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => User, 'authorId')
  author: User;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @NeededBy(() => LocationTaskRole, 'taskRoleId')
  locationsTaskRole: LocationTaskRole[];

  @NeededBy(() => UserTaskRole, 'taskRoleId')
  usersTaskRole: UserTaskRole[];

  @NeededBy(() => TaskRoleTaskType, 'taskRoleId')
  taskRoleTaskTypes: TaskRoleTaskType[];
}
