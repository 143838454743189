import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { withActorRef } from '../../../../shared/Machine';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from './machine';

export const GetContainerByLpn = withActorRef(
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const updateContainerLpn = useCallback(
    (containerLpn: string) =>
      send({ type: 'UpdateContainerLpn', containerLpn }),
    [send]
  );

  const submitContainerLpn = useCallback(
    () => send({ type: 'SubmitContainerLpn' }),
    [send]
  );

  return (
    <Ink.Box flexDirection='column' paddingY={1}>
      <Ink.Text bold>{current.context.hint}:</Ink.Text>

      {current.context.containerSuggestionLpn ? (
        <Ink.Text>
          {current.context.containerSuggestionHint}
          <Ink.Chalk color='greenBright' bold>
            {current.context.containerSuggestionLpn}
          </Ink.Chalk>
        </Ink.Text>
      ) : null}

      {current.hasTag('loading') ? (
        <Ink.Text>Cargando</Ink.Text>
      ) : (
        <>
          <Ink.TextInput
            value={current.context.containerLpn}
            onChange={updateContainerLpn}
            onSubmit={submitContainerLpn}
          />

          {current.context.error ? (
            <Ink.Text bold color='redBright'>
              {current.context.error}
            </Ink.Text>
          ) : null}
        </>
      )}
    </Ink.Box>
  );
});
