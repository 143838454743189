import { StockTableStatuses } from '../StockTable';
import { StockItemStatus } from './StockItemStatus';

export class StockItemStatusTechnicalServiceStatus extends StockItemStatus {
  public displayName = 'En Servicio Tecnico';

  public internalName = StockTableStatuses.TechnicalService;

  public statusColor = '#d7d7d7';
}
