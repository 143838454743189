import { ConsolidatingContainersToStagingContainerStatuses } from '../ConsolidatingContainersToStagingContainer';
import { ConsolidatingContainersToStagingContainerStatus } from './ConsolidatingContainersToStagingContainerStatus';

export class ConsolidatingContainersToStagingContainerStatusPending extends ConsolidatingContainersToStagingContainerStatus {
  public displayName = 'Pendiente';

  public internalName =
    ConsolidatingContainersToStagingContainerStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
