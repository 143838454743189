import { PutAwayItemStatus } from './PutAwayItemStatus';

export class PutAwayItemStatusMissingStatus extends PutAwayItemStatus {
  public displayName = 'Faltando';

  public internalName = 'missing';

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
