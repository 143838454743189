import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { MiniMopAuditingWaveMachine } from './machine';

export const MiniMopAuditingWaveTask = withActorRef(MiniMopAuditingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          Ola de Auditoría Nº{current.context.auditingWaveId} - Mini-MOP
        </Ink.Header>
        {/* Header */}
        <Ink.Box flexDirection='column'>
          {/* CreatingInteriorContainer */}
          <>
            {current.matches('CreatingInteriorContainer') && (
              <Ink.Text bold>Creando contenedor para el Interior</Ink.Text>
            )}

            {current.matches(
              'CreatingInteriorContainer.ScanningInteriorLPN'
            ) && (
              <Ink.Text>Escanear etiqueta del contenedor a despachar</Ink.Text>
            )}

            {current.matches('CreatingInteriorContainer.StickingLabel') && (
              <Ink.Text>Pega la etiqueta en el contenedor de despacho</Ink.Text>
            )}
          </>

          {/* AuditingItem */}
          <>
            {current.matches('AuditingItem') && (
              <>
                <Ink.Text bold>Auditando items</Ink.Text>
                {current.context.auditingItems?.map(auditingItem => (
                  <Ink.Text
                    key={auditingItem.id}
                    bold
                    color={
                      auditingItem.auditedQuantity >=
                      auditingItem.expectedQuantity
                        ? 'greenBright'
                        : 'redBright'
                    }
                  >
                    {auditingItem.product.sku}-{auditingItem.product.name} |{' '}
                    {auditingItem.auditedQuantity}/
                    {auditingItem.expectedQuantity}
                  </Ink.Text>
                ))}
              </>
            )}
            {current.matches('AuditingItem.ScanningSKU') && (
              <Ink.Button
                label='Completar auditoría'
                onClick={() => {
                  send('close');
                }}
                id='complete-auditing'
                key='complete-auditing'
              />
            )}

            {current.matches('AuditingItem.ScanningContainer') && (
              <Ink.Text>Escanear etiqueta del contenedor del destino</Ink.Text>
            )}
          </>

          {current.matches('ConfirmingContainerClose') && (
            <>
              <Ink.Text bold>Cierre de contenedor</Ink.Text>
              <Ink.Text>Haz terminado la auditoría!</Ink.Text>
            </>
          )}
        </Ink.Box>

        {/* Body */}
        <Ink.Box flexDirection='column'>
          {current.hasTag('loading') && <Ink.Text>Cargando... </Ink.Text>}

          {current.hasTag('lpn') && <EnterLpn />}
          {current.hasTag('container') && <GetContainerByLpn />}
          {current.hasTag('sku') && <GetProductBySKU />}

          {current.matches('CreatingInteriorContainer.StickingLabel') && (
            <>
              <Ink.Box flexDirection='row' justifyContent='space-between'>
                <Ink.Button
                  onClick={() => {
                    send('confirm');
                  }}
                  label='Etiqueta pegada'
                  id='confirm-stick-label'
                  key='confirm-stick-label'
                />
              </Ink.Box>
            </>
          )}

          {current.matches('ConfirmingContainerClose') && (
            <>
              <Ink.Box flexDirection='row' justifyContent='space-between'>
                <Ink.Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    send('cancel');
                  }}
                  label='Revalidar cierre'
                />
              </Ink.Box>
            </>
          )}
        </Ink.Box>
      </Ink.Box>
    );
  }
);
