import { TaskStatuses } from '../Task';
import { TaskStatus } from './TaskStatus';

export class TaskStatusCompletedStatus extends TaskStatus {
  public displayName = 'Completada';

  public internalName = TaskStatuses.Completed;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
