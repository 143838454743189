import { Entity, Field, Needs, PrimaryKey } from '@skyframe/core';
import { Location } from './Location';
import { LocationZone } from './LocationZone';
import { User } from './User';

@Entity()
export class LocationAssignment {
  @PrimaryKey()
  id: number;

  @Field({ name: 'user_id' })
  userId: number;

  @Needs(() => User, 'userId')
  user: User;

  @Field({ name: 'location_zone_id' })
  locationZoneId: number;

  @Needs(() => LocationZone, 'locationZoneId')
  locationZone: LocationZone;

  @Field({ name: 'location_id' })
  locationId: number;

  @Needs(() => Location, 'locationId')
  location: Location;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}
