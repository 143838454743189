import { StockTransferStatus } from './StockTransferStatus';

export class StockTransferDispatchedStatus extends StockTransferStatus {
  public displayName = 'Despachado';

  public internalName = 'dispatched';

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}
