import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditingItem } from './AuditingItem';
import { AuditingProcess } from './AuditingProcess';
import { Container } from './Container';
import { MopPickingContainer } from './MopPickingContainer';
import { MopSorterExitItem } from './MopSorterExitItem';
import {
  AuditingWaveCancelledStatus,
  AuditingWaveCompletedStatus,
  AuditingWavePausedStatus,
  AuditingWavePendingStatus,
  AuditingWaveProcessingStatus,
  AuditingWaveStatus
} from './auditing-wave-status';

export const AuditingWaveStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Paused:     'paused',
  Cancelled:  'cancelled'
};

@Entity()
export class AuditingWave {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => AuditingProcess, { name: 'auditing_process_id' })
  auditingProcessId: number;

  @ForeignKey(() => MopPickingContainer, { name: 'mop_picking_container_id' })
  mopPickingContainerId: number;

  @ForeignKey(() => Container, { name: 'target_container_id' })
  targetContainerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Status({
    [AuditingWaveStatuses.Pending]:    AuditingWavePendingStatus,
    [AuditingWaveStatuses.Processing]: AuditingWaveProcessingStatus,
    [AuditingWaveStatuses.Completed]:  AuditingWaveCompletedStatus,
    [AuditingWaveStatuses.Cancelled]:  AuditingWaveCancelledStatus,
    [AuditingWaveStatuses.Paused]:     AuditingWavePausedStatus
  })
  status: AuditingWaveStatus;

  @Needs(() => AuditingProcess, 'auditingProcessId')
  auditingProcess: AuditingProcess;

  @Needs(() => MopPickingContainer, 'mopPickingContainerId')
  mopPickingContainer: MopPickingContainer;

  @Knows(() => Container, 'targetContainerId')
  targetContainer: Container;

  @NeededBy(() => AuditingItem, 'auditingWaveId')
  auditingItems: AuditingItem[];

  @NeededBy(() => MopSorterExitItem, 'auditingWaveId')
  mopSorterExitItems: MopSorterExitItem[];
}
