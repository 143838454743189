import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { InventoryCounting } from './InventoryCounting';
import { InventoryItem } from './InventoryItem';

@Entity()
export class InventoryCountingItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => InventoryCounting, { name: 'inventory_counting_id' })
  inventoryCountingId: number;

  @Field({ name: 'counted_quantity' })
  countedQuantity: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => InventoryCounting, 'inventoryCountingId')
  inventoryCounting: InventoryCounting;

  @Needs(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;
}
