import { ConsolidateContainersWaveStatuses } from '../ConsolidateContainersWave';
import { ConsolidateContainersWaveStatus } from './ConsolidateContainersWaveStatus';

export class ConsolidateContainersWaveStatusCompleted extends ConsolidateContainersWaveStatus {
  public displayName = 'Completado';

  public internalName = ConsolidateContainersWaveStatuses.Completed;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
