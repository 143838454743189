import { MovingToAuditingLocationProcessStatuses } from '../MovingToAuditingLocationProcess';
import { MovingToAuditingLocationProcessStatus } from './MovingToAuditingLocationProcessStatus';

export class MovingToAuditingLocationProcessCompletedStatus extends MovingToAuditingLocationProcessStatus {
  public displayName = 'Completado';

  public internalName = MovingToAuditingLocationProcessStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
