import { Container, Location, ProductItem, ProductItemName } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { GoToHelpEvent } from '../../core/GenericOptions';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../scan-item/ScanItemMachine';

export interface ChangeItemStatusContext {
  item: ProductItem | null;

  location?: Location | null;
  container?: Container | null;

  status: string;
  type: string;

  hint: string;
  error: string;
}

export const defaultChangeItemStatusMachineContext = {
  item:      null,
  location:  null,
  container: null,
  status:    '',
  type:      ProductItemName.InventoryItem,
  hint:      '',
  error:     ''
};

const HELP = `Cambiar estado de un item
Escanee un item para cambiar su estado.`;

export const ChangeItemStatusMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGEBOYCGAXMBJHAtgLIYDGAFgJYB2YAdDZVpRgDaUBeYAxIqAA4B7WE0qDqfEAA9EARgDMAJgAMdAJwBWRWsXyALADZZyzQBoQAT0QaA7AbrLlBm-OM3ZNxQA55AX1-maJg4+GDEZFS0dLCkGNQAygDSAKrc-KiCEACupFjxsdS0EJJCIszikjIIsrJq9rIqNspeBppqanrmVtU2XnT6itp6al42erJG-oHo2HiEJBQ09ABmYFiL1FAAChnZuQBiGQTI2GyCUNwQ4vQ0AG6CANb0QbOh4RsraxvbuzlYh4JjqdWOcEHdBLFytQANrKAC6JWEogqSGkiG0fTUyhsNg04wMXnGemJXXRIzoBg0BgMJhqTg0zT8ARALxC8wiSzoq3WkR+mT+AKBWDOFzAqAyqDo-FY2GWglQBDorLmYQWkU+PJofL2-yOJ2FIKgYOo90hYhh8MRZXNlUQ8kcdEUDOU8hpejsJicpIQBm0dGdLpqSmUil9UxZMzZqo5UXS-IOgiy1AgvFRpWRElRVTG8kdlPzChUoe98lLFOpBNqGjaHgM4eVbzVnJicQAigAVACa3AAjlk4swsBYAKLUHDoYpppFQ20IVy55zePE+TQaPHerx9eSbiZ6FR6Bm2PT1yMq97quikSNat6Xa4ME2PZ6nxsx+hX4I3wjG03Yc2whEp2tFFQCqBp5A0BxDE3VwdAMbcNG9LEHEcZQFGMLxBlkPQvBPYIzybKIPz-TZbzFCUpRlLA5QVJUX3ZD5L2vUjv3BM1xAAq0M1nYwQ0dPFmgMHCvGUA9EMsRBxj6CZnHpeRcScNQbDw14GIvUdxwAGUELBuBBLANLFSAuJnLNEBzPMqSpQsQwMb1Q3qCscVEvRHFGfxmWoTI4EkBs1M5RhmDYTgwBMm0zOqZQqX9eScQUVzZEJGxvQ0LxIMUdwfDQ0T9FkDQVKjc9mwKJJkjCkC0WqVLIL3WoTFcrEvAUezNDoVzHHtbCvCxDoCoIt8uS+XkdnjXVAX1EVyszUDEAAWmw1Q1xdCDbFE1ocRShQHC0VK3JdExcOZPzo0YuMdX2RNkym2dUr6aCcV9Xc9HkEturoGTqTqJp4PGPrX0YltqA7TtroiiZZHexKFMpPE6S8b0Jkg11DCMEYHMGOsjvok6L2I5gWLCUGZoQEYIfkDo3RGeSD2SiSEE8CkBnGbQjAa48sfw-71LHMVtKwInKtxewhKatCaUSxR3Q3Po0oPDoWjSglXD+-yojeZVJwEadwuJvK0raxQ6o6T0mpeunjFzAMGRsDoIMwplpk51XQqA7iIvmoT-QZUs1yaQwlNp7pVB+3RCW8dwxk0WQPN8IA */
  createMachine<ChangeItemStatusContext>(
    {
      context: defaultChangeItemStatusMachineContext,
      id:      'ChangeItemStatusMachine',
      initial: 'Initialize',
      states:  {
        Initialize: {
          always: [
            {
              cond:   'itemIsSet',
              target: 'SetStatus'
            },
            {
              target: 'ScanItem'
            }
          ]
        },
        ScanItem: {
          invoke: {
            src:  ScanItemMachine,
            id:   ScanItemMachine.id,
            data: ctx => ({
              ...DefaultScanItemContext,
              location:  ctx.location,
              container: ctx.container
            }),
            onDone: [
              {
                actions: 'assignItem',
                cond:    context => !context.status,
                target:  'SetStatus'
              },
              {
                actions: 'assignItem',
                target:  'ChangeItemStatus'
              }
            ]
          }
        },
        SetStatus: {
          on: {
            statusEntered: {
              actions: 'assignStatus',
              target:  'ChangeItemStatus'
            }
          }
        },
        ChangeItemStatus: {
          invoke: {
            src:    'changeItemStatus',
            onDone: {
              target: 'ItemStatusChanged'
            },
            onError: {
              actions: 'assignError',
              target:  'ScanItem'
            }
          }
        },
        ItemStatusChanged: {
          type: 'final'
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions,
        assignStatus: assign({
          status: (_context, event) => event.data.status
        }),
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: { ...API }
    }
  );
