import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanControlDigitMachine } from './scan-control-digit.machine';

export const ScanControlDigit: FC = withActorRef(ScanControlDigitMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const onSubmitCheckDigit = checkDigit => {
      send({
        type: 'ControlDigitScanned',
        data: { checkDigit }
      });
    };
    const changeControlDigit = checkDigit => {
      send({
        type: 'change',
        data: { checkDigit: checkDigit }
      });
    };

    return (
      <>
        {current.matches('AwaitingControlDigitScan') ? (
          <>
            <Ink.Text>
              {current.context.hint
                ? current.context.hint
                : 'Escanee Dígito de Control:'}
            </Ink.Text>
            <Ink.TextInput
              onChange={changeControlDigit}
              value={current.context.location.checkDigit}
              onSubmit={onSubmitCheckDigit}
            />
          </>
        ) : null}

        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}
      </>
    );
  }
);

ScanControlDigit.displayName = 'ScanControlDigit';
