import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { CreateAnomaly } from '../create-anomaly/CreateAnomalyTask';
import { CreateContainer } from '../create-container/CreateContainer';
import { MoveContainer } from '../move-container/MoveContainer';
import { TransferItem } from '../transfer-item/TransferItem';
import { MarkAsAnomalyMachine } from './MarkAsAnomalyMachine';

export const MarkAsAnomaly: FC = withActorRef(MarkAsAnomalyMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.hint ? (
                'MARCANDO INCIDENCIA'
              ) : (
                <>
                  MARCANDO INCIDENCIA:{' '}
                  <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>

        {current.matches('CreateContainer') ? <CreateContainer /> : null}

        {current.matches('SelectAnomalyContainer') ? (
          <>
            <Ink.Text>Escoger contenedor de de incidencia</Ink.Text>

            {current.context.anomalyContainers.map(container => (
              <Ink.Button
                key={container.id.toString()}
                id={container.id.toString()}
                label={container.lpn}
                onClick={() => send({ type: 'select', data: { container } })}
              />
            ))}

            <Ink.Button
              key='create'
              id='create'
              label='Crear nuevo contenedor de incidencia'
              onClick={() => send('create')}
            />
          </>
        ) : null}

        {current.matches('CreateAnomaly') ? (
          <>
            <CreateAnomaly />
          </>
        ) : null}

        {current.matches('ShowTransferItemDetails') ? (
          <>
            <Ink.Text>Mueva el item: </Ink.Text>
            <Ink.Text>
              {' '}
              - SKU:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.sku as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              {' '}
              - Cantidad:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.quantity as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              {' '}
              - Producto:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.product.name as any}
              </Ink.Chalk>
            </Ink.Text>

            {current.context.anomalyInventoryItem?.lot?.lotNumber ? (
              <Ink.Text>
                {' '}
                - Lote:{' '}
                <Ink.Chalk greenBright>
                  {current.context.anomalyInventoryItem.lot.lotNumber}
                </Ink.Chalk>
              </Ink.Text>
            ) : null}

            {current.context.anomalyInventoryItem?.lot?.expirationDate ? (
              <Ink.Text>
                {' '}
                - Fecha de Vencimiento:{' '}
                <Ink.Chalk greenBright>
                  {new Date(
                    current.context.anomalyInventoryItem?.lot?.expirationDate
                  ).toLocaleDateString()}
                </Ink.Chalk>
              </Ink.Text>
            ) : null}

            <Ink.Text>
              Desde el contenedor{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.container.lpn as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              al contenedor{' '}
              <Ink.Chalk greenBright>
                {current.context.selectedContainer?.lpn as any}
              </Ink.Chalk>
            </Ink.Text>

            <Ink.Button
              key='move'
              id='move'
              label='Mover Item'
              onClick={() => send('confirm')}
            />
          </>
        ) : null}

        {current.matches('MoveToAnomalyContainer') ? (
          <>
            <TransferItem />
          </>
        ) : null}

        {current.matches('MoveContainerAnomalyContainer') ? (
          <>
            {current.context.selectedContainer ? (
              <Ink.Text>
                Mueva el contenedor{' '}
                <Ink.Chalk greenBright>
                  {current.context.selectedContainer.lpn}
                </Ink.Chalk>{' '}
                a la ubicación de merma.
              </Ink.Text>
            ) : (
              <Ink.Text>Mueva el contenedor a la ubicación de merma.</Ink.Text>
            )}
            <MoveContainer />

            <Ink.Button
              key='skip'
              id='skip'
              label='Saltar tarea de mover contenedor'
              onClick={() => send('skip')}
            />
          </>
        ) : null}

        {current.matches('AnomalyCreated') ? (
          <>
            <Ink.Text>proceso completado</Ink.Text>
          </>
        ) : null}
      </Ink.Box>
    );
  }
);
