import { TransportOrderStatuses } from '../TransportOrder';
import { TransportOrderStatus } from './TransportOrderStatus';

export class TransportOrderStatusCompletedWithErrorStatus extends TransportOrderStatus {
  public displayName = 'Completada con error';

  public internalName = TransportOrderStatuses.CompletedWithError;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
