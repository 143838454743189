import { InventoryItemPhysicalStatuses } from '../InventoryItem';
import { InventoryItemPhysicalStatus } from './InventoryItemPhysicalStatus';

export class InventoryItemPhysicalStatusAvailableStatus extends InventoryItemPhysicalStatus {
  public displayName = 'Disponible';

  public internalName = InventoryItemPhysicalStatuses.Available;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
