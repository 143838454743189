import {
  Container,
  InventoryItem,
  Location,
  ProductItem,
  SlottingAlgorithm
} from '@wms/domain';
import { createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import {
  PlaceItemInContainerMachine,
  placeItemInContainerInitialContext
} from '../place-item-in-container/PlaceItemInContainerMachine';
import {
  RemoveItemFromContainerMachine,
  removeItemFromContainerInitialContext
} from '../remove-item-from-container/RemoveItemFromContainerMachine';

export interface TransferItemContext {
  fromLocation: Location | null;

  // Any of these 4
  slottingAlgorithm: SlottingAlgorithm | null;
  suggestedContainer: Container | null;
  requiredLocation: Location | null;
  location: Location | null;

  // Any of these 3.
  requestedItem: InventoryItem | null;
  suggestedItem: InventoryItem | null;
  validItems: Container[];

  item: ProductItem | null;
  validator: null;

  hint: string | null;
  error: string | null;
  quantity: number;
}

export const DefaultTransferItemInitialContext: TransferItemContext = {
  fromLocation: null,

  // Any of these 4
  slottingAlgorithm:  null,
  suggestedContainer: null,
  requiredLocation:   null,
  location:           null,

  // Any of these 3.
  requestedItem: null,
  suggestedItem: null,
  validItems:    [],
  item:          null,

  hint: 'Traslade productos',

  validator: null,
  error:     '',
  quantity:  0
};

export const TransferItemMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFkD2A3MBJALmAtgHQCWAdsTsQIYA2xAXmAMSKgAOqsFxqprIAD0QAmYQDZCADgCcABjEAWBWLHCAzGtEBWADQgAnojWTJUjZq3TLVyWIC+dvWky4ChAIIB3Kt1JRX+ADKAMZUpEwQvGAkpOioANbRzth4RF4+lH4BIWEIZHGhlLwA2rIAuvwcXEV8SIIi4lJyisqqGtp6hghqsgCMZu2W1lq2Cg5OGClu6b7+qTnhYABOS6hLhGw0VDgAZmtEyQEe3rPZoaR5saiFPKSlFXVV3Lz8QggjhGoKagDsar3SH5iAFaBQ-TqIAGmcyacSSYRyTT2RwgQ6pQgAJTgqBo6DIUAA6mt4jsaKhPCxHpxnrVQG8xLJTL1ZNIwdJbFofj9epIId0ftJCNJhcKfuJpL1hLIxii0W4AGJgHDBAAW+MCAFcoDBYHgIAAZa7bW4RKIxOKJQhyoiK5Vqvya7VwPWGm68S4FY0lcqVak1V6QqX9cSckMmbliXQGIxafqyePx4S2H4KdRqLTjVGTI621XqrU6l1GmpMZardabbZ7JYHbPo3P2qCOwuQV1ei75Yu3e6+6q3AMISV9QghsWR8O9SN876mBOJ5OpjSZ63HDL4ts1Bam0jRTuWlczTJQDe3BYerveh7sP39upvXr-H5SIbyRmiXpgvm9LmEOeyJNiCm6gyhMLjooe64XqQW5lmsGxbLs+xWnW0wnEeJ68GenZuncPpUn2Lx3pCWjGCO4iRhKfwPmoYh8v8piSDCPL-F8VjLihRAAGq0MQEDGn4267lc+4cYQ3F0HxR7njhPb4TSA5DsGkZjiMkgRlGXRyL+c4AUBXxqOxYFuOJvH8VApYrHBlaITWyFGVxPGSfi0ntrJ14EbS9SDkGI7KWGamThpIiKNpCa6Yu6YOCipCoBAcD8CuZDcDxjC9vJRGDpOhDKGpCh9GIJgaNI05KJ85hKGo0hFRmsqiRBWTzOcaX+hln7RggCKyGV7Rwp1SKGVMRBYrAOJ4n4RJLCSZKeM1t50ogqb9JV8iSLIPwsqyD7TumhBaAmvScv+WhaAVA05kqeYOgWzqtlBs2EfNCDhrtB0qROU7tXlgoisKkgKOy+W9Gd4FoZBOELPdnn3ntXWSC+SayLGQzTsyAwaMxD7-TVoGDWJjlmZDA7shILLGMCChQvCtHtYF2U-VYiIFf+wNuAEyQQITGUHfGz5WP+q1I1YX6yGou0Jnlx3pgyB0s-gnOPeoX6zn+Ksi1FdhAA */
  createMachine<TransferItemContext>(
    {
      id:      'TransferItem',
      initial: 'initialize',
      states:  {
        initialize: {
          always: [
            {
              target: 'RemovingItemFromContainer'
            }
          ]
        },
        RemovingItemFromContainer: {
          invoke: {
            src:  RemoveItemFromContainerMachine,
            id:   RemoveItemFromContainerMachine.id,
            data: ctx => ({
              ...removeItemFromContainerInitialContext,
              hint:      'Quitando item de contenedor.',
              container: ctx.requestedItem?.container,
              item:      ctx.requestedItem
            }),
            onDone: [
              {
                actions: 'assignItem',
                cond:    'slottingAlgorithmIsSet',
                target:  'FetchingSuggestedContainer'
              },
              {
                actions: 'assignItem',
                target:  'PlacingItemInContainer'
              }
            ],
            onError: [
              {
                actions:  'assignError',
                target:   'RemovingItemFromContainer',
                internal: false
              }
            ]
          }
        },
        FetchingSuggestedContainer: {
          invoke: {
            src:    'fetchSuggestedContainer',
            onDone: {
              actions: 'assignContainer',
              target:  'PlacingItemInContainer'
            },
            onError: [
              {
                actions: 'assignError',
                target:  'RemovingItemFromContainer'
              }
            ]
          }
        },
        PlacingItemInContainer: {
          invoke: {
            src:  PlaceItemInContainerMachine,
            id:   PlaceItemInContainerMachine.id,
            data: ctx => ({
              ...placeItemInContainerInitialContext,
              hint:               'Escanee contenedor de destino.',
              requestedContainer: ctx.suggestedContainer,
              item:               ctx.requestedItem
            }),
            onDone: {
              actions: 'assignLocation',
              target:  'ItemTransfered'
            },
            onError: {
              actions:  'assignError',
              target:   'PlacingItemInContainer',
              internal: false
            }
          }
        },
        ItemTransfered: {
          type: 'final'
        }
      }
    },
    {
      guards: {
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions
      },
      services: {
        ...API
      }
    }
  );
