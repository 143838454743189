import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { WeightContainer } from '../../capa-4/weight-container/WeightContainer';
import { WeightContainerTaskMachine } from './WeightContainerTaskMachine';

export const WeightContainerTask = withActorRef(WeightContainerTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.hint ? (
                'PESANDO CONTENEDOR'
              ) : (
                <>
                  PESANDO CONTENEDOR:{' '}
                  <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>

        {current.matches('WeightContainer') ? (
          <>
            <WeightContainer />
          </>
        ) : null}

        {current.matches('Initializing') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}
      </Ink.Box>
    );
  }
);
