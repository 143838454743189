import { Container, InventoryItem, Location } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import {
  RemoveItemFromContainerMachine,
  removeItemFromContainerInitialContext
} from '../../capa-4/remove-item-from-container/RemoveItemFromContainerMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface RemoveItemsFromContainerTaskContext {
  originContainer: Container | null;
  currentItem: InventoryItem | null;
  itemsToRemove: InventoryItem[];
  removedItems: InventoryItem[];
  targetLocation: Location | null;
  hint: string | null;
  error: string;
}

export const removeItemsFromContainerTaskInitialContext = {
  originContainer: null,
  currentItem:     null,
  itemsToRemove:   [],
  removedItems:    [],
  targetLocation:  null,
  hint:            null,
  error:           ''
};

const HELP = `Remover item de contenedor
Escanee un contenedor y luego un producto en el mismo para removerlo.`;

// Se parece demasiado a AddItemsToContainer (naturalmente)
export const RemoveItemsFromContainerTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<RemoveItemsFromContainerTaskContext>(
    {
      id:      'RemoveItemsFromContainerTaskMachine',
      initial: 'RemoveItemFromContainer',
      states:  {
        Initialize: {
          entry: 'RemoveItemFromContainer'
        },
        RemoveItemFromContainer: {
          invoke: {
            src:  RemoveItemFromContainerMachine,
            id:   RemoveItemFromContainerMachine.id,
            data: ctx => ({
              ...removeItemFromContainerInitialContext,
              requestedContainer: ctx.originContainer
            }),
            onDone: [
              {
                actions: ['updateContainer', 'updateItems'],
                cond:    'itemsRemaining',
                target:  'RemoveItemFromContainer'
              },
              {
                actions: ['updateContainer', 'updateItems'],
                target:  'ConfirmingTask'
              }
            ]
          }
        },
        ConfirmingTask: {
          on: {
            finishTask:                     'RemovedItems',
            continue:                       'RemoveItemFromContainer',
            continueWithDifferentContainer: {
              target:  'RemoveItemFromContainer',
              actions: 'resetContainer'
            }
          }
        },
        RemovedItems: {
          type: 'final',
          data: ctx => ctx
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        itemsRemaining: ctx => ctx.itemsToRemove.length > 0
      },
      actions: {
        updateItems: assign({
          itemsToRemove: (ctx, event) =>
            ctx.itemsToRemove.filter(item => item.id !== event.data.item.id),
          removedItems: (ctx, event) => [...ctx.removedItems, event.data.item]
        }),
        resetContainer: assign({
          originContainer: () =>
            removeItemsFromContainerTaskInitialContext.originContainer as any
        }),
        updateContainer: assign({
          originContainer: (ctx, event) =>
            ctx.originContainer || event.data.container
        }),
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API
      }
    }
  );
