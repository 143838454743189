import { MachineOptions } from 'xstate';
import { MiniMopAuditingWaveContext } from './machine';

type MiniMopAuditingWaveGuards = MachineOptions<
  MiniMopAuditingWaveContext,
  any
>['guards'];

export const miniMopAuditingWaveGuards: MiniMopAuditingWaveGuards = {
  isCity: (_, event) => event.data.isCity
};
