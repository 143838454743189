import Ink from '@ink';
import React, { FC } from 'react';
import { MenuItemProps } from './GenericOptions';

export interface TaskOptionsProps {
  menuItems: MenuItemProps[];
}

export const TaskOptions: FC<TaskOptionsProps> = ({ menuItems }) => {
  return (
    <>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingTop={4} paddingX={2}>
        {menuItems.map((item, i) => (
          <Ink.Button key={item.id || `item-${i}`} {...item} />
        ))}
      </Ink.Box>
    </>
  );
};

TaskOptions.displayName = 'TaskOptions';
