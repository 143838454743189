import { createMachine } from 'xstate';
import { UtilityActions } from '../../../../api/api';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import {
  ContainerDetailsContext,
  ContainerDetailsMachine
} from '../../capa-4/view-container-detail/ContainerDetailsMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export const initialContainerDetailsContext = {
  container: null
};

const HELP = `Ver detalles de contenedor
Escanee un contenedor para ver los ítems que contiene.`;

export const ViewContainerDetailsMachine =
  createMachine<ContainerDetailsContext>(
    {
      id:      'ViewContainerDetails',
      initial: 'scanContainer',
      states:  {
        scanContainer: {
          invoke: {
            id:     ScanContainerMachine.id,
            src:    ScanContainerMachine,
            onDone: [
              {
                target:  'viewDetails',
                actions: ['assignContainer']
              }
            ],
            data: DefaultScanContainerContext
          }
        },
        viewDetails: {
          invoke: {
            id:   ContainerDetailsMachine.id,
            src:  ContainerDetailsMachine,
            data: ctx => ({
              lpn: ctx.container.lpn
            }),
            target: 'finishedViewingItems'
          }
        },
        finishedViewingItems: {
          type: 'final'
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      actions: {
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      }
    }
  );
