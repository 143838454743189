import { Container, InventoryItem, Location, ProductItem } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../scan-container/ScanContainerMachine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../scan-item/ScanItemMachine';

export interface RemoveItemFromContainerTaskContext {
  fromLocation: Location | null;
  requestedContainer: Container | null;
  requiredLocation: Location | null;

  container: Container | null;
  item: InventoryItem | null;

  validContainers: Container[];
  validItems: ProductItem[];

  hint: string | null;
  error: string | null;

  isBulkItem: boolean | null;
}

export const removeItemFromContainerInitialContext: RemoveItemFromContainerTaskContext =
  {
    fromLocation: null,

    requestedContainer: null,
    requiredLocation:   null,

    container: null,
    item:      null,

    validContainers: [],
    validItems:      [],

    hint:  'Quitando ítems de contenedor',
    error: '',

    isBulkItem: null
  };

export const RemoveItemFromContainerMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFkD2A3MBhVA7ALgIYCWuYATgHSnH7GEA2xAXmAMSKgAOqstxeTiAAeiALQBGAEyUAHBIAsAThUB2WVIBsspQFYFAGhABPcQtkBfC0bSYcBEmSqEA7iTq4o9oqQoBlAGNCXDYsAHkAOQAVAEEASQiAUQAlAH0-LBiIpIARIR4+OkEkE3EAZiUyuVVdXWklAAYdXRUjUQQKmWlNKVUFfoUy1U0JKxsMbDwfJ0pkuFQGdFIoAHVUcgAzBlQXDhKC-mLQdtldVUpdKTKGmqVVGqlZI1MEMV6GynMpc0qFKQaGo8xiBbJMHL4qAAxMD4AIAC2WfgArlAYLB8JAADKoIJFEIQPBgai4dCoADWRNB3kcFEo0NhCM8yNRcAxEGxuIEuAQpFJnLwAG0GgBdfK8Q64ITtCTSWSUPRaDSaG5-czPRCKKoAvSqCSVMqaTTDYFUqY0qEw+GIlFotkcwh4tgUcjrShcBgOjbrAC2lFN4Jm9KtTJtrKxOIdXJ5JIjeKFov24rxUo1ZTOn3MDRlZSktU0l3VHR0cgkZQNAKzOm+Jom1IhlFc7mW9rxgWCbBbXLbuDIEDFhS5KY6Mso+dUlU0-VUj11qkLUiuF36+aUpaUUlluhrdjN9fQjGIEEjnjYBLIxNJFL9td3M33TCPHig0b5kcFIv7EqEL16mkoVzqWp1AaPRZAaXQ2kQU4qm0BRLl0AEpEaHptzBaZaXvQ9jygJ1yBdKh3U9H1rx3AMMIPR9lhfWMuXjT9kxKE4RzuMowM0dcGgNJRDFKBBNUobV9CGBClBGJQrGsEBcFQCA4CEf10KoGg6APVh6MHRjxD1BRKEUDdalkZRdEMm5CzEXQ-zKBQJBuMsAR6WR5FQusZkbfhPBc-wgklRMByOEQNRaShWPMMCFCzcLVCGMyKiqWQajqJCmj0FRnNvWk5lgBYlk8NZNm2Fx1P89oRnOfMJH0BRNDLX4nl4yRGkoe4004hdWMaaQ0rIi0GWtFl0XDfkfO4JMNOORBRPOECoruJo4Nasy4IuZVwokdRlF1VLJIU80GzcdyoE7PBuyK4aAoQJDdF0hRVCzYY9PXKyzMamzLlueDhh0LrFMoTDKM8U6h0nK6DWuJRZBGUsGiq+dhn-NMKrOMDQPA77ds88hQT7Xyv00viJHzUd12UR4kPzXQylhv8AJlCzV0neRNDRiFAbxt4JDlPTEcM0DIsWw05CqxzTkaS7VAkiwgA */
  createMachine<RemoveItemFromContainerTaskContext>(
    {
      id:      'RemoveItemFromContainer',
      initial: 'initialize',
      context: removeItemFromContainerInitialContext,
      states:  {
        initialize: {
          always: [
            {
              cond:   'isBulkItem',
              target: 'RemoveItemFromContainer'
            },
            {
              cond:   'containerAndItemSet',
              target: 'RemoveItemFromContainer'
            },
            {
              cond:   'containerIsSet',
              target: 'AwaitingItemScan'
            },
            {
              target: 'AwaitingContainerScan'
            }
          ]
        },
        AwaitingContainerScan: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              DefaultScanContainerContext,
              validContainers:    ctx.validContainers,
              suggestedContainer: ctx.requestedContainer
            }),
            onDone: {
              actions: ['assignContainer'],
              target:  'AwaitingItemScan'
            },
            onError: {
              actions: 'assignError',
              target:  'AwaitingContainerScan'
            }
          }
        },
        AwaitingItemScan: {
          invoke: {
            src:  ScanItemMachine,
            id:   ScanItemMachine.id,
            data: ctx => ({
              ...DefaultScanItemContext,
              validItems:    ctx.validItems,
              container:     ctx.container,
              location:      ctx.container?.location,
              suggestedItem: ctx.item
            }),
            onDone: {
              actions: 'assignItem',
              target:  'Validating'
            }
          }
        },
        Validating: {
          always: [
            {
              cond:   'validate',
              target: 'RemoveItemFromContainer'
            },
            {
              actions: 'productNotInContainer',
              target:  'AwaitingItemScan'
            }
          ]
        },

        RemoveItemFromContainer: {
          invoke: {
            src:    'removeItemFromContainer',
            onDone: [
              {
                actions: 'assignItem',
                target:  'ItemRemovedFromContainer'
              }
            ],
            onError: [
              {
                actions: 'assignError',
                target:  'AwaitingItemScan'
              }
            ]
          }
        },
        ItemRemovedFromContainer: {
          type: 'final',
          data: ctx => ({ container: ctx.container, item: ctx.item })
        }
      }
    },
    {
      guards: {
        ...UtilityGuards,
        validate: ctx => {
          const { item, container } = ctx;
          return item?.containerId === container?.id;
        },
        containerAndItemSet: ctx => {
          return !!ctx?.container && !!ctx?.item;
        },
        containerIsSet: ctx => !!ctx.requestedContainer,
        itemIsSet:      ctx => !!ctx.item,
        isBulkItem:     ctx => !!ctx.isBulkItem
      },
      actions: {
        ...UtilityActions,
        assignContainer: assign({
          container: (_ctx, event) => event.data.container
        }),
        productNotInContainer: assign({
          error: ctx =>
            'El producto no se encuentra en el contenedor. ' + ctx.item?.sku
        }),
        removeContainerFromItem: assign({
          item: ctx => {
            return { ...ctx.item, container: null, containerId: null } as any;
          }
        }),
        assignItem: assign({
          item: (_ctx, ev) => ev.item || ev.data.item
        })
      },
      services: {
        ...API
      }
    }
  );
