import { FractioningContainerPayload, InventoryItem, Task } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface FractionateContainerTaskContext {
  task: Task | null;
  containerId: number | null;
  receiptId: number | null;
  containerLpn: string | null;
  hint: string | null;
  error: string | null;
  payload: FractioningContainerPayload | null;
  items: InventoryItem[];
  selectedItemId: number | null;
  inventoryItem: InventoryItem | null;
  suggestedPieces: number | null;
  fracPieces: number | null;
  remainingQty: number | null;
}

export const DefaultFractionateContainerTaskContext: FractionateContainerTaskContext =
  {
    task:            null,
    containerId:     null,
    receiptId:       null,
    hint:            null,
    error:           null,
    payload:         null,
    containerLpn:    null,
    items:           [],
    selectedItemId:  null,
    inventoryItem:   null,
    suggestedPieces: null,
    fracPieces:      null,
    remainingQty:    null
  };

const HELP = 'Fraccionando productos.';

export const FractionateContainerTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<FractionateContainerTaskContext>(
    {
      id:      'FractionateContainerTaskMachine',
      initial: 'Initialize',
      states:  {
        Initialize: {
          always: {
            actions: assign({
              containerId: ctx =>
                ctx.payload ? ctx.payload.containerId : null,
              receiptId:    ctx => (ctx.payload ? ctx.payload.receiptId : null),
              containerLpn: ctx =>
                ctx.payload ? ctx.payload?.containerLpn : null
            }),
            target: 'FetchFractionableItems'
          }
        },
        FetchFractionableItems: {
          invoke: {
            src:    'fetchFractionableItems',
            onDone: {
              actions: assign({
                items: (ctx, evt) => evt.data.items
              }),
              target: 'SelectItem'
            },
            onError: {
              target: 'CompleteTask'
            }
          }
        },
        SelectItem: {
          on: {
            select: {
              actions: assign({
                selectedItemId: (ctx, evt) => evt.data.id
              }),
              target: 'FetchItemDetails'
            }
          }
        },
        FetchItemDetails: {
          invoke: {
            src:    'inventoryItemDetails',
            onDone: {
              actions: assign({
                inventoryItem: (ctx, evt) => evt.data.item
              }),
              target: 'FetchSuggestedPieces'
            }
          }
        },
        FetchSuggestedPieces: {
          invoke: {
            src:    'fetchSuggestedFracPieces',
            onDone: {
              actions: assign({
                suggestedPieces: (ctx, evt) => evt.data.pieces
              }),
              target: 'EnterPieces'
            },
            onError: {
              actions: 'assignError',
              target:  'FatalError'
            }
          }
        },
        EnterPieces: {
          on: {
            piecesEntered: {
              actions: assign({
                fracPieces: (ctx, evt) => evt.data.pieces
              }),
              target: 'EnterRemainingQty'
            }
          }
        },
        EnterRemainingQty: {
          on: {
            remQtyEntered: {
              actions: assign({
                remainingQty: (ctx, evt) => evt.data.remQty
              }),
              target: 'FractionateItems'
            }
          }
        },
        FractionateItems: {
          invoke: {
            src:    'fracItem',
            onDone: {
              target: 'FetchFractionableItems'
            }
          }
        },
        CompleteTask: {
          invoke: {
            src:    'completeTask',
            onDone: {
              target: 'ItemsFractionated'
            }
          }
        },
        ItemsFractionated: {
          type: 'final'
        },
        FatalError: {
          on: {
            retry: {
              target: 'FetchSuggestedPieces'
            }
          }
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        FracItemsAvail: ctx => ctx.items.length > 0
      },
      actions: {
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP),
        ...UtilityActions
      },
      services: {
        ...API
      }
    }
  );
