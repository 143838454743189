import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Address } from './Address';
import { ZoneClassifier } from './ZoneClassifier';

@Entity()
export class ZipCode {
  @PrimaryKey()
  id: number;

  @Field()
  area: string;

  @Field()
  code: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => ZoneClassifier, { name: 'zone_classifier_id' })
  zoneClassifierId: number;

  @NeededBy(() => Address, 'zipCodeId')
  addresses: Address[];

  @Needs(() => ZoneClassifier, 'zoneClassifierId')
  zoneClassifier: ZoneClassifier;
}
