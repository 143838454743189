import { Entity } from '@skyframe/core';
import { BaseLocation } from './BaseLocation';
import { Container } from './Container';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { LocationType } from './LocationType';
import { Warehouse } from './Warehouse';
import { ZoneClassifier } from './ZoneClassifier';
import { LocationStatus } from './location-status';

@Entity()
export class Dock extends BaseLocation {
  rubric: never;

  families: never;

  checkDigit: never;

  side: never;

  column: never;

  level: never;

  status: LocationStatus;

  placementAllowed: never;

  routeId: never;

  /* Should always be null */
  parent: never;

  /* Stagings */
  children: Location[];

  appointment: never;

  containers: Container[];

  inventoryItems: InventoryItem[];

  locationType: LocationType;

  warehouse: Warehouse;

  zoneClassifier: ZoneClassifier;

  crossDockWarehouse: never;

  route: never;
}
