import { PackingItemStatuses } from '../PackingItem';
import { PackingItemStatus } from './PackingItemStatus';

export class PackingItemStatusDeliveredStatus extends PackingItemStatus {
  public displayName = 'Entregado';

  public internalName = PackingItemStatuses.Delivered;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
