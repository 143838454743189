import { MachineOptions } from 'xstate';
import { MiniMopPickingWaveContext } from './machine';

type MiniMopPickingWaveGuardsType = MachineOptions<
  MiniMopPickingWaveContext,
  any
>['guards'];

export const miniMopPickingWaveGuards: MiniMopPickingWaveGuardsType = {
  allBranchOfficesHaveBeenPrepared: (_ctx, evt) => {
    return !evt.data.branchOffice;
  },
  hasPendingPickItems: (_ctx, evt) => {
    return !!evt.data.pickItem;
  },
  pickingCartPositionIsAvailable: (_ctx, evt) => {
    return evt.data.pickingCartPositionAvailable;
  },
  hasPendingMopPickingContainersToClose: (_ctx, evt) => {
    return !!evt.data.mopPickingContainer;
  }
};
