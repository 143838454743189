import { ClassificationContainerStatuses } from '../ClassificationContainer';
import { ClassificationContainerStatus } from './ClassificationContainerStatus';

export class ClassificationContainerMovedStatus extends ClassificationContainerStatus {
  public displayName = 'Movido';

  public internalName = ClassificationContainerStatuses.Moved;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
