import { Location, Task } from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultScanLabelContext,
  ScanLabelMachine
} from '../../capa-4/scan-label/machine';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../../capa-4/scan-location/ScanLocationMachine';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { miniMoveToSorterExitActions } from './actions';

export interface MiniMoveToSorterExitContext {
  task: Task | null;
  label: string | null;
  location: Location | null;
  error: string | null;
}

export const DeafultMiniMoveToSorterExitContext = {
  task:     null,
  location: null,
  label:    null,
  error:    null
};

export const MiniMoveToSorterExitMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QFkCWA7VyD2A3MAKtgMrYBOALmGQKIAeqFAdMQMYCG6m6UACu6wDW7GAGII2dGCYZc2QdLSYc+IqUrV6jFhy4Y+A4TASzsHCqkkBtAAwBdW3cSgADtliNL6ZyDqIATADMNkwAnABsABwA7AAsAKzx4YExoQCMADQgAJ4BNrFMNkGxsdHRgf6xofH5AL61WUpYeIQk5FS0DMxsnNxQADJm7BaS4pLSpgpMTSqt6h1a3bp9g+ZeJuhya9b2jj5uHiPeSL6ISQWRoTbhafGh95fRkVm5CP75hcU2l7GBoZFpIr1RoYZqqNoaTraADCkgAZqgyABbfSzJFgdAUUTIADyADUaMgaAA5AgAfWhOOJADEAJIAJSJABE9icDp5JD4-AhQlUmIE4rzIjFwr9vi8Av5okxBfdwvF-PFYmlIvFosCQDMWmp2poukxYegEcjUS10ZjRNCAILE6E0fqs1zuDnHUDc8I2aWXa7RfLvSJVaIShAAphVe7RfyReWBWKeyIarXg+Z67TU0GwAAWpvw5qxEikTFgFGGilBsx1kMWTHTmCzObAecdIHZRy5iDSf38YWu-l5aVKUdi4WD-luTEigSnioVsSCAfCifL2ohC31tdQ9Z4aIxWOoZHITBcABthnDyEjpsvk7qocwN1uoDvMRstsMvI5m62vO2edF4kwSSKrE0ZFDcArBp2BTRBEALJNGoqBGk6oaug2AQHAPhJnMt6LPszptic3IALQjjkiDEQB9zUTRtGBEuygrimd46L0+j8EIIhgPhhw-kRiBzpB0b8lOwSetRSEJg0mrXjhVb6j0eg8Ks76cmyBF8W6iApCEArhmkyEwYJ5FvEUTCVBG8SBFZfY2P8DFgnJa4wvCiIotuZq7jxLq-uU3aitcdn+OEY6JKEo7vEwaThDFIWBCF0RRCBDkVquqb3hm2YebmXnqbxalaQgBkRGGvbvBEdkRJB6QiVOCrxMh1Q2AKKVMbh66ZZA3mEYVtyeoBTzvGkfYekEo6doBk5TiFDUWUk9T1EAA */
    id:                         'MiniMoveToSorterExit',
    predictableActionArguments: true,
    schema:                     {
      context: {} as MiniMoveToSorterExitContext
    },
    initial: 'ScanningPackage',
    states:  {
      ScanningPackage: {
        invoke: {
          src:  ScanLabelMachine,
          id:   ScanLabelMachine.id,
          data: (_ctx, _event) => ({
            ...DefaultScanLabelContext,
            hint: 'Escanee la etiqueta del paquete'
          }),
          onDone: {
            actions: 'assignLabel',
            target:  'ScanningLocation'
          }
        }
      },

      ScanningLocation: {
        invoke: {
          src:  ScanLocationMachine,
          id:   ScanLocationMachine.id,
          data: (_ctx, _event) => ({
            ...DefaultScanLocationContext,
            hint: 'Confirme escaneando ubicación'
          }),
          onDone: {
            target:  'ConfirmingMovement',
            actions: 'assignLocation'
          }
        }
      },

      ConfirmingMovement: {
        on: {
          MOVEMENT_CONFIRMED: 'FinishingMovement',
          CANCEL:             'ScanningPackage'
        }
      },

      FinishingMovement: {
        invoke: {
          src: SendToParentMachine,
          id:  SendToParentMachine.id,

          data: (ctx, _event) => {
            return {
              task:    ctx.task,
              type:    'MiniPackageMovedToSorterExit',
              payload: {
                label:      ctx.label,
                locationId: ctx.location?.id
              }
            };
          },

          onError: {
            target:  'ConfirmingMovement',
            actions: 'assignConfirmationError'
          },
          onDone: 'Finished'
        }
        
      },

      Finished: {
        type: 'final'
      }
    }
  },
  {
    actions: miniMoveToSorterExitActions
  }
);
