import { ReservationStatuses } from '../Reservation';
import { ReservationStatus } from './ReservationStatus';

export class ReservationStatusDispatchedStatus extends ReservationStatus {
  public displayName = 'Despachada';

  public internalName = ReservationStatuses.Dispatched;

  public statusColor = '#f3ecfe';

  public textColor = '#8b4df3';
}
