import { InventoryItemQualityStatuses } from '../InventoryItem';
import { InventoryItemQualityStatus } from './InventoryItemQualityStatus';

export class InventoryItemQualityStatusDetainedStatus extends InventoryItemQualityStatus {
  public displayName = 'Retenido';

  public internalName = InventoryItemQualityStatuses.Detained;

  public statusColor = '#e3c5d1';

  public textColor = '#64053f';
}
