import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  OwnedBy,
  PrimaryKey
} from '@skyframe/core';
import { PackagingLevel } from './PackagingLevel';
import { Product } from './Product';
import { PurchaseOrder } from './PurchaseOrder';
import { Warehouse } from './Warehouse';

@Entity()
export class PurchaseOrderItem {
  @PrimaryKey()
  id: number;

  @Field({ name: 'sku' })
  sku: string;

  @Field({ name: 'quantity' })
  quantity: number;

  @Field({ name: 'pending_quantity' })
  pendingQuantity: number;

  @Field({ name: 'finished' })
  finished: boolean;

  @Field({ name: 'delivery_date' })
  deliveryDate: Date;

  @Field({ name: 'notified' })
  notified: boolean;

  @Field({ name: 'position' })
  position: number;

  @Field({ name: 'tolerance' })
  tolerance: number;

  @Field({ name: 'unit' })
  unit: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'sap_line_number' })
  sapLineNumber: number;

  @ForeignKey(() => PurchaseOrder, { name: 'purchase_order_id' })
  purchaseOrderId: number;

  @OwnedBy(() => PurchaseOrder, 'purchaseOrderId')
  purchaseOrder: PurchaseOrder;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Knows(() => Product, 'productId')
  product: Product;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => PackagingLevel, { name: 'packaging_level_id' })
  packagingLevelId: number;

  @Needs(() => PackagingLevel, 'packagingLevelId')
  packagingLevel: PackagingLevel;
}
