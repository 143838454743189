import { MopSorterExitProcessStatuses } from '../MopSorterExitProcess';
import { MopSorterExitProcessStatus } from './MopSorterExitProcessStatus';

export class MopSorterExitProcessProcessingStatus extends MopSorterExitProcessStatus {
  public displayName = 'Procesando';

  public internalName = MopSorterExitProcessStatuses.Processing;

  /* public statusColor = '#64b5f6';

  public textColor = '#ffa300';*/

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
