import { Container, Location } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import {
  CreateContainerMachine,
  createContainerMachineInitialContext
} from '../../capa-4/create-container/CreateContainerMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface CreateContainersTaskContext {
  currentContainer: Container | null;
  containersToCreate: Container[];
  createdContainers: Container[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
}

export const createContainersTaskInitialContext = {
  currentContainer:   null,
  containersToCreate: [],
  createdContainers:  [],
  targetLocation:     null,
  hint:               null,
  error:              ''
};

const HELP = `Creación de Contenedor 
Escanee una etiqueta para crear un nuevo contenedor en el sistema.`;

export const CreateContainersTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGEBOYCGAXMyD2AdlhgJYFiqwAqGsA1gLIYDGAFmWAMRR5V4ASYADYAHANoAGALqJQIvLBJYShWSACeiALQAOAOwA6AKwAaEAA9EAFgBsATgN2JNgExGAvu7NpMOfEVJyShp6JjYOAx9sMih-Yg5KTghCMAMyADc8OlSov0J4oOpaRhZ2ckj0aIJY-MCKWAQMvGZowkkpdrV5RWVVJAtEIwBmAEYDCRcRiSsXPTsdOxHRs00EIZtDCS3nCSNpqxGjPU9vSryAhKLQ0ojcmLi6xOTypuyK31xay5CS8PK76oPS6NAiZFq9AjtMQjGT9bpKFQENSWBAuOxDAwuCSjNwrRAuewGKxGWw2CR2Vx2PRGDxeEC5T4XQo-MJlHKEABmJFQAFsYj9OFyCCRYKwfp04QoEX1QCirDoMQq7C4rHN0ToJCM8QgbFYHCN9OshjobHtdbTTh8gcziqzbpzuXzqgLmPkyABXMASuRSiHI-F2BwuHQEoaqzXomwjGza4Y6TF6HS2El6LYuY2eOkEPAQOBqBnW+osm7kLq+xH+hBadEGEN2PXE6kKlwubV6KxE02BskHGnDEYnelnRkFIu2kupACSwuUGCEJAAXmAyz0K-0UVpibW0Q2jkZmyM7Nro2MKUZA0cW+no1ZBwWvjbrn8cmd7g-6ivpUj14gJNqZmMFpDla77BOOz6RA6vL8sUn5+j+CB-hoiBkkMd7DoWYFPmykFMvUDIQHBa6yr+bZDIYtKeEAA */
  createMachine<CreateContainersTaskContext>(
    {
      id:      'CreateContainersTaskMachine',
      initial: 'CreatingContainers',
      states:  {
        Initialize: {
          entry: 'CreatingContainers'
        },
        CreatingContainers: {
          invoke: {
            src:    CreateContainerMachine,
            id:     CreateContainerMachine.id,
            data:   createContainerMachineInitialContext,
            onDone: [
              {
                actions: 'updateContext',
                cond:    'containersRemaining',
                target:  'CreatingContainers'
              },
              {
                actions: 'updateContext',
                target:  'ConfirmingTask'
              }
            ]
          }
        },
        ConfirmingTask: {
          on: {
            finishTask: {
              target: 'ContainersCreated'
            },
            continue: 'CreatingContainers'
          }
        },
        ContainersCreated: {
          type: 'final',
          data: ctx => ctx
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        containersRemaining: ctx => ctx.containersToCreate.length > 0
      },
      actions: {
        updateContext: assign({
          containersToCreate: (context, event) => [
            ...context.containersToCreate.filter(
              container => container.lpn !== event.data.container.lpn
            )
          ],
          createdContainers: (context, event) => [
            ...context.createdContainers,
            event.data.container
          ],
          currentContainer: (_ctx, _evt) => null
        }),
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API
      }
    }
  );
