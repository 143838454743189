import { Location, OrderTypes, PickItem, Task } from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultEnterQuantityContext,
  EnterQuantityMachine
} from '../../capa-4/enter-quantity/EnterQuantityMachine';
import {
  DefaultScanControlDigitContext,
  ScanControlDigitMachine
} from '../../capa-4/scan-control-digit/scan-control-digit.machine';
import {
  DefaultScanSKUContext,
  ScanSKUMachine
} from '../../capa-4/scan-sku/ScanSKUMachine';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { bigPickingWaveActions } from './actions';
import { bigPickingWaveGuards } from './guards';
import { bigPickingWaveServices } from './services';

export interface BigPickingWaveContext {
  task: Task;
  pickingWaveId: number;
  deliveryType: string;

  clampLocation: Location | null;

  currentPickItem: PickItem | null;
  maxClampCapacity: number | null;

  suggestedPickQuantity: number | null;

  pickItemsIndex: number;
  pickItemsQuantity: number;
  pickedQuantity: number | null;
}

export const DefaultBigPickingWaveContext: BigPickingWaveContext = {
  task:          {} as Task,
  pickingWaveId: -1,
  deliveryType:  '',

  clampLocation: null,

  currentPickItem:  null,
  maxClampCapacity: null,

  suggestedPickQuantity: null,

  pickItemsIndex:    0,
  pickItemsQuantity: 0,
  pickedQuantity:    null
};

export const BigPickingWaveMachine = createMachine(
  {
    id:                         'BigPickingWave',
    predictableActionArguments: true,
    schema:                     {
      context: {} as BigPickingWaveContext
    },
    initial: 'ScanningClamp',
    states:  {
      ScanningClamp: {
        tags:   ['location'],
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Escanee etiqueta del clamp',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            actions: 'assignClampLocation',
            target:  'FetchingPickItemAssociatedToPickingStagingItem'
          }
        }
      },
      FetchingPickItemAssociatedToPickingStagingItem: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchPickItemAssociatedToPickingStagingItem',
          onDone: [
            {
              target:  'ScanningStagingLocation',
              cond:    'pickingToStagingItemExist',
              actions: 'assignCurrentPickItem'
            },
            {
              target: 'FetchingPendingPickItems'
            }
          ]
        }
      },
      FetchingPendingPickItems: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchPendingPickItems',
          onDone: {
            target:  'FetchingNextPickItem',
            actions: 'assignPickItemQuantities'
          }
        }
      },
      FetchingNextPickItem: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchNextPickItem',
          onDone: [
            {
              target:  'ScanningControlDigit',
              cond:    'hasPickItemtoPick',
              actions: 'assignCurrentPickItem'
            },
            {
              target: 'ConfirmingScreen'
            }
          ]
        }
      },
      ScanningControlDigit: {
        tags:   ['controldigit'],
        invoke: {
          id:   ScanControlDigitMachine.id,
          src:  ScanControlDigitMachine,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            validLocations:   [ctx.currentPickItem?.inventoryItem.location],
            requiredLocation: ctx.currentPickItem?.inventoryItem.location,
            hint:             'Confirme Ingresando Digito de Control'
          }),
          onDone: 'FetchingMaxClampCapacity'
        }
      },
      FetchingMaxClampCapacity: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchClampCapacity',
          onDone: [
            {
              target:  'FetchingSuggestedPickQuantity',
              actions: 'assignMaxClampCapacity'
            }
          ]
        }
      },
      FetchingSuggestedPickQuantity: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchSuggestedPickQuantity',
          onDone: [
            {
              target:  'ScanningSKU',
              actions: 'assignSuggestedPickQuantity'
            }
          ]
        }
      },

      ScanningSKU: {
        tags:   ['sku'],
        invoke: {
          id:   ScanSKUMachine.id,
          src:  ScanSKUMachine,
          data: ctx => ({
            ...DefaultScanSKUContext,
            validIdentifiers: [ctx.currentPickItem?.sku],
            hint:             'Escanee SKU del producto'
          }),
          onDone: 'EnteringQuantity'
        }
      },
      EnteringQuantity: {
        tags:   ['quantity'],
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: ctx => ({
            ...DefaultEnterQuantityContext,
            max: ctx.suggestedPickQuantity,
            min: 1
          }),
          onDone: [
            {
              target:  'MarkingItemAsPicked',
              actions: 'assignPickedQuantity'
            }
          ]
        }
      },
      MarkingItemAsPicked: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'BigPickingItemPicked',
              payload: {
                pickItemId:     ctx.currentPickItem?.id,
                pickedQuantity: ctx.pickedQuantity,
                locationId:     ctx.clampLocation?.id
              }
            };
          },
          onDone: 'FetchingPickItemAssociatedToPickingStagingItem'
        }
      },

      ScanningStagingLocation: {
        tags:   ['controldigit'],
        invoke: {
          id:   ScanControlDigitMachine.id,
          src:  ScanControlDigitMachine,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            validLocations: [
              ctx.currentPickItem?.orderItem.order.appointment.staging.name
            ],
            requiredLocation:
              ctx.currentPickItem?.orderItem.order.appointment.staging
          }),
          onDone: 'UnloadingScanningSKU'
        }
      },

      UnloadingScanningSKU: {
        tags:   ['sku'],
        invoke: {
          id:   ScanSKUMachine.id,
          src:  ScanSKUMachine,
          data: ctx => ({
            ...DefaultScanSKUContext,
            validIdentifiers: [ctx.currentPickItem?.sku],
            hint:             'Escanee SKU del producto'
          }),
          onDone: 'UnloadingEnteringQuantity'
        }
      },
      UnloadingEnteringQuantity: {
        tags:   ['quantity'],
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: ctx => ({
            ...DefaultEnterQuantityContext,
            matches: ctx.pickedQuantity,
            hint:
              ctx.currentPickItem?.orderItem.order.orderType.name ===
              OrderTypes.WMSRetailHD
                ? 'Confirme cantidad depositada y pegue las etiquetas'
                : 'Confirme cantidad depositada en el pallet'
          }),
          onDone: 'MarkingItemAsMoved'
        }
      },
      MarkingItemAsMoved: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'BigPickingToStagingItemMovement',
              payload: {
                pickingToStagingItemId:
                  ctx.currentPickItem?.pickingToStagingItem.id,
                locationId:
                  ctx.currentPickItem?.orderItem.order.appointment.staging.id
              }
            };
          },
          onDone: 'FetchingPickItemAssociatedToPickingStagingItem'
        }
      },

      ConfirmingScreen: {
        on: {
          cancel:  'FetchingPickItemAssociatedToPickingStagingItem',
          confirm: 'Finishing'
        }
      },
      Finishing: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'BigPickingTaskCompleted',
              payload: {
                pickingWaveId: ctx.pickingWaveId,
                taskId:        ctx.task.id
              }
            };
          },
          onDone: 'Done'
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   bigPickingWaveGuards,
    actions:  bigPickingWaveActions,
    services: bigPickingWaveServices
  }
);
