import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { Address } from './Address';
import { InventoryItem } from './InventoryItem';
import { Order } from './Order';
import { OrderItem } from './OrderItem';
import { ReceiptItem } from './ReceiptItem';
import { Reservation } from './Reservation';

export const HandleCancelationStatuses = {
  Pending:   'pending',
  NoNeeded:  'completed',
  InProcess: 'in-process',
  Completed: 'in-completed'
};

export type HandleCancelationStatus =
  typeof HandleCancelationStatuses[keyof typeof HandleCancelationStatuses];

export interface InventoryItemCustomer {
  fullName: string;
  dni: number;
}

@Entity()
export class FulfillmentOrder {
  @PrimaryKey()
  id: number;

  @Field({ name: 'wms_group_id' })
  wmsGroupId: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'handle_cancelation' })
  handleCancelation: HandleCancelationStatus;

  @Knows(() => ReceiptItem, 'groupId')
  receiptItems: ReceiptItem[];

  @Knows(() => OrderItem, 'groupId')
  orderItems: OrderItem[];

  @Knows(() => InventoryItem, 'groupId')
  inventoryItems: InventoryItem[];

  customer: InventoryItemCustomer;

  @ForeignKey(() => Address, { name: 'address_id' })
  addressId: number;

  @Knows(() => Address, 'addressId')
  address: Address;

  @Knows(() => Reservation, 'reservationId')
  reservation: Reservation;

  @Knows(() => Order, 'orderId')
  order: Order;

  @ForeignKey(() => Reservation, { name: 'reservation_id' })
  reservationId: number;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;
}
