import { AuditingWaveStatuses } from '../AuditingWave';
import { AuditingWaveStatus } from './AuditingWaveStatus';

export class AuditingWaveProcessingStatus extends AuditingWaveStatus {
  public displayName = 'Procesando';

  public internalName = AuditingWaveStatuses.Processing;

  /* public statusColor = '#64b5f6';

  public textColor = '#ffa300';*/

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
