import { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Page } from '../../types/page';
import { useManualApi } from './useManualApi';

export type RequestFn<T> = () => Promise<T>;
export type RequestPrevFn<T> = () => Promise<T> | undefined;
export type RequestNextFn<T> = () => Promise<T> | undefined;

export interface UseListApi<T> {
  page: Page<T>;
  pageIndex: number;
  totalPages: number | null;
  state: ReactUseApi.State;
  hasPrev: boolean;
  hasNext: boolean;
  request: RequestFn<T>;
  requestPrev: RequestPrevFn<T>;
  requestNext: RequestNextFn<T>;
}

function getListApiConfig(
  config: ReactUseApi.SingleConfig | string,
  perPage: number,
  pageIndex: number
): AxiosRequestConfig {
  const baseConfig: AxiosRequestConfig =
    typeof config === 'string' ? { method: 'GET', url: config } : config;

  return {
    ...baseConfig,
    params: {
      ...(baseConfig.params || {}),
      limit:  perPage,
      offset: pageIndex * perPage
    }
  };
}

export function useListApi<T = never>(
  config: ReactUseApi.SingleConfig | string,
  perPage: number
): UseListApi<T> {
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState<number | null>(null);

  const [page, state, requestFn] = useManualApi<Page<T>>(
    getListApiConfig(config, perPage, pageIndex)
  );

  const hasPrev = pageIndex > 0;
  const hasNext = totalPages ? pageIndex < totalPages - 1 : false;

  useEffect(() => {
    if (page && typeof page.count === 'number') {
      const total = Math.ceil(page.count / perPage);
      setTotalPages(total);

      if (pageIndex >= total) {
        setPageIndex(Math.max(0, total - 1));
      }
    }
  }, [page]);

  const request = useCallback(
    (index: number = 0) => requestFn(getListApiConfig(config, perPage, index)),
    [requestFn]
  );

  const requestPrev = useCallback(() => {
    if (hasPrev) {
      const prevIndex = pageIndex - 1;

      setPageIndex(prevIndex);
      return request(prevIndex);
    }
  }, [hasPrev, pageIndex, request, setPageIndex]);

  const requestNext = useCallback(() => {
    if (hasNext) {
      const nextIndex = pageIndex + 1;

      setPageIndex(nextIndex);
      return request(nextIndex);
    }
  }, [hasNext, pageIndex, request, setPageIndex]);

  return {
    page,
    pageIndex,
    totalPages,
    state,
    hasPrev,
    hasNext,
    request,
    requestPrev,
    requestNext
  };
}
