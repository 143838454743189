import { Container, InventoryItem, Location } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import {
  DefaultRestockLineContext,
  RestockLineMachine
} from '../../capa-4/restocking-line-machine/RestockLineMachine';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../../capa-4/scan-item/ScanItemMachine';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../../capa-4/scan-location/ScanLocationMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export const DefaultRestockingUtilityContext: RestockingUtilityContext = {
  location:     null,
  container:    null,
  item:         null,
  restockItems: [],
  restockItem:  null,
  error:        null
};

type RestockingUtilityContext = {
  location: Location | null;
  container: Container | null;
  item: InventoryItem | null;
  restockItems: InventoryItem[];
  restockItem: InventoryItem | null;
  error: string | null;
};

const HELP = 'Ver detalles de item.';

export const RestockingUtilityMachine = createMachine<RestockingUtilityContext>(
  {
    id:      'RestockingUtility',
    initial: 'Initial',
    states:  {
      Initial: {
        always: [
          {
            target: 'ChooseContOrLocation'
          }
        ]
      },

      ChooseContOrLocation: {
        on: {
          container: {
            target: 'ScanContainer'
          },
          location: {
            target: 'ScanLocation'
          }
        }
      },

      ScanLocation: {
        invoke: {
          id:     ScanLocationMachine.id,
          src:    ScanLocationMachine,
          onDone: [
            {
              target:  'ScanItem',
              actions: 'assignLocation'
            }
          ],
          data: DefaultScanLocationContext
        }
      },

      ScanContainer: {
        invoke: {
          id:     ScanContainerMachine.id,
          src:    ScanContainerMachine,
          onDone: [
            {
              target:  'ScanItem',
              actions: 'assignContainer'
            }
          ],
          data: DefaultScanContainerContext
        }
      },

      ScanItem: {
        invoke: {
          id:     ScanItemMachine.id,
          src:    ScanItemMachine,
          onDone: [
            {
              target:  'FindAvailableRestockingItems',
              actions: 'assignItem'
            }
          ],
          data: ctx => ({
            ...DefaultScanItemContext,
            container: ctx.container,
            location:  ctx.location
          })
        }
      },

      FindAvailableRestockingItems: {
        invoke: {
          src:    'fetchRestockingItems',
          onDone: {
            actions: assign({
              restockItems: (ctx, evt) => evt.data.items
            }),
            target: 'ChooseRestockItem'
          },
          onError: {
            actions: 'assignError',
            target:  'Error'
          }
        }
      },

      ChooseRestockItem: {
        on: {
          select: {
            actions: assign({
              restockItem: (ctx, evt) => evt.data.item
            }),
            target: 'RestockItems'
          }
        }
      },

      RestockItems: {
        invoke: {
          id:   RestockLineMachine.id,
          src:  RestockLineMachine,
          data: ctx => ({
            ...DefaultRestockLineContext,
            destinationContainer: ctx.item?.container,
            item:                 ctx.restockItem,
            container:            ctx.restockItem?.container,
            location:             ctx.item?.location
          }),
          onDone: {
            target: 'Finished'
          },
          onError: {
            actions: 'assignError',
            target:  'Error'
          }
        }
      },

      Finished: {
        type: 'final'
      },

      Error: {}
    },
    on: {
      goToHelp: {
        actions: 'triggerHelpScreen'
      }
    }
  },
  {
    actions: {
      ...UtilityActions,
      triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
        triggerHelpScreen(HELP)
    },
    services: {
      ...API
    }
  }
);
