import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MiniMopMoveToSorterExitMachine } from './machine';

export const MiniMopMoveToSorterExitTask = withActorRef(
  MiniMopMoveToSorterExitMachine
)(({ actorRef }) => {
  const [current] = useActor(actorRef);

  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        Traslado manual a Salida de Sorter #
        {current.context.sorterExitProcessId}
      </Ink.Header>
      {/* Header */}
      <Ink.Box>
        {current.matches('ScanningContainer') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>Escanear contenedor que desea trasladar</Ink.Text>
            <Ink.Text> </Ink.Text>
          </Ink.Box>
        )}
        {current.matches('ScanningLocation') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>Escanear ubicación de salida del sorter</Ink.Text>
            <Ink.Text> </Ink.Text>
          </Ink.Box>
        )}
      </Ink.Box>
      {/* Body */}
      <Ink.Box>
        {current.matches('ScanningContainer') && <GetContainerByLpn />}

        {current.matches('ScanningLocation') && <GetLocationByName />}
      </Ink.Box>
    </Ink.Box>
  );
});
