import { InventoryItemStatuses } from '../InventoryItem';
import { InventoryItemStatus } from './InventoryItemStatus';

export class InventoryItemStatusAvailableStatus extends InventoryItemStatus {
  public displayName = 'Disponible';

  public internalName = InventoryItemStatuses.Available;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
