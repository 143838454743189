import { AxiosError } from 'axios';

export function getErrorView(
  error?: AxiosError,
  defaultErrorMessage: string = 'Error.'
): string | null {
  return error
    ? error.response?.data?.errorView
      ? error.response.data.errorView
      : defaultErrorMessage
    : null;
}
