import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { Category2 } from './Category2';
import { RejectionReason } from './RejectionReason';

@Entity()
export class Category2RejectionReason {
  @PrimaryKey()
  id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => RejectionReason, { name: 'rejection_reason_id' })
  rejectionReasonId: number;

  @Knows(() => RejectionReason, 'rejectionReasonId')
  rejectionReason: RejectionReason;

  @ForeignKey(() => Category2, { name: 'category2_id' })
  category2Id: number;

  @Knows(() => Category2, 'category2Id')
  category2: Category2;
}
