import { Task, UploadDownloadContainerPayload } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API } from '../../../../api/api';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface DownloadContainerTaskContext {
  task: Task | null;
  containerLpn: string;
  locationName: string;
  hint: string | null;
  error: string | null;
}

export const DefaultDownloadContainerTaskContext = {
  task:         null,
  containerLpn: '',
  locationName: '',
  hint:         null,
  error:        ''
};

// @TODO: Download and Upload Container have the same states
const HELP = 'Traslado de contenedor a pasillo.';

export const DownloadContainerTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<DownloadContainerTaskContext>(
    {
      id:      'DownloadContainerTaskMachine',
      initial: 'Initialize',
      states:  {
        Initialize: {
          entry: assign({
            containerLpn: ctx =>
              (ctx.task?.payload as UploadDownloadContainerPayload)
                .containerLpn,
            locationName: ctx =>
              (ctx.task?.payload as UploadDownloadContainerPayload).locationName
          }),
          always: 'ScanContainer'
        },
        ScanContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              requestedContainer: {
                lpn:      ctx.containerLpn,
                location: {
                  name: ctx.locationName
                }
              }
            }),
            onDone:  'Confirm',
            onError: 'ScanContainer'
          }
        },
        Confirm: {
          on: {
            confirm: 'CompleteTask',
            back:    'ScanContainer'
          }
        },
        CompleteTask: {
          invoke: {
            src:     'completeTask',
            onDone:  'ContainerMoved',
            onError: 'Confirm'
          }
        },
        ContainerMoved: {
          type: 'final',
          data: ctx => ctx
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards:  {},
      actions: {
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API
      }
    }
  );
