import Ink from '@ink';
import React, { FC, useState } from 'react';

export const ScanLicensePlate: FC<{
  suggestedLicensePlate: string | null;
  plateScanned: (barcode: string) => void;
}> = ({ suggestedLicensePlate, plateScanned }) => {
  const [inputBarcode, setBarcode] = useState('');

  return (
    <>
      <Ink.Text>Escanee la patente</Ink.Text>

      {suggestedLicensePlate ? (
        <>
          <Ink.Text>
            PATENTE SUGERIDA:{' '}
            <Ink.Chalk greenBright>{suggestedLicensePlate}</Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      <Ink.TextInput
        value={inputBarcode}
        onChange={setBarcode}
        onSubmit={plateScanned}
      />
    </>
  );
};

ScanLicensePlate.displayName = 'ScanLicensePlate';
