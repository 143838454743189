import { TransportOrderStatuses } from '../TransportOrder';
import { TransportOrderStatus } from './TransportOrderStatus';

export class TransportOrderStatusProcessingStatus extends TransportOrderStatus {
  public displayName = 'Procesando';

  public internalName = TransportOrderStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
