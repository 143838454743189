import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { SorterWMSLocation } from './SorterWMSLocation';
import { WMSTransportOrder } from './WMSTransportOrder';
import {
  TransportOrderStatus,
  TransportOrderStatusCancelledStatus,
  TransportOrderStatusCompletedStatus,
  TransportOrderStatusCompletedWithErrorStatus,
  TransportOrderStatusFailedToCreateStatus,
  TransportOrderStatusPendingStatus,
  TransportOrderStatusProcessingStatus
} from './transport-order-status';

export const TransportOrderStatuses = {
  Pending:            'pending',
  Processing:         'processing',
  Completed:          'completed',
  Cancelled:          'cancelled',
  FailedToCreate:     'failed-to-create',
  CompletedWithError: 'completed-with-error'
};

export const TransportOrderType = {
  TransportOrder: 'transport-order',
  PutToWall:      'put-to-wall'
} as const;

@Entity()
export class TransportOrder {
  @PrimaryKey()
  id: number;

  @Status({
    [TransportOrderStatuses.Completed]:      TransportOrderStatusCompletedStatus,
    [TransportOrderStatuses.Cancelled]:      TransportOrderStatusCancelledStatus,
    [TransportOrderStatuses.Pending]:        TransportOrderStatusPendingStatus,
    [TransportOrderStatuses.Processing]:     TransportOrderStatusProcessingStatus,
    [TransportOrderStatuses.FailedToCreate]:
      TransportOrderStatusFailedToCreateStatus,
    [TransportOrderStatuses.CompletedWithError]:
      TransportOrderStatusCompletedWithErrorStatus
  })
  status: TransportOrderStatus;

  @Field()
  barcode: string;

  @ForeignKey(() => SorterWMSLocation, {
    name: 'origin_sorter_wms_location_id'
  })
  originSorterWMSLocationId: number;

  @ForeignKey(() => SorterWMSLocation, {
    name: 'requested_sorter_wms_location_id'
  })
  requestedSorterWMSLocationId: number;

  @ForeignKey(() => SorterWMSLocation, { name: 'final_sorter_wms_location_id' })
  finalSorterWMSLocationId: number;

  @Field({ name: 'valid_final_locations' })
  validFinalLocations: any;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => SorterWMSLocation, 'originSorterWMSLocationId')
  originSorterWMSLocation: SorterWMSLocation;

  @Knows(() => SorterWMSLocation, 'requestedSorterWMSLocationId')
  requestedSorterWMSLocation: SorterWMSLocation;

  @Knows(() => SorterWMSLocation, 'finalSorterWMSLocationId')
  finalSorterWMSLocation: SorterWMSLocation;

  @Knows(() => WMSTransportOrder, 'transportOrderId')
  wmsTransportOrder: WMSTransportOrder;
}
