import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MoveToAuditingLocationMachine } from './machine';

export const MiniMopMoveToAuditingLocationTask = withActorRef(
  MoveToAuditingLocationMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const closeTask = useCallback(() => send({ type: 'CloseTask' }), [send]);

  const continueTask = useCallback(
    () => send({ type: 'ContinueTask' }),
    [send]
  );

  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        <Ink.Text bold>Trasladar manualmente el paquete</Ink.Text>
      </Ink.Header>
      {current.matches('ScanningContainer') ? (
        <>
          <Ink.Box flexDirection='column'>
            <GetContainerByLpn />
          </Ink.Box>
        </>
      ) : null}
      {current.hasTag('loading') ? (
        <>
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>Cargando...</Ink.Text>
          </Ink.Box>
        </>
      ) : null}
      {current.matches('ScanningLocation') ? (
        <>
          <Ink.Box flexDirection='column'>
            <GetLocationByName />
          </Ink.Box>
        </>
      ) : null}
      {current.matches('MoveConfirmed') ? (
        <>
          <Ink.Box flexDirection='column'>
            <Ink.Text bold color='green'>
              El paquete se trasladó correctamente.
            </Ink.Text>
          </Ink.Box>
        </>
      ) : null}
      {current.matches('ConfirmingScreen') ? (
        <>
          <Ink.Box flexDirection='row' justifyContent='space-around'>
            <Ink.Button
              label='Continuar'
              onClick={continueTask}
              style={{ width: '40%' }}
            />
            <Ink.Button
              label='Finalizar'
              onClick={closeTask}
              style={{ width: '40%' }}
            />
          </Ink.Box>
        </>
      ) : null}

      {current.context.error && (
        <Ink.Text bold color='red'>
          {current.context.error}
        </Ink.Text>
      )}
    </Ink.Box>
  );
});
