import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { Receipt } from './Receipt';

export enum ReceiptTypes {
  Sellers = 'colecta-sellers',
  WMS = 'wms',
  StorePickup = 'colecta-sellers',
  Return = 'returned',
  Proveedor = 'Proveedor',
  Transferencia = 'Transferencia'
}

@Entity()
export class ReceiptType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'display_name' })
  displayName: string;

  @Field({ name: 'default_workflow' })
  defaultWorkflow: string;

  @Field()
  enabled: boolean;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => Receipt, 'receiptTypeId')
  receipts: Receipt[];
}
