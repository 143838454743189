import { Container, Location } from '@wms/domain';
import { createMachine } from 'xstate';

import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { auditingTaskAssignerActions } from './actions';
import { auditingTaskAssignerGuards } from './guards';
import { auditingTaskAssignerServices } from './services';

export interface AuditingTaskAssignerContext {
  location: Location | null;
  container: Container | null;
  errorMessage: string | null;
}

export const DefaultAuditingTaskAssignerContext: AuditingTaskAssignerContext = {
  location:     null,
  container:    null,
  errorMessage: null
};

export const AuditingTaskAssignerMachine = createMachine(
  {
    id:                         'AuditingTaskAssigner',
    predictableActionArguments: true,
    schema:                     {
      context: {} as AuditingTaskAssignerContext
    },
    context: DefaultAuditingTaskAssignerContext,
    initial: 'ScanningLocation',
    states:  {
      ScanningLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Confirme escaneando la etiqueta de estación de auditoría',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: [
            {
              actions: ['assignAuditingLocation'],
              target:  'ScanningContainer'
            }
          ]
        }
      },
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: _ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando la etiqueta del contenedor',
              getAnyContainerByLpn(
                'Ocurrió un error, por favor reintente más tarde.'
              )
            ),
          onDone: {
            target:  'MarkingItemAsMoved',
            actions: 'assignAuditingContainer'
          }
        },
        on: {
          RESET: 'ScanningLocation'
        }
      },
      MarkingItemAsMoved: {
        tags:   ['loading'],
        invoke: {
          src:     'markItemAsMoved',
          onDone:  'TaskAssigned',
          onError: {
            target:  'ScanningLocation',
            actions: 'assignErrorMessage'
          }
        }
      },
      TaskAssigned: {
        type: 'final',
        data: (context, event) => event.data
      }
    }
  },
  {
    guards:   auditingTaskAssignerGuards,
    actions:  auditingTaskAssignerActions,
    services: auditingTaskAssignerServices
  }
);
