import { ReservationOrderStatuses } from '../ReservationOrder';
import { ReservationOrderStatus } from './ReservationOrderStatus';

export class ReservationOrderStatusRejectedStatus extends ReservationOrderStatus {
  public displayName = 'Rechazada';

  public internalName = ReservationOrderStatuses.Rejected;

  public statusColor = '#f3d6e1';

  public textColor = '#b8006d';
}
