import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { Location } from './Location';
import { ProductAttribute } from './ProductAttribute';

@Entity()
export class LocationProductAttribute {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => ProductAttribute, { name: 'product_attribute_id' })
  productAttributeId: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => ProductAttribute, 'productAttributeId')
  productAttribute: ProductAttribute;

  @Needs(() => Location, 'locationId')
  location: Location;
}
