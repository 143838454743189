import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { Category } from './Category';
import { Location } from './Location';

@Entity()
export class CategoryLocation {
  @PrimaryKey()
  id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => Category, { name: 'category_id' })
  categoryId: number;

  @ForeignKey(() => Location, { name: 'aisle_id' })
  aisleId: number;

  @ForeignKey(() => Location, { name: 'section_id' })
  sectionId: number;

  @Knows(() => Category, 'categoryId')
  category: Category;

  @Knows(() => Location, 'aisleId')
  aisle: Location;

  @Knows(() => Location, 'sectionId')
  section: Location;
}
