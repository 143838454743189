import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { Product } from './Product';
import { Warehouse } from './Warehouse';
import {
  StockItemStatus,
  StockItemStatusAnomalyStatus,
  StockItemStatusAvailableStatus,
  StockItemStatusReservedStatus,
  StockItemStatusStorePickUpStatus,
  StockItemStatusTechnicalServiceStatus
} from './stock-item-status';

export const StockTableStatuses = {
  Available:        'available',
  Reserved:         'reserved',
  TechnicalService: 'techical-service',
  Scrap:            'scrap',
  Anomaly:          'anomaly',
  StorePickUp:      'store-pick-up'
};
@Entity()
export class StockTable {
  @PrimaryKey()
  id: number;

  @Unique()
  @Field()
  sku: string;

  @Field()
  quantity: number;

  @Needs(() => Product, 'productId')
  product: Product;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Status(
    {
      [StockTableStatuses.Available]:        StockItemStatusAvailableStatus,
      [StockTableStatuses.Anomaly]:          StockItemStatusAnomalyStatus,
      [StockTableStatuses.Reserved]:         StockItemStatusReservedStatus,
      [StockTableStatuses.Scrap]:            StockItemStatusReservedStatus,
      [StockTableStatuses.StorePickUp]:      StockItemStatusStorePickUpStatus,
      [StockTableStatuses.TechnicalService]:
        StockItemStatusTechnicalServiceStatus
    },
    { name: 'stock_item_status' }
  )
  status: StockItemStatus;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}
