import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  OwnedBy,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Address } from './Address';
import { FulfillmentOrder } from './FulfillmentOrder';
import { InventoryItem } from './InventoryItem';
import { PackagingLevel } from './PackagingLevel';
import { Product } from './Product';
import { PutAwayItem } from './PutAwayItem';
import { Receipt } from './Receipt';
import { ReceiptItemType } from './ReceiptItemType';
import { ZoneClassifier } from './ZoneClassifier';
import {
  ReceiptItemStatus,
  ReceiptItemStatusDamagedStatus,
  ReceiptItemStatusMissingStatus,
  ReceiptItemStatusOpenStatus,
  ReceiptItemStatusProcessingStatus,
  ReceiptItemStatusRejectedStatus,
  ReceiptItemStatusRepairedStatus,
  ReceiptItemStatusSortedStatus,
  ReceiptItemStatusSortingStatus,
  ReceiptItemStatusStoredStatus,
  ReceiptItemStatusVerifiedStatus
} from './receipt-item-status';
import { ReceiptItemStatusPartiallyMissingStatus } from './receipt-item-status/ReceiptItemStatusPartiallyMissingStatus';
import { ReceiptItemStatusPartiallyReceivedStatus } from './receipt-item-status/ReceiptItemStatusPartiallyReceivedStatus';
import { ReceiptItemStatusPartiallyRejectedStatus } from './receipt-item-status/ReceiptItemStatusPartiallyRejectedStatus';

export const ReceiptItemStatuses = {
  Open:              'open',
  Processing:        'processing',
  Missing:           'missing',
  Damaged:           'damaged',
  Rejected:          'rejected',
  Verified:          'verified',
  Sorting:           'sorting',
  Sorted:            'sorted',
  Stored:            'stored',
  Repaired:          'repaired',
  PartiallyMissing:  'partially-missing',
  PartiallyRejected: 'partially-rejected',
  PartiallyReceived: 'partially-received'
};

@Entity()
export class ReceiptItem {
  @PrimaryKey()
  id: number;

  @Field()
  weight: number;

  @Field()
  sku: string;

  @Field()
  quantity: number;

  @Field({ name: 'wms_receipt_item_id' })
  wmsReceiptItemId: string;

  @Field()
  volume: number;

  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @ForeignKey(() => FulfillmentOrder, { name: 'group_id' })
  groupId: number;

  @ForeignKey(() => Address, { name: 'address_id' })
  addressId: number;

  @ForeignKey(() => ReceiptItemType, { name: 'receipt_item_type_id' })
  receiptItemTypeId: number;

  @ForeignKey(() => ZoneClassifier, { name: 'zone_classifier_id' })
  zoneClassifierId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'sap_line_number' })
  sapLineNumber: number;

  @Status({
    [ReceiptItemStatuses.Open]:             ReceiptItemStatusOpenStatus,
    [ReceiptItemStatuses.Processing]:       ReceiptItemStatusProcessingStatus,
    [ReceiptItemStatuses.Missing]:          ReceiptItemStatusMissingStatus,
    [ReceiptItemStatuses.Damaged]:          ReceiptItemStatusDamagedStatus,
    [ReceiptItemStatuses.Rejected]:         ReceiptItemStatusRejectedStatus,
    [ReceiptItemStatuses.Verified]:         ReceiptItemStatusVerifiedStatus,
    [ReceiptItemStatuses.Sorting]:          ReceiptItemStatusSortingStatus,
    [ReceiptItemStatuses.Sorted]:           ReceiptItemStatusSortedStatus,
    [ReceiptItemStatuses.Stored]:           ReceiptItemStatusStoredStatus,
    [ReceiptItemStatuses.Repaired]:         ReceiptItemStatusRepairedStatus,
    [ReceiptItemStatuses.PartiallyMissing]:
      ReceiptItemStatusPartiallyMissingStatus,
    [ReceiptItemStatuses.PartiallyRejected]:
      ReceiptItemStatusPartiallyRejectedStatus,
    [ReceiptItemStatuses.PartiallyReceived]:
      ReceiptItemStatusPartiallyReceivedStatus
  })
  status: ReceiptItemStatus;

  @Knows(() => InventoryItem, 'receiptItemId')
  inventoryItem: InventoryItem;

  @OwnedBy(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Knows(() => FulfillmentOrder, 'groupId')
  group: FulfillmentOrder;

  @Knows(() => Address, 'addressId')
  address: Address;

  @Knows(() => ZoneClassifier, 'zoneClassifierId')
  zoneClassifier: ZoneClassifier;

  @Needs(() => ReceiptItemType, 'receiptItemTypeId')
  receiptItemType: ReceiptItemType;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  // @TODO: Change to Needs
  @Knows(() => Product, 'productId')
  product: Product;

  @ForeignKey(() => PackagingLevel, { name: 'packaging_level_id' })
  packagingLevelId: number;

  // @TODO: Change to Needs
  @Knows(() => PackagingLevel, 'packagingLevelId')
  packagingLevel: PackagingLevel;

  @NeededBy(() => PutAwayItem, 'receiptItemId')
  putAwayItems: PutAwayItem[];

  public static readonly ANOMALY_STATUSES = [
    ReceiptItemStatuses.Missing,
    ReceiptItemStatuses.Damaged,
    ReceiptItemStatuses.Rejected,
    ReceiptItemStatuses.PartiallyMissing
  ];
}
