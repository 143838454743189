import { ReceiptStatus } from './ReceiptStatus';

export class ReceiptStatusClosedStatus extends ReceiptStatus {
  public displayName = 'Cerrado';

  public internalName = 'closed';

  public statusColor = '#f3ecfe';

  public textColor = '#8240f2';
}
