import { MachineOptions } from 'xstate';
import { BigPickingWaveContext } from './machine';

type BigPickingWaveGuards = MachineOptions<
  BigPickingWaveContext,
  any
>['guards'];

export const bigPickingWaveGuards: BigPickingWaveGuards = {
  pickingToStagingItemExist: (ctx, evt) => {
    return evt.data;
  },
  hasPickItemtoPick: (ctx, evt) => {
    return evt.data;
  }
};
