import { InventoryCountStatus } from './InventoryCountStatus';

export class InventoryCountStatusCancelledStatus extends InventoryCountStatus {
  public displayName = 'Cancelado';

  public internalName = 'cancelled';

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
