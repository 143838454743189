import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ConsolidatingContainersToStagingContainer } from './ConsolidatingContainersToStagingContainer';
import { OrderBatch } from './OrderBatch';
import {
  ConsolidatingContainersToStagingProcessCancelledStatus,
  ConsolidatingContainersToStagingProcessCompletedStatus,
  ConsolidatingContainersToStagingProcessPausedStatus,
  ConsolidatingContainersToStagingProcessPendingStatus,
  ConsolidatingContainersToStagingProcessProcessingStatus,
  ConsolidatingContainersToStagingProcessStatus
} from './consolidating-containers-to-staging-process-status';

export const ConsolidatingContainersToStagingProcessStatuses = {
  Processing: 'Processing',
  Completed:  'Completed',
  Paused:     'Paused',
  Cancelled:  'Cancelled',
  Pending:    'Pending'
};

@Entity()
export class ConsolidatingContainersToStagingProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [ConsolidatingContainersToStagingProcessStatuses.Processing]:
      ConsolidatingContainersToStagingProcessProcessingStatus,
    [ConsolidatingContainersToStagingProcessStatuses.Completed]:
      ConsolidatingContainersToStagingProcessCompletedStatus,
    [ConsolidatingContainersToStagingProcessStatuses.Paused]:
      ConsolidatingContainersToStagingProcessPausedStatus,
    [ConsolidatingContainersToStagingProcessStatuses.Cancelled]:
      ConsolidatingContainersToStagingProcessCancelledStatus,
    [ConsolidatingContainersToStagingProcessStatuses.Pending]:
      ConsolidatingContainersToStagingProcessPendingStatus
  })
  status: ConsolidatingContainersToStagingProcessStatus;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(
    () => ConsolidatingContainersToStagingContainer,
    'consolidatingContainersToStagingProcessId'
  )
  consolidatingContainersToStagingContainers: ConsolidatingContainersToStagingContainer[];

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;
}
