import { StagingAuditingItemStatuses } from '../StagingAuditingItem';
import { StagingAuditingItemStatus } from './StagingAuditingItemStatus';

export class StagingAuditingItemAuditingStatus extends StagingAuditingItemStatus {
  public displayName = 'Auditando';

  public internalName = StagingAuditingItemStatuses.Auditing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
