import { Container, InventoryItem, Location } from '@wms/domain';
import { createMachine } from 'xstate';
import { UtilityActions } from '../../../../api/api';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../../capa-4/scan-item/ScanItemMachine';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../../capa-4/scan-location/ScanLocationMachine';

import { GoToHelpEvent } from '../../core/GenericOptions';

export const initialItemDetailsContext: ItemDetailContext = {
  item:      null,
  location:  null,
  container: null
};

type ItemDetailContext = {
  item: InventoryItem | null;
  location: Location | null;
  container: Container | null;
};

const HELP = 'Ver detalles de item.';

export const ViewItemDetailsMachine = createMachine<ItemDetailContext>(
  {
    id:      'ViewItemDetails',
    initial: 'chooseContOrLocation',
    states:  {
      chooseContOrLocation: {
        on: {
          container: {
            target: 'scanContainer'
          },
          location: {
            target: 'scanLocation'
          }
        }
      },

      scanLocation: {
        invoke: {
          id:     ScanLocationMachine.id,
          src:    ScanLocationMachine,
          onDone: [
            {
              target:  'scanItem',
              actions: 'assignLocation'
            }
          ],
          data: DefaultScanLocationContext
        }
      },

      scanContainer: {
        invoke: {
          id:     ScanContainerMachine.id,
          src:    ScanContainerMachine,
          onDone: [
            {
              target:  'scanItem',
              actions: 'assignContainer'
            }
          ],
          data: DefaultScanContainerContext
        }
      },

      scanItem: {
        invoke: {
          id:     ScanItemMachine.id,
          src:    ScanItemMachine,
          onDone: [
            {
              target:  'viewDetails',
              actions: 'assignItem'
            }
          ],
          data: ctx => ({
            ...DefaultScanItemContext,
            container: ctx.container,
            location:  ctx.location
          })
        }
      },

      viewDetails: {
        on: {
          back: {
            target: 'finishedViewingItem'
          }
        }
      },

      finishedViewingItem: {
        type: 'final'
      }
    },
    on: {
      goToHelp: {
        actions: 'triggerHelpScreen'
      }
    }
  },
  {
    actions: {
      ...UtilityActions,
      triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
        triggerHelpScreen(HELP)
    }
  }
);
