import { api } from '../../../../api/utils/axios-instance';

export const miniMopPickingWaveServices = {
  fetchPickingCartTypes: async (_ctx, _evt) => {
    const { data } = await api.get('location-attribute/get-picking-cart-types');
    return {
      pickingCartTypes: data
    };
  },
  fetchBranchOfficesQuantity: async (ctx, _evt) => {
    const { data } = await api.get(
      `customer/${ctx.pickingWaveId}/get-branch-offices`
    );
    return {
      branchOfficeQuantity: data
    };
  },
  fetchDestinyContainer: async (ctx, _evt) => {
    const { data } = await api.get(`container/lpn/${ctx.containerLpn}`);
    return {
      destinyContainer: data
    };
  },
  fetchNextBranchOfficeToPrepare: async (ctx, _evt) => {
    const { data } = await api.get(
      `customer/${ctx.pickingWaveId}/get-next-branch-office`
    );
    return {
      branchOffice: data
    };
  },
  fetchVerifyPickingCartPositionAvailable: async (ctx, _evt) => {
    const { data } = await api.get(
      `mop-picking-container/${ctx.pickingWaveId}/verify-picking-cart-position-available`,
      {
        params: {
          pickingCartPosition: ctx.pickingCartPosition
        }
      }
    );
    return {
      pickingCartPositionAvailable: data
    };
  },
  fetchNextPickItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/next-pick-item`
    );
    return {
      pickItem: data
    };
  },
  fetchPickItemDestinyContainer: async (ctx, _evt) => {
    const { data } = await api.get(
      `pick-item/${ctx.currentPickItem.id}/get-pick-item-destiny-container`
    );
    return {
      pickItemDestinyLocation: data
    };
  },
  fetchNextMopPickingContainerToClose: async (ctx, _evt) => {
    const { data } = await api.get(
      `mop-picking-container/${ctx.pickingWaveId}/get-next-mop-picking-container`
    );
    return {
      mopPickingContainer: data
    };
  }
};
