import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { OrderBatch } from './OrderBatch';
import { StagingAuditingContainer } from './StagingAuditingContainer';
import { StagingAuditingWave } from './StagingAuditingWave';
import { StagingAuditingProcessCompletedStatus } from './staging-auditing-process-status/StagingAuditingProcessCompletedStatus';
import { StagingAuditingProcessPendingStatus } from './staging-auditing-process-status/StagingAuditingProcessPendingStatus';
import { StagingAuditingProcessProcessingStatus } from './staging-auditing-process-status/StagingAuditingProcessProcessingStatus';
import { StagingAuditingProcessStatus } from './staging-auditing-process-status/StagingAuditingProcessStatus';

export const StagingAuditingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed'
};

@Entity()
export class StagingAuditingProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [StagingAuditingProcessStatuses.Pending]:
      StagingAuditingProcessPendingStatus,
    [StagingAuditingProcessStatuses.Processing]:
      StagingAuditingProcessProcessingStatus,
    [StagingAuditingProcessStatuses.Completed]:
      StagingAuditingProcessCompletedStatus
  })
  status: StagingAuditingProcessStatus;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(() => StagingAuditingWave, 'stagingAuditingProcessId')
  stagingAuditingWaves: StagingAuditingWave[];

  @NeededBy(() => StagingAuditingContainer, 'stagingAuditingProcessId')
  stagingAuditingContainers: StagingAuditingContainer[];
}
