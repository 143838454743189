import { Container, Location, Task } from '@wms/domain';
import { createMachine } from 'xstate';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getMopSorterExitContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-mop-sorter-exit-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { miniMopMoveToSorterExitActions } from './actions';
import { miniMopMoveToSorterExitGuards } from './guards';
import { miniMopMoveToSorterExitServices } from './services';

export interface MiniMopMoveToSorterExitContext {
  task: Task;
  sorterExitProcessId: number;

  location: Location | null;
  container: Container | null;
}

export const DefaultMiniMopMoveToSorterExitContext: MiniMopMoveToSorterExitContext =
  {
    task:                {} as Task,
    sorterExitProcessId: -1,

    location:  null,
    container: null
  };

export const MiniMopMoveToSorterExitMachine = createMachine(
  {
    id:                         'MiniMopMoveToSorterExit',
    predictableActionArguments: true,
    schema:                     {
      context: {} as MiniMopMoveToSorterExitContext
    },
    initial: 'ScanningContainer',
    states:  {
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando la etiqueta del contenedor',
              getMopSorterExitContainerByLpn(
                'Ocurrió un error, por favor reintente más tarde.',
                ctx.sorterExitProcessId
              )
            ),
          onDone: {
            target:  'ScanningLocation',
            actions: 'assignContainer'
          }
        }
      },
      ScanningLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Confirme escaneando la etiqueta de salida del sorter',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            actions: 'assignLocation',
            target:  'MarkingItemAsMoved'
          }
        }
      },
      MarkingItemAsMoved: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniMopSorterExitItemMoved',
              payload: {
                locationId: ctx.location?.id,
                lpn:        ctx.container?.lpn
              }
            };
          },
          onDone: 'Done'
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   miniMopMoveToSorterExitGuards,
    actions:  miniMopMoveToSorterExitActions,
    services: miniMopMoveToSorterExitServices
  }
);
