import { Entity, Field, PrimaryKey } from '@skyframe/core';

export const SlottingAlgorithms = {
  Prestorage:  'pre-storage-cedis',
  Storage:     'storage-cedis',
  Predispatch: 'pre-dispatch-cedis',
  Grid3:       'grid-3-cedis'
};

@Entity()
export class SlottingAlgorithm {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  key: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}
