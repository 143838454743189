import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditingWave } from './AuditingWave';
import { Product } from './Product';
import {
  AuditingItemAuditedStatus,
  AuditingItemAuditingStatus,
  AuditingItemCancelledStatus,
  AuditingItemStatus
} from './auditing-item-status';

export const AuditingItemStatuses = {
  Auditing:  'auditing',
  Audited:   'audited',
  Cancelled: 'cancelled'
};

@Entity()
export class AuditingItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => AuditingWave, { name: 'auditing_wave_id' })
  auditingWaveId: number;

  @Field({ name: 'expected_quantity' })
  expectedQuantity: number;

  @Field({ name: 'audited_quantity' })
  auditedQuantity: number;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Status({
    [AuditingItemStatuses.Auditing]:  AuditingItemAuditingStatus,
    [AuditingItemStatuses.Audited]:   AuditingItemAuditedStatus,
    [AuditingItemStatuses.Cancelled]: AuditingItemCancelledStatus
  })
  status: AuditingItemStatus;

  @Needs(() => AuditingWave, 'auditingWaveId')
  auditingWave: AuditingWave;

  @Needs(() => Product, 'productId')
  product: Product;
}
