import { AuditingProcessStatuses } from '../AuditingProcess';
import { AuditingProcessStatus } from './AuditingProcessStatus';

export class AuditingProcessCancelledStatus extends AuditingProcessStatus {
  public displayName = 'Cancelado';

  public internalName = AuditingProcessStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
