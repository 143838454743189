import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusDamagedStatus extends ReceiptItemStatus {
  public displayName = 'Dañado';

  public internalName = ReceiptItemStatuses.Damaged;

  public statusColor = '#ffd3d3';

  public textColor = '#c12424';
}
