import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { Appointment } from './Appointment';
import { Courier } from './Courier';

@Entity()
export class Vehicle {
  @PrimaryKey()
  id: number;

  @Field({ name: 'license_plate' })
  licensePlate: string;

  @Field({ name: 'vehicle_type' })
  vehicleType: string;

  @ForeignKey(() => Courier, { name: 'courier_id' })
  courierId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Appointment, 'vehicleId')
  appointments: Appointment[];

  @Knows(() => Courier, 'courierId')
  courier: Courier;
}
