import Ink from '@ink';
import React, { FC } from 'react';

export interface Highlightedinfo {
  attribute: string;
  value: string | number;
  color?: 'redBright' | 'greenBright';
  bold?: boolean;
}

export const HighlightedInfo: FC<Highlightedinfo> = ({
  attribute,
  value,
  color = 'greenBright',
  bold = true
}) => {
  return (
    <Ink.Text>
      {attribute}: &nbsp;{' '}
      <Ink.Chalk color={color} bold={bold}>
        {value}
      </Ink.Chalk>
    </Ink.Text>
  );
};

HighlightedInfo.displayName = 'HighlightedInfo';
