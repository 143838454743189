import { ReservationStatuses } from '../Reservation';
import { ReservationStatus } from './ReservationStatus';

export class ReservationStatusRejectedStatus extends ReservationStatus {
  public displayName = 'Rechazada';

  public internalName = ReservationStatuses.Rejected;

  public statusColor = '#e3c5d1';

  public textColor = '#64053f';
}
