import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../scan-container/ScanContainer';
import { ScanLocation } from '../scan-location/ScanLocation';
import { ScanWeight } from './ScanWeight';
import { WeightContainerMachine } from './WeightContainerMachine';

export const WeightContainer: FC = withActorRef(WeightContainerMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <>
        <Ink.Text>Asignando peso a contenedor</Ink.Text>
        {current.matches('awaitingContainerScan') ? <ScanContainer /> : null}

        {current.matches('confirmSelectedContainer') ? (
          <>
            <Ink.Text bold>
              Pesar el contenedor #{current.context.container?.lpn} que se
              encuentra en la ubicación{' '}
              {current.context.container?.location.name}
            </Ink.Text>

            <Ink.Button
              label='Confirmar contenedor a pesar'
              onClick={() => send({ type: 'confirmSelectedContainer' })}
            />
          </>
        ) : null}

        {current.matches('scanWeight') ? (
          <ScanWeight
            weightEntered={weight => {
              send({ type: 'weightEntered', data: { weight } });
            }}
            container={current.context.container}
          />
        ) : null}

        {current.matches('palletizeContainer') ? (
          <>
            <Ink.Text bold>Emplayar y etiquetar contenedor</Ink.Text>

            <Ink.Button
              label='Confirmar emplayado y etiquetado'
              onClick={() => send({ type: 'palletizeContainer' })}
            />
          </>
        ) : null}

        {current.matches('awaitingLocationScan') ? <ScanLocation /> : null}

        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}
      </>
    );
  }
);
