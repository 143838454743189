import { SorterExitProcessStatuses } from '../SorterExitProcess';
import { SorterExitProcessStatus } from './SorterExitProcessStatus';

export class SorterExitProcessStatusProcessingStatus extends SorterExitProcessStatus {
  public displayName = 'Procesando';

  public internalName = SorterExitProcessStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
