import { StagingAuditingProcessStatuses } from '../StagingAuditingProcess';
import { StagingAuditingProcessStatus } from './StagingAuditingProcessStatus';

export class StagingAuditingProcessCompletedStatus extends StagingAuditingProcessStatus {
  public displayName = 'Completado';

  public internalName = StagingAuditingProcessStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
