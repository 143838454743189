import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ContainerDetailsMachine } from './ContainerDetailsMachine';

export const ContainerDetails: FC = withActorRef(ContainerDetailsMachine)(
  ({ actorRef }) => {
    const [{ matches, context }] = useActor(actorRef);

    return matches('ShowingDetails') ? (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Text>
          LPN:{'  '}
          <Ink.Chalk greenBright bold>
            {context.container.lpn}
          </Ink.Chalk>
        </Ink.Text>
        <Ink.Text>
          LOC.:{'  '}
          <Ink.Chalk greenBright bold>
            {context.container.location.name}
          </Ink.Chalk>
        </Ink.Text>
        <Ink.Box flexDirection='column'>
          <Ink.Text>Items:</Ink.Text>
          {context.container.inventoryItems.length > 0 ? (
            context.container.inventoryItems.map((ii, i) => {
              return (
                <Ink.Box key={i} justifyContent='flex-start'>
                  <Ink.Text>{ii.quantity} x</Ink.Text>
                  {ii.product ? (
                    <Ink.Chalk greenBright>
                      {ii.product.sku + ' - ' + ii.product.name}
                    </Ink.Chalk>
                  ) : (
                    <Ink.Chalk redBright>NO PRODUCT INFO</Ink.Chalk>
                  )}
                </Ink.Box>
              );
            })
          ) : (
            <Ink.Chalk redBright bold>
              El contenedor esta vacío
            </Ink.Chalk>
          )}
        </Ink.Box>
      </Ink.Box>
    ) : (
      <></>
    );
  }
);

ContainerDetails.displayName = 'ContainerDetails';
