import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Document } from './Document';
import { Location } from './Location';
import { Printer } from './Printer';

@Entity()
export class LabelingDesk {
  @PrimaryKey()
  id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field()
  available: boolean;

  @Knows(() => Document, 'labelingDeskId')
  documents: Document[];

  @Needs(() => Location, 'locationId')
  location: Location;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @Knows(() => Printer, 'labelingDeskId')
  printers: Printer[];
}
