import { Location, ProductItem } from '@wms/domain';
import { createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';

import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../scan-item/ScanItemMachine';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../scan-location/ScanLocationMachine';

export interface BulkStorageTaskContext {
  currentItem: ProductItem | null;
  hint: string | null;
  logicStorageContainerLpn: string | null;
  error: string | null;
  locationName: string | null;
  item: any;
  Location: Location | null;
}

export const BulkStorageItemTaskInitialContext = {
  currentItem:              null,
  hint:                     null,
  logicStorageContainerLpn: 'GRANEL',
  error:                    '',
  locationName:             'AB-FRAC',
  item:                     null,
  location:                 null
};

export const BulkStorageItemMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFkD2A3MBJALmAtgHQCWAdsTsQIYA2xAXmAMSKgAOqsFxqprIAD0QAmYQDZCADgCcABjEAWBWLHCAzGtEBWADQgAnojWTJUjZq3TLVyWIC+dvWky4ChAIIB3Kt1JRX+ADKAMZUpEwQvGAkpOioANbRzth4RF4+lH4BIWEIZHGhlLwA2rIAuvwcXEV8SIIi4lJyisqqGtp6hghqsgCMZu2W1lq2Cg5OGClu6b7+qTnhYABOS6hLhGw0VDgAZmtEyQEe3rPZoaR5saiFPKSlFXVV3Lz8QggjhGoKagDsar3SH5iAFaBQ-TqIAGmcyacSSYRyTT2RwgQ6pQgAJTgqBo6DIUAA6mt4jsaKhPCxHpxnrVQG8xLJTL1ZNIwdJbFofj9epIId0ftJCNJhcKfuJpL1hLIxii0W4AGJgHDBAAW+MCAFcoDBYHgIAAZa7bW4RKIxOKJQhyoiK5Vqvya7VwPWGm68S4FY0lcqVak1V6QqX9cSckMmbliXQGIxafqyePx4S2H4KdRqLTjVGTI621XqrU6l1GmpMZardabbZ7JYHbPo3P2qCOwuQV1ei75Yu3e6+6q3AMISV9QghsWR8O9SN876mBOJ5OpjSZ63HDL4ts1Bam0jRTuWlczTJQDe3BYerveh7sP39upvXr-H5SIbyRmiXpgvm9LmEOeyJNiCm6gyhMLjooe64XqQW5lmsGxbLs+xWnW0wnEeJ68GenZuncPpUn2Lx3pCWjGCO4iRhKfwPmoYh8v8piSDCPL-F8VjLihRAAGq0MQEDGn4267lc+4cYQ3F0HxR7njhPb4TSA5DsGkZjiMkgRlGXRyL+c4AUBXxqOxYFuOJvH8VApYrHBlaITWyFGVxPGSfi0ntrJ14EbS9SDkGI7KWGamThpIiKNpCa6Yu6YOCipCoBAcD8CuZDcDxjC9vJRGDpOhDKGpCh9GIJgaNI05KJ85hKGo0hFRmsqiRBWTzOcaX+hln7RggCKyGV7Rwp1SKGVMRBYrAOJ4n4RJLCSZKeM1t50ogqb9JV8iSLIPwsqyD7TumhBaAmvScv+WhaAVA05kqeYOgWzqtlBs2EfNCDhrtB0qROU7tXlgoisKkgKOy+W9Gd4FoZBOELPdnn3ntXWSC+SayLGQzTsyAwaMxD7-TVoGDWJjlmZDA7shILLGMCChQvCtHtYF2U-VYiIFf+wNuAEyQQITGUHfGz5WP+q1I1YX6yGou0Jnlx3pgyB0s-gnOPeoX6zn+Ksi1FdhAA */
  createMachine<BulkStorageTaskContext>(
    {
      id:      'BulkStorageItem',
      initial: 'initialize',
      states:  {
        initialize: {
          always: [
            {
              target: 'AwaitingItemScan'
            }
          ]
        },
        AwaitingItemScan: {
          invoke: {
            src:  ScanItemMachine,
            id:   ScanItemMachine.id,
            data: {
              ...DefaultScanItemContext,
              locationName: ctx => ctx.locationName
            },
            onDone: [
              {
                actions: ['assignItem'],
                target:  'AwaitingLocationScan'
              }
            ],
            onError: [
              {
                actions:  'assignError',
                target:   'AwaitingItemScan',
                internal: false
              }
            ]
          }
        },
        AwaitingLocationScan: {
          invoke: {
            src:  ScanLocationMachine,
            id:   ScanLocationMachine.id,
            data: {
              ...DefaultScanLocationContext
            },
            onDone: [
              {
                actions: 'assignLocation',
                target:  'Validating'
              }
            ],
            onError: [
              {
                actions:  'assignError',
                target:   'AwaitingLocationScan',
                internal: false
              }
            ]
          }
        },
        Validating: {
          invoke: {
            src:    'changeItemContainerAndLocation',
            onDone: [
              {
                actions: 'assignItem',
                target:  'ItemMoved'
              }
            ],
            onError: [
              {
                actions: 'assignError',
                target:  'AwaitingLocationScan'
              }
            ]
          }
        },
        ItemMoved: {
          type: 'final'
        }
      }
    },
    {
      guards: {
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions
      },
      services: {
        ...API
      }
    }
  );
