import { Product } from '@wms/domain';
import { createMachine } from 'xstate';
import { Maybe } from '../../../../../utils/maybe';
import { getProductBySKUActions } from './actions';
import { getProductBySKUGuards } from './guards';
import { getProductBySKUServices } from './services';

export interface GetProductBySKUContext {
  readonly hint: string;
  productSKU: string;
  product: Product | null;
  error: string | null;
}

export type GetProductBySKUFn = (lpn: string) => Promise<Maybe<Product>>;

export const GetProductBySKUMachineId = 'GetProductBySKU';

export const GetProductBySKUMachine = (
  hint: string,
  fetchFn: GetProductBySKUFn
) =>
  createMachine(
    {
      id:                         GetProductBySKUMachineId,
      predictableActionArguments: true,
      schema:                     {
        context: {} as GetProductBySKUContext
      },
      context: {
        hint,
        productSKU: '',
        product:    null,
        error:      null
      },
      initial: 'EnteringProductSKU',
      states:  {
        EnteringProductSKU: {
          on: {
            UpdateProductSKU: {
              actions: ['updateProductSKU', 'clearError']
            },
            SubmitProductSKU: {
              cond:   'isValidProductSKU',
              target: 'FetchingProduct'
            }
          }
        },
        FetchingProduct: {
          tags:   ['loading'],
          invoke: {
            src:    'fetchProduct',
            onDone: [
              {
                cond:    'isSuccess',
                actions: 'assignProduct',
                target:  'Finished'
              },
              {
                actions: 'assignError',
                target:  'EnteringProductSKU'
              }
            ]
          }
        },
        Finished: {
          type: 'final',
          data: (context, _event) => context.product
        }
      }
    },
    {
      guards:   getProductBySKUGuards,
      actions:  getProductBySKUActions,
      services: getProductBySKUServices(fetchFn)
    }
  );
