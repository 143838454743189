import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { CreateItem } from '../../capa-4/create-item/CreateItem';
import { CreateItemsTaskMachine } from './CreateItemsTaskMachine';

export const CreateItemsTask = withActorRef(CreateItemsTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const menuItems = [
      {
        key:         'assign-task',
        displayName: 'Continuar creando.',
        event:       'continue'
      },
      { key: 'back', displayName: 'Volver al menu.', event: 'finishTask' }
    ];

    const handleClick = useCallback((id: string) => {
      const item = menuItems.find(menuItem => menuItem.key === id);
      if (item) send(item.event);
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.hint ? (
                'CREANDO ITEMS'
              ) : (
                <>
                  CREANDO ITEM:{' '}
                  <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>

        {current.matches('CreatingItems') ? (
          <>
            <CreateItem />
          </>
        ) : null}

        {current.matches('ConfirmingTask') ? (
          <>
            ¿Desea continuar con otro item?
            <Ink.Box flexDirection='column'>
              {menuItems.map(item => (
                <Ink.Button
                  key={item.key}
                  id={item.key}
                  label={item.displayName}
                  onClick={handleClick}
                />
              ))}
            </Ink.Box>
          </>
        ) : null}

        {current.matches('Initializing') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}
      </Ink.Box>
    );
  }
);
