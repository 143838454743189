import { InventoryCountingStatus } from './InventoryCountingStatus';

export class InventoryCountingStatusProcessingStatus extends InventoryCountingStatus {
  public displayName = 'Procesando';

  public internalName = 'processing';

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
