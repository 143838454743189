import { Product } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';

export interface ScanProductIdentifierContext {
  product: Product | null;
  identifier: string | null;
  error: string | null;
}

export const DefaultScanProductContext: ScanProductIdentifierContext = {
  product:    null,
  identifier: null,
  error:      null
};

export const ScanProductIdentifierMachine =
  createMachine<ScanProductIdentifierContext>(
    {
      id:      'ScanProductIdentifierMachine',
      initial: 'AwaitingIdentifierScan',
      states:  {
        AwaitingIdentifierScan: {
          on: {
            ChangedIdentifier: {
              actions: assign({
                identifier: (_, event) => event.data
              })
            },
            SubmittedIdentifier: {
              target: 'FetchingProduct'
            }
          }
        },
        FetchingProduct: {
          invoke: {
            src:    'findProductByIdentifier',
            onDone: {
              actions: assign({ product: (_ctx, event) => event.data.product }),
              target:  'ProductFound'
            },
            onError: {
              actions: 'assignError',
              target:  'AwaitingIdentifierScan'
            }
          }
        },
        ProductFound: {
          type: 'final',
          data: ctx => ({ product: ctx.product })
        }
      }
    },
    {
      guards: {
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions
      },
      services: { ...API }
    }
  );
