import { ConsolidatingContainersToStagingProcessStatuses } from '../ConsolidatingContainersToStagingProcess';
import { ConsolidatingContainersToStagingProcessStatus } from './ConsolidatingContainersToStagingProcessStatus';

export class ConsolidatingContainersToStagingProcessPendingStatus extends ConsolidatingContainersToStagingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = ConsolidatingContainersToStagingProcessStatuses.Pending;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
