import { PackingItemStatuses } from '../PackingItem';
import { PackingItemStatus } from './PackingItemStatus';

export class PackingItemStatusDraftStatus extends PackingItemStatus {
  public displayName = 'Borrador';

  public internalName = PackingItemStatuses.Draft;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
