import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../../modules/shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { ConsolidationClasificationMachine } from './machine';

export const ConsolidationClasificationComponent = withActorRef(
  ConsolidationClasificationMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);
  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        <Ink.Text bold>
          Clasificación en Rampa:{' '}
          {current.context.classificationItemLocation?.name || ''}
        </Ink.Text>
      </Ink.Header>
      {current.matches('ScanningLabel') ? (
        <>
          <HighlightedInfo
            attribute='Escanee Bulto'
            value={current.context.classificationItem?.label || ''}
          />
          <ScanLabel />
          <Ink.Text>
            <Ink.Chalk redBright bold>
              {current.context.error || ''}
            </Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}
      {current.matches('ChoosingContainerType') ? (
        <>
          <Ink.Text> Seleccione tipo de contenedor a crear</Ink.Text>
          <Ink.Button
            onClick={() =>
              send({
                type: 'CONTAINER_TYPE_CHOSEN',
                data: { containerTypeName: 'PALLET ARLOG' }
              })
            }
            label='PALLET'
          />
          <Ink.Button
            onClick={() =>
              send({
                type: 'CONTAINER_TYPE_CHOSEN',
                data: { containerTypeName: 'ROLL CONTAINER' }
              })
            }
            label='ROLL CONTAINER'
          />
        </>
      ) : null}

      {current.matches('ScanningRollContainer') ? (
        <>
          <Ink.Text>
            {' '}
            El bulto escaneado es el 1ero para el destino indicado
          </Ink.Text>
          <Ink.Text> Cree/Escanee RollContainer</Ink.Text>
          <Ink.Text> </Ink.Text>
          <ScanLabel />
        </>
      ) : null}

      {current.matches('LocatingContainer') ? (
        <>
          <HighlightedInfo
            attribute='Ubique contenedor en posicion'
            value={current.context.suggestedSlotPosition!}
          />

          <Ink.Button
            onClick={() => send('CONTAINER_LOCATED')}
            label='Contenedor Ubicado'
          />
        </>
      ) : null}

      {current.matches('DepositingInContainer') ? (
        <>
          <HighlightedInfo
            attribute='Deposite bulto'
            value={current.context.classificationItem!.label}
          />

          <HighlightedInfo
            attribute='En contenedor'
            value={current.context.classificationContainer!.container.lpn}
          />

          <HighlightedInfo
            attribute='En posicion'
            value={current.context.classificationContainer!.slotPosition}
          />

          <GetContainerByLpn id='DepositingInContainer' />
        </>
      ) : null}

      {current.matches('Finishing') ? (
        <>
          <Ink.Text> Clasificacion en contenedor finalizado</Ink.Text>
          <Ink.Text> ¿Que desea hacer?</Ink.Text>

          <Ink.Button
            onClick={() => send('CONTINUING_SCANNING')}
            label='CONTINUAR ESCANEANDO'
          />

          <Ink.Button
            onClick={() => send('CLOSING_CONTAINER')}
            label='CERRAR CONTENEDOR'
          />
        </>
      ) : null}

      {current.hasTag('loading') ? <Ink.Text> Cargando...</Ink.Text> : null}
    </Ink.Box>
  );
});
