import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  Owns,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Order } from './Order';
import { PriorityType } from './PriorityType';
import { Receipt } from './Receipt';
import { StockTransfer } from './StockTransfer';
import { TransferOrderItem } from './TransferOrderItem';
import { User } from './User';
import { Warehouse } from './Warehouse';

import {
  TransferOrderStatus,
  TransferOrderStatusCancelledStatus,
  TransferOrderStatusClosedStatus,
  TransferOrderStatusOpenStatus
} from './transfer-order-status';

export const TransferOrderStatuses = {
  Open:      'open',
  Closed:    'closed',
  Cancelled: 'cancelled'
};

@Entity()
export class TransferOrder {
  @PrimaryKey()
  id: number;

  @Field({ name: 'due_date' })
  dueDate: Date;

  @ForeignKey(() => Warehouse, { name: 'from_warehouse_id' })
  fromWarehouseId: number;

  @ForeignKey(() => Warehouse, { name: 'to_warehouse_id' })
  toWarehouseId: number;

  @ForeignKey(() => User, { name: 'user_id' })
  userId: number;

  @Knows(() => User, 'userId')
  user: User;

  @Field({ name: 'sap_transfer_code' })
  sapCode: number;

  @Field({
    name: 'transfer_number'
  })
  transferNumber: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Owns(() => TransferOrderItem, 'transferOrderId')
  transferOrderItems: TransferOrderItem[];

  @Needs(() => Warehouse, 'fromWarehouseId')
  fromWarehouse: Warehouse;

  @Needs(() => Warehouse, 'toWarehouseId')
  toWarehouse: Warehouse;

  @Knows(() => Receipt, 'transferOrderId')
  receipts: Receipt[];

  @Knows(() => Order, 'transferOrderId')
  orders: Order[];

  @Knows(() => StockTransfer, 'transferOrderId')
  stockTransfers: StockTransfer[];

  @Status({
    [TransferOrderStatuses.Open]:      TransferOrderStatusOpenStatus,
    [TransferOrderStatuses.Cancelled]: TransferOrderStatusCancelledStatus,
    [TransferOrderStatuses.Closed]:    TransferOrderStatusClosedStatus
  })
  status: TransferOrderStatus;

  @ForeignKey(() => PriorityType, { name: 'priority_type_id' })
  priorityTypeId: number;

  @Knows(() => PriorityType, 'priorityTypeId')
  priorityType: PriorityType;
}
