import { TaskStatuses } from '../Task';
import { TaskStatus } from './TaskStatus';

export class TaskStatusCancelledStatus extends TaskStatus {
  public displayName = 'Cancelada';

  public internalName = TaskStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#b90c0c';
}
