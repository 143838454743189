import { MachineOptions, assign } from 'xstate';
import { MaxiPickingWaveContext } from './machine';

type MaxiPickingWaveActionsType = MachineOptions<
  MaxiPickingWaveContext,
  any
>['actions'];

export const MaxiPickingWaveActions: MaxiPickingWaveActionsType = {
  assignPalletTruck: assign({
    palletTruck: (_ctx, event) => event.data.location
  }),
  assignPalletTruckType: assign({
    palletTruckType: (_ctx, event) => event.data.palletTruckType
  }),
  assignCreatedContainerLpn: assign({
    createdContainersLpn: (ctx, event) => {
      ctx.createdContainersLpn.push(event.data.lpn as string);
      return ctx.createdContainersLpn;
    }
  }),
  assignContainersLeftToUnload: assign({
    containersLeftToUnload: (ctx, event) => {
      ctx.containersLeftToUnload.push(event.data.lpn as string);
      return ctx.containersLeftToUnload;
    }
  }),
  assignContainersWasUnload: assign({
    containersLeftToUnload: (ctx, event) => event.data
  }),
  assignCreatedContainersId: assign({
    createdContainersId: (ctx, event) => {
      ctx.createdContainersId.push(event.data.container.id as number);
      return ctx.createdContainersId;
    }
  }),
  assignContainerSuggestedType: assign({
    containerSuggestedType: (_ctx, event) => event.data.containerType
  }),
  assignContainerType: assign({
    containerType: (_ctx, event) => event.data.containerType
  }),
  assignUnloadedContainer: assign({
    unloadedContainer: (_ctx, event) => event.data.container
  }),
  unassignedContainersLeftToScan: assign({
    containersLeftToUnload: (ctx, event) =>
      ctx.containersLeftToUnload.filter(
        contLpn => contLpn !== event.data.container.lpn
      )
  }),
  assignContainerlpn: assign({
    containerlpn: (_ctx, event) => event.data.lpn
  }),
  assignCurrentPickItem: assign({
    currentPickItem: (_ctx, event) => event.data.pickItem
  }),
  assignCurrentPickItemContainer: assign({
    currentPickItemContainer: (_ctx, event) => event.data.container
  }),
  assignOutputLocation: assign({
    outputLocation: (_ctx, event) => event.data.outputLocation
  }),
  assignPickItemQuantities: assign({
    pickItemsQuantity: (_ctx, event) => event.data.totalItems,
    pickItemsIndex:    (_ctx, event) => event.data.pickedItems + 1
  }),
  clearCtxPendingPickItems: assign({
    unloadedContainer:            (_ctx, _event) => null,
    containerlpn:                 (_ctx, _event) => null,
    containerType:                (_ctx, _event) => null,
    containerSuggestedType:       (_ctx, _event) => null,
    currentPickItem:              (_ctx, _event) => null,
    currentPickItemTotalQuantity: (_ctx, _event) => null,
    currentPickItemContainer:     (_ctx, _event) => null,
    outputLocation:               (_ctx, _event) => null,
    pickItemsIndex:               (_ctx, _event) => 0,
    pickItemsQuantity:            (_ctx, _event) => null
  }),
  assignCurrentPickItemQuantity: assign({
    currentPickItemTotalQuantity: (ctx, _event) =>
      ctx.currentPickItem?.quantity ?? 0
  }),
  assignContainerWasForcedToClose: assign({
    containerWasForcedToClose: (_ctx, _event) => true
  }),
  assignCreatedContainersQuantity: assign({
    createdContainersQuantity: (ctx, _event) =>
      ctx.createdContainersQuantity + 1
  }),
  clearContainerWasForcedToClose: assign({
    containerWasForcedToClose: (_ctx, _event) => false
  }),
  clearCreatedContainersCtx: assign({
    createdContainersId:  (_ctx, _event) => [],
    createdContainersLpn: (_ctx, _event) => []
  }),
  errorInvalidTruckType: assign({
    error: (_ctx, _event) => 'La ubicación escaneada no es válida'
  }),
  errorContainerAlreadyExists: assign({
    error: (_ctx, _event) => 'El contenedor escaneado ya existe'
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  })
};
