import { PutAwayItemStatus } from './PutAwayItemStatus';

export class PutAwayItemStatusRejectedStatus extends PutAwayItemStatus {
  public displayName = 'Rechazado';

  public internalName = 'rejected';

  public statusColor = '#ffd3d3';

  public textColor = '#c12424';
}
