import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { PlaceItemInContainer } from '../../capa-4/place-item-in-container/PlaceItemInContainer';
import { AddItemsToContainerTaskMachine } from './AddItemsToContainerTaskMachine';

export const AddItemsToContainerTask = withActorRef(
  AddItemsToContainerTaskMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const menuItems = [
    {
      key:         'continue-same-container',
      displayName: 'Continuar con mismo contenedor',
      event:       'continue'
    },
    {
      key:         'continue-different-container',
      displayName: 'Continuar con distinto contenedor',
      event:       'continueWithDifferentContainer'
    },
    { key: 'back', displayName: 'Volver al menu.', event: 'finishTask' }
  ];

  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(menuItem => menuItem.key === id);
    if (item) send(item.event);
  }, []);

  return (
    <Ink.Box flexDirection='column' paddingLeft={1}>
      <Ink.Box>
        <Ink.Header>
          <Ink.Text bold>
            {!current.context.hint ? (
              'Agregar ítems a Contenedor'
            ) : (
              <>
                AGREGANDO ITEM:{' '}
                <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
              </>
            )}
          </Ink.Text>
        </Ink.Header>
      </Ink.Box>

      {current.matches('AddItemToContainer') ? (
        <>
          <PlaceItemInContainer />
        </>
      ) : null}

      {current.matches('ConfirmingTask') ? (
        <>
          <Ink.Box flexDirection='column'>
            {menuItems.map(item => (
              <Ink.Button
                key={item.key}
                id={item.key}
                label={item.displayName}
                onClick={handleClick}
              />
            ))}
          </Ink.Box>
        </>
      ) : null}

      {current.matches('Initializing') ? (
        <Ink.Text>Cargando...</Ink.Text>
      ) : null}

      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}
    </Ink.Box>
  );
});
