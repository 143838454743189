import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ClassificationContainer } from './ClassificationContainer';
import { ClassificationProcess } from './ClassificationProcess';
import { Container } from './Container';
import { InventoryItem } from './InventoryItem';
import {
  ClassificationItemClassifiedStatus,
  ClassificationItemPendingStatus,
  ClassificationItemStatus
} from './classification-item-status';

export const ClassificationItemStatuses = {
  Pending:    'pending',
  Classified: 'classified'
};

@Entity()
export class ClassificationItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => ClassificationProcess, {
    name: 'classification_process_id'
  })
  classificationProcessId: number;

  @ForeignKey(() => ClassificationContainer, {
    name: 'classification_container_id'
  })
  classificationContainerId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field()
  label: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Status({
    [ClassificationItemStatuses.Pending]:    ClassificationItemPendingStatus,
    [ClassificationItemStatuses.Classified]: ClassificationItemClassifiedStatus
  })
  status: ClassificationItemStatus;

  @Needs(() => ClassificationProcess, 'classificationProcessId')
  classificationProcess: ClassificationProcess;

  @Knows(() => ClassificationContainer, 'classificationContainerId')
  classificationContainer: ClassificationContainer;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Knows(() => Container, 'containerId')
  container: Container;
}
