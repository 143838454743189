import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MiniMopAuditingWaveContext } from './machine';

type MiniMopAuditingWaveServices = MachineOptions<
  MiniMopAuditingWaveContext,
  any
>['services'];

export const miniMopAuditingWaveServices: MiniMopAuditingWaveServices = {
  checkIfIsCity: async context => {
    const { data } = await api.get(
      `auditing-wave/${context.auditingWaveId}/is-city`
    );
    return { isCity: data };
  },
  fetchSuggestedContainerType: async context => {
    const { data } = await api.get(
      `auditing-wave/${context.auditingWaveId}/suggested-container-type`
    );
    return { containerType: data };
  },
  fetchPendingAuditingItems: async context => {
    const { data } = await api.get(
      `auditing-wave/${context.auditingWaveId}/auditing-items`
    );
    return { auditingItems: data };
  }
};
