import { IncidentStatuses } from '../Incident';
import { IncidentStatus } from './IncidentStatus';

export class IncidentStatusPendingStatus extends IncidentStatus {
  public displayName = 'Pendiente';

  public internalName = IncidentStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
