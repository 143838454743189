import { MachineOptions, assign } from 'xstate';
import { MiniPickingWaveContext } from './machine';

type MiniPickingWaveActionsType = MachineOptions<
  MiniPickingWaveContext,
  any
>['actions'];

export const MiniPickingWaveActions: MiniPickingWaveActionsType = {
  assignSelectedContainerType: assign({
    selectedContainerType: (_ctx, event) => event.containerType
  }),
  assignLpn: assign({
    lpn: (_ctx, event) => event.data.lpn
  }),
  assignContainersType: assign({
    suggestedContainerType: (_ctx, event) => event.data.suggestedContainerType,
    containerTypes:         (_ctx, event) => event.data.containerTypes
  }),
  assignInitialLocation: assign({
    initialLocation: (_ctx, event) => event.data.location
  }),
  assignLocation: assign({
    selectedLocation: (_ctx, event) => event.data.location
  }),
  assignContainer: assign({
    createdContainer: (_ctx, event) => event.data
  }),
  assignCurrentPickItem: assign({
    currentPickItem: (_ctx, event) => event.data
  }),
  assignPendingAndTotalItems: assign({
    totalItems:   (_ctx, event) => event.data.totalItems,
    pendingItems: (_ctx, event) => event.data.pendingItems
  }),
  assignProduct: assign({
    currentProduct: (_ctx, event) => event.data.product
  }),
  incrementPickedQuantity: assign({
    pickedQuantity: (ctx, _event) => ctx.pickedQuantity + 1
  }),
  resetPickedQuantity: assign({
    pickedQuantity: (_ctx, _event) => 0
  })
};
