import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  OwnedBy,
  PrimaryKey
} from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { OrderItem } from './OrderItem';
import { PackagingLevel } from './PackagingLevel';
import { Product } from './Product';
import { TransferOrder } from './TransferOrder';

@Entity()
export class TransferOrderItem {
  @PrimaryKey()
  id: number;

  @Field({ name: 'sku' })
  sku: string;

  @Field({ name: 'sap_line_number' })
  sapLineNumber: number;

  @Field({ name: 'released' })
  released: number;

  @Field({ name: 'description' })
  description: string;

  @ForeignKey(() => TransferOrder, { name: 'transfer_order_id' })
  transferOrderId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  // wms wms stuff
  @Field()
  weight: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  // relations
  @OwnedBy(() => TransferOrder, 'transferOrderId')
  transferOrder: TransferOrder;

  // product: Product;
  /* @ForeignKey(() => Product, { name: 'product_id' })
    productId: number;

    @Knows(() => Product, 'productId')
    product: Product;*/

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @ForeignKey(() => PackagingLevel, { name: 'packaging_level_id' })
  packagingLevelId: number;

  @Knows(() => PackagingLevel, 'packagingLevelId')
  packagingLevel: PackagingLevel;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Knows(() => Product, 'productId')
  product: Product;

  @Field()
  quantity: number;

  @Knows(() => OrderItem, 'transferOrderItemId')
  orderItem: OrderItem;
}
