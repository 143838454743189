import { IncidentStatuses } from '../Incident';
import { IncidentStatus } from './IncidentStatus';

export class IncidentStatusResolvedStatus extends IncidentStatus {
  public displayName = 'Resuelto';

  public internalName = IncidentStatuses.Resolved;

  public statusColor = '#f3ecfe';

  public textColor = '#8b4df3';
}
