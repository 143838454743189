import Ink from '@ink';
import React, { FC, useCallback } from 'react';

interface MenuProps {
  send: (any: string, any1: any) => void;
}

const menuItems = [
  {
    key:         'download-container',
    displayName: 'BAJAR CONTENEDOR',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'download-container' }
  },
  {
    key:         'upload-container',
    displayName: 'SUBIR CONTENEDOR',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'upload-container' }
  },
  {
    key:         'restock-inventory',
    displayName: 'RESTOCKEAR INVENTARIO',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'restock-inventory' }
  },
  {
    key:         'inventory-counting',
    displayName: 'CONTEO DE INVENTARIO',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'inventory-counting' }
  },

  { key: 'back', displayName: '← ATRÁS', event: 'Back', payload: {} }
] as const;

export const LogisticsMenu: FC<MenuProps> = ({ send }) => {
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(i => i.key === id);
    if (item) send(item.event, item.payload);
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item => (
          <Ink.Button
            key={item.key}
            id={item.key}
            label={item.displayName}
            onClick={handleClick}
          />
        ))}
      </Ink.Box>
    </Ink.Box>
  );
};

LogisticsMenu.displayName = 'Menu';
