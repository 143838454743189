import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { PutAwayItem } from './PutAwayItem';
import { RejectionReason } from './RejectionReason';
import { User } from './User';
import { Warehouse } from './Warehouse';
import {
  IncidentStatus,
  IncidentStatusResolvedStatus
} from './incident-status';
import { IncidentStatusPendingStatus } from './incident-status/IncidentStatusPendingStatus';

export const IncidentStatuses = {
  Pending:  'pending',
  Resolved: 'resolved'
};

export const IncidentTypes = {
  inventoryItem: 'inventoryItem'
};

@Entity()
export class Incident {
  @PrimaryKey()
  id: number;

  type: string;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field()
  identifier: number;

  @Status({
    [IncidentStatuses.Pending]:  IncidentStatusPendingStatus,
    [IncidentStatuses.Resolved]: IncidentStatusResolvedStatus
  })
  status: IncidentStatus;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => User, { name: 'reporter_id' })
  reporterId: number;

  @ForeignKey(() => User, { name: 'resolver_id' })
  resolverId: number;

  @Knows(() => User, 'reporterId')
  reporter: User;

  @Knows(() => User, 'resolverId')
  resolver: User;

  @ForeignKey(() => RejectionReason, { name: 'rejection_reason_id' })
  rejectionReasonId: number;

  @Knows(() => RejectionReason, 'rejectionReasonId')
  rejectionReason: RejectionReason;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @ForeignKey(() => PutAwayItem, { name: 'put_away_item_id' })
  putAwayItemId: number;

  @Knows(() => PutAwayItem, 'putAwayItemId')
  putAwayItem: PutAwayItem;
}
