import { AuditingItemStatuses } from '../AuditingItem';
import { AuditingItemStatus } from './AuditingItemStatus';

export class AuditingItemAuditingStatus extends AuditingItemStatus {
  public displayName = 'Auditando';

  public internalName = AuditingItemStatuses.Auditing;

  public statusColor = '#e2f1f8';
}
