import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { ManagePrinterContext } from './machine';

type ManagePrinterActions = MachineOptions<
  ManagePrinterContext,
  any
>['actions'];

const DEFAULT_ERROR_MESSAGE =
  'Ocurrió un error. Por favor reintente más tarde.';

export const managePrinterActions: ManagePrinterActions = {
  setPrinter: assign({
    printer: (_, event) => event.data.printer
  }),
  clearPrinter: assign({
    printer: (_, __) => null
  }),
  setPrinterCode: assign({
    printerCode: (_, event) => event.data.printerCode
  }),
  clearPrinterCode: assign({
    printerCode: (_, __) => ''
  }),
  assignError: assign({
    error: (_, event) => getErrorView(event.data, DEFAULT_ERROR_MESSAGE)
  }),
  clearError: assign({
    error: (_, __) => null
  })
};
