import { Printer, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import { managePrinterActions } from './actions';
import { managePrinterGuards } from './guards';
import { managePrinterServices } from './services';

export interface ManagePrinterContext {
  task: Task;
  printer: Printer | null;
  printerCode: string;

  error: string | null;
}

export const DefaultManagePrinterContext: ManagePrinterContext = {
  task:        {} as Task,
  printer:     null,
  printerCode: '',

  error: null
};

export const ManagePrinterMachine = createMachine(
  {
    id:                         'ManagePrinter',
    predictableActionArguments: true,
    schema:                     {
      context: {} as ManagePrinterContext
    },
    initial: 'FetchingCurrentPrinter',
    states:  {
      FetchingCurrentPrinter: {
        tags:   'loading',
        invoke: {
          src:    'fetchCurrentPrinter',
          onDone: [
            {
              cond:    'hasPrinter',
              target:  'HasPrinter',
              actions: ['setPrinter']
            },
            {
              target:  'NoPrinter',
              actions: ['clearPrinter']
            }
          ]
        }
      },
      HasPrinter: {
        on: {
          disconnect: 'DisconnectingFromPrinter'
        }
      },
      NoPrinter: {
        on: {
          connect: 'ConnectingToPrinter',
          change:  {
            target:  'NoPrinter',
            actions: ['setPrinterCode', 'clearError']
          }
        }
      },
      ConnectingToPrinter: {
        tags:   ['connect'],
        invoke: {
          src:    'connectToPrinter',
          onDone: {
            target:  'FetchingCurrentPrinter',
            actions: ['clearPrinterCode']
          },
          onError: {
            target:  'FetchingCurrentPrinter',
            actions: ['clearPrinterCode', 'clearPrinter', 'assignError']
          }
        }
      },
      DisconnectingFromPrinter: {
        tags:   ['disconnect'],
        invoke: {
          src:    'disconnectFromPrinter',
          onDone: {
            target:  'NoPrinter',
            actions: ['clearPrinter']
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   managePrinterGuards,
    actions:  managePrinterActions,
    services: managePrinterServices
  }
);
