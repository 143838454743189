import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MiniPackingWaveContext } from './machine';

type MiniPackingWaveServices = MachineOptions<
  MiniPackingWaveContext,
  any
>['services'];

export const miniPackingWaveServices: MiniPackingWaveServices = {
  fetchPackingItemBySKU: async (ctx, _evt) => {
    const { data } = await api.get(
      `packing-wave/${ctx.packingWaveId}/packing-item-by-sku`,
      {
        params: {
          sku: ctx.product?.sku
        }
      }
    );
    return {
      packingItem: data
    };
  },
  fetchSuggestedPackageType: async (ctx, _evt) => {
    const { data } = await api.get(
      `packing-wave/${ctx.packingWaveId}/suggested-pack-type`
    );
    return {
      packType: data
    };
  },
  fetchOtherPackageType: async (_ctx, _evt) => {
    const { data } = await api.get('pack-type');
    return {
      packTypes: data.rows
    };
  },
  getPackingWaveDetails: async (ctx, _evt) => {
    const { data } = await api.get(`packing-wave/${ctx.packingWaveId}`);
    return data;
  },
  checkPendingItems: async (ctx, _evt) => {
    const { data } = await api.get(
      `packing-wave/${ctx.packingWaveId}/pending-items-count`
    );
    return data;
  }
};
