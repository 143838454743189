import { AnomalyTypes, Container, InventoryItem } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { GoToHelpEvent } from '../../core/GenericOptions';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../scan-container/ScanContainerMachine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../scan-item/ScanItemMachine';
import { SelectRejectionReasonMachine } from '../select-rejection-reason/SelectRejectionReasonMachine';

export interface CreateAnomalyContext {
  inventoryItemId: number | null;
  container: Container | null;
  anomalyType: AnomalyTypes | null;
  sku: string | null;
  lotNumber: string | null;
  quantity: number | null;
  items: InventoryItem[];
  hint: any;
  anomalyQuantity: number | null;
  selectedRejectionReasonId: number | null;
  suggestedItem: InventoryItem | null;
  item: InventoryItem | null;
  inventoryItemStatusFilter: string | null;
}

export const createAnomalyInitialContext = {
  anomalyType:               null,
  inventoryItemId:           null,
  container:                 null,
  sku:                       null,
  lotNumber:                 null,
  quantity:                  null,
  hint:                      null,
  items:                     [],
  anomalyQuantity:           null,
  selectedRejectionReasonId: null,
  suggestedItem:             null,
  item:                      null,
  inventoryItemStatusFilter: null
};

const HELP = `Marcar incidencia en producto
Escanee una etiqueta de producto y el lote.`;

export const CreateAnomalyMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<CreateAnomalyContext>(
    {
      id:      'CreateAnomalyTaskMachine',
      initial: 'Initial',
      context: createAnomalyInitialContext,
      states:  {
        Initial: {
          always: [
            {
              target: 'ScanItem',
              cond:   'hasContainer'
            },
            {
              target: 'ScanContainer'
            }
          ]
        },
        ScanContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              ...ctx,
              suggestedContainer: ctx.suggestedItem?.container
            }),
            onDone: {
              actions: ['assignContainer'],
              target:  'ScanItem'
            },
            onError: {
              actions: 'assignError',
              target:  'ScanContainer'
            }
          }
        },
        ScanItem: {
          // invoke scan sku machine
          invoke: {
            src:  ScanItemMachine,
            id:   ScanItemMachine.id,
            data: ctx => ({
              ...DefaultScanItemContext,
              container:     ctx.container,
              suggestedItem: ctx.suggestedItem,
              status:        ctx.inventoryItemStatusFilter
            }),
            onDone: [
              {
                target:  'CreateAnomaly',
                actions: ['assignItem', 'assignQty'],
                cond:    'isMissingAnomaly'
              },
              {
                actions: ['assignItem', 'assignQty'],
                target:  'SelectRejectionReason'
              }
            ],
            onError: {
              actions: 'assignError'
            }
          }
        },
        SelectRejectionReason: {
          // rejection reason machine ?
          invoke: {
            src:    SelectRejectionReasonMachine,
            id:     SelectRejectionReasonMachine.id,
            onDone: {
              target:  'CreateAnomaly',
              actions: assign({
                selectedRejectionReasonId: (ctx, evt) => evt.data.selected
              })
            }
          }
        },
        CreateAnomaly: {
          // put data on backend
          invoke: {
            src:    'markAsAnomaly',
            onDone: {
              target:  'AnomalyCreated',
              actions: assign({
                item: (ctx, evt) => evt.data.data
              })
            },
            onError: {
              actions: 'assignError',
              target:  'ScanContainer'
            }
          }
        },
        AnomalyCreated: {
          type: 'final',
          data: ctx => ({
            item: ctx.item
          })
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        ...UtilityGuards,
        hasContainer:        ctx => !!ctx.container,
        isQuantityAvailable: (ctx: CreateAnomalyContext) =>
          !!ctx.anomalyQuantity &&
          ctx.anomalyQuantity <= ctx.items.reduce((p, c) => p + c.quantity, 0)
      },
      actions: {
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP),
        assignContainer: assign({
          container: (_ctx, event) => event.data.container
        }),
        assignInvItem: assign({
          items: ctx => ctx.items
        }),
        assignQty: assign({
          anomalyQuantity: (_ctx, event) => event.data.reqQty
        })
      },
      services: {
        ...API
      }
    }
  );
