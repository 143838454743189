import { InventoryItem } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import Ink from '../../../../ink';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanItem } from '../../capa-4/scan-item/ScanItem';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { ViewItemDetailsMachine } from './ViewItemDetailsMachine';

export const ViewItemDetailsTask: FC = withActorRef(ViewItemDetailsMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <>
        <Ink.Header>Detalle Item</Ink.Header>

        {current.matches('chooseContOrLocation') ? (
          <>
            <Ink.Text>Buscar item suelto o en contenedor?</Ink.Text>

            <Ink.Button
              key='location'
              id='location'
              label='Ubicación'
              onClick={() => send({ type: 'location' })}
            />

            <Ink.Button
              key='create'
              id='create'
              label='Contenedor'
              onClick={() => send('container')}
            />
          </>
        ) : null}

        {current.matches('scanLocation') ? <ScanLocation /> : null}

        {current.matches('scanContainer') ? <ScanContainer /> : null}

        {current.matches('scanItem') ? <ScanItem /> : null}

        {current.matches('viewDetails') ? (
          <>
            <Ink.Text>
              ID:{' '}
              <Ink.Chalk greenBright>
                {current.context.item?.id as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              SKU:{' '}
              <Ink.Chalk greenBright>
                {current.context.item?.sku as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              Nombre:{' '}
              <Ink.Chalk greenBright>
                {current.context.item?.product.name as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              Cantidad:{' '}
              <Ink.Chalk greenBright>
                {current.context.item?.quantity as any}
              </Ink.Chalk>
            </Ink.Text>

            {current.context.item?.container ? (
              <>
                <Ink.Text>
                  Container LPN:{' '}
                  <Ink.Chalk greenBright>
                    {current.context.item?.container.lpn as any}
                  </Ink.Chalk>
                </Ink.Text>
              </>
            ) : (
              <Ink.Text>
                <Ink.Chalk redBright>
                  -- ESTE ITEM SE ENCUENTRA SUELTO --
                </Ink.Chalk>
              </Ink.Text>
            )}

            <Ink.Text>
              Ubicación:{' '}
              <Ink.Chalk greenBright>
                {current.context.item?.location.name as any}
              </Ink.Chalk>
            </Ink.Text>

            <Ink.Text>
              Status:{' '}
              <Ink.Chalk greenBright>
                {InventoryItem.mapStatusName(
                  current.context.item?.status as any
                )}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              Quality Status:{' '}
              <Ink.Chalk greenBright>
                {InventoryItem.mapQualityStatusName(
                  current.context.item?.qualityStatus as any
                )}
              </Ink.Chalk>
            </Ink.Text>

            <Ink.Button
              key='back'
              id='back'
              label='Atrás'
              onClick={() => send('back')}
            />
          </>
        ) : null}
      </>
    );
  }
);

ViewItemDetailsTask.displayName = 'ViewItemDetailsTask';
