import { MachineOptions } from 'xstate';
import { GetProductBySKUContext } from './machine';

type GetLocationByNameGuards = MachineOptions<
  GetProductBySKUContext,
  any
>['guards'];

export const getProductBySKUGuards: GetLocationByNameGuards = {
  isValidProductSKU: (context, _event) => !!context.productSKU,
  isSuccess:         (_context, event) => event.data.type === 'success'
};
