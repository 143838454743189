import { api } from '../../../../api/utils/axios-instance';

export const miniPickingWaveServices = {
  fetchSuggestedContainerTypesAndInitialLocation: async (_ctx, _evt) => {
    const [{ data: containerTypeData }, { data: locationData }] =
      await Promise.all([
        api.get('/container-type/suggested', {
          params: {
            type: 'mini'
          }
        }),
        api.get('location/mezzanine')
      ]);

    return {
      suggestedContainerType: containerTypeData.suggestedContainerType,
      containerTypes:         containerTypeData.containerTypes,
      location:               locationData
    };
  },
  fetchPendingItemsCount: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/pending-pick-items`
    );

    return {
      pendingItems: data.pendingItems,
      totalItems:   data.totalItems
    };
  },
  fetchingPickItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/next-pick-item`
    );

    return data;
  },
  fetchContainerByPickingWaveId: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/container`
    );
    return data;
  }
};
