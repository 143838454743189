import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusOpenStatus extends ReceiptItemStatus {
  public displayName = 'Esperado';

  public internalName = ReceiptItemStatuses.Open;

  public statusColor = '#d7d7d7';

  public textColor = '#393838';
}
