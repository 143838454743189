import { ConsolidatingContainersToStagingContainerStatuses } from '../ConsolidatingContainersToStagingContainer';
import { ConsolidatingContainersToStagingContainerStatus } from './ConsolidatingContainersToStagingContainerStatus';

export class ConsolidatingContainersToStagingContainerStatusMoved extends ConsolidatingContainersToStagingContainerStatus {
  public displayName = 'Movido';

  public internalName = ConsolidatingContainersToStagingContainerStatuses.Moved;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
