import { PackingProcessStatuses } from '../PackingProcess';
import { PackingProcessStatus } from './PackingProcessStatus';

export class PackingProcessStatusProcessingStatus extends PackingProcessStatus {
  public displayName = 'Procesando';

  public internalName = PackingProcessStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
