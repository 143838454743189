import Ink from '@ink';
import React, { FC, useCallback } from 'react';

interface MenuProps {
  send: (any: string, any1: any) => void;
}

const menuItems = [
  {
    key:         'process-receipt',
    displayName: 'RECEPCIONAR MERCADERÍA',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'process-receipt' }
  },
  {
    key:         'direct-reception',
    displayName: 'RECEPCIONAR EN LÍNEA CON GUARDADO',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'direct-reception' }
  },
  /* {
    key:         'put-away',
    displayName: 'ALMACENAR MERCADERIA',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'put-away' }
  },*/
  {
    key:         'move-container-to-prestorage',
    displayName: 'ALMACENAR CONTENEDOR',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'move-container-to-prestorage' }
  },
  /*
  {
    key:         'frac-container',
    displayName: 'FRACCIONAR PRODUCTOS',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'frac-container' }
  },*/
  { key: 'back', displayName: '← ATRÁS', event: 'Back', payload: {} }
] as const;

export const InboundMenu: FC<MenuProps> = ({ send }) => {
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(i => i.key === id);
    if (item) {
      send(item.event, item.payload);
    }
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item => (
          <Ink.Button
            key={item.key}
            id={item.key}
            label={item.displayName}
            onClick={handleClick}
          />
        ))}
      </Ink.Box>
    </Ink.Box>
  );
};

InboundMenu.displayName = 'Menu';
