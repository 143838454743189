import { StagingAuditingContainerStatuses } from '../StagingAuditingContainer';
import { StagingAuditingContainerStatus } from './StagingAuditingContainerStatus';

export class StagingAuditingContainerCancelledStatus extends StagingAuditingContainerStatus {
  public displayName = 'Cancelado';

  public internalName = StagingAuditingContainerStatuses.Cancelled;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
