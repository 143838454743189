import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusSortedStatus extends ReceiptItemStatus {
  public displayName = 'Almacenado';

  public internalName = ReceiptItemStatuses.Sorted;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
