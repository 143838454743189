import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Appointment } from './Appointment';
import { Task } from './Task';
import { User } from './User';
import { Warehouse } from './Warehouse';
import {
  TaskBucketStatus,
  TaskBucketStatusCompletedStatus,
  TaskBucketStatusDraftStatus,
  TaskBucketStatusPendingStatus
} from './task-bucket-status';

export const NewTaskBucketTypes = {
  VerifyReceipt:                            'receipt-verification',
  PutAwayReceipt:                           'receipt-put-away',
  MoveToStorage:                            'move-to-storage',
  InboundMoveContainerFromStagingToStorage:
    'inbound-move-container-from-staging-to-storage',
  OutboundCAS:       'outbound-cas',
  MoveToGrid3:       'containers-move-to-grid-3',
  WeightContaines:   'weight-and-tag-containers-to-storage-v0',
  DownloadContainer: 'download-container',
  UploadContainer:   'upload-container',
  FracContainer:     'frac-container'
};

export const TaskBucketStatuses = {
  Pending:   'pending',
  Completed: 'completed',
  Draft:     'draft'
};

export const ReceiptBucketTasktypes = {
  ReceiptTaking:        'receipt-taking',
  PlaceItemInContainer: 'receipt-placeItemInContainer',
  MoveContainer:        'receipt-moveContainer'
} as const;

export const OrderBucketTasktypes = {
  PrepareRoute:         'order-prepareRoute',
  AuditRoute:           'order-audit',
  DispatchRoute:        'order-dispatchRoute',
  ConsolidateContainer: 'consolidate-container',
  PickWave:             'order-pickWave',
  Auditing:             'order-auditing'
} as const;

export const OutboundBucketTaskTypes = {
  MoveToPackingLocation:    'move-to-packing-location',
  Packing:                  'packing',
  MoveToSorterExit:         'move-to-sorter-exit',
  MovingToAuditingLocation: 'moving-to-auditing-location',
  Consolidation:            'consolidation',
  StagingAuditing:          'staging-auditing'
} as const;

export const RestockingBucketTaskTypes = {
  Restocking:  'restocking',
  Readjusting: 'readjusting'
} as const;

export const InventoryCountBucketTaskTypes = {
  CountInventory: 'count-inventory'
} as const;

export const LogisticBucketTasktypes = {
  Move:              'logistic-move',
  SortCrossDock:     'logistic-sort-cross-dock',
  Replenishment:     'logistic-replenishment',
  DownloadContainer: 'download-container',
  UploadContainer:   'upload-container'
} as const;

export type BucketTasktype =
  | (typeof ReceiptBucketTasktypes)[keyof typeof ReceiptBucketTasktypes]
  | (typeof OrderBucketTasktypes)[keyof typeof OrderBucketTasktypes]
  | (typeof LogisticBucketTasktypes)[keyof typeof LogisticBucketTasktypes]
  | (typeof RestockingBucketTaskTypes)[keyof typeof RestockingBucketTaskTypes]
  | (typeof InventoryCountBucketTaskTypes)[keyof typeof InventoryCountBucketTaskTypes]
  | (typeof NewTaskBucketTypes)[keyof typeof NewTaskBucketTypes];
@Entity()
export class TaskBucket {
  @PrimaryKey()
  id: number;

  @Status({
    [TaskBucketStatuses.Draft]:     TaskBucketStatusDraftStatus,
    [TaskBucketStatuses.Pending]:   TaskBucketStatusPendingStatus,
    [TaskBucketStatuses.Completed]: TaskBucketStatusCompletedStatus
  })
  status: TaskBucketStatus;

  @NeededBy(() => Task, 'taskBucketId')
  tasks: Task[];

  @Field()
  type: BucketTasktype;

  @ForeignKey(() => Appointment, { name: 'appointment_id' })
  appointmentId: number;

  @Knows(() => Appointment, 'appointmentId')
  appointment: Appointment;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @Field()
  priority: number;

  @Field({ name: 'current_workers' })
  currentWorkers: number;

  @Field({ name: 'work_force_size' })
  workForceSize: number;

  @Knows(() => User, 'taskBucketId')
  workforce: User[];

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}
