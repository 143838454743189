import { Entity, Field, Knows, NeededBy, PrimaryKey } from '@skyframe/core';
import { Order } from './Order';
import { OrderBatch } from './OrderBatch';
import { ProcessInstanceListener } from './ProcessInstanceListener';
import { Receipt } from './Receipt';
import { StockTransfer } from './StockTransfer';
import { Task } from './Task';

@Entity()
export class Process {
  @PrimaryKey()
  id: number;

  currentState: any;

  @Field({ name: 'process_definition_id' })
  processDefinitionId: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Receipt, 'processInstanceId')
  receipts: Receipt[];

  @Knows(() => Order, 'processInstanceId')
  orders: Order[];

  @Knows(() => OrderBatch, 'processInstanceId')
  orderBatches: OrderBatch[];

  @Knows(() => Task, 'processInstanceId')
  tasks: Task[];

  @Knows(() => StockTransfer, 'processInstanceId')
  stockTransfers: StockTransfer[];

  @NeededBy(() => ProcessInstanceListener, 'processListenerId')
  processInstanceListeners: ProcessInstanceListener[];
}
