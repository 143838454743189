import Ink from '@ink';
import { useActor } from '@xstate/react';
import { isAlphanumeric } from 'class-validator';
import React, { FC, Reducer, useReducer } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanItem } from '../scan-item/ScanItem';
import { ChangeItemStatusMachine } from './ChangeItemStatusMachine';

export const ChangeItemStatus: FC = withActorRef(ChangeItemStatusMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const onSubmit = status => {
      send({ type: 'statusEntered', data: { status } });
    };

    const [inputStatus, setStatus] = useReducer<Reducer<string, string>>(
      (prev, next) => {
        if (next !== '' && current.context) {
          // setError('');
        }
        return next === '' || isAlphanumeric(next) ? next : prev;
      },
      ''
    );

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.hint ? (
                'CAMBIANDO ESTADO DE ITEM'
              ) : (
                <>
                  CAMBIANDO ESTADO DE ITEM:{' '}
                  <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>
        {current.matches('ScanItem') ? <ScanItem /> : null}
        {current.matches('SetStatus') ? (
          <>
            {' '}
            <Ink.Text>
              {current.context.hint
                ? current.context.hint
                : 'Ingrese estado del item:'}
            </Ink.Text>
            <Ink.TextInput
              value={inputStatus}
              onChange={setStatus}
              onSubmit={onSubmit}
            />
          </>
        ) : null}

        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}
      </Ink.Box>
    );
  }
);
