import { StagingAuditingItemStatuses } from '../StagingAuditingItem';
import { StagingAuditingItemStatus } from './StagingAuditingItemStatus';

export class StagingAuditingItemCancelledStatus extends StagingAuditingItemStatus {
  public displayName = 'Cancelado';

  public internalName = StagingAuditingItemStatuses.Cancelled;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
