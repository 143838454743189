import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { withActorRef } from '../../../../shared/Machine';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from './machine';

export const GetLocationByName = withActorRef(
  GetLocationByNameMachine,
  GetLocationByNameMachineId
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const updateLocationName = useCallback(
    (locationName: string) =>
      send({ type: 'UpdateLocationName', locationName }),
    [send]
  );

  const submitLocationName = useCallback(
    () => send({ type: 'SubmitLocationName' }),
    [send]
  );

  return (
    <Ink.Box flexDirection='column' paddingY={1}>
      <Ink.Text bold>{current.context.hint}:</Ink.Text>

      {current.hasTag('loading') ? (
        <Ink.Text>Cargando</Ink.Text>
      ) : (
        <>
          <Ink.TextInput
            value={current.context.locationName}
            onChange={updateLocationName}
            onSubmit={submitLocationName}
          />

          {current.context.error ? (
            <Ink.Text color='redBright' bold>
              {current.context.error}
            </Ink.Text>
          ) : null}
        </>
      )}
    </Ink.Box>
  );
});
