import { StagingAuditingWaveStatuses } from '../StagingAuditingWave';
import { StagingAuditingWaveStatus } from './StagingAuditingWaveStatus';

export class StagingAuditingWavePausedStatus extends StagingAuditingWaveStatus {
  public displayName = 'Pausado';

  public internalName = StagingAuditingWaveStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
