import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ClassificationContainer } from './ClassificationContainer';
import { ConsolidatingContainersToStagingProcess } from './ConsolidatingContainersToStagingProcess';
import { Container } from './Container';
import {
  ConsolidatingContainersToStagingContainerStatus,
  ConsolidatingContainersToStagingContainerStatusMoved,
  ConsolidatingContainersToStagingContainerStatusPending
} from './consolidating-containers-to-staging-containers-status';

export const ConsolidatingContainersToStagingContainerStatuses = {
  Pending: 'pending',
  Moved:   'moved'
};

@Entity()
export class ConsolidatingContainersToStagingContainer {
  @PrimaryKey()
  id: number;

  @Status({
    [ConsolidatingContainersToStagingContainerStatuses.Pending]:
      ConsolidatingContainersToStagingContainerStatusPending,
    [ConsolidatingContainersToStagingContainerStatuses.Moved]:
      ConsolidatingContainersToStagingContainerStatusMoved
  })
  status: ConsolidatingContainersToStagingContainerStatus;

  @ForeignKey(() => ConsolidatingContainersToStagingProcess, {
    name: 'consolidating_containers_to_staging_process_id'
  })
  consolidatingContainersToStagingProcessId: number;

  @ForeignKey(() => ClassificationContainer, {
    name: 'classification_container_id'
  })
  classificationContainerId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(
    () => ConsolidatingContainersToStagingProcess,
    'consolidatingContainersToStagingProcessId'
  )
  consolidatingContainersToStagingProcess: ConsolidatingContainersToStagingProcess;

  @Needs(() => ClassificationContainer, 'classificationContainerId')
  classificationContainer: ClassificationContainer;

  @Needs(() => Container, 'containerId')
  container: Container;
}
