import { AuditingItemStatuses } from '../AuditingItem';
import { AuditingItemStatus } from './AuditingItemStatus';

export class AuditingItemAuditedStatus extends AuditingItemStatus {
  public displayName = 'Auditado';

  public internalName = AuditingItemStatuses.Audited;

  public statusColor = '#b90c0c';
}
