import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { OrderBatch } from './OrderBatch';
import { PackingItem } from './PackingItem';
import { PackingWave } from './PackingWave';
import {
  PackingProcessStatus,
  PackingProcessStatusCancelledStatus,
  PackingProcessStatusCompletedStatus,
  PackingProcessStatusPendingStatus,
  PackingProcessStatusProcessingStatus
} from './packing-process-status';

export const PackingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class PackingProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [PackingProcessStatuses.Pending]:    PackingProcessStatusPendingStatus,
    [PackingProcessStatuses.Processing]: PackingProcessStatusProcessingStatus,
    [PackingProcessStatuses.Cancelled]:  PackingProcessStatusCancelledStatus,
    [PackingProcessStatuses.Completed]:  PackingProcessStatusCompletedStatus
  })
  status: PackingProcessStatus;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(() => PackingWave, 'packingProcessId')
  packingWaves: PackingWave[];

  @Knows(() => PackingItem, 'packingProcessId')
  packingItems: PackingItem[];
}
