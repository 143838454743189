import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetLocationByNameFn } from '../machine';

export function getAnyLocationByName(
  defaultErrorMessage: string
): GetLocationByNameFn {
  return fetchResult(defaultErrorMessage, name =>
    api.get<Location>('location/name', {
      params: { name }
    })
  );
}
