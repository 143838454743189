import { MachineOptions } from 'xstate';
import { GetContainerByLpnContext } from './machine';

type GetLocationByNameGuards = MachineOptions<
  GetContainerByLpnContext,
  any
>['guards'];

export const getContainerByLpnGuards: GetLocationByNameGuards = {
  isValidContainerLpn: (context, _event) => !!context.containerLpn,
  isSuccess:           (_context, event) => event.data.type === 'success'
};
