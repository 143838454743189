import { Location } from '@wms/domain';
import { createMachine } from 'xstate';
import { UtilityActions } from '../../../../api/api';

import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../../capa-4/scan-location/ScanLocationMachine';
import { LocationDetailsMachine } from '../../capa-4/view-location-detail/LocationDetailsMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export const initialLocationDetailsContext: LocationDetailContext = {
  location: null
};

type LocationDetailContext = {
  location: Location | null;
};

const HELP = `Ver detalles de ubicación
Escanee una ubicación para ver todo lo que se encuentra en la misma.`;

export const ViewLocationDetailsMachine = createMachine<LocationDetailContext>(
  {
    id:      'ViewLocationDetails',
    initial: 'scanLocation',
    states:  {
      scanLocation: {
        invoke: {
          id:     ScanLocationMachine.id,
          src:    ScanLocationMachine,
          onDone: [
            {
              target:  'viewDetails',
              actions: ['assignLocation']
            }
          ],
          data: DefaultScanLocationContext
        }
      },
      viewDetails: {
        invoke: {
          id:   LocationDetailsMachine.id,
          src:  LocationDetailsMachine,
          data: ctx => ({
            location: ctx.location
          }),
          target: 'finishedViewingLocation'
        }
      },
      finishedViewingLocation: {
        type: 'final'
      }
    },
    on: {
      goToHelp: {
        actions: 'triggerHelpScreen'
      }
    }
  },
  {
    actions: {
      ...UtilityActions,
      triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
        triggerHelpScreen(HELP)
    }
  }
);
