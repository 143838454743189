import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { ManagePrinterContext } from './machine';

type ManagePrinterServices = MachineOptions<
  ManagePrinterContext,
  any
>['services'];

export const managePrinterServices: ManagePrinterServices = {
  fetchCurrentPrinter: async () => {
    const { data } = await api.get('/printer/get-by-user');
    return {
      printer: data
    };
  },
  connectToPrinter: async (ctx, _) => {
    await api.post('/printer/connect', null, {
      params: {
        printerCode: ctx.printerCode
      }
    });
  },
  disconnectFromPrinter: async () => {
    await api.post('/printer/disconnect');
  }
};
