import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusDocMissingStatus extends OrderItemStatus {
  public displayName = 'Falta Documentación';

  public internalName = OrderItemStatuses.DocMissing;

  public statusColor = '#ffd3d3';

  public textColor = '#b90c0c';
}
