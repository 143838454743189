import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { ScanSKU } from '../../capa-4/scan-sku/ScanSKU';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MiniMopPickingWaveMachine } from './machine';

export const MiniMopPickingWaveTask = withActorRef(MiniMopPickingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const getDisplayName = (pickingCartType: string) => {
      switch (pickingCartType) {
        case 'tote-picking-cart-9':
          return 'Carro de Picking 9 Cubetas';
        default:
          return 'N/A';
      }
    };
    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            Ola Nº{current.context.pickingWaveId || 'N/A'} - AB TIE - Mezzanine
            Nivel N/A
          </Ink.Text>
        </Ink.Header>
        {/* Header */}
        <Ink.Box flexDirection='column'>
          {current.matches('SelectingPickingCartType') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Tomar carro de picking</Ink.Text>
              {current.context.pickingCartTypes?.map(type => (
                <Ink.Button
                  label={getDisplayName(type)}
                  onClick={() =>
                    send({
                      type: 'pickingCartTypeSelected',
                      data: { pickingCartType: type }
                    })
                  }
                  key={type}
                />
              ))}
            </Ink.Box>
          ) : null}

          {current.matches('ScanningPickingCart') ? (
            <>
              <Ink.Text>
                Confirme &nbsp;
                <Ink.Chalk bold color='greenBright'>
                  {getDisplayName(current.context.pickingCartType!)}
                </Ink.Chalk>
              </Ink.Text>
              <GetLocationByName id='ScanningPickingCart' />
            </>
          ) : null}

          {current.matches('ScanningContainer') ? (
            <>
              <Ink.Text>Creacion de cubetas</Ink.Text>
              <Ink.Text>
                Debe crear {current.context.branchOfficeTotal}
              </Ink.Text>
              <Ink.Text>
                Cubetas creadas {current.context.branchOfficeCreated} /{' '}
                {current.context.branchOfficeTotal}
              </Ink.Text>
            </>
          ) : null}

          {current.matches('SelectingPickingCartPosition') ? (
            <>
              <Ink.Text>Indique Posicion dentro del carro</Ink.Text>
              <Ink.Text>
                Cubeta {current.context.branchOfficeCreated} /{' '}
                {current.context.branchOfficeTotal}
              </Ink.Text>
              <EnterQuantity />
              <Ink.Text>
                <Ink.Chalk bold color='redBright'>
                  {current.context.positionError || ''}
                </Ink.Chalk>
              </Ink.Text>
            </>
          ) : null}

          {current.matches('ScanningItemLocation') ? (
            <>
              <Ink.Text>Trasladese Hasta la ubicacion</Ink.Text>
              <Ink.Text>
                <Ink.Chalk bold color='greenBright'>
                  {current.context.currentPickItem!.inventoryItem.location.name}
                </Ink.Chalk>
              </Ink.Text>
              <GetLocationByName id='ScanningItemLocation' />
            </>
          ) : null}

          {current.matches('ScanningPickItem') ? (
            <>
              <Ink.Text>
                Escanee CB del producto &nbsp;
                <Ink.Chalk bold color='greenBright'>
                  {current.context.currentPickItem!.sku}
                </Ink.Chalk>
                &nbsp;-&nbsp;
                <Ink.Chalk bold color='greenBright'>
                  {current.context.currentPickItem!.product.name}
                </Ink.Chalk>
              </Ink.Text>
            </>
          ) : null}

          {current.matches('ScanningMiniMopContainer') ? (
            <>
              <Ink.Text>
                Escanee Contenedor &nbsp;
                <Ink.Chalk bold color='greenBright'>
                  {
                    current.context.currentMopPickingContainerToClose!.container
                      .lpn
                  }
                </Ink.Chalk>
              </Ink.Text>
              <GetContainerByLpn id='ScanningMiniMopContainer' />
            </>
          ) : null}

          {current.matches('ScanningPickItemDestinyLocation') ? (
            <>
              <Ink.Text>Escanee cubeta de destino </Ink.Text>
              <Ink.Text>
                <Ink.Chalk bold color='greenBright'>
                  {
                    current.context.pickItemDestinyContainerLocation!.container
                      .lpn
                  }
                </Ink.Chalk>
              </Ink.Text>
            </>
          ) : null}

          {current.matches('ScanningItemQuantity') ? (
            <>
              <Ink.Text>
                Deposite &nbsp;
                <Ink.Chalk bold>
                  {current.context.currentPickItem!.quantity}
                </Ink.Chalk>
                &nbsp; unidad/es de
              </Ink.Text>
              <Ink.Text>
                <Ink.Chalk bold color='greenBright'>
                  {current.context.currentPickItem!.sku}
                </Ink.Chalk>
                &nbsp; - &nbsp;
                <Ink.Chalk bold color='greenBright'>
                  {current.context.currentPickItem!.product.name}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Text>
                en cubeta &nbsp;
                <Ink.Chalk bold color='greenBright'>
                  {
                    current.context.pickItemDestinyContainerLocation!.container
                      .lpn
                  }
                </Ink.Chalk>
                &nbsp; en posicion &nbsp;
                <Ink.Chalk bold>
                  {
                    current.context.pickItemDestinyContainerLocation!
                      .pickingCartPosition
                  }
                </Ink.Chalk>
              </Ink.Text>
            </>
          ) : null}

          {current.matches('FinishingTask') && (
            <>
              <Ink.Text bold>
                ¿Estas seguro que quieres terminar la Ola de Pickeo #
                {current.context.pickingWaveId}?
              </Ink.Text>
              <Ink.Box flexDirection='row' justifyContent='space-between'>
                <Ink.Button
                  style={{ width: '40%' }}
                  onClick={() => {
                    send('confirmTaskFinished');
                  }}
                  label='Si'
                />
                <Ink.Button
                  style={{ width: '40%' }}
                  onClick={() => {
                    send('cancel');
                  }}
                  label='No'
                />
              </Ink.Box>
            </>
          )}
        </Ink.Box>

        {/* body */}

        <>
          {current.hasTag('loading') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Cargando...</Ink.Text>
            </Ink.Box>
          ) : null}

          {current.hasTag('containerCreate') ? (
            <Ink.Box flexDirection='column'>
              <EnterLpn />
            </Ink.Box>
          ) : null}

          {current.hasTag('sku') ? (
            <Ink.Box flexDirection='column'>
              <ScanSKU />
            </Ink.Box>
          ) : null}

          {current.hasTag('quantity') ? (
            <Ink.Box flexDirection='column'>
              <EnterQuantity />
            </Ink.Box>
          ) : null}

          {current.hasTag('containerGet') ? (
            <Ink.Box flexDirection='column'>
              <GetContainerByLpn />
            </Ink.Box>
          ) : null}
        </>
      </Ink.Box>
    );
  }
);
