import { MachineOptions, assign } from 'xstate';
import { MiniMopPickingWaveContext } from './machine';

type MiniMopPickingWaveActionsType = MachineOptions<
  MiniMopPickingWaveContext,
  any
>['actions'];

export const miniMopPickingWaveActions: MiniMopPickingWaveActionsType = {
  assignPickingCartTypes: assign({
    pickingCartTypes: (_ctx, event) => event.data.pickingCartTypes
  }),
  assignSelectedPickingCartType: assign({
    pickingCartType: (_ctx, event) => event.data.pickingCartType
  }),
  assignPickingCart: assign({
    pickingCart: (_ctx, event) => event.data
  }),
  assignNextBranchOfficeToPrepare: assign({
    branchOfficeToPrepare: (_ctx, event) => event.data.branchOffice
  }),
  assignBranchOfficesQuantity: assign({
    branchOfficeTotal: (_ctx, event) => event.data.branchOfficeQuantity
  }),
  assignContainerLpn: assign({
    containerLpn: (_ctx, event) => event.data.lpn
  }),
  assignNextPickItem: assign({
    currentPickItem: (_ctx, event) => event.data.pickItem
  }),
  assignPickItemLocation: assign({
    pickItemLocation: (_ctx, event) => event.data.location
  }),
  assignPickingCartPosition: assign({
    pickingCartPosition: (_ctx, event) => event.data.quantity
  }),
  assignPickedQuantity: assign({
    pickedQuantity: (_ctx, event) => event.data.quantity
  }),
  assignCurrentMopPickingContainerToClose: assign({
    currentMopPickingContainerToClose: (_ctx, event) =>
      event.data.mopPickingContainer
  }),
  assignBranchOfficeCreated: assign({
    branchOfficeCreated: (ctx, _event) => ctx.branchOfficeCreated + 1
  }),
  assignPickItemDestinyLocation: assign({
    pickItemDestinyContainerLocation: (_ctx, event) =>
      event.data.pickItemDestinyLocation
  }),
  assignPositionError: assign({
    positionError: (__, ___) => 'La posicion ya se encuentra ocupada'
  }),
  clearPositionError: assign({
    positionError: (__, ___) => null
  })
};
