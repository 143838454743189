import { PickingToStagingItemStatuses } from '../PickingToStagingItem';
import { PickingToStagingItemStatus } from './PickingToStagingItemStatus';

export class PickingToStagingItemStatusMovedStatus extends PickingToStagingItemStatus {
  public displayName = 'Movido';

  public internalName = PickingToStagingItemStatuses.Moved;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
