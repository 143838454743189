import { TransportOrderStatuses } from '../TransportOrder';
import { TransportOrderStatus } from './TransportOrderStatus';

export class TransportOrderStatusCompletedStatus extends TransportOrderStatus {
  public displayName = 'Completada';

  public internalName = TransportOrderStatuses.Completed;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
