import { PutAwayItemQualityStatuses } from '../PutAwayItem';
import { PutAwayItemQualityStatus } from './PutAwayItemQualityStatus';

export class PutAwayItemQualityStatusAnomalyStatus extends PutAwayItemQualityStatus {
  public displayName = 'Anomalia';

  public internalName = PutAwayItemQualityStatuses.Anomaly;

  public statusColor = '#ffd3d3';

  public textColor = '#b90c0c';
}
