import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../scan-container/ScanContainer';
import { ScanControlDigit } from '../scan-control-digit/ScanControlDigit';
import { ScanLocation } from '../scan-location/ScanLocation';
import { MoveContainerMachine } from './MoveContainerMachine';

export const MoveContainer: FC = withActorRef(MoveContainerMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <>
        <Ink.Text>Trasladando Contenedor</Ink.Text>

        {current.matches('PrecedingTasksAvailable') ? (
          <>
            <Ink.Text>
              La tarea de mover cotenedor esta momentaneamente detenida hasta
              que se completen las tareas de Restocking asociadas al contenedor
            </Ink.Text>
            <Ink.Box flexDirection='column' paddingLeft={1}>
              <Ink.Button
                key='goBack'
                id='goBack'
                label='Volver a verificar'
                onClick={() => send('verify')}
              />
            </Ink.Box>
          </>
        ) : null}

        {current.matches('AwaitingContainerScan') ? <ScanContainer /> : null}

        {current.matches('AwaitingLocationScan') ? <ScanLocation /> : null}
        {current.matches('AwaitingCheckDigitScan') ? (
          <>
            <Ink.Text>
              Ubicación del canal:{' '}
              <Ink.Chalk greenBright>
                {current.context.requiredLocation?.name as string}
              </Ink.Chalk>
            </Ink.Text>

            <ScanControlDigit />
          </>
        ) : null}

        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}
      </>
    );
  }
);
