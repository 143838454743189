import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MidiPickingWaveContext } from './machine';

type MidiPickingWaveActions = MachineOptions<
  MidiPickingWaveContext,
  any
>['actions'];

export const midiPickingWaveActions: MidiPickingWaveActions = {
  assignSuggestedItem: assign({
    currentPickItem:  (_ctx, event) => event.data.pickItem,
    selectedLocation: (_ctx, _event) => null,
    selectedProduct:  (_ctx, _event) => null,
    selectedQuantity: (_ctx, _event) => null,
    selectedLabel:    (_ctx, _event) => null,
    reportedIncident: (_ctx, _event) => null,
    error:            (_ctx, _event) => null
  }),
  assignLocation: assign({
    selectedLocation: (_ctx, event) => event.data.location
  }),
  assignProduct: assign({
    selectedProduct: (_ctx, event) => event.data.product
  }),
  assignQuantity: assign({
    selectedQuantity: (_ctx, event) => event.data.quantity
  }),
  assignLabel: assign({
    selectedLabel: (_ctx, event) => event.data.label
  }),
  assignPendingItems: assign({
    pendingItems: (_ctx, event) => event.data.pendingItems,
    totalItems:   (_ctx, event) => event.data.totalItems
  }),
  assignInvalidProductError: assign({
    error: (_context, event) => event.data.errorView
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  }),
  assignError: assign({
    error: (_ctx, event) => getErrorView(event.data.data)
  }),
  assignPendingItemsToSort: assign({
    pendingItemsToSort: (_ctx, event) => event.data.count
  })
};
