import { Container } from '@wms/domain';
import { createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { GoToOptionsEvent, MenuItemProps } from '../../core/GenericOptions';

export interface ScanContainerContext {
  container: Container | null;
  suggestedContainer: Container | null;
  requestedContainer: Container | null;
  validContainers: Container[];
  hint: string | null;
  lpn: string | null;
  error: string | null;
}

export const DefaultScanContainerContext = {
  lpn:                '',
  error:              '',
  container:          null,
  suggestedContainer: null,
  requestedContainer: null,
  validContainers:    [],
  hint:               null
};

export const ScanContainerMachine = createMachine<ScanContainerContext>(
  {
    id:      'ScanContainerMachine',
    initial: 'AwaitingContainerScan',
    states:  {
      AwaitingContainerScan: {
        on: {
          ContainerScanned: [
            {
              cond:    'invalidLPN',
              actions: 'errorInvalidContainer',
              target:  'AwaitingContainerScan'
            },
            {
              cond:    'requestedContainerInvalid',
              actions: 'errorInvalidContainer',
              target:  'AwaitingContainerScan'
            },
            {
              actions: 'assignLPN',
              target:  'FetchingContainer'
            }
          ]
        }
      },
      FetchingContainer: {
        invoke: {
          src:    'findContainerByLpn',
          onDone: {
            actions: 'assignContainer',
            target:  'ContainerFound'
          },
          onError: {
            actions: 'assignError',
            target:  'AwaitingContainerScan'
          }
        }
      },
      ContainerFound: {
        type: 'final',
        data: ctx => ({ container: ctx.container })
      }
    },
    on: {
      goToOptions: {
        actions: 'triggerMenuScreen'
      },
      finishCloseContainer: {
        target: 'ContainerFound'
      }
    }
  },
  {
    guards: {
      ...UtilityGuards,
      invalidLPN: (ctx, event) =>
        ctx.validContainers.length !== 0 &&
        !ctx.validContainers.some(container => container.lpn === event.data.lpn)
    },
    actions: {
      ...UtilityActions,
      triggerMenuScreen: (
        ctx,
        { send, triggerMenuScreen }: GoToOptionsEvent
      ) => {
        const options: MenuItemProps[] = [];
        options.push({
          label:   'Volver a verificar lineas',
          onClick: () => send('finishCloseContainer')
        });
        triggerMenuScreen(options);
      }
    },
    services: { ...API }
  }
);
