import { SelectInputProps } from '@wms/client-core';
import React, { useCallback } from 'react';
import { Box } from './Box';
import { Button } from './Button';

export function SelectInput<V>({
  items,
  onSelect
}: SelectInputProps<V>): JSX.Element {
  const handleSelect = useCallback(
    (key: string) => {
      if (onSelect) {
        const value = items?.find(
          item => item.key?.toString() === key.toString()
        );

        if (value) {
          onSelect(value);
        }
      }
    },
    [onSelect]
  );

  return (
    <Box flexDirection='column' paddingLeft={1}>
      {items?.map(item => (
        <Button
          key={item.key}
          id={item.key}
          label={item.label}
          onClick={handleSelect}
        />
      ))}
    </Box>
  );
}
