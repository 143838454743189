import { TransferOrderStatus } from './TransferOrderStatus';

export class TransferOrderStatusClosedStatus extends TransferOrderStatus {
  public displayName = 'Cerrado';

  public internalName = 'closed';

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
