import { AuditingProcessStatuses } from '../AuditingProcess';
import { AuditingProcessStatus } from './AuditingProcessStatus';

export class AuditingProcessPendingStatus extends AuditingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = AuditingProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
