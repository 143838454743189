import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchDispatchedStatus extends OrderBatchStatus {
  public displayName = 'Despachado';

  public internalName = OrderBatchStatuses.Dispatched;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}
