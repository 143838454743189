import { StagingAuditingProcessStatuses } from '../StagingAuditingProcess';
import { StagingAuditingProcessStatus } from './StagingAuditingProcessStatus';

export class StagingAuditingProcessPendingStatus extends StagingAuditingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = StagingAuditingProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
