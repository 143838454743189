import { StockTableStatuses } from '../StockTable';
import { StockItemStatus } from './StockItemStatus';

export class StockItemStatusStorePickUpStatus extends StockItemStatus {
  public displayName = 'En Transito';

  public internalName = StockTableStatuses.StorePickUp;

  public statusColor = '#e3c5d1';
}
