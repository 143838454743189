import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusProcessingStatus extends ReceiptItemStatus {
  public displayName = 'Procesando';

  public internalName = ReceiptItemStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
