import { Container, MoveContainerToStoragePayload, Task } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import {
  DefaultWeightContainerContext,
  WeightContainerMachine
} from '../../capa-4/weight-container/WeightContainerMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface WeightContainerTaskContext {
  container: Container;
  title: string | null;
  hint: string | null;
  error: string | null;
  payload: MoveContainerToStoragePayload;
  task: Task | null;
}

export const WeightContainerTaskInitialContext = {
  container:       null,
  hint:            null,
  error:           '',
  suggestLocation: false,
  payload:         {},
  task:            null
};

const HELP = `Pesado de contenedor
  Escanee un contenedor y su peso. `;

export const WeightContainerTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<WeightContainerTaskContext>(
    {
      id:      'WeightContainerTaskMachine',
      initial: 'Initialize',
      states:  {
        Initialize: {
          entry: assign({
            container: ctx => ctx.payload?.container
          }),
          always: {
            target: 'WeightContainer'
          }
        },
        WeightContainer: {
          invoke: {
            src:  WeightContainerMachine,
            id:   WeightContainerMachine.id,
            data: ctx => ({
              ...DefaultWeightContainerContext,
              container: ctx.container,
              task:      ctx.task
            }),
            onDone: [
              {
                actions: 'assignContainer',
                target:  'FinishIfAllWeightCompleted'
              }
            ]
          }
        },
        FinishIfAllWeightCompleted: {
          invoke: {
            src:    'finishIfAllWeightCompleted',
            onDone: {
              target: 'ContainerWeighted'
            }
          }
        },
        ContainerWeighted: {
          type: 'final',
          data: ctx => ctx
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      actions: {
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API
      }
    }
  );
