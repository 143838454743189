import { MopSorterExitItemStatuses } from '../MopSorterExitItem';
import { MopSorterExitItemStatus } from './MopSorterExitItemStatus';

export class MopSorterExitItemCancelledStatus extends MopSorterExitItemStatus {
  public displayName = 'Cancelado';

  public internalName = MopSorterExitItemStatuses.Cancelled;

  public statusColor = '#b90c0c';
}
