import { PickingProcessStatus } from './PickingProcessStatus';

export class PickingProcessStatusCancelledStatus extends PickingProcessStatus {
  public displayName = 'Cancelado';

  public internalName = 'cancelled';

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
