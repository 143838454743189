import { Container, DispatchContainersV0Payload } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API } from '../../../../api/api';
import { api } from '../../../../api/utils/axios-instance';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';

type DispatchContainersContext = {
  payload: DispatchContainersV0Payload | null;
  error: string | null;
  toScanContainers: Container[];
  scannedContainers: Container[];
};

export const initialDispatchContainersContext: DispatchContainersContext = {
  payload:           null,
  error:             null,
  toScanContainers:  [],
  scannedContainers: []
};

export const DispatchContainersMachine = createMachine<
  DispatchContainersContext,
  any,
  {
    value:
      | 'CyclingContainers'
      | 'ConfirmingDispatch'
      | 'DispatchingContainers'
      | 'Done';
    context: DispatchContainersContext;
  }
>(
  {
    id:      'DispatchContainersMachine',
    initial: 'CyclingContainers',
    entry:   'initializeToScanContainers',
    states:  {
      CyclingContainers: {
        invoke: {
          src:  ScanContainerMachine,
          id:   ScanContainerMachine.id,
          data: context => ({
            ...DefaultScanContainerContext,
            hint:               'Escanee contenedor a despachar',
            suggestedContainer: context.toScanContainers[0],
            suggestedLocation:  context.toScanContainers[0].location,
            validContainers:    context.toScanContainers
          }),
          onDone: [
            {
              actions: ['scannedContainer', 'removeScannedContainer'],
              target:  'VerifyingContainers'
            }
          ]
        }
      },
      VerifyingContainers: {
        always: [
          {
            cond:   'noRemainingContainers',
            target: 'ConfirmingDispatch'
          },
          { target: 'CyclingContainers' }
        ]
      },
      ConfirmingDispatch: {
        on: {
          CONFIRM: 'DispatchingContainers'
        }
      },
      DispatchingContainers: {
        invoke: { src: 'dispatchContainers', onDone: 'Done' }
      },
      Done: {
        invoke: { src: 'completeTask' }
      }
    }
  },
  {
    guards: {
      noRemainingContainers: context => context.toScanContainers.length === 0
    },
    actions: {
      removeScannedContainer: assign({
        toScanContainers: (context, event) =>
          context.toScanContainers.filter(c => c.id !== event.data.container.id)
      }),
      scannedContainer: assign({
        scannedContainers: (context, event) => [
          ...context.scannedContainers,
          event.data.container
        ]
      }),
      initializeToScanContainers: assign({
        scannedContainers: _context => [],
        toScanContainers:  context => context.payload!.order.containers
      })
    },
    services: {
      dispatchContainers: ctx =>
        api.post(`/order/${ctx.payload?.order.id}/dispatch`, {
          scannedContainers: ctx.scannedContainers.map(c => c.lpn)
        }),
      completeTask: API.completeTask
    }
  }
);
