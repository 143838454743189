import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { Counter } from '../../../shared/Counter';
import { withActorRef } from '../../../shared/Machine';
import { MoveContainer } from '../../capa-4/move-container/MoveContainer';
import { PutAwayContainersTaskMachine } from './PutAwayContainersTaskMachine';

export const PutAwayContainersTask: FC = withActorRef(
  PutAwayContainersTaskMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const onCompleted = () => {
    console.log('AllItemsPutAway');
  };

  const menuItems = [
    {
      key:         'assign-task',
      displayName: '1. Confirmar tarea.',
      event:       'confirmPutAway'
    }
  ];
  const handleClick = useCallback((id: string) => {
    const clickedItem = menuItems.find(item => item.key === id);
    if (clickedItem) send(clickedItem.event);
  }, []);

  return (
    <Ink.Box flexDirection='column' paddingLeft={1}>
      <Ink.Header>
        <Ink.Text bold>
          {!current.context.receipt?.id ? (
            'ALMACENANDO'
          ) : (
            <>
              ALMACENANDO ORDEN DE ENTRADA:{' '}
              <Ink.Chalk greenBright>{current.context.receipt?.id}</Ink.Chalk>
            </>
          )}
        </Ink.Text>
      </Ink.Header>

      {current.matches('IteratingOverReceiptContainers') ? (
        <>
          <Ink.Text>
            <Counter
              count={
                current.context.receiptContainers !== null
                  ? {
                      total:
                        (current.context.receiptContainers?.length +
                          current.context.putAwayContainers?.length) |
                        0,
                      processed: current.context.putAwayContainers?.length
                    }
                  : null
              }
              onComplete={onCompleted}
            />{' '}
            Contenedores
          </Ink.Text>
          <MoveContainer />
        </>
      ) : null}

      {current.matches('ConfirmPutAway') ? (
        <Ink.Box flexDirection='column' paddingTop={1} paddingX={1}>
          <>
            {current.context.receiptContainers.length === 0 ? (
              <Ink.Text>
                Se han almacenado todos los contenedores de la recepción{' '}
                <Ink.Chalk>{current.context?.receipt?.id || ''}</Ink.Chalk>.
              </Ink.Text>
            ) : (
              <Ink.Text>
                Se han almacenado{' '}
                <Ink.Chalk greenBright>
                  {current.context?.putAwayContainers?.length}
                </Ink.Chalk>
                /
                <Ink.Chalk greenBright>
                  {current.context?.receiptContainers?.length || 0}
                </Ink.Chalk>{' '}
                contenedores de la recepción{' '}
                <Ink.Chalk greenBright>
                  {current.context?.receipt?.id || ''}
                </Ink.Chalk>
                .
              </Ink.Text>
            )}

            <Ink.Box paddingY={1}>
              <Ink.Text>¿Confirmar Verificación?</Ink.Text>
              {/* {state.context.error && ( */}
              {/*   <Ink.Text color='redBright'>{state.context.error}</Ink.Text> */}
              {/* )} */}
            </Ink.Box>

            <Ink.Box flexDirection='column'>
              {menuItems.map(item => (
                <Ink.Button
                  key={item.key}
                  id={item.key}
                  label={item.displayName}
                  onClick={handleClick}
                />
              ))}
            </Ink.Box>
          </>
        </Ink.Box>
      ) : null}

      {current.matches('Initializing') ? (
        <Ink.Text>Cargando...</Ink.Text>
      ) : null}
    </Ink.Box>
  );
});
