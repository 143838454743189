import { Container, ContainerType, Location } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../scan-location/ScanLocationMachine';

export interface CreateContainerContext {
  partialContainerBody: Partial<Container>;
  lpn: string;
  hint: string;
  location: Location;
  createdContainer: Container | null;
  requiredContainers: [];
  error: string;
  isUtilities: boolean;
  containerType: ContainerType;
  isAnomaly: boolean;
  receiptId?: number;
  orderId?: number;
  gridNumber?: number;
  pickingWaveId?: number;
  parentId: number | null;
  containerTypeList: ContainerType[];
  forceContainerType: boolean;
}

export const createContainerMachineInitialContext: CreateContainerContext = {
  partialContainerBody: {},
  isUtilities:          true,
  lpn:                  '',
  requiredContainers:   [],
  hint:                 'Creando Contenedor',
  location:             { name: '' } as Location,
  createdContainer:     null,
  error:                '',
  containerType:        {} as ContainerType,
  isAnomaly:            false,
  parentId:             null,
  containerTypeList:    [] as ContainerType[],

  forceContainerType: false
};

export type CreateContainerEvent =
  | { type: 'ContainerScanned'; createdContainer: Container | null }
  | { type: 'ContainerValidated' };

export const CreateContainerMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGEBOYCGAXMyD2AdlhgJYFioB0YRFZUAMgAoByAxAMYAWGBMA2gAYAuolAAHPLBJYShMSAAeiACwBOAMyUNglQDYAHCo0GAjGoDsBg3oA0IAJ6INhyvoMWXagKyDBe0z0AXyD7NEwcfCJScioaHFR6ZnZYAFcAIwBbGSFRJBBJaVl5fOUEPQ1vSlNvPQAmFQNBUwNvHxV7JwQVCzVKNX8GurqNGsqQsPRsXEJiMgpqWkS+ZLY0rJzTPIkpGTkCBTKKqpr6xubW9s7nEcoztQM1B41zlQmQcOmoudjKADMwFhuABRRQkWCyPhsCCEMCUMgANzwAGs4Z9IrMYgsAUCuKDwZCoAhEXgONh9rlcgpCnsSqAynU9HpKN53BZvE06qZmt4LNcEBzmXU1IFGjVzIJNO90TNovMqDiQWCIfQ2BRUHgqOIADbYP6azKUGXfLEKwFKgn0YkEJFk4oESkiam7e2HRCM5mswzszncmp8xyqFSCNxPJ6CKzeGrGbzSqYYuW-WBkggMUnkwjQ2Hwm0otHx2U-BbJ3hpu37a22jMOkRU-I012lRDclR1Nze2p6CxWGx2QMIYZabyjBpqYUPUwNDRxiKF02UEup9P2rPkHNI1FGgsm+ULlNl6uV5cU2tbZ1FfZuhAtttszvd6xM-mjT2aCwqXlNZp6N6hD7bzFd0VLh6B3WIABUHHEOBVzhElN2NQDfmA0CkIoSDoNgI9y0IR1tgKF1Lyba82iqAZ6k8QQbFGHx+RUYwWRUQImXaYY6gMGcvjQqhYDAbUwA4LAwPQqCwE4Hg+DAOsdgvOklGbGwLDuRp9AsCMKhsDp+w0CxTBZFtBHY4xPDUJlOITIseL4gShO4jCxPWbIsGkgjZIOYi6kMrRJ18djPzMOiLDbCNhS5NpPE839JlnYSqARDBtRICByShGE13g-MYu4yh4sS5LCWw6s8PPWl3Ppd16KFF5g2sQRvA5QJ+WGZlBBHdRxzMKdzLnXdcqSlKoDVVANS1XUsH1VBDUQxMFj6-KrRJHCa2EFyGyI8qBy86o6l81oe1MfkzBZPw-G5cKW2nd4CDwCA4AUabLJKxsNoAWj7LoXrIsNvp+qU-we+d4joFZWCe9b5O6CxmVMjweiMdkTDUOi9D6YdJxcYy-LqbrYv+c08WVQkwbkspW35XkQw0YZapcQy-A4-6AJmnj92POS1pJxAPD6F4dKjTReR-Axnz8O5R27B4DB0KwceylC+Fi+z4HrQjOevaqxd8UwemHH9vDqOimMoAxhk0DQniZEwov-LLmYXazBMV0TibKiGWmGO4PGHRkodMc2Df7Ho215YUvJ0Uyqdlu25oGl2rzYvSOw0HRWsaJqGm0ScP3cSXfGtgHdw4biZQgOOPL0Wo7lqdiqKjZokf7No+lOIy0dMHsQhCIA */
  createMachine<CreateContainerContext>(
    {
      id:      'CreateContainer',
      initial: 'enteringLPN',
      states:  {
        enteringLPN: {
          on: {
            change: {
              target:  'enteringLPN',
              actions: assign({
                lpn: (_ctx, evt) => evt.lpn
              })
            },
            submit: [
              {
                target: 'fetchingContainerTypes',
                cond:   'validLocation'
              },
              {
                target: 'scanLocation'
              }
            ]
          }
        },

        // TODO fetch existing container and validate if it's empty. Move to location if it is specified.
        fetchExisting: {
          invoke: {
            src:    'createContainer',
            onDone: {
              target:  'containerCreated',
              actions: ['assignCreatedContainer']
            },
            onError: {
              target:  'enteringLPN',
              actions: ['assignError']
            }
          }
        },

        scanLocation: {
          invoke: {
            src:  ScanLocationMachine,
            id:   ScanLocationMachine.id,
            data: ctx => ({
              ...DefaultScanLocationContext,
              hint: ctx.gridNumber
                ? `Escanee una ubicación de Grid ${ctx.gridNumber}:`
                : null,
              suggestLocation: false,
              gridNumber:      ctx.gridNumber
            }),
            onDone: {
              actions: 'assignLocation',
              target:  'fetchingContainerTypes'
            }
          }
        },
        fetchingContainerTypes: {
          always: {
            cond:   'forceContainerType',
            target: 'validating'
          },
          invoke: {
            src:    'fetchContainerTypes',
            onDone: {
              actions: 'assignContainerTypes',
              target:  'selectContainerType'
            }
          }
        },
        selectContainerType: {
          on: {
            change: {
              target:  'selectContainerType',
              actions: assign({
                containerType: (_ctx, evt) => evt.containerType
              })
            },
            submit: {
              target: 'validating'
            }
          }
        },

        validating: {
          invoke: {
            src:    'createContainer',
            onDone: {
              actions: ['assignCreatedContainer'],
              target:  'containerCreated'
            },
            onError: {
              target:  'enteringLPN',
              actions: ['assignError']
            }
          }
        },

        containerCreated: {
          type: 'final',
          data: ctx => ({
            container: ctx.createdContainer
          })
        }
      }
    },
    {
      guards: {
        validLocation:      (ctx): boolean => !!ctx.location?.name,
        forceContainerType: ctx => !!ctx.forceContainerType,
        taskIsCas:          ctx => !!ctx.orderId
      },
      actions: {
        ...UtilityActions,
        assignError: assign({
          error: (_context, event) =>
            event.data?.response?.data?.errorView ||
            event.data?.response?.data?.message ||
            (event as any).data.toString() ||
            'No se pudo crear contenedor'
        }),
        assignCreatedContainer: assign({
          createdContainer: (_context, event) => (event as any).data.container
        }),
        assignContainerTypes: assign({
          containerTypeList: (_context, event) =>
            (event as any).data.containerTypes
        })
      },
      services: {
        ...API
      }
    }
  );
