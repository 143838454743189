import { MachineOptions, assign } from 'xstate';
import { MiniPackingWaveContext } from './machine';

type MiniPackingWaveActions = MachineOptions<
  MiniPackingWaveContext,
  any
>['actions'];

export const miniPackingWaveActions: MiniPackingWaveActions = {
  assignProduct: assign({
    product: (_ctx, event) => event.data.product
  }),
  assignPackingItem: assign({
    currentPackingItem: (_ctx, event) => event.data.packingItem
  }),
  assignSuggestedPackageType: assign({
    suggestedPackageType: (_ctx, event) => event.data.packType
  }),
  assignPackageType: assign({
    selectedPackageType: (_ctx, event) => event.data.packType
  }),
  assignLabel: assign({
    label: (_ctx, event) => event.data.label
  }),
  assignOtherPackageType: assign({
    otherPackageTypes: (_ctx, event) => event.data.packTypes
  }),
  assignReason: assign({
    reason: (_ctx, event) => event.data.reason
  }),
  assignSKUError: assign({
    error: (_ctx, event) => event.data.response.data.errorView
  }),
  assignLabelError: assign({
    error: (_ctx, event) => event.data.response.data.errorView
  }),
  clearPackingCtx: assign({
    currentPackingItem:   (_, __) => null,
    label:                (_, __) => null,
    product:              (_, __) => null,
    selectedPackageType:  (_, __) => null,
    suggestedPackageType: (_, __) => null,
    error:                (_, __) => null
  }),
  clearErrorCtx: assign({
    error: (_, __) => null
  })
};
