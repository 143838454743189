import { AuditingItem, ContainerType, Product, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import {
  DefaultEnterLpnContext,
  EnterLpnMachine
} from '../../capa-4/enter-lpn/EnterLpnMachine';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getAuditingWaveTargetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-auditing-wave-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getAuditingWaveProductBySKU } from '../../layer-4/product/get-product-by-sku/fetchers/get-auditingWave-product-by-sku';
import {
  GetProductBySKUMachine,
  GetProductBySKUMachineId
} from '../../layer-4/product/get-product-by-sku/machine';
import { miniMopAuditingWaveActions } from './actions';
import { miniMopAuditingWaveGuards } from './guards';
import { miniMopAuditingWaveServices } from './services';

export interface MiniMopAuditingWaveContext {
  // System
  task: Task;
  locationId: number;
  auditingWaveId: number;

  // Interior Container
  interiorContainerlpn: string | null;
  interiorContainerSuggestedContainerType: ContainerType | null;
  closedContainerLpn: string | null;

  auditingItems: AuditingItem[] | null;

  currentProduct: Product | null;
}

export const DefaultMiniMopAuditingWaveContext: MiniMopAuditingWaveContext = {
  task:           {} as Task,
  locationId:     -1,
  auditingWaveId: -1,

  interiorContainerlpn:                    null,
  interiorContainerSuggestedContainerType: null,
  closedContainerLpn:                      null,

  auditingItems: null,

  currentProduct: null
};

export const MiniMopAuditingWaveMachine = createMachine(
  {
    id:                         'MiniMopAuditingWave',
    predictableActionArguments: true,
    schema:                     {
      context: {} as MiniMopAuditingWaveContext
    },
    initial: 'CheckingIfIsCity',
    states:  {
      CheckingIfIsCity: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfIsCity',
          onDone: [
            {
              cond:   'isCity',
              target: 'FetchingPendingAuditingItems'
            },
            {
              target: 'CreatingInteriorContainer'
            }
          ]
        }
      },
      CreatingInteriorContainer: {
        tags:    ['lpn'],
        initial: 'ScanningInteriorLPN',
        states:  {
          ScanningInteriorLPN: {
            invoke: {
              id:   EnterLpnMachine.id,
              src:  EnterLpnMachine,
              data: {
                ...DefaultEnterLpnContext,
                hint: 'Escanee nuevo contenedor a crear'
              },
              onDone: {
                actions: 'assignLpn',
                target:  'FetchingSuggestedContainerType'
              }
            }
          },
          FetchingSuggestedContainerType: {
            tags:   ['loading'],
            invoke: {
              src:    'fetchSuggestedContainerType',
              onDone: {
                actions: 'assignSuggestedContainerType',
                target:  'StickingLabel'
              }
            }
          },
          StickingLabel: {
            on: {
              confirm: 'CreatingTargetContainer'
            }
          },
          CreatingTargetContainer: {
            tags:   ['loading'],
            invoke: {
              id:   SendToParentMachine.id,
              src:  SendToParentMachine,
              data: ctx => {
                return {
                  task:    ctx.task,
                  type:    'MiniMopCreateTargetContainer',
                  payload: {
                    locationId:      ctx.locationId,
                    auditingWaveId:  ctx.auditingWaveId,
                    lpn:             ctx.interiorContainerlpn,
                    containerTypeId:
                      ctx.interiorContainerSuggestedContainerType?.id
                  }
                };
              },
              onDone: {
                target: 'InteriorContainerDone'
              }
            }
          },
          InteriorContainerDone: {
            type: 'final'
          }
        },
        onDone: 'FetchingPendingAuditingItems'
      },

      FetchingPendingAuditingItems: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchPendingAuditingItems',
          onDone: [
            {
              actions: 'assignAuditingItems',
              target:  'AuditingItem'
            }
          ]
        }
      },
      AuditingItem: {
        initial: 'ScanningSKU',
        states:  {
          ScanningSKU: {
            tags:   ['sku'],
            invoke: {
              id:  GetProductBySKUMachineId,
              src: ctx =>
                GetProductBySKUMachine(
                  'Escanear SKU de un producto sin terminar de auditar',
                  getAuditingWaveProductBySKU(
                    'Ocurrió un error, por favor reintente más tarde.',
                    ctx.auditingWaveId
                  )
                ),
              onDone: {
                actions: 'assignCurrentProduct',
                target:  'ScanningContainer'
              }
            },
            on: {
              close: {
                target: '#MiniMopAuditingWave.ConfirmingContainerClose'
              }
            }
          },
          ScanningContainer: {
            tags:   ['container'],
            invoke: {
              id:  GetContainerByLpnMachineId,
              src: ctx =>
                GetContainerByLpnMachine(
                  'Confirme escaneando la etiqueta del contenedor',
                  getAuditingWaveTargetContainerByLpn(
                    'Ocurrió un error, por favor reintente más tarde.',
                    ctx.auditingWaveId
                  )
                ),
              onDone: {
                target: 'MarkingProductAsAudited'
              }
            }
          },

          MarkingProductAsAudited: {
            tags:   ['loading'],
            invoke: {
              id:   SendToParentMachine.id,
              src:  SendToParentMachine,
              data: ctx => {
                return {
                  task:    ctx.task,
                  type:    'MiniMopAddAuditedProduct',
                  payload: {
                    auditingWaveId: ctx.auditingWaveId,
                    productId:      ctx.currentProduct?.id
                  }
                };
              },
              onDone: {
                actions: 'postAuditedCleanup',
                target:  'AuditingItemDone'
              }
            }
          },
          AuditingItemDone: {
            type: 'final'
          }
        },
        onDone: 'FetchingPendingAuditingItems'
      },
      ConfirmingContainerClose: {
        tags:   ['lpn'],
        invoke: {
          id:   EnterLpnMachine.id,
          src:  EnterLpnMachine,
          data: {
            ...DefaultEnterLpnContext,
            hint: 'Escanee contenedor y deposite en el sorter'
          },
          onDone: {
            actions: 'assignClosedLpn',
            target:  'ClosingContainer'
          }
        },
        on: {
          cancel: 'FetchingPendingAuditingItems'
        }
      },
      ClosingContainer: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniMopAuditingWaveCompletedAndNotifyToSorterExitTrack',
              payload: {
                auditingWaveId: ctx.auditingWaveId,
                label:          ctx.closedContainerLpn
              }
            };
          },
          onDone: 'Done'
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   miniMopAuditingWaveGuards,
    actions:  miniMopAuditingWaveActions,
    services: miniMopAuditingWaveServices
  }
);
