import { Container, Location, Product, ProductItemName } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { ProductItem } from '../../../shared/utils/ProductItem';

export interface ScanItemContext {
  suggestedLocation: Location | null;
  requiredLocation: Location | null;

  // Any of these 3.
  requestedItem: ProductItem | null;
  suggestedItem: ProductItem | null;
  validItems: ProductItem[];

  // Search will be narrowing down from possible items until only one is left.
  possibleItems: ProductItem[];
  item: ProductItem | null;
  sku: string;

  type: string;

  hint: string;

  filters: any;
  error: string;

  product: Product | null;
  quantity: number;

  lotNumber: string | null;

  container: Container | null;
  location: Location | null;

  status: string;
  inventoryCountingItemId: number | null;

  inventoryItemStatusFilter: string | null;
  skipQuantityCheck: boolean;

  locationName?: string | null;
  isBulkItem?: boolean | null;
}

export const DefaultScanItemContext = {
  // Any of these 4
  suggestedLocation: null,
  requiredLocation:  null,

  // Any of these 3.
  requestedItem: null,
  suggestedItem: null,
  validItems:    [],

  type: ProductItemName.InventoryItem,

  // Search will be narrowing down from possible items until only one is left.
  possibleItems: [],
  item:          null,

  hint: 'Escanee Producto',
  sku:  '',

  filters:        null,
  error:          '',
  quantity:       0,
  scannedProduct: null,

  inventoryCountingItemId: null,
  lotNumber:               null,
  container:               null,

  inventoryItemStatusFilter: null,
  skipQuantityCheck:         false,
  status:                    null,
  locationName:              null,
  isBulkItem:                null
};

export const ScanItemMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGUDGBDAdgSQC5gFsA6AS0xNxPQBsSAvMAYkVAAcB7WCk9zFkAB6IALAE4ArEQBswgOyipogIwAOYQGYADEuEAaEAE9EKzbKKzxS2Us0AmYeK2iAvs-1oseQkXQB3dNyYUABC6ABOqOwQYB6YjKxhUQCuqLixmJD8HFyUvPxCCFq2ROriouqyKuImtrIO4vpGhXJEmm122lay6uq2ru4YOPjEsIMAigAqAJqMY7gGAKKY+GGZSCDZ3HnrBU6tsqYq8rJS9rKyjYgAtD1EwiqiylJVj0qO-SCxXsQAZmC4qAAFmQoN8AGKJAjYTAANzAy3YYQMjAgvDApFh7AA1uivsMiH8AcCguDIdC4QikQgyDD2BhcpgANqaAC6WU4Wz4O0QFSkRFU9xUSnKZXktiklwQwtErXaNlsthFZT6bk+g2+BP+QJBpPYUNh8NwiORYDCiTCRFY1HQuB+iOIeO8hO1JOGEL15MNxupmPpPCZrPZOX9+R5dhKZQqVRqdXEDUMiCkmhUsrab3UwmEmh6sg+juICWSqTB7CSmAgzHWmwZoYQKlsmiI9aFFUqUkUckltlURHEcpM1hUUmFudV+aISxWABl2LhGNRZ5PTWs2Bya9y6+L+aJZOLHNm5L1JaVe1JebvxHIDy4PpgonB+OOyNwaPQwEHObXxSnu+KpLvuhOaxJWURsdykS9xEqYRh3uPN1XxPwAkoIJQgie9Yg-ddQAKWDzCFcVs0eNR4yaU5hF7OVqjjPs3ngzx8VGLBJimLCQw3HQf1sExRGzNRdwUdRJRkXsbE0KCFSgpR-yUeihidLViVBN0yQNSkmlXYNthw64xKIbjVHkYUYK0I5j00PlrDkCwLFOPsVQGBjvELCAUlwEsywgNjtMERMKiIXjTggywHAVUQuzqVM7HuZQNFsd4xwQ7wlzCGdcG8rkdIQCCfz7bQswg9QZFsSUdEkPK7G4+ph16OSNXBUtywy2sbGUflBzUBtiu0UrLyi6T62k0xSjq4Zmo3K4lHsfShSOZRRBM5MLgTBBJvEu4HlipNFHUHcVVcIA */
  createMachine<ScanItemContext>(
    {
      id:      'ScanItem',
      initial: 'initialize',
      states:  {
        initialize: {
          always: {
            target: 'awaitingBarcodeScan'
          }
        },
        awaitingBarcodeScan: {
          on: {
            productScanned: [
              {
                actions: ['assignSku', 'clearError'],
                cond:    'requestedItemValidated',
                target:  'fetchingProduct'
              },
              {
                actions:  'errorInvalidProduct',
                cond:     'requestedItemInvalid',
                target:   'awaitingBarcodeScan',
                internal: false
              },
              {
                actions: ['assignSku', 'clearError'],
                target:  'fetchingProduct'
              }
            ]
          }
        },
        fetchingProduct: {
          invoke: {
            src:    'findProduct',
            onDone: {
              actions: 'assignProduct',
              target:  'productFound'
            },
            onError: {
              actions: 'assignError',
              target:  'awaitingBarcodeScan'
            }
          }
        },
        productFound: {
          always: [
            {
              cond:   'productIsBultoCiego',
              target: 'fetchItem'
            },
            {
              cond:   'isLotControlled',
              target: 'EnterLot'
            },
            {
              target: 'scanQTY'
            }
          ]
        },
        EnterLot: {
          on: {
            lotEntered: {
              actions: assign({
                lotNumber: (_ctx, evt) => evt.data.lot
              }),
              target: 'scanQTY'
            }
          }
        },
        scanQTY: {
          on: {
            QtyEntered: {
              actions: ['assignQuantity'],
              target:  'validateQuantity'
            }
          }
        },
        validateQuantity: {
          always: [
            {
              actions: ['assignQuantityError'],
              cond:    'quantityHigherThanSuggested',
              target:  'scanQTY'
            },
            {
              target: 'fetchItem'
            }
          ]
        },
        fetchItem: {
          invoke: {
            src:    'findItem',
            onDone: [
              {
                cond:    'itemsNotNull',
                actions: assign({
                  possibleItems: (context, event) => event.data.items
                }),
                target: 'SelectItem'
              },
              {
                actions: 'assignItem',
                target:  'ItemFound'
              }
            ],
            onError: {
              actions: 'assignError',
              target:  'awaitingBarcodeScan'
            }
          }
        },

        SelectItem: {
          on: {
            select: {
              actions: assign({
                item: (ctx, evt) => evt.data.item
              }),
              target: 'ItemFound'
            }
          }
        },

        ItemFound: {
          type:  'final',
          entry: 'assignQuantityToCurrentItem',
          data:  {
            item: (context: ScanItemContext) => {
              return {
                ...context.item,
                availQty:    context.item?.quantity,
                quantity:    context.quantity,
                reqQuantity: context.quantity,
                sku:         context.sku
              };
            },
            quantity:  (context: ScanItemContext) => context.item?.quantity,
            reqQty:    (context: ScanItemContext) => context.quantity,
            sku:       (context: ScanItemContext) => context.sku,
            lotNumber: (context: ScanItemContext) => context.lotNumber
          }
        }
      }
    },
    {
      guards: {
        quantityHigherThanSuggested: ctx => {
          if (ctx.requestedItem && ctx.requestedItem.quantity && ctx.quantity) {
            return ctx.requestedItem.quantity < ctx.quantity;
          }
          return false;
        },
        isLotControlled: ctx => {
          return !!ctx?.product?.lotControl;
        },
        itemsNotNull: (ctx, evt) => evt.data.items && evt.data.items.length > 1,
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions,
        assignProduct: assign({
          sku:     (_ctx, { data: { product } }) => product.sku,
          product: (_ctx, { data: { product } }): Product => product
        }),
        assignQuantityError: assign({
          error: 'La cantidad no puede ser mayor a la solicitada'
        }),
        assignQuantityToCurrentItem: assign({
          item: context => {
            if (context.item) {
              context.item.quantity = context.quantity;
            }
            return context.item;
          }
        }),
        assignItem: assign({
          item: (_context, event) => {
            return event.data.items[0];
          }
        })
      },
      services: { ...API }
    }
  );
