import Ink from '@ink';
import { InventoryItem } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { PlaceItemInContainer } from '../../capa-4/place-item-in-container/PlaceItemInContainer';
import { ScanItem } from '../../capa-4/scan-item/ScanItem';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { PickLineMachine } from './PickLineMachine';

export const PickLine: FC = withActorRef(PickLineMachine)(({ actorRef }) => {
  const [current] = useActor(actorRef);

  return (
    <Ink.Box flexDirection='column' paddingTop={1}>
      {current.matches('initializing') ? (
        <Ink.Text>Cargando...</Ink.Text>
      ) : (
        <>
          {current.context.location ? (
            <Ink.Text>
              UB.:{' '}
              <Ink.Chalk bold greenBright>
                {current.context.location.name || 'no se encontró'}
              </Ink.Chalk>
            </Ink.Text>
          ) : null}

          {current.context.currentItem ? (
            <>
              <Ink.Text inline>
                Traspase{' '}
                <Ink.Chalk bold greenBright>
                  {current.context.currentItem.quantity || 0}
                </Ink.Chalk>{' '}
                de{' '}
                <Ink.Text bold color='greenBright' inline>
                  SKU {current.context.currentItem?.sku || ''}
                </Ink.Text>{' '}
                al contenedor de picking
              </Ink.Text>

              <Ink.Text bold color='greenBright' inline>
                {current.context.currentItem?.product?.name}
              </Ink.Text>

              <Ink.Text inline>
                UBI.:{' '}
                <Ink.Chalk bold greenBright>
                  {
                    (current.context.currentItem as InventoryItem)?.location
                      ?.name
                  }
                </Ink.Chalk>
              </Ink.Text>
            </>
          ) : null}
        </>
      )}
      <>
        {current.matches('scanLocation') ? (
          <>
            <ScanLocation />
          </>
        ) : null}

        {current.matches('scanItem') ? (
          <>
            <ScanItem />
          </>
        ) : null}

        {current.matches('placeItemInPickingContainer') ? (
          <PlaceItemInContainer />
        ) : null}
      </>
    </Ink.Box>
  );
});
