import { MachineOptions, assign } from 'xstate';
import { MiniMopAuditingWaveContext } from './machine';

type MiniMopAuditingWaveActions = MachineOptions<
  MiniMopAuditingWaveContext,
  any
>['actions'];

export const miniMopAuditingWaveActions: MiniMopAuditingWaveActions = {
  assignLpn: assign({
    interiorContainerlpn: (_, event) => event.data.lpn
  }),
  assignClosedLpn: assign({
    closedContainerLpn: (_, event) => event.data.lpn
  }),
  assignSuggestedContainerType: assign({
    interiorContainerSuggestedContainerType: (_, event) =>
      event.data.containerType
  }),
  assignAuditingItems: assign({
    auditingItems: (_, event) => event.data.auditingItems
  }),
  assignCurrentProduct: assign({
    currentProduct: (_, event) => event.data
  }),
  postAuditedCleanup: assign({
    currentProduct: (_, __) => null,
    auditingItems:  (_, __) => null
  })
};
