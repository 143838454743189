import { ConsolidateContainersItemStatuses } from '../ConsolidateContainersItem';
import { ConsolidateContainersItemStatus } from './ConsolidateContainersItemStatus';

export class ConsolidateContainersItemStatusDraft extends ConsolidateContainersItemStatus {
  public displayName = 'Borrador';

  public internalName = ConsolidateContainersItemStatuses.Draft;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
