import Ink from '@ink';
import { Task, TaskTypes } from '@wms/domain';
import React, { FC, useCallback } from 'react';

interface MenuProps {
  send: (any: string, any1: any) => void;
}

const menuItems = [
  {
    key:         'create-container',
    displayName: 'Crear Contenedor',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.CreateContainersV0 } as Task }
  },
  {
    key:         'create-item',
    displayName: 'Crear Ítem',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.CreateItem } as Task }
  },
  {
    key:         'move-containers',
    displayName: 'Trasladar contenedor',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.MoveContainerToStorage } as Task }
  },
  {
    key:         'move-items',
    displayName: 'Trasladar Productos',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.MoveItemsV0 } as Task }
  },
  {
    key:         'agregar-items',
    displayName: 'Agregar Ítems a Contenedor',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.AddItemsToContainer } as Task }
  },
  {
    key:         'remove-items-from-container',
    displayName: 'Sacar ítems de contenedor',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.RemoveItemsFromContainer } as Task }
  },
  /* {
    key:         'consolidate-containers',
    displayName: 'Consolidar Contenedores',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.ConsolidateContainersV0 } as Task }
  },*/
  /* {
    key:         'restock-inventory',
    displayName: 'Restockear Producto',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.RestockInventoryV0 } as Task }
  },*/
  {
    key:         'restocking-utility',
    displayName: 'Restockear Item',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.RestockingUtility } as Task }
  },
  {
    key:         'view-container-detail',
    displayName: 'Ver Detalle Contenedor',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.viewContainerDetail } }
  },
  {
    key:         'view-product-detail',
    displayName: 'Buscar producto',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.ViewProductDetail } }
  },
  {
    key:         'view-location-detail',
    displayName: 'Ver Detalle ubicación',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.viewLocationDetail } }
  },
  {
    key:         'change-item-status',
    displayName: 'Cambiar estado de ítem',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.ChangeItemStatus } }
  },
  // {
  //   key:         'control-inventory',
  //   displayName: 'Controlar inventario',
  //   event:       'taskSelected',
  //   payload:     { task: { type: TaskTypes.InventoryCounting } as Task }
  // },
  {
    key:         'mark-as-anomaly',
    displayName: 'Marcar como anomalía',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.MarkItemAsAnomaly } }
  },
  {
    key:         'mark-as-anomaly-task',
    displayName: 'Marcar como anomalía en proceso',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.MarkAsAnomalyTask } }
  },
  {
    key:         'item-details',
    displayName: 'Ver Detalle Ítem',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.ItemDetails } }
  },
  {
    key:         'manage-printer',
    displayName: 'Administrar impresora',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.ManagePrinter } }
  },
  {
    key:         'test-printer',
    displayName: 'Probar impresora',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.TestPrinter } }
  },
  {
    key:         'frac-storage',
    displayName: 'Almacenar Producto de Granel',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.FracStorage } }
  },
  { key: 'back', displayName: 'ATRÁS', event: 'Back', payload: {} }
] as const;

export const InventoryManagementMenu: FC<MenuProps> = ({ send }) => {
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(({ key }) => key === id);

    if (item) {
      send(item.event, item.payload);
    }
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item => (
          <Ink.Button
            key={item.key}
            id={item.key}
            label={item.displayName}
            onClick={handleClick}
          />
        ))}
      </Ink.Box>
    </Ink.Box>
  );
};

InventoryManagementMenu.displayName = 'Menu';
