import { ConsolidateContainersItemStatuses } from '../ConsolidateContainersItem';
import { ConsolidateContainersItemStatus } from './ConsolidateContainersItemStatus';

export class ConsolidateContainersItemStatusMoving extends ConsolidateContainersItemStatus {
  public displayName = 'Trasladando';

  public internalName = ConsolidateContainersItemStatuses.Moving;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
