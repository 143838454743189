import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditingWave } from './AuditingWave';
import { OrderBatch } from './OrderBatch';
import {
  AuditingProcessCancelledStatus,
  AuditingProcessCompletedStatus,
  AuditingProcessPendingStatus,
  AuditingProcessProcessingStatus,
  AuditingProcessStatus
} from './auditing-process-status';

export const AuditingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class AuditingProcess {
  @PrimaryKey()
  id: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Status({
    [AuditingProcessStatuses.Pending]:    AuditingProcessPendingStatus,
    [AuditingProcessStatuses.Processing]: AuditingProcessProcessingStatus,
    [AuditingProcessStatuses.Completed]:  AuditingProcessCompletedStatus,
    [AuditingProcessStatuses.Cancelled]:  AuditingProcessCancelledStatus
  })
  status: AuditingProcessStatus;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(() => AuditingWave, 'auditingProcessId')
  auditingWaves: AuditingWave[];
}
