import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { DownloadContainerTaskMachine } from './DownloadContainerTaskMachine';

export const DownloadContainerTask: FC = withActorRef(
  DownloadContainerTaskMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const menuItems = [
    {
      key:         'confirmar',
      displayName: 'Confirmar',
      event:       'confirm'
    },
    {
      key:         'back',
      displayName: 'Volver a escanear contenedor',
      event:       'back'
    }
  ];
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(menuItem => menuItem.key === id);
    if (item) send(item.event);
  }, []);

  return (
    <Ink.Box flexDirection='column' paddingLeft={1}>
      <Ink.Box>
        <Ink.Header>
          <Ink.Text bold>
            {!current.context.hint ? (
              'BAJADA DE CONTENEDOR'
            ) : (
              <>
                BAJADA DE CONTENEDOR:{' '}
                <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
              </>
            )}
          </Ink.Text>
        </Ink.Header>
      </Ink.Box>

      {current.matches('ScanContainer') ? (
        <Ink.Box flexDirection='column'>
          <Ink.Text>
            Escanee contenedor{' '}
            <Ink.Chalk greenBright>
              {current.context.containerLpn || ''}
            </Ink.Chalk>{' '}
            en ubicación{' '}
            <Ink.Chalk greenBright>
              {current.context.locationName || ''}
            </Ink.Chalk>{' '}
            y descargar al pasillo
          </Ink.Text>
          <ScanContainer />
        </Ink.Box>
      ) : null}
      {current.matches('Confirm') ? (
        <>
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>
              Confirmar bajada de contenedor{' '}
              <Ink.Chalk greenBright>
                {current.context.containerLpn || ''}
              </Ink.Chalk>{' '}
              al pasillo
            </Ink.Text>
            <Ink.Box flexDirection='column' paddingLeft={1}>
              {menuItems.map(item => (
                <Ink.Button
                  key={item.key}
                  id={item.key}
                  label={item.displayName}
                  onClick={handleClick}
                />
              ))}
            </Ink.Box>
          </Ink.Box>
        </>
      ) : null}

      {current.matches('Initializing') ? (
        <Ink.Text>Cargando...</Ink.Text>
      ) : null}
    </Ink.Box>
  );
});
