import Ink from '@ink';
import { AnomalyTypes } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { CreateAnomaly } from '../create-anomaly/CreateAnomalyTask';
import { CreateContainer } from '../create-container/CreateContainer';
import { MoveContainer } from '../move-container/MoveContainer';
import { TransferItem } from '../transfer-item/TransferItem';
import { MarkAsIncidentMachine } from './mark-as-incident.machine';

export const MarkAsIncident: FC = withActorRef(MarkAsIncidentMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);
    const anomalyTypes: Array<{
      key: AnomalyTypes;
      displayName: string;
      event: 'MARK_AS_DAMAGED' | 'MARK_AS_MISSING';
    }> = [
      {
        key:         AnomalyTypes.DAMAGED,
        displayName: 'Marcar como Dañado.',
        event:       'MARK_AS_DAMAGED'
      },
      {
        key:         AnomalyTypes.MISSING,
        displayName: 'Marcar como Faltante.',
        event:       'MARK_AS_MISSING'
      }
    ];

    const handleClick = useCallback((id: string) => {
      const item = anomalyTypes.find(anomalyType => anomalyType.key === id);
      if (item) send({ type: item.event, data: { anomalyType: item.key } });
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.hint ? (
                'MARCANDO INCIDENCIA'
              ) : (
                <>
                  MARCANDO INCIDENCIA:{' '}
                  <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>
        {current.matches('Initial') ? (
          <>
            <Ink.Box flexDirection='column'>
              {anomalyTypes.map(item => (
                <Ink.Button
                  key={item.key}
                  id={item.key}
                  label={item.displayName}
                  onClick={handleClick}
                />
              ))}
            </Ink.Box>
            <Ink.Box flexDirection='column'>
              <Ink.Button
                key='back'
                id='back'
                label='Volver.'
                onClick={() => send({ type: 'BACK' })}
              />
            </Ink.Box>
          </>
        ) : null}

        {current.matches('CreateContainer') ? <CreateContainer /> : null}

        {current.matches('SelectAnomalyContainer') ? (
          <>
            <Ink.Text>Escoger contenedor de de incidencia</Ink.Text>

            {current.context.anomalyContainers.map(container => (
              <Ink.Button
                key={container.id.toString()}
                id={container.id.toString()}
                label={container.lpn}
                onClick={() => send({ type: 'SELECT', data: { container } })}
              />
            ))}

            <Ink.Button
              key='create'
              id='create'
              label='Crear nuevo contenedor de incidencia'
              onClick={() => send('CREATE')}
            />
          </>
        ) : null}

        {current.matches('CreateAnomaly') ? (
          <>
            <CreateAnomaly />
          </>
        ) : null}

        {current.matches('ShowTransferItemDetails') ? (
          <>
            <Ink.Text>Mueva el item: </Ink.Text>
            <Ink.Text>
              {' '}
              - SKU:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.sku as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              {' '}
              - Cantidad:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.quantity as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              {' '}
              - Producto:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.product.name as any}
              </Ink.Chalk>
            </Ink.Text>

            {current.context.anomalyInventoryItem?.lot?.lotNumber ? (
              <Ink.Text>
                {' '}
                - Lote:{' '}
                <Ink.Chalk greenBright>
                  {current.context.anomalyInventoryItem.lot.lotNumber}
                </Ink.Chalk>
              </Ink.Text>
            ) : null}

            {current.context.anomalyInventoryItem?.lot?.expirationDate ? (
              <Ink.Text>
                {' '}
                - Fecha de Vencimiento:{' '}
                <Ink.Chalk greenBright>
                  {new Date(
                    current.context.anomalyInventoryItem?.lot?.expirationDate
                  ).toLocaleDateString()}
                </Ink.Chalk>
              </Ink.Text>
            ) : null}

            <Ink.Text>
              Desde el contenedor:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.container.lpn as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              Al contenedor:{' '}
              <Ink.Chalk greenBright>
                {current.context.selectedContainer?.lpn as any}
              </Ink.Chalk>
            </Ink.Text>

            <Ink.Button
              key='move'
              id='move'
              label='Mover Item'
              onClick={() => send('CONFIRM')}
            />
          </>
        ) : null}

        {current.matches('ShowMissingItemDetails') ? (
          <>
            <Ink.Text>Item Faltante: </Ink.Text>
            <Ink.Text>
              {' '}
              - SKU:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.sku as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              {' '}
              - Cantidad:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.quantity as any}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              {' '}
              - Producto:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.product.name as any}
              </Ink.Chalk>
            </Ink.Text>

            {current.context.anomalyInventoryItem?.lot?.lotNumber ? (
              <Ink.Text>
                {' '}
                - Lote:{' '}
                <Ink.Chalk greenBright>
                  {current.context.anomalyInventoryItem.lot.lotNumber}
                </Ink.Chalk>
              </Ink.Text>
            ) : null}

            {current.context.anomalyInventoryItem?.lot?.expirationDate ? (
              <Ink.Text>
                {' '}
                - Fecha de Vencimiento:{' '}
                <Ink.Chalk greenBright>
                  {new Date(
                    current.context.anomalyInventoryItem?.lot?.expirationDate
                  ).toLocaleDateString()}
                </Ink.Chalk>
              </Ink.Text>
            ) : null}

            <Ink.Text>
              En el contenedor:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyInventoryItem?.container.lpn as any}
              </Ink.Chalk>
            </Ink.Text>

            <Ink.Button
              key='confirm'
              id='confirm'
              label='Confirmar.'
              onClick={() => send('CONFIRM')}
            />
          </>
        ) : null}

        {current.matches('MoveToAnomalyContainer') ? (
          <>
            <TransferItem />
          </>
        ) : null}

        {current.matches('MoveContainerAnomalyContainer') ? (
          <>
            {current.context.selectedContainer ? (
              <Ink.Text>
                Mueva el contenedor &nbsp;
                <Ink.Chalk greenBright>
                  {current.context.selectedContainer.lpn}
                </Ink.Chalk>
                &nbsp; a la ubicación de merma.
              </Ink.Text>
            ) : (
              <Ink.Text>Mueva el contenedor a la ubicación de merma.</Ink.Text>
            )}
            <MoveContainer />

            <Ink.Button
              key='skip'
              id='skip'
              label='Saltar tarea de mover contenedor'
              onClick={() => send('SKIP')}
            />
          </>
        ) : null}

        {current.matches('AnomalyCreated') ? (
          <>
            <Ink.Text>proceso completado</Ink.Text>
          </>
        ) : null}
      </Ink.Box>
    );
  }
);
