import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusAuditedStatus extends OrderStatus {
  public displayName = 'Auditado';

  public internalName = OrderStatuses.Audited;

  public statusColor = '#c9def1';

  public textColor = '#212f61';
}
