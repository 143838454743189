import { ReceiptStatus } from './ReceiptStatus';

export class ReceiptStatusConfirmedStatus extends ReceiptStatus {
  public displayName = 'Confirmado';

  public internalName = 'confirmed';

  public statusColor = '#f3ecfe';

  public textColor = '#8240f2';
}
