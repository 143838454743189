import { ReservationOrderStatuses } from '../ReservationOrder';
import { ReservationOrderStatus } from './ReservationOrderStatus';

export class ReservationOrderStatusDeliveredStatus extends ReservationOrderStatus {
  public displayName = 'Entregada';

  public internalName = ReservationOrderStatuses.Delivered;

  public statusColor = '#f6e8fe';

  public textColor = '#9700f0';
}
