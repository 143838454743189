import { Task } from '@wms/domain';
import { actions, createMachine } from 'xstate';
import { api } from '../../../api/utils/axios-instance';

export interface SendToParentContext {
  task: Task;
  type: string;
  payload: Record<string, any>;
}

export const SendToParentMachine = createMachine<SendToParentContext>(
  {
    id:                         'SendToParentMachine',
    predictableActionArguments: true,
    schema:                     {
      context: {} as SendToParentContext
    },
    initial: 'SendingEvent',
    states:  {
      SendingEvent: {
        invoke: {
          src:     'dispatchEvent',
          onDone:  'EventSent',
          onError: {
            actions: actions.escalate((_ctx, ev) => ev)
          }
        }
      },
      EventSent: {
        type: 'final'
      }
    }
  },
  {
    services: {
      dispatchEvent: async (context, _event) => {
        //        await api.post<void>(`/process/${process?.id}/event`, {

        await api.post<void>(`/task/${context.task?.id}/event`, {
          type:    context.type,
          payload: context.payload
        });
      }
    }
  }
);
