import Ink from '@ink';
import { isAlphanumeric } from 'class-validator';
import React, { FC, Reducer, useReducer } from 'react';
import { ProductItem } from '../../../shared/utils/ProductItem';

export const ScanSKU: FC<{
  suggestedItem: ProductItem | null;
  barcodeScanned: (barcode: string) => void;
  requestedItem: ProductItem | null;
}> = ({ suggestedItem, barcodeScanned, requestedItem }) => {
  let error;

  const [inputBarcode, setBarcode] = useReducer<Reducer<string, string>>(
    (prev, next) => {
      if (next !== '' && error) {
        // setError('');
      }
      return next === '' || isAlphanumeric(next) ? next : prev;
    },
    ''
  );

  return (
    <>
      <Ink.Text>Escanee etiqueta del Producto</Ink.Text>

      {suggestedItem && !requestedItem ? (
        <>
          <Ink.Text>
            SKU Sugerido: &nbsp;
            <Ink.Chalk greenBright>
              {suggestedItem.sku + ' - ' + suggestedItem.product?.name}
            </Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      {requestedItem ? (
        <>
          <Ink.Text>
            SKU Requerido:
            <Ink.Chalk greenBright>
              {requestedItem.sku + ' - ' + requestedItem.product?.name}
            </Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      <Ink.TextInput
        value={inputBarcode}
        onChange={setBarcode}
        onSubmit={barcodeScanned}
      />
    </>
  );
};

ScanSKU.displayName = 'ScanSku';
