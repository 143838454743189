import { Location } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';

export interface ScanControlDigitContext {
  suggestLocation: boolean;
  suggestedLocation: Location | null;
  requiredLocation: Location | null;
  validLocations: Location[];
  location: Location;
  type: string;
  draftLocation: Location | null;
  hint: string | null;
  error: string | null;
  containerLpn?: string;
  gridNumber?: number;
}

export const DefaultScanControlDigitContext = {
  suggestedLocation: null,
  requiredLocation:  null,
  validLocations:    [],
  location:          { name: '', checkDigit: '' } as Location,
  type:              '',
  draftLocation:     {},
  error:             null,
  hint:              null,
  containerLPN:      ''
};

export const ScanControlDigitMachine = createMachine<ScanControlDigitContext>(
  {
    id:      'ScanControlDigit',
    initial: 'AwaitingControlDigitScan',
    states:  {
      AwaitingControlDigitScan: {
        on: {
          change: {
            target:  'AwaitingControlDigitScan',
            actions: 'assignScannedValue'
          },
          ControlDigitScanned: [
            {
              cond:    'invalidControlDigit',
              actions: 'errorInvalidControlDigit',
              target:  'AwaitingControlDigitScan'
            },
            {
              target: 'FetchingControlDigit'
            }
          ]
        }
      },
      FetchingControlDigit: {
        invoke: {
          src:    'findLocationByDigitControl',
          onDone: [
            {
              actions: 'assignLocation',
              target:  'LocationFound'
            }
          ],
          onError: {
            actions: 'assignError',
            target:  'AwaitingControlDigitScan'
          }
        }
      },
      LocationFound: {
        type: 'final',
        data: ctx => ({
          location: ctx.location
        })
      }
    }
  },
  {
    guards: {
      invalidControlDigit: ctx => {
        return (
          !!ctx.requiredLocation &&
          ctx.requiredLocation.checkDigit !== ctx.location.checkDigit
        );
      }
    },
    actions: {
      ...UtilityActions,
      assignScannedValue: assign({
        location: (_ctx, e: any) => e.data
      })
    },
    services: {
      ...API
    }
  }
);
