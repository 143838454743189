import { Container, MopPickingContainer, Task } from '@wms/domain';
import { createMachine } from 'xstate';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { moveToAuditingLocationActions } from './actions';
import { moveToAuditingLocationGuards } from './guards';
import { moveToAuditingLocationServices } from './services';

export interface MoveToAuditingLocationContext {
  task: Task | null;

  container: Container | null;
  mopPickingContainer: MopPickingContainer | null;
  locationId: number;
  error: string | null;
}

export const DefaultMoveToAuditingLocationContext: MoveToAuditingLocationContext =
  {
    task: null,

    container:           null,
    mopPickingContainer: null,
    locationId:          -1,
    error:               null
  };

export const MoveToAuditingLocationMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5gF8A0IB2B7CdGgFkBLDIgBSIGMBrEqAdQEMA3MfEABy1iIBcisGdgA9EAWgBs6AJ7iArADoAnCtVq1AZmRoQxUhRp0mrBQAksAGyLwknbnwFDbohABYATDMQAOAIwLXAHYlX1c5QIkABg0NQLklbXQ9cipaDAYWMAUAYQALRgBXdi4efkERRA8vBF8NJWUJb0DfJVcguTlXb21tIA */
    id:                         'MiniMopMoveToAuditingLocation',
    predictableActionArguments: true,
    schema:                     {
      context: {} as MoveToAuditingLocationContext
    },
    initial: 'ScanningContainer',
    states:  {
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: GetContainerByLpnMachine(
            'Escanee el contenedor a trasladar',
            getAnyContainerByLpn(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: [
            {
              target:  'FetchingMopPickingContainer',
              actions: 'assignContainer'
            }
          ]
        }
      },
      FetchingMopPickingContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchingMopPickingContainer',
          onDone: {
            target:  'ScanningLocation',
            actions: ['assignMopPickingContainer', 'clearError']
          },
          onError: {
            target:  'ScanningContainer',
            actions: ['assignError']
          }
        }
      },
      ScanningLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Escanee la ubicación de destino',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            target:  'ConfirmingMovement',
            actions: ['assignLocationId', 'clearError']
          },
          onError: {
            target:  'ScanningContainer',
            actions: 'assignError'
          }
        }
      },
      ConfirmingMovement: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniMopContainerMovedToAuditingLocation',
              payload: {
                locationId:    ctx.locationId,
                label:         ctx.container?.lpn,
                pickingWaveId: ctx.mopPickingContainer?.pickingWaveId
              }
            };
          },
          onDone: {
            target:  'ConfirmingScreen',
            actions: ['clearError']
          },
          onError: {
            target:  'ScanningContainer',
            actions: 'assignError'
          }
        }
      },
      ConfirmingScreen: {
        on: {
          CloseTask: {
            target: 'MoveConfirmed'
          },
          ContinueTask: {
            target: 'ScanningContainer'
          }
        }
      },
      MoveConfirmed: {
        type: 'final'
      }
    }
  },
  {
    guards:   moveToAuditingLocationGuards,
    actions:  moveToAuditingLocationActions,
    services: moveToAuditingLocationServices
  }
);
