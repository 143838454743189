import { Location } from '@wms/domain';
import { isDecimal } from 'class-validator';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';

export interface ScanLocationContext {
  suggestLocation: boolean;
  suggestedLocation: Location | null;
  requiredLocation: Location | null;
  validLocations: Location[];
  location: Location;
  type: string;
  draftLocation: Location | null;
  hint: string | null;
  error: string | null;
  containerLpn?: string;
  gridNumber?: number;
  position: string;
}

export const DefaultScanLocationContext = {
  suggestedLocation: null,
  requiredLocation:  null,
  validLocations:    [],
  location:          { name: '' } as Location,
  type:              '',
  draftLocation:     {},
  error:             null,
  hint:              null,
  containerLPN:      '',
  position:          ''
};

export const ScanLocationMachine = createMachine<ScanLocationContext>(
  {
    id:      'ScanLocation',
    initial: 'AwaitingLocationScan',
    states:  {
      AwaitingLocationScan: {
        on: {
          CHANGE: {
            target:  'AwaitingLocationScan',
            actions: 'assignScannedValue'
          },
          LOCATION_SCANNED: [
            {
              cond:   'isHighLevelPosition',
              target: 'DeclaringLocationPosition'
            },

            {
              cond:    'invalidLocation',
              actions: 'errorInvalidLocation',
              target:  'AwaitingLocationScan'
            },
            {
              target: 'FetchingLocation'
            }
          ]
        }
      },
      DeclaringLocationPosition: {
        on: {
          CHANGE: {
            target:  'DeclaringLocationPosition',
            actions: ['assignPositionValue']
          },
          LOCATION_SCANNED: [
            {
              cond:    'invalidNumberOfLocation',
              actions: 'errorInvalidNumberOfLocation',
              target:  'DeclaringLocationPosition'
            },
            {
              cond:    'invalidRangeOfLocation',
              actions: 'errorInvalidRange',
              target:  'DeclaringLocationPosition'
            },

            {
              cond:    'invalidMaxCharacters',
              actions: 'errorInvalidMaxCharacters',
              target:  'DeclaringLocationPosition'
            },
            {
              cond:    'invalidLocation',
              actions: 'errorInvalidLocation',
              target:  'AwaitingLocationScan'
            },
            {
              target:  'FetchingLocation',
              actions: 'setLocationWithPositionLevel'
            }
          ]
        }
      },
      FetchingLocation: {
        invoke: {
          src:    'findLocationByName',
          onDone: [
            {
              actions: 'assignLocation',
              target:  'LocationFound'
            }
          ],
          onError: {
            actions: 'assignError',
            target:  'AwaitingLocationScan'
          }
        }
      },
      ValidateLocation: {
        invoke: {
          src:     'isValidGrid',
          onDone:  'LocationFound',
          onError: 'AwaitingLocationScan'
        }
      },
      LocationFound: {
        type: 'final',
        data: ctx => ({
          location: ctx.location
        })
      }
    }
  },
  {
    guards: {
      isTypeSet:           ctx => !!ctx.type,
      isHighLevelPosition: (ctx, event) => event.data.name.includes('XX'),
      invalidLocation:     (ctx, event) =>
        ctx?.validLocations?.length !== 0 &&
        !ctx?.validLocations.some(
          location => location.name === event.data.name
        ),
      hasGridNumber:           ctx => !!ctx.gridNumber,
      invalidMaxCharacters:    ctx => ctx.position.length > 2,
      invalidNumberOfLocation: ctx => !isDecimal(ctx.position),
      invalidRangeOfLocation:  ctx =>
        Number(ctx.position) > 5 || Number(ctx.position) < 1
    },
    actions: {
      ...UtilityActions,
      assignScannedValue: assign({
        location: (_ctx, e: any) => e.data
      }),
      assignLocationTypeValue: assign({
        type: (_ctx, e: any) => e.data
      }),
      assignPositionValue: assign({
        position: (ctx, e: any) => {
          if (ctx.position.includes('0')) return e.data.position;
          else return `0${e.data.position}`;
        }
      }),
      setLocationWithPositionLevel: assign({
        location: ctx => {
          const position = ctx.position;
          const name = ctx.location.name.replace('XX', position);
          return {
            ...ctx.location,
            name
          };
        },
        position: _ctx => '',
        error:    _ctx => null
      }),
      clearError: assign({
        error: _ctx => null
      })
    },
    services: {
      ...API
    }
  }
);
