import { ReservationOrderStatuses } from '../ReservationOrder';
import { ReservationOrderStatus } from './ReservationOrderStatus';

export class ReservationOrderStatusDispatchedStatus extends ReservationOrderStatus {
  public displayName = 'Despachada';

  public internalName = ReservationOrderStatuses.Dispatched;

  public statusColor = '#f3ecfe';

  public textColor = '#8b4df3';
}
