import { Product } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetProductBySKUFn } from '../machine';

export function getAuditingWaveProductBySKU(
  defaultErrorMessage: string,
  auditingWaveId: number
): GetProductBySKUFn {
  return fetchResult(defaultErrorMessage, sku =>
    api.get<Product>(`auditing-wave/${auditingWaveId}/product/${sku}`)
  );
}
