import Ink from '@ink';
import { Container, PutAwayItemStatuses } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { Counter } from '../../../shared/Counter';
import { LoadingScreen } from '../../../shared/LoadingScreen';
import { withActorRef } from '../../../shared/Machine';
import { SelectList } from '../../../shared/SelectList';
import { CreateContainer } from '../../capa-4/create-container/CreateContainer';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { ReceiptLine } from './ReceiptLine';
import { VerifyItemsTaskMachine } from './VerifyItemsTaskMachine';
import { ContainersList } from './options-machines/containers-list/ContainersList';
import { MarkItemAsAnomaly } from './options-machines/mark-item-as-anomaly/MarkItemAsAnomaly';
import { RejectItem } from './options-machines/reject-item/RejectItem';

export const VerifyItemsTask: FC = withActorRef(VerifyItemsTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const onCompleted = () => {
      console.log('AllItemsPicked');
    };
    const menuItems = [
      ...(current.context.receiptContainers.length === 0
        ? [
            {
              key:         'assign-task',
              displayName: 'Confirmar tarea',
              event:       'confirmReceiptVerification'
            }
          ]
        : []),
      ...(current.context.nonEmptyContainers.length !== 0
        ? [
            {
              key:         'close-container',
              displayName: 'Confirmar recepción',
              event:       'goToContainersList'
            }
          ]
        : []),
      ...(current.context.nonEmptyContainers.length === 0 &&
      current.context.receiptContainers.length !== 0
        ? [
            {
              key:         'del-unused-containers',
              displayName: 'Desasociar contenedores sin usar',
              event:       'deleteUnusedContainers'
            }
          ]
        : [])
    ];

    const containerMenuItems = [
      {
        key:         'create-container',
        displayName: 'Crear nuevo contenedor',
        event:       'createContainer'
      },
      {
        key:         'continue',
        displayName: 'Continuar a verificación',
        event:       'receiptLine'
      }
    ];

    const handleClick = useCallback(
      (id: string) => {
        const clickedItem = [...menuItems, ...containerMenuItems].find(
          item => item.key === id
        );

        if (clickedItem) {
          send(clickedItem.event);
        }
      },
      [menuItems, containerMenuItems]
    );

    const onSelect = (container: Container) => {
      send({ type: 'containerChange', data: { container } });
    };

    const uniquePutAwayItemsLength = (): number => {
      return new Set(
        current.context.putAwayItems
          .filter(item => (item.status as any) !== PutAwayItemStatuses.Rejected)
          .map(item => item.receiptItemId)
      ).size;
    };

    return (
      <Ink.Box flexDirection='column' paddingTop={2} paddingX={2}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.receipt?.id ? (
                'PROCESANDO RECEPCIÓN '
              ) : (
                <>
                  <Ink.Text>PROCESANDO RECEPCIÓN: </Ink.Text>
                  <Ink.Chalk>{current.context.receipt.id}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>
        {current.matches('DeclaringUnloadingLocation') ? (
          <>
            Especifique ubicación del canal de recepcionado.
            {current.context?.payload?.appointment?.staging?.name ? (
              <>
                <Ink.Text>
                  Ubicación del canal:{' '}
                  <Ink.Chalk greenBright>
                    {current.context.payload.appointment.staging.name}
                  </Ink.Chalk>
                </Ink.Text>
              </>
            ) : null}
            <ScanControlDigit />
          </>
        ) : null}

        {current.matches('ConfirmContainerCreation') ? (
          <>
            Confirmar contenedores
            {containerMenuItems.map(item => (
              <Ink.Button
                key={item.key}
                id={item.key}
                label={item.displayName}
                onClick={handleClick}
              />
            ))}
          </>
        ) : null}

        {current.matches('DeclaringPutAwayContainers') ? (
          <>
            Declare contenedores para recepcionar productos.
            <Ink.Text>
              <Ink.Text>Contenedores declarados: </Ink.Text>
              <Counter
                count={
                  current.context.containersToCreate.length
                    ? {
                        total:     current.context.containersToCreate?.length | 0, // current.context.pickingWave?.pickItems?.length | 0,
                        processed: current.context.receiptContainers.length // current.context.pickingWave?.pickItems.filter(()).length
                      }
                    : null
                }
                onComplete={onCompleted}
              />{' '}
            </Ink.Text>
            <CreateContainer />
          </>
        ) : null}

        {current.matches('ClosingContainers') ? (
          <>
            Escanee contenedor para cerrarlo:
            <ScanContainer />
          </>
        ) : null}

        {current.matches('IteratingOverReceiptLines') ? (
          <>
            <Ink.Text>
              <Counter
                count={
                  current.context.totalReceiptItems !== null
                    ? {
                        total:     current.context.totalReceiptItems.length | 0,
                        processed: uniquePutAwayItemsLength()
                      }
                    : null
                }
                onComplete={onCompleted}
              />
              &nbsp;Lineas Verificadas
            </Ink.Text>

            <Ink.Box>
              <Ink.Text>Verificando en contenedor: </Ink.Text>
              <Ink.Chalk greenBright bold>
                {current.context.currentContainer?.lpn || ''}
              </Ink.Chalk>
            </Ink.Box>

            <SelectList<Container>
              items={current.context.receiptContainers}
              trackBy={item => item.id}
              getDisplayText={item => item.lpn}
              onSelect={onSelect}
            />

            <ReceiptLine />
          </>
        ) : null}

        {current.matches('RejectItem') ? <RejectItem /> : null}
        {current.matches('MarkItemAsAnomaly') ? <MarkItemAsAnomaly /> : null}
        {current.matches('ContainersList') ? <ContainersList /> : null}

        {current.matches('ConfirmReceiptItemsVerification') ? (
          <Ink.Box flexDirection='column' paddingTop={1}>
            <>
              {current.context.receiptItems.length === 0 ? (
                <Ink.Text inline>
                  Se han verificado todos los productos de la{' '}
                  <Ink.Chalk greenBright bold>
                    {`Recepcion ${
                      current.context?.receipt?.id
                        ? `#${current.context?.receipt?.id}`
                        : ''
                    }`}
                  </Ink.Chalk>
                </Ink.Text>
              ) : (
                <Ink.Text inline>
                  Se han verificado{' '}
                  <Ink.Chalk greenBright>
                    {uniquePutAwayItemsLength()}
                  </Ink.Chalk>
                  /
                  <Ink.Chalk greenBright>
                    {current.context?.receipt?.receiptItems?.length || 0}
                  </Ink.Chalk>{' '}
                  lineas de la recepción{' '}
                  <Ink.Chalk greenBright>
                    {current.context?.receipt?.id || ''}
                  </Ink.Chalk>
                  .
                </Ink.Text>
              )}

              <Ink.Box paddingY={1}>
                <Ink.Text>¿Confirmar Verificación?</Ink.Text>
                {/* {state.context.error && ( */}
                {/*   <Ink.Text color='redBright'>{state.context.error}</Ink.Text> */}
                {/* )} */}
              </Ink.Box>

              <Ink.Box flexDirection='column'>
                {menuItems.map(item => (
                  <Ink.Button
                    key={item.key}
                    id={item.key}
                    label={item.displayName}
                    onClick={handleClick}
                  />
                ))}
              </Ink.Box>
            </>
          </Ink.Box>
        ) : null}

        {current.matches('ConfirmingTask') ? <LoadingScreen /> : null}
        {current.matches('initializing') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}
      </Ink.Box>
    );
  }
);
