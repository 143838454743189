// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Entity,
  Field,
  ForeignKey,
  IsDefined,
  Knows,
  Needs,
  Owns,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ApiField } from '../decorators';
import { Appointment } from './Appointment';
import { Comment } from './Comment';
import { Container } from './Container';
import { Document } from './Document';
import { Location } from './Location';
import { Process } from './Process';
import { PurchaseOrder } from './PurchaseOrder';
import { ReceiptItem } from './ReceiptItem';
import { ReceiptOrigin } from './ReceiptOrigin';
import { ReceiptType } from './ReceiptType';
import { Route } from './Route';
import { StockTransfer } from './StockTransfer';
import { Task } from './Task';
import { TransferOrder } from './TransferOrder';
import { Warehouse } from './Warehouse';
import {
  ReceiptStatus,
  ReceiptStatusClosedStatus,
  ReceiptStatusConfirmedStatus,
  ReceiptStatusOpenStatus,
  ReceiptStatusPreSortingStatus,
  ReceiptStatusProcessingStatus,
  ReceiptStatusSortedStatus,
  ReceiptStatusSortingStatus,
  ReceiptStatusStoredStatus,
  ReceiptStatusStoringStatus
} from './receipt-status';

export const ReceiptStatuses = {
  Open:       'open',
  Processing: 'processing',
  Confirmed:  'confirmed',
  Closed:     'closed',
  PreSorting: 'pre-sorting',
  Sorting:    'sorting',
  Sorted:     'sorted',
  Storing:    'storing',
  Stored:     'stored'
};

export const ProcessReceiptStatuses = [
  ReceiptStatuses.Processing,
  ReceiptStatuses.Confirmed,
  ReceiptStatuses.PreSorting,
  ReceiptStatuses.Sorting
];

export const ReceiptMoveStatuses = {
  NotMoved:  'not-moved',
  Moved:     'moved',
  Cancelled: 'cancelled'
};

export type ReceiptMoveStatusesTypes =
  typeof ReceiptMoveStatuses[keyof typeof ReceiptMoveStatuses];

export const ReceiptWorkFlows = {
  ZoneClassifierLowVolume:  'zone-classifier-low-volume',
  ZoneClassifierHighVolume: 'zone-classifier-high-volume',
  CrossDockPush:            'cross-dock-push',
  WMSCedisReceivingProcess: 'ReceivingProcess-v0',
  WMSCedisInboundProcess:   'InboundProcess-v0'
};

export type ReceiptWorkFlowTypes =
  typeof ReceiptWorkFlows[keyof typeof ReceiptWorkFlows];

@Entity()
export class Receipt {
  @ApiField()
  @PrimaryKey()
  id: number;

  @Field({ name: 'work_flow' })
  workFlow: ReceiptWorkFlowTypes;

  @ApiField()
  @IsDefined({ message: 'El campo es requerido.' })
  @ForeignKey(() => ReceiptType, { name: 'receipt_type_id' })
  receiptTypeId: number;

  @ApiField()
  @IsDefined({ message: 'El campo es requerido.' })
  @ForeignKey(() => ReceiptOrigin, { name: 'receipt_origin_id' })
  receiptOriginId: number;

  @ApiField()
  @ForeignKey(() => Appointment, { name: 'appointment_id' })
  appointmentId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'sap_code' })
  sapCode: number;

  @Status({
    [ReceiptStatuses.Open]:       ReceiptStatusOpenStatus,
    [ReceiptStatuses.Processing]: ReceiptStatusProcessingStatus,
    [ReceiptStatuses.Confirmed]:  ReceiptStatusConfirmedStatus,
    [ReceiptStatuses.Closed]:     ReceiptStatusClosedStatus,
    [ReceiptStatuses.PreSorting]: ReceiptStatusPreSortingStatus,
    [ReceiptStatuses.Sorting]:    ReceiptStatusSortingStatus,
    [ReceiptStatuses.Sorted]:     ReceiptStatusSortedStatus,
    [ReceiptStatuses.Storing]:    ReceiptStatusStoringStatus,
    [ReceiptStatuses.Stored]:     ReceiptStatusStoredStatus
  })
  status: ReceiptStatus;

  @Owns(() => ReceiptItem, 'receiptId')
  receiptItems: ReceiptItem[];

  @Needs(() => ReceiptType, 'receiptTypeId')
  receiptType: ReceiptType;

  @Needs(() => ReceiptOrigin, 'receiptOriginId')
  receiptOrigin: ReceiptOrigin;

  @Knows(() => Document, 'receiptId')
  documents: Document[];

  @Knows(() => Appointment, 'appointmentId')
  appointment: Appointment;

  @Knows(() => Task, 'receiptId')
  task: Task[];

  @ForeignKey(() => Process, { name: 'process_instance_id' })
  processInstanceId: number;

  @Knows(() => Process, 'processInstanceId')
  processInstance: Process;

  static WORK_FLOW_AVAILABILITY = [
    {
      internalName: ReceiptWorkFlows.ZoneClassifierLowVolume,
      displayName:  'Bajo Volumen (Clasificación en Recepción)',
      isAvailable:  true
    },
    {
      internalName: ReceiptWorkFlows.ZoneClassifierHighVolume,
      displayName:  'Alto Volumen (Clasificación post Recepción)',
      isAvailable:  false
    },
    {
      internalName: ReceiptWorkFlows.CrossDockPush,
      displayName:  'Cross dock Push',
      isAvailable:  true
    },
    {
      internalName: ReceiptWorkFlows.WMSCedisReceivingProcess,
      displayName:  'Recepción en Cedis',
      isAvailable:  true
    },
    {
      internalName: ReceiptWorkFlows.WMSCedisInboundProcess,
      displayName:  'Entrada de mercadería en Cedis',
      isAvailable:  true
    }
  ];

  @Knows(() => Comment, 'receiptId')
  comment: Comment[];

  @ForeignKey(() => PurchaseOrder, { name: 'purchase_order_id' })
  purchaseOrderId?: number;

  @Knows(() => PurchaseOrder, 'purchaseOrderId')
  purchaseOrder?: PurchaseOrder;

  @Field({ name: 'move_status' })
  moveStatus: ReceiptMoveStatusesTypes;

  @ForeignKey(() => Route, { name: 'route_id' })
  routeId: number;

  @Knows(() => Route, 'routeId')
  route: Route;

  @Field({ name: 'estimated_date' })
  estimatedDate: Date;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Knows(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ApiField()
  @ForeignKey(() => TransferOrder, { name: 'transfer_order_id' })
  transferOrderId?: number;

  @Knows(() => TransferOrder, 'transferOrderId')
  transferOrder?: TransferOrder;

  @ApiField()
  @ForeignKey(() => StockTransfer, { name: 'stock_transfer_id' })
  stockTransferId?: number;

  @Knows(() => StockTransfer, 'stockTransferId')
  stockTransfer?: StockTransfer;

  @Knows(() => Container, 'receiptId')
  containers: Container[];

  @ForeignKey(() => Location, { name: 'unloading_location_id' })
  unloadingLocationId: number;

  @Knows(() => Location, 'unloadingLocationId')
  unloadingLocation: Location;

  @Field({ name: 'many_sku_allowed_in_container' })
  manySkuAllowedInContainer: boolean;
}
