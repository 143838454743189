import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusDispatchingStatus extends OrderStatus {
  public displayName = 'Despachando';

  public internalName = OrderStatuses.Dispatching;

  public statusColor = '#f9f5ff';

  public textColor = '#9f69fa';
}
