import { useCallback, useState } from 'react';
import useApi from 'react-use-api';

export function useManualApi<D>(
  config: ReactUseApi.SingleConfig | string,
  opt: ReactUseApi.Options | ReactUseApi.Options['handleData'] = {}
): [D, ReactUseApi.State, ReactUseApi.RequestFn] {
  const [shouldRequest, setShouldRequest] = useState(false);

  const [data, state, requestFn] = useApi<D>(config, {
    ...opt,
    skip:  !shouldRequest,
    watch: [shouldRequest]
  });

  const request = useCallback<ReactUseApi.RequestFn>(
    (cfg, keepState) =>
      requestFn(cfg, keepState)
        .then(() => setShouldRequest(true))
        .finally(() => setShouldRequest(false)),
    [setShouldRequest, requestFn]
  );

  return [data, state, request];
}
