import { MachineOptions } from 'xstate';
// import { api } from '../../../../api/utils/axios-instance';
import { MiniMopMoveToSorterExitContext } from './machine';

type MiniMopMoveToSorterExitServices = MachineOptions<
  MiniMopMoveToSorterExitContext,
  any
>['services'];

export const miniMopMoveToSorterExitServices: MiniMopMoveToSorterExitServices =
  {};
