// Ver si pasar para capa4

import {
  Container,
  Location,
  ProductItemName,
  PutAwayItem,
  Receipt,
  ReceiptItem
} from '@wms/domain';
import { createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../../../api/api';
import {
  CreateContainerMachine,
  createContainerMachineInitialContext
} from '../../../../capa-4/create-container/CreateContainerMachine';

import { CreateItemMachine } from '../../../../capa-4/create-item/CreateItemMachine';
import { PlaceItemInContainerMachine } from '../../../../capa-4/place-item-in-container/PlaceItemInContainerMachine';

export interface MarkItemAsAnomalyMachineContext {
  receipt: Receipt | null;
  item: ReceiptItem | null;
  putAwayItem: PutAwayItem | null;
  container: Container | null;
  validItems: ReceiptItem[];
  location: Location | null;
}

export const DefaultMarkItemAsAnomalyMachineContext = {
  receipt:     null,
  item:        null,
  putAwayItem: null,
  validItems:  [],
  container:   null,
  containers:  [],
  location:    null
};

export const MarkItemAsAnomalyMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCuAXAggdwIYE8BhAewDt1cBLUsAJ1gBVdYBrAOmsvUtwBtKAXmADEiUAAdisLpTJiQAD0QAGADQh8KgL5b1aLHiJkK1Oo2bsAkujq5upKAHkAbnQBKYAMZhK49CXIqGnphTF5eazAAW1gAZXRiWlwYCHlJaW45JEVEACYATny2AEYANnyADgBWAHYK-NzlABYK9U0EAFoKtgBmJrKy6pqmnuVcptKdPQwcAgCTYPNWNgCAM0paKP1Z-GFPMnXN5EpPFmooNKkZLNAlBFLS3LZc4prS4oKWl9K2xFfu8pNfJVXI1Ro9YpVUoVHS6ECkYgQODybaGeZBMxMZacbh8QRgS4ZWSkeR3NQaRD5SZw1FzYwY+hYqw2JL2JyuWgeby+fz00z0QnXEnZO7FJrKNjNKmVCrNPrjX4IJpVNgjCaNXJVfLKnr1JpTEC0oyBflLdhrDZbGaGQWZYW3RA6ti1fJvOo1KrFfJin4UhCy57FHo9fLKMNVCqlLW5A1G9GmplsI1MkhRcS8MA2VLZdJC0mIUo1Iqh4E1ZSF3XlnqKyPOrUNIug0YtWPWukmxZM23E-OdBV+rrFXofV3KHplz2PfWwoA */
  createMachine<MarkItemAsAnomalyMachineContext>(
    {
      id:      'Mark Item As Anomaly',
      context: DefaultMarkItemAsAnomalyMachineContext,
      initial: 'ChooseContainer',
      states:  {
        ChooseContainer: {
          always: {
            cond:   context => !context.container,
            target: 'CreateContainer'
          },

          on: {
            create: {
              target: 'CreateContainer'
            },
            continue: {
              target: 'CreateItem'
            }
          }
        },
        CreateContainer: {
          invoke: {
            src:  CreateContainerMachine,
            id:   CreateContainerMachine.id,
            data: context => ({
              ...createContainerMachineInitialContext,
              location:  context.location,
              isAnomaly: true
            }),
            onDone: {
              actions: 'assignContainer',
              target:  'CreateItem'
            }
          }
        },
        CreateItem: {
          invoke: {
            src:  CreateItemMachine,
            id:   CreateItemMachine.id,
            data: context => ({
              validItems:    context.validItems,
              type:          ProductItemName.PutAwayItem,
              location:      context.location,
              qualityStatus: 'anomaly'
            }),
            onDone: {
              actions: 'assignItem',
              target:  'placeItemInContainer'
            }
          }
        },
        placeItemInContainer: {
          invoke: {
            id:   PlaceItemInContainerMachine.id,
            src:  PlaceItemInContainerMachine,
            data: ctx => {
              return {
                requestedContainer: ctx.container,
                type:               ProductItemName.PutAwayItem,
                item:               ctx.item,
                location:           ctx.location
              };
            },
            onDone: [
              {
                target: 'ItemMarkedAsAnomaly'
              }
            ]
          }
        },

        ItemMarkedAsAnomaly: {
          type: 'final',
          data: context => ({ item: context.putAwayItem })
        }
      }
    },
    {
      guards: {
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions
      },
      services: { ...API }
    }
  );
