import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Unique
} from '@skyframe/core';
import { Warehouse } from './Warehouse';

export interface WarehouseConfiguration {
  selectedReceiptWorkFlow: string;
  mustAudit: boolean;
  threshold: number;
  selectedPickingMethod: string;
  containerLabelCounter: number;
  bucketLabelCounter: number;
}

@Entity()
export class Configuration {
  @PrimaryKey()
  id: number;

  @Unique()
  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  config: WarehouseConfiguration;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}
