import { TextProps, pallet } from '@wms/client-core';
import React, { CSSProperties, FC, useMemo } from 'react';

function getStyle({ inline, contents, color, bold }: TextProps): CSSProperties {
  return {
    display:      contents ? 'contents' : inline ? 'inline-block' : 'flex',
    paddingRight: inline ? '0' : '2px',
    ...(color ? { color: pallet[color] || color } : {}),
    ...(bold ? { fontWeight: 'bold' } : {}),
    alignItems:   'center'
  };
}

export const Text: FC<TextProps> = props => {
  const style = useMemo<CSSProperties>(() => getStyle(props), [props]);

  const children = useMemo(
    () =>
      typeof props.children === 'string'
        ? props.children.replace(/^\s+|\s+$/g, match =>
            '\u00A0'.repeat(match.length)
          )
        : props.children,
    [props.children]
  );

  return <span style={style}>{children}</span>;
};
