import Ink from '@ink';
import { Container } from '@wms/domain';
import React, { FC, useState } from 'react';

export const ScanWeight: FC<{
  container: Container | null;
  weightEntered: (weight: string) => void;
}> = ({ weightEntered }) => {
  const [inputWeight, setWeight] = useState('');

  return (
    <>
      <Ink.Text bold>Ingrese peso:</Ink.Text>

      <Ink.TextInput
        value={inputWeight}
        onChange={setWeight}
        onSubmit={weightEntered}
      />
    </>
  );
};

ScanWeight.displayName = 'ScanWeight';
