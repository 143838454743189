import { MachineOptions, assign } from 'xstate';
import { GetProductBySKUContext } from './machine';

type GetProductBySKUActions = MachineOptions<
  GetProductBySKUContext,
  any
>['actions'];

export const getProductBySKUActions: GetProductBySKUActions = {
  updateProductSKU: assign({
    productSKU: (_context, event) => event.productSKU
  }),
  assignProduct: assign({
    product: (context, event) => event.data.data
  }),
  assignError: assign({
    error: (context, event) => event.data.error
  }),
  clearError: assign({
    error: (_context, _event) => null
  })
};
