import { ConsolidatingContainersToStagingProcessStatuses } from '../ConsolidatingContainersToStagingProcess';
import { ConsolidatingContainersToStagingProcessStatus } from './ConsolidatingContainersToStagingProcessStatus';

export class ConsolidatingContainersToStagingProcessPausedStatus extends ConsolidatingContainersToStagingProcessStatus {
  public displayName = 'Pausado';

  public internalName = ConsolidatingContainersToStagingProcessStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
