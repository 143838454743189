import { PackingProcessStatuses } from '../PackingProcess';
import { PackingProcessStatus } from './PackingProcessStatus';

export class PackingProcessStatusCompletedStatus extends PackingProcessStatus {
  public displayName = 'Completed';

  public internalName = PackingProcessStatuses.Completed;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
