import Ink from '@ink';
import { Task } from '@wms/domain';
import React, { FC, useCallback } from 'react';
import { JobTypes } from '../tasks/jobs';

interface MenuProps {
  send: (any: string, any1: any) => void;
}

const menuItems = [
  // {
  //   key:         'pick-order',
  //   displayName: 'PICKEAR ORDEN',
  //   event:       'navigateToTaskSearcher',
  //   payload:     { menuItemKey: 'pick-order' }
  // },
  {
    key:         'pick-wave',
    displayName: 'PICKEAR OLA',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'pick-wave' }
  },
  {
    key:         'pack-wave',
    displayName: 'EMPAQUETAR',
    event:       'goToPackingTaskAssigner',
    payload:     {}
  },
  {
    key:         'transfer-to-packing-location',
    displayName: 'TRASLADO MANUAL A ESTACIÓN DE PACKING',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'transfer-to-packing-location' }
  },
  {
    key:         'mini-move-to-sorter-exit',
    displayName: 'TRASLADO MANUAL A SALIDA DEL SORTER',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'mini-move-to-sorter-exit' }
  },
  {
    key:         'maxi-consolidate-containers',
    displayName: 'TRASLADAR CONTENEDORES A CANAL',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'consolidate-containers' }
  },
  {
    key:         'transfer-to-auditing-location',
    displayName: 'TRASLADO A UBICACIÓN DE AUDITORÍA',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'transfer-to-auditing-location' }
  },
  {
    key:         'mini-mop-audit',
    displayName: 'AUDITORÍA MINI-MOP',
    event:       'goToAuditingTaskAssigner',
    payload:     {}
  },
  {
    key:         'consolidate-order',
    displayName: 'CONSOLIDAR ORDEN',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'consolidate-order' }
  },
  {
    key:         'consolidation-clasification',
    displayName: 'CLASIFICACION EN SALIDA DE SORTER',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.Outbound.Consolidation.Classification } as Task
    }
  },
  {
    key:         'move-containers-to-grid-3',
    displayName: 'TRASLADAR CONTENEDORES A PESADO',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'move-containers-to-grid-3' }
  },
  {
    key:         'move-containers-of-sorter-track-to-staging',
    displayName: 'TRASLADAR DE SALIDA DE RAMPA A STAGING',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'move-containers-of-sorter-track-to-staging' }
  },
  {
    key:         'weight-container',
    displayName: 'PESAR CONTENEDOR',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'weight-container' }
  },
  {
    key:         'audit-order',
    displayName: 'AUDITAR ORDEN',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'audit-order' }
  },
  {
    key:         'ship-order',
    displayName: 'DESPACHAR ORDEN',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'dispatch-order' }
  },
  {
    key:         'readjust-products',
    displayName: 'COMPLETAR PRODUCTOS FALTANTES',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'readjust-products' }
  },
  { key: 'back', displayName: '← ATRÁS', event: 'Back', payload: {} }
] as const;

export const OutboundMenu: FC<MenuProps> = ({ send }) => {
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(i => i.key === id);
    if (item) {
      send(item.event, item.payload);
    }
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item => (
          <Ink.Button
            key={item.key}
            id={item.key}
            label={item.displayName}
            onClick={handleClick}
          />
        ))}
      </Ink.Box>
    </Ink.Box>
  );
};

OutboundMenu.displayName = 'Menú';
