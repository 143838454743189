import { ReservationStatuses } from '../Reservation';
import { ReservationStatus } from './ReservationStatus';

export class ReservationStatusDeliveredStatus extends ReservationStatus {
  public displayName = 'Entregada';

  public internalName = ReservationStatuses.Delivered;

  public statusColor = '#f6e8fe';

  public textColor = '#9700f0';
}
