import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusCancelledStatus extends OrderStatus {
  public displayName = 'Cancelado';

  public internalName = OrderStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
