import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../scan-container/ScanContainer';
import { ScanItem } from '../scan-item/ScanItem';
import { SelectRejectionReason } from '../select-rejection-reason/SelectRejectionReason';
import { CreateAnomalyMachine } from './CreateAnomalyTaskMachine';

export const CreateAnomaly: FC = withActorRef(CreateAnomalyMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const handleClick = useCallback(() => {
      send('confirm');
    }, []);

    const handleClickScan = useCallback(() => {
      send('proceed');
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.hint ? (
                'MARCANDO INCIDENCIA'
              ) : (
                <>
                  MARCANDO INCIDENCIA:{' '}
                  <Ink.Chalk greenBright>{current.context.hint}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>

        {current.matches('ScanContainer') ? (
          <>
            <Ink.Text>
              Escanee el contenedor en el que se encuentra el ítem con
              incidencia.
            </Ink.Text>{' '}
            <ScanContainer />{' '}
          </>
        ) : null}

        {current.matches('ScanItem') ? (
          <>
            <Ink.Text>Escanee el ítem que tiene la incidencia.</Ink.Text>{' '}
            <ScanItem />{' '}
          </>
        ) : null}

        {current.matches('ConfirmSelection') ? (
          <>
            <Ink.Text>El ítem seleccionado es: </Ink.Text>
            <Ink.Chalk greenBright>
              {`${current.context.items[0].sku} - ${
                current.context.items[0].product.name
              }${
                current.context.items[0].lot?.lotNumber
                  ? ` - Lote ${current.context.items[0].lot?.lotNumber}`
                  : ''
              }`}
            </Ink.Chalk>
            <Ink.Text>
              Cantidad:{' '}
              <Ink.Chalk greenBright>
                {current.context.anomalyQuantity +
                  '/' +
                  current.context.items.reduce((p, c) => p + c.quantity, 0)}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              Contenedor:{' '}
              <Ink.Chalk greenBright>
                {current.context.items[0].container.lpn}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              Ubicación:{' '}
              <Ink.Chalk greenBright>
                {current.context.items[0].container.location.name}
              </Ink.Chalk>
            </Ink.Text>

            <Ink.Button
              key='confirm'
              id='confirm'
              label='Confirmar Selección'
              onClick={handleClick}
            />
          </>
        ) : null}

        {current.matches('SelectRejectionReason') ? (
          <>
            <SelectRejectionReason />
          </>
        ) : null}

        {current.matches('CreateAnomaly') ? (
          <>
            <Ink.Text>Creando incidencia...</Ink.Text>
          </>
        ) : null}

        {current.matches('AnomalyCreated') ? (
          <>
            <Ink.Text>Incidencia creada.</Ink.Text>
          </>
        ) : null}

        {current.matches('BadQtyError') ? (
          <>
            <Ink.Chalk redBright>
              La cantidad solicitada no está disponible en este contenedor.
            </Ink.Chalk>
            <Ink.Button
              key='scan'
              id='scan'
              label='Volver a escanear items'
              onClick={handleClickScan}
            />
          </>
        ) : null}

        {current.matches('Initial') ? (
          <>
            {' '}
            <Ink.Text>Cargando...</Ink.Text>{' '}
          </>
        ) : null}
      </Ink.Box>
    );
  }
);
