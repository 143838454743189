import Ink from '@ink';
import React, { FC, useEffect, useState } from 'react';
import { AuthenticationPayload, useLogin } from '../../api/auth';
import { SESSION_TOKEN } from '../../session/useSession';

export const Auth: FC<{
  openSession: (user: AuthenticationPayload) => void;
}> = ({ openSession }) => {
  const [user, setUser] = useState({ recordIdOrUsername: '', password: '' });
  const [error, setError] = useState<string>('');
  const [focus, setFocus] = useState(true);

  const setUserName = recordIdOrUsername => {
    setUser(oldUser => ({
      ...oldUser,
      recordIdOrUsername,
      username: recordIdOrUsername
    }));
  };
  const setPassword = password => {
    setUser(oldUser => ({ ...oldUser, password }));
  };
  const [authPayload, requestState, request] = useLogin(user);

  const onSubmit = () => {
    if (user.recordIdOrUsername && user.password) {
      request();
    }
  };

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const savedToken = localStorage.getItem(SESSION_TOKEN);
      if (savedToken) {
        openSession(authPayload);
      }
    }
  }, []);

  useEffect(() => {
    if (requestState.error) {
      setError('Credenciales incorrectas');
      setPassword('');
      setFocus(true);
    }
    // check is logIn
    if (!requestState.error && authPayload) {
      openSession(authPayload);

      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(SESSION_TOKEN, authPayload.token);
      }
    }
  }, [authPayload, requestState]);

  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        <Ink.Text>Iniciar sesion</Ink.Text>
      </Ink.Header>

      <Ink.Box
        paddingLeft={1}
        paddingTop={1}
        height={9}
        flexDirection='column'
        justifyContent='space-between'
      >
        <Ink.Box
          flexDirection='column'
          marginBottom={1}
          justifyContent='space-between'
        >
          <Ink.Text>Ingrese Usuario</Ink.Text>
          <Ink.TextInput
            focus={focus}
            value={user.recordIdOrUsername}
            onChange={setUserName}
            onSubmit={() => {
              if (user.recordIdOrUsername) {
                setFocus(false);
              }
            }}
          />
          <Ink.Text>Ingrese Clave</Ink.Text>
          <Ink.TextInput
            focus={!focus}
            value={user.password}
            onChange={setPassword}
            onSubmit={() => {
              setFocus(false);
              onSubmit();
            }}
            mask='*'
          />
        </Ink.Box>

        {error ? (
          <Ink.Box>
            <Ink.Text color='redBright'>{error}</Ink.Text>
          </Ink.Box>
        ) : null}

        <Ink.Button
          label='INGRESAR'
          onClick={onSubmit}
          disabled={!user.recordIdOrUsername || !user.password}
        />
      </Ink.Box>
    </Ink.Box>
  );
};
Auth.displayName = 'Auth';
