import { ClassificationProcessStatuses } from '../ClassificationProcess';
import { ClassificationProcessStatus } from './ClassificationProcessStatus';

export class ClassificationProcessCompletedStatus extends ClassificationProcessStatus {
  public displayName = 'Completado';

  public internalName = ClassificationProcessStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
