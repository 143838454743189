import { StagingAuditingWaveStatuses } from '../StagingAuditingWave';
import { StagingAuditingWaveStatus } from './StagingAuditingWaveStatus';

export class StagingAuditingWaveCompletedStatus extends StagingAuditingWaveStatus {
  public displayName = 'Completado';

  public internalName = StagingAuditingWaveStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
