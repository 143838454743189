import { OrderTypes } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MidiPickingWaveContext } from './machine';

type MidiPickingWaveServices = MachineOptions<
  MidiPickingWaveContext,
  any
>['services'];

export const midiPickingWaveServices: MidiPickingWaveServices = {
  fetchPendingItemsCount: async (ctx, _evt) => {
    const { data } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/pending-pick-items`
    );
    return {
      pendingItems: data.pendingItems,
      totalItems:   data.totalItems,
      count:        data.pendingItems
    };
  },
  fetchSuggestedItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/next-pick-item`
    );
    return { pickItem: data };
  },
  reportProductInfoMismatch: (_ctx, _evt) => {
    // Reportar un error de que el producto no tiene la cantidad necesaria
    return Promise.resolve();
  },
  fetchPendingItemsToSortCount: async (ctx, _evt) => {
    const { data } = await api.get(
      `/pick-item/${ctx.currentPickItem.id}/pending-inventory-items-to-sort`
    );
    return {
      count: data.count
    };
  },
  getTypeDelivery: async (ctx, _evt) => {
    await Promise.resolve();
    const orderTypeName = ctx.currentPickItem?.orderItem.order.orderType
      .name as OrderTypes;
    const HDStatuses = [OrderTypes.WMSRetailHD];
    const ABStatuses = [OrderTypes.WMSRetailSS, OrderTypes.StoreSupplySellers];

    if (ABStatuses.includes(orderTypeName)) {
      return { type: 'AB' };
    } else if (HDStatuses.includes(orderTypeName)) {
      return { type: 'HD' };
    } else {
      return { type: 'HD' }; // Default fallback
    }
  },
  printHomeDeliveryLabels: async (ctx, _evt) => {
    await api.post('printer/midi/pick-item/home-delivery-andreani', null, {
      params: {
        pickItemId: ctx.currentPickItem.id
      }
    });
  }
};
