import { PackingWaveStatuses } from '../PackingWave';
import { PackingWaveStatus } from './PackingWaveStatus';

export class PackingWaveStatusProcessingStatus extends PackingWaveStatus {
  public displayName = 'Procesando';

  public internalName = PackingWaveStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
