import { MachineOptions } from 'xstate';
import { GetLocationByNameContext } from './machine';

type GetLocationByNameGuards = MachineOptions<
  GetLocationByNameContext,
  any
>['guards'];

export const getLocationByNameGuards: GetLocationByNameGuards = {
  isValidLocationName: (context, _event) => !!context.locationName,
  isSuccess:           (_context, event) => event.data.type === 'success'
};
