import { AxiosError } from 'axios';

export function isSerializationError(error?: AxiosError): boolean {
  return (
    error?.response?.data?.message ===
    'error de serialización de transacción a la hora de buscar tarea'
  );
}

export function isCantAuditError(error?: AxiosError): boolean {
  return error?.response?.status === 418;
}
