import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditingProcess } from './AuditingProcess';
import { ClassificationProcess } from './ClassificationProcess';
import { ConsolidateContainersProcess } from './ConsolidateContainersProcess';
import { ConsolidatingContainersToStagingProcess } from './ConsolidatingContainersToStagingProcess';
import { MopSorterExitProcess } from './MopSorterExitProcess';
import { MovingToAuditingLocationProcess } from './MovingToAuditingLocationProcess';
import { Order } from './Order';
import { PackingLocationProcess } from './PackingLocationProcess';
import { PackingProcess } from './PackingProcess';
import { PickingProcess } from './PickingProcess';
import { Process } from './Process';
import { SorterExitProcess } from './SorterExitProcess';
import { StagingAuditingProcess } from './StagingAuditingProcess';
import { Task } from './Task';
import { Warehouse } from './Warehouse';
import {
  OrderBatchAuditedStatus,
  OrderBatchAuditingStatus,
  OrderBatchDispatchedStatus,
  OrderBatchDispatchingStatus,
  OrderBatchNotPreparedStatus,
  OrderBatchPreparedStatus,
  OrderBatchPreparingStatus,
  OrderBatchStatus
} from './order-batch-status';

export const OrderBatchStatuses = {
  NotPrepared: 'not-prepared',
  Preparing:   'preparing',
  Prepared:    'prepared',
  Auditing:    'auditing',
  Audited:     'audited',
  Dispatching: 'dispatching',
  Dispatched:  'dispatched'
};

@Entity()
export class OrderBatch {
  @PrimaryKey()
  id: number;

  @Field()
  key: string;

  @Field()
  description: string;

  @Status({
    [OrderBatchStatuses.NotPrepared]: OrderBatchNotPreparedStatus,
    [OrderBatchStatuses.Preparing]:   OrderBatchPreparingStatus,
    [OrderBatchStatuses.Prepared]:    OrderBatchPreparedStatus,
    [OrderBatchStatuses.Auditing]:    OrderBatchAuditingStatus,
    [OrderBatchStatuses.Audited]:     OrderBatchAuditedStatus,
    [OrderBatchStatuses.Dispatching]: OrderBatchDispatchingStatus,
    [OrderBatchStatuses.Dispatched]:  OrderBatchDispatchedStatus
  })
  status: OrderBatchStatus;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Order, 'orderBatchId')
  orders: Order[];

  @Knows(() => Task, 'orderBatchId')
  tasks: Task[];

  @NeededBy(() => ConsolidatingContainersToStagingProcess, 'orderBatchId')
  consolidatingContainersToStagingProcesses: ConsolidatingContainersToStagingProcess[];

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => Process, { name: 'process_instance_id' })
  processInstanceId: number;

  @Knows(() => Process, 'processInstanceId')
  processInstance: Process;

  @Knows(() => PickingProcess, 'orderBatchId')
  pickingProcesses: PickingProcess[];

  @NeededBy(() => ClassificationProcess, 'orderBatchId')
  classificationProcesses: ClassificationProcess[];

  @NeededBy(() => PackingLocationProcess, 'orderBatchId')
  packingLocationProcess: PackingLocationProcess[];

  @NeededBy(() => PackingProcess, 'orderBatchId')
  packingProcesses: PackingProcess[];

  @NeededBy(() => SorterExitProcess, 'orderBatchId')
  sorterExitProcesses: SorterExitProcess[];

  @NeededBy(() => ConsolidateContainersProcess, 'orderBatchId')
  consolidateContainersProcesses: ConsolidateContainersProcess[];

  @NeededBy(() => AuditingProcess, 'orderBatchId')
  auditingProcesses: AuditingProcess[];

  @NeededBy(() => MovingToAuditingLocationProcess, 'orderBatchId')
  movingToAuditingLocationProcesses: MovingToAuditingLocationProcess[];

  @NeededBy(() => MopSorterExitProcess, 'orderBatchId')
  mopSorterExitProcesses: MopSorterExitProcess[];

  @NeededBy(() => StagingAuditingProcess, 'orderBatchId')
  stagingAuditingProcess: StagingAuditingProcess[];
}
