import { api } from '../../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../../utils/fetch-result';
import { PrintFn } from '../../../machine';

export function printMidiPickItemHomeDelivery(
  defaultErrorMessage: string,
  courier: 'andreani' | 'cruz-del-sur',
  pickItemId: number
): PrintFn {
  return fetchResult(defaultErrorMessage, () =>
    api.post<void>(`printer/midi/pick-item/home-delivery-${courier}`, null, {
      params: {
        pickItemId: pickItemId
      }
    })
  );
}
