import { PutAwayItemStatus } from './PutAwayItemStatus';

export class PutAwayItemStatusCosolidatedStatus extends PutAwayItemStatus {
  public displayName = 'Consolidado';

  public internalName = 'consolidated';

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
