import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { FulfillmentOrder } from './FulfillmentOrder';
import { ReceiptItem } from './ReceiptItem';
import { Warehouse } from './Warehouse';
import { ZipCode } from './ZipCode';

@Entity()
export class Address {
  @PrimaryKey()
  id: number;

  // TODO: Should be a fixed list of valid options.
  @Field()
  country: string;

  @Field()
  state: string;

  @Field()
  city: string;

  @Field()
  street: string;

  @Field({ name: 'street_number' })
  streetNumber: number;

  @Field({ name: 'between_streets' })
  betweenStreets: string;

  @Field({ name: 'geo_lat' })
  geoLat: string;

  @Field({ name: 'geo_long' })
  geoLong: string;

  @Field()
  filcar: string;

  @Field()
  observations: string;

  @Field()
  region: string;

  @Field()
  neighborhood: string;

  @Field()
  district: string;

  @ForeignKey(() => ZipCode, { name: 'zip_code_id' })
  zipCodeId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => ReceiptItem, 'addressId')
  receiptItems: ReceiptItem[];

  @Knows(() => FulfillmentOrder, 'addressId')
  fulfillmentOrders: FulfillmentOrder[];

  @Knows(() => Warehouse, 'addressId')
  warehouse: Warehouse;

  @Needs(() => ZipCode, 'zipCodeId')
  zipCode: ZipCode;
}
