import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusRejectedStatus extends ReceiptItemStatus {
  public displayName = 'Rechazado';

  public internalName = ReceiptItemStatuses.Rejected;

  public statusColor = '#ffd3d3';

  public textColor = '#c12424';
}
