import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ClassificationContainer } from './ClassificationContainer';
import { ClassificationItem } from './ClassificationItem';
import { OrderBatch } from './OrderBatch';
import {
  ClassificationProcessCancelledStatus,
  ClassificationProcessCompletedStatus,
  ClassificationProcessPausedStatus,
  ClassificationProcessPendingStatus,
  ClassificationProcessProcessingStatus,
  ClassificationProcessStatus
} from './classification-process-status';

export const ClassificationProcessStatuses = {
  Cancelled:  'cancelled',
  Completed:  'completed',
  Processing: 'processing',
  Paused:     'paused',
  Pending:    'pending'
};

@Entity()
export class ClassificationProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [ClassificationProcessStatuses.Cancelled]:
      ClassificationProcessCancelledStatus,
    [ClassificationProcessStatuses.Completed]:
      ClassificationProcessCompletedStatus,
    [ClassificationProcessStatuses.Processing]:
      ClassificationProcessProcessingStatus,
    [ClassificationProcessStatuses.Paused]:  ClassificationProcessPausedStatus,
    [ClassificationProcessStatuses.Pending]: ClassificationProcessPendingStatus
  })
  status: ClassificationProcessStatus;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(() => ClassificationContainer, 'classificationProcessId')
  classificationContainers: ClassificationContainer[];

  @NeededBy(() => ClassificationItem, 'classificationProcessId')
  classificationItems: ClassificationItem[];
}
