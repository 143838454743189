import { InventoryItemQualityStatuses } from '../InventoryItem';
import { InventoryItemQualityStatus } from './InventoryItemQualityStatus';

export class InventoryItemQualityStatusDamagedStatus extends InventoryItemQualityStatus {
  public displayName = 'Dañado';

  public internalName = InventoryItemQualityStatuses.Damaged;

  public statusColor = '#ffd3d3';

  public textColor = '#b90c0c';
}
