import { MachineOptions, assign } from 'xstate';
import { MiniMopMoveToSorterExitContext } from './machine';

type MiniMopMoveToSorterExitActions = MachineOptions<
  MiniMopMoveToSorterExitContext,
  any
>['actions'];

export const miniMopMoveToSorterExitActions: MiniMopMoveToSorterExitActions = {
  assignContainer: assign({
    container: (_ctx, event) => event.data
  }),
  assignLocation: assign({
    location: (_ctx, event) => event.data
  })
};
