import { AuditingProcessStatuses } from '../AuditingProcess';
import { AuditingProcessStatus } from './AuditingProcessStatus';

export class AuditingProcessProcessingStatus extends AuditingProcessStatus {
  public displayName = 'Procesando';

  public internalName = AuditingProcessStatuses.Processing;

  /* public statusColor = '#64b5f6';

  public textColor = '#ffa300';*/

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
