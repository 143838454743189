import { MopSorterExitItemStatuses } from '../MopSorterExitItem';
import { MopSorterExitItemStatus } from './MopSorterExitItemStatus';

export class MopSorterExitItemMovingStatus extends MopSorterExitItemStatus {
  public displayName = 'Moviendo';

  public internalName = MopSorterExitItemStatuses.Moving;

  public statusColor = '#e2f1f8';
}
