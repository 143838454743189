import { TransferOrderStatus } from './TransferOrderStatus';

export class TransferOrderStatusOpenStatus extends TransferOrderStatus {
  public displayName = 'Abierto';

  public internalName = 'open';

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
