import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { PackagingLevel } from './PackagingLevel';

export enum UOMName {
  CAJA = 'CAJA',
  UNIDAD = 'UNIDAD'
}
@Entity()
export class UOM {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => PackagingLevel, 'uomId')
  packagingLevels: PackagingLevel[];
}
