import { api } from '../../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../../utils/fetch-result';
import { PrintFn } from '../../../machine';

export function printMiniPackingItemHomeDelivery(
  defaultErrorMessage: string,
  courier: 'andreani' | 'cruz-del-sur',
  packingItemId: number,
  packingLocationId: number
): PrintFn {
  return fetchResult(defaultErrorMessage, () =>
    api.post<void>(`printer/mini/packing-item/home-delivery-${courier}`, null, {
      params: {
        packingItemId: packingItemId,
        locationId:    packingLocationId
      }
    })
  );
}
