import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchPreparingStatus extends OrderBatchStatus {
  public displayName = 'Preparando';

  public internalName = OrderBatchStatuses.Preparing;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}
