import Ink from '@ink';
import React, { FC, useState } from 'react';

export const ScanPieces: FC<{
  suggestedPieces: number | null;
  fracQty: number | null;
  piecesEntered: (pieces: string) => void;
}> = ({ suggestedPieces, fracQty, piecesEntered }) => {
  const [inputPieces, setPieces] = useState('');

  return (
    <>
      {fracQty ? (
        <Ink.Text>
          Fraccione e ingrese la cantidad de piezas de{' '}
          <Ink.Chalk greenBright>{fracQty}</Ink.Chalk> g creadas.
        </Ink.Text>
      ) : null}

      {suggestedPieces ? (
        <>
          <Ink.Text>
            Piezas Sugeridas:
            <Ink.Chalk greenBright>{suggestedPieces}</Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      <Ink.TextInput
        value={inputPieces}
        onChange={setPieces}
        onSubmit={piecesEntered}
      />
    </>
  );
};

ScanPieces.displayName = 'ScanPieces';
