import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ConsolidateContainersItem } from './ConsolidateContainersItem';
import { ConsolidateContainersProcess } from './ConsolidateContainersProcess';
import { Location } from './Location';
import {
  ConsolidateContainersWaveStatus,
  ConsolidateContainersWaveStatusCancelled,
  ConsolidateContainersWaveStatusCompleted,
  ConsolidateContainersWaveStatusPaused,
  ConsolidateContainersWaveStatusProcessing
} from './consolidate-container-wave-status';
import { ConsolidateContainersWaveStatusPending } from './consolidate-container-wave-status/ConsolidateContainersWaveStatusPending';

export const ConsolidateContainersWaveStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Paused:     'paused',
  Cancelled:  'cancelled'
};

@Entity()
export class ConsolidateContainersWave {
  @PrimaryKey()
  id: number;

  @Status({
    [ConsolidateContainersWaveStatuses.Cancelled]:
      ConsolidateContainersWaveStatusCancelled,
    [ConsolidateContainersWaveStatuses.Completed]:
      ConsolidateContainersWaveStatusCompleted,
    [ConsolidateContainersWaveStatuses.Paused]:
      ConsolidateContainersWaveStatusPaused,
    [ConsolidateContainersWaveStatuses.Processing]:
      ConsolidateContainersWaveStatusProcessing,
    [ConsolidateContainersWaveStatuses.Pending]:
      ConsolidateContainersWaveStatusPending
  })
  status: ConsolidateContainersWaveStatus;

  @ForeignKey(() => ConsolidateContainersProcess, {
    name: 'consolidate_containers_process_id'
  })
  consolidateContainersProcessId: number;

  @ForeignKey(() => Location, {
    name: 'origin_location_id'
  })
  originLocationId: number;

  @ForeignKey(() => Location, {
    name: 'target_location_id'
  })
  targetLocationId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => ConsolidateContainersProcess, 'consolidateContainersProcessId')
  consolidateContainersProcess: ConsolidateContainersProcess;

  @Needs(() => Location, 'originLocationId')
  originLocation: Location;

  @Needs(() => Location, 'targetLocationId')
  targetLocation: Location;

  @Knows(() => ConsolidateContainersItem, 'consolidateContainersWaveId')
  consolidateContainersItems: ConsolidateContainersItem[];
}
