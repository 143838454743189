import { MopPickingContainerStatuses } from '../MopPickingContainer';
import { MopPickingContainerStatus } from './MopPickingContainerStatus';

export class MopPickingContainerMovingStatus extends MopPickingContainerStatus {
  public displayName = 'Moviendo';

  public internalName = MopPickingContainerStatuses.Moving;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
