import { ClassificationProcessStatuses } from '../ClassificationProcess';
import { ClassificationProcessStatus } from './ClassificationProcessStatus';

export class ClassificationProcessProcessingStatus extends ClassificationProcessStatus {
  public displayName = 'Procesando';

  public internalName = ClassificationProcessStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
