import { StockTransferStatus } from './StockTransferStatus';

export class StockTransferPendingStatus extends StockTransferStatus {
  public displayName = 'Pendiente';

  public internalName = 'pending';

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
