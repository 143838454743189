import { FC } from 'react';
import { BoxProps } from './components/Box';
import { ButtonProps } from './components/Button';
import { ChalkProps } from './components/Chalk';
import { HeaderProps } from './components/Header';
import { NewlineProps } from './components/Newline';
import { SelectInputProps } from './components/SelectInput';
import { TextProps } from './components/Text';
import { TextInputProps } from './components/TextInput';

export interface InkModule {
  Box: FC<BoxProps>;
  Button: FC<ButtonProps>;
  Chalk: FC<ChalkProps>;
  Header: FC<HeaderProps>;
  Newline: FC<NewlineProps>;
  SelectInput<V>(props: SelectInputProps<V>): JSX.Element;
  Text: FC<TextProps>;
  TextInput: FC<TextInputProps>;
  useInput: (inputHandler: any, options?: any) => void;
}

export const inkModule: InkModule = {} as any;

export function initializeInk(module: InkModule): void {
  inkModule.Box = module.Box;
  inkModule.Button = module.Button;
  inkModule.Chalk = module.Chalk;
  inkModule.Header = module.Header;
  inkModule.Newline = module.Newline;
  inkModule.SelectInput = module.SelectInput;
  inkModule.Text = module.Text;
  inkModule.TextInput = module.TextInput;
  inkModule.useInput = module.useInput;
}
