import Ink from '@ink';
import { useActor } from '@xstate/react';
import { isDecimal } from 'class-validator';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterCheckDigitMachine } from './EnterCheckDigitMachine';

export const EnterCheckDigit: FC = withActorRef(EnterCheckDigitMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const setCheckDigit = useCallback(
      (input: string) => {
        if (isDecimal(input)) {
          send({
            type: 'updateCheckDigit',
            data: { checkDigit: parseInt(input) }
          });
        }
      },
      [send]
    );

    const submitCheckDigit = useCallback(() => {
      send('submitCheckDigit');
    }, [send]);

    return (
      <>
        <Ink.Text bold>Ingrese dígito verificador:</Ink.Text>

        <Ink.TextInput
          value={
            typeof current.context.checkDigit === 'number'
              ? current.context.checkDigit.toString()
              : ''
          }
          onChange={setCheckDigit}
          onSubmit={submitCheckDigit}
        />
      </>
    );
  }
);
