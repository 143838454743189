import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';

import { Container } from './Container';
import { PackingLocationProcess } from './PackingLocationProcess';
import { PickingWave } from './PickingWave';
import { WMSTransportOrder } from './WMSTransportOrder';
import {
  PackingLocationItemStatus,
  PackingLocationItemStatusCancelledStatus,
  PackingLocationItemStatusDeliveredStatus,
  PackingLocationItemStatusDraftStatus,
  PackingLocationItemStatusMovingStatus
} from './packing-location-item-status';

export const PackingLocationItemStatuses = {
  Draft:     'draft',
  Moving:    'moving',
  Delivered: 'delivered',
  Cancelled: 'cancelled'
};

@Entity()
export class PackingLocationItem {
  @PrimaryKey()
  id: number;

  @Status({
    [PackingLocationItemStatuses.Cancelled]:
      PackingLocationItemStatusCancelledStatus,
    [PackingLocationItemStatuses.Moving]:    PackingLocationItemStatusMovingStatus,
    [PackingLocationItemStatuses.Delivered]:
      PackingLocationItemStatusDeliveredStatus,
    [PackingLocationItemStatuses.Draft]: PackingLocationItemStatusDraftStatus
  })
  status: PackingLocationItemStatus;

  @ForeignKey(() => PackingLocationProcess, {
    name: 'packing_location_process_id'
  })
  packingLocationProcessId: number;

  @ForeignKey(() => PickingWave, { name: 'picking_wave_id' })
  pickingWaveId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => PackingLocationProcess, 'packingLocationProcessId')
  packingLocationProcess: PackingLocationProcess;

  @Needs(() => PickingWave, 'pickingWaveId')
  pickingWave: PickingWave;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Knows(() => WMSTransportOrder, 'packingLocationItemId')
  wmsTransportOrder: WMSTransportOrder;
}
