import { Location, Product, Task } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import {
  DefaultEnterCheckDigitContext,
  EnterCheckDigitMachine
} from '../../../capa-4/enter-check-digit/EnterCheckDigitMachine';
import {
  DefaultEnterQuantityContext,
  EnterQuantityMachine
} from '../../../capa-4/enter-quantity/EnterQuantityMachine';
import {
  DefaultScanProductContext,
  ScanProductIdentifierMachine
} from '../../../capa-4/scan-product/ScanProductIdentifierMachine';
import { InboundDirectReceptionService } from './InboundDirectReception.service';

export interface InboundDirectReceptionContext {
  task: Task | null;
  payload: any;
  receiptId: number | null;
  error: string | null;

  currentProduct: Product | null;
  currentQuantity: number | null;

  checkDigit: string | null;
  suggestedStorageLocation: Location | null;
}

export const DefaultInboundDirectReceptionContext = {
  task:      null,
  payload:   null,
  receiptId: null,
  error:     null,

  currentProduct:  null,
  currentQuantity: null,

  checkDigit:               null,
  suggestedStorageLocation: null
};

export const InboundDirectReceptionMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEkB2AjA9gV1RAIgJYBOYAxgC4BK5YADhYZqgLICGZAFoamAHRpCjNgBtCALx5QAxAG0ADAF1EoOplhCmqFSAAeiAIwGAHACY+AVgCcAZgDsdgCxHjVu-IsAaEAE9Epxzs+NwA2CwtHRwsQkwMrAF947zQsXAIScmpaBi12Lh5+AGUyNlQABWJMCGxKABVMGjIwQgA3MGkIZn4eFswAa34UnDwiUkpG+kZmPO5ePmLSiqqainqJ1rAEHswSqdQFRQOdNQ09nX0EEysDYNNTeXk7EIfjDxtvPwRTCyCQv7CbBZjJEwo5EskMMN0mMsk0ctMOLN+ABRVAUMDEKQARWwpUYFB8HS6fG2AwEkLSo0yE3hrERBT4qPRmNQUBxeKEPi2qF6uy0ByOSBAJ00zHO-lMIUcfFMVzskr+dmMNkcH38Pz4-wBQJBEXBICGlIy42yexmDPYxD6UgAgrB1m0IES5qTBhSRsbYZNcvS5pbray7Q7INzeWw9gKlMd1KLtEKLpKDPI+AZHPIzEZTHYDBZU2qvq8+DZbLKbPJJcYgXZ9YaPTCaWbffwAGJgCj5VmFbBQGCwdEQQoUTDENgwAAyO3DWmd3R5-TdqTr1NNPo7LbbHagXZ7cH7g+Ho7AE75zFDk4jSkFqhjZ3j-hiydT6dlBizObzvkQxhujisf7MVghKYZYmDYNbutCy5wo2a6MmiGJSPuI7jueWgAMKcOQfREFAQgziSc5krWkEmtBq5InBzKIUOyFHqhzAYVhOFCGeJ77JeUZCiKt6gBcNhATK37fDYr6OGEcT5iqxjBAYfxJqY37yL+8hgUkBoQVSpHegisH+rasBIZA+GuuSi4kV6tLmn6bBWvphkQKxU7MJGyhcTeWjigg7g3HYKo2K49huI8knZpqjgiSEbjAZEwHgWZmkWTBFGMWQAZQMgABmAASbCwCww5gMg6IALawGstAbMZhELlCCUNuRDIpWlmU5XlBVFWApXlU0GyORehycdepweXelwmOY1j2E4LhBV4n5fFYFh8IEYQWCpDiAuFcW1Z69U6clmGpVILW5flpAdV1DQVW0VW9ERGm7Su+2NYdzXZad7UlWVV09W0fX8peBiuUNsaeS4E22A4zhyh4kmVnwAUGL5TjyM8YnbUa9ZPXSsFoZgxV0CIbZgLUuV9Ld86mTtWNkc9cx4wTRPoqTsB9P9zkccDwruWKo1XDcVh3A8TwvG8cNLYjyNpmjISJGpqBVHAOjEXV2NWWA0bDbzvGIAAtCE+b63wDwPLKVivOWItgmpKuPbTOMUYIwhiJIrKa6DfPljYmoBH+f7yFFf75nc3srREKlhApBiqRC8V29pDsMgs5SVNUdQ-c0bTuzxeiGH8VgI3Ya3RNm-mSu883fBLthxAF1hAumGNLlpllNpRCGsuyaKctnI06186YhJYtiC4EAfuHYwfhAjNfm7Y9evMYTfmXtifWbZgb2tdkC99rudfNLhcWLKAcqgHxhT9XIlz4Ci2L8vqv2+rfCtu2sxbt2vZ7jRh7Hk5cYgxzgma40ljBFxEpETMIQi7B0cOYFaMQszZgDrYB+8dW6wSZB3LcP8UJsSasxCgu8AH7zgZJESMpJTRCsGJJ4AFZY2wejTBOz89Kb3ssQzyuYfJ+UikBYwq0aGSUCCmcKOZog2G+M4JejC47MIwQdLCx13ptXOl9bqmcNZuS1iQi4AiC7ZjAZEfywsDDCKCKmES4QQiSIiCYNB8ikqNXxoTYmLM+icL5tA5MHhAjykFqWCwFdPj8RuMWSRwEVKIwYbHamUEWFt2bDwQgsBMIQE8f3e4Ajh7FgCO4IKk95r+W9lKCJdwQivEWqpRIQA */
  createMachine<InboundDirectReceptionContext>(
    {
      id:      'InboundDirectReceptionMachine',
      initial: 'Initializing',
      states:  {
        Initializing: {
          entry: assign({
            receiptId: context => context.payload.receiptId
          }),
          always: [
            {
              target: 'ScanProductToReceive'
            }
          ]
        },
        ScanProductToReceive: {
          invoke: {
            id:     ScanProductIdentifierMachine.id,
            src:    ScanProductIdentifierMachine,
            data:   DefaultScanProductContext,
            onDone: {
              actions: assign({
                currentProduct: (ctx, event) => event.data.product
              }),
              target: 'EnteringQuantity'
            }
          },
          exit: 'clearError'
        },
        EnteringQuantity: {
          invoke: {
            id:     EnterQuantityMachine.id,
            src:    EnterQuantityMachine,
            data:   DefaultEnterQuantityContext,
            onDone: {
              actions: assign({
                currentQuantity: (ctx, event) => event.data.quantity
              }),
              target: 'ValidatingProductAndQuantity'
            }
          }
        },

        ValidatingProductAndQuantity: {
          invoke: {
            src:    'ValidatingProductAndQuantity',
            onDone: [
              {
                target: 'MarkingAsReceived',
                cond:   'isProductAndQuantityValid'
              },
              {
                target:  'ScanProductToReceive',
                actions: 'assignInvalidProductError'
              }
            ]
          }
        },
        MarkingAsReceived: {
          invoke: {
            src:    'receiveItem',
            onDone: {
              target: 'FetchingSuggestedStorageLocation'
            }
          }
        },
        FetchingSuggestedStorageLocation: {
          invoke: {
            src:    'fetchSuggestedStorageLocation',
            onDone: {
              actions: assign({
                suggestedStorageLocation: (_ctx, event) => event.data.data
              }),
              target: 'EnteringStorageLocationCheckDigit'
            }
          }
        },
        EnteringStorageLocationCheckDigit: {
          invoke: {
            id:     EnterCheckDigitMachine.id,
            src:    EnterCheckDigitMachine,
            data:   DefaultEnterCheckDigitContext,
            onDone: {
              actions: assign({
                checkDigit: (_ctx, event) => event.data.checkDigit
              }),
              target: 'MarkingAsStored'
            }
          }
        },
        MarkingAsStored: {
          invoke: {
            src:    'storeItem',
            onDone: {
              target: 'CheckingIfHasMoreItemsToReceive'
            }
          }
        },
        CheckingIfHasMoreItemsToReceive: {
          invoke: {
            src:    'countPendingItemsToReceive',
            onDone: [
              {
                target: 'ScanProductToReceive',
                cond:   'hasMoreItemsToReceive'
              },
              {
                target: 'CompleteTask'
              }
            ]
          }
        },
        CompleteTask: {
          invoke: {
            src:    'completeTask',
            onDone: {
              target: 'Finished'
            }
          }
        },
        Finished: {
          type: 'final'
        }
      }
    },
    {
      actions: {
        clearError: assign({
          error: (_ctx, _event) => null
        }),
        assignInvalidProductError: assign({
          error: (_context, event) => event.data.errorView
        })
      },
      services: {
        ...InboundDirectReceptionService
      },
      guards: {
        hasMoreItemsToReceive:     (_ctx, event) => event.data.count > 0,
        isProductAndQuantityValid: (_ctx, event) => event.data.isValid
      }
    }
  );
