import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusDamagedStatus extends OrderItemStatus {
  public displayName = 'Dañado';

  public internalName = OrderItemStatuses.Damaged;

  public statusColor = '#ffd3d3';

  public textColor = '#b90c0c';
}
