import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { Process } from './Process';

@Entity()
export class ProcessInstanceListener {
  @PrimaryKey()
  id: number;

  @Field({ name: 'event_pattern' })
  eventPattern: string;

  @ForeignKey(() => Process, { name: 'process_listener_id' })
  processListenerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Process, 'processListenerId')
  process: Process;
}
