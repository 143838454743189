import { ReservationStatuses } from '../Reservation';
import { ReservationStatus } from './ReservationStatus';

export class ReservationStatusCancelledStatus extends ReservationStatus {
  public displayName = 'Cancelada';

  public internalName = ReservationStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
