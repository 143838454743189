import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { LocationDetailsMachine } from './LocationDetailsMachine';

export const LocationDetails: FC = withActorRef(LocationDetailsMachine)(
  ({ actorRef }) => {
    const [{ matches, context }] = useActor(actorRef);

    const unstockedItems = context.location?.inventoryItems.filter(
      ii => !ii.containerId
    );

    return matches('ShowingDetails') ? (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Text>
          NAME:
          <Ink.Chalk greenBright bold>
            {context.location!.name}
          </Ink.Chalk>
        </Ink.Text>
        <Ink.Box flexDirection='column'>
          <Ink.Text>Contendores:</Ink.Text>
          {context.location!.containers.length > 0 ? (
            context.location!.containers.map((c, i) => (
              <Ink.Box key={i} flexDirection='column'>
                <Ink.Text>
                  LPN: <Ink.Chalk greenBright>{c.lpn}</Ink.Chalk>
                </Ink.Text>
                {c.inventoryItems.length > 0 ? (
                  c.inventoryItems.map((ii, j) => {
                    return (
                      <Ink.Box key={j} marginLeft={10}>
                        <Ink.Text>{ii.quantity} x</Ink.Text>
                        {ii.product ? (
                          <Ink.Chalk greenBright>
                            {ii.product.sku + ' - ' + ii.product.name}
                          </Ink.Chalk>
                        ) : (
                          <Ink.Chalk redBright>NO PRODUCT INFO</Ink.Chalk>
                        )}
                      </Ink.Box>
                    );
                  })
                ) : (
                  <Ink.Chalk redBright bold>
                    No hay ítems en este contendor
                  </Ink.Chalk>
                )}
              </Ink.Box>
            ))
          ) : (
            <Ink.Chalk redBright bold>
              No hay contenedores
            </Ink.Chalk>
          )}
        </Ink.Box>
        <Ink.Box flexDirection='column'>
          <Ink.Text>Items Sueltos:</Ink.Text>
          {unstockedItems && unstockedItems.length > 0 ? (
            context.location!.inventoryItems.map((ii, i) => (
              <Ink.Box key={i} justifyContent='flex-start'>
                <Ink.Text>{ii.quantity} x</Ink.Text>
                <Ink.Chalk greenBright>
                  {ii.product.sku + ' - ' + ii.product.name}
                </Ink.Chalk>{' '}
              </Ink.Box>
            ))
          ) : (
            <Ink.Chalk redBright bold>
              No hay ítems sueltos
            </Ink.Chalk>
          )}
        </Ink.Box>
      </Ink.Box>
    ) : (
      <Ink.Chalk greenBright>Loading...</Ink.Chalk>
    );
  }
);

LocationDetails.displayName = 'LocationDetails';
