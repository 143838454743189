import { PickingContainerGroupStatuses } from '../PickingContainerGroup';
import { PickingContainerGroupStatus } from './PickingContainerGroupStatus';

export class PickingContainerGroupStatusClosed extends PickingContainerGroupStatus {
  public displayName = 'Cerrado';

  public internalName = PickingContainerGroupStatuses.Closed;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
