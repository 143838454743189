import { StockTableStatuses } from '../StockTable';
import { StockItemStatus } from './StockItemStatus';

export class StockItemStatusAvailableStatus extends StockItemStatus {
  public displayName = 'Disponible';

  public internalName = StockTableStatuses.Available;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
