import { useListApi, useManualApi } from '.';

export function useGetContainerTypes(offset: number) {
  return useListApi(
    {
      method: 'GET',
      url:    'container-type'
    },
    offset
  );
}

export function useConfirmContainerQuantities(containerTypeList, receiptId) {
  return useManualApi<void>({
    method: 'POST',
    url:    'container-type/confirm-containters-quantities',
    params: {
      receiptId: receiptId
    },
    data: { containerTypeList }
  });
}
