import { PickingWaveStatuses } from '../PickingWave';
import { PickingWaveStatus } from './PickingWaveStatus';

export class PickingWaveStatusPendingStatus extends PickingWaveStatus {
  public displayName = 'Pendiente';

  public internalName = PickingWaveStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
