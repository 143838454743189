import { MachineOptions } from 'xstate';
import { MiniPickingWaveContext } from './machine';

type MiniPickingWaveGuards = MachineOptions<
  MiniPickingWaveContext,
  any
>['guards'];

export const miniPickingWaveGuards: MiniPickingWaveGuards = {
  hasQuantityToPick: (ctx, _event) => {
    return ctx.pickedQuantity !== ctx.currentPickItem?.quantity;
  },
  pendingItemsRemaining: (_ctx, event) => {
    return event.data.pendingItems > 0;
  }
};
