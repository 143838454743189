import { StockTableStatuses } from '../StockTable';
import { StockItemStatus } from './StockItemStatus';

export class StockItemStatusAnomalyStatus extends StockItemStatus {
  public displayName = 'Con Anomalía';

  public internalName = StockTableStatuses.Anomaly;

  public statusColor = '#ffd3d3';

  public textColor = '#c12424';
}
