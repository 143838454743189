import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { ScanItem } from '../../capa-4/scan-item/ScanItem';
import { AuditContainersTaskMachine } from './AuditContainersTaskMachine';

export const AuditContainersTask = withActorRef(AuditContainersTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const menuItems = [
      {
        key:         'confirm-audit',
        displayName: '2. Confirmar Auditoría.',
        event:       'continue'
      },
      { key: 'back', displayName: '2. Volver', event: 'goBack' }
    ];

    const handleClick = useCallback((id: string) => {
      const item = menuItems.find(menuItem => menuItem.key === id);
      if (item) send(item.event);
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingX={2}>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.orderId ? (
                'AUDITANDO ORDEN'
              ) : (
                <>
                  AUDITANDO ORDEN:{' '}
                  <Ink.Chalk greenBright>{current.context.orderId}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>

        {current.matches('ScanningLocation') ? (
          <>
            <Ink.Box>
              <Ink.Text>
                Dirijase a:{' '}
                <Ink.Chalk greenBright bold>
                  {current.context.auditLocation!.name}
                </Ink.Chalk>
              </Ink.Text>
            </Ink.Box>

            <ScanControlDigit />
          </>
        ) : null}

        {current.matches('LocationScanned') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}

        {current.matches('SelectContainer') ? (
          <>
            <Ink.Box>
              <Ink.Text>
                Auditando en canal:{' '}
                <Ink.Chalk greenBright bold>
                  {current.context.auditLocation?.name || ''}
                </Ink.Chalk>
              </Ink.Text>
            </Ink.Box>

            <ScanContainer />
          </>
        ) : null}

        {current.matches('IteratingOverItemsInContainer') ? (
          <>
            <Ink.Box>
              <Ink.Text>
                Auditando en canal:{' '}
                <Ink.Chalk greenBright bold>
                  {current.context.auditLocation?.name || ''}
                </Ink.Chalk>
              </Ink.Text>
            </Ink.Box>

            <Ink.Box>
              <Ink.Text>
                Contenedor:{' '}
                <Ink.Chalk greenBright bold>
                  {current.context.currentContainer?.lpn || ''}
                </Ink.Chalk>
              </Ink.Text>
            </Ink.Box>

            <ScanItem />
          </>
        ) : null}

        {current.matches('ConfirmAudit') ? (
          <Ink.Box flexDirection='column'>
            <Ink.Text inline>
              Se ha completado la auditoría de la orden #
              <Ink.Chalk greenBright>
                {current.context.orderId!.toString()}
              </Ink.Chalk>
            </Ink.Text>
            {menuItems.map(item => (
              <Ink.Button
                key={item.key}
                id={item.key}
                label={item.displayName}
                onClick={handleClick}
              />
            ))}
          </Ink.Box>
        ) : null}
      </Ink.Box>
    );
  }
);
