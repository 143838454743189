import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusPartiallyRejectedStatus extends ReceiptItemStatus {
  public displayName = 'Parcialmente Rechazado';

  public internalName = ReceiptItemStatuses.PartiallyRejected;

  public statusColor = '#ffd3d3';

  public textColor = '#c12424';
}
