import { RejectionReason } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';

export interface SelectRejectionReasonContext {
  rejectionReasons: RejectionReason[];
  selected: RejectionReason | null;
}

export const DefaultSelectRejectionReasonContext = {
  rejectionReasons: [],
  selected:         null
};

export const SelectRejectionReasonMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGUDGBDAdgSQC5gFsA6AS0xNxPQBsSAvMAYkVAAcB7WCk9zFkAB6IALAE4ArEQBswgOyipogIwAOYQGYADEuEAaEAE9EKzbKKzxS2Us0AmYeK2iAvs-1oseQkXQB3dNyYUABC6ABOqOwQYB6YjKxhUQCuqLixmJD8HFyUvPxCCFq2ROriouqyKuImtrIO4vpGhXJEmm122lay6uq2ru4YOPjEsIMAigAqAJqMY7gGAKKY+GGZSCDZ3HnrBU6tsqYq8rJS9rKyjYgAtD1EwiqiylJVj0qO-SCxXsQAZmC4qAAFmQoN8AGKJAjYTAANzAy3YYQMjAgvDApFh7AA1uivsMiH8AcCguDIdC4QikQgyDD2BhcpgANqaAC6WU4Wz4O0QFSkRFU9xUSnKZXktiklwQwtErXaNlsthFZT6bk+g2+BP+QJBpPYUNh8NwiORYDCiTCRFY1HQuB+iOIeO8hO1JOGEL15MNxupmPpPCZrPZOX9+R5dhKZQqVRqdXEDUMiCkmhUsrab3UwmEmh6sg+juICWSqTB7CSmAgzHWmwZoYQKlsmiI9aFFUqUkUckltlURHEcpM1hUUmFudV+aISxWABl2LhGNRZ5PTWs2Bya9y6+L+aJZOLHNm5L1JaVe1JebvxHIDy4PpgonB+OOyNwaPQwEHObXxSnu+KpLvuhOaxJWURsdykS9xEqYRh3uPN1XxPwAkoIJQgie9Yg-ddQAKWDzCFcVs0eNR4yaU5hF7OVqjjPs3ngzx8VGLBJimLCQw3HQf1sExRGzNRdwUdRJRkXsbE0KCFSgpR-yUeihidLViVBN0yQNSkmlXYNthw64xKIbjVHkYUYK0I5j00PlrDkCwLFOPsVQGBjvELCAUlwEsywgNjtMERMKiIXjTggywHAVUQuzqVM7HuZQNFsd4xwQ7wlzCGdcG8rkdIQCCfz7bQswg9QZFsSUdEkPK7G4+ph16OSNXBUtywy2sbGUflBzUBtiu0UrLyi6T62k0xSjq4Zmo3K4lHsfShSOZRRBM5MLgTBBJvEu4HlipNFHUHcVVcIA */
  createMachine<SelectRejectionReasonContext>(
    {
      context: DefaultSelectRejectionReasonContext,
      id:      'SelectRejectionReason',
      initial: 'initialize',
      states:  {
        initialize: {
          always: {
            target: 'fetchingRejectionReasons'
          }
        },
        fetchingRejectionReasons: {
          invoke: {
            src:    'fetchRejectionReasons',
            onDone: {
              actions: assign({
                rejectionReasons: (ctx, evt) => evt.data.rejectionReasons
              }),
              target: 'listRejectionReasons'
            }
          }
        },
        listRejectionReasons: {
          on: {
            select: {
              target:  'rejectionReasonSelected',
              actions: assign({
                selected: (ctx, event) => event.data.id
              })
            }
          }
        },
        rejectionReasonSelected: {
          type: 'final',
          data: {
            selected: ctx => ctx.selected
          }
        }
      }
    },
    {
      guards: {
        ...UtilityGuards
      },
      actions: {
        ...UtilityActions
      },
      services: { ...API }
    }
  );
