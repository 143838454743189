import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditOrder } from './AuditOrder';
import { Container } from './Container';
import { OrderItem } from './OrderItem';
import {
  AuditOrderItemCompletedStatus,
  AuditOrderItemPausedStatus,
  AuditOrderItemPendingStatus,
  AuditOrderItemStatus
} from './audit-order-item-status';

export const AuditOrderItemStatuses = {
  Pending:   'pending',
  Paused:    'paused',
  Completed: 'completed'
};

@Entity()
export class AuditOrderItem {
  @PrimaryKey()
  id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'sku' })
  sku: string;

  @ForeignKey(() => AuditOrder, { name: 'audit_order_id' })
  auditOrderId: number;

  @Needs(() => AuditOrder, 'auditOrderId')
  audit: AuditOrder;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Field({ name: 'quantity' })
  quantity: number;

  @Field({ name: 'external_id' })
  externalId: string;

  @ForeignKey(() => OrderItem, { name: 'order_item_id' })
  orderItemId: number;

  @Knows(() => OrderItem, 'orderItemId')
  orderItem: OrderItem;

  @Status({
    [AuditOrderItemStatuses.Pending]:   AuditOrderItemPendingStatus,
    [AuditOrderItemStatuses.Completed]: AuditOrderItemCompletedStatus,
    [AuditOrderItemStatuses.Paused]:    AuditOrderItemPausedStatus
  })
  status: AuditOrderItemStatus;
}
