import { useCallback, useEffect, useState } from 'react';
import { AuthenticationPayload } from '../api/auth';

export const SESSION_TOKEN = 'session_token';

export interface SessionApi {
  token: string | null;
  openSession: (res: AuthenticationPayload) => void;
  closeSession: () => void;
}

export function useSession(): SessionApi {
  const [token, setToken] = useState<string | null>(() =>
    typeof localStorage !== 'undefined'
      ? localStorage.getItem(SESSION_TOKEN)
      : null
  );

  const openSession = useCallback(
    (res: AuthenticationPayload) => res && setToken(res.token),
    [setToken]
  );

  const closeSession = useCallback(() => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(SESSION_TOKEN);
    }

    setToken(null);
  }, [setToken]);

  useEffect(() => {
    if (token && typeof localStorage !== 'undefined') {
      localStorage.setItem(SESSION_TOKEN, token);
    }
  }, [token]);

  return { token, openSession, closeSession };
}
