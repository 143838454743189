import { ClassificationProcessStatuses } from '../ClassificationProcess';
import { ClassificationProcessStatus } from './ClassificationProcessStatus';

export class ClassificationProcessPendingStatus extends ClassificationProcessStatus {
  public displayName = 'Pendiente';

  public internalName = ClassificationProcessStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
