import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusSortingStatus extends ReceiptItemStatus {
  public displayName = 'Almacenando';

  public internalName = ReceiptItemStatuses.Sorting;

  public statusColor = '#fbe7d9';

  public textColor = '#e77b00';
}
