import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MaxiConsolidateContainersMachine } from './machine';

export const MaxiConsolidateContainersTask = withActorRef(
  MaxiConsolidateContainersMachine
)(({ actorRef }) => {
  const [current] = useActor(actorRef);
  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        <Ink.Text bold>
          Traslado N {current.context.consolidateContainersWaveId} - Selectivos
          Maxi
        </Ink.Text>
      </Ink.Header>
      {/* Header */}
      <Ink.Box flexDirection='column'>
        {current.matches('ScanningPalletTruck') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Escanear transpaleta</Ink.Text>
          </Ink.Box>
        )}

        {current.matches('ScanningContainer1') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>Cargue 1er contenedor</Ink.Text>
            <Ink.Text>
              Trasladese hasta la ubicacion:{' '}
              <Ink.Chalk color='greenBright' bold>
                {current.context.originLocation?.name as string}
              </Ink.Chalk>
            </Ink.Text>
          </Ink.Box>
        )}

        {current.matches('ScanningContainer2') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>Cargue 2do contenedor</Ink.Text>
            <Ink.Text>
              Trasladese hasta la ubicacion:{' '}
              <Ink.Chalk color='greenBright' bold>
                {current.context.originLocation?.name as string}
              </Ink.Chalk>
            </Ink.Text>
          </Ink.Box>
        )}

        {current.matches('ScanningControlDigit') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>
              Trasladese a canal:{' '}
              <Ink.Chalk color='greenBright'>
                {current.context.stageLocation?.name as string}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>Ingrese Dígito verificador del canal</Ink.Text>
          </Ink.Box>
        )}

        {current.matches('UnloadingContainer1') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>
              Descargue contenedor&nbsp;
              <Ink.Chalk bold color='greenBright'>
                {current.context.container1?.lpn || 'N/A'}
              </Ink.Chalk>
              &nbsp;en el canal
            </Ink.Text>
          </Ink.Box>
        )}

        {current.matches('UnloadingContainer2') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>
              Descargue contenedor&nbsp;
              <Ink.Chalk bold color='greenBright'>
                {current.context.container2?.lpn || 'N/A'}
              </Ink.Chalk>
              &nbsp;en el canal
            </Ink.Text>
          </Ink.Box>
        )}
        <Ink.Text> </Ink.Text>
      </Ink.Box>

      {/* Body */}
      <Ink.Box>
        {current.hasTag('loading') && <Ink.Text>Cargando.. </Ink.Text>}

        {current.matches('ScanningPalletTruck') && (
          <Ink.Box flexDirection='column'>
            <GetLocationByName />
          </Ink.Box>
        )}

        {current.matches('ScanningContainer1') && (
          <Ink.Box flexDirection='column'>
            <GetContainerByLpn id='ScanningContainer1' />
            {current.context.error && (
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            )}
          </Ink.Box>
        )}

        {current.matches('ScanningContainer2') && (
          <Ink.Box flexDirection='column'>
            <GetContainerByLpn id='ScanningContainer2' />
            {current.context.error && (
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            )}
          </Ink.Box>
        )}

        {current.matches('ScanningControlDigit') && (
          <Ink.Box flexDirection='column'>
            <ScanControlDigit />
          </Ink.Box>
        )}
      </Ink.Box>

      {current.matches('UnloadingContainer1') && (
        <Ink.Box flexDirection='column'>
          <ScanContainer />
          {current.context.error && (
            <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
          )}
        </Ink.Box>
      )}

      {current.matches('UnloadingContainer2') && (
        <Ink.Box flexDirection='column'>
          <ScanContainer />
          {current.context.error && (
            <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
          )}
        </Ink.Box>
      )}

      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}
    </Ink.Box>
  );
});
