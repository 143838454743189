import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { PackingTaskAssignerContext } from './machine';

type PackingTaskAssignerActions = MachineOptions<
  PackingTaskAssignerContext,
  any
>['actions'];

const DEFAULT_ERROR_MESSAGE =
  'Ocurrió un error. Por favor reintente más tarde.';

export const packingTaskAssignerActions: PackingTaskAssignerActions = {
  assignPackingLocation: assign({
    packingLocation: (_context, event) => event.data
  }),
  assignContainer: assign({
    container: (_context, event) => event.data
  }),
  assignError: assign({
    error: (_context, event) =>
      getErrorView(event.data, DEFAULT_ERROR_MESSAGE) || DEFAULT_ERROR_MESSAGE
  }),
  clearError: assign({
    error: (_context, _event) => null
  })
};
