import { Entity, Field, Knows, PrimaryKey } from '@skyframe/core';
import { Order } from './Order';

@Entity()
export class InventoryAssignationStrategy {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  key: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Order, 'wantedAssigationStrategyId')
  orders: Order[];
}
