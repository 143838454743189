import { MopPickingContainerStatuses } from '../MopPickingContainer';
import { MopPickingContainerStatus } from './MopPickingContainerStatus';

export class MopPickingContainerCancelledStatus extends MopPickingContainerStatus {
  public displayName = 'Cancelado';

  public internalName = MopPickingContainerStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
