import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { ScanProductIdentifier } from '../../capa-4/scan-product/ScanProductIdentifier';
import { Print } from '../../layer-4/printer/component';
import { MiniPackingWaveMachine } from './machine';

const otherPackTypeReasons = [
  {
    id:    1,
    label: 'El producto es demasiado grande para el packaging sugerido'
  },
  {
    id:    2,
    label: 'El producto es demasiado pequeño para el packaging sugerido'
  },
  {
    id:    3,
    label: 'No hay stock de packaging sugerido'
  },
  {
    id:    99,
    label: 'Otras razones'
  }
];

export const MiniPackingWaveTask = withActorRef(MiniPackingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Header>Ola de Packing #{current.context.packingWaveId}</Ink.Header>

        {current.hasTag('loading') && (
          <Ink.Box>
            <Ink.Text>Cargando...</Ink.Text>
          </Ink.Box>
        )}

        {current.hasTag('printing') && (
          <Ink.Box>
            <Print />
          </Ink.Box>
        )}

        {(current.matches('ScanningSKU') ||
          current.matches('ConfirmingSKU')) && <ScanProductIdentifier />}

        {current.matches('SelectingPackageType') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>
              Elija packaging para:{' '}
              <Ink.Chalk greenBright bold>
                {`${current.context.currentPackingItem?.orderItem.sku} - ${current.context.currentPackingItem?.orderItem.product.name}`}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              Tomar:
              <Ink.Chalk bold>
                {`${current.context.suggestedPackageType?.name}`}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Button
              id={current.context.suggestedPackageType?.id.toString()}
              label={current.context.suggestedPackageType?.name}
              onClick={() => {
                send({
                  type: 'select',
                  data: { packType: current.context.suggestedPackageType }
                });
              }}
            />
            <Ink.Button
              id='other'
              label='Tomar otro packaging'
              onClick={() => {
                send({
                  type: 'other'
                });
              }}
            />
          </Ink.Box>
        )}

        {current.matches('SelectingOtherPackageType') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Elija packaging</Ink.Text>
            <Ink.Text>
              Se sugiere {current.context.suggestedPackageType?.name}
            </Ink.Text>
            {current.context.otherPackageTypes?.map(packType => (
              <Ink.Button
                key={packType.id}
                label={packType.name}
                onClick={() => {
                  send({
                    type: 'select',
                    data: { packType }
                  });
                }}
              />
            ))}
          </Ink.Box>
        )}

        {current.matches('SelectingReason') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>¿Por que no utilizó el packaging sugerido?</Ink.Text>
            {otherPackTypeReasons.map(reason => (
              <Ink.Button
                key={reason.id}
                label={reason.label}
                onClick={() => {
                  send({
                    type: 'select',
                    data: { reason }
                  });
                }}
              />
            ))}
          </Ink.Box>
        )}

        {current.matches('ScanningLabel') && <ScanLabel />}
      </Ink.Box>
    );
  }
);
