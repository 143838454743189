import { ReceiptStatus } from './ReceiptStatus';

export class ReceiptStatusSortedStatus extends ReceiptStatus {
  public displayName = 'Clasificado';

  public internalName = 'sorted';

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
