import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { CreateContainer } from '../../capa-4/create-container/CreateContainer';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { ScanProductIdentifier } from '../../capa-4/scan-product/ScanProductIdentifier';
import { MiniPickingWaveMachine } from './machine';

export const MiniPickingWaveTask = withActorRef(MiniPickingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);
    const QuantityLeft =
      (current.context.currentPickItem?.quantity as number) -
      current.context.pickedQuantity;
    return (
      <Ink.Box paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            Ola N° {current.context.pickingWaveId || 'N/A'} - Mezzanine Nivel
            N/A
          </Ink.Text>
        </Ink.Header>
        {current.matches('SelectingContainerType') ? (
          <>
            <Ink.Box flexDirection='column'>
              <Ink.Text>Tome Contenedor</Ink.Text>
              <Ink.Text>Se Sugiere contenedor tipo</Ink.Text>
              <Ink.Text>
                {' '}
                {current.context.suggestedContainerType?.name}{' '}
              </Ink.Text>

              {current.context.containerTypes?.map(item => (
                <Ink.Button
                  key={item.id}
                  onClick={() => {
                    send({
                      type:          'containerTypeSelected',
                      containerType: item
                    });
                  }}
                  label={item.name}
                />
              ))}
            </Ink.Box>
          </>
        ) : null}

        {current.matches('ScanningContainer') ? (
          <>
            <EnterLpn />
          </>
        ) : null}

        {current.matches('CreatingContainer') ? (
          <>
            <CreateContainer />
          </>
        ) : null}

        {current.matches('ScanningLocation') ? (
          <>
            <Ink.Box flexDirection='column'>
              <ScanLocation />
            </Ink.Box>
          </>
        ) : null}

        {current.matches('ScanningProduct') ? (
          <>
            <Ink.Box flexDirection='column'>
              <Ink.Text>
                {' '}
                Cantidad a Pickear: {
                  current.context.currentPickItem?.quantity
                }{' '}
              </Ink.Text>
              <Ink.Text> Restantes: {QuantityLeft} </Ink.Text>
              <Ink.Text> Escanee CB del producto: </Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.currentPickItem?.product.sku as string} -{' '}
                {current.context.currentPickItem?.product?.name as string}
              </Ink.Text>

              <ScanProductIdentifier />
            </Ink.Box>
          </>
        ) : null}

        {current.matches('ScanningContainerToSorter') ? (
          <>
            <Ink.Box flexDirection='column'>
              <Ink.Box paddingBottom={2}>
                <Ink.Text>Picking Completado</Ink.Text>
              </Ink.Box>
              <Ink.Text> Todos los items han sido tomados.</Ink.Text>
              <Ink.Text> El contenedor ha sido cerrado</Ink.Text>
              <ScanContainer />
            </Ink.Box>
          </>
        ) : null}
      </Ink.Box>
    );
  }
);
