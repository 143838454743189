import { MachineOptions, assign } from 'xstate';
import { ScanLabelContext } from './machine';

type ScanLabelActions = MachineOptions<ScanLabelContext, any>['actions'];

export const scanLabelActions: ScanLabelActions = {
  assignLabel: assign({
    label: (_, event) => event.data.label,
    error: () => ''
  }),
  assignError: assign({
    error: (_, event) => event.data.error
  }),
  assignRequiredError: assign({
    error: (_, __) => 'El Codigo de barras escaneado no es el correcto'
  })
};
