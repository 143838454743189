import { ReservationOrderStatuses } from '../ReservationOrder';
import { ReservationOrderStatus } from './ReservationOrderStatus';

export class ReservationOrderStatusProcessingStatus extends ReservationOrderStatus {
  public displayName = 'Procesando';

  public internalName = ReservationOrderStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
