import Ink from '@ink';
import { isAlphanumeric } from 'class-validator';
import React, { FC, Reducer, useReducer } from 'react';

export const EnterLot: FC<{
  suggestedLot: string | null;
  lotEntered: (lot: string) => void;
}> = ({ suggestedLot, lotEntered }) => {
  let error;
  const [inputLot, setLot] = useReducer<Reducer<string, string>>(
    (prev, next) => {
      if (next !== '' && error) {
        // setError('');
      }
      return next === '' || isAlphanumeric(next) ? next : prev;
    },
    ''
  );

  return (
    <>
      <Ink.Text>Ingrese Lote:</Ink.Text>

      {suggestedLot ? (
        <>
          <Ink.Text>
            Lote sugerido: <Ink.Chalk greenBright>{suggestedLot}</Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      <Ink.TextInput value={inputLot} onChange={setLot} onSubmit={lotEntered} />
    </>
  );
};

EnterLot.displayName = 'EnterLotInformation';
