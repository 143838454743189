import { AxiosResponse } from 'axios';
import { getErrorView } from '../modules/shared/utils/getErrorView';
import { Maybe } from './maybe';

type AxiosResponseDataOf<T> = T extends AxiosResponse<infer R> ? R : unknown;

export class CustomFetchResultError extends Error {
  constructor(public readonly errorMessage) {
    super(errorMessage);
  }
}

export function fetchResult<
  TParams extends readonly any[],
  TResponse extends AxiosResponse
>(
  defaultErrorMessage: string,
  fn: (...args: TParams) => Promise<TResponse>
): (...args: TParams) => Promise<Maybe<AxiosResponseDataOf<TResponse>>> {
  return async (...args: TParams) => {
    try {
      const response = await fn(...args);
      return { type: 'success', data: response.data };
    } catch (e) {
      return e instanceof CustomFetchResultError
        ? {
            type:     'error',
            error:    e.errorMessage,
            rawError: e
          }
        : {
            type:     'error',
            error:    getErrorView(e, defaultErrorMessage) || defaultErrorMessage,
            rawError: e
          };
    }
  };
}
