import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { OrderItem } from './OrderItem';

@Entity()
export class DispatchLabel {
  @PrimaryKey()
  id: number;

  @Field({ name: 'identifier_in_sequence' })
  identifierInSequence: number;

  @Field({ name: 'is_valid' })
  isValid: boolean;

  @ForeignKey(() => OrderItem, { name: 'order_item_id' })
  orderItemId: number;

  @Knows(() => OrderItem, 'orderItemId')
  orderItem: OrderItem;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}
