import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Location } from './Location';
import { PackingItem } from './PackingItem';
import { PackingProcess } from './PackingProcess';
import {
  PackingWaveStatus,
  PackingWaveStatusCancelledStatus,
  PackingWaveStatusCompletedStatus,
  PackingWaveStatusPausedStatus,
  PackingWaveStatusPendingStatus,
  PackingWaveStatusProcessingStatus
} from './packing-wave-status';

export const PackingWaveStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class PackingWave {
  @PrimaryKey()
  id: number;

  @Status({
    [PackingWaveStatuses.Pending]:    PackingWaveStatusPendingStatus,
    [PackingWaveStatuses.Processing]: PackingWaveStatusProcessingStatus,
    [PackingWaveStatuses.Cancelled]:  PackingWaveStatusCancelledStatus,
    [PackingWaveStatuses.Completed]:  PackingWaveStatusCompletedStatus,
    [PackingWaveStatuses.Paused]:     PackingWaveStatusPausedStatus
  })
  status: PackingWaveStatus;

  @ForeignKey(() => PackingProcess, { name: 'packing_process_id' })
  packingProcessId: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => PackingProcess, 'packingProcessId')
  packingProcess: PackingProcess;

  @Needs(() => Location, 'locationId')
  location: Location;

  @Knows(() => PackingItem, 'packingWaveId')
  packingItems: PackingItem[];
}
