import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { ActorRefFrom } from 'xstate';
import { MidiPickingWaveMachine } from '../machine';

export interface MidiPickingWavePrintingProps {
  actorRef: ActorRefFrom<typeof MidiPickingWaveMachine>;
}

export const MidiPickingWavePrinting: FC<MidiPickingWavePrintingProps> = ({
  actorRef
}) => {
  const [current, send] = useActor(actorRef);

  return (
    <Ink.Box flexDirection='column'>
      {current.matches({ PrintingHomeDelivery: 'ConfirmingPrint' }) && (
        <Ink.Box flexDirection='column'>
          <Ink.Text>Continue con la impresión de etiquetas</Ink.Text>
          <Ink.Button
            label='Imprimir etiquetas'
            onClick={() => {
              send('print');
            }}
          />
        </Ink.Box>
      )}

      {current.matches({ PrintingInternalSupply: 'StickingLabels' }) && (
        <Ink.Box flexDirection='column'>
          <Ink.Text>Pegue etiquetas pre-impresas y confirme</Ink.Text>
          <Ink.Button
            label='Etiquetas pegadas'
            onClick={() => {
              send('confirm');
            }}
          />
        </Ink.Box>
      )}
    </Ink.Box>
  );
};
