import { Container } from '@wms/domain';
import { createMachine } from 'xstate';
import { Maybe } from '../../../../../utils/maybe';
import { getContainerByLpnActions } from './actions';
import { getContainerByLpnGuards } from './guards';
import { getContainerByLpnServices } from './services';

export interface GetContainerByLpnContext {
  readonly hint: string;
  readonly containerSuggestionLpn?: string;
  readonly containerSuggestionHint?: string;
  containerLpn: string;
  container: Container | null;
  error: string | null;
}

export type GetContainerByLpnFn = (lpn: string) => Promise<Maybe<Container>>;

export const GetContainerByLpnMachineId = 'GetContainerByLpn';

export const GetContainerByLpnMachine = (
  hint: string,
  fetchFn: GetContainerByLpnFn,
  containerSuggestion?: { lpn: string; hint: string }
) =>
  createMachine(
    {
      id:                         GetContainerByLpnMachineId,
      predictableActionArguments: true,
      schema:                     {
        context: {} as GetContainerByLpnContext
      },
      context: {
        hint,
        containerSuggestionLpn:  containerSuggestion?.lpn,
        containerSuggestionHint: containerSuggestion?.hint,
        containerLpn:            '',
        container:               null,
        error:                   null
      },
      initial: 'EnteringContainerLpn',
      states:  {
        EnteringContainerLpn: {
          on: {
            UpdateContainerLpn: {
              actions: ['updateContainerLpn', 'clearError']
            },
            SubmitContainerLpn: {
              cond:   'isValidContainerLpn',
              target: 'FetchingContainer'
            }
          }
        },
        FetchingContainer: {
          tags:   ['loading'],
          invoke: {
            src:    'fetchContainer',
            onDone: [
              {
                cond:    'isSuccess',
                actions: 'assignContainer',
                target:  'Finished'
              },
              {
                actions: 'assignError',
                target:  'EnteringContainerLpn'
              }
            ]
          }
        },
        Finished: {
          type: 'final',
          data: (context, _event) => context.container
        }
      }
    },
    {
      guards:   getContainerByLpnGuards,
      actions:  getContainerByLpnActions,
      services: getContainerByLpnServices(fetchFn)
    }
  );
