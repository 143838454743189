import { Container } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetContainerByLpnFn } from '../machine';

export function getAuditingWaveTargetContainerByLpn(
  defaultErrorMessage: string,
  auditingWaveId: number
): GetContainerByLpnFn {
  return fetchResult(defaultErrorMessage, lpn =>
    api.get<Container>(`auditing-wave/${auditingWaveId}/container/${lpn}`)
  );
}
