import Ink from '@ink';
import React, { FC } from 'react';

interface ErrorMessageType {
  error: string;
  bold?: boolean;
}

export const ErrorMessage: FC<ErrorMessageType> = ({ error, bold = false }) => {
  return (
    <Ink.Box>
      <Ink.Text color='redBright' bold={bold}>
        {' '}
        {error}{' '}
      </Ink.Text>
    </Ink.Box>
  );
};
