import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Location } from './Location';
import { SorterLocation } from './SorterLocation';
import { TransportOrder } from './TransportOrder';

@Entity()
export class SorterWMSLocation {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ForeignKey(() => SorterLocation, { name: 'sorter_location_id' })
  sorterLocationId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Location, 'locationId')
  location: Location;

  @Needs(() => SorterLocation, 'sorterLocationId')
  sorterLocation: SorterLocation;

  @Knows(() => TransportOrder, 'originSorterWMSLocationId')
  originInTransportOrder: TransportOrder[];

  @Knows(() => TransportOrder, 'requestedSorterWMSLocationId')
  requestedInTransportOrder: TransportOrder[];

  @Knows(() => TransportOrder, 'finalSorterWMSLocationId')
  finalInTransportOrder: TransportOrder[];
}
