import { InventoryItemQualityStatuses } from '../InventoryItem';
import { InventoryItemQualityStatus } from './InventoryItemQualityStatus';

export class InventoryItemQualityStatusMissingStatus extends InventoryItemQualityStatus {
  public displayName = 'Faltante';

  public internalName = InventoryItemQualityStatuses.Missing;

  public statusColor = '#ffd3d3';

  public textColor = '#b90c0c';
}
