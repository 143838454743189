import { MopSorterExitProcessStatuses } from '../MopSorterExitProcess';
import { MopSorterExitProcessStatus } from './MopSorterExitProcessStatus';

export class MopSorterExitProcessPendingStatus extends MopSorterExitProcessStatus {
  public displayName = 'Pendiente';

  public internalName = MopSorterExitProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
