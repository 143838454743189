import { ConsolidateContainersWaveStatuses } from '../ConsolidateContainersWave';
import { ConsolidateContainersWaveStatus } from './ConsolidateContainersWaveStatus';

export class ConsolidateContainersWaveStatusPending extends ConsolidateContainersWaveStatus {
  public displayName = 'Pendiente';

  public internalName = ConsolidateContainersWaveStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
