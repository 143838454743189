import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { ContainerType } from './ContainerType';
import { PackagingLevel } from './PackagingLevel';
import { Product } from './Product';

export const ContainerCapacityRuleTypes = {
  Qty:    'Cantidad',
  Volume: 'Metraje'
};
@Entity()
export class ContainerCapacityRule {
  @PrimaryKey()
  id: number;

  @Field({ name: 'amount_of_levels' })
  amountOfLevels: number;

  @Field({ name: 'quantity_per_level' })
  qtyPerLevel: number;

  @Field({ name: 'rule_type' })
  ruleType: typeof ContainerCapacityRuleTypes[keyof typeof ContainerCapacityRuleTypes];

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @ForeignKey(() => PackagingLevel, { name: 'packaging_level_id' })
  packagingLevelId: number;

  @ForeignKey(() => ContainerType, { name: 'container_type_id' })
  containerTypeId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => ContainerType, 'containerTypeId')
  containerType: ContainerType;

  @Needs(() => Product, 'productId')
  product: Product;

  @Needs(() => PackagingLevel, 'packagingLevelId')
  packagingLevel: PackagingLevel;
}
