import { TaskBucketStatuses } from '../TaskBucket';
import { TaskBucketStatus } from './TaskBucketBucketStatus';

export class TaskBucketStatusCompletedStatus extends TaskBucketStatus {
  public displayName = 'Completada';

  public internalName = TaskBucketStatuses.Completed;

  public statusColor = '#66bb6a';
}
