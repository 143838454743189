import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import Ink from '../../../../ink';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ContainerDetails } from '../../capa-4/view-container-detail/ContainerDetails';
import { ViewContainerDetailsMachine } from './ViewContainerDetailsMachine';

export const ViewContainerDetailsTask: FC = withActorRef(
  ViewContainerDetailsMachine
)(({ actorRef }) => {
  const [context] = useActor(actorRef);

  return (
    <>
      <Ink.Header>Detalle Contendor</Ink.Header>
      {context.matches('scanContainer') ? <ScanContainer /> : null}
      {context.matches('viewDetails') ? <ContainerDetails /> : null}
    </>
  );
});

ViewContainerDetailsTask.displayName = 'ViewContainerDetailsTask';
