import { MachineOptions } from 'xstate';
import { GetContainerByLpnContext, GetContainerByLpnFn } from './machine';

type GetContainerByLpnServices = MachineOptions<
  GetContainerByLpnContext,
  any
>['services'];

export const getContainerByLpnServices: (
  fetchFn: GetContainerByLpnFn
) => GetContainerByLpnServices = fetchFn => ({
  fetchContainer: (context, _event) => fetchFn(context.containerLpn.trim())
});
