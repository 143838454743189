import { Container } from '@wms/domain';
import { createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';

export interface ContainerDetailsContext {
  lpn: string;
  container: Container;
}

export const DefaultContainerDetailsContext = {
  lpn:       null,
  container: null
};

export const ContainerDetailsMachine = createMachine<ContainerDetailsContext>(
  {
    id:      'ContainerDetails',
    initial: 'FetchingDetail',
    states:  {
      FetchingDetail: {
        invoke: {
          src:    'findContainer',
          onDone: {
            target:  'ShowingDetails',
            actions: ['assignContainer']
          }
        }
      },
      ShowingDetails: {}
    }
  },
  {
    actions: {
      ...UtilityActions
    },
    services: {
      ...API
    }
  }
);
