import { PickingWaveStatuses } from '../PickingWave';
import { PickingWaveStatus } from './PickingWaveStatus';

export class PickingWaveStatusProcessingStatus extends PickingWaveStatus {
  public displayName = 'Procesando';

  public internalName = PickingWaveStatuses.Processing;

  /* public statusColor = '#64b5f6';

  public textColor = '#ffa300';*/

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}
