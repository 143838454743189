import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  PrimaryKey
} from '@skyframe/core';
import { LocationProductAttribute } from './LocationProductAttribute';
import { Product } from './Product';

export const ProductAttributeVolume = {
  MINI: 'mini',
  MIDI: 'midi',
  MAXI: 'maxi',
  BIG:  'big'
} as const;

export const ProductAttributeMhe = {
  CLAMP: 'clamp'
} as const;

export const ProductAttributeType = {
  VOLUME:           'volume',
  MHE:              'mhe',
  MAXCLAMPCAPACITY: 'clampMaxQtyByMovement'
} as const;

export type productAttributeType =
  typeof ProductAttributeType[keyof typeof ProductAttributeType];

export type productAttributeVolume =
  typeof ProductAttributeVolume[keyof typeof ProductAttributeVolume];

export type productAttributeMhe =
  typeof ProductAttributeMhe[keyof typeof ProductAttributeMhe];

export type productAttributeValues =
  | productAttributeVolume
  | productAttributeMhe;
@Entity()
export class ProductAttribute {
  @PrimaryKey()
  id: number;

  @Field()
  type: string;

  @Field()
  name: string;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Product, 'productId')
  product: Product;

  @NeededBy(() => LocationProductAttribute, 'productAttributeId')
  locationProductAttributes: LocationProductAttribute[];
}
