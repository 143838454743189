import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanItem } from '../scan-item/ScanItem';
import { ScanLocation } from '../scan-location/ScanLocation';
import { BulkStorageItemMachine } from './BulkStorageItemMachine';

export const BulkStorageItem: FC = withActorRef(BulkStorageItemMachine)(
  ({ actorRef }) => {
    const [current] = useActor(actorRef);

    return (
      <>
        <Ink.Text>Almacenando Producto</Ink.Text>
        {current.matches('AwaitingItemScan') ? <ScanItem /> : null}

        {current.matches('AwaitingLocationScan') ? <ScanLocation /> : null}

        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}
      </>
    );
  }
);
