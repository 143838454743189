import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import Ink from '../../../../ink';
import { withActorRef } from '../../../shared/Machine';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { LocationDetails } from '../../capa-4/view-location-detail/LocationDetails';
import { ViewLocationDetailsMachine } from './ViewLocationDetailsMachine';

export const ViewLocationDetailsTask: FC = withActorRef(
  ViewLocationDetailsMachine
)(({ actorRef }) => {
  const [context] = useActor(actorRef);

  return (
    <>
      <Ink.Header>Detalle Ubicación</Ink.Header>
      {context.matches('scanLocation') ? <ScanLocation /> : null}
      {context.matches('viewDetails') ? <LocationDetails /> : null}
    </>
  );
});

ViewLocationDetailsTask.displayName = 'ViewLocationDetailsTask';
