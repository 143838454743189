import { Product } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { UtilityActions } from '../../../../api/api';
import {
  DefaultScanProductContext,
  ScanProductIdentifierMachine
} from '../../capa-4/scan-product/ScanProductIdentifierMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export const initialProductDetailContext: ProductDetailContext = {
  product: null
};

type ProductDetailContext = {
  product: Product | null;
};

const HELP = `Ver detalles de producto
Escanee un indentificador (SKU o EAN) para ver todo los detalles del mismo.`;

export const ViewProductDetailsMachine = createMachine<ProductDetailContext>(
  {
    id:      'ViewProductDetails',
    initial: 'scanProduct',
    states:  {
      scanProduct: {
        invoke: {
          id:     ScanProductIdentifierMachine.id,
          src:    ScanProductIdentifierMachine,
          onDone: [
            {
              target:  'viewDetails',
              actions: assign({
                product: (_ctx, event) => event.data.product
              })
            }
          ],
          data: DefaultScanProductContext
        }
      },
      viewDetails: {
        on: {
          DoneViewing: {
            target: 'finishedViewingProduct'
          },
          SelectedRescan: {
            target: 'scanProduct'
          }
        }
      },
      finishedViewingProduct: {
        type: 'final'
      }
    },
    on: {
      goToHelp: {
        actions: 'triggerHelpScreen'
      }
    }
  },
  {
    actions: {
      ...UtilityActions,
      triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
        triggerHelpScreen(HELP)
    }
  }
);
