import {
  Container,
  Location,
  Lot,
  Product,
  ProductItemName,
  ReceiptItem
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { ProductItem } from '../../../shared/utils/ProductItem';
import {
  CreateLotMachine,
  defaultCreateLotContext
} from '../create-lot/CreateLotMachine';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../scan-location/ScanLocationMachine';

export interface CreateItemContext {
  sku: string | null;
  quantity: number | null;
  location: Location | null;

  lotNumber: string;
  expiration: string | null;
  serial: string | null;

  requestedItem: ProductItem | null;
  suggestedItem: ProductItem | null;
  validItems: ProductItem[];
  item: ProductItem | null;
  type: string;
  beforeCreationValidation: ((context: CreateItemContext) => boolean) | null;
  identifier: string;
  hint: string | null;
  error: string | null;

  scannedProduct: Product | null;

  lot: Lot | null;

  receiptItemId: number | null;
  qualityStatus: string;
  expectedContainer: Container | null;
}

export const DefaultCreateItemMachineContext = {
  sku:           '',
  quantity:      null,
  location:      null,
  lotNumber:     '',
  expiration:    null,
  serial:        null,
  identifier:    '',
  requestedItem: {
    wmsReceiptItemId: '',
    product:          { serializeControl: true }
  } as any,
  suggestedItem:            null,
  validItems:               [],
  // Creation Configs
  type:                     ProductItemName.InventoryItem,
  beforeCreationValidation: null,
  item:                     null,
  scannedProduct:           null,

  hint:  null,
  error: null,

  lot: null,

  receiptItemId:     null,
  receiptId:         null,
  qualityStatus:     '',
  expectedContainer: null
};

export const CreateItemMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGEBOYCGAXMBJHAtgLIYDGAFgJYB2YAdDZVpRgDaUBeYAxIqAA4B7WE0qDqfEAA9EARgDMAJgAMdAJwBWRWsXyALADZZyzQBoQAT0QaA7AbrLlBm-OM3ZNxQA55AX1-maJg4+GDEZFS0dLCkGNQAygDSAKrc-KiCEACupFjxsdS0EJJCIszikjIIsrJq9rIqNspeBppqanrmVtU2XnT6itp6al42erJG-oHo2HiEJBQ09ABmYFiL1FAAChnZuQBiGQTI2GyCUNwQ4vQ0AG6CANb0QbOh4RsraxvbuzlYh4JjqdWOcEHdBLFytQANrKAC6JWEogqSGkiG0fTUyhsNg04wMXnGemJXXRIzoBg0BgMJhqTg0zT8ARALxC8wiSzoq3WkR+mT+AKBWDOFzAqAyqDo-FY2GWglQBDorLmYQWkU+PJofL2-yOJ2FIKgYOo90hYhh8MRZXNlUQ8kcdEUDOU8hpejsJicpIQBm0dGdLpqSmUil9UxZMzZqo5UXS-IOgiy1AgvFRpWRElRVTG8kdlPzChUoe98lLFOpBNqGjaHgM4eVbzVnJicQAigAVACa3AAjlk4swsBYAKLUHDoYpppFQ20IVy55zePE+TQaPHerx9eSbiZ6FR6Bm2PT1yMq97quikSNat6Xa4ME2PZ6nxsx+hX4I3wjG03Yc2whEp2tFFQCqBp5A0BxDE3VwdAMbcNG9LEHEcZQFGMLxBlkPQvBPYIzybKIPz-TZbzFCUpRlLA5QVJUX3ZD5L2vUjv3BM1xAAq0M1nYwQ0dPFmgMHCvGUA9EMsRBxj6CZnHpeRcScNQbDw14GIvUdxwAGUELBuBBLANLFSAuJnLNEBzPMqSpQsQwMb1Q3qCscVEvRHFGfxmWoTI4EkBs1M5RhmDYTgwBMm0zOqZQqX9eScQUVzZEJGxvQ0LxIMUdwfDQ0T9FkDQVKjc9mwKJJkjCkC0WqVLIL3WoTFcrEvAUezNDoVzHHtbCvCxDoCoIt8uS+XkdnjXVAX1EVyszUDEAAWmw1Q1xdCDbFE1ocRShQHC0VK3JdExcOZPzo0YuMdX2RNkym2dUr6aCcV9Xc9HkEturoGTqTqJp4PGPrX0YltqA7TtroiiZZHexKFMpPE6S8b0Jkg11DCMEYHMGOsjvok6L2I5gWLCUGZoQEYIfkDo3RGeSD2SiSEE8CkBnGbQjAa48sfw-71LHMVtKwInKtxewhKatCaUSxR3Q3Po0oPDoWjSglXD+-yojeZVJwEadwuJvK0raxQ6o6T0mpeunjFzAMGRsDoIMwplpk51XQqA7iIvmoT-QZUs1yaQwlNp7pVB+3RCW8dwxk0WQPN8IA */
  createMachine<CreateItemContext>(
    {
      context: DefaultCreateItemMachineContext,
      id:      'CreateItemMachine',
      initial: 'ScanSKU',
      states:  {
        ScanSKU: {
          on: {
            productScanned: [
              {
                cond:    'skuIsValid',
                actions: assign({
                  identifier:    (_context, event) => event.data.identifier,
                  receiptItemId: (context, event) =>
                    context.validItems.find(
                      receiptItem =>
                        receiptItem.sku === event.data.identifier ||
                        (receiptItem as ReceiptItem).packagingLevel.ean ===
                          event.data.identifier
                    )?.id || null
                }),
                target: 'FetchingProductFromCatalog'
              },
              {
                actions: ['assignInvalidIdentifierError'],
                target:  'ScanSKU'
              }
            ]
          }
        },
        FetchingProductFromCatalog: {
          invoke: {
            src:    'findProduct',
            onDone: [
              {
                actions: 'assignProduct',
                target:  'PRODUCT_FOUND'
              }
            ],
            onError: [
              {
                actions: 'assignError',
                target:  'ScanSKU'
              }
            ]
          }
        },
        PRODUCT_FOUND: {
          always: [
            {
              cond:    'validateBeforeCreation',
              actions: ['errorMonoSkuContainer'],
              target:  'ScanSKU'
            },
            {
              cond:    'productScannedItsNotTheSuggested',
              actions: 'updateSuggestedItem',
              target:  'ScanQTY'
            },
            {
              target: 'ScanQTY'
            }
          ]
        },
        ScanQTY: {
          on: {
            quantityEntered: [
              {
                cond:    'productIsLotControlled',
                target:  'EnterLot',
                actions: 'assignQuantity'
              },
              {
                cond:    'scanLocationIsSet',
                target:  'ValidatingItem',
                actions: 'assignQuantity'
              },
              {
                actions: 'assignQuantity',
                target:  'ScanLocation'
              }
            ]
          }
        },
        ScanLocation: {
          invoke: {
            src:    ScanLocationMachine,
            id:     ScanLocationMachine.id,
            data:   { ...DefaultScanLocationContext, suggestLocation: false },
            onDone: {
              actions: 'assignLocation',
              target:  'ValidatingItem'
            }
          }
        },
        ValidatingItem: {
          invoke: {
            src:    'validateItem',
            onDone: [
              {
                actions: 'assignItem',
                target:  'CreatingItem'
              }
            ],
            onError: [
              {
                cond:    'checkIfQuantityIsZero',
                target:  'ScanQTY',
                actions: 'assignError'
              },

              {
                actions: 'assignError',
                target:  'ScanQTY'
              }
            ]
          }
        },
        CreatingItem: {
          invoke: {
            src:    'createItem',
            onDone: [
              {
                actions: 'assignItem',
                target:  'ItemCreated'
              }
            ],
            onError: [
              {
                cond:    'checkIfQuantityIsZero',
                target:  'ScanQTY',
                actions: 'assignError'
              },

              {
                actions: 'assignError',
                target:  'ScanQTY'
              }
            ]
          }
        },
        EnterLot: {
          invoke: {
            src:    CreateLotMachine,
            id:     CreateLotMachine.id,
            data:   defaultCreateLotContext,
            onDone: [
              {
                actions: assign({
                  lot: (_ctx, event) => event.data.lot
                }),
                cond:   'scanLocationIsSet',
                target: 'CreatingItem'
              },
              {
                actions: assign({
                  lot: (_ctx, event) => event.data.lot
                }),
                target: 'ScanLocation'
              }
            ]
          }
        },
        ItemCreated: {
          type: 'final',
          data: {
            item: (context: CreateItemContext) => context.item
          }
        }
      }
    },
    {
      guards: {
        ...UtilityGuards,
        skuIsValid: (context, event) =>
          context.validItems?.length === 0 ||
          context.validItems?.some(
            item =>
              item.sku === event.data.identifier ||
              (item as ReceiptItem).packagingLevel.ean === event.data.identifier
          ),
        scanLocationIsSet:      context => !!context.location,
        checkIfQuantityIsZero:  context => context.quantity === 0,
        validateBeforeCreation: context => {
          return !!context.beforeCreationValidation?.(context);
        }
      },
      actions: {
        ...UtilityActions,
        assignQuantity: assign({
          quantity: (context, { data: { quantity } }) =>
            quantity *
            (context.scannedProduct?.packagingLevels[0]?.name === 'KG'
              ? 1
              : context.scannedProduct?.packagingLevels[0]?.quantity || 1)
        }),
        assignProduct: assign({
          sku:            (_ctx, { data: { product } }) => product.sku,
          scannedProduct: (_ctx, { data: { product } }): Product => product
        }),
        assignInvalidIdentifierError: assign({
          error: _ctx =>
            'El SKU ingresado no pertenece a esta recepción. Por favor verificar e ingresar nuevamente'
        }),
        errorMonoSkuContainer: assign<any>({
          error: () => 'El contenedor escaneado es mono SKU'
        }),
        updateSuggestedItem: assign({
          suggestedItem: (ctx): ProductItem | null => {
            return ctx.validItems.find(
              item => item.sku === ctx.scannedProduct!.sku
            ) as ProductItem;
          }
        })
      },
      services: { ...API }
    }
  );
