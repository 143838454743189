import {
  Container,
  Location,
  Order,
  StockTransfer,
  Task,
  TaskTypes
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { MoveContainerMachine } from '../../capa-4/move-container/MoveContainerMachine';

import { GoToHelpEvent, GoToOptionsEvent } from '../../core/GenericOptions';
import { moveContainersTaskInitialContext } from '../move-containers/MoveContainersTaskMachine';

export interface DispatchItemsTaskMachineContext {
  task: Task | null;
  payload: any;
  order: Order | null;
  containersToMove: Container[];
  containersToDispatch: Container[];
  movedContainers: Container[];
  dispatchedContainers: Container[];
  staging: Location | null;
  licensePlate: string | null;
  error: string | null;
  stockTransferId: number | null;
  stockTransfer: StockTransfer | null;
}

export const DefaultDispatchItemsTaskMachine = {
  task:                 null,
  payload:              null,
  order:                null,
  containersToMove:     [],
  containersToDispatch: [],
  movedContainers:      [],
  dispatchedContainers: [],
  staging:              null,
  licensePlate:         null,
  error:                null,
  stockTransferId:      null,
  stockTransfer:        null
};

const HELP = 'lol';

export const DispatchItemTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAUCuAXAggdwIYE8BhAewDt1cBLUsAJ1gBVdYBrAOmsvUtwBtKAXmADEiUAAdisLpTJiQAD0QAGADQh8KgL5b1aLHiJkK1Oo2bsAkujq5upKAHkAbnQBKYAMZhK49CXIqGnphTF5eazAAW1gAZXRiWlwYCHlJaW45JEVEACYATny2AEYANnyADgBWAHYK-NzlABYK9U0EAFoKtgBmJrKy6pqmnuVcptKdPQwcAgCTYPNWNgCAM0paKP1Z-GFPMnXN5EpPFmooNKkZLNAlBFLS3LZc4prS4oKWl9K2xFfu8pNfJVXI1Ro9YpVUoVHS6ECkYgQODybaGeZBMxMZacbh8QRgS4ZWSkeR3NQaRD5SZw1FzYwY+hYqw2JL2JyuWgeby+fz00z0QnXEnZO7FJrKNjNKmVCrNPrjX4IJpVNgjCaNXJVfLKnr1JpTEC0oyBflLdhrDZbGaGQWZYW3RA6ti1fJvOo1KrFfJin4UhCy57FHo9fLKMNVCqlLW5A1G9GmplsI1MkhRcS8MA2VLZdJC0mIUo1Iqh4E1ZSF3XlnqKyPOrUNIug0YtWPWukmxZM23E-OdBV+rrFXofV3KHplz2PfWwoA */
  createMachine<DispatchItemsTaskMachineContext>(
    {
      id:      TaskTypes.DispatchItem,
      context: DefaultDispatchItemsTaskMachine,
      initial: 'Initializing',
      states:  {
        Initializing: {
          entry: assign({
            stockTransferId:      context => context.payload?.stockTransferId,
            stockTransfer:        context => context.payload?.stockTransfer,
            order:                context => context.payload,
            containersToMove:     context => context.payload?.containers,
            containersToDispatch: context => context.payload?.containers,
            staging:              context =>
              context.payload?.stockTransfer?.appointment?.staging ||
              context.payload?.order?.appointment?.staging,
            licensePlate: context =>
              context.payload?.stockTransfer?.appointment?.vehicle
                ?.licensePlate ||
              context.payload?.order?.appointment?.vehicle?.licensePlate
          }),
          always: [
            {
              target: 'MoveContainers'
            }
          ]
        },
        MoveContainers: {
          invoke: {
            id:   MoveContainerMachine.id,
            src:  MoveContainerMachine,
            data: ctx => ({
              ...moveContainersTaskInitialContext,
              requiredLocation:  ctx.stockTransfer?.appointment?.staging,
              validContainers:   ctx.payload?.containers,
              suggestedLocation: ctx.staging
            }),
            onDone: [
              {
                actions: 'updateContext',
                cond:    'containersToMoveRemaining',
                target:  'MoveContainers'
              },
              {
                actions: 'updateContext',
                target:  'DispatchContainers'
              }
            ]
          }
        },
        DispatchContainers: {
          on: {
            continue: {
              target: 'ScanLicensePlates'
            }
          }
        },
        ScanLicensePlates: {
          on: {
            plateScanned: [
              {
                cond:   'plateIsValid',
                target: 'ConfirmDispatch'
              },
              {
                actions: ['assignInvalidIdentifierError'],
                target:  'ScanLicensePlates'
              }
            ]
          }
        },
        ConfirmDispatch: {
          invoke: {
            src:    'confirmDispatch',
            onDone: [
              {
                target: 'MarkAsComnpleted'
              }
            ]
          }
        },
        MarkAsComnpleted: {
          invoke: {
            src:    'completeSimpleTask',
            onDone: [
              {
                target: 'DispatchConfirmed'
              }
            ],
            onError: [
              {
                actions: 'assignError',
                target:  'ScanLicensePlates'
              }
            ]
          }
        },
        DispatchConfirmed: {}
      }
    },
    {
      guards: {
        ...UtilityGuards,
        containersToMoveRemaining: ctx => ctx.containersToMove.length > 1,
        plateIsValid:              (context, event) =>
          event.data.licensePlate === context.licensePlate
      },
      actions: {
        ...UtilityActions,
        updateContext: assign(ctx => {
          const [movedContainer, ...containersToMove] = ctx.containersToMove;
          const currentContainer = containersToMove[0];
          const movedContainers = [...ctx.containersToMove, movedContainer];

          return {
            ...ctx,
            containersToMove,
            movedContainers,
            currentContainer,
            dispatchedContainers: [...ctx.dispatchedContainers, movedContainer]
          };
        }),
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP),
        assignInvalidIdentifierError: assign({
          error: _ctx => 'Patente Invalida'
        }),
        triggerMenuScreen: (
          ctx,
          { send, triggerMenuScreen }: GoToOptionsEvent
        ) => {
          triggerMenuScreen([
            {
              label:   'Terminar tarea',
              onClick: () => send('finishTask')
            }
          ]);
        }
      },
      services: { ...API }
    }
  );
