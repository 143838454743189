import { AuditingItemStatuses } from '../AuditingItem';
import { AuditingItemStatus } from './AuditingItemStatus';

export class AuditingItemCancelledStatus extends AuditingItemStatus {
  public displayName = 'Cancelado';

  public internalName = AuditingItemStatuses.Cancelled;

  public statusColor = '#b90c0c';
}
