import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { DispatchContainersMachine } from './dispatch-containers.machine';

export const DispatchContainers: FC = withActorRef(DispatchContainersMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const completeDispatch = () => {
      send({ type: 'CONFIRM' });
    };
    return (
      <>
        <Ink.Box>
          <Ink.Header>
            <Ink.Text bold>
              {!current.context.payload?.order.id ? (
                'DESPACHANDO ORDEN '
              ) : (
                <>
                  <Ink.Text>DESPACHANDO ORDEN: </Ink.Text>
                  <Ink.Chalk>{current.context.payload!.order.id}</Ink.Chalk>
                </>
              )}
            </Ink.Text>
          </Ink.Header>
        </Ink.Box>
        {current.matches('CyclingContainers') ? (
          <>
            <ScanContainer />
          </>
        ) : null}
        {current.matches('DispatchingContainers') ? (
          <Ink.Text>Procesando...</Ink.Text>
        ) : null}
        {current.matches('ConfirmingDispatch') ? (
          <>
            <Ink.Text>
              Confirmar despacho de {current.context.scannedContainers.length}{' '}
              {current.context.scannedContainers.length === 1
                ? 'contenedor'
                : 'contenedores'}
            </Ink.Text>
            <Ink.Button onClick={completeDispatch} label='Despachar' />
          </>
        ) : null}
        {current.matches('Done') ? (
          <Ink.Text>
            Despacho completado, dirijase a las opciones para volver al menú
          </Ink.Text>
        ) : null}
        <Ink.Text>{current.context.error}</Ink.Text>
      </>
    );
  }
);

DispatchContainers.displayName = 'DispatchContainers';
