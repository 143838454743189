import { ClassificationItemStatuses } from '../ClassificationItem';
import { ClassificationItemStatus } from './ClassificationItemStatus';

export class ClassificationItemPendingStatus extends ClassificationItemStatus {
  public displayName = 'Pendiente';

  public internalName = ClassificationItemStatuses.Pending;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}
