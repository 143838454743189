import { Entity, Field, Knows, NeededBy, PrimaryKey } from '@skyframe/core';
import { Location } from './Location';
import { LocationAssignment } from './LocationAssignment';
import { ProductTypeLocationZone } from './ProductTypeLocationZone';

@Entity()
export class LocationZone {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  description: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => ProductTypeLocationZone, 'locationZoneId')
  productTypeLocationZones: ProductTypeLocationZone[];

  @Knows(() => Location, 'locationZoneId')
  locations: Location[];

  @NeededBy(() => LocationAssignment, 'locationZoneId')
  locationAssignments: LocationAssignment[];
}
