import Ink from '@ink';
import { useActor } from '@xstate/react';
import { isDecimal } from 'class-validator';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterQuantityMachine } from './EnterQuantityMachine';

export const EnterQuantity: FC = withActorRef(EnterQuantityMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const setQuantity = useCallback(
      (input: string) => {
        if (isDecimal(input) || input === '') {
          send({
            type: 'updateQuantity',
            data: { quantity: input === '' ? '' : parseInt(input) }
          });
        }
      },
      [send]
    );

    const submitQuantity = useCallback(() => {
      send('submitQuantity');
    }, [send]);

    return (
      <>
        <Ink.Text bold>{current.context.hint}</Ink.Text>
        <Ink.TextInput
          value={
            typeof current.context.quantity === 'number'
              ? current.context.quantity.toString()
              : ''
          }
          onChange={setQuantity}
          onSubmit={submitQuantity}
        />
        {current.context.error ? (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        ) : null}
      </>
    );
  }
);
