import Ink from '@ink';
import { ContainerType } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC, useEffect } from 'react';
import { useGetContainerTypes } from '../../../../api/containerType';
import { withActorRef } from '../../../shared/Machine';
import { Paginator } from '../../../shared/Paginator';
import { SelectList } from '../../../shared/SelectList';
import { ScanLocation } from '../scan-location/ScanLocation';
import { CreateContainerMachine } from './CreateContainerMachine';

export const CreateContainer: FC = withActorRef(CreateContainerMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const onSubmit = () => {
      send('submit');
    };

    const onChangeLpn = (lpn: string) => {
      send({ type: 'change', lpn });
    };

    const onChangeContainerTypeName = (containerType: any) => {
      send({ type: 'change', containerType });
      send('submit');
    };

    const { page, request, hasPrev, hasNext, requestPrev, requestNext } =
      useGetContainerTypes(5);

    useEffect(() => {
      request();
    }, []);

    return (
      <>
        <Ink.Box flexDirection='column' paddingTop={1}>
          {current.matches('enteringLPN') ? (
            <Ink.Box flexDirection='column'>
              {current.context.hint ? (
                <Ink.Text>{current.context.hint}</Ink.Text>
              ) : null}
              <Ink.Text>Escanee etiqueta de contenedor a crear: </Ink.Text>
              <Ink.TextInput
                value={current.context.lpn}
                onChange={onChangeLpn}
                onSubmit={onSubmit}
                focus
                showCursor
              />

              {current.context.error && (
                <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
              )}
            </Ink.Box>
          ) : null}

          {current.matches('scanLocation') ? <ScanLocation /> : null}

          {current.matches('selectContainerType')
            ? page &&
              page.rows.length > 0 && (
                <Ink.Box flexDirection='column' paddingX={1}>
                  <Ink.Box flexDirection='column'>
                    <Ink.Text>Ingrese tipo de contenedor</Ink.Text>
                    {/* <Ink.SelectInput
                    items={containerTypesList}
                    onSelect={onChangeContainerTypeName}
                  /> */}
                    <SelectList
                      items={page.rows.map((ct: ContainerType) => ({
                        label: ct.name,
                        value: ct.id,
                        id:    ct.id,
                        key:   ct.id.toString()
                      }))}
                      trackBy={item => item.label}
                      getDisplayText={item => `${item.label}`}
                      onSelect={item => {
                        onChangeContainerTypeName(item);
                      }}
                    />
                    <Paginator
                      hasPrev={hasPrev}
                      hasNext={hasNext}
                      onPrev={requestPrev}
                      onNext={requestNext}
                    />

                    {current.context.error && (
                      <Ink.Text color='redBright'>
                        {current.context.error}
                      </Ink.Text>
                    )}
                  </Ink.Box>
                </Ink.Box>
              )
            : null}

          {current.matches('fetchingContainerTypes') ? (
            <Ink.Box>
              <Ink.Text>Buscando tipos de contenedores...</Ink.Text>
            </Ink.Box>
          ) : null}
        </Ink.Box>
      </>
    );
  }
);
