import { MopPickingContainerStatuses } from '../MopPickingContainer';
import { MopPickingContainerStatus } from './MopPickingContainerStatus';

export class MopPickingContainerMovedStatus extends MopPickingContainerStatus {
  public displayName = 'Movido';

  public internalName = MopPickingContainerStatuses.Moved;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}
