import { TaskStatuses } from '../Task';
import { TaskStatus } from './TaskStatus';

export class TaskStatusDraftStatus extends TaskStatus {
  public displayName = 'Borrador';

  public internalName = TaskStatuses.Draft;

  public statusColor = '#d7d7d7';
}
