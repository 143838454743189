import { api } from '../../../../api/utils/axios-instance';

export const consolidationClassificationServices = {
  getClassificationItemByLabel: async (ctx, _evt) => {
    const { data } = await api.get('classification-item/label', {
      params: {
        label: ctx.label
      }
    });
    return {
      classificationItem: data.item,
      location:           data.location,
      customer:           data.customer
    };
  },
  checkIfHasClassificationContainer: async (ctx, _evt) => {
    const { data } = await api.get(
      'classification-container/classification-item',
      {
        params: {
          classificationItemId: ctx.classificationItem.id,
          locationId:           ctx.classificationItemLocation.id
        }
      }
    );
    return {
      classificationContainer: data
    };
  },
  createNewClassificationContainer: async (ctx, _evt) => {
    await api.get(
      'classification-process/consolidation-classification-dispatch-process-event',
      {
        params: {
          classificationProcessId:
            ctx.classificationItem.classificationProcess.id,
          event:   'ConsolidationClassificationCreateContainer',
          payload: {
            lpn:             ctx.newContainerLabel,
            containerTypeId: ctx.selectedContainerType.id,
            slot:            ctx.suggestedSlotPosition,
            locationId:      ctx.classificationItemLocation.id,
            customerId:      ctx.classificationItemCostumer.id
          }
        }
      }
    );
  },
  ConsolidationClassificationClassifyItem: async (ctx, _evt) => {
    await api.get(
      'classification-process/consolidation-classification-dispatch-process-event',
      {
        params: {
          classificationProcessId:
            ctx.classificationItem.classificationProcess.id,
          event:   'ConsolidationClassificationClassifyItem',
          payload: {
            classificationItemId: ctx.classificationItem.id,
            containerId:          ctx.classificationContainer.container.id
          }
        }
      }
    );
  },
  ConsolidationClassificationContainerClosed: async (ctx, _evt) => {
    await api.get(
      'classification-process/consolidation-classification-dispatch-process-event',
      {
        params: {
          classificationProcessId:
            ctx.classificationItem.classificationProcess.id,
          event:   'ConsolidationClassificationContainerClosed',
          payload: {
            containerId: ctx.classificationContainer.container.id
          }
        }
      }
    );
  },
  getContainerTypeByName: async (ctx, _evt) => {
    const { data } = await api.get('container-type/name', {
      params: {
        name: ctx.selectedContainerTypeName
      }
    });
    return {
      containerType: data
    };
  },
  getSuggestedSlotPosition: async (ctx, _evt) => {
    const { data } = await api.get(
      'classification-process/next-classification-container-slot-position',
      {
        params: {
          locationId: ctx.classificationItemLocation.id
        }
      }
    );
    return {
      slotPosition: data
    };
  }
};
