import { InventoryCountStatus } from './InventoryCountStatus';

export class InventoryCountStatusConfirmationStatus extends InventoryCountStatus {
  public displayName = 'Esperando Confirmación';

  public internalName = 'confirmation';

  public statusColor = '#c9def1';

  public textColor = '#212f61';
}
