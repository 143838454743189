import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { PackingTaskAssignerContext } from './machine';

type PackingTaskAssignerServices = MachineOptions<
  PackingTaskAssignerContext,
  any
>['services'];

export const packingTaskAssignerServices: PackingTaskAssignerServices = {
  FetchPackingWaveTask: async context => {
    const { data: task } = await api.get('packing-wave/assign-mini-task', {
      params: {
        packingLocationId: context.packingLocation?.id,
        containerId:       context.container?.id
      }
    });

    return task;
  }
};
