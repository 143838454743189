import { AuditOrderItemStatuses } from '../AuditOrderItem';
import { AuditOrderItemStatus } from './AuditOrderItemStatus';

export class AuditOrderItemPendingStatus extends AuditOrderItemStatus {
  public displayName = 'Pendiente';

  public internalName = AuditOrderItemStatuses.Pending;

  public statusColor = '#d7d7d7';
}
