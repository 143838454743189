import { api } from '../../../../../api/utils/axios-instance';

export const InboundDirectReceptionService = {
  receiveItem: (ctx, _ev) => {
    return api.post(`/task/${ctx.task?.id}/event`, {
      type:    'productReceived',
      payload: {
        productId: ctx.currentProduct?.id,
        quantity:  ctx.currentQuantity
      }
    });
  },
  storeItem: (ctx, _ev) => {
    return api.post(`/task/${ctx.task?.id}/event`, {
      type:    'productStored',
      payload: {
        checkDigit: ctx.checkDigit,
        quantity:   ctx.currentQuantity
      }
    });
  },
  completeTask: (ctx, _ev) => {
    return api.post(`/task/${ctx.task?.id}/complete`, ctx);
  },
  countPendingItemsToReceive: async (ctx, _ev) => {
    const { data } = await api.get(
      `/receipt/${ctx.receiptId}/count-pending-direct-reception-items`,
      ctx
    );
    return data;
  },
  fetchSuggestedStorageLocation: (_ctx, _ev) => {
    return api.get('receipt/direct-suggested-storage-location');
  },
  validateProductAndQuantity: async (ctx, _ev) => {
    const { data } = await api.get(
      `receipt/${ctx.receiptId}/validate-product-and-quantity`,
      {
        params: {
          productId: ctx.currentProduct?.id,
          quantity:  ctx.currentQuantity
        }
      }
    );
    return data;
  }
};
