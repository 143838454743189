import { InventoryItemStatuses } from '../InventoryItem';
import { InventoryItemStatus } from './InventoryItemStatus';

export class InventoryItemStatusReceivingStatus extends InventoryItemStatus {
  public displayName = 'Recibiendo';

  public internalName = InventoryItemStatuses.Receiving;

  public statusColor = '#ffeff3';

  public textColor = '#b90c0c';
}
