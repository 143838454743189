import { PickingProcessStatus } from './PickingProcessStatus';

export class PickingProcessStatusCompletedStatus extends PickingProcessStatus {
  public displayName = 'Completado';

  public internalName = 'completed';

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
