import { assign, createMachine } from 'xstate';

export interface EnterLpnContext {
  lpn: string | null;
  invalidLpns: string[];
  invalidLpnMessage: string;
  hint: string;
  error: string | null;
}

export const DefaultEnterLpnContext: EnterLpnContext = {
  lpn:               null,
  invalidLpns:       [],
  invalidLpnMessage: 'LPN inválido',
  hint:              'Escanee LPN:',
  error:             null
};

export const EnterLpnMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFEB2AXMAnAwgCzAGMBrAEQEspz0BZAQ0L3NTADowNtmp8izLqAYgCuABwh1MvEhSroA2gAYAuolCiA9rGrkNqNSAAeiACwBOVmYBMZgGwAOe7atWAzGYCMAVg8AaEACeiPYerOZmZvZmimYA7Ipu9rEAvsn+aJi4BDICtAxMLOycWNzS-HKCsMIARgC21GWy1EqqSCCa2ui6+m3GCFYmVqweJoq2rl5xJg4u9v5BCB4hll6uHlax9jYuJl4pqf6oGhBwBhnYjbn0jMxgBh06egZ9ALR+gYgvXpYRv39-rgOIHOWT4TTyN0KHEypWy5Wo9y0jx6oD6Jli80QSyGuzW6yszl20VsQJBlzk1wKbEYYNyAGUavV0JgIIjOt1nqYJqw3CZ3GYvOMYiY5h9FssrIo1q4rOsTCNBalUkA */
  createMachine<EnterLpnContext>(
    {
      id:                         'EnterLpnMachine',
      initial:                    'enteringLpn',
      predictableActionArguments: true,
      states:                     {
        enteringLpn: {
          on: {
            lpnScanned: {
              actions: assign({
                lpn: (_ctx, event) => event.data.lpn
              })
            },
            submitLpn: [
              {
                cond:    'lpnIsInvalid',
                target:  'enteringLpn',
                actions: 'showErrorAndCleanInput'
              },
              {
                target: 'lpnSubmitted'
              }
            ]
          }
        },
        lpnSubmitted: {
          type: 'final',
          data: (ctx, _event) => ({ lpn: ctx.lpn })
        }
      }
    },
    {
      guards: {
        lpnIsInvalid: (ctx, _event) => {
          if (
            (ctx.invalidLpns.length !== 0 &&
              ctx.invalidLpns.includes(ctx.lpn as string)) ||
            ctx.lpn == null
          ) {
            return true;
          }
          return false;
        }
      },
      actions: {
        showErrorAndCleanInput: assign({
          error: (ctx, _event) => ctx.invalidLpnMessage,
          lpn:   (_, __) => null
        })
      }
    }
  );
