import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { Counter } from '../../../shared/Counter';
import { withActorRef } from '../../../shared/Machine';
import { MoveContainer } from '../../capa-4/move-container/MoveContainer';
import { ScanLicensePlate } from '../../capa-4/scan-license-plate/ScanLicensePlate';
import { DispatchItemTaskMachine } from './DispatchItemTaskMachine';

export const DispatchItemTask: FC = withActorRef(DispatchItemTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const onCompleted = () => {
      console.log('AllContainersPicked');
    };

    const continueDispatch = useCallback(() => {
      send('continue');
    }, []);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Header>
          <Ink.Text bold>
            {!current.context.order?.id ? (
              'MOVIENDO A CANAL'
            ) : (
              <>
                DESPACHANDO ORDEN DE SALIDA:{' '}
                <Ink.Chalk greenBright>{current.context.order?.id}</Ink.Chalk>
              </>
            )}
          </Ink.Text>
        </Ink.Header>

        {current.matches('MoveContainers') ? (
          <>
            <Ink.Text>
              <Counter
                count={
                  current.context.containersToMove !== null
                    ? {
                        total:
                          (current.context.containersToMove?.length +
                            current.context.movedContainers?.length) |
                          0,
                        processed: current.context.containersToMove?.length
                      }
                    : null
                }
                onComplete={onCompleted}
              />{' '}
              Contenedores
            </Ink.Text>
            <MoveContainer />
          </>
        ) : null}

        {current.matches('DispatchContainers') ? (
          <>
            <Ink.Text>
              Se despacharán los siguientes contenedores:{' '}
              <Ink.Box flexDirection='row'>
                {' '}
                {current.context.dispatchedContainers.map(item => (
                  <Ink.Text key={item.id} color='greenBright'>
                    {item.lpn + ', '}
                  </Ink.Text>
                ))}
              </Ink.Box>
            </Ink.Text>
            <Ink.Button
              key='continue'
              id='continue'
              label='Continuar'
              onClick={continueDispatch}
            />
          </>
        ) : null}

        {current.matches('ScanLicensePlates') ? (
          <>
            <ScanLicensePlate
              plateScanned={plate =>
                send({ type: 'plateScanned', data: { licensePlate: plate } })
              }
              suggestedLicensePlate={current.context?.licensePlate}
            />

            {current.context?.error ? (
              <>
                <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
              </>
            ) : null}
          </>
        ) : null}

        {current.matches('ConfirmDispatch') ? (
          <>
            <Ink.Text>Confirmando despacho...</Ink.Text>
          </>
        ) : null}

        {current.matches('DispatchConfirmed') ? (
          <>
            <Ink.Text>Contenedores despachados.</Ink.Text>
          </>
        ) : null}

        {current.matches('Initializing') ? (
          <Ink.Text>Cargando...</Ink.Text>
        ) : null}
      </Ink.Box>
    );
  }
);
