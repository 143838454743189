import { PickingWaveStatuses } from '../PickingWave';
import { PickingWaveStatus } from './PickingWaveStatus';

export class PickingWaveStatusCompletedStatus extends PickingWaveStatus {
  public displayName = 'Completado';

  public internalName = PickingWaveStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
