import { ClassificationContainerStatuses } from '../ClassificationContainer';
import { ClassificationContainerStatus } from './ClassificationContainerStatus';

export class ClassificationContainerClosedStatus extends ClassificationContainerStatus {
  public displayName = 'Cerrado';

  public internalName = ClassificationContainerStatuses.Closed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}
