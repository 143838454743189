import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchNotPreparedStatus extends OrderBatchStatus {
  public displayName = 'Sin Preparar';

  public internalName = OrderBatchStatuses.NotPrepared;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}
