import { Entity, Field, PrimaryKey } from '@skyframe/core';

@Entity()
export class SapSync {
  @PrimaryKey()
  id: number;

  @Field()
  entity: string;

  @Field({ name: 'last_updated' })
  lastUpdated: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}
