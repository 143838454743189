import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import Ink from '../../../../ink';
import { withActorRef } from '../../../shared/Machine';
import { ScanProductIdentifier } from '../../capa-4/scan-product/ScanProductIdentifier';
import { ViewProductDetailsMachine } from './ViewProductDetailsMachine';

export const ViewProductDetailsTask: FC = withActorRef(
  ViewProductDetailsMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  return (
    <>
      <Ink.Header>Buscar Producto</Ink.Header>
      {current.matches('scanProduct') ? <ScanProductIdentifier /> : null}
      {current.matches('viewDetails') ? (
        <>
          <Ink.Text bold>
            {current.context.product?.name} - {current.context.product?.sku} -{' '}
            {current.context.product?.ean}
          </Ink.Text>
          {current.context.product?.inventoryItems.map(ii => {
            return (
              <>
                <Ink.Chalk bold greenBright>
                  {ii.location.name}
                </Ink.Chalk>
                <Ink.Text> Cantidad: {ii.quantity}</Ink.Text>
                <Ink.Text> Calidad: {ii.qualityStatus.displayName}</Ink.Text>
              </>
            );
          })}
          <Ink.Button
            onClick={() => send({ type: 'SelectedRescan' })}
            label='BUSCAR OTRO PRODUCTO'
          />
        </>
      ) : null}
    </>
  );
});

ViewProductDetailsTask.displayName = 'ViewProductDetailsTask';
